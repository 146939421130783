import { Component, OnInit } from '@angular/core';
import {Contact} from '../../../../model/contact/Contact';
import {ContactService} from '../../../../service/interne/contact/ContactService';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {ReseauxSociauxService} from '../../../../service/interne/contact/ReseauxSociauxService';
import {CoordonneesService} from '../../../../service/interne/contact/CoordonneesService';
import {ListeContactContact} from '../../../../model/liste/ListeContactContact';
import {Parution} from '../../../../model/parution/Parution';
import {ParutionService} from '../../../../service/interne/parution/parutionService';
import {ParutionContact} from '../../../../model/parution/ParutionContact';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-utilitaire-dedoublonner-contacts',
  templateUrl: './utilitaire-dedoublonner-contacts.component.html',
  styleUrls: ['./utilitaire-dedoublonner-contacts.component.scss'],
  providers: [DialogueShareService, ReseauxSociauxService, ParutionService]
})
export class UtilitaireDedoublonnerContactsComponent implements OnInit {

  structureChoisi: string;
  visualiser: string;
  idUn: number;
  idDeux: number;
  contactIdUn: number;
  contactIdDeux: number;
  afficheUn: boolean;
  afficheDeux: boolean;
  champRechercheContactUn: string;
  champRechercheContactDeux: string;
  choix: string;
  suggIdUn: number;
  suggIdDeux: number;
  contactsRechercheUn: Array<Contact>;
  contactsRechercheDeux: Array<Contact>;
  suggestionsDoublons: Array<any> = [];
  suggSocChoix: Array<any> = [];
  contact: Contact;
  oldContact: Contact;
  urlTempUn: string;
  urlTempDeux: string;
  nbCaracteres: number;
  nbCaracteresPremURL: number;
  nbCaracteresDeuxURL: number;
  commentaires: string;
  afficher: string;
  auteurAncienId: number;
  urlLinkedin: string;
  urlTwitter: string;
  urlFacebook: string;
  constructor(private contactService: ContactService,
              private dialogueShareService: DialogueShareService,
              public formatIntranetShareService: FormatIntranetShareService,
              private reseauxSociauxService: ReseauxSociauxService, private coordonneesService: CoordonneesService,
              private parutionService: ParutionService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    const that = this;
    this.visualiser = 'non' ;
    this.structureChoisi = 'numero';
    this.afficheUn = false ;
    this.afficheDeux = false;
    this.contactIdUn = 0;
    this.contactIdDeux = 0;
    this.champRechercheContactUn = '';
    this.champRechercheContactDeux = '';
    this.choix = 'non';
    this.suggIdUn = 0;
    this.suggIdDeux = 0;
    this.afficher = '';
    this.auteurAncienId = 0;
    this.urlLinkedin = '';
    this.urlTwitter = '';
    this.urlFacebook = '';
    // récupérer l'ancien id de l'auteur pour le lien vers sa fiche intranet
    if (this.contact && this.contact.auteur != null) {
      this.contactService.getAncienIdAuteur(this.contact.id).then(retour => {
        if (retour !== 0) {
          that.auteurAncienId = retour;
        }
      });
    }
    /*this.contact = new Contact(0, null, '', '', '', false, false
      , '', '', null, null, null, null,
      new Civilite('', '', ''), new Fonction(0,  '', [], 0),
      new Coordonnees(0, '', '', '', '', '', '', '', '',
        new Pays(0, '', '',  '', null,  null, null), '', ''
        , null, null, null, 0, null, null, null)
      , new Genre(0, '', [], []), [], [], [], [], [], [],
      [], [], [], new ReseauxSociaux(0, '', '' , ''),  []);*/
    // pour l'onglet suggestion
    // récupérer tous les contacts sans la limite de pagination
    this.contactService.getSuggestionDedoublonnage().then((retour) => {
      let contacts = retour['hydra:member'] as Array<Contact>;
      contacts.forEach(contact => {
        contacts = contacts.filter(cont => cont.id !== contact.id);
        const check = contacts.find(({ nom, prenom}) => nom === contact.nom && prenom === contact.prenom);
        // mettre tous les doublons dans un tableau
        if (check !== undefined) {
          // mettre l'original
          this.suggestionsDoublons.push(contact);
          // mettre le doublon
          this.suggestionsDoublons.push(check);
        }
      });
      // retirer les originaux ajoutés plusieurs fois
      this.suggestionsDoublons = this.suggestionsDoublons.filter((x, i) => this.suggestionsDoublons.indexOf(x) === i);
      // créer nouveau tableau avec le compteur total pour chaque doublon
      const arrays = [];
      this.suggestionsDoublons.forEach(cont => {
        let compteur = 0;
        const array = [];
        // s'il n'est pas déjà compté dans le tableau
        const checkPresent = arrays.find(({ nom, prenom}) => nom === cont.nom && prenom === cont.prenom);
        if (checkPresent === undefined) {
          array['id'] = cont.id;
          array['nom'] = cont.nom;
          array['prenom'] = cont.prenom;
          this.suggestionsDoublons.forEach(contBis => {
            if ((cont.nom === contBis.nom) && (cont.prenom === contBis.prenom)) {
              compteur = compteur + 1;
            }
          });
          array['total'] = compteur;
          arrays.push(array);
        }
      });
      this.suggestionsDoublons = arrays;
    });
  }
  rechercheContact(champRecherche, numero) {
    if (numero === 1) {
      this.contactService.getContactRechercheByNom(champRecherche).then(contacts => {
        this.contactsRechercheUn = contacts['hydra:member'] as Array<Contact>;
        this.afficheUn = true ;
      });
    } else {
      this.contactService.getContactRechercheByNom(champRecherche).then(contacts => {
        this.contactsRechercheDeux = contacts['hydra:member'] as Array<Contact>;
        this.afficheDeux = true ;
      });
    }
  }
  verifierDedoublonnage(idUn, idDeux) {
    if (idUn !== undefined && idDeux !== undefined && idUn !== 0 && idDeux !== 0 && idUn !== idDeux ) {
      this.contactService.getContactDetail(idUn).then(contactDoublon => {
        // on récupère les informations du doublon escalnet
        const oldContact = contactDoublon as Contact;
        this.contactService.getContactDetail(idDeux).then(contact => {
          // on récupère le contact escalnet à garder
          const contactMAJ = contact as Contact;
          this.visualiser = 'oui' ;
          let idCoordContactMaj = 0;
          let idReseauxSociaux = 0;
          if (contactMAJ.coordonnees != null) {
            idCoordContactMaj = contactMAJ.coordonnees.id;
          }
          if (contactMAJ.reseauxSociaux != null) {
            idReseauxSociaux = contactMAJ.reseauxSociaux.id;
          }
          // on compare les informations avec celles de l'existant,
          // on met à jour les informations si champ rempli, si champs vide on garde les anciennes
          if (contactMAJ.fonction == null && oldContact.fonction != null) {
            contactMAJ.fonction = oldContact.fonction;
          }
          if (contactMAJ.civilite == null && oldContact.civilite != null) {
            contactMAJ.civilite = oldContact.civilite;
          }
          if (contactMAJ.commentaires == null && contactMAJ.commentaires === '') {
            contactMAJ.commentaires = oldContact.commentaires;
          } else if (contactMAJ.commentaires != null && contactMAJ.commentaires !== ''
            && oldContact.commentaires != null && oldContact.commentaires !== '') {
            contactMAJ.commentaires += ' \n' + oldContact.commentaires;
          }
          if (contactMAJ.objet == null && contactMAJ.objet === '') {
            contactMAJ.objet = oldContact.objet;
          }
          if (contactMAJ.dateConclusion == null && oldContact.dateConclusion != null) {
            contactMAJ.dateConclusion = oldContact.dateConclusion;
          }
          if (contactMAJ.modeEnvoiPref == null && contactMAJ.modeEnvoiPref === '') {
            contactMAJ.modeEnvoiPref = oldContact.modeEnvoiPref;
          }
          if (contactMAJ.piecesJointes === false && oldContact.piecesJointes === true) {
            contactMAJ.piecesJointes = oldContact.piecesJointes;
          }
          if (contactMAJ.sousCategoriesGenerales == null && oldContact.sousCategoriesGenerales != null) {
            contactMAJ.sousCategoriesGenerales = oldContact.sousCategoriesGenerales;
          }
          if (contactMAJ.categoriesGenerales == null && oldContact.categoriesGenerales != null) {
            contactMAJ.categoriesGenerales = oldContact.categoriesGenerales;
          } else if (contactMAJ.categoriesGenerales != null && oldContact.categoriesGenerales != null) {
            oldContact.categoriesGenerales.forEach(categgen => {
              const categgenDejaPresent = contactMAJ.categoriesGenerales.find( (categGen) => categGen.id === categgen.id);
              if (!categgenDejaPresent) {
                contactMAJ.categoriesGenerales.push(categgen);
              }
            });
          }
          if (contactMAJ.sousCategoriesGenerales == null && oldContact.sousCategoriesGenerales != null) {
            contactMAJ.sousCategoriesGenerales = oldContact.sousCategoriesGenerales;
          } else if (contactMAJ.sousCategoriesGenerales != null && oldContact.sousCategoriesGenerales != null) {
            oldContact.sousCategoriesGenerales.forEach(sousCateggen => {
              const categgenDejaPresent = contactMAJ.sousCategoriesGenerales.find( (sousCategGen) => sousCategGen.id === sousCateggen.id);
              if (!categgenDejaPresent) {
                contactMAJ.sousCategoriesGenerales.push(sousCateggen);
              }
            });
          }
          if (contactMAJ.langues == null && oldContact.langues != null) {
            contactMAJ.langues = oldContact.langues;
          } else if (contactMAJ.langues != null && oldContact.langues != null) {
            oldContact.langues.forEach(langue => {
             const langueDejaPresent = contactMAJ.langues.find( (languee) => languee.id === langue.id);
             if (!langueDejaPresent) {
               contactMAJ.langues.push(langue);
             }
            });
          }
          if (contactMAJ.pays == null && oldContact.pays != null) {
            contactMAJ.pays = oldContact.pays;
          } else if (contactMAJ.pays != null && oldContact.pays != null) {
            oldContact.pays.forEach(pays => {
              const paysDejaPresent = contactMAJ.pays.find( (payss) => payss.id === pays.id);
              if (!paysDejaPresent) {
                contactMAJ.pays.push(pays);
              }
            });
          }
          // Societe
          if (contactMAJ.contactOrganisations == null && oldContact.contactOrganisations != null) {
            contactMAJ.contactOrganisations = oldContact.contactOrganisations;
          } else if (contactMAJ.contactOrganisations != null && oldContact.contactOrganisations != null) {
            oldContact.contactOrganisations.forEach(assoOld => {
               const assoDejaPresent = contactMAJ.contactOrganisations
                 .find( (assos) => assos.organisation.id === assoOld.organisation.id);
               if (!assoDejaPresent) {
                 contactMAJ.contactOrganisations.push(assoOld);
                 // Vérification des coordonnées de l'asso contact organisation si l'asso existe
               } else if (assoDejaPresent) {
                 if (assoDejaPresent.premierTel == null || assoDejaPresent.premierTel == '') {
                   assoDejaPresent.premierTel = assoOld.premierTel;
                 }
                 if (assoDejaPresent.deuxiemeTel == null || assoDejaPresent.deuxiemeTel == '') {
                   assoDejaPresent.deuxiemeTel = assoOld.deuxiemeTel;
                 }
                 if (assoDejaPresent.email == null || assoDejaPresent.email == '') {
                   assoDejaPresent.email = assoOld.email;
                 }
               }
                });
          }
          // Supports media
          if (contactMAJ.contactSupports == null && oldContact.contactSupports != null) {
            contactMAJ.contactSupports = oldContact.contactSupports;
          } else if (contactMAJ.contactSupports != null && oldContact.contactSupports != null) {
            oldContact.contactSupports.forEach(assoOld => {
              const assoDejaPresent = contactMAJ.contactSupports
                .find( (assos) => assos.support.id === assoOld.support.id);
              if (!assoDejaPresent) {
                contactMAJ.contactSupports.push(assoOld);
                // Vérification des coordonnées de l'asso contact support si l'asso existe
              } else if (assoDejaPresent) {
                if (assoDejaPresent.fonction == null) {
                  assoDejaPresent.fonction = assoOld.fonction;
                }
                if (assoDejaPresent.premierTel == null || assoDejaPresent.premierTel == '') {
                  assoDejaPresent.premierTel = assoOld.premierTel;
                }
                if (assoDejaPresent.deuxiemeTel == null || assoDejaPresent.deuxiemeTel == '') {
                  assoDejaPresent.deuxiemeTel = assoOld.deuxiemeTel;
                }
                if (assoDejaPresent.email == null || assoDejaPresent.email == '') {
                  assoDejaPresent.email = assoOld.email;
                }
                if (assoDejaPresent.dateDepart == null && assoOld.dateDepart != null) {
                  assoDejaPresent.dateDepart = assoOld.dateDepart;
                }
                if (assoOld.commentaires != null && assoOld.commentaires != '') {
                  assoDejaPresent.commentaires += ' ' + assoOld.commentaires;
                }
              }
            });
          }
          // Réseaux sociaux
          if (idReseauxSociaux > 0) {
            let i = 0;
            let nbCaracteresURL = 0;
            if (contactMAJ.reseauxSociaux != null) {
              if (contactMAJ.reseauxSociaux.linkedinURL == null || contactMAJ.reseauxSociaux.linkedinURL === ''
              && oldContact.reseauxSociaux != null) {
                contactMAJ.reseauxSociaux.linkedinURL = oldContact.reseauxSociaux.linkedinURL;
              }
              if (contactMAJ.reseauxSociaux.twitterURL == null || contactMAJ.reseauxSociaux.twitterURL === ''
                && oldContact.reseauxSociaux != null) {
                contactMAJ.reseauxSociaux.twitterURL = oldContact.reseauxSociaux.twitterURL;
              }
              if (contactMAJ.reseauxSociaux.facebookURL == null || contactMAJ.reseauxSociaux.facebookURL === ''
                && oldContact.reseauxSociaux != null) {
                contactMAJ.reseauxSociaux.facebookURL = oldContact.reseauxSociaux.facebookURL;
              }
              if (contactMAJ.reseauxSociaux.linkedinURL != null && contactMAJ.reseauxSociaux.linkedinURL !== '') {
                nbCaracteresURL = contactMAJ.reseauxSociaux.linkedinURL.length;
                while (i < nbCaracteresURL) {
                  this.urlLinkedin = this.urlLinkedin + contactMAJ.reseauxSociaux.linkedinURL.substr(i, 70) + ' ';
                  i = i + 70;
                }
              }
              i = 0;
              if (contactMAJ.reseauxSociaux.facebookURL != null && contactMAJ.reseauxSociaux.facebookURL !== '') {
                nbCaracteresURL = contactMAJ.reseauxSociaux.facebookURL.length;
                while (i < nbCaracteresURL) {
                  this.urlFacebook = this.urlFacebook + contactMAJ.reseauxSociaux.facebookURL.substr(i, 70) + ' ';
                  i = i + 70;
                }
              }
              i = 0;
              if (contactMAJ.reseauxSociaux.twitterURL != null && contactMAJ.reseauxSociaux.twitterURL !== '') {
                nbCaracteresURL = contactMAJ.reseauxSociaux.twitterURL.length;
                while (i < nbCaracteresURL) {
                  this.urlTwitter = this.urlTwitter + contactMAJ.reseauxSociaux.twitterURL.substr(i, 70) + ' ';
                  i = i + 70;
                }
              }
            }
          } else {
            if (oldContact.reseauxSociaux != null) {
              // affichage de la prévisualisation
              this.urlFacebook = oldContact.reseauxSociaux.facebookURL;
              this.urlTwitter = oldContact.reseauxSociaux.twitterURL;
              this.urlLinkedin = oldContact.reseauxSociaux.linkedinURL;
              contactMAJ.reseauxSociaux = oldContact.reseauxSociaux;
            }
          }
           // Dirigeants
          if (contactMAJ.dirigeants == null && oldContact.dirigeants != null) {
             contactMAJ.dirigeants = oldContact.dirigeants;
           } else if (contactMAJ.dirigeants != null && oldContact.dirigeants != null) {
             oldContact.dirigeants.forEach(dirigeant => {
               contactMAJ.dirigeants.push(dirigeant);
             });
           }
          // Coordonnées personnelles
          if (idCoordContactMaj > 0) {
            if ((contactMAJ.coordonnees.premierTel == null || contactMAJ.coordonnees.premierTel === '')
              &&  oldContact.coordonnees != null) {
              contactMAJ.coordonnees.premierTel = oldContact.coordonnees.premierTel ;
            }
            if ((contactMAJ.coordonnees.deuxiemeTel == null || contactMAJ.coordonnees.deuxiemeTel === '')
              &&  oldContact.coordonnees != null) {
              contactMAJ.coordonnees.deuxiemeTel = oldContact.coordonnees.deuxiemeTel ;
            }
            if ((contactMAJ.coordonnees.email == null || contactMAJ.coordonnees.email === '') &&  oldContact.coordonnees != null) {
              contactMAJ.coordonnees.email = oldContact.coordonnees.email ;
            }
            if ((contactMAJ.coordonnees.rue == null || contactMAJ.coordonnees.rue === '') &&  oldContact.coordonnees != null) {
              contactMAJ.coordonnees.rue = oldContact.coordonnees.rue ;
            }
            if ((contactMAJ.coordonnees.complements == null || contactMAJ.coordonnees.complements === '')
              &&  oldContact.coordonnees != null) {
              contactMAJ.coordonnees.complements = oldContact.coordonnees.complements ;
            }
            if ((contactMAJ.coordonnees.premierURL == null || contactMAJ.coordonnees.premierURL === '')
              &&  oldContact.coordonnees != null) {
              contactMAJ.coordonnees.premierURL = oldContact.coordonnees.premierURL ;
            }
            if ((contactMAJ.coordonnees.deuxiemeURL == null || contactMAJ.coordonnees.deuxiemeURL === '')
              &&  oldContact.coordonnees != null) {
              contactMAJ.coordonnees.deuxiemeURL = oldContact.coordonnees.deuxiemeURL ;
            }
            if ((contactMAJ.coordonnees.boitePostale == null || contactMAJ.coordonnees.boitePostale === '')
              &&  oldContact.coordonnees != null) {
              contactMAJ.coordonnees.boitePostale = oldContact.coordonnees.boitePostale ;
            }
            if ((contactMAJ.coordonnees.codePostal == null || contactMAJ.coordonnees.codePostal === '')
              &&  oldContact.coordonnees != null) {
              contactMAJ.coordonnees.codePostal = oldContact.coordonnees.codePostal ;
            }
            if ((contactMAJ.coordonnees.ville == null || contactMAJ.coordonnees.ville === '') &&  oldContact.coordonnees != null) {
              contactMAJ.coordonnees.ville = oldContact.coordonnees.ville ;
            }
            if (contactMAJ.coordonnees.pays == null && oldContact.coordonnees != null) {
              if (oldContact.coordonnees.pays != null) {
                contactMAJ.coordonnees.pays = oldContact.coordonnees.pays ;
              }
            }
          } else {
            if (oldContact.coordonnees != null) {
              contactMAJ.coordonnees = oldContact.coordonnees;
            }
          }
          // on conserve la date de creation la plus ancienne
          if (contactMAJ.auteur == null) {
            contactMAJ.auteur = oldContact.auteur;
          }
          // on conserve la date de creation la plus ancienne
          if (contactMAJ.creation == null) {
            contactMAJ.creation = oldContact.creation;
          }
          // on conserve l'enrgistrement le plus ancien
          if (contactMAJ.creation == null) {
            contactMAJ.creation = oldContact.creation;
          }
          // on met à jour la date de modification
          contactMAJ.modification = new Date();
          // on sauvegarde temporairement toutes ces modifications
          this.contact = contactMAJ;
          this.oldContact = oldContact;
          // adapter l'affichage du commentaire si trop long
          if (this.contact.commentaires != null) {
            this.nbCaracteres = this.contact.commentaires.length;
            this.commentaires = this.contact.commentaires.substr(0, 150);
          }
          // adapter l'affichage du premier lien si trop long
          if (this.contact.coordonnees != null) {
            if (this.contact.coordonnees.premierURL != null) {
              this.nbCaracteresPremURL = this.contact.coordonnees.premierURL.length;
              let i = 0;
              this.urlTempUn = '';
              while (i < this.nbCaracteresPremURL) {
                this.urlTempUn = this.urlTempUn + this.contact.coordonnees.premierURL.substr(i, 70) + ' ';
                i = i + 70;
              }
            }
          }
          // adapter l'affichage du deuxieme lien si trop long
          if (this.contact.coordonnees != null) {
            if (this.contact.coordonnees.deuxiemeURL != null) {
              this.nbCaracteresDeuxURL = this.contact.coordonnees.deuxiemeURL.length;
              let i = 0;
              this.urlTempDeux = '';
              while (i < this.nbCaracteresDeuxURL) {
                this.urlTempDeux = this.urlTempDeux + this.contact.coordonnees.deuxiemeURL.substr(i, 70) + ' ';
                i = i + 70;
              }
            }
          }
        });
      });
    } else {
      this.dialogueShareService.addDialogue('merci de saisir deux contacts différents');
    }
  }
  retourChoix() {
    this.choix = 'non';
    this.suggSocChoix = [];
  }
  retour() {
    this.visualiser = 'non';
    this.contact = null;
  }
  choixFait(nom) {
    this.suggSocChoix = [];
    this.choix = 'oui';
    this.contactService.getContactRechercheByNom(nom).then((retour) => {
      const arrayTemp = retour['hydra:member'] as Array<Contact>;
      arrayTemp.forEach(contact => {
        // si c'est le nom exact, pour matcher avec le compteur nombre de doublons
        if (contact.nom === nom) {
          this.suggSocChoix.push(contact);
        }
      });
    });
  }
  changerAffichage() {
    if (this.afficher === 'plus') {
      this.commentaires = this.contact.commentaires.substr(0, 150);
      this.afficher = 'moins';
    } else {
      this.commentaires = this.contact.commentaires;
      this.afficher = 'plus';
    }
  }
  extraitNombre(str) {
    return Number(str.replace(/\D/g, ''));
  }
  majContact() {
    this.spinner.show();
    const categgenMAJ = this.contact.categoriesGenerales;
    const sousCateggenMAJ = this.contact.sousCategoriesGenerales;
    const languesMAJ = this.contact.langues;
    const paysMAJ = this.contact.pays;
    const societesMAJ = this.contact.contactOrganisations;
    const supportsMAJ = this.contact.contactSupports;
    let civilite = 0;
    if (this.contact.civilite && this.contact.civilite.id != '') {
      civilite = parseInt(this.contact.civilite.id, 10);
    }
    // Réseaux sociaux
    if (this.urlLinkedin != null || this.urlTwitter != null || this.urlFacebook != null) {
      if (this.contact.reseauxSociaux != null) {
        if (this.urlLinkedin != null && this.urlLinkedin != '') {
          this.contact.reseauxSociaux.linkedinURL = this.urlLinkedin;
        }
        if (this.urlTwitter != null && this.urlTwitter != '') {
          this.contact.reseauxSociaux.twitterURL = this.urlTwitter;
        }
        if (this.urlFacebook != null && this.urlFacebook != '') {
          this.contact.reseauxSociaux.facebookURL = this.urlFacebook;
        }
        this.reseauxSociauxService.modifierResauxSociaux(this.contact.reseauxSociaux.id,
          this.contact.reseauxSociaux.linkedinURL,  this.contact.reseauxSociaux.twitterURL,
          this.contact.reseauxSociaux.facebookURL).then();
      } else {
        this.reseauxSociauxService.addResauxSociaux(this.urlLinkedin, this.urlTwitter, this.urlFacebook)
          .then(reseauxSociaux => {
            this.contact.reseauxSociaux = reseauxSociaux;
          });
      }
    }
    this.contactService.modifierContact(this.contact.id, this.contact, civilite, true).then(async contact => {
      const contactASave = contact as Contact;
      // Coordonnées
      if (this.contact.coordonnees != null) {
        let paysId = 0;
        if (this.contact.coordonnees.pays != null) {
          paysId = this.contact.coordonnees.pays.id;
        }
        // détacher coordonnée à old contact, car elle ne peut être rattachée à 2 contacts
        if (this.oldContact.coordonnees != null) {
          await this.coordonneesService.resetCoordonneesContact(this.oldContact.id).then(() => {
            this.coordonneesService.modifierCoordonnees(this.contact.coordonnees.id, this.contact.coordonnees,
                contactASave.id, paysId).then();
          });
        } else {
          await this.coordonneesService.modifierCoordonnees(this.contact.coordonnees.id, this.contact.coordonnees,
            contactASave.id, paysId).then();
        }
      }
      // catégories générales
      if (categgenMAJ != null) {
        if (contactASave.categoriesGenerales != null && contactASave.categoriesGenerales.length > 0) {
          await this.contactService.deleteContactCategGen(contactASave.id).then(() => {
          categgenMAJ.forEach( categgen => {
            this.contactService.addContactCategGen(contactASave.id, categgen.ancienId).then();
          });
        });
        } else {
          categgenMAJ.forEach( categgen => {
             this.contactService.addContactCategGen(contactASave.id, categgen.ancienId).then();
          });
        }
      }
      // sous catégories générales
      if (sousCateggenMAJ != null) {
        if (contactASave.sousCategoriesGenerales != null && contactASave.sousCategoriesGenerales.length > 0) {
          this.contactService.deleteContactSousCategGen(contactASave.id).then(() => {
            sousCateggenMAJ.forEach( sousCateggen => {
              this.contactService.addContactSousCategGen(contactASave.id, sousCateggen.ancienId).then();
            });
          });
        } else {
          sousCateggenMAJ.forEach( sousCateggen => {
            this.contactService.addContactSousCategGen(contactASave.id, sousCateggen.ancienId).then();
          });
        }
      }
      // langues
      if (languesMAJ != null) {
        if (contactASave.langues != null && contactASave.langues.length > 0) {
          await this.contactService.deleteContactLangues(contactASave.id).then(() => {
            languesMAJ.forEach( langue => {
              this.contactService.addContactLangues(contactASave.id, langue.id).then();
            });
          });
        } else {
          languesMAJ.forEach( langue => {
            this.contactService.addContactLangues(contactASave.id, langue.id).then();
          });
        }
      }
      // pays
      if (paysMAJ != null) {
        if (contactASave.pays != null && contactASave.pays.length > 0) {
          await  this.contactService.deleteContactPays(contactASave.id).then(() => {
            paysMAJ.forEach(pays => {
              this.contactService.addContactPays(contactASave.id, pays.id).then();
            });
          });
        } else {
          paysMAJ.forEach(pays => {
             this.contactService.addContactPays(contactASave.id, pays.id).then();
          });
        }
      }
      // Societe
      if (societesMAJ != null) {
        if (contactASave.contactOrganisations != null && contactASave.contactOrganisations.length > 0) {
          await contactASave.contactOrganisations.forEach(asso => {
            this.contactService.deleteContactOrganisation(asso.id).then(() => {
              societesMAJ.forEach(assoMAJ => {
                let socID;
                socID = this.extraitNombre(assoMAJ.organisation);
                this.contactService.addContactOrganisation(contactASave.id, socID, assoMAJ.premierTel,
                  assoMAJ.deuxiemeTel, assoMAJ.email).then();
              });
            });
          });
        } else {
          societesMAJ.forEach(assoMAJ => {
            let socID;
            socID = this.extraitNombre(assoMAJ.organisation);
            this.contactService.addContactOrganisation(contactASave.id, socID, assoMAJ.premierTel,
              assoMAJ.deuxiemeTel, assoMAJ.email).then();
          });
        }
        // supprimer les asso sociétés avant de supprimer le old contact
        if (this.oldContact.contactOrganisations != null && this.oldContact.contactOrganisations.length > 0) {
          await this.oldContact.contactOrganisations.forEach(asso => {
          this.contactService.deleteContactOrganisation(asso.id).then();
          });
        }
      }
      // Support
      if (supportsMAJ != null) {
        if (contactASave.contactSupports != null && contactASave.contactSupports.length > 0) {
          contactASave.contactSupports.forEach(asso => {
             this.contactService.deleteContactSupport(asso.id).then(() => {
              supportsMAJ.forEach(assoMAJ => {
                if (assoMAJ.fonction != null && assoMAJ.fonction.id > 0) {
                  this.contactService.addContactSupport(contactASave.id, assoMAJ.support.id, assoMAJ.premierTel,
                    assoMAJ.deuxiemeTel, assoMAJ.email, assoMAJ.fonction.id, assoMAJ.participe, assoMAJ.dateDepart,
                    assoMAJ.commentaires, assoMAJ.dernierEmailVerif).then();
                } else {
                  this.contactService.addContactSupport(contactASave.id, assoMAJ.support.id, assoMAJ.premierTel,
                    assoMAJ.deuxiemeTel, assoMAJ.email, 0, assoMAJ.participe, assoMAJ.dateDepart,
                    assoMAJ.commentaires, assoMAJ.dernierEmailVerif).then();
                }
              });
            });
          });
        } else {
          supportsMAJ.forEach(assoMAJ => {
            if (assoMAJ.fonction != null && assoMAJ.fonction.id > 0) {
              this.contactService.addContactSupport(contactASave.id, assoMAJ.support.id, assoMAJ.premierTel,
                assoMAJ.deuxiemeTel, assoMAJ.email, assoMAJ.fonction.id, assoMAJ.participe, assoMAJ.dateDepart,
                assoMAJ.commentaires, assoMAJ.dernierEmailVerif).then();
            } else {
              this.contactService.addContactSupport(contactASave.id, assoMAJ.support.id, assoMAJ.premierTel,
                assoMAJ.deuxiemeTel, assoMAJ.email, 0, assoMAJ.participe, assoMAJ.dateDepart,
                assoMAJ.commentaires, assoMAJ.dernierEmailVerif).then();
            }
            this.contactService.updateContactAssoIntranet('support', assoMAJ.id).then();
          });
        }
        // supprimer les asso supports avant de supprimer le old contact
        if (this.oldContact.contactSupports != null && this.oldContact.contactSupports.length > 0) {
          await this.oldContact.contactSupports.forEach(asso => {
              this.contactService.deleteContactSupport(asso.id).then();
          });
        }
      }
      // Liste contacts
      await this.contactService.getContactListeContactByContactID(this.oldContact.id).then(async retourOldAsso => {
        // Listes de contact associées au contact à supprimer
        const listeOldAsso = retourOldAsso['hydra:member'] as Array<ListeContactContact>;
        await this.contactService.getContactListeContactByContactID(contactASave.id).then(async retourAssoMAJ => {
          // Listes de contact associées au contact à MAJ
          const listeAssoMAJ = retourAssoMAJ['hydra:member'] as Array<ListeContactContact>;
          if (listeAssoMAJ.length === 0 && listeOldAsso.length > 0) {
            for (const oldAsso of listeOldAsso) {
              if (oldAsso.supportPrefere != null) {
                await this.contactService.addListeContactContact(oldAsso.listeContact.id, contactASave.id, oldAsso.supportPrefere.id).then();
              } else {
                await this.contactService.addListeContactContact(oldAsso.listeContact.id, contactASave.id, 0).then();
              }
            }
            // Vérifier si la liste est déjà présent dans le contact à MAJ
          } else if (listeAssoMAJ.length > 0 && listeOldAsso.length > 0) {
            for (const assoOld of listeOldAsso) {
              const assoDejaPresent = listeAssoMAJ
                .find( (assos) => assos.listeContact.id === assoOld.listeContact.id);
              if (!assoDejaPresent) {
                if ( assoOld.supportPrefere != null) {
                  await this.contactService.addListeContactContact(assoOld.listeContact.id, contactASave.id,
                    assoOld.supportPrefere.id).then();
                } else {
                  await this.contactService.addListeContactContact(assoOld.listeContact.id, contactASave.id,
                    0).then();
                }
              } else if (assoDejaPresent) {
                // Vérifier le support préféré
                if ((assoDejaPresent.supportPrefere == null || assoDejaPresent.supportPrefere !== assoOld.supportPrefere)
                && assoOld.supportPrefere != null) {
                  assoDejaPresent.supportPrefere = assoOld.supportPrefere;
                  await this.contactService.updateListeContactContact(assoDejaPresent, assoDejaPresent.supportPrefere)
                    .then();
                }
              }
            }
          }
        });
        // supprimer les asso liste contact avant de supprimer le old contact
        if (listeOldAsso.length > 0) {
        for (const assoOld of listeOldAsso) {
          await this.contactService.deleteListeContactContact(assoOld.id).then();
   }
 }
      });
      // Suivis
      if (this.oldContact.suivis != null) {
        for (const suivi of this.oldContact.suivis) {
          let suiviID;
          suiviID = this.extraitNombre(suivi);
          await this.contactService.updateContactSuvi(contactASave, suiviID).then(() => {
            this.contactService.updateContactSuviIntranet(contactASave.id, suiviID).then();
          });
        }
      }
      // Dirigeants
      if (this.oldContact.dirigeants != null) {
        for (const societe of this.oldContact.dirigeants) {
          let socID;
          socID = this.extraitNombre(societe);
          await this.contactService.updateDirigeantSociete(contactASave, socID).then(() => {
            this.contactService.updateDirigeantSocieteIntranet(contactASave.id, socID).then();
          });
        }
      }
      // sous catégories générales
      if (sousCateggenMAJ != null) {
        if (contactASave.sousCategoriesGenerales != null && contactASave.sousCategoriesGenerales.length > 0) {
          this.contactService.deleteContactSousCategGen(contactASave.id).then(() => {
            sousCateggenMAJ.forEach( sousCateggen => {
              this.contactService.addContactSousCategGen(contactASave.id, sousCateggen.ancienId).then();
            });
          });
        } else {
          sousCateggenMAJ.forEach( sousCateggen => {
            this.contactService.addContactSousCategGen(contactASave.id, sousCateggen.ancienId).then();
          });
        }
      }
      // Parutions
      await this.contactService.getAllParutionsContact(contactASave.ancienId).then(async parutionsMAJ => {
        const parutionsAssociesMAJ = parutionsMAJ['hydra:member'] as Array<Parution>;
        await this.contactService.getAllParutionsContact(this.oldContact.ancienId).then(async parutionsOld => {
          const parutionsAssociesOld = parutionsOld['hydra:member'] as Array<Parution>;
          if (parutionsAssociesOld.length > 0 ) {
            for (const parutionOld of parutionsAssociesOld) {
              const checkPresent = parutionsAssociesMAJ.find((paru) => paru.id === parutionOld.id);
              if (checkPresent === undefined) {
                await this.parutionService.addContactParution(contactASave.id, parutionOld.id).then(() => {
                  // On supprime l'asso contact-parution du contact à supprimer
                  this.contactService.getParutionContacts(parutionOld.id, this.oldContact.id).then(async retour => {
                   const asso = retour['hydra:member'][0] as ParutionContact;
                   await this.parutionService.supprimerAssoContactsParution(asso.id).then();
                  });
                });
              } else {
                await this.contactService.getParutionContacts(parutionOld.id, this.oldContact.id).then(async retour => {
                  const asso = retour['hydra:member'][0] as ParutionContact;
                  await this.parutionService.supprimerAssoContactsParution(asso.id).then();
                });
              }
            }
          }
        });
      });
      // mettre à jour contact dans la base d'intranet
      await this.contactService.importerContactIntranet(contactASave.id, contactASave.ancienId).then(async reponse => {
        // Mettre à jour les infos assos liste de contact
        await this.contactService.getContactListeContactByContactID(contactASave.id).then(async retour => {
          const listeAsso = retour['hydra:member'] as Array<ListeContactContact>;
          if (listeAsso.length > 0) {
            for (const asso of  listeAsso) {
              await this.contactService.updateContactAssoIntranet('liste', asso.id).then();
            }
          }
        });
        // Mettre à jour assos parution
        await this.contactService.getContactParutionByContactID(contactASave.id).then(async response => {
          const listeAsso = response['hydra:member'] as Array<ParutionContact>;
          if (listeAsso.length > 0) {
            for (const asso of listeAsso) {
              await this.contactService.updateContactAssoIntranet('parution', asso.id).then();
            }
          }
        });
        // supprimer contact dans la base intranet
        await this.contactService.deleteContactCopieIntranet(this.oldContact.ancienId).then(async r => {
          // supprimer contact dans la base escalnet
          await this.contactService.deleteContact(this.oldContact.id).then(() => {
            this.visualiser = 'fin';
            this.spinner.hide();
          });
        });
      });
    });
  }
}
