import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../../environement/environement';

@Injectable({ providedIn: 'root' })
export class CategoriesService {
  private CategoriesUrl = Environement.BASE_API_URL + '/api/categorie_des_mots_cles';
  constructor(private http: HttpClient) { }

  getCategories(): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.CategoriesUrl, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getCategorieByID(idCategorie): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.CategoriesUrl + '/' + idCategorie, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
