import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';



@Injectable()
export class ImportListeContactHaService{
    private importCsv = Environement.BASE_API_URL + '/import/liste/contact/ha/';
    constructor(private http: HttpClient) { }

  getTabFromCsv(file): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post(this.importCsv + 'csv' , { 'file': file['value']},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  valideColonne(listeTypeColonne, listeImportHaColonne): Promise<any>{
      let listeTypeColonneString = '';
      listeTypeColonne.forEach((elem) => {
        listeTypeColonneString = listeTypeColonneString + ',' + elem['content'];
      });
      listeTypeColonneString = listeTypeColonneString.substr(1);
      let listeTypeColonneHaString = '';
      listeImportHaColonne.forEach((elem) => {
        listeTypeColonneHaString = listeTypeColonneHaString + ',' + elem['content'];
      });
      listeTypeColonneHaString = listeTypeColonneHaString.substr(1);
      const headers = this.initOptionHeader();
      return this.http.post(this.importCsv + 'valideColonne' , { 'listeColonne': listeTypeColonneString,
        'listeColonneHa': listeTypeColonneHaString},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

    private errorHandler(err: any): Promise<any> {
        return Promise.reject(err.message || err );
    }
    private initOptionHeader() {
        const token: any = localStorage.getItem('auth-tokens');
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.set('X-Auth-Token', token);
        return headers;
    }
}
