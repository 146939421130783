import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {UserGroupeService} from '../../../../../service/interne/user/UserGroupeService';
import {AuthUserService} from '../../../../../service/interne/user/AuthUserService';
import {AuthShareService} from '../../../../../service/partage/AuthShareService';
import {AuthUserGroupeUserService} from '../../../../../service/interne/user/AuthUserGroupeUserService';

import {AuthUser} from '../../../../../model/user/authUser';
import {UserGroupe} from '../../../../../model/user/UserGroupe';
import {AuthUserGroupeUser} from '../../../../../model/user/AuthUserGroupeUser';



@Component({
  selector: 'app-escal-utilisateur-groupe-detail',
  templateUrl: './escal-utilisateur-groupe-detail.component.html',
  styleUrls: ['./escal-utilisateur-groupe-detail.component.scss'],
  providers: [ UserGroupeService, AuthUserService, AuthUserGroupeUserService ],
})
export class EscalUtilisateurGroupeDetailComponent implements OnInit {


  id: number;
  private sub: any;
  userGroupe: UserGroupe;
  ouvrirAddVal: boolean;
  users: Array<AuthUser>;
  usersSelect: Array<AuthUser>;
  userCheck: Array<any>;

  constructor(private router: Router, private route: ActivatedRoute, private userGroupeService: UserGroupeService,
              public authShareService: AuthShareService, private authUserService: AuthUserService,
              private authUserGroupeUserService: AuthUserGroupeUserService) {
  }

  ngOnInit() {
    const that = this;
    this.sub = this.route.params.subscribe(params => {
      that.id = +params['id'];
      that.userGroupeService.getUserGroupeDetail(that.id)
        .then((retour) => {
          that.userGroupe = retour as UserGroupe;
        });
      that.authUserService.getUsersList(1)
        .then((retour1) => {
          that.users = retour1['hydra:member'] as Array<AuthUser>;
          that.users.sort(function compare(a, b) {
            if (a.user.prenom.localeCompare(b.user.prenom) < b.user.prenom.localeCompare(b.user.prenom, 'en', { sensitivity: 'base' })) {
              return -1;
            }
            if (a.user.prenom.localeCompare(b.user.prenom) > b.user.prenom.localeCompare(b.user.prenom, 'en', { sensitivity: 'base' })) {
              return 1;
            }
            return 0;
          });
          that.initClientSelect();
        });
    });
  }

  deleteGroup() {
    const that = this;
    this.userGroupeService.delUserGroupe(that.id)
      .then((retour) => {
        console.log(retour);
        that.router.navigate(['/escal/user/groupe']);
      });
  }

  checkLastElement(index) {
    const nbELement = this.userGroupe.users.length;
    if (nbELement % 2 === 1) {
      if (index === nbELement - 1) {
        return 'lastLiElement lastLiElementLine';
      }
    } else {
      if (index === nbELement - 2 || index === nbELement - 1) {
        return 'lastLiElement';
      }
    }
  }

  initClientSelect() {
    const usersSelectVal: Array<AuthUser> = [];
    this.users.forEach((authUser: AuthUser) => {
      var present = 0;
      this.userGroupe.users.forEach((authUserPresent: AuthUserGroupeUser) => {
        if (authUserPresent.user.id === authUser.id) {
          present = 1;
        }
      });
      if (present === 0) {
        usersSelectVal.push(authUser);
      }
    });
    this.usersSelect = usersSelectVal;
  }

  setSelected(selectElement) {
    const usersCheckTmp: Array<any> = [];
    for (let i = 0; i < selectElement.options.length; i++) {
      if (selectElement.options[i].selected) {
        usersCheckTmp.push(selectElement.options[i].value);
      }
    }
    this.userCheck = usersCheckTmp;
  }

  ajouterUser() {
    const that = this;
    this.ouvrirAddVal = false;
    this.userCheck.forEach((iduser) => {
      that.authUserGroupeUserService.addMember(iduser, that.userGroupe.id)
        .then( (retour) => {
          that.ngOnInit();
        });
    });
  }
  deleteUserGroupe(idAuthUserGroupeUser){
    const that = this;
    this.authUserGroupeUserService.delMember(idAuthUserGroupeUser)
      .then( (retour) => {
        that.ngOnInit();
      });
  }
}
