import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ContactService} from '../../../../service/interne/contact/ContactService';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {Contact} from '../../../../model/contact/Contact';
import {Fonction} from '../../../../model/contact/Fonction';
import {Langues} from '../../../../model/information/Langues';
import {Civilite} from '../../../../model/user/Civilite';
import {CiviliteService} from '../../../../service/interne/user/CiviliteService';
import {LangueService} from '../../../../service/interne/contact/LangueService';
import {FonctionService} from '../../../../service/interne/contact/FonctionService';
import {PaysService} from '../../../../service/interne/contact/PaysService';
import {Pays} from '../../../../model/information/Pays';
import {Coordonnees} from '../../../../model/information/Coordonnees';
import {OrganisationService} from '../../../../service/interne/organisation/OrganisationService';
import {AuthShareService} from '../../../../service/partage/AuthShareService';
import {ReseauxSociaux} from '../../../../model/contact/ReseauxSociaux';
import {CoordonneesService} from '../../../../service/interne/contact/CoordonneesService';
import {ReseauxSociauxService} from '../../../../service/interne/contact/ReseauxSociauxService';
import {GenreService} from '../../../../service/interne/genre/GenreService';
import {Genre} from '../../../../model/contact/Genre';
import {CategGenShareService} from '../../../../service/partage/CategGenShareService';
import {CategoriesGenerales} from '../../../../model/information/CategoriesGenerales';
import {SousCategoriesGenerales} from '../../../../model/information/SousCategoriesGenerales';
import {SousCategGenShareService} from '../../../../service/partage/SousCategGenShareService';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {ListeContact} from '../../../../model/liste/ListeContact';
import {ListeContactService} from '../../../../service/interne/contact/ListeContactService';
import {AuthUser} from "../../../../model/user/authUser";
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-escal-escal-contact-add',
  templateUrl: './escal-contact-add.component.html',
  styleUrls: ['./escal-contact-add.component.scss'],
  providers: [ClientService, ContactService, CiviliteService, PaysService, LangueService, FonctionService,
    OrganisationService, CoordonneesService, ReseauxSociauxService, GenreService,
    CategGenShareService, SousCategGenShareService, ListeContactService]
})
export class EscalContactAddComponent implements OnInit {

  id: number;
  private sub: any;
  contact: Contact;
  idOrganisationSelected: number;
  fonctionsIntranet: Array<any> = [];
  societeRechercheIntranet: Array<any> = [];
  fonctions: Array<Fonction> = [];
  genres: Array<Genre> = [];
  civilites: Array<Civilite>;
  codePostal: string;
  boitePostal: string;
  champRechercheFonction = '';
  champRechercheSociete = '';
  champRechercheCategGen = '';
  champRechercheSousCategGen = '';
  listeCategGenBase: Array<CategoriesGenerales>;
  categGenIntranet: Array<any> = [];
  listeCategGenChoix: Array<any> = [];
  rechercheCategGenEncours = false;
  idCategGenSelectionnees: Array<any>;
  idCategGenASauvegarder: Array<any>;
  categGenSelectionner: CategoriesGenerales;
  listeCategGenASauvegarder: Array<CategoriesGenerales> = [];
  sousCategGenIntranet: Array<any> = [];
  listeSousCategGenChoix: Array<any> = [];
  rechercheSousCategGenEncours = false;
  idSousCategGenSelectionnees: Array<any>;
  idSousCategGenASauvegarder: Array<any>;
  sousCategGenSelectionner: SousCategoriesGenerales;
  listeSousCategGenASauvegarder: Array<SousCategoriesGenerales> = [];
  rechercheFonctionEncours = false;
  rechercheSocieteEncours = false;
  listeLanguesBase: Array<Langues>;
  idLanguesSelectionnees: Array<any>;
  idLanguesASauvegarder: Array<any>;
  langueSelectionner: Langues;
  listeLanguesASauvegarder: Array<Langues> = [];
  afficheFonction: boolean;
  afficheSociete: boolean;
  affichePays: boolean;
  ancienIDFoncSelec: number;
  idCoordPersoPays: Pays;
  listePaysBase: Array<Pays>;
  listePaysBaseCoordPerso: Array<Pays>;
  idPaysSelectionnees: Array<any>;
  idPaysASauvegarder: Array<any>;
  paysSelectionner: Pays;
  listePaysASauvegarder: Array<Pays> = [];
  coordonneesContact: Coordonnees;
  telPro1: string;
  telPro2: string;
  emailPro: string;
  telPerso: string;
  telGsm: string;
  rueVoie: string;
  ville: string;
  url1: string;
  url2: string;
  reseauxSociaux: ReseauxSociaux;
  facebookURL: string;
  twitterURL: string;
  linkedinURL: string;
  complement: string;
  emailPerso: string;
  doublonsRecherche: Array<any> = [];
  structureChoisi: string;
  ouvert = 0;
  civiliteId: number;
  authUser: AuthUser;
  creer: boolean;
  idContact: number;
  constructor(private route: ActivatedRoute, private contactService: ContactService,
              private langueService: LangueService, private fonctionService: FonctionService,
              private clientService: ClientService, private router: Router, private civiliteService: CiviliteService,
              private paysService: PaysService, private organisationService: OrganisationService,
              private coordonneesService: CoordonneesService, private authShareService: AuthShareService,
              private reseauxSociauxService: ReseauxSociauxService, private genreService: GenreService,
              private categGenShareService: CategGenShareService, private sousCategGenShareService: SousCategGenShareService,
              private dialogueShareService: DialogueShareService, public formatIntranetShareService: FormatIntranetShareService,
              private listeContactService: ListeContactService, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    const that = this;
    this.creer = false;
    this.idContact = 0;
    this.ancienIDFoncSelec = 0;
    this.afficheFonction = false;
    this.affichePays = false;
    this.afficheSociete = false;
    this.codePostal = '';
    this.telPro1 = '';
    this.telPro2 = '';
    this.emailPro = '';
    this.telPerso = '';
    this.telGsm = '';
    this.rueVoie = '';
    this.url1 = '';
    this.url2 = '';
    this.facebookURL = '';
    this.twitterURL = '';
    this.linkedinURL = '';
    this.structureChoisi = 'création';
    this.civiliteId = 0;
    this.authUser = this.authShareService.authUser;
    this.idCoordPersoPays = new Pays(0, '', '',  '', null,  null, null);
    this.coordonneesContact = new Coordonnees(0, '', '', '', '', '', '', '', '', null,
      '', '', this.authShareService.authUser, new Date(), null, 0, null, null, null);
    this.contact = new Contact(that.id, null, '', '', '', false, false
      , '', '', null, this.authShareService.authUser, new Date(), null, null, null, this.coordonneesContact
      , null, [], [], [], [], [], [], []
      , [], [], null,  []);
    this.langueService.getLangues().then(retour => {
      this.listeLanguesBase = retour['hydra:member'] as Array<Langues>;
    });
    this.paysService.getPays().then(retour => {
      this.listePaysBase = retour['hydra:member'] as Array<Pays>;
      this.listePaysBaseCoordPerso = retour['hydra:member'] as Array<Pays>;
    });
    this.civiliteService.getCivilites()
      .then((retour) => {
        that.civilites = retour['hydra:member'];
      });
    this.fonctionService.getFonctions().then(retour => {
      this.fonctions = retour['hydra:member'] as Array<Fonction>;
    });
    this.genreService.getGenres().then(retour => {
      this.genres = retour['hydra:member'] as Array<Genre>;
    });
    this.categGenShareService.getCategoriesGenerales().then(retour => {
      this.listeCategGenBase = retour['hydra:member'] as Array<CategoriesGenerales>;
    });
  }

  rechercheSociete() {
    this.afficheSociete = true;
    if (this.champRechercheSociete.length > 2 && !this.rechercheSocieteEncours) {
      this.rechercheSocieteEncours = true;
      this.champRechercheSociete = this.champRechercheSociete.replace(' ', '.');
      this.organisationService.getSocieteRecherche(this.champRechercheSociete).then((retour) => {
        this.societeRechercheIntranet = retour;
        this.societeRechercheIntranet.forEach(societeTrouve => {
          if (societeTrouve.nom != '' && societeTrouve.nom != null) {
            societeTrouve.nom = societeTrouve.nom.replace('&amp;', '&');
            societeTrouve.nom = societeTrouve.nom.replace('&#039;', '’');
            societeTrouve.nom = societeTrouve.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheSociete = this.champRechercheSociete.replace('.', ' ');
      });
      this.rechercheSocieteEncours = false;
    }
  }

  rechercheFonction() {
    this.afficheFonction = true;
    if (this.champRechercheFonction.length > 2 && !this.rechercheFonctionEncours) {
      this.rechercheFonctionEncours = true;
      this.champRechercheFonction = this.champRechercheFonction.replace(' ', '.');
      this.fonctionService.getFonctionsIntranet(this.champRechercheFonction).then((retour) => {
        this.fonctionsIntranet = retour;
        this.fonctionsIntranet.forEach(fonctionTrouve => {
          if (fonctionTrouve.libelle != '' && fonctionTrouve.libelle != null) {
            fonctionTrouve.libelle = fonctionTrouve.libelle.replace('&amp;', '&');
            fonctionTrouve.libelle = fonctionTrouve.libelle.replace('&#039;', '’');
            fonctionTrouve.libelle = fonctionTrouve.libelle.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheFonction = this.champRechercheFonction.replace('.', ' ');
      });
    }
    this.rechercheFonctionEncours = false;
  }

  rechercheCategorieGenerale() {
    if (this.champRechercheCategGen.length > 2 && !this.rechercheCategGenEncours) {
      this.rechercheCategGenEncours = true;
      this.categGenShareService.getCategGenIntranet(this.champRechercheCategGen).then((retour) => {
        this.categGenIntranet = retour;
        this.listeCategGenChoix = retour;
        // éviter les doublons dans la liste de selection en cas d'une nouvelle recherche
        if (this.listeCategGenASauvegarder.length > 0) {
          this.listeCategGenASauvegarder.forEach(categGenASave => {
            this.listeCategGenChoix =
              this.listeCategGenChoix.filter(categGenChoix => categGenChoix.id !== categGenASave.id);
          });
        }
      });
    }
    this.rechercheCategGenEncours = false;
  }

  rechercheSousCategorieGenerale() {
    if (this.champRechercheSousCategGen.length > 2 && !this.rechercheSousCategGenEncours) {
      this.rechercheSousCategGenEncours = true;
      this.sousCategGenShareService.getSousCategGenIntranet(this.champRechercheSousCategGen).then((retour) => {
        this.sousCategGenIntranet = retour;
        this.listeSousCategGenChoix = retour;
        // éviter les doublons dans la liste de selection en cas d'une nouvelle recherche
        if (this.listeSousCategGenASauvegarder.length > 0) {
          this.listeSousCategGenASauvegarder.forEach(sousCategGenASave => {
            this.listeSousCategGenChoix =
              this.listeSousCategGenChoix.filter(sousCategGenChoix => sousCategGenChoix.id !== sousCategGenASave.id);
          });
        }
      });
    }
    this.rechercheSousCategGenEncours = false;
  }

  extraitNombre(str) {
    return Number(str.replace(/\D/g, ''));
  }

  setSelected(selectElement, action, nature) {
    const groupe: Array<any> = [];
    let optionSelected;
    for (let i = 0; i < selectElement.options.length; i++) {
      if (selectElement.options[i].selected) {
        optionSelected = selectElement.options[i].value;
        // Le format de value est de [0: '1']
        if (optionSelected.indexOf('\'') !== -1) {
          // extraire le vrai id sauvegarder
          optionSelected = optionSelected.indexOf('\'');
          optionSelected = selectElement.options[i].value.slice(optionSelected);
          optionSelected = this.extraitNombre(optionSelected);
          optionSelected = optionSelected.toString();
        }
        groupe.push(optionSelected);
      }
    }
    if (action === 'ajout') {
      switch (nature) {
        case 'langue':
          this.idLanguesSelectionnees = groupe;
          break;
        case 'pays':
          this.idPaysSelectionnees = groupe;
          break;
        case 'categgen':
          this.idCategGenSelectionnees = groupe;
          break;
        case 'souscateggen':
          this.idSousCategGenSelectionnees = groupe;
          break;
      }
    } else if (action === 'sup') {
      switch (nature) {
        case 'langue':
          this.idLanguesASauvegarder = groupe;
          break;
        case 'pays':
          this.idPaysASauvegarder = groupe;
          break;
        case 'categgen':
          this.idCategGenASauvegarder = groupe;
          break;
        case 'souscateggen':
          this.idSousCategGenASauvegarder = groupe;
          break;
      }
    }
  }

  changeLangue(action) {
    const that = this;
    let nouvelleListeLangue;
    let listeLangue;
    let languePresent;
    if (action === 'add') {
      listeLangue = this.idLanguesSelectionnees;
    } else {
      listeLangue = this.idLanguesASauvegarder;
    }
    listeLangue.forEach((idLangueSelectionnees) => {
      that.langueService.getLangueByID(idLangueSelectionnees).then(retour => {
        that.langueSelectionner = retour as Langues;
        if (action === 'add') {
          // retirer la(es) langues sélectionnée(s) dans la liste de langue de base
          nouvelleListeLangue = this.listeLanguesBase.filter((langue) => langue.id !== that.langueSelectionner.id);
          this.listeLanguesBase = nouvelleListeLangue;
          // évite les doublons
          languePresent = this.listeLanguesASauvegarder.find((langue) => langue.id === that.langueSelectionner.id);
          if (languePresent === undefined) {
            this.listeLanguesASauvegarder.push(that.langueSelectionner);
          }
        } else if (action === 'delete') {
          // retirer la(es) langues sélectionnée(s) dans la liste de langue à sauvegarder
          nouvelleListeLangue = this.listeLanguesASauvegarder.filter((langue) => langue.id !== that.langueSelectionner.id);
          this.listeLanguesASauvegarder = nouvelleListeLangue;
          // évite les doublons
          languePresent = this.listeLanguesBase.find((langue) => langue.id === that.langueSelectionner.id);
          if (languePresent === undefined) {
            this.listeLanguesBase.push(that.langueSelectionner);
          }
        }
      });
    });
  }

  changePays(action) {
    const that = this;
    let nouvelleListePays;
    let listePays;
    let paysPresent;
    if (action === 'add') {
      listePays = this.idPaysSelectionnees;
    } else {
      listePays = this.idPaysASauvegarder;
    }
    listePays.forEach((idPaysSelectionnees) => {
      that.paysService.getPaysDetail(idPaysSelectionnees).then(retour => {
        that.paysSelectionner = retour as Pays;
        if (action === 'add') {
          // retirer la(es) pays sélectionnée(s) dans la liste de langue de base
          nouvelleListePays = this.listePaysBase.filter((pays) => pays.id !== that.paysSelectionner.id);
          this.listePaysBase = nouvelleListePays;
          // évite les doublons
          paysPresent = this.listePaysASauvegarder.find((pays) => pays.id === that.paysSelectionner.id);
          if (paysPresent === undefined) {
            this.listePaysASauvegarder.push(that.paysSelectionner);
          }
        } else if (action === 'delete') {
          // retirer la(es) pays sélectionnée(s) dans la liste de langue à sauvegarder
          nouvelleListePays = this.listePaysASauvegarder.filter((pays) => pays.id !== that.paysSelectionner.id);
          this.listePaysASauvegarder = nouvelleListePays;
          // évite les doublons
          paysPresent = this.listePaysBase.find((pays) => pays.id === that.paysSelectionner.id);
          if (paysPresent === undefined) {
            this.listePaysBase.push(that.paysSelectionner);
          }
        }
      });
    });
  }

  changeCategGen(action) {
    let listeCategGen;
    let categGenPresent;
    let categGenDansSave;
    if (action === 'add') {
      listeCategGen = this.idCategGenSelectionnees;
    } else {
      listeCategGen = this.idCategGenASauvegarder;
    }
    // Gestion de la liste à sauvegarder et la liste de selection
    listeCategGen.forEach((idCategGenSelectionnees) => {
      this.categGenIntranet.forEach(categGen => {
        if (idCategGenSelectionnees == categGen.id) {
          this.categGenSelectionner = categGen as CategoriesGenerales;
          this.listeCategGenChoix =
            this.listeCategGenChoix.filter(categGenSave => categGenSave.id !== idCategGenSelectionnees);
        } else {
          // retirer la(es) categGen sélectionnée(s) dans la liste de categGen à sauvegarder
          this.listeCategGenASauvegarder =
            this.listeCategGenASauvegarder.filter(categGenSave => categGenSave.id !== idCategGenSelectionnees);
        }
      });
      if (action === 'add') {
        // retirer la(es) categGen sélectionnée(s) dans la liste de categGen de base
        // évite les doublons
        categGenPresent = this.listeCategGenASauvegarder.find((categGen) => categGen.id === this.categGenSelectionner.id);
        if (categGenPresent === undefined) {
          this.listeCategGenASauvegarder.push(this.categGenSelectionner);
        }
      } else if (action === 'delete') {
        // évite les doublons
        categGenPresent = this.listeCategGenChoix.find((categGen) => categGen.id == this.categGenSelectionner.id);
        categGenDansSave = this.listeCategGenASauvegarder.find(cateGen => cateGen.id == this.categGenSelectionner.id);
        if (categGenPresent === undefined && categGenDansSave === undefined) {
          this.listeCategGenChoix.push(this.categGenSelectionner);
        }
      }
    });
  }

  changeSousCategGen(action) {
    let listeSousCategGen;
    let sousCategGenPresent;
    let sousCategGenDansSave;
    if (action === 'add') {
      listeSousCategGen = this.idSousCategGenSelectionnees;
    } else {
      listeSousCategGen = this.idSousCategGenASauvegarder;
    }
    // Gestion de la liste à sauvegarder et la liste de selection
    listeSousCategGen.forEach((idSousCategGenSelectionnees) => {
      this.sousCategGenIntranet.forEach(sousCategGen => {
        if (idSousCategGenSelectionnees == sousCategGen.id) {
          this.sousCategGenSelectionner = sousCategGen as SousCategoriesGenerales;
          this.listeSousCategGenChoix =
            this.listeSousCategGenChoix.filter(sousCategGenSave => sousCategGenSave.id !== idSousCategGenSelectionnees);
        } else {
          // retirer la(es) categGen sélectionnée(s) dans la liste de categGen à sauvegarder
          this.listeSousCategGenASauvegarder =
            this.listeSousCategGenASauvegarder.filter(sousCategGenSave => sousCategGenSave.id !== idSousCategGenSelectionnees);
        }
      });
      if (action === 'add') {
        // retirer la(es) categGen sélectionnée(s) dans la liste de categGen de base
        // évite les doublons
        sousCategGenPresent = this.listeSousCategGenASauvegarder.find((sousCategGen) =>
          sousCategGen.id === this.sousCategGenSelectionner.id);
        if (sousCategGenPresent === undefined) {
          this.listeSousCategGenASauvegarder.push(this.sousCategGenSelectionner);
        }
      } else if (action === 'delete') {
        // retirer la(es) categGen sélectionnée(s) dans la liste de categGen à sauvegarder
        // évite les doublons
        sousCategGenPresent = this.listeSousCategGenChoix.find((sousCategGen) => sousCategGen.id == this.sousCategGenSelectionner.id);
        sousCategGenDansSave = this.listeSousCategGenASauvegarder.find(sousCategGen => sousCategGen.id == this.sousCategGenSelectionner.id);
        if (sousCategGenPresent === undefined && sousCategGenDansSave === undefined) {
          this.listeSousCategGenChoix.push(this.sousCategGenSelectionner);
        }
      }
    });
  }

  async ajoutFonction(idFonction) {
    const that = this;
    let fonctSelec;
    let fonctionTrouve;
    // récupérer la fonction seléctionée
    this.fonctionsIntranet.forEach(fonctionIntranet => {
      if (fonctionIntranet.ancienId == idFonction) {
        fonctSelec = fonctionIntranet as Fonction;
        return;
      }
    });
    // On vérifie si la fonction existe déjà sur la base escalnet
    await this.fonctionService.getFonctionsEscalnetbyAncienID(fonctSelec.ancienId).then(retour => {
      fonctionTrouve = retour['hydra:member'];
      // si la fonction n'existe pas, alors on crée sur la base escalnet
      if (fonctionTrouve.length == 0) {
        this.fonctionService.addFonction(fonctSelec).then();
      } else {
        this.contact.fonction = fonctionTrouve[0];
      }
    });
    if (this.contact.fonction == null) {
      this.fonctionService.getFonctionsEscalnetbyAncienID(this.ancienIDFoncSelec).then(fonctionSelec => {
        that.contact.fonction = fonctionSelec['hydra:member'][0];
      });
    }
  }

  async ajoutCategGen(CategGen, contactID = null) {
    const that = this;
    let categGenTrouve;
    // On vérifie si la catégorie générale existe déjà sur la base escalnet
    await this.categGenShareService.getCategGensbyAncienID(CategGen.id).then(async retour => {
      categGenTrouve = retour['hydra:member'];
      // si la catégorie générale n'existe pas, alors on crée sur la base escalnet
      if (categGenTrouve.length == 0) {
        await that.categGenShareService.addCategGen(CategGen).then(categGenCree => {
          // Seulement dans le cas d'une mise à jour de contact
          if (contactID !== null) {
            this.contactService.addContactCategGen(contactID, categGenCree.ancienId);
          }
        });
      } else if (contactID === null) {
        that.contact.categoriesGenerales = categGenTrouve;
        // Seulement dans le cas d'une mise à jour de contact
      } else {
        await this.contactService.addContactCategGen(contactID, CategGen.id);
      }
    });
  }

  async ajoutSousCategGen(sousCategGen, contactID = null) {
    const that = this;
    let sousCategGenTrouve;
    // On vérifie si la sous catégorie générale existe déjà sur la base escalnet
    await this.sousCategGenShareService.getSousCategGensbyAncienID(sousCategGen.id).then(async retour => {
      sousCategGenTrouve = retour['hydra:member'];
      // si la sous catégorie générale n'existe pas, alors on crée sur la base escalnet
      if (sousCategGenTrouve.length == 0) {
        await that.sousCategGenShareService.addSousCategGen(sousCategGen).then(sousCategGenCree => {
          // Seulement dans le cas d'une mise à jour de contact
          if (contactID !== null) {
            this.contactService.addContactSousCategGen(contactID, sousCategGenCree.ancienId);
          }
        });
      } else if (contactID === null) {
        that.contact.sousCategoriesGenerales = sousCategGenTrouve;
        // Seulement dans le cas d'une mise à jour de contact
      } else {
        await this.contactService.addContactSousCategGen(contactID, sousCategGen.id);
      }
    });
  }

  async verifierContactDoublon() {
    if (this.contact.nom != '' && this.contact.prenom != '') {
      if (this.contact.genre !== null) {
        // gérer cas caractère spéciaux envoie api : espace et slash
        let nomContact = this.contact.prenom + '~~' + this.contact.nom;
        nomContact = nomContact.replace(' ', '~~');
        nomContact = nomContact.replace('/', '@@@');
        // chercher potentiels doublons dans la base intranet
        this.contactService.getDoublonsSuggestion(nomContact).then((retour) => {
          this.doublonsRecherche = retour;
          // si aucun doublon trouvé
          if (this.doublonsRecherche.length === 0) {
            this.creerContact();
          } else {
            // s'il y a au moins un doublon
            this.structureChoisi = 'doublon';
            this.doublonsRecherche.forEach(doublon => {
              // vérifier si doublon déjà dans la base escalnet ou non
              this.contactService.getDoublonEscalnetSiExiste(doublon.id).then((doublonEscalnet) => {
                if (doublonEscalnet['hydra:member'].length >= 1) {
                  doublon.escalnet = 'existe';
                }
              });
              if (doublon.nom != '' && doublon.nom != null) {
                // remplacer caractères reçus de l'api
                doublon.nom = doublon.nom.replace('&amp;', '&');
                doublon.nom = doublon.nom.replace('&#039;', '’');
                doublon.nom = doublon.nom.replace('L&#039;', 'L’');
              }
              if (doublon.prenom != '' && doublon.prenom != null) {
                // remplacer caractères reçus de l'api
                doublon.prenom = doublon.prenom.replace('&amp;', '&');
                doublon.prenom = doublon.prenom.replace('&#039;', '’');
                doublon.prenom = doublon.prenom.replace('L&#039;', 'L’');
              }
            });
          }
        });
      } else {
        this.dialogueShareService.addDialogue('Le genre est obligatoire');
      }
    } else {
      this.dialogueShareService.addDialogue('Le nom et le prénom sont obligatoires');
    }
  }

  async creerContact() {
    const that = this;
    // Récupérer les infos saisies coordonnées du contact
    this.coordonneesContact.pays = this.idCoordPersoPays;

    // Seulement si on sélectionne une fonction
    if (this.ancienIDFoncSelec > 0) {
      // Ajout d'une nouvelle fonction si jamais elle n'existe pas dans la base escalnet
      await this.ajoutFonction(this.ancienIDFoncSelec).then();
      if (this.contact.fonction == null) {
        await this.fonctionService.getFonctionsEscalnetbyAncienID(this.ancienIDFoncSelec).then(fonctionSelec => {
          that.contact.fonction = fonctionSelec['hydra:member'][0];
        });
      }
    }
    // Seulement s'il y a une catégorie générale
    if (this.listeCategGenASauvegarder.length > 0) {
      for (const CategGen of this.listeCategGenASauvegarder) {
        await this.ajoutCategGen(CategGen).then();
      }
    }
    // Seulement s'il y a une sous catégorie générale
    if (this.listeSousCategGenASauvegarder.length > 0) {
      for (const sousCategGen of this.listeSousCategGenASauvegarder) {
        await this.ajoutSousCategGen(sousCategGen).then();
      }
    }
    // s'il faut au moins un champ de coordonnée est saisi
    if (this.coordonneesContact.premierTel != '' || this.coordonneesContact.deuxiemeTel != ''
      || (this.coordonneesContact.email != '' && this.coordonneesContact.email != null)
      || this.coordonneesContact.rue != '' || (this.coordonneesContact.complements != '' && this.coordonneesContact.complements != null)
      || (this.coordonneesContact.boitePostale !== '' && this.coordonneesContact.boitePostale != null)
      || this.coordonneesContact.codePostal != '' || (this.coordonneesContact.ville != undefined
        && this.coordonneesContact.ville != '' && this.coordonneesContact.ville != null)
      || (this.idCoordPersoPays != null && this.idCoordPersoPays.id > 0)) {
      await this.coordonneesService.addCoordonnees(that.coordonneesContact).then(coordonnes => {
        this.contact.coordonnees = coordonnes;
      });
    } else  {
      this.contact.coordonnees = null;
    }
    // ajout seulement s'il y au moins 1 reseau social
    if (this.linkedinURL.length > 0 || this.facebookURL.length > 0 || this.twitterURL.length > 0) {
      await this.reseauxSociauxService.addResauxSociaux(this.linkedinURL, this.twitterURL, this.facebookURL)
        .then(reseauxSociaux => {
          this.contact.reseauxSociaux = reseauxSociaux;
        });
    }
    // Ajout des ASSOCIATIONS
    this.contactService.addContact(this.contact, this.civiliteId).then(async contact => {
      // Seulement s'il y a asso contact Organisation
      if (this.idOrganisationSelected > 0) {
        // Vérifier si la société existe déjà sur escalnet, sinon on l'importe
         await this.organisationService.importerOrganisation(this.idOrganisationSelected).then(() => {
          this.organisationService.getDoublonEscalnetSiExiste(this.idOrganisationSelected).then(societe => {
            const socId = societe['hydra:member'][0].id;
            // on crée l'asso avec les coordonnées
            this.contactService.addContactOrganisation(contact.id, socId, this.telPro1,
              this.telPro2, this.emailPro);
          });
        });
      }
      // Ajout des langues au contact s'il y en 1 ou plusieurs
      if (this.listeLanguesASauvegarder.length > 0) {
        this.listeLanguesASauvegarder.forEach(idLangues => {
          this.contactService.addContactLangues(contact.id, idLangues.id);
        });
      }
      // Ajout des pays au contact s'il y en 1 ou plusieurs
      if (this.listePaysASauvegarder.length > 0) {
        this.listePaysASauvegarder.forEach(idPays => {
          this.contactService.addContactPays(contact.id, idPays.id);
        });
      }
      // Ajout asso catégories générales au contact s'il au moins 1
      if (this.listeCategGenASauvegarder.length > 0) {
        this.listeCategGenASauvegarder.forEach(categGen => {
          this.contactService.addContactCategGen(contact.id, categGen.id);
        });
      }
      // Ajout asso sous catégories générales au contact s'il au moins 1
      if (this.listeSousCategGenASauvegarder.length > 0) {
        this.listeSousCategGenASauvegarder.forEach(sousCategGen => {
          this.contactService.addContactSousCategGen(contact.id, sousCategGen.id);
        });
      }
      this.contactService.importerContactIntranet(contact.id).then(() => {
        this.idContact = contact.id;
        this.creer = true;
        this.dialogueShareService.addDialogue('Le contact est bien créé');
      });

    });
  }

  retourCreerContact() {
    this.structureChoisi = 'contact';
    this.doublonsRecherche = [];
  }

  elemOuvert(id) {
    if (this.ouvert === id) {
      this.ouvert = 0;
    } else {
      this.ouvert = id;
    }
  }

  elementIsOuvert(id) {
    if (this.ouvert === id) {
      return true;
    }
  }

  importerContact(contactID) {
    // importer le contact
    this.spinner.show();
    this.contactService.importerContact(contactID).then(() => {
      // redirection
      this.verifierContactDoublon().then(() => {
        this.spinner.hide();
        this.dialogueShareService.addDialogue('Le contact a bien été importée');
      });
    });
  }

  async majContact(contactID) {
    this.spinner.show();
    // mettre à jour le contact
    // On récupère les informations du doublon intranet, les informations sont peut-etre + à jour que celui deja dans la base escalnet
    this.contactService.getAllInformationsContact(contactID).then((contactDoublon) => {
      const oldContact = contactDoublon;
      let linkedin = this.linkedinURL;
      let facebook = this.facebookURL;
      let twitter = this.twitterURL;
      // on récupère le contact escalnet
      this.contactService.getDoublonEscalnetSiExiste(contactID).then(async (contact) => {
        const idContactMAJ = contact['hydra:member'][0].id;
        const ancienIDContactMAJ = contact['hydra:member'][0].ancienId;
        let idCoordContactMaj = 0;
        if (contact['hydra:member'][0].coordonnees != null) {
          idCoordContactMaj = contact['hydra:member'][0].coordonnees.id;
        }
        // on compare les informations avec celles de l'existant,
        // on met à jour les informations si champ rempli, si champs vide on garde les anciennes
        if (this.contact.commentaires == null || this.contact.commentaires === '') {
          this.contact.commentaires = oldContact.comments;
        }
        if (this.contact.modeEnvoiPref == null || this.contact.modeEnvoiPref === '') {
          this.contact.modeEnvoiPref = oldContact.lib_mode_envoi;
        }
        if (this.contact.piecesJointes == null) {
          this.contact.piecesJointes = oldContact.piecejointe;
        }
        if (this.contact.objet == null || this.contact.objet === '') {
          this.contact.objet = oldContact.objet;
        }
        if (this.ancienIDFoncSelec == null || this.ancienIDFoncSelec === 0) {
          let fonctionTrouve;
          const fonctionIntranet = new Fonction(null, oldContact.lib_fonction, null, oldContact.ID_fonction);
          await this.fonctionService.getFonctionsEscalnetbyAncienID(oldContact.ID_fonction).then(async retour => {
            fonctionTrouve = retour['hydra:member'];
            // si la fonction n'existe pas, alors on crée sur la base escalnet
            if (fonctionTrouve.length == 0) {
              await this.fonctionService.addFonction(fonctionIntranet, 'oui').then(fonctionCree => {
                this.contact.fonction = fonctionCree;
              });
            } else {
              this.contact.fonction = fonctionTrouve[0];
            }
          });
        } else {
          await this.ajoutFonction(this.ancienIDFoncSelec).then();
        }
        if (this.contact.genre == null) {
          this.contact.genre = oldContact.ID_type;
        }
        if (this.civiliteId == 0) {
          if (oldContact.civilite == 0) {
            this.contact.civilite = null;
          } else {
            this.contact.civilite = oldContact.civilite;
          }
        }
        // Si l'un des champs du formulaire est vide, on check si il y a des données sur l'intranet
        // on récupère les reseaux sociaux du contact sur intranet via l'ancien id contact
        await this.contactService.getReseauxSociauxBy(oldContact.id).then(retour => {
          const reseauxSociauxOldCont = retour;
          if (this.linkedinURL.length == 0) {
            linkedin = reseauxSociauxOldCont.Linkedin;
          }
          if (this.facebookURL.length == 0) {
            facebook = reseauxSociauxOldCont.Facebook;
          }
          if (this.twitterURL.length == 0) {
            twitter = reseauxSociauxOldCont.Twitter;
          }
        });
        await this.reseauxSociauxService.addResauxSociaux(linkedin, twitter, facebook)
          .then(reseauxSociaux => {
            this.contact.reseauxSociaux = reseauxSociaux;
          });
        // coordonnees contact
        if (this.coordonneesContact.premierTel === undefined || this.coordonneesContact.premierTel === '') {
          this.coordonneesContact.premierTel = oldContact.tel;
        }
        if (this.coordonneesContact.deuxiemeTel === undefined || this.coordonneesContact.deuxiemeTel === '') {
          this.coordonneesContact.deuxiemeTel = oldContact.tel_gsm;
        }
        if (this.coordonneesContact.email == null || this.coordonneesContact.email === '') {
          this.coordonneesContact.email = oldContact.email;
        }
        if (this.coordonneesContact.rue == null || this.coordonneesContact.rue === '') {
          this.coordonneesContact.rue = oldContact.adresse1;
        }
        if (this.coordonneesContact.complements == null || this.coordonneesContact.complements === '') {
          this.coordonneesContact.complements = oldContact.adresse2;
        }
        if (this.coordonneesContact.premierURL == null || this.coordonneesContact.premierURL === '') {
          this.coordonneesContact.premierURL = oldContact.web1;
        }
        if (this.coordonneesContact.deuxiemeURL == null || this.coordonneesContact.deuxiemeURL === '') {
          this.coordonneesContact.deuxiemeURL = oldContact.web2;
        }
        if (this.coordonneesContact.boitePostale == null || this.coordonneesContact.boitePostale === '') {
          this.coordonneesContact.boitePostale = oldContact.bpostal;
        }
        if (this.coordonneesContact.codePostal == null || this.coordonneesContact.codePostal === '') {
          this.coordonneesContact.codePostal = oldContact.cpostal;
        }
        if (this.coordonneesContact.ville == null || this.coordonneesContact.ville === '') {
          this.coordonneesContact.ville = oldContact.ville;
        }
        if (this.idCoordPersoPays.id == 0) {
          this.idCoordPersoPays.id = oldContact.ID_pays;
        }
        if (idCoordContactMaj != 0) {
          this.coordonneesService.modifierCoordonnees(idCoordContactMaj, this.coordonneesContact,
            idContactMAJ, this.idCoordPersoPays.id).then();
        } else {
          // s'il n'existe pas encore de coordonnées et qu'au moins un champ de coordonnée est saisi
          if (this.coordonneesContact.premierTel != '' || this.coordonneesContact.deuxiemeTel != ''
            || (this.coordonneesContact.email != '' && this.coordonneesContact.email != null)
            || this.coordonneesContact.rue != ''
            || (this.coordonneesContact.complements != '' && this.coordonneesContact.complements != null)
            || (this.coordonneesContact.boitePostale !== '' && this.coordonneesContact.boitePostale != null)
            || this.coordonneesContact.codePostal != '' || (this.coordonneesContact.ville != undefined
              && this.coordonneesContact.ville != '' && this.coordonneesContact.ville != null)
            || (this.idCoordPersoPays.id > 0)) {
            await this.coordonneesService.addCoordonnees(this.coordonneesContact).then();
          }
        }
        this.contact.coordonnees = this.coordonneesContact;
        await this.contactService.modifierContact(idContactMAJ, this.contact, this.civiliteId).then(async cont => {
          const contactModifier = cont as Contact;
          // les assos
          // verifie s'il y a asso dans intranet et sur escalnet
          // verifier si le pays existe sur escalnet, si oui ajouter l'asso
          // si non créer le pays et ajouter l'asso
          // Contact Pays Asso
          // modifier les coordonnees du contact mise à jour si existantes
          // Contact Pays Asso
          // On récupère les pays associés au contact sur intranet s'il n'y pas de seléction dans le formulaire escalnet
          if (this.listePaysASauvegarder == null || this.listePaysASauvegarder.length === 0) {
            // On ne modifie rien s'il n'y a pas d'asso sur intranet
            if (oldContact.contact_pays_asso.length > 0) {
              oldContact.contact_pays_asso.forEach(PaysID => {
                // éviter de réassocier le pays si la liaison existe déjà sur escalnet
                if (contactModifier.pays.length > 0) {
                  contactModifier.pays.forEach(async paysAssocie => {
                    if (paysAssocie.id !== PaysID) {
                      await this.contactService.addContactPays(contactModifier.id, PaysID);
                    }
                  });
                } else {
                  this.contactService.addContactPays(contactModifier.id, PaysID);
                }
              });
            }
            // Sinon mettre à jour les assos via la seléction dans le formulaire escalnet
          } else {
            this.contactService.deleteContactPays(contactModifier.id).then(() => {
              this.listePaysASauvegarder.forEach(idPays => {
                this.contactService.addContactPays(contactModifier.id, idPays.id);
              });
            });
          }
          // Contact Langue Asso
          // On récupère les langues associés au contact sur intranet s'il n'y pas de seléction dans le formulaire escalnet
          if (this.listeLanguesASauvegarder == null || this.listeLanguesASauvegarder.length === 0) {
            // On ne modifie rien s'il n'y a pas d'asso sur intranet
            if (oldContact.contact_langues_asso.length > 0) {
              // éviter de réassocier le pays si la liaison existe déjà sur escalnet
              oldContact.contact_langues_asso.forEach(langueID => {
                if (contactModifier.langues.length > 0) {
                  contactModifier.langues.forEach(async languesAssocie => {
                    if (languesAssocie.id !== langueID) {
                      await this.contactService.addContactLangues(contactModifier.id, langueID);
                    }
                  });
                } else {
                  this.contactService.addContactLangues(contactModifier.id, langueID);
                }
              });
            }
            // Sinon mettre à jour les assos via la selection dans le formulaire escalnet
          } else {
            this.contactService.deleteContactLangues(contactModifier.id).then(() => {
              this.listeLanguesASauvegarder.forEach(idLangue => {
                this.contactService.addContactLangues(contactModifier.id, idLangue.id);
              });
            });
          }
          // Contact Categorie asso
          if (this.listeCategGenASauvegarder == null || this.listeCategGenASauvegarder.length === 0) {
            if (oldContact.contact_categgen_asso.length > 0) {
              // éviter de réassocier le pays si la liaison existe déjà sur escalnet
              oldContact.contact_categgen_asso.forEach(categgen => {
                categgen = categgen as CategoriesGenerales;
                if (contactModifier.categoriesGenerales.length > 0) {
                  contactModifier.categoriesGenerales.forEach(async categgAssocie => {
                    if (categgAssocie.ancienId !== categgen.id) {
                      await this.ajoutCategGen(categgen, contactModifier.id).then();
                    }
                  });
                } else {
                   this.ajoutCategGen(categgen, contactModifier.id).then();
                }
              });
            }
          } else {
            this.contactService.deleteContactCategGen(contactModifier.id).then(() => {
              this.listeCategGenASauvegarder.forEach(categgen => {
                this.ajoutCategGen(categgen, contactModifier.id);
              });
            });
          }
          // Contact sous Categorie asso
          if (this.listeSousCategGenASauvegarder == null || this.listeSousCategGenASauvegarder.length === 0) {
            if (oldContact.contact_sous_categgen_asso.length > 0) {
              // éviter de réassocier le pays si la liaison existe déjà sur escalnet
              oldContact.contact_sous_categgen_asso.forEach(sousCateggen => {
                sousCateggen = sousCateggen as SousCategoriesGenerales;
                if (contactModifier.sousCategoriesGenerales.length > 0) {
                  contactModifier.sousCategoriesGenerales.forEach(async sousCateggAssocie => {
                    if (sousCateggAssocie.ancienId !== sousCateggen.id) {
                      await this.ajoutSousCategGen(sousCateggen, contactModifier.id).then();
                    }
                  });
                } else {
                   this.ajoutSousCategGen(sousCateggen, contactModifier.id).then();
                }
              });
            }
          } else {
            // Sinon mettre à jour les assos via la selection dans le formulaire escalnet
            this.contactService.deleteContactSousCategGen(contactModifier.id).then(() => {
              this.listeSousCategGenASauvegarder.forEach(sousCateggen => {
                this.ajoutSousCategGen(sousCateggen, contactModifier.id);
              });
            });
          }
          // Contact société asso
          if (this.idOrganisationSelected == null || this.idOrganisationSelected == 0) {
           if (oldContact.cont_soc_asso.length > 0) {
            await this.organisationService.importerOrganisation(oldContact.cont_soc_asso[0].id).then(() => {
              // on récupère la société importée
                this.organisationService.getDoublonEscalnetSiExiste(oldContact.cont_soc_asso[0].id).then(async societe => {
                const socId = societe['hydra:member'][0].id;
                let tel1 = oldContact.cont_soc_asso.telPro1;
                let tel2 = oldContact.cont_soc_asso.telPro2;
                let emailPro = oldContact.cont_soc_asso.email_pro;
                // on crée l'asso avec les coordonnées récupérées sur intranet
                if (tel1 == '' || tel1 == null) {
                  tel1 = societe['hydra:member'][0].telPro1;
                }
                if (tel2 == '' || tel2 == null) {
                  tel2 = societe['hydra:member'][0].telPro2;
                }
                if (emailPro == '' || emailPro == null) {
                  emailPro = societe['hydra:member'][0].emailPro;
                }
                await this.contactService.addContactOrganisation(contactModifier.id, socId, tel1, tel2, emailPro);
              });
            });
           }
          } else {
            // si le formulaire est rempli, on prend les données
            await this.organisationService.importerOrganisation(this.idOrganisationSelected).then(() => {
                this.organisationService.getDoublonEscalnetSiExiste(this.idOrganisationSelected).then(async societe => {
                const socId = societe['hydra:member'][0].id;
                // On récupère les assos existantes sur escalnet
                await this.contactService.getContactOrganisationBy(contactModifier.id).then(async retour => {
                  const listeAssoContactOrganisation = retour['hydra:member'];
                  listeAssoContactOrganisation.forEach(contactOrganisation => {
                    // on garde les coordonnees escalnet s'il n'y a pas sur le formulaire
                    if (societe.id == contactOrganisation.contact.id) {
                      if (this.telPro1 == '' || this.telPro1 == null) {
                        this.telPro1 = contactOrganisation.telPro1;
                      }
                      if (this.telPro2 == '' || this.telPro2 == null) {
                        this.telPro2 = contactOrganisation.telPro2;
                      }
                      if (this.emailPro == '' || this.emailPro == null) {
                        this.emailPro = contactOrganisation.emailPro;
                      }
                    }
                    // On met à jour les assos
                    this.contactService.deleteContactOrganisation(contactOrganisation.id);
                  });
                  await this.contactService.addContactOrganisation(contactModifier.id, socId, this.telPro1,
                    this.telPro2, this.emailPro);
                });
              });
            });
          }
          // contact support asso
          this.contactService.getContactSupportBycontactID(contactModifier.id).then((retour) => {
            const listeContactSupportEscalnet = retour['hydra:member'];
            const listeContactSupportInranet = oldContact.cont_supp_asso;
            if (listeContactSupportEscalnet.length === 0) {
              if (listeContactSupportInranet.length > 0) {
                listeContactSupportInranet.forEach(contactSupportIntranet => {
                  // On crée sur la base escalnet le support, s'il n'existe pas
                  this.organisationService.importerSupport(contactSupportIntranet.id).then(() => {
                    this.organisationService.getSupportByAncienId(contactSupportIntranet.id).then(async support => {
                      const supportTrouve = support['hydra:member'][0];
                      let fonctionSupport = null;
                      if (contactSupportIntranet.IDfonction_suppasso > 0) {
                        // On vérifie si la fonction existe déjà sur la base escalnet
                        await this.fonctionService.getFonctionsEscalnetbyAncienID(contactSupportIntranet.IDfonction_suppasso)
                          .then(async fonc => {
                            const fonctionTrouve = fonc['hydra:member'];
                            // si la fonction n'existe pas, alors on crée sur la base escalnet
                            if (fonctionTrouve.length == 0) {
                              await this.contactService.getFonctionIntranetByAncienID(contactSupportIntranet.IDfonction_suppasso)
                                .then(async foncTrouve => {
                                  const fonctionIntra = foncTrouve['hydra:member'] as Fonction;
                                  await this.fonctionService.addFonction(fonctionIntra).then(fonctionCree => {
                                    fonctionSupport = fonctionCree;
                                  });
                                });
                            } else {
                              fonctionSupport = fonctionTrouve[0];
                            }
                          });
                        await this.contactService.addContactSupport(contactModifier.id, supportTrouve.id,
                          contactSupportIntranet.tel_suppasso, contactSupportIntranet.fax_suppasso,
                          contactSupportIntranet.email_suppasso, fonctionSupport.id, contactSupportIntranet.participetjs_suppasso,
                          contactSupportIntranet.datedepart_suppasso, contactSupportIntranet.commentaire,
                          contactSupportIntranet.dernier_email_verifie);
                        // Dans le cas, l'asso contact support n'a pas de fonction
                      } else {
                        await this.contactService.addContactSupport(contactModifier.id, supportTrouve.id,
                          contactSupportIntranet.tel_suppasso, contactSupportIntranet.fax_suppasso,
                          contactSupportIntranet.email_suppasso, 0, contactSupportIntranet.participetjs_suppasso,
                          contactSupportIntranet.datedepart_suppasso, contactSupportIntranet.commentaire,
                          contactSupportIntranet.dernier_email_verifie);
                      }
                    });
                  });
                });
              }
            }
          });
          // Contact liste contact asso
          this.contactService.getContactListeContactByContactID(contactModifier.id).then((retour) => {
            const listeListeContactContactEscalnet = retour['hydra:member'];
            const listeListeContactContactIntranet = oldContact.contact_liste_contact_asso;
            // Priorité aux assos sur la base escalnet, s'il n'y en a pas on prend ceux sur la base intranet
            if (listeListeContactContactEscalnet.length === 0 && listeListeContactContactIntranet.length > 0) {
              listeListeContactContactIntranet.forEach(listeContactContactIntranet => {
                // On vérifie si la liste contact existe ou non sur la base escalnet
                this.listeContactService.getListeContactEscalnetByAncienID(listeContactContactIntranet.ID_listecontact)
                  .then(async reponse => {
                    let listeContactEscalnet = reponse['hydra:member'];
                    if (listeContactEscalnet.length > 0) {
                      listeContactEscalnet = reponse['hydra:member'][0] as ListeContact;
                    }
                    // on crée la liste contact si elle n'existe pas sur la base escalnet, ensuite on crée l'asso
                    if (Object.keys(listeContactEscalnet).length === 0) {
                      const listeContactIntranet = new ListeContact(null, listeContactContactIntranet.nomliste_contact,
                        listeContactContactIntranet.commentaire_liste, listeContactContactIntranet.datecreation_liste,
                        listeContactContactIntranet.datemodif_liste, listeContactContactIntranet.ID_auteur_liste,
                        listeContactContactIntranet.IDtype_liste, null, listeContactContactIntranet.ID_listecontact);
                      this.listeContactService.addListeContact(listeContactIntranet).then(async listeContactCree => {
                        await this.contactService.addListeContactContact(listeContactCree.id, contactModifier.id,
                          listeContactContactIntranet.ID_support_prefere);
                      });
                      // si la liste contact existe déjà sur la base escalnet, on crée simplement l'asso
                    } else {
                      await this.contactService.addListeContactContact(listeContactEscalnet.id, contactModifier.id,
                        listeContactContactIntranet.ID_support_prefere);
                    }
                  });
              });
            }
          });
        });
        await this.contactService.importerContactIntranet(idContactMAJ, ancienIDContactMAJ).then(() => {
          // redirection fiche contact
          this.spinner.hide();
          this.router.navigate(['/escal/contacts']);
          this.dialogueShareService.addDialogue('Le contact a bien été mis à jour');
        });
      });
    });
  }

  importerMajContact(contactID) {
    // importer le contact
    this.spinner.show();
    this.contactService.importerContact(contactID).then(() => {
      // mettre à jour du contact
      this.majContact(contactID).then();
      this.verifierContactDoublon().then(() => {
        this.spinner.hide();
        this.dialogueShareService.addDialogue('Le contact a bien été importé');
      });
    });
  }

  supprimerContact(contactID) {
    this.dialogueShareService.addDialogue('Envoyer une demande à l\'IT ?', () => {
      const nomDemandeur = this.authUser.user.prenom + ' ' + this.authUser.user.nom;
      this.contactService.getContactByAncienId(contactID).then(contacts => {
        const contact = contacts['hydra:member'][0] as Contact;
        this.contactService.demandeSupprimerContact(contact.id, nomDemandeur).then(() => {
          this.dialogueShareService.addDialogue('Demande envoyée');
        });
      });
    });
  }
}
