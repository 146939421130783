import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../../environement/environement';
import {PressroomCommunique} from '../../../../model/client/pressroom/PressroomCommunique';


@Injectable()
export class CommuniqueService{
    private pressroomComunique = Environement.BASE_API_URL + '/api/pressroom_communiques/';
    constructor(private http: HttpClient) { }

  addPhotoToCommunique(idPressroom: number, idCommunique: number, photo: Array<any>) {
    const headers = this.initOptionHeader();
    return this.http.post( Environement.BASE_API_URL + '/update/pressroom/communique/photo/' + idPressroom + '/' + idCommunique ,
      { 'data': photo['value'], 'nom': photo['filename']}, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  changePdf(idPressroom: number, idCommunique: number, pdf: Array<any>) {
    const headers = this.initOptionHeader();
    return this.http.post( Environement.BASE_API_URL + '/update/pressroom/communique/pdf/' + idPressroom + '/' + idCommunique ,
      { 'data': pdf['value'], 'nom': pdf['filename']}, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  delPhotoCommunique(idpressroom, idCommunique){
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/update/pressroom/communique/photo/delete/' + idpressroom + '/' + idCommunique , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  saveTitreCommunique(communique: PressroomCommunique){
    const headers = this.initOptionHeader();
    return this.http.put( this.pressroomComunique + communique.id , { 'nom' : communique.nom} , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getAllCommuniquePressroom(idPressroom){
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/communique/pressroom/' + idPressroom,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  };
  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
