import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ArticleSite} from '../../../../../model/site/ArticleSite';
import {MotCleSite} from '../../../../../model/site/MotCleSite';

@Component({
  selector: 'app-pagination-content-mot-cle-site',
  templateUrl: './pagination-content-mot-cle-site.component.html',
  styleUrls: ['./pagination-content-mot-cle-site.component.scss']
})
export class PaginationContentMotCleSiteComponent implements OnInit {

  @Input()
  motCles: Array<MotCleSite>;
  @Output() deleteMotCle = new EventEmitter<Array<any>>();
  constructor() { }

  ngOnInit() {
  }

  deleteMotClefonction(id){
    this.deleteMotCle.emit(id);
  }
}
