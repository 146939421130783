import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';

@Injectable()
export class VeilleService {
  private veilleUrl = Environement.BASE_API_URL + '/api/veilles';
  constructor(private http: HttpClient) { }

  getVeillesJour(): Promise<any> {
    const headers = this.initOptionHeader();
    const now = new Date();
    const date: string = now.getFullYear().toString() + '-' + (now.getMonth() + 1).toString() + '-' + now.getDate().toString();
    return this.http.get(this.veilleUrl + '.json?date[after]=' + date , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  creeVeilleJour(): Promise<any> {
    const headers = this.initOptionHeader();
    const now = new Date();
    return this.http.post(this.veilleUrl + '.json', { 'date' : now , 'valider': '0' },{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  vaiderVeille( id ): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/maillings/send/veille/' + id , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getVeilles( ): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.veilleUrl, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  };

  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
