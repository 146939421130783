import {AuthUser} from '../user/authUser';
import {Contact} from '../contact/Contact';
import {TypeRelationSuivi} from './TypeRelationSuivi';
import {VecteurSuivi} from './VecteurSuivi';
import {Statut} from './Statut';
import {Support} from '../support/Support';
import {SuiviNature} from './SuiviNature';
import {ClientMailingCalendrierRedactionnel} from '../client/ClientMailingCalendrierRedactionnel';
import {EvenementMetier} from '../evenementMetier/EvenementMetier';

export class Suivi {
  id: number;
  ancienId: number;
  date: Date;
  titre: string;
  commentaires: string;
  niveau: number;
  aParaitre: boolean;
  inclureRA: boolean;
  suiviPar: AuthUser;
  email: string;
  telephone: string;
  mutualisation: AuthUser;
  contact: Contact;
  typeRelation: TypeRelationSuivi;
  vecteur: VecteurSuivi;
  statut: Statut;
  supports: Array<Support>;
  suiviNatures: Array<SuiviNature>;
  clientMailingCalendrierRedactionnels: Array<ClientMailingCalendrierRedactionnel>;
  auteur: AuthUser;
  creation: Date;
  modification: Date;
  evenement: EvenementMetier;
  niveauMutualisation: string;

  constructor(id: number, ancienId: number, date: Date, titre: string, commentaires: string, niveau: number, aParaitre: boolean, inclureRA: boolean,
              suiviPar: AuthUser, email: string, telephone: string, mutualisation: AuthUser, contact: Contact, typeRelation: TypeRelationSuivi,
              vecteur: VecteurSuivi, statut: Statut, supports: Array<Support>, suiviNatures: Array<SuiviNature>,
              clientMailingCalendrierRedactionnels: Array<ClientMailingCalendrierRedactionnel>, auteur: AuthUser, creation: Date,
              modification: Date, evenement: EvenementMetier, niveauMutualisation: string) {
    this.id = id;
    this.ancienId = ancienId;
    this.date = date;
    this.titre = titre;
    this.commentaires = commentaires;
    this.niveau = niveau;
    this.aParaitre = aParaitre;
    this.inclureRA = inclureRA;
    this.suiviPar = suiviPar;
    this.email = email;
    this.telephone = telephone;
    this.mutualisation = mutualisation;
    this.contact = contact;
    this.typeRelation = typeRelation;
    this.vecteur = vecteur;
    this.statut = statut;
    this.supports = supports;
    this.suiviNatures = suiviNatures;
    this.clientMailingCalendrierRedactionnels = clientMailingCalendrierRedactionnels;
    this.auteur = auteur;
    this.creation = creation;
    this.modification = modification;
    this.evenement = evenement;
    this.niveauMutualisation = niveauMutualisation;
  }
}
