import { Component, OnInit } from '@angular/core';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {Client} from '../../../../model/client/Client';
import {ModalCalendrierShareService} from '../../../../service/partage/ModalCalendrierShareService';
import {forEach} from '@angular/router/src/utils/collection';
import {RoleUserClient} from '../../../../model/client/RoleUserClient';
import {AuthShareService} from '../../../../service/partage/AuthShareService';

@Component({
  selector: 'app-modal-calendrier-client',
  templateUrl: './modal-calendrier-client.component.html',
  styleUrls: ['./modal-calendrier-client.component.scss'],
  providers: [ ClientService ]
})
export class ModalCalendrierClientComponent implements OnInit {

  clients: Array<Client>;
  clientSelect: Array<Client>;

  constructor(private authShareService: AuthShareService, private clientService: ClientService,
              public modalCalendrierShareService: ModalCalendrierShareService) { }

  ngOnInit() {
    const that = this;
    that.authShareService.getUserByAuthToken();
    this.clientService.getClientList(1)
      .then((retour) => {
        that.clients = retour['hydra:member'] as Array<Client>;
        that.initClientSelect();
      });
  }

  initClientSelect() {
    const clientSelectVal: Array<Client> = [];
    this.clients.forEach((client: Client) => {
      let present = 0;
      this.authShareService.authUser.rolesUserClient.forEach((clientRole: RoleUserClient) => {
        if (clientRole.client.id === client.id){
          present = 1;
        }
      });
      if (present === 1) {
        clientSelectVal.push(client);
      }
    });
    this.clientSelect = clientSelectVal;
    if(this.clientSelect.length === 0){
      this.modalCalendrierShareService.clientId = '0';
    }else{
      this.modalCalendrierShareService.clientId = this.clientSelect[0].id;
    }
  }

}
