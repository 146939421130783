import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';



@Injectable()
export class CoupureService {
    private coupureUrl = Environement.BASE_API_URL + '/api/coupures';
    constructor(private http: HttpClient) { }

  getCoupureNotSend(idClient) {
    const headers = this.initOptionHeader();
    return this.http.get( this.coupureUrl + '?pagination=false&envoyer=false&client.id=' + idClient, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  loadCoupure(idClient) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/coupure/client/load/' + idClient, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getContactsClient(idClient) {
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/coupure/ficheClient/getContactsClients/contacts/' + idClient, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }


  getContactsClientCoupures(idClient) {
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/coupures/ficheClient/getContactsClientsCoupures/contactsEnvoiCoupures/' + idClient, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }


  private errorHandler(err: any): Promise<any> {
        return Promise.reject(err.message || err );
    }
    private initOptionHeader() {
        const token: any = localStorage.getItem('auth-tokens');
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.set('X-Auth-Token', token);
        return headers;
    }
}
