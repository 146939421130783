import {Injectable} from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';


@Injectable()
export class EvenementSalleService {

  constructor(private http: HttpClient) {
  }



  checkDispoSalle(salleId, dateDebut, dateFin, dragdrop = false) {

    let dateDebutplus = new Date();
    let dateFinplus = new Date();
    if(dragdrop === true){
      dateDebutplus = new Date(dateDebut.getTime() + - 60 * ( dateDebut.getTimezoneOffset()) * 1000);
      dateFinplus = new Date(dateFin.getTime() + - 60 * ( dateFin.getTimezoneOffset()) * 1000);
    } else {
      dateDebutplus = dateDebut;
      dateFinplus = dateFin;
    }
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/checkDispoSalle/' + salleId + '/' +
      dateDebutplus.toISOString().substr(0, 19).replace('T', ' ') + '/' +
      dateFinplus.toISOString().substr(0, 19).replace('T', ' '),
      {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err);
  }

  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
