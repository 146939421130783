
import {Evenement} from '../evenement/Evenement';

export class Salle {
  id: string;
  nom: string;
  evenements: Array<Evenement>;
  couleurCalendrierBord: string;
  couleurCalendrierFond: string;


  constructor(id: string, nom: string, evenements: Array<Evenement>, couleurCalendrierBord: string, couleurCalendrierFond: string ) {
    this.id = id;
    this.nom = nom;
    this.evenements = evenements;
    this.couleurCalendrierBord = couleurCalendrierBord;
    this.couleurCalendrierFond = couleurCalendrierFond;

  }
}
