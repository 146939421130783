import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CategoriesService} from '../../../../service/interne/client/motsCles/CategoriesService';
import {CategorieDesMotsCles} from '../../../../model/client/motsCles/CategorieDesMotsCles';
import {MotsCles} from '../../../../model/client/motsCles/MotsCles';
import {Client} from '../../../../model/client/Client';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {MotsClesService} from '../../../../service/interne/client/motsCles/MotsClesService';
import { MatSelect } from '@angular/material/select';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {SuivitIntranetService} from '../../../../service/interne/suivit/SuivitIntranetService';
import {NgxSpinnerService} from 'ngx-spinner';
import {Suivi} from '../../../../model/suivi/Suivi';
import {NatureSuivi} from '../../../../model/suivi/NatureSuivi';
import {SuivitActionSujet} from '../../../../model/suivitAction/SuivitActionSujet';
import {SuiviActionShareService} from '../../../../service/partage/SuiviActionShareService';
import {TypeRelationSuivi} from '../../../../model/suivi/TypeRelationSuivi';
import {OrganisationService} from '../../../../service/interne/organisation/OrganisationService';
import {ParutionService} from '../../../../service/interne/parution/parutionService';
import {ContactService} from '../../../../service/interne/contact/ContactService';


@Component({
  selector: 'app-pagination-recherche',
  templateUrl: './pagination-recherche.component.html',
  styleUrls: ['./pagination-recherche.component.scss'],
  providers: [ ClientService, SuivitIntranetService, SuiviActionShareService, OrganisationService, ParutionService, ContactService ]
})
export class PaginationRechercheComponent implements OnInit {

  rechercheContent: string;
  rechercheType: string;
  @Input()
  typeRecherche: Array<any>;
  rechercheActive = '';
  motsClesRecherche: Array<MotsCles> = [];
  IDClientsList: Array<MotsCles> = [];
  clients: Array<Client> = [];
  curentPage = 1;
  clientsPagination: Array<Client> = [];
  maxPage: number;
  motsClesList: string;
  client: Client;
  rechercheCategorie = '';
  rechercheDate = '';
  categoriesList: Array<CategorieDesMotsCles>;
  naturesList: Array<NatureSuivi>;
  CategorieTrouve: CategorieDesMotsCles;
  MotCle: MotsCles;
  startMoment: Date;
  dateDebut: Date;
  dateFin: Date;
  dateDebutIntranet: Date;
  dateFinIntranet: Date;
  actu: number;
  motclesChecked = [];
  champRechercheIntranet: string;
  typeRechercheIntranet: string;
  natureRechercheIntranet: string;
  suivisAffichePage: Array<any> = [];
  societesAffichePage: Array<any> = [];
  parutionsAffichePage: Array<any> = [];
  contactAffichePage: Array<any> = [];
  suivisAffiche: Array<any> = [];
  societesAffiche: Array<any> = [];
  parutionsAffiche: Array<any> = [];
  contactAffiche: Array<any> = [];
  unSuivi: Suivi;
  rechercheEnCour: boolean;
  isRechercheSuivi: boolean;
  isRechercheClient: boolean;
  isRechercheOrganisation: boolean;
  isRechercheParution: boolean;
  isRechercheContact: boolean;
  isRechercheActualite: boolean;
  jours: number;
  anciensJours: number;
  tri: string;
  @Output() rechercheEvent = new EventEmitter<Array<any>>();
  constructor(private categoriesService: CategoriesService, private clientService: ClientService, private motsClesService: MotsClesService, private route: ActivatedRoute,
              private dialogueShareService: DialogueShareService, private suivitIntranetService: SuivitIntranetService, private spinner: NgxSpinnerService, private suiviActionShareService: SuiviActionShareService, private router: Router,
              private organisationService: OrganisationService, private parutionService: ParutionService, private contactService: ContactService) {
    document.addEventListener('click', (event) => this.onClick(event));
    if (this.route.url['value'][2] != undefined && this.route.url['value'][2].path === 'actuel')  {
      this.actu = 1;
    } else {
      this.actu = 0;
    }
  }
  @ViewChild('select') select: MatSelect;
  actuel = [];
  ngOnInit() {
    const that = this;
    this.champRechercheIntranet = '';
    this.typeRechercheIntranet = '';
    this.natureRechercheIntranet = '';
    this.rechercheEnCour = false;
    this.rechercheContent = '';
    this.motsClesList = '';
    this.maxPage = 0;
    this.isRechercheSuivi = false;
    this.isRechercheClient = false;
    this.isRechercheOrganisation = false;
    this.isRechercheParution = false;
    this.isRechercheContact = false;
    this.isRechercheActualite = false;
    this.jours = 30;
    this.anciensJours = 30;
    this.rechercheType = this.typeRecherche[0];
    that.suiviActionShareService.getNatures()
      .then((natures) => {
        that.naturesList = natures['hydra:member'] as Array<NatureSuivi>;
      });
    that.categoriesService.getCategories()
      .then((categories) => {
        that.categoriesList = categories['hydra:member'];
      });
    this.typeRecherche.forEach( type => {
      if (type == 'Collaborateur') {
        this.isRechercheSuivi = true;
      }
      if (type == 'Score') {
        this.isRechercheClient = true;
      }
      if (type == 'Forme Juridique') {
        this.isRechercheOrganisation = true;
      }
      if (type == 'Résumé') {
        this.isRechercheParution = true;
      }
      if (type == 'Fonction') {
        this.isRechercheContact = true;
      }
      if (type == 'Chiffres clés') {
        this.isRechercheActualite = true;
      }
    });
    if (this.actu == 1)  {
      this.tri = 'actuels';
    } else {
      this.tri = 'anciens';
    }
  }
  updateRecherche(value) {
    const jours = this.jours;
    this.ngOnInit();
    this.rechercheType = value;
    this.jours = jours;
    if (this.rechercheActive !== '') {
      this.recherche();
    }
    this.suivisAffiche = [];
    this.societesAffiche = [];
    this.parutionsAffiche = [];
  }

  updateRechercheJours(anciensJours) {
    const jours = this.jours;
    const type = this.rechercheType;
    this.ngOnInit();
    this.rechercheType = type;
    this.jours = jours;
    // si changement de jours
    if (this.rechercheActive !== '' || this.jours !== anciensJours) {
      this.recherche();
    }
    this.anciensJours = this.jours ;
    this.suivisAffiche = [];
    this.parutionsAffiche = [];
  }

  // @ts-ignore
  changeMotsCles(MotCle) {
    let present = false;
    let positionMotCle;
    let positionMotCleChecked;
    let i = 0;
    let clientPresent;
    this.motsClesRecherche.forEach(motCle => {
      if (motCle.id === MotCle.id) {
        present = true;
        positionMotCle = i ;
      }
      i = i + 1;
    });
    if (!present) {
      // ajouter le mot cle à la liste
      this.motsClesRecherche.push(MotCle);
      this.motclesChecked.push(MotCle.id);
      // ajouter le mot cle à la chaine de caractere qu'on envoie au controller
      this.motsClesList += MotCle.libelle + ',';
    } else {
      // retirer le mot cle de la liste
      this.motsClesRecherche.splice(positionMotCle, 1);
      i = 0;
      present = false;
      this.motclesChecked.forEach(motCleChecked => {
        if (motCleChecked === MotCle.id) {
          present = true;
          positionMotCleChecked = i;
        }
        i = i + 1;
      });
      this.motclesChecked.splice(positionMotCleChecked, 1);
      // retirer le mot clé de la chaine de caractere qu'on envoie au controller
      this.motsClesList = this.motsClesList.replace(MotCle.libelle + ',', '');
    }
    if (this.motsClesRecherche !== undefined) {
      this.clients = [];
      this.clientService.rechercheClients(this.motsClesList, this.actu)
        .then((clientsListID) => {
          this.IDClientsList = clientsListID.split(',');
          for (let i = 0; i < this.IDClientsList.length - 1; i++) {
            this.clientService.getClientDetail(this.IDClientsList[i])
              .then((client) => {
                this.client = client as Client;
                // parcourir this.clients et ajouter client s'il n'est pas dans this.clients
                clientPresent = this.clients.find(element => element.id === this.client.id);
                if (clientPresent === undefined) {
                  this.clients.push(this.client);
                }
                this.getMaxPage(this.clients.length);
                // on renvoie les 10 premiers à chaque nouvelle recherche
                this.curentPage = 1;
                this.getChangePage(this.curentPage);
              });
          }
        });
    }
    this.getMaxPage(this.clients.length);
    // on renvoie les 10 premiers à chaque nouvelle recherche
    this.curentPage = 1;
    this.getChangePage(this.curentPage);
  }


  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }

  getChangePage(page) {
    const that = this;
    this.clientsPagination = [];
    this.curentPage = page;
    let position = this.curentPage * 10 ; // 3 pages donc 30, on va donc commencer à 20
    let nbElement = 0;
    that.clients.forEach(client => {
      if (nbElement === position - 10) {
        this.clientsPagination.push(client);
        if (position - 10 < this.curentPage * 10 - 1) {
          position +=  1;
        }
      }
      nbElement = nbElement + 1;
    });
  }

  updateRechercheCategorie(value) {
    const that = this;
    this.rechercheCategorie = value;
    if (this.rechercheActive !== '') {
      this.recherche();
    }
    that.categoriesService.getCategorieByID(value)
      .then( (categorie) => {
        that.CategorieTrouve = categorie as CategorieDesMotsCles;
      });
  }

  updateRechercheDate(value) {
    this.rechercheDate = value;
    if (this.rechercheActive !== '') {
      this.recherche();
    }
  }

  recherche() {
    if (this.isRechercheSuivi || this.isRechercheParution) {
      if (this.rechercheContent == '' && this.jours !== this.anciensJours) {
        // si changement jours sans recherche
        this.rechercheEvent.emit(['changeJours', this.rechercheType, this.jours]);
      } else {
        // si recherche sans changement jours
        this.rechercheEvent.emit([this.rechercheContent, this.rechercheType, this.jours]);
      }
    } else {
      this.rechercheEvent.emit([this.rechercheContent, this.rechercheType]);
    }
  }

  onClick(event) {
    const elem = event.target;
    if ( elem.classList.contains('rechercheActive')) {
      this.rechercheActive = 'active';
    } else {
      if ( this.rechercheContent === '') {
        this.rechercheActive = '';
      } else {
        this.rechercheActive = 'active';
      }
    }
  }
  updateRechercheContent(event) {
    this.recherche();
  }


  getStat() {
    const that = this;
    let clientPresent;
    this.clients = [];
    if (this.dateDebut !== undefined && this.dateFin !== undefined) {
      const monthDeb = this.dateDebut.getMonth() + 1;
      const monthFin = this.dateFin.getMonth() + 1;
      this.clientService.getClientsDates(this.dateDebut.getFullYear() + '-' + monthDeb + '-' + this.dateDebut.getDate() ,
        this.dateFin.getFullYear() + '-' + monthFin + '-' + this.dateFin.getDate(), this.actu)
        .then( (retour) => {
          if (retour === '') {
          this.dialogueShareService.addDialogue('Aucun résultat pour cette période');
          }
          this.IDClientsList = retour.split(',');
          for (let i = 0; i < this.IDClientsList.length - 1; i++) {
            this.clientService.getClientDetail(this.IDClientsList[i])
              .then((retour2) => {
                this.client = retour2 as Client;
                clientPresent = this.clients.find(element => element.id === this.client.id);
                if (clientPresent === undefined) {
                  this.clients.push(this.client);
                }
                this.getMaxPage(that.clients.length);
                // on renvoie les 10 premiers à chaque nouvelle recherche
                this.curentPage = 1;
                this.getChangePage(this.curentPage);
              });
          }
        });
    } else if (this.dateFin === undefined) {
      this.dialogueShareService.addDialogue('La date de fin est obligatoire');
    } else if (this.dateDebut === undefined) {
      this.dialogueShareService.addDialogue('La date de début est obligatoire');
    }
  }



  getStatEntreeSortie(typeTri) {
    const that = this;
    let clientPresent;
    this.clients = [];
    if (this.dateDebut !== undefined && this.dateFin !== undefined) {
      const monthDeb = this.dateDebut.getMonth() + 1;
      const monthFin = this.dateFin.getMonth() + 1;
      this.clientService.getClientsDatesTri(this.dateDebut.getFullYear() + '-' + monthDeb + '-' + this.dateDebut.getDate() ,
        this.dateFin.getFullYear() + '-' + monthFin + '-' + this.dateFin.getDate(), this.actu, typeTri)
        .then( (retour) => {
          if (retour === '') {
            this.dialogueShareService.addDialogue('Aucun résultat pour cette période');
          }
          this.IDClientsList = retour.split(',');
          for (let i = 0; i < this.IDClientsList.length - 1; i++) {
            this.clientService.getClientDetail(this.IDClientsList[i])
              .then((retour2) => {
                this.client = retour2 as Client;
                clientPresent = this.clients.find(element => element.id === this.client.id);
                if (clientPresent === undefined) {
                  this.clients.push(this.client);
                }
                this.getMaxPage(that.clients.length);
                // on renvoie les 10 premiers à chaque nouvelle recherche
                this.curentPage = 1;
                this.getChangePage(this.curentPage);
              });
          }
        });
    } else if (this.dateFin === undefined) {
      this.dialogueShareService.addDialogue('La date de fin est obligatoire');
    } else if (this.dateDebut === undefined) {
      this.dialogueShareService.addDialogue('La date de début est obligatoire');
    }
  }

  resetSelect() {
    let motclesSelected;
    motclesSelected = this.select.selected;
    motclesSelected.forEach(matOption => {
      this.motsClesService.getMotCleByID(matOption.value).then(motCle => {
        this.changeMotsCles(motCle);
        matOption.deselect();
      });
    });
  }
  resetRechercheDate() {
    this.clients = [];
    this.dateFin = undefined;
    this.dateDebut = undefined;
    this.getMaxPage(this.clients.length);
    // on renvoie les 10 premiers à chaque nouvelle recherche
    this.curentPage = 1;
    this.getChangePage(this.curentPage);
  }


  resetRechercheIntranet() {
    this.dateFinIntranet = undefined;
    this.dateDebutIntranet = undefined;
  }


  getSuivisIntranet() {
    this.suivisAffiche = [];
    if (this.dateDebutIntranet != undefined && this.dateFinIntranet != undefined) {
      if (this.natureRechercheIntranet != '') {
        if (this.typeRechercheIntranet != '') {
          if (this.champRechercheIntranet != '') {
            this.champRechercheIntranet = this.champRechercheIntranet.replace(' ', '.');
            this.spinner.show();
            this.suivitIntranetService.getSuivisRecherche(this.champRechercheIntranet, this.dateDebutIntranet.getFullYear()
              + '-' + (this.dateDebutIntranet.getMonth() + 1) + '-' + this.dateDebutIntranet.getDate(), this.dateFinIntranet.getFullYear()
              + '-' + (this.dateFinIntranet.getMonth() + 1) + '-' + this.dateFinIntranet.getDate(), this.typeRechercheIntranet
              , this.natureRechercheIntranet).then((retour) => {
                this.spinner.hide();
                this.rechercheEnCour = true;
                this.suivisAffiche = retour;
                this.champRechercheIntranet = this.champRechercheIntranet.replace('.', ' ');
                this.getMaxPage(this.suivisAffiche.length);
                // on renvoie les 10 premiers à chaque nouvelle recherche
                this.curentPage = 1;
                // on trie les valeurs du tableau par date décroissante
                this.suivisAffiche.sort(function compare(a, b) {
                  if (a.dateSuivi > b.dateSuivi)
                    return -1;
                  if (a.dateSuivi < b.dateSuivi )
                    return 1;
                  return 0;
                });
                this.suivisAffichePage = this.suivisAffiche;
                this.getChangePageSuivis(this.curentPage);
            });
          } else {
            this.dialogueShareService.addDialogue('Veuillez saisir au moins 3 caractères');
          }
        } else {
          this.dialogueShareService.addDialogue('Veuillez choisir un filtre de recherche');
        }
      } else {
        this.dialogueShareService.addDialogue('Veuillez choisir une nature de recherche');
      }
    } else {
      this.dialogueShareService.addDialogue('Veuillez choisir un intervalle de dates');
    }
  }

  getChangePageSuivis(page) {
    const that = this;
    this.curentPage = page;
    let position = this.curentPage * 10 ; // 3 pages donc 30, on va donc commencer à 20
    let nbElement = 0;
    that.suivisAffiche = [];
    that.suivisAffichePage.forEach(suivi => {
      if (nbElement === position - 10) {
        this.suivisAffiche.push(suivi);
        if (position - 10 < this.curentPage * 10 - 1) {
          position +=  1;
        }
      }
      nbElement = nbElement + 1;
    });
  }

  getOrganisationsIntranet() {
    this.societesAffiche = [];
    if (this.typeRechercheIntranet != '') {
      if (this.champRechercheIntranet != '') {
        this.champRechercheIntranet = this.champRechercheIntranet.replace(' ', '.');
        this.spinner.show();
        this.organisationService.getSocietesRechercheIntranet(this.champRechercheIntranet,
          this.typeRechercheIntranet).then((retour) => {
          this.spinner.hide();
          this.rechercheEnCour = true;
          this.societesAffiche = retour;
          this.champRechercheIntranet = this.champRechercheIntranet.replace('.', ' ');
          this.getMaxPage(this.societesAffiche.length);
          // on renvoie les 10 premiers à chaque nouvelle recherche
          this.curentPage = 1;
          // on trie les valeurs du tableau par date décroissante
          this.societesAffiche.sort(function compare(a, b) {
            if (a.nom < b.nom)
              return -1;
            if (a.nom > b.nom )
              return 1;
            return 0;
          });
          this.societesAffichePage = this.societesAffiche;
          this.getChangePageSocietes(this.curentPage);
        });
      } else {
        this.dialogueShareService.addDialogue('Veuillez saisir au moins 3 caractères');
      }
    } else {
      this.dialogueShareService.addDialogue('Veuillez choisir un filtre de recherche');
    }
  }

  getParutionsIntranet() {
    this.parutionsAffiche = [];
    if (this.typeRechercheIntranet != '') {
      if (this.champRechercheIntranet != '') {
        this.champRechercheIntranet = this.champRechercheIntranet.replace(' ', '.');
        this.spinner.show();
        this.parutionService.getParutionsRechercheIntranet(this.champRechercheIntranet,
          this.typeRechercheIntranet).then((retour) => {
          this.spinner.hide();
          this.rechercheEnCour = true;
          this.parutionsAffiche = retour;
          this.champRechercheIntranet = this.champRechercheIntranet.replace('.', ' ');
          this.getMaxPage(this.parutionsAffiche.length);
          // on renvoie les 10 premiers à chaque nouvelle recherche
          this.curentPage = 1;
          // on trie les valeurs du tableau par date décroissante
          this.parutionsAffiche.sort(function compare(a, b) {
            if (a.date < b.date) {
              return -1;
            }
            if (a.date > b.date ) {
              return 1;
            }
            return 0;
          });
          this.parutionsAffichePage = this.parutionsAffiche;
          this.getChangePageParutions(this.curentPage);
        });
      } else {
        this.dialogueShareService.addDialogue('Veuillez saisir au moins 3 caractères');
      }
    } else {
      this.dialogueShareService.addDialogue('Veuillez choisir un filtre de recherche');
    }
  }

  getChangePageSocietes(page) {
    const that = this;
    this.curentPage = page;
    let position = this.curentPage * 10 ; // 3 pages donc 30, on va donc commencer à 20
    let nbElement = 0;
    that.societesAffiche = [];
    that.societesAffichePage.forEach(soc => {
      if (nbElement === position - 10) {
        this.societesAffiche.push(soc);
        if (position - 10 < this.curentPage * 10 - 1) {
          position +=  1;
        }
      }
      nbElement = nbElement + 1;
    });
  }
  getChangePageContact(page) {
    const that = this;
    this.curentPage = page;
    let position = this.curentPage * 10 ; // 3 pages donc 30, on va donc commencer à 20
    let nbElement = 0;
    that.contactAffiche = [];
    that.contactAffichePage.forEach(contact => {
      if (nbElement === position - 10) {
        this.contactAffiche.push(contact);
        if (position - 10 < this.curentPage * 10 - 1) {
          position +=  1;
        }
      }
      nbElement = nbElement + 1;
    });
  }

  getChangePageParutions(page) {
    const that = this;
    this.curentPage = page;
    let position = this.curentPage * 10 ; // 3 pages donc 30, on va donc commencer à 20
    let nbElement = 0;
    that.parutionsAffiche = [];
    that.parutionsAffichePage.forEach(soc => {
      if (nbElement === position - 10) {
        this.parutionsAffiche.push(soc);
        if (position - 10 < this.curentPage * 10 - 1) {
          position +=  1;
        }
      }
      nbElement = nbElement + 1;
    });
  }

  changeStatutClient() {
    if (this.tri == 'actuels') {
      this.router.navigate(['/escal/client/actuel']);
    } else {
      this.router.navigate(['/escal/client/ancien']);
    }
  }
  getContactsIntranet() {
    this.contactAffiche = [];
    if (this.typeRechercheIntranet != '') {
      if (this.champRechercheIntranet != '') {
        this.champRechercheIntranet = this.champRechercheIntranet.replace(' ', '.');
        this.spinner.show();
        this.contactService.getContactRechercheIntranet(this.champRechercheIntranet,
          this.typeRechercheIntranet).then(retour => {
            this.spinner.hide();
            this.rechercheEnCour = true;
            this.contactAffiche = retour;
            this.champRechercheIntranet = this.champRechercheIntranet.replace('.', ' ');
            this.getMaxPage(this.contactAffiche.length);
            // on renvoie les 10 premiers à chaque nouvelle recherche
            this.curentPage = 1;
            // on trie les valeurs du tableau par date décroissante
            this.contactAffiche.sort(function compare(a, b) {
              if (a.nom < b.nom && a.prenom < b.prenom)
                return -1;
              if (a.nom > b.nom && a.prenom > b.prenom )
                return 1;
              return 0;
            });
            this.contactAffichePage = this.contactAffiche;
            this.getChangePageContact(this.curentPage);
        });
      } else {
        this.dialogueShareService.addDialogue('Veuillez saisir au moins 3 caractères');
      }
    } else {
      this.dialogueShareService.addDialogue('Veuillez choisir une nature de recherche');
    }
  }
}
