import { Component, OnInit } from '@angular/core';
import {DialogueShareService} from '../../../service/partage/utilitaire/DialogueShareService';
import {CategorieCoupure} from '../../../model/coupure/CategorieCoupure';
import {CategorieCoupureService} from '../../../service/interne/coupure/CategorieCoupureService';

@Component({
  selector: 'app-verification-thematiques',
  templateUrl: './verification-thematiques.component.html',
  styleUrls: ['./verification-thematiques.component.scss'],
  providers: [ CategorieCoupureService ]
})
export class VerificationThematiquesComponent implements OnInit {

  categoriesCoupure: Array<CategorieCoupure>;
  libelle: string;

  constructor(private categorieCoupureService: CategorieCoupureService, private dialogueShareService: DialogueShareService) { }

  ngOnInit() {
    const that = this;
    this.categorieCoupureService.getCategorieToCheck()
      .then((retour) => {
        that.categoriesCoupure = retour['hydra:member']  as Array<CategorieCoupure>;
      });
  }

  updateThematique(){
    const that = this;
    this.categorieCoupureService.refreshCategorieCoupure()
      .then((retour) => {
        this.dialogueShareService.addDialogue('la liste a été mise a jour');
        that.categorieCoupureService.getCategorieToCheck()
          .then((retour) => {
            that.categoriesCoupure = retour['hydra:member']  as Array<CategorieCoupure>;
          });
      });
  }
  validerVerification() {
    const that = this;
    const variable = {
      'categoriesCoupure': this.categoriesCoupure, 'categorieCoupureService': this.categorieCoupureService
    };
    this.dialogueShareService.addDialogue('valider', () => {
      variable['categoriesCoupure'].forEach((categorieCoupure) => {
        if (categorieCoupure.valider === true) {
          variable['categorieCoupureService'].validerCategorie(categorieCoupure.id)
            .then((retour) => {
            });
        } else {
          variable['categorieCoupureService'].deleteCategorie(categorieCoupure.id)
            .then((retour) => {
            });
        }
      });
      setTimeout(() => {
        that.categorieCoupureService.getCategorieToCheck()
          .then((retour) => {
            that.categoriesCoupure = retour['hydra:member']  as Array<CategorieCoupure>;
          });
      }, 500);
    });
  }
  ajouterCategorie(){
    const that = this;
    this.categorieCoupureService.addCategorie(this.libelle)
      .then((retour) => {
        that.dialogueShareService.addDialogue('categorie ajouté');
        that.libelle = '';
      });
  }

}
