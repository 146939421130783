import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ArticleSiteSection} from '../../../model/site/ArticleSiteSection';
import {ArticleSite} from '../../../model/site/ArticleSite';
import {ImageArticleSite} from '../../../model/site/ImageArticleSite';
import {Environement} from '../../../environement/environement';




@Injectable()
export class ArticleSiteSectionService{
    private articleSectionSiteUrl = Environement.BASE_API_URL + '/api/article_site_sections';
    constructor(private http: HttpClient) { }

  addArticleSiteSection(articleSiteSection: ArticleSiteSection, article: ArticleSite, image: ImageArticleSite) {
    const headers = this.initOptionHeader();
    if (image === null) {
      return this.http.post(this.articleSectionSiteUrl , {'nom': articleSiteSection.nom,
        'content': articleSiteSection.content, 'contentDisposition': articleSiteSection.contentDisposition,
        'imagePosition': articleSiteSection.imagePosition, 'title': articleSiteSection.title,
        'couleurTitle': articleSiteSection.couleurTitle,
        'article': Environement.BASE_API_URL + '/api/article_sites/' + article.id}, {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    } else {
      return this.http.post(this.articleSectionSiteUrl , {'nom': articleSiteSection.nom,
        'content': articleSiteSection.content, 'contentDisposition': articleSiteSection.contentDisposition,
        'imagePosition': articleSiteSection.imagePosition, 'title': articleSiteSection.title,
        'couleurTitle': articleSiteSection.couleurTitle,
        'article': Environement.BASE_API_URL + '/api/article_sites/' + article.id,
        'image': Environement.BASE_API_URL + '/api/image_article_sites/' + image.id}, {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }

  }
  updateArticleSiteSection(articleSiteSection: ArticleSiteSection, image: ImageArticleSite){
    const headers = this.initOptionHeader();
    if (image === null) {
      return this.http.put(this.articleSectionSiteUrl  + '/' + articleSiteSection.id , {'nom': articleSiteSection.nom,
        'content': articleSiteSection.content, 'contentDisposition': articleSiteSection.contentDisposition,
        'imagePosition': articleSiteSection.imagePosition, 'title': articleSiteSection.title,
        'couleurTitle': articleSiteSection.couleurTitle, 'image' : null }, {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    } else {
      return this.http.put(this.articleSectionSiteUrl  + '/' + articleSiteSection.id , {'nom': articleSiteSection.nom,
        'content': articleSiteSection.content, 'contentDisposition': articleSiteSection.contentDisposition,
        'imagePosition': articleSiteSection.imagePosition, 'title': articleSiteSection.title,
        'couleurTitle': articleSiteSection.couleurTitle,
        'image': Environement.BASE_API_URL + '/api/image_article_sites/' + image.id }, {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }

  deleteSection(id){
    const headers = this.initOptionHeader();
    return this.http.delete(this.articleSectionSiteUrl + '/' + id ,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
    private errorHandler(err: any): Promise<any> {
        return Promise.reject(err.message || err );
    };
    private initOptionHeader(){
        const token: any = localStorage.getItem('auth-tokens');
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.set('X-Auth-Token', token);
        return headers;
    }
}
