import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ImagePressroom} from '../../../../../../model/client/pressroom/ImagePressroom';
import {ImagePressroomService} from '../../../../../../service/interne/client/pressroom/ImagePressroomService';
import {DialogueShareService} from '../../../../../../service/partage/utilitaire/DialogueShareService';
import {Environement} from '../../../../../../environement/environement';
import {PressroomShareService} from '../../../../../../service/partage/PressroomShareService';

@Component({
  selector: 'app-pressroom-client-configuration-phototech',
  templateUrl: './pressroom-client-configuration-phototech.component.html',
  styleUrls: ['./pressroom-client-configuration-phototech.component.scss'],
  providers: [ ImagePressroomService ]
})
export class PressroomClientConfigurationPhototechComponent implements OnInit {

  phototechPressroom: ImagePressroom;
  imageUpload: Array<any> = [];
  addOpen: boolean;

  @ViewChild('inputFile')
  inputFile: ElementRef;

  constructor(private imagePressRoomService: ImagePressroomService,
              private dialogueShareService: DialogueShareService,
              public pressroomShareService: PressroomShareService) { }

  ngOnInit() {
    this.phototechPressroom = new ImagePressroom(0, '', 'phototech');
    this.pressroomShareService.loadPhototech();
    this.closeAddOpen();
  }

  savePhoto() {
    const that = this;
    if (this.phototechPressroom.nom.length < 3){
      that.dialogueShareService.addDialogue('Le nom doit faire au moins 3 caractères');
    } else if (typeof (this.imageUpload['filename']) === undefined) {
      that.dialogueShareService.addDialogue('Choisissez d\'abord un fichier');
    } else {
      this.imagePressRoomService.addImagePressroom(this.phototechPressroom, this.imageUpload, this.pressroomShareService.pressroom.id)
        .then(() => {
          this.pressroomShareService.loadPhototech();
          this.closeAddOpen();
        });
    }
  }

  closeAddOpen() {
    this.addOpen = false;
    this.imageUpload = [];
    this.phototechPressroom = new ImagePressroom(0, '', 'phototech');
  }

  deletePhoto(photo: ImagePressroom) {
    const variable = {
      imagePressRoomService: this.imagePressRoomService, photo: photo
      , pressroomShareService: this.pressroomShareService
    };
    this.dialogueShareService.addDialogue('êtes vous sûre de vouloir supprmier ce logo', () => {
      variable.imagePressRoomService.deleteteImage(variable.photo)
        .then((retour) => {
          variable.pressroomShareService.loadPhototech();
        });
    });
  }

  changeImage(event) {
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
      const extension = file.name.split('.')[1];
      if (extension.toUpperCase() === 'JPEG' || extension.toUpperCase() === 'PNG' || extension.toUpperCase() === 'JPG'){

          that.imageUpload['filename'] = file.name;
        that.imageUpload['filetype'] = file.type;
        // @ts-ignore
        that.imageUpload['value'] = reader.result.split(',')[1];
        that.imageUpload['extension'] = file.name.split('.')[1];
      } else {
        that.dialogueShareService.addDialogue('Seul les format jpg, png, jpeg sont accepté');
        that.inputFile.nativeElement.value = '';
      }
      };
    }
  }

  getImageSource(logo: ImagePressroom){
    return Environement.BASE_API_URL + '/image/pressroom/content/' + logo.id;
  }

}
