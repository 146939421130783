import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';


@Injectable()
export class CategorieCoupureService{
    private categorieCoupureUrl = Environement.BASE_API_URL + '/api/categorie_coupures';
    constructor(private http: HttpClient) { };

  refreshCategorieCoupure(){
        let headers = this.initOptionHeader();
        return this.http.get(Environement.BASE_API_URL + '/categorieCoupure/update', {headers: headers} ).toPromise()
            .then(response => response)
            .catch(this.errorHandler);
    }

  getCategorieToCheck(){
    let headers = this.initOptionHeader();
    return this.http.get(this.categorieCoupureUrl + '?valider=0', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  validerCategorie(id){
    let headers = this.initOptionHeader();
    return this.http.put(this.categorieCoupureUrl + '/' + id, { 'valider' : true }, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  deleteCategorie(id){
    let headers = this.initOptionHeader();
    return this.http.delete(this.categorieCoupureUrl + '/' + id, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addCategorie(libelle){
    let headers = this.initOptionHeader();
    return this.http.post(this.categorieCoupureUrl, { 'valider' : true, 'libelle': libelle }, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
    private errorHandler(err: any): Promise<any> {
        return Promise.reject(err.message || err );
    };
    private initOptionHeader(){
        const token: any = localStorage.getItem('auth-tokens');
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.set('X-Auth-Token', token);
        return headers;
    }
}
