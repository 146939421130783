import {SuivitActionSujet} from './SuivitActionSujet';
import {Client} from '../client/Client';
import {CalendrierRedactionnel} from '../client/CalendrierRedactionnel';
import {Mailling} from '../mailling/Mailling';
import {NatureActionSuivi} from './NatureActionSuivi';
import {TypeDeRelation} from './TypeDeRelation';
import {Vecteur} from './Vecteur';
import {AuthUser} from '../user/authUser';
import {SuiviActionAssoPersonneMediaOrganisation} from './SuiviActionAssoPersonneMediaOrganisation';

export class SuiviAction {
  id: number;
  titre: string;
  date: Date;
  aParaitre: boolean;
  contactSuiviId: number;
  mediaSuivitIds: Array<number>;
  societe1: Client;
  calRedSociete1: CalendrierRedactionnel;
  communiqueSociete1: Mailling;
  societe2: Client;
  calRedSociete2: CalendrierRedactionnel;
  communiqueSociete2: Mailling;
  typeDeRelation: TypeDeRelation;
  niveauSuivit: number;
  natureActionSuivi: NatureActionSuivi;
  vecteur: Vecteur;
  emailContactSuivi: string;
  numeroContactSuivi: string;
  sujetEnCours: Array<SuivitActionSujet>;
  clientOportunites1Id: number;
  clientOportunites2Id: number;
  clientOportunites3Id: number;
  commentaireOportunite: string;
  suivitPar: AuthUser;
  statusSuivit: number;
  note: string;
  suiviActionAssoPersonneMediaOrganisation: SuiviActionAssoPersonneMediaOrganisation;

  constructor(id: number, titre: string, date: Date, aParaitre: boolean, contactSuivitId: number, mediaSuivitIds: Array<number>,
              societe1: Client, calRedSociete1: CalendrierRedactionnel, communiqueSociete1: Mailling,
              societe2: Client, calRedSociete2: CalendrierRedactionnel, communiqueSociete2: Mailling, typeDeRelation: TypeDeRelation,
              niveauSuivit: number, natureActionSuivi: NatureActionSuivi, vecteur: Vecteur, emailContactSuivit: string,
              numeroContactSuivit: string,
              sujetEnCours: Array<SuivitActionSujet>, clientOportunites1Id: number, clientOportunites2Id: number,
              clientOportunites3Id: number, commentaireOportunite: string, suivitPar: AuthUser, statusSuivit: number, note: string,
              suiviActionAssoPersonneMediaOrganisation: SuiviActionAssoPersonneMediaOrganisation) {
    this.id = id;
    this.titre = titre;
    this.date = date;
    this.aParaitre = aParaitre;
    this.contactSuiviId = contactSuivitId;
    this.mediaSuivitIds = mediaSuivitIds;
    this.societe1 = societe1;
    this.calRedSociete1 = calRedSociete1;
    this.communiqueSociete1 = communiqueSociete1;
    this.societe2 = societe2;
    this.calRedSociete2 = calRedSociete2;
    this.communiqueSociete2 = communiqueSociete2;
    this.typeDeRelation = typeDeRelation;
    this.niveauSuivit = niveauSuivit;
    this.natureActionSuivi = natureActionSuivi;
    this.vecteur = vecteur;
    this.emailContactSuivi = emailContactSuivit;
    this.numeroContactSuivi = numeroContactSuivit;
    this.sujetEnCours = sujetEnCours;
    this.clientOportunites1Id = clientOportunites1Id;
    this.clientOportunites2Id = clientOportunites2Id;
    this.clientOportunites3Id = clientOportunites3Id;
    this.commentaireOportunite = commentaireOportunite;
    this.suivitPar = suivitPar;
    this.statusSuivit = statusSuivit;
    this.note = note;
    this.suiviActionAssoPersonneMediaOrganisation = suiviActionAssoPersonneMediaOrganisation;

  }
}
