import {PieceJointe} from './PieceJointe';
import {SuivitOuverture} from './SuivitOuverture';
import {PartieCp} from './PartieCp';
import {Client} from '../client/Client';
import {AuthUser} from '../user/authUser';
import {LogoMailling} from './LogoMailling';

export class Mailling {
  id: number;
  client: Client;
  enCour: boolean;
  envoyePar: AuthUser;
  nom: string;
  objet: string;
  replyTo: AuthUser;
  resumer: string;
  erreur: string;
  typeMail: string;
  pieceJointes : Array<PieceJointe>;
  suivitOuvertures: Array<SuivitOuverture>;
  nbMail: number;
  objetCour: string;
  titre: string;
  content:string;
  resumerCour:string;
  partiesCp: Array<PartieCp>;
  pdf: any;
  nbMailEnvoye: any;
  color: string;
  photo: any;
  langue: string;
  debutMailCoupure: string;
  finMailCoupure: string;
  dateEnvoi: Date;
  hyperlien: boolean;
  logo: LogoMailling;
  signature: boolean;
  signatureContenu: string;



  constructor( id: number, client: Client, enCour: boolean, envoyePar: AuthUser, nom: string, objet: string, replyTo: AuthUser,
               resumer: string, typeMail: string, pieceJointes : Array<PieceJointe>, suivitOuvertures: Array<SuivitOuverture>,
               nbMail: number,objetCour: string, titre: string, content:string, resumerCour:string,
               partiesCp: Array<PartieCp>, pdf: any, nbMailEnvoye: any, color: string, photo: any, langue: string,
               debutMailCoupure: string, finMailCoupure: string, hyperlien: boolean, erreur: string,
               logo: LogoMailling, signature: boolean, signatureContenu: string) {
    this.id = id;
    this.client = client;
    this.enCour = enCour;
    this.envoyePar = envoyePar;
    this.nom = nom;
    this.objet = objet;
    this.replyTo = replyTo;
    this.resumer = resumer;
    this.typeMail = typeMail;
    this.pieceJointes = pieceJointes;
    this.suivitOuvertures = suivitOuvertures;
    this.nbMail = nbMail;
    this.objetCour = objetCour;
    this.titre = titre;
    this.content = content;
    this.resumerCour = resumerCour;
    this.partiesCp = partiesCp;
    this.pdf = pdf;
    this.nbMailEnvoye = nbMailEnvoye;
    this.color = color;
    this.photo = photo;
    this.langue = langue;
    this.debutMailCoupure = debutMailCoupure;
    this.finMailCoupure = finMailCoupure;
    this.hyperlien = hyperlien;
    this.erreur = erreur;
    this.logo = logo;
    this.signature = signature;
    this.signatureContenu = signatureContenu;
  }
}
