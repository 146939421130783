import {AuthUserGroupeUser} from './AuthUserGroupeUser';

export class UserGroupe {
  id: string;
  nom: string;
  alias: string;
  nbMembre: number;
  users: Array<AuthUserGroupeUser>;


  constructor(id: string, nom: string, alias: string, nbMembre: number, users: Array<AuthUserGroupeUser>) {
    this.id = id;
    this.nom = nom;
    this.alias = alias;
    this.nbMembre = nbMembre;
    this.users = users;
  }
}
