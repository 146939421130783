import { Component, OnInit } from '@angular/core';
import {AuthShareService} from './service/partage/AuthShareService';
import {AuthTokenService} from './service/interne/user/AuthTokenService';
import {DialogueShareService} from './service/partage/utilitaire/DialogueShareService';
import {MenuShareService} from './service/partage/utilitaire/MenuShareService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ AuthTokenService ]
})
export class AppComponent implements OnInit{

  constructor(private authShareService: AuthShareService, private authTokenService: AuthTokenService,
              private dialogueShareService: DialogueShareService, private menuShareService: MenuShareService) {
  }

  ngOnInit() {
    this.authShareService.authTokenService = this.authTokenService;
    this.authShareService.dialogueShareService = this.dialogueShareService;
    this.dialogueShareService.dialogue = [];
    this.authShareService.getUserByAuthToken();
    this.menuShareService.menuOpen = true;
  }
  displaySideBar(){
    if(this.menuShareService.menuOpen === false) {
      return 'none';
    }
    if(this.menuShareService.menuOpen === true) {
      return 'block';
    }
  }
  widthMenu() {
    if (this.menuShareService.menuOpen === false) {
      return '100%';
    } else {
      return 'calc(100% - 250px)';
    }
  }
}
