import {Component, OnInit, ViewChild} from '@angular/core';
import {Veille} from '../../../../model/veille/Veille';
import {VeilleService} from '../../../../service/interne/veille/VeilleService';

@Component({
  selector: 'app-escal-veille-jour',
  templateUrl: './escal-veille-jour.component.html',
  styleUrls: ['./escal-veille-jour.component.scss'],
  providers: [ VeilleService ],
})
export class EscalVeilleJourComponent implements OnInit {

  @ViewChild('dt') datetimepicker: any;
  veilles: Array<Veille>;
  dateValue: any = 0;
  dateEnvoi: string;
  forbiddenDates: Array<any> = [];
  dateEnable = [];
  dateselectedVeille: Date;
  veilleActive: Veille;


  constructor(private veilleService: VeilleService) { }

  myFilter = (d: Date): boolean => {
    return (typeof this.dateEnable[d.getFullYear().toString() + '-' + (d.getMonth() + 1).toString()
    + '-' + d.getDate().toString()] !== 'undefined');
  };

  ngOnInit() {
    const that = this;
    const today = new Date();
    this.forbiddenDates.push(today);
    this.datetimepicker.firstDayOfWeek = 1;
    let dateEnable = [];
    this.veilleService.getVeilles()
      .then((retour) => {
        that.veilles = retour['hydra:member'] as Array<Veille>;
        that.veilles.forEach((veille) => {
          const date = new Date(veille.date);
          const daydate =  date.getFullYear().toString() + '-' + (date.getMonth() + 1).toString() + '-' + date.getDate().toString();
          that.dateselectedVeille = date;
          that.dateEnable[daydate] = veille;
        });
        that.veilleActive = that.dateEnable[that.dateselectedVeille.getFullYear().toString() + '-' +
        (that.dateselectedVeille.getMonth() + 1).toString() + '-' + that.dateselectedVeille.getDate().toString()];
      });
  }
  isSelected(date) {
    console.log(date);
  }
  changeDate() {
    this.veilleActive = this.dateEnable[this.dateselectedVeille.getFullYear().toString() + '-' +
    (this.dateselectedVeille.getMonth() + 1).toString() + '-' + this.dateselectedVeille.getDate().toString()];
  }

}
