import { Component, OnInit } from '@angular/core';
import {PressroomShareService} from '../../../../../../service/partage/PressroomShareService';
import {AuthUser} from '../../../../../../model/user/authUser';
import {AuthUserService} from '../../../../../../service/interne/user/AuthUserService';
import {ImagePressroom} from '../../../../../../model/client/pressroom/ImagePressroom';
import {Environement} from '../../../../../../environement/environement';

@Component({
  selector: 'app-pressroom-client-configuration-template',
  templateUrl: './pressroom-client-configuration-template.component.html',
  styleUrls: ['./pressroom-client-configuration-template.component.scss'],
  providers: [ AuthUserService ]
})
export class PressroomClientConfigurationTemplateComponent implements OnInit {

  constructor(public pressroomShareService: PressroomShareService, private authUserService: AuthUserService) { }

  authUsers: Array<AuthUser> = [];
  image: ImagePressroom = null;
  logo: ImagePressroom = null;
  contact1: number = null;
  contact2: number = null;

  ngOnInit() {
    this.authUserService.getUsersList(1)
      .then((retour) => {
        this.authUsers = retour['hydra:member'] as Array<AuthUser>;
      });
    this.pressroomShareService.loadPhototech();
    this.pressroomShareService.loadLogo();
    if (this.pressroomShareService.pressroom.contact1 !== null) {
      this.contact1 = this.pressroomShareService.pressroom.contact1.id;
    }
    if (this.pressroomShareService.pressroom.contact2 !== null) {
      this.contact2 = this.pressroomShareService.pressroom.contact2.id;
    }
    this.image = this.pressroomShareService.pressroom.imageFond;
    this.logo = this.pressroomShareService.pressroom.logoAffiche;
  }

  getImageSource(image: ImagePressroom) {
    return Environement.BASE_API_URL + '/image/pressroom/content/' + image.id;
  }

  getImageZoomUrl() {
    if (this.image === null) {
      return 'assets/application/images/noImage.png';
    } else {
      return this.getImageSource(this.image);
    }
  }
  isImageSelected(image: ImagePressroom) {
    if (image === null || this.image === null) {
      return false;
    }
    return image.id === this.image.id;
  }

  getLogoZoomUrl() {
    if (this.logo === null) {
      return 'assets/application/images/noImage.png';
    } else {
      return this.getImageSource(this.logo);
    }
  }
  isLogoSelected(image: ImagePressroom) {
    if (image === null || this.logo === null) {
      return false;
    }
    return image.id === this.logo.id;
  }

  sauvegarder(){
    this.pressroomShareService.pressroomService.updateConfiguration(this.pressroomShareService.pressroom, this.contact1, this.contact2,
      this.image, this.logo)
      .then(() => {
        this.pressroomShareService.initPressroom();
      });
  }
}
