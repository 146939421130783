import { Component, OnInit } from '@angular/core';

import {MenuShareService} from '../../../../../service/partage/utilitaire/MenuShareService';

@Component({
  selector: 'app-structure-menu-gauche-content',
  templateUrl: './structure-menu-gauche-content.component.html',
  styleUrls: ['./structure-menu-gauche-content.component.scss']
})
export class StructureMenuGaucheContentComponent implements OnInit {

  constructor(public menuShareService: MenuShareService) { }

  ngOnInit() {
  }

}
