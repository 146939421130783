import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';
import {RoleUserClient} from '../../../model/client/RoleUserClient';



@Injectable()
export class ClientRoleUserService {
  private roleUserClientUrl = Environement.BASE_API_URL + '/api/client_role_users';
  constructor(private http: HttpClient) { };

  updateRoleUserClient(roleUserClient: RoleUserClient) {
    const headers = this.initOptionHeader();
    return this.http.put(this.roleUserClientUrl + '/' +  roleUserClient.id, { 'adresseGenerique': roleUserClient.adresseGenerique,
      'adresseCoupure': roleUserClient.adresseCoupure, 'role': roleUserClient.role,
        'color': roleUserClient.color },
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  deleteRoleUserClient(id) {
    const headers = this.initOptionHeader();
    return this.http.delete(this.roleUserClientUrl + '/' +  id,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getClientRoleUserByClientAuthUser(idClient, idUser) {
    const headers = this.initOptionHeader();
    return this.http.get(this.roleUserClientUrl + '?client=' +  idClient + '&authUser=' +  idUser,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addClientRoleUser(idClient, idAuthUser, pressTeam = false ) {
    const headers = this.initOptionHeader();
    return this.http.post(this.roleUserClientUrl,{'role': 'BO', 'client': Environement.BASE_API_URL + '/api/clients/' + idClient ,
      'authUser': Environement.BASE_API_URL + '/api/auth_users/' + idAuthUser,
      'adresseCoupure': false, 'adresseGenerique': false },{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getClientRoleUser(idUser): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.roleUserClientUrl + '/' +  idUser + '?pagination=false',{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  };
  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
