import { Component, OnInit } from '@angular/core';


import {AuthShareService} from '../../../../service/partage/AuthShareService';
import {MenuShareService} from '../../../../service/partage/utilitaire/MenuShareService';
import {OpenWithPostShareService} from '../../../../service/partage/utilitaire/OpenWithPostShareService';

@Component({
  selector: 'app-structure-menu-header',
  templateUrl: './structure-menu-header.component.html',
  styleUrls: ['./structure-menu-header.component.scss']
})
export class StructureMenuHeaderComponent implements OnInit {

  constructor(private  authShareService: AuthShareService, private openWithPostShareService: OpenWithPostShareService,
              public menuShareService: MenuShareService) { }

  ngOnInit() {
  }
  intra() {
    const that = this;
    this.openWithPostShareService.openWindowWithPost('//intranet.escalconsulting.com/identification.php', {
      login: this.authShareService.authUser.oldLogin ,
      password: this.authShareService.authUser.oldPassword
    });
  }
  fileStation() {
    this.openWithPostShareService.openWindows('//docs.escalconsulting.com/index.cgi?launchApp=SYNO.SDS.App.FileStation3.Instance');
  }
  logout() {
    localStorage.clear();
  }
}
