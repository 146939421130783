import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../../environement/environement';



@Injectable()
export class StatistiqueIntranet {
  private statisticUrl = Environement.BASE_API_URL + '/api/statistique/intranet';
  private statisticUrl850 = Environement.BASE_API_URL + '/api/statistique';
  constructor(private http: HttpClient) { }

  getStatAgenceExist(idAuthUser) {
    const headers = this.initOptionHeader();
    return this.http.get(this.statisticUrl + '/agenceExiste/' + idAuthUser, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getStatistiqueIntranet(dateDebut, dateFin) {
    const headers = this.initOptionHeader();
    return this.http.get(this.statisticUrl + '/' + dateDebut + '/' + dateFin, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getStatistiqueMutualisations(dateDebut, dateFin) {
    const headers = this.initOptionHeader();
    return this.http.get(this.statisticUrl850 + '/mutualisation/' + dateDebut + '/' + dateFin, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getJournalisteInterview(dateDebut, dateFin) {
    const headers = this.initOptionHeader();
    return this.http.get(this.statisticUrl + '/journalisteArticle/' + dateDebut + '/' + dateFin, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getStatAgence(dateDebut, dateFin) {
    const headers = this.initOptionHeader();
    return this.http.get(this.statisticUrl + '/agence/' + dateDebut + '/' + dateFin, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getJournalisteInterviewExist(idAuthUser) {
    const headers = this.initOptionHeader();
    return this.http.get(this.statisticUrl + '/journalisteArticleExiste/' + idAuthUser, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getListe850(idAuthUser) {
    const headers = this.initOptionHeader();
    return this.http.get(this.statisticUrl850 + '/journalistesNonContactes/' + idAuthUser, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getListe850Existe(idAuthUser) {
    const headers = this.initOptionHeader();
    return this.http.get(this.statisticUrl + '/journalistesNonContactesExiste/' + idAuthUser, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getListeVeilleDateContacts() {
    const headers = this.initOptionHeader();
    return this.http.get(this.statisticUrl850 + '/listesVeilleDateContacts', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getListeVeilleDateContactsExiste() {
    const headers = this.initOptionHeader();
    return this.http.get(this.statisticUrl + '/listeVeilleDateContactsExiste', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getListeVeilleInfosContacts() {
    const headers = this.initOptionHeader();
    return this.http.get(this.statisticUrl850 + '/listesVeilleInfosContacts', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getListeVeilleInfosContactsExiste() {
    const headers = this.initOptionHeader();
    return this.http.get(this.statisticUrl + '/listeVeilleInfosContactsExiste', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getListeVeilleNbITWContacts() {
    const headers = this.initOptionHeader();
    return this.http.get(this.statisticUrl850 + '/listesVeilleNbITWContacts', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getListeVeilleNbITWContactsExiste() {
    const headers = this.initOptionHeader();
    return this.http.get(this.statisticUrl + '/listeVeilleNbITWContactsExiste', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  };
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
