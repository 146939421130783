
export class Civilite {
  id: string;
  libelle: string;
  genre: string;

  constructor(id: string, libelle: string, genre: string) {
    this.id = id;
    this.libelle = libelle;
    this.genre = genre;
  }
}
