import {Component, Input, OnInit} from '@angular/core';

import {ClientRoleUserService} from '../../../../../service/interne/client/ClientRoleUserService';
import {AuthShareService} from '../../../../../service/partage/AuthShareService';

import {RoleUserClient} from '../../../../../model/client/RoleUserClient';

@Component({
  selector: 'app-gestion-mes-client-detail-item',
  templateUrl: './gestion-mes-client-detail-item.component.html',
  styleUrls: ['./gestion-mes-client-detail-item.component.scss'],
  providers: [ClientRoleUserService]
})
export class GestionMesClientDetailItemComponent implements OnInit {

  @Input()
  clientRole: RoleUserClient;
  constructor(private clientRoleUserService: ClientRoleUserService, private authShareService: AuthShareService) { }

  ngOnInit() {
  }
  removeELem(){
    const that = this;
    this.clientRoleUserService.deleteRoleUserClient(this.clientRole.id)
      .then((retour) => {
        that.authShareService.getUserByAuthToken();
      });
  }
  changeClientRole(){
    this.clientRoleUserService.updateRoleUserClient(this.clientRole)
      .then((retour) => {
      });
  }

}
