import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';

import {ImageArticleSite} from '../../../model/site/ImageArticleSite';
import {Environement} from '../../../environement/environement';



@Injectable()
export class ImageArticleSiteService{
    private imageArticleUrl = Environement.BASE_API_URL + '/api/image_article_sites';
    constructor(private http: HttpClient) { }

  addImageArticle(imageArticleSite: ImageArticleSite, logoUpload, idArticle) {
    const headers = this.initOptionHeader();
    return this.http.post(this.imageArticleUrl , {'nom': imageArticleSite.nom, 'idArticle' : idArticle ,
      'value': logoUpload['value'], 'filetype': logoUpload['extension']}, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  deleteteImageArticle(imageArticleSite: ImageArticleSite){
    const headers = this.initOptionHeader();
    return this.http.delete( this.imageArticleUrl + '/'  + imageArticleSite.id ,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }


  private errorHandler(err: any): Promise<any> {
        return Promise.reject(err.message || err );
    };

    private initOptionHeader(){
        const token: any = localStorage.getItem('auth-tokens');
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.set('X-Auth-Token', token);
        return headers;
    }
}
