import {Component, OnInit} from '@angular/core';
import {Environement} from '../../../../../../environement/environement';
import {PressroomCommunique} from '../../../../../../model/client/pressroom/PressroomCommunique';
import {CommuniqueService} from '../../../../../../service/interne/client/pressroom/CommuniqueService';
import {PressroomShareService} from '../../../../../../service/partage/PressroomShareService';

@Component({
  selector: 'app-pressroom-client-configuration-communique',
  templateUrl: './pressroom-client-configuration-communique.component.html',
  styleUrls: ['./pressroom-client-configuration-communique.component.scss'],
  providers: [CommuniqueService]
})
export class PressroomClientConfigurationCommuniqueComponent implements OnInit {

  photoUpload: Array<any> = [];
  pdfUpload: Array<any> = [];
  editCommunique: PressroomCommunique;
  noCacheVal:any;

  constructor( private communiqueService: CommuniqueService, public pressroomShareService: PressroomShareService) { }

  ngOnInit() {
    this.noCacheVal = this.NoCache();
    this.pressroomShareService.loadCommuniques();
    this.editCommunique = null;
  }

  NoCache() {
    const a =  Math.floor(Math.random() * Math.floor(99999));
    const b = Math.floor(Math.random() * Math.floor(99999));
    return '?' + a + b;
  }

  getPhotoUrl(communique: PressroomCommunique) {
    return Environement.BASE_API_URL + '/pressroomClient/' + this.pressroomShareService.pressroom.id + '/communique/' +
      communique.ancienId  + '.' + communique.photo + this.noCacheVal;
  }
  getPdfUrl(communique: PressroomCommunique) {
    return Environement.BASE_API_URL + '/pressroomClient/' + this.pressroomShareService.pressroom.id + '/communique/' +
      communique.ancienId  + '.pdf' + this.noCacheVal;
  }

  changePhoto(event) {
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        that.photoUpload['filename'] = file.name;
        that.photoUpload['filetype'] = file.type;
        // @ts-ignore
        that.photoUpload['value'] = reader.result.split(',')[1];
      };
    }
  }

  savePhoto() {
    const that = this;
    this.communiqueService.addPhotoToCommunique(this.pressroomShareService.pressroom.id, this.editCommunique.id, this.photoUpload)
      .then((retour) => {
        that.pressroomShareService.loadCommuniques();
        that.editCommunique = null;
      });
  }

  changePdf(event) {
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        that.pdfUpload['filename'] = file.name;
        that.pdfUpload['filetype'] = file.type;
        // @ts-ignore
        that.pdfUpload['value'] = reader.result.split(',')[1];
      };
    }
  }

  savePdf() {
    const that = this;
    this.communiqueService.changePdf(this.pressroomShareService.pressroom.id, this.editCommunique.id, this.pdfUpload)
      .then((retour) => {
        that.pressroomShareService.loadCommuniques();
        that.editCommunique = null;
      });
  }

  deletePhoto() {
    const that = this;
    this.communiqueService.delPhotoCommunique(this.pressroomShareService.pressroom.id , this.editCommunique.id)
      .then((retour) => {
        that.pressroomShareService.loadCommuniques();
        that.editCommunique = null;
      });
  }

  saveTitreCommunique() {
    const that = this;
    this.communiqueService.saveTitreCommunique(this.editCommunique)
      .then(() => {
        that.pressroomShareService.loadCommuniques();
        that.editCommunique = null;
      });
  }
}
