import {Component, Input, OnInit} from '@angular/core';
import {DndDropEvent, DropEffect} from 'ngx-drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ImportListeContactHaShareService} from '../../../../../service/partage/import/ImportListeContactHaShareService';

@Component({
  selector: 'app-import-liste-contact-ha-choix-colone',
  templateUrl: './import-liste-contact-ha-choix-colone.component.html',
  styleUrls: ['./import-liste-contact-ha-choix-colone.component.scss']
})
export class ImportListeContactHaChoixColoneComponent implements OnInit {

  layout:any;
  horizontalLayoutActive:boolean = false;
  private currentDraggableEvent: DragEvent;
  private currentDragEffectMsg: string;
  private readonly verticalLayout = {
    container: "row",
    list: "column",
    dndHorizontal: false
  };
  private readonly horizontalLayout = {
    container: "row",
    list: "row",
    dndHorizontal: true
  };

  constructor( private snackBarService: MatSnackBar, public importListeContactHaShareService: ImportListeContactHaShareService ) {
    this.setHorizontalLayout( this.horizontalLayoutActive );
    this.importListeContactHaShareService.initTabTitreColonne();
  }

  setHorizontalLayout( horizontalLayoutActive:boolean ) {

    this.layout = (horizontalLayoutActive) ? this.horizontalLayout : this.verticalLayout;
  }

  onDragStart( event:DragEvent ) {

    this.currentDragEffectMsg = "";
    this.currentDraggableEvent = event;

    this.snackBarService.dismiss();
  }



  onDrop( event: DndDropEvent, list?:any[] ) {

    if( list
      && (event.dropEffect === "copy"
        || event.dropEffect === "move") ) {

      let index = event.index;

      if( typeof index === "undefined" ) {

        index = list.length;
      }

      list.splice( index, 0, event.data );
    }
  }
  onDragged( item:any, list:any[], effect:DropEffect ) {

    if( effect === "move" ) {
      const index = list.indexOf( item );
      list.splice( index, 1 );
    }
  }

  onDragEnd( event:DragEvent ) {
  }
  ngOnInit() {
  }

  nonPresent(item: any, list:any[]){
    const index = list.indexOf( item );
    list.splice( index, 1 );
  }
  valider() {
    this.importListeContactHaShareService.valideColonne();
  }
}
