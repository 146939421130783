import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../../environement/environement';
import {PressroomAccueil} from '../../../../model/client/pressroom/PressroomAccueil';


@Injectable()
export class PressroomAccueilService{
    private pressroomAccueilUrl = Environement.BASE_API_URL + '/api/pressroom_accueils';
    constructor(private http: HttpClient) { }

  updateTitle(pressroomAccueil: PressroomAccueil) {
    const headers = this.initOptionHeader();
    return this.http.put(this.pressroomAccueilUrl + '/' + pressroomAccueil.id , {'title': pressroomAccueil.title,
      'couleurTitle': pressroomAccueil.couleurTitle}, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addTitle(pressroomAccueil: PressroomAccueil, idPressroom){
    const headers = this.initOptionHeader();
    return this.http.post(this.pressroomAccueilUrl , {'title': pressroomAccueil.title, 'couleurTitle': pressroomAccueil.couleurTitle,
      'pressroom': Environement.BASE_API_URL + '/api/pressrooms/' + idPressroom }, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getAccueilPressroom(idPressroomAccueil){
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/api/pressroom_accueils/' + idPressroomAccueil,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
    private errorHandler(err: any): Promise<any> {
        return Promise.reject(err.message || err );
    };
    private initOptionHeader(){
        const token: any = localStorage.getItem('auth-tokens');
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.set('X-Auth-Token', token);
        return headers;
    }
}
