import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';

@Injectable()
export class AuthUserGroupeUserService {
  private authUserUrl = Environement.BASE_API_URL + '/api/auth_user_groupe_users';
  constructor(private http: HttpClient) { }

  addMember(iduser, idgroupe): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post(this.authUserUrl, {'groupe': '/api/groupe_users/' + idgroupe, 'user': '/api/auth_users/' + iduser},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  delMember(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.delete(this.authUserUrl + '/' + id,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getUserGroupeByClientAuthUser(idClient, idUser) {

  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
