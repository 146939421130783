import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';

@Injectable()
export class SuivitMaillingService{
    private suivitMaillingUrl = Environement.BASE_API_URL + '/api/suivit_ouverture_maillings';
    constructor(private http: HttpClient) { }

  getSuvitMailling(idMailling) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suivitMaillingUrl + '?pagination=false&mailling.id=' + idMailling, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers; }
}
