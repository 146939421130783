import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';
import {Contact} from '../../../model/contact/Contact';

@Injectable()
export class LangueService {
  contact: Contact;
  private langueURL = Environement.BASE_API_URL + '/api/langues';
  constructor(private http: HttpClient) {
  }
  getLangues() {
    const headers = this.initOptionHeader();
    return this.http.get(this.langueURL, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getLangueByID(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.langueURL + '/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
