import {Component, Input, OnInit} from '@angular/core';

import {MenuShareService} from '../../../../../../service/partage/utilitaire/MenuShareService';

import {SousMenu} from '../../../../../../model/utilitaire/menu/Sous-menu';

@Component({
  selector: 'app-structure-menu-gauche-content-element',
  templateUrl: './structure-menu-gauche-content-element.component.html',
  styleUrls: ['./structure-menu-gauche-content-element.component.scss']
})
export class StructureMenuGaucheContentElementComponent implements OnInit {

  @Input()
  sousMenu: SousMenu;
  constructor(public menuShareService: MenuShareService) { }

  ngOnInit() {
  }

}
