import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {ClientService} from '../../../service/interne/client/ClientService';
import {ClientRoleUserService} from '../../../service/interne/client/ClientRoleUserService';
import {AuthUserService} from '../../../service/interne/user/AuthUserService';
import {AuthShareService} from '../../../service/partage/AuthShareService';


import {AuthUser} from '../../../model/user/authUser';
import {Client} from '../../../model/client/Client';
import {RoleUserClient} from '../../../model/client/RoleUserClient';

@Component({
  selector: 'app-administration-client-role-user',
  templateUrl: './administration-client-role-user.component.html',
  styleUrls: ['./administration-client-role-user.component.scss'],
  providers: [ ClientService, ClientRoleUserService, AuthUserService]
})
export class AdministrationClientRoleUserComponent implements OnInit {

  sub: any;
  id: any;
  ouvert = false;
  clients: Array<Client>;
  clientSelect: Array<Client>;
  clientIdSelect;
  authUser: AuthUser;

  constructor(private authShareService: AuthShareService, private clientService: ClientService,
              private clientRoleUserService: ClientRoleUserService, private route: ActivatedRoute,
              private authUserService: AuthUserService) {
    document.addEventListener('click', (event) => this.onClick(event));
  }

  ngOnInit() {
    const that = this;
    this.sub = this.route.params.subscribe(params => {
      that.id = params['id'];
      that.authUserService.getUser(that.id)
        .then((retour) =>{
          that.authUser = retour as AuthUser;
        });
    });
    this.clientService.getClientList(1)
      .then((retour) => {
        that.clients = retour['hydra:member'] as Array<Client>;
        that.initClientSelect();
      });
  }

  onClick(event) {
    const elem = event['target'];
    if (elem.classList.contains('modal-overlay')) {
      this.ouvert = false;
    }
  }

  displayModale() {
    if (this.ouvert) {
      return 'inline-block';
    }
    return 'none';
  }

  initClientSelect() {
    const clientSelectVal: Array<Client> = [];
    const that = this;
    this.clients.forEach((client: Client) => {
      let present = 0;
      that.authUser.rolesUserClient.forEach((clientRole: RoleUserClient) => {
        if (clientRole.client.id === client.id) {
          present = 1;
        }
      });
      if (present === 0) {
        clientSelectVal.push(client);
      }
    });
    this.clientSelect = clientSelectVal;
    if (this.clientSelect.length === 0) {
      this.clientIdSelect = '0';
    } else {
      this.clientIdSelect = this.clientSelect[0].id;
    }
  }

  ajouter() {
    const that = this;
    this.clientRoleUserService.addClientRoleUser(this.clientIdSelect, this.authUser.id)
      .then((retour) => {
        that.ouvert = false;
        that.ngOnInit();
      });
  }

}
