import { Component, OnInit } from '@angular/core';
import {Actualite} from '../../../../model/actualite/Actualite';
import {AuthShareService} from '../../../../service/partage/AuthShareService';
import {Client} from '../../../../model/client/Client';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {ActualiteService} from '../../../../service/interne/actualite/ActualiteService';
import {ChiffreCleService} from '../../../../service/interne/actualite/ChiffreCleService';
import {InnovationService} from '../../../../service/interne/actualite/InnovationService';
import {PerspectiveService} from '../../../../service/interne/actualite/PerspectiveService';
import {ThemeDescriptifService} from '../../../../service/interne/actualite/ThemeDescriptifService';
import {Router} from '@angular/router';

@Component({
  selector: 'app-escal-actualite-add',
  templateUrl: './escal-actualite-add.component.html',
  styleUrls: ['./escal-actualite-add.component.scss'],
  providers: [ ClientService, ActualiteService, ChiffreCleService, InnovationService, PerspectiveService, ThemeDescriptifService ]
})
export class EscalActualiteAddComponent implements OnInit {

  clients: Array<Client>;
  clientId: number;
  actualite: Actualite;
  chiffresCles: string;
  themeDescriptifs: string;
  innovations: string;
  perspectives: string;
  constructor(private authShareService: AuthShareService, private clientService: ClientService, private actualiteService: ActualiteService,
              private dialogueShareService: DialogueShareService, private chiffreCleService: ChiffreCleService,
              private innovationService: InnovationService, private perspectiveService: PerspectiveService,
              private themeDescriptifService: ThemeDescriptifService, private router: Router) { }

  ngOnInit() {
    this.clientId = 0;
    this.chiffresCles = '';
    this.themeDescriptifs = '';
    this.innovations = '';
    this.perspectives = '';
    this.actualite = new Actualite(0, '', '', '', '', new Date() , null, this.authShareService.authUser,
      null, [], [], [], [], null);
    this.clientService.getClientList(1)
      .then((clients) => {
        this.clients = clients['hydra:member'] as Array<Client>;
      });
  }

  creerActualite() {
    // si informations obligatoires non renseignées
    if (this.actualite.nom != '') {
      if (this.actualite.pitch != '') {
        if (this.clientId != 0) {
          // pour chaque saut de ligne, séparer les chiffres clés en tableau
          let contentChiffres = '';
          for (let i = 0; i < this.chiffresCles.length; i++) {
            if (this.chiffresCles.charCodeAt(i) === 10) {
              contentChiffres = contentChiffres + '~#';
            } else {
              contentChiffres = contentChiffres + this.chiffresCles.charAt(i);
            }
          }
          const tabChiffres = contentChiffres.split('~#');
          // pour chaque saut de ligne, séparer les thèmes descriptifs en tableau
          let contentThemes = '';
          for (let i = 0; i < this.themeDescriptifs.length; i++) {
            if (this.themeDescriptifs.charCodeAt(i) === 10) {
              contentThemes = contentThemes + '~#';
            } else {
              contentThemes = contentThemes + this.themeDescriptifs.charAt(i);
            }
          }
          const tabThemes = contentThemes.split('~#');
          // pour chaque saut de ligne, séparer les innovations en tableau
          let contentInnovations = '';
          for (let i = 0; i < this.innovations.length; i++) {
            if (this.innovations.charCodeAt(i) === 10) {
              contentInnovations = contentInnovations + '~#';
            } else {
              contentInnovations = contentInnovations + this.innovations.charAt(i);
            }
          }
          const tabInnovations = contentInnovations.split('~#');
          // pour chaque saut de ligne, séparer les perspectives en tableau
          let contentPerspectives = '';
          for (let i = 0; i < this.perspectives.length; i++) {
            if (this.perspectives.charCodeAt(i) === 10) {
              contentPerspectives = contentPerspectives + '~#';
            } else {
              contentPerspectives = contentPerspectives + this.perspectives.charAt(i);
            }
          }
          const tabPerspectives = contentPerspectives.split('~#');
          // creer actualité
          this.actualiteService.addActualite(this.actualite, this.clientId)
            .then(async actualite => {
              // creer chiffres cles asso
              await tabChiffres.forEach(chiffre => {
                this.chiffreCleService.addChiffreCle(chiffre, actualite.id).then();
              });
              // creer themes descriptifs asso
              await tabThemes.forEach(theme => {
                this.themeDescriptifService.addThemeDescriptif(theme, actualite.id).then();
              });
              // creer innovations asso
              await tabInnovations.forEach(innovation => {
                this.innovationService.addInnovation(innovation, actualite.id).then();
              });
              // creer perspectives asso
              await tabPerspectives.forEach(perspective => {
                this.perspectiveService.addPerspective(perspective, actualite.id).then();
              });
              // enregistrer actualite dans la base d'intranet
              await this.actualiteService.importerActualiteIntranet(actualite.id, 0).then((ancienIdActu) => {
                // insérer l'id d'intranet dans ancienId d'escalnet
                this.actualiteService.addAncienIdActualite(Number(ancienIdActu), actualite.id).then((retour) => {
                  // rappeler la fonction, cette fois pour s'assurer que les promises soient terminés
                  this.actualiteService.importerActualiteIntranet(actualite.id, Number(ancienIdActu)).then((fin) => {
                    this.router.navigate(['/escal/actualites']);
                    this.dialogueShareService.addDialogue('L\'actualité a bien été créée');
                  });
                });
              });
            });
        } else {
          this.dialogueShareService.addDialogue('Le client est obligatoire');
        }
      } else {
        this.dialogueShareService.addDialogue('Le pitch est obligatoire');
      }
    } else {
      this.dialogueShareService.addDialogue('Le nom est obligatoire');
    }
  }

}
