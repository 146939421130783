import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Client} from '../../../../../model/client/Client';
import {Environement} from '../../../../../environement/environement';

import {MotsCles} from '../../../../../model/client/motsCles/MotsCles';
import {AuthUser} from '../../../../../model/user/authUser';
import {AuthUserGroupeUser} from '../../../../../model/user/AuthUserGroupeUser';
import {UserGroupe} from '../../../../../model/user/UserGroupe';
import {ClientService} from '../../../../../service/interne/client/ClientService';
import {MotsClesService} from '../../../../../service/interne/client/motsCles/MotsClesService';
import {AuthUserService} from '../../../../../service/interne/user/AuthUserService';
import {AuthShareService} from '../../../../../service/partage/AuthShareService';
import {CategoriesService} from '../../../../../service/interne/client/motsCles/CategoriesService';
import {CategorieDesMotsCles} from '../../../../../model/client/motsCles/CategorieDesMotsCles';
import {formatDate} from '@angular/common';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';

@Component({
  selector: 'app-escal-client-fiche-mots-cles',
  templateUrl: './escal-client-fiche-mots-cles.component.html',
  styleUrls: ['./escal-client-fiche-mots-cles.component.scss'],
  providers: [ AuthUserService ],
})
export class EscalClientFicheMotsClesComponent implements OnInit {

  @Input()
  client: Client;
  @Output()
  refresh = new EventEmitter();
  addOpen: boolean;
  motsCles: Array<MotsCles>;
  categoriesList: Array<CategorieDesMotsCles>;
  motsClesCheckID: Array<any>;
  ouvrirAddVal: boolean;
  MotCleTrouve: MotsCles;
  active = '';
  MotclesChecked = [];

  constructor(private motsClesService: MotsClesService, private authUserService: AuthUserService, private clientService: ClientService,
              private authShareService: AuthShareService, private categoriesService: CategoriesService,
              private dialogueShareService: DialogueShareService) { }

  ngOnInit() {
    const that = this;
    this.client.motsCles.forEach(motCle => {
      this.MotclesChecked.push(motCle.id);
    });
    this.closeAddOpen();
    that.categoriesService.getCategories()
      .then((categories) => {
        that.categoriesList = categories['hydra:member'];
      });
  }

  actived(categ) {
    if (categ === this.active) {
      this.active = '';
    } else {
      this.active = categ ;
    }
  }

  changeMotsCles(event, MotCle) {
    let action;
    let positionMotsCles;
    let positionMotsClesChecked;
    let i = 0;
    let trouve = false;
    this.client.motsCles.forEach(motCle => {
      if (motCle.id === MotCle.id) {
        trouve = true;
        positionMotsCles = i ;
      }
      i = i + 1;
    });
    if (!trouve) {
      this.client.motsCles.push(MotCle);
      this.MotclesChecked.push(MotCle.id);
      action = 'ajout';
    } else {
      this.client.motsCles.splice(positionMotsCles, 1);
      i = 0;
      trouve = false;
      this.MotclesChecked.forEach(motCleChecked => {
        if (motCleChecked === MotCle.id) {
          trouve = true;
          positionMotsClesChecked = i ;
        }
        i = i + 1;
      });
      this.MotclesChecked.splice(positionMotsClesChecked, 1);
      action = 'supp';
    }
    this.motsCles = this.client.motsCles;
    this.clientService.updateMotsCles(this.client, MotCle.id, action)
      .then(() => {
      });
  }

  sendChangeToApi() {
    let dateEntree = this.client.dateEntree;
    let dateSortie = this.client.dateSortie;
    if (this.client.dateEntree.toString().includes('T00:00:00')  == false) {
      dateEntree = this.formatDate(this.client.dateEntree);
    }
    if (this.client.dateSortie.toString().includes('T00:00:00')  == false) {
      dateSortie =  this.formatDate(this.client.dateSortie);
    }
    if (dateEntree > dateSortie) {
      this.dialogueShareService.addDialogue('Dates saisies incohérentes');
    } else {
      this.clientService.updateClientMotsCles(this.client)
        .then(() => {
        });
    }
  }
  formatDate(date) {
     let dateFormater;
     let dateOK;
     let mois;
     let jour;
     dateOK = new Date(date.getTime() + (2 * 1000 * 60 * 60));
     mois = dateOK.getMonth();
     jour = dateOK.getDate();
     if (dateOK.getMonth().toString().length == 1) {
       mois = '0' + dateOK.getMonth();
     }
     if (dateOK.getDate().toString().length == 1) {
      jour = '0' + dateOK.getDate();
    }
     dateFormater = dateOK.getFullYear() + '-' + (mois + 1).toString() + '-' + jour + 'T00:00:00+00:00';
     return dateFormater;
  }
  setSelected(selectElement) {
    const motsClesGroupe: Array<any> = [];
    for (let i = 0; i < selectElement.options.length; i++) {
      if (selectElement.options[i].selected) {
        motsClesGroupe.push(selectElement.options[i].value);
      }
    }
    this.motsClesCheckID = motsClesGroupe;
  }

  ajouterMotsCles() {
    const that = this;
    this.ouvrirAddVal = false;
    let action;
    action = 'ajout';
    this.motsClesCheckID.forEach((idMotCle) => {
      that.clientService.updateMotsCles(this.client, idMotCle, action)
        .then( () => {
        });
      that.motsClesService.getMotCleByID(idMotCle)
        .then( (motsCles) => {
          that.MotCleTrouve = motsCles as MotsCles;
          this.client.motsCles.push(that.MotCleTrouve);
        });
    });
  }


  supprimerMotCle(motCle) {
    let present;
    let action ;
    const that = this;
    action = 'supp';
    that.dialogueShareService.addDialogue('êtes-vous sûr de vouloir supprimer ce mot clé ?', function () {
      that.clientService.updateMotsCles(that.client, motCle.id, action)
        .then(() => {
        });
      present = that.client.motsCles.indexOf(motCle);
      that.client.motsCles.splice(present, 1);
      that.dialogueShareService.addDialogue('Le mot clé est supprimé');
    });
  }

  closeAddOpen() {
    this.addOpen = false;
  }
  filterFin = (d: Date): boolean => {
    if (this.client.dateEntree !== undefined) {
      if (d > this.client.dateEntree) {
        return true;
      } else if (d === this.client.dateEntree) {
        if (d.getMonth() > this.client.dateEntree.getMonth()) {
          return true;
        } else if (d.getMonth() === this.client.dateEntree.getMonth()) {
          if (d.getDate() > this.client.dateEntree.getDate()) {
            return true;
          }
        }
      }
    }
  }
}
