import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../../environement/environement';


@Injectable()
export class SuggestionService {
  private suggestionUrl = Environement.BASE_API_URL + '/api/suggestions';
  constructor(private http: HttpClient) { };

  addSuggestion(suggestion, categId): Promise<any> {
    const headers = this.initOptionHeader();
    const Date = suggestion.date.getFullYear() + '-' + (suggestion.date.getMonth() + 1).toString() + '-' + suggestion.date.getDate()
      + ' ' + suggestion.date.getHours() + ':' + suggestion.date.getMinutes() + ':00' ;
    return this.http.post(this.suggestionUrl, { 'priorite': suggestion.priorite, 'cible': suggestion.cible,
        'description': suggestion.description, 'statut': Environement.BASE_API_URL + '/api/statuts/1',
        'categorieSuggestion': Environement.BASE_API_URL + '/api/categorie_suggestions/' + categId, 'date': Date,
        'auteur': Environement.BASE_API_URL + '/api/auth_users/' + suggestion.auteur.id},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  demandeEnvoyeeMail(suggestionId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suggestionUrl + '/envoyer/mail/' + suggestionId ,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  };
  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
