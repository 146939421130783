import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';


import {AuthUserService} from '../../../../service/interne/user/AuthUserService';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {AuthShareService} from '../../../../service/partage/AuthShareService';

import {AuthUser} from '../../../../model/user/authUser';

@Component({
  selector: 'app-escal-utilisateur-profil-public',
  templateUrl: './escal-utilisateur-profil-public.component.html',
  styleUrls: ['./escal-utilisateur-profil-public.component.scss'],
  providers: [ AuthUserService ]
})
export class EscalUtilisateurProfilPublicComponent implements OnInit {

  id: number;
  authUser: AuthUser;
  active: string = 'information';
  sub: any;

  constructor(private  route: ActivatedRoute, private authUserService: AuthUserService, private dialogueShareService: DialogueShareService,
              public authShareService: AuthShareService) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.authUserService.getUser(this.id)
        .then((retour) => {
          this.authUser = retour as AuthUser;
        });
    });
  }

  actived(type){
    if ( type === this.active) {
      return 'active';
    }
  }

  desactiverUser(){
    const that = this;

    const variable = {
      'authUserService': this.authUserService, 'id': this.authUser.id
      , 'dialogueShareService': this.dialogueShareService,
    };
    this.dialogueShareService.addDialogue('êtes vous sûre de vouloir désactiver l\'utilisateur', function () {
      variable['authUserService'].desactiverUser(variable['id'])
        .then((retour) => {
          variable['dialogueShareService'].addDialogue('utilisateur desactiver');
        })
        .catch((retour) => {
          variable['dialogueShareService'].addDialogue('l\'utilisateur n\'a pas pu être désactiver');
        });
    });
  }

}
