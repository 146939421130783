import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../../environement/environement';
import {PressroomAccueilSection} from '../../../../model/client/pressroom/PressroomAccueilSection';
import {ImagePressroom} from '../../../../model/client/pressroom/ImagePressroom';


@Injectable()
export class PressroomAccueilSectionService{
    private pressroomAccueilSectionUrl = Environement.BASE_API_URL + '/api/pressroom_accueil_sections';
    constructor(private http: HttpClient) { }

  addPressroomAccueilSection(pressroomAccueilSection: PressroomAccueilSection, pressroomAccueilId, image: ImagePressroom) {
    const headers = this.initOptionHeader();
    if (image === null) {
      return this.http.post(this.pressroomAccueilSectionUrl , {'nom': pressroomAccueilSection.nom,
        'content': pressroomAccueilSection.content, 'contentDisposition': pressroomAccueilSection.contentDisposition,
        'imagePosition': pressroomAccueilSection.imagePosition, 'title': pressroomAccueilSection.title,
        'couleurTitle': pressroomAccueilSection.couleurTitle,
        'pressroomAccueil': Environement.BASE_API_URL + '/api/pressroom_accueils/' + pressroomAccueilId}, {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    } else {
      return this.http.post(this.pressroomAccueilSectionUrl , {'nom': pressroomAccueilSection.nom,
        'content': pressroomAccueilSection.content, 'contentDisposition': pressroomAccueilSection.contentDisposition,
        'imagePosition': pressroomAccueilSection.imagePosition, 'title': pressroomAccueilSection.title,
        'couleurTitle': pressroomAccueilSection.couleurTitle,
        'pressroomAccueil': Environement.BASE_API_URL + '/api/pressroom_accueils/' + pressroomAccueilId,
        'image': Environement.BASE_API_URL + '/api/image_pressrooms/' + image.id}, {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }

  }
  updatePressroomAccueilSection(pressroomAccueilSection: PressroomAccueilSection, image: ImagePressroom){
    const headers = this.initOptionHeader();
    if (image === null) {
      return this.http.put(this.pressroomAccueilSectionUrl  + '/' + pressroomAccueilSection.id , {'nom': pressroomAccueilSection.nom,
        'content': pressroomAccueilSection.content, 'contentDisposition': pressroomAccueilSection.contentDisposition,
        'imagePosition': pressroomAccueilSection.imagePosition, 'title': pressroomAccueilSection.title,
        'couleurTitle': pressroomAccueilSection.couleurTitle, 'image' : null }, {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    } else {
      return this.http.put(this.pressroomAccueilSectionUrl  + '/' + pressroomAccueilSection.id , {'nom': pressroomAccueilSection.nom,
        'content': pressroomAccueilSection.content, 'contentDisposition': pressroomAccueilSection.contentDisposition,
        'imagePosition': pressroomAccueilSection.imagePosition, 'title': pressroomAccueilSection.title,
        'couleurTitle': pressroomAccueilSection.couleurTitle, 'image': Environement.BASE_API_URL + '/api/image_pressrooms/' + image.id }, {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }

    private errorHandler(err: any): Promise<any> {
        return Promise.reject(err.message || err );
    };
    private initOptionHeader(){
        const token: any = localStorage.getItem('auth-tokens');
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.set('X-Auth-Token', token);
        return headers;
    }
}
