import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ValidationCongeService} from "../../../service/interne/administration/ValidationCongeService";

@Component({
  selector: 'app-administration-validation-conge',
  templateUrl: './administration-validation-conge.component.html',
  styleUrls: ['./administration-validation-conge.component.scss'],
  providers: [ ValidationCongeService ]
})
export class AdministrationValidationCongeComponent implements OnInit {

  id: any;
  rand: any;
  sub: any;
  message: any;

  constructor(private route: ActivatedRoute, private validationCongeService: ValidationCongeService) { }

  ngOnInit() {
    const that = this;
    this.sub = this.route.params.subscribe(params => {
      that.id = params['id'];
      that.rand = params['rand'];
      that.validationCongeService.getValidationConge(that.id, that.rand)
        .then((retour) => {
          that.message = retour;
        });
    });
  }

}
