import {Component, Input, OnInit} from '@angular/core';
import {Salle} from '../../../../model/lieux/Salle';

@Component({
  selector: 'app-administration-salle-item',
  templateUrl: './administration-salle-item.component.html',
  styleUrls: ['./administration-salle-item.component.scss']
})
export class AdministrationSalleItemComponent implements OnInit {

  @Input() salle: Salle;

  constructor() { }

  ngOnInit() {
  }

}
