import { Component, OnInit } from '@angular/core';
import {Organisation} from '../../../../model/organisation/Organisation';
import {AuthUser} from '../../../../model/user/authUser';
import {AuthUserService} from '../../../../service/interne/user/AuthUserService';
import {FormeJuridique} from '../../../../model/organisation/FormeJuridique';
import {FormeJuridiqueService} from '../../../../service/interne/organisation/FormeJuridiqueService';
import {Genre} from '../../../../model/contact/Genre';
import {GenreService} from '../../../../service/interne/genre/GenreService';
import {CodeApe} from '../../../../model/organisation/CodeApe';
import {CodeApeService} from '../../../../service/interne/organisation/CodeApeService';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {OrganisationService} from '../../../../service/interne/organisation/OrganisationService';
import {Pays} from '../../../../model/information/Pays';
import {PaysService} from '../../../../service/interne/contact/PaysService';

@Component({
  selector: 'app-escal-organisation-recherche',
  templateUrl: './escal-organisation-recherche.component.html',
  styleUrls: ['./escal-organisation-recherche.component.scss'],
  providers: [AuthUserService, FormeJuridiqueService, GenreService, CodeApeService, OrganisationService, PaysService]
})
export class EscalOrganisationRechercheComponent implements OnInit {

  rechercheEnCours: boolean;
  affiche: boolean;
  afficheCateg: boolean;
  rechercheEncoursDirigeant = false;
  rechercheEncoursCateg = false;
  creeChoisi: string;
  nom: string;
  siren: string;
  capital: string;
  effectif: string;
  implantation: string;
  premierTel: string;
  deuxiemeTel: string;
  email: string;
  premierURL: string;
  deuxiemeURL: string;
  rue: string;
  complements: string;
  codePostal: string;
  boitePostale: string;
  ville: string;
  champRechercheDirigeant: string;
  champRechercheCateg: string;
  formeId: number;
  genreId: number;
  codeId: number;
  dirigeantId: number;
  categId: number;
  paysId: number;
  jours: number;
  suiviPar: number;
  total: number;
  maxPage: number;
  societesRecherchesAvancees: Array<Organisation>;
  formes: Array<FormeJuridique>;
  genres: Array<Genre>;
  codes: Array<CodeApe>;
  pays: Array<Pays>;
  users: Array<AuthUser> = [];
  dirigeantsRecherche: Array<any> = [];
  categoriesRecherche: Array<any> = [];
  curentPage = 1;
  headerContent: Array<any> = [];
  constructor(private authUserService: AuthUserService, private formeJuridiqueService: FormeJuridiqueService,
              private genreService: GenreService, private codeApeService: CodeApeService,
              public formatIntranetShareService: FormatIntranetShareService, private organisationService: OrganisationService,
              private paysService: PaysService) { }

  ngOnInit() {
    this.headerContent.push('isOrganisation');
    this.headerContent.push('Nom');
    this.headerContent.push('Genre');
    this.headerContent.push('Forme');
    this.headerContent.push('Catégorie');

    this.jours = 0;
    this.suiviPar = 0;
    this.formeId = 0;
    this.genreId = 0;
    this.codeId = 0;
    this.dirigeantId = 0;
    this.categId = 0;
    this.paysId = 0;
    this.creeChoisi = '';
    this.nom = '';
    this.siren = '';
    this.capital = '';
    this.effectif = '';
    this.implantation = '';
    this.premierTel = '';
    this.deuxiemeTel = '';
    this.email = '';
    this.premierURL = '';
    this.deuxiemeURL = '';
    this.rue = '';
    this.complements = '';
    this.codePostal = '';
    this.boitePostale = '';
    this.ville = '';
    this.champRechercheDirigeant = '';
    this.champRechercheCateg = '';
    this.rechercheEnCours = false;
    this.affiche = false;
    this.afficheCateg = false;
    this.societesRecherchesAvancees = [];
    this.formeJuridiqueService.getFormesJuridiques().then(formesJ => {
      this.formes = formesJ['hydra:member'] as Array<FormeJuridique>;
    });
    this.genreService.getGenres().then(genres => {
      this.genres = genres['hydra:member'] as Array<Genre>;
    });
    this.codeApeService.getCodes().then(codes => {
      this.codes = codes['hydra:member'] as Array<CodeApe>;
    });
    this.paysService.getAllPays().then(pays => {
      this.pays = pays['hydra:member'] as Array<Pays>;
    });
    this.authUserService.getUsersList(1).then((usersRetour) => {
      this.users = usersRetour['hydra:member'] as Array<AuthUser>;
    });
  }

  rechercheDirigeant() {
    this.affiche = true;
    if (this.champRechercheDirigeant.length > 2 && !this.rechercheEncoursDirigeant) {
      this.rechercheEncoursDirigeant = true;
      this.champRechercheDirigeant = this.champRechercheDirigeant.replace(' ', '.');
      this.organisationService.getDirigeantRecherche(this.champRechercheDirigeant).then((retour) => {
        this.dirigeantsRecherche = retour;
        this.dirigeantsRecherche.forEach(dirigeant => {
          if (dirigeant.nomPrenom != '' && dirigeant.nomPrenom != null) {
            dirigeant.nomPrenom = dirigeant.nomPrenom.replace('&amp;', '&');
            dirigeant.nomPrenom = dirigeant.nomPrenom.replace('&#039;', '’');
            dirigeant.nomPrenom = dirigeant.nomPrenom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheDirigeant = this.champRechercheDirigeant.replace('.', ' ');
      });
      this.rechercheEncoursDirigeant = false;
    }
  }

  rechercheCategories() {
    this.afficheCateg = true;
    if (this.champRechercheCateg.length > 2 && !this.rechercheEncoursCateg) {
      this.rechercheEncoursCateg = true;
      this.champRechercheCateg = this.champRechercheCateg.replace(' ', '.');
      this.organisationService.getCategoriesRecherche(this.champRechercheCateg).then((retour) => {
        this.categoriesRecherche = retour;
        this.categoriesRecherche.forEach(categorie => {
          if (categorie.nom != '' && categorie.nom != null) {
            categorie.nom = categorie.nom.replace('&amp;', '&');
            categorie.nom = categorie.nom.replace('&#039;', '’');
            categorie.nom = categorie.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheCateg = this.champRechercheCateg.replace('.', ' ');
      });
      this.rechercheEncoursCateg = false;
    }
  }

  rechercherSocietes() {
    this.organisationService.getSocietesRechercheAvancee(this.nom, this.siren, this.capital, this.effectif,
      this.implantation, this.formeId, this.genreId, this.suiviPar, this.codeId, this.dirigeantId, this.categId,
      this.premierTel, this.deuxiemeTel, this.email, this.premierURL, this.deuxiemeURL, this.rue, this.complements,
      this.codePostal, this.boitePostale, this.paysId, this.creeChoisi, this.jours, this.curentPage).then((retour) => {
      this.societesRecherchesAvancees = retour['hydra:member'] as Array<Organisation>;
      this.total = retour['hydra:totalItems'];
      this.getMaxPage(retour['hydra:totalItems']);
      this.rechercheEnCours = true;
    });
  }

  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }

  changePage(page) {
    this.curentPage = page;
    this.rechercherSocietes();
  }

  reprendreRecherche() {
    this.rechercheEnCours = false;
    this.curentPage = 1;
  }

}
