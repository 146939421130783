import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-escal-suivi-gestion-organisation',
  templateUrl: './escal-suivi-gestion-organisation.component.html',
  styleUrls: ['./escal-suivi-gestion-organisation.component.scss']
})
export class EscalSuiviGestionOrganisationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
