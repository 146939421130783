import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Organisation} from '../../../../../model/organisation/Organisation';
import {OrganisationService} from '../../../../../service/interne/organisation/OrganisationService';
import {ContactOrganisation} from '../../../../../model/contact/ContactOrganisation';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';

@Component({
  selector: 'app-escal-organisation-fiche-contacts',
  templateUrl: './escal-organisation-fiche-contacts.component.html',
  styleUrls: ['./escal-organisation-fiche-contacts.component.scss']
})
export class EscalOrganisationFicheContactsComponent implements OnInit {

  contactsRattaches: Array<ContactOrganisation>;
  affiche: boolean;
  afficheContact: boolean;
  modifier: boolean;
  contactId: number;
  contactsRecherche: Array<any> = [];
  champRechercheContact: string;
  rechercheEncoursContact = false;
  @Input()
  organisation: Organisation;
  @Output()
  refresh = new EventEmitter();
  constructor(private organisationService: OrganisationService, public formatIntranetShareService: FormatIntranetShareService,
              private dialogueShareService: DialogueShareService) { }

  ngOnInit() {
    this.affiche = false;
    this.afficheContact = false;
    this.modifier = false;
    this.contactId = 0;
    this.contactsRecherche = [];
    this.champRechercheContact = '';
    this.organisationService.getAllContactsOrganisation(this.organisation.id).then((contacts) => {
      this.contactsRattaches = contacts['hydra:member'] as Array<ContactOrganisation>;
    });
  }

  afficheAjout() {
    if (this.affiche === true) {
      this.affiche = false;
      this.modifier = false;
    } else {
      this.affiche = true;
      this.modifier = true;
    }
  }

  rechercheContact() {
    this.afficheContact = true;
    if (this.champRechercheContact.length > 2 && !this.rechercheEncoursContact) {
      this.rechercheEncoursContact = true;
      this.champRechercheContact = this.champRechercheContact.replace(' ', '.');
      this.organisationService.getDirigeantRecherche(this.champRechercheContact).then((retour) => {
        this.contactsRecherche = retour;
        this.contactsRecherche.forEach(contact => {
          if (contact.nomPrenom != '' && contact.nomPrenom != null) {
            contact.nomPrenom = contact.nomPrenom.replace('&amp;', '&');
            contact.nomPrenom = contact.nomPrenom.replace('&#039;', '’');
            contact.nomPrenom = contact.nomPrenom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheContact = this.champRechercheContact.replace('.', ' ');
      });
      this.rechercheEncoursContact = false;
    }
  }

  ajouterContact() {
    // si contact renseigné
    if (this.contactId != 0) {
      // on importe le contact
      this.organisationService.importerDirigeant(this.contactId).then(() => {
        // on récupère le contact renseigné
        this.organisationService.getDirigeantByAncienId(this.contactId).then((contact) => {
          const contId = contact['hydra:member'][0].id;
          // on l'associe
          this.organisationService.addContact(contId, this.organisation.id).then(() => {
            // on modifie aussi la base intranet avec les anciens id
            this.organisationService.addContactIntranet(this.contactId, this.organisation.ancienId).then(() => {
              this.ngOnInit();
              this.dialogueShareService.addDialogue('Le contact a bien été associé');
            });
          });
        });
      });
    }
  }

  supprimerContact(assoId, contactAncienId) {
    // on supprimer l'asso
      this.organisationService.supprimerAssoContactSoc(assoId).then(() => {
        // on supprime aussi la base intranet avec les anciens id
        this.organisationService.suppAssoContactIntranet(contactAncienId, this.organisation.ancienId).then(() => {
          this.ngOnInit();
          this.dialogueShareService.addDialogue('Le contact a bien été désassocié');
        });
      });
  }
}
