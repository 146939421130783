import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {MaillingService} from '../../../../service/interne/mailling/maillingService';

@Component({
  selector: 'app-escal-mailling-valider',
  templateUrl: './escal-mailling-valider.component.html',
  styleUrls: ['./escal-mailling-valider.component.scss'],
  providers: [ MaillingService ]
})
export class EscalMaillingValiderComponent implements OnInit {

  private sub: any;
  id: number;

  constructor(private router: Router, private route: ActivatedRoute, private maillingService: MaillingService) { }

  ngOnInit() {
    const that = this;
    this.sub = this.route.params.subscribe(params => {
      that.id = +params['id'];
      that.maillingService.validMailling(that.id)
        .then((retour) => {
          that.router.navigate(['/escal/mailling/' + that.id]);
        });
    });
  }
}
