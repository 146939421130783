import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';


@Injectable()
export class NatureActionSuiviService {
    private natureSuiviUrl = Environement.BASE_API_URL + '/api/nature_suivis';
    private natureTypeRelationUrl = Environement.BASE_API_URL + '/api/nature_type_relation_suivis';
    constructor(private http: HttpClient) { }

  getNature(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.natureSuiviUrl + '/' + id, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getNatures() {
    const headers = this.initOptionHeader();
    return this.http.get(this.natureSuiviUrl, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }


  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;}
}
