import { Component, OnInit } from '@angular/core';
import {TypeRelationSuivi} from '../../../../model/suivi/TypeRelationSuivi';
import {TypeDeRelationService} from '../../../../service/interne/SuiviAction/typeDeRelationService';
import {AuthUser} from '../../../../model/user/authUser';
import {AuthUserService} from '../../../../service/interne/user/AuthUserService';
import {NatureSuivi} from '../../../../model/suivi/NatureSuivi';
import {NatureActionSuiviService} from '../../../../service/interne/SuiviAction/natureActionSuiviService';
import {SuiviActionShareService} from '../../../../service/partage/SuiviActionShareService';
import {VecteurSuivi} from '../../../../model/suivi/VecteurSuivi';
import {Statut} from '../../../../model/suivi/Statut';
import {Client} from '../../../../model/client/Client';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {SuivitIntranetService} from '../../../../service/interne/suivit/SuivitIntranetService';
import {Suivi} from '../../../../model/suivi/Suivi';

@Component({
  selector: 'app-escal-suivi-recherche',
  templateUrl: './escal-suivi-recherche.component.html',
  styleUrls: ['./escal-suivi-recherche.component.scss'],
  providers: [TypeDeRelationService, AuthUserService, NatureActionSuiviService, SuiviActionShareService, ClientService,
    SuivitIntranetService]
})
export class EscalSuiviRechercheComponent implements OnInit {

  typeDeRelations: Array<TypeRelationSuivi> = [];
  users: Array<AuthUser> = [];
  naturesSelect: Array<NatureSuivi>;
  vecteurs: Array<VecteurSuivi>;
  statuts: Array<Statut>;
  clients: Array<Client>;
  contactsRecherche: Array<any> = [];
  champRechercheContact: string;
  titre: string;
  note: string;
  creeChoisi: string;
  affiche: boolean;
  typeId: number;
  natureId: number;
  vecteurId: number;
  statutId: number;
  premiereSocId: number;
  deuxiemeSocId: number;
  contactIDSelect: number;
  suiviPar: number;
  jours: number;
  date: Date;
  periodeDeb: Date;
  periodeFin: Date;
  rechercheEnCours: boolean;
  suivisRecherchesAvancees: Array<Suivi>;
  headerContent: Array<any> = [];
  curentPage = 1;
  maxPage: number;
  total: number;
  constructor(private typeDeRelationService: TypeDeRelationService, private authUserService: AuthUserService,
              private natureActionSuiviService: NatureActionSuiviService, private suiviActionShareService: SuiviActionShareService,
              private clientService: ClientService, public formatIntranetShareService: FormatIntranetShareService,
              private suivitIntranetService: SuivitIntranetService) { }

  ngOnInit() {
    this.headerContent.push('');
    this.headerContent.push('Titre');
    this.headerContent.push('Contact');
    this.headerContent.push('Suivi par');
    this.headerContent.push('Date');

    this.rechercheEnCours = false;
    this.suivisRecherchesAvancees = [];
    this.champRechercheContact = '';
    this.titre = '';
    this.note = '';
    this.creeChoisi = '';
    this.affiche = false;
    this.typeId = 0;
    this.natureId = 0;
    this.vecteurId = 0;
    this.statutId = 0;
    this.premiereSocId = 0;
    this.deuxiemeSocId = 0;
    this.contactIDSelect = 0;
    this.suiviPar = 0;
    this.jours = 0;
    this.typeDeRelationService.getTypeRelation().then((retour) => {
      this.typeDeRelations = retour['hydra:member'] as Array<TypeRelationSuivi>;
    });
    this.natureActionSuiviService.getNatures().then((retour) => {
      this.naturesSelect = retour['hydra:member'] as Array<NatureSuivi>;
    });
    this.suiviActionShareService.getVecteurs().then((retour) => {
      this.vecteurs = retour['hydra:member'] as Array<VecteurSuivi>;
    });
    this.suiviActionShareService.getStatuts().then((retour) => {
      this.statuts = retour['hydra:member'] as Array<Statut>;
    });
    this.authUserService.getUsersList(1).then((usersRetour) => {
      this.users = usersRetour['hydra:member'] as Array<AuthUser>;
    });
    this.clientService.getClientList(1)
      .then((clients) => {
        this.clients = clients['hydra:member'] as Array<Client>;
      });
  }

  rechercheContact() {
    this.affiche = true;
    if (this.champRechercheContact.length > 2) {
      this.champRechercheContact = this.champRechercheContact.replace(' ', '.');
      this.suivitIntranetService.getContactRecherche(this.champRechercheContact).then((retour) => {
        this.contactsRecherche = retour;
        this.champRechercheContact = this.champRechercheContact.replace('.', ' ');
      });
    }
  }

  rechercherSuivis() {
    this.suiviActionShareService.getSuivisRechercheAvancee(this.titre, this.typeId, this.natureId, this.vecteurId,
      this.statutId, this.note, this.contactIDSelect, this.suiviPar, this.premiereSocId, this.deuxiemeSocId, this.periodeDeb,
      this.periodeFin, this.date, this.creeChoisi, this.jours, this.curentPage).then((retour) => {
      this.suivisRecherchesAvancees = retour['hydra:member'] as Array<Suivi>;
      this.total = retour['hydra:totalItems'];
      this.getMaxPage(retour['hydra:totalItems']);
      this.rechercheEnCours = true;
    });
  }

  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }

  changePage(page) {
    this.curentPage = page;
    this.rechercherSuivis();
  }

  reprendreRecherche() {
    this.rechercheEnCours = false;
    this.curentPage = 1;
  }

}
