import { Component, OnInit } from '@angular/core';
import {PressroomService} from '../../../../service/interne/client/pressroom/PressroomService';
import {Client} from '../../../../model/client/Client';
import {ActivatedRoute} from '@angular/router';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {PressroomModelService} from '../../../../model/client/pressroom/PressroomModelService';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {PressroomAccueilSectionService} from '../../../../service/interne/client/pressroom/PressroomAccueilSectionService';
import {PressroomShareService} from '../../../../service/partage/PressroomShareService';
import {ImagePressroomService} from '../../../../service/interne/client/pressroom/ImagePressroomService';
import {CommuniqueService} from '../../../../service/interne/client/pressroom/CommuniqueService';
import {ArticleService} from '../../../../service/interne/client/pressroom/ArticleService';
import {PressroomAccueilService} from '../../../../service/interne/client/pressroom/PressroomAccueilService';
import {Environement} from '../../../../environement/environement';
import {EvenementService} from '../../../../service/interne/client/pressroom/EvenementService';

@Component({
  selector: 'app-pressroom-client',
  templateUrl: './pressroom-client.component.html',
  styleUrls: ['./pressroom-client.component.scss'],
  providers: [ PressroomService, PressroomModelService, ClientService, PressroomAccueilSectionService,
    ImagePressroomService, CommuniqueService, ArticleService, PressroomAccueilService, EvenementService]
})
export class PressroomClientComponent implements OnInit {

  private sub: any;
  client: Client;
  constructor(private route: ActivatedRoute,
              private pressroomService: PressroomService,
              private dialogueShareService: DialogueShareService,
              private clientService: ClientService,
              private imagePressRoomService: ImagePressroomService,
              private communiqueService: CommuniqueService,
              private articleService: ArticleService,
              private pressroomAccueilService: PressroomAccueilService,
              public pressroomShareService: PressroomShareService,
              private evenementService: EvenementService) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.pressroomShareService.clientService = this.clientService;
      this.pressroomShareService.pressroomService = this.pressroomService;
      this.pressroomShareService.dialogueShareService = this.dialogueShareService;
      this.pressroomShareService.imagePressroomService = this.imagePressRoomService;
      this.pressroomShareService.communiqueService = this.communiqueService;
      this.pressroomShareService.articleService = this.articleService;
      this.pressroomShareService.pressroomAccueilService = this.pressroomAccueilService;
      this.pressroomShareService.idClient = params.id;
      this.pressroomShareService.initPressroom();
      this.pressroomShareService.evenementService = this.evenementService;
    });
  }

  updateTest(){

    const that = this;
    const variable = {
      'pressroomService': this.pressroomService, 'dialogueShareService': this.dialogueShareService,
      'id': this.pressroomShareService.pressroom.id
    };
    this.dialogueShareService.addDialogue('La pressroom sur le serveur test sera remplacée', function () {
      variable['pressroomService'].sendToTest(variable['id'])
        .then((retour) => {
          variable['dialogueShareService'].addDialogue('La mise à jour est terminée');
        })
        .catch((retour) => {
          variable['dialogueShareService'].addDialogue('erreur lors de la mise à jour');
        });
    });
  }
  updateProd(){

    const that = this;
    const variable = {
      'pressroomService': this.pressroomService, 'dialogueShareService': this.dialogueShareService,
      'id': this.pressroomShareService.pressroom.id
    };
    this.dialogueShareService.addDialogue('La pressroom sur le serveur test sera remplacé', function () {
      variable['pressroomService'].sendToProd(variable['id'])
          .then((retour) => {
            variable['dialogueShareService'].addDialogue('La mise à jours est terminé');
          })
          .catch((retour) => {
            variable['dialogueShareService'].addDialogue('erreur lors de la mise à jours');
          });
    });
  }

  getAdressTest() {
    return Environement.SERVER_TEST_PRESSROOM + '/pressroom/' + this.pressroomShareService.pressroom.id;
  }
  getAdressProd() {
    return Environement.SERVER_PRESSROOM + '/pressroom/' + this.pressroomShareService.pressroom.id;
  }
}
