import { Component, OnInit } from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {StatistiqueIntranet} from "../../../../service/interne/utilitaire/statistique/statistiqueIntranet";
import {Environement} from '../../../../environement/environement';
import {AuthShareService} from '../../../../service/partage/AuthShareService';

@Component({
  selector: 'app-utilitaire-bdd-base',
  templateUrl: './utilitaire-bdd-base.component.html',
  styleUrls: ['./utilitaire-bdd-base.component.scss'],
  providers: [ StatistiqueIntranet ]
})
export class UtilitaireBddBaseComponent implements OnInit {

  dateDebut: Date = new Date();
  dateFin: Date = new Date();
  requestsDisponible: Array<any> = [];
  requestDispoid = 1;
  dataApi = [];
  linkStatististique = false;
  linkStatististiqueAgence = false;
  linkListe850 = false;
  linkListeVeilleDateContacts = false;
  linkListeVeilleInfosContacts = false;
  linkListeVeilleNbITW = false;

  constructor(private spinner: NgxSpinnerService, private statistiqueIntranet: StatistiqueIntranet, private authShareService: AuthShareService) { }

  ngOnInit() {
    this.requestsDisponible = [];
    let requestDispo = [];
    requestDispo['id'] = 1;
    requestDispo['nom'] = 'Coordonnées des contacts concernés par un interview';
    this.requestsDisponible.push(requestDispo);
    requestDispo = [];
    requestDispo['id'] = 2;
    requestDispo['nom'] = 'Ensemble de tous les statistiques des clients actifs';
    this.requestsDisponible.push(requestDispo);
    requestDispo = [];
    requestDispo['id'] = 3;
    requestDispo['nom'] = 'Liste des journalistes avec interviews sans conversation depuis juin 2023';
    this.requestsDisponible.push(requestDispo);
    requestDispo = [];
    requestDispo['id'] = 4;
    requestDispo['nom'] = 'Nombre de contacts ajoutés/supprimés aux listes veilles clients et date de dernière modification';
    this.requestsDisponible.push(requestDispo);
    this.initLink();
    requestDispo = [];
    requestDispo['id'] = 5;
    requestDispo['nom'] = 'Ensemble des contacts associés aux listes veilles clients';
    this.requestsDisponible.push(requestDispo);
    this.initLink();
    requestDispo = [];
    requestDispo['id'] = 6;
    requestDispo['nom'] = 'Nombre d\'interviews pour chaque contact associés aux listes veilles clients';
    this.requestsDisponible.push(requestDispo);
    this.initLink();
  }

  exportData() {
    const monthDeb = this.dateDebut.getMonth() + 1;
    const monthFin = this.dateFin.getMonth() + 1;
    this.spinner.show();
    if(Number(this.requestDispoid) === 1) {
      this.statistiqueIntranet.getJournalisteInterview(this.dateDebut.getFullYear() + '-' + monthDeb + '-' + this.dateDebut.getDate() ,
        this.dateFin.getFullYear() + '-' + monthFin + '-' + this.dateFin.getDate())
        .then( (retour) => {
          this.dataApi = [];
          this.dataApi = retour;
          this.spinner.hide();
          this.ngOnInit();
        });
    }
    if(Number(this.requestDispoid) === 2) {
      this.statistiqueIntranet.getStatAgence(this.dateDebut.getFullYear() + '-' + monthDeb + '-' + this.dateDebut.getDate() ,
        this.dateFin.getFullYear() + '-' + monthFin + '-' + this.dateFin.getDate())
        .then( (retour) => {
          this.dataApi = [];
          this.dataApi = retour;
          this.spinner.hide();
          this.ngOnInit();
        });
    }
    if(Number(this.requestDispoid) === 3) {
      this.statistiqueIntranet.getListe850(this.authShareService.authUser.id)
        .then( (retour) => {
          this.dataApi = [];
          this.dataApi = retour;
          this.spinner.hide();
          this.ngOnInit();
        });
    }
    if(Number(this.requestDispoid) === 4) {
      this.statistiqueIntranet.getListeVeilleDateContacts()
        .then( (retour) => {
          this.dataApi = [];
          this.dataApi = retour;
          this.spinner.hide();
          this.ngOnInit();
        });
    }
    if(Number(this.requestDispoid) === 5) {
      this.statistiqueIntranet.getListeVeilleInfosContacts()
        .then( (retour) => {
          this.dataApi = [];
          this.dataApi = retour;
          this.spinner.hide();
          this.ngOnInit();
        });
    }
    if(Number(this.requestDispoid) === 6) {
      this.statistiqueIntranet.getListeVeilleNbITWContacts()
        .then( (retour) => {
          this.dataApi = [];
          this.dataApi = retour;
          this.spinner.hide();
          this.ngOnInit();
        });
    }
  }

  isLinkType() {
    if (Number(this.requestDispoid) === 1) {
      return this.linkStatististique;
    }
    if (Number(this.requestDispoid) === 2) {
      return this.linkStatististiqueAgence;
    }
    if (Number(this.requestDispoid) === 3) {
      return this.linkListe850;
    }
    if (Number(this.requestDispoid) === 4) {
      return this.linkListeVeilleDateContacts;
    }
    if (Number(this.requestDispoid) === 5) {
      return this.linkListeVeilleInfosContacts;
    }
    if (Number(this.requestDispoid) === 6) {
      return this.linkListeVeilleNbITW;
    }
  }

  getNomType() {
    if (Number(this.requestDispoid) === 1) {
      return 'liste journaliste article';
    }
    if (Number(this.requestDispoid) === 2) {
      return 'liste statistique Client';
    }
    if (Number(this.requestDispoid) === 3) {
      return 'liste 850';
    }
    if (Number(this.requestDispoid) === 4) {
      return 'listes date modif et nb contacts';
    }
    if (Number(this.requestDispoid) === 5) {
      return 'listes infos contacts';
    }
    if (Number(this.requestDispoid) === 6) {
      return 'listes nombres ITW';
    }
  }

  genererLink() {
    if (Number(this.requestDispoid) === 1) {
      return Environement.BASE_API_URL + '/excelStat/' + this.authShareService.authUser.id + 'journalisteArticle.xlsx';
    }
    if (Number(this.requestDispoid) === 2) {
      return Environement.BASE_API_URL + '/excelStat/' + this.authShareService.authUser.id + 'statAgence.xlsx';
    }
    if (Number(this.requestDispoid) === 3) {
      return Environement.BASE_API_URL + '/excelStat/' + this.authShareService.authUser.id + 'liste850.xlsx';
    }
    if (Number(this.requestDispoid) === 4) {
      return Environement.BASE_API_URL + '/excelStat/DateModifEtNbContacts.xlsx';
    }
    if (Number(this.requestDispoid) === 5) {
      return Environement.BASE_API_URL + '/excelStat/ContactsAssociesAuxListes.xlsx';
    }
    if (Number(this.requestDispoid) === 6) {
      return Environement.BASE_API_URL + '/excelStat/NombreITWDepuis21Fev2021.xlsx';
    }
  }

  initLink() {
    this.statistiqueIntranet.getJournalisteInterviewExist(this.authShareService.authUser.id).then((retour) => {
      if (retour['generer'] === 'false') {
        this.linkStatististique = false;
      } else {
        this.linkStatististique = true;
      }
    });
    this.statistiqueIntranet.getStatAgenceExist(this.authShareService.authUser.id).then((retour) => {
      if (retour['generer'] === 'false') {
        this.linkStatististiqueAgence = false;
      } else {
        this.linkStatististiqueAgence = true;
      }
    });
    this.statistiqueIntranet.getListe850Existe(this.authShareService.authUser.id).then((retour) => {
      if (retour['generer'] === 'false') {
        this.linkListe850 = false;
      } else {
        this.linkListe850 = true;
      }
    });
    this.statistiqueIntranet.getListeVeilleDateContactsExiste().then((retour) => {
      if (retour['generer'] === 'false') {
        this.linkListeVeilleDateContacts = false;
      } else {
        this.linkListeVeilleDateContacts = true;
      }
    });
    this.statistiqueIntranet.getListeVeilleInfosContactsExiste().then((retour) => {
      if (retour['generer'] === 'false') {
        this.linkListeVeilleInfosContacts = false;
      } else {
        this.linkListeVeilleInfosContacts = true;
      }
    });
    this.statistiqueIntranet.getListeVeilleNbITWContactsExiste().then((retour) => {
      if (retour['generer'] === 'false') {
        this.linkListeVeilleNbITW = false;
      } else {
        this.linkListeVeilleNbITW = true;
      }
    });
  }

  pasDeDate() {
    const tab = [3, 4, 5, 6];
    return !tab.includes(Number(this.requestDispoid));
  }
}
