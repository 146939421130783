import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import {BugsComponent} from './component/utilitaire/bugs/bugs.component';
import {VerificationThematiquesComponent} from './component/utilitaire/verification-thematiques/verification-thematiques.component';
import {EscalUtilisateurProfilPriveComponent} from './component/escal/escal-utilisateur/escal-utilisateur-profil-prive/escal-utilisateur-profil-prive.component';
import {EscalUtilisateurChangeMdpComponent} from './component/escal/escal-utilisateur/escal-utilisateur-change-mdp/escal-utilisateur-change-mdp.component';
import {GestionMesClientsComponent} from './component/escal/gestion-client/gestion-mes-clients/gestion-mes-clients.component';
import {GestionMesClientDetailComponent} from './component/escal/gestion-client/gestion-mes-client-detail/gestion-mes-client-detail.component';
import {EscalUtilisateurProfilAdministratifComponent} from './component/escal/escal-utilisateur/escal-utilisateur-profil-administratif/escal-utilisateur-profil-administratif.component';
import {EscalUtilisateurProfilPublicComponent} from './component/escal/escal-utilisateur/escal-utilisateur-profil-public/escal-utilisateur-profil-public.component';
import {EscalVeilleThematiqueComponent} from './component/escal/escal-veille/escal-veille-thematique/escal-veille-thematique.component';
import {EscalVeilleCreationComponent} from './component/escal/escal-veille/escal-veille-creation/escal-veille-creation.component';
import {EscalVeilleJourComponent} from './component/escal/escal-veille/escal-veille-jour/escal-veille-jour.component';
import {EscalUtilisateurListComponent} from './component/escal/escal-utilisateur/escal-utilisateur-list/escal-utilisateur-list.component';
import {EscalUtilisateurAddComponent} from './component/escal/escal-utilisateur/escal-utilisateur-add/escal-utilisateur-add.component';
import {AdministrationClientRoleUserComponent} from './component/administration/administration-client-role-user/administration-client-role-user.component';
import {EscalUtilisateurGroupeComponent} from './component/escal/escal-utilisateur/groupe/escal-utilisateur-groupe/escal-utilisateur-groupe.component';
import {EscalUtilisateurGroupeDetailComponent} from './component/escal/escal-utilisateur/groupe/escal-utilisateur-groupe-detail/escal-utilisateur-groupe-detail.component';
import {EscalClientListComponent} from './component/escal/escal-client/escal-client-list/escal-client-list.component';
import {EscalClientAddComponent} from './component/escal/escal-client/escal-client-add/escal-client-add.component';
import {EscalClientFicheComponent} from './component/escal/escal-client/escal-client-fiche/escal-client-fiche.component';
import {EscalMaillingListComponent} from './component/escal/escal-mailling/escal-mailling-list/escal-mailling-list.component';
import {EscalMaillingListSendComponent} from './component/escal/escal-mailling/escal-mailling-list-send/escal-mailling-list-send.component';
import {EscalMaillingValiderComponent} from './component/escal/escal-mailling/escal-mailling-valider/escal-mailling-valider.component';
import {EscalMaillingViewComponent} from './component/escal/escal-mailling/escal-mailling-view/escal-mailling-view.component';
import {EscalMaillingViewEnCourComponent} from './component/escal/escal-mailling/escal-mailling-view/escal-mailling-view-en-cour/escal-mailling-view-en-cour.component';
import {EscalMaillingSuivitComponent} from './component/escal/escal-mailling/escal-mailling-suivit/escal-mailling-suivit.component';
import {AfficheCalendrierComponent} from './component/calendrier/affiche-calendrier/affiche-calendrier.component';
import {AdministrationSalleComponent} from './component/administration/administration-salle/administration-salle.component';
import {DemandeCongeComponent} from './component/utilitaire/demande-conge/demande-conge.component';
import {UpdateJourFerieComponent} from './component/utilitaire/update-jour-ferie/update-jour-ferie.component';
import {AfficheCalendrierMesClientsComponent} from './component/calendrier/affiche-calendrier-mes-clients/affiche-calendrier-mes-clients.component';
import {AfficheCalendrierClientComponent} from './component/calendrier/affiche-calendrier-client/affiche-calendrier-client.component';
import {AfficheCalendrierUserComponent} from './component/calendrier/affiche-calendrier-user/affiche-calendrier-user.component';
import {SommaireListeParutionComponent} from './component/escal/parution/sommaire-liste-parution/sommaire-liste-parution.component';
import {MergepdfComponent} from './component/utilitaire/mergepdf/mergepdf.component';
import {PressroomClientComponent} from './component/escal/escal-client/pressroom-client/pressroom-client.component';
import {ImportListeContactHaComponent} from './component/utilitaire/import/import-liste-contact-ha/import-liste-contact-ha.component';
import {StatistiqueIntranetComponent} from './component/utilitaire/statistique/statistique-intranet/statistique-intranet.component';
import {SiteEscalArticleComponent} from './component/site-escal/site-escal-article/site-escal-article.component';
import {SiteEscalArticleAddComponent} from './component/site-escal/site-escal-article/site-escal-article-add/site-escal-article-add.component';
import {SiteEscalArticleConfigurationComponent} from './component/site-escal/site-escal-article/site-escal-article-configuration/site-escal-article-configuration.component';
import {SiteEscalArticlePhotoComponent} from './component/site-escal/site-escal-article/site-escal-article-photo/site-escal-article-photo.component';
import {SiteEscalMotCleComponent} from './component/site-escal/site-escal-mot-cle/site-escal-mot-cle.component';
import {SiteEscalMotCleAddComponent} from './component/site-escal/site-escal-mot-cle/site-escal-mot-cle-add/site-escal-mot-cle-add.component';
import {SiteEscalMotCleConfigurationComponent} from './component/site-escal/site-escal-mot-cle/site-escal-mot-cle-configuration/site-escal-mot-cle-configuration.component';
import {EscalSuivitGestionComponent} from './component/escal/suivit/escal-suivit-gestion/escal-suivit-gestion.component';
import {UtilitaireBddBaseComponent} from './component/utilitaire/utilitaire-bdd/utilitaire-bdd-base/utilitaire-bdd-base.component';
import {AdministrationValidationCongeComponent} from './component/administration/administration-validation-conge/administration-validation-conge.component';
import {EscalSuiviGestionComponent} from './component/escal/suivit/escal-suivi-gestion/escal-suivi-gestion.component';
import {ReportMailRetourComponent} from './component/utilitaire/report-mail-retour/report-mail-retour.component';
import {
  EscalClientDocumentComponent
} from './component/escal/escal-client/escal-client-document/escal-client-document.component';
import {EscalClientModifierComponent} from './component/escal/escal-client/escal-client-modifier/escal-client-modifier.component';
import {EscalSuiviListComponent} from './component/escal/suivit/escal-suivi-list/escal-suivi-list.component';
import {EscalSuiviFicheComponent} from './component/escal/suivit/escal-suivi-fiche/escal-suivi-fiche.component';
import {EscalSuiviModifierComponent} from './component/escal/suivit/escal-suivi-modifier/escal-suivi-modifier.component';
import {
  StatistiqueMutualisationComponent
} from './component/utilitaire/statistique/statistique-mutualisation/statistique-mutualisation.component';
import {EscalSuiviFicheIntranetComponent} from './component/escal/suivit/escal-suivi-fiche-intranet/escal-suivi-fiche-intranet.component';
import {EscalSuiviRechercheComponent} from './component/escal/suivit/escal-suivi-recherche/escal-suivi-recherche.component';
import {
  EscalOrganisationListComponent
} from './component/escal/escal-organisation/escal-organisation-list/escal-organisation-list.component';
import {EscalOrganisationAddComponent} from './component/escal/escal-organisation/escal-organisation-add/escal-organisation-add.component';
import {
  EscalOrganisationFicheComponent
} from './component/escal/escal-organisation/escal-organisation-fiche/escal-organisation-fiche.component';
import {
  EscalOrganisationModifierComponent
} from './component/escal/escal-organisation/escal-organisation-modifier/escal-organisation-modifier.component';
import {
  EscalOrganisationRechercheComponent
} from './component/escal/escal-organisation/escal-organisation-recherche/escal-organisation-recherche.component';
import {
  EscalOrganisationFicheExportComponent
} from './component/escal/escal-organisation/escal-organisation-fiche/escal-organisation-fiche-export/escal-organisation-fiche-export.component';
import {
  EscalOrganisationDocumentComponent
} from './component/escal/escal-organisation/escal-organisation-document/escal-organisation-document.component';
import {
  EscalOrganisationFicheIntranetComponent
} from './component/escal/escal-organisation/escal-organisation-fiche-intranet/escal-organisation-fiche-intranet.component';
import {
  UtilitaireDedoublonnerSocietesComponent
} from './component/utilitaire/utilitaire-dedoublonner/utilitaire-dedoublonner-societes/utilitaire-dedoublonner-societes.component';
import {
  UtilitaireImporterSocietesComponent
} from './component/utilitaire/utilitaire-importer/utilitaire-importer-societes/utilitaire-importer-societes.component';
import {EscalClientAnalysesComponent} from './component/escal/escal-client/escal-client-analyses/escal-client-analyses.component';
import {
  EscalClientRapportActiviteComponent
} from './component/escal/escal-client/escal-client-rapport-activite/escal-client-rapport-activite.component';


const routes: Routes = [
  {
    path: '',
    component: AfficheCalendrierComponent,
  },
  {
    path: 'calendrier/mesClients',
    component: AfficheCalendrierMesClientsComponent,
  },
  {
    path: 'calendrier/client/:id',
    component: AfficheCalendrierClientComponent,
  },  {
    path: 'calendrier/user/:id',
    component: AfficheCalendrierUserComponent,
  },
  {
    path: 'escal/client/actuel',
    component: EscalClientListComponent,
  },
  {
    path: 'escal/client/ancien',
    component: EscalClientListComponent,
  },
  {
    path: 'escal/client/add',
    component: EscalClientAddComponent,
  },
  {
    path: 'escal/societes',
    component: EscalOrganisationListComponent,
  },
  {
    path: 'escal/societe/ajout',
    component: EscalOrganisationAddComponent,
  },
  {
    path: 'escal/societe/modifier/:id',
    component: EscalOrganisationModifierComponent,
  },
  {
    path: 'escal/societe/:id',
    component: EscalOrganisationFicheComponent,
  },
  {
    path: 'escal/societes/recherche',
    component: EscalOrganisationRechercheComponent,
  },
  {
    path: 'escal/societe/:id/export',
    component: EscalOrganisationFicheExportComponent,
  },
  {
    path: 'escal/societe/:id/fichiers',
    component: EscalOrganisationDocumentComponent,
  },
  {
    path: 'escal/societe/intranet/:id',
    component: EscalOrganisationFicheIntranetComponent,
  },
  {
    path: 'escal/user/actuel',
    component: EscalUtilisateurListComponent,
  },
  {
    path: 'escal/user/ancien',
    component: EscalUtilisateurListComponent,
  },
  {
    path: 'escal/user/add',
    component: EscalUtilisateurAddComponent,
  },
  {
    path: 'profil/gestion/administration',
    component: EscalUtilisateurProfilAdministratifComponent,
  },
  {
    path: 'mesClients',
    component: GestionMesClientsComponent,
  },
  {
    path: 'gestionClients',
    component: GestionMesClientDetailComponent,
  },
  {
    path: 'profil/gestion/passwords',
    component: EscalUtilisateurChangeMdpComponent,
  },
  {
    path: 'profil/gestion/perso',
    component: EscalUtilisateurProfilPriveComponent,
  },
  {
    path: 'profil/:id',
    component: EscalUtilisateurProfilPublicComponent,
  },
  {
    path: 'bugs',
    component: BugsComponent,
  },
  {
    path: 'verif/thematique/verif',
    component: VerificationThematiquesComponent,
  },
  {
    path: 'escal/veille/creation',
    component: EscalVeilleCreationComponent,
  },
  {
    path: 'escal/veille/thematique',
    component: EscalVeilleThematiqueComponent,
  },
  {
    path: 'escal/veille/jour',
    component: EscalVeilleJourComponent,
  },
  {
    path: 'admin/user/clientRole/:id',
    component: AdministrationClientRoleUserComponent,
  },
  {
    path: 'admin/salle',
    component: AdministrationSalleComponent,
  },
  {
    path: 'escal/user/groupe',
    component: EscalUtilisateurGroupeComponent,
  },
  {
    path: 'escal/user/groupe/:id',
    component: EscalUtilisateurGroupeDetailComponent,
  },
  {
    path: 'escal/client/:id/pressroom',
    component: PressroomClientComponent,
  },
  {
    path: 'escal/client/:id',
    component: EscalClientFicheComponent,
  },
  {
    path: 'escal/mailling/envoyer',
    component: EscalMaillingListSendComponent,
  },
  {
    path: 'escal/mailling/:id/validMailling',
    component: EscalMaillingValiderComponent,
  },
  {
    path: 'escal/mailling',
    component: EscalMaillingListComponent,
  },
  {
    path: 'escal/mailling/:id',
    component: EscalMaillingViewComponent,
  },
  {
    path: 'escal/mailling/en_cours/:id',
    component: EscalMaillingViewEnCourComponent,
  },
  {
    path: 'escal/mailling/suivit/:id',
    component: EscalMaillingSuivitComponent,
  },
  {
    path: 'escal/suivit/:id',
    component: EscalSuivitGestionComponent,
  },
  {
    path: 'escal/suivi/ajout',
    component: EscalSuiviGestionComponent,
  },
  {
    path: 'escal/suivi/ajout/:id',
    component: EscalSuiviGestionComponent,
  },
  {
    path: 'escal/suivis',
    component: EscalSuiviListComponent,
  },
  {
    path: 'escal/suivis/recherche',
    component: EscalSuiviRechercheComponent,
  },
  {
    path: 'escal/suivi/:id',
    component: EscalSuiviFicheComponent,
  },
  {
    path: 'escal/suivi/intranet/:id',
    component: EscalSuiviFicheIntranetComponent,
  },
  {
    path: 'escal/suivi/modifier/:id',
    component: EscalSuiviModifierComponent,
  },
  {
    path: 'demande/conge',
    component: DemandeCongeComponent,
  },
  {
    path: 'conge/confirmation/:id/:rand',
    component: AdministrationValidationCongeComponent,
  },
  {
    path: 'admin/update/jourFerie',
    component: UpdateJourFerieComponent,
  },
  {
    path: 'escal/parution/sommaire/:id',
    component: SommaireListeParutionComponent,
  },
  {
    path: 'utilitaire/pdf/mergepdf',
    component: MergepdfComponent,
  },
  {
    path: 'import/liste/contact/ha',
    component: ImportListeContactHaComponent,
  },
  {
    path: 'admin/statistic/intranet',
    component: StatistiqueIntranetComponent,
  },
  {
    path: 'admin/statistic/mutualisation',
    component: StatistiqueMutualisationComponent,
  },
  {
    path: 'site/article/liste',
    component: SiteEscalArticleComponent,
  },
  {
    path: 'site/article/add',
    component: SiteEscalArticleAddComponent,
  },
  {
    path: 'site/article/modify/:id',
    component: SiteEscalArticleConfigurationComponent,
  },
  {
    path: 'site/article/photo/:id',
    component: SiteEscalArticlePhotoComponent
  },
  {
    path: 'site/motCle/liste',
    component: SiteEscalMotCleComponent,
  },
  {
    path: 'site/motCle/add',
    component: SiteEscalMotCleAddComponent,
  },
  {
    path: 'site/motCle/modify/:id',
    component: SiteEscalMotCleConfigurationComponent,
  },
  {
    path: 'utilitaire/bdd/base',
    component: UtilitaireBddBaseComponent
  },
  {
    path: 'utilitaire/retour/email',
    component: ReportMailRetourComponent
  },
  {
    path: 'utilitaire/dedoublonner/societes',
    component: UtilitaireDedoublonnerSocietesComponent
  },
  {
    path: 'utilitaire/importer/societes',
    component: UtilitaireImporterSocietesComponent
  },


  {
    path: 'escal/client/:id/document',
    component: EscalClientDocumentComponent
  },
  {
    path: 'escal/client/:id/analyses',
    component: EscalClientAnalysesComponent
  },
  {
    path: 'escal/client/:id/rapport',
    component: EscalClientRapportActiviteComponent
  },
  {
    path: 'escal/client/:id/modifier',
    component: EscalClientModifierComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
