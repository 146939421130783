import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';

@Injectable()
export class UserGroupeService{
  private userGroupeUrl = Environement.BASE_API_URL + '/api/groupe_users';
  constructor(private http: HttpClient) { }

  getUserGroupe(): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.userGroupeUrl + '?pagination=false', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getUserGroupeDetail(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.userGroupeUrl + '/' + id, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addUserGroupe(nom, alias): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post(this.userGroupeUrl , {'nom': nom, 'alias': alias},{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  delUserGroupe(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.delete(this.userGroupeUrl + '/' + id, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateGroupe(urlAuthUser): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.put(this.userGroupeUrl , {'users': urlAuthUser } , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  };
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
