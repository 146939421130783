import { Component, OnInit } from '@angular/core';
import {OrganisationService} from '../../../../service/interne/organisation/OrganisationService';
import {Organisation} from '../../../../model/organisation/Organisation';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {CoordonneesService} from '../../../../service/interne/coordonnees/CoordonneesService';
import {Support} from '../../../../model/support/Support';
import {ContactOrganisation} from '../../../../model/contact/ContactOrganisation';
import {Emission} from '../../../../model/support/Emission';

@Component({
  selector: 'app-utilitaire-dedoublonner-societes',
  templateUrl: './utilitaire-dedoublonner-societes.component.html',
  styleUrls: ['./utilitaire-dedoublonner-societes.component.scss'],
  providers: [ OrganisationService, CoordonneesService ]
})
export class UtilitaireDedoublonnerSocietesComponent implements OnInit {

  structureChoisi: string;
  choix: string;
  champRechercheSocUn: string;
  champRechercheSocDeux: string;
  idUn: number;
  idDeux: number;
  societeIdUn: number;
  societeIdDeux: number;
  suggIdUn: number;
  suggIdDeux: number;
  visualier: string;
  afficheUn: boolean;
  afficheDeux: boolean;
  societesRechercheUn: Array<Organisation>;
  societesRechercheDeux: Array<Organisation>;
  societe: Organisation;
  oldSociete: Organisation;
  afficher: string;
  commentaires: string;
  urlTempUn: string;
  urlTempDeux: string;
  nbCaracteres: number;
  nbCaracteresPremURL: number;
  nbCaracteresDeuxURL: number;
  ouvert = 0;
  suggestionsDoublons: Array<any> = [];
  suggSocChoix: Array<any> = [];
  constructor(private organisationService: OrganisationService, private dialogueShareService: DialogueShareService,
              public formatIntranetShareService: FormatIntranetShareService, private coordonneesService: CoordonneesService) { }

  ngOnInit() {
    this.nbCaracteres = 0;
    this.afficher = 'moins';
    this.structureChoisi = 'numero';
    this.choix = 'non';
    this.champRechercheSocUn = '';
    this.champRechercheSocDeux = '';
    this.visualier = 'non' ;
    this.afficheUn = false ;
    this.afficheDeux = false;
    this.societeIdUn = 0;
    this.societeIdDeux = 0;
    this.suggIdUn = 0;
    this.suggIdDeux = 0;
    // pour l'onglet suggestion
    // récupérer toutes les organisations sans la limite de pagination
    this.organisationService.getSuggestionDedoublonnage().then((retour) => {
      let societes = retour['hydra:member'] as Array<Organisation>;
      societes.forEach(societe => {
        societes = societes.filter(soc => soc.id !== societe.id);
        const check = societes.find(({ nom }) => nom === societe.nom);
        // mettre tous les doublons dans un tableau
        if (check !== undefined) {
          // mettre l'original
          this.suggestionsDoublons.push(societe);
          // mettre le doublon
          this.suggestionsDoublons.push(check);
        }
      });
      // retirer les originaux ajoutés plusieurs fois
      this.suggestionsDoublons = this.suggestionsDoublons.filter((x, i) => this.suggestionsDoublons.indexOf(x) === i);
      // créer nouveau tableau avec le compteur total pour chaque doublon
      let arrays = [];
      this.suggestionsDoublons.forEach(soc => {
        let compteur = 0;
        let array = [];
        // s'il n'est pas déjà compté dans le tableau
        const checkPresent = arrays.find(({ nom }) => nom === soc.nom);
        if (checkPresent === undefined) {
          array['id'] = soc.id;
          array['nom'] = soc.nom;
          this.suggestionsDoublons.forEach(socBis => {
            if (soc.nom === socBis.nom) {
              compteur = compteur + 1;
            }
          });
          array['total'] = compteur;
          arrays.push(array);
        }
      });
      this.suggestionsDoublons = arrays;
    });
  }

  rechercheSociete(champRecherche, numero) {
    if (numero === 1) {
      this.organisationService.getSocieteByNom(champRecherche).then((societes) => {
        this.societesRechercheUn = societes['hydra:member'] as Array<Organisation>;
        this.afficheUn = true ;
      });
    } else {
      this.organisationService.getSocieteByNom(champRecherche).then((societes) => {
        this.societesRechercheDeux = societes['hydra:member'] as Array<Organisation>;
        this.afficheDeux = true ;
      });
    }
  }

  verifierDedoublonnage(idUn, idDeux) {
    if (idUn !== undefined && idDeux !== undefined && idUn !== 0 && idDeux !== 0 && idUn !== idDeux ) {
      // on récupère les informations du doublon escalnet
      this.organisationService.getOrganisationDetails(idUn).then((societeDoublon) => {
        const oldSociete = societeDoublon as Organisation;
        // si ce n'est pas un client
        if (oldSociete.client == null)  {
          // on récupère la societe escalnet à garder
          this.organisationService.getOrganisationDetails(idDeux).then((societe) => {
            const societeMAJ = societe as Organisation;
            // si ce n'est pas un client
            if (societeMAJ.client == null)  {
              this.visualier = 'oui' ;
              let idCoordSocMaj = 0;
              if (societeMAJ.coordonnees != null) {
                idCoordSocMaj = societeMAJ.coordonnees.id;
              }
              // on compare les informations avec celles de l'existant,
              // on met à jour les informations si champ rempli, si champs vide on garde les anciennes
              if (societeMAJ.capital == null || societeMAJ.capital === '' || societeMAJ.capital === '0') {
                societeMAJ.capital = oldSociete.capital ;
              }
              if (societeMAJ.commentaires == null || societeMAJ.commentaires === '') {
                societeMAJ.commentaires = oldSociete.commentaires ;
              }
              if (societeMAJ.effectif == null || societeMAJ.effectif === '') {
                societeMAJ.effectif = oldSociete.effectif ;
              }
              if (societeMAJ.implantation == null || societeMAJ.implantation === '') {
                societeMAJ.implantation = oldSociete.implantation ;
              }
              if (societeMAJ.siren == null || societeMAJ.siren === '') {
                societeMAJ.siren = oldSociete.siren ;
              }
              if (societeMAJ.formeJuridique == null && oldSociete.formeJuridique != null) {
                societeMAJ.formeJuridique = oldSociete.formeJuridique;
              }
              if (societeMAJ.dirigeant == null &&  oldSociete.dirigeant != null) {
                societeMAJ.dirigeant = oldSociete.dirigeant ;
              }
              if (societeMAJ.appartenirA == null &&  oldSociete.appartenirA != null) {
                societeMAJ.appartenirA  = oldSociete.appartenirA ;
              }
              if (societeMAJ.categorieGenerale == null &&  oldSociete.categorieGenerale != null) {
                societeMAJ.categorieGenerale = oldSociete.categorieGenerale ;
              }
              if (societeMAJ.coordonnees != null) {
                if ((societeMAJ.coordonnees.premierTel == null || societeMAJ.coordonnees.premierTel === '')
                  &&  oldSociete.coordonnees != null) {
                  societeMAJ.coordonnees.premierTel = oldSociete.coordonnees.premierTel ;
                }
                if ((societeMAJ.coordonnees.deuxiemeTel == null || societeMAJ.coordonnees.deuxiemeTel === '')
                  &&  oldSociete.coordonnees != null) {
                  societeMAJ.coordonnees.deuxiemeTel = oldSociete.coordonnees.deuxiemeTel ;
                }
                if ((societeMAJ.coordonnees.email == null || societeMAJ.coordonnees.email === '') &&  oldSociete.coordonnees != null) {
                  societeMAJ.coordonnees.email = oldSociete.coordonnees.email ;
                }
                if ((societeMAJ.coordonnees.rue == null || societeMAJ.coordonnees.rue === '') &&  oldSociete.coordonnees != null) {
                  societeMAJ.coordonnees.rue = oldSociete.coordonnees.rue ;
                }
                if ((societeMAJ.coordonnees.complements == null || societeMAJ.coordonnees.complements === '')
                  &&  oldSociete.coordonnees != null) {
                  societeMAJ.coordonnees.complements = oldSociete.coordonnees.complements ;
                }
                if ((societeMAJ.coordonnees.premierURL == null || societeMAJ.coordonnees.premierURL === '')
                  &&  oldSociete.coordonnees != null) {
                  societeMAJ.coordonnees.premierURL = oldSociete.coordonnees.premierURL ;
                }
                if ((societeMAJ.coordonnees.deuxiemeURL == null || societeMAJ.coordonnees.deuxiemeURL === '')
                  &&  oldSociete.coordonnees != null) {
                  societeMAJ.coordonnees.deuxiemeURL = oldSociete.coordonnees.deuxiemeURL ;
                }
                if ((societeMAJ.coordonnees.boitePostale == null || societeMAJ.coordonnees.boitePostale === '')
                  &&  oldSociete.coordonnees != null) {
                  societeMAJ.coordonnees.boitePostale = oldSociete.coordonnees.boitePostale ;
                }
                if ((societeMAJ.coordonnees.codePostal == null || societeMAJ.coordonnees.codePostal === '')
                  &&  oldSociete.coordonnees != null) {
                  societeMAJ.coordonnees.codePostal = oldSociete.coordonnees.codePostal ;
                }
                if ((societeMAJ.coordonnees.ville == null || societeMAJ.coordonnees.ville === '') &&  oldSociete.coordonnees != null) {
                  societeMAJ.coordonnees.ville = oldSociete.coordonnees.ville ;
                }
                if (societeMAJ.coordonnees.pays == null && oldSociete.coordonnees != null) {
                  if (oldSociete.coordonnees.pays != null) {
                    societeMAJ.coordonnees.pays = oldSociete.coordonnees.pays ;
                  }
                }
              } else {
                if (oldSociete.coordonnees != null) {
                  societeMAJ.coordonnees = oldSociete.coordonnees;
                }
              }
              if (societeMAJ.ape == null && oldSociete.ape != null) {
                societeMAJ.ape = oldSociete.ape ;
              }
              // on conserve la date de creation la plus ancienne
              if (societeMAJ.auteur == null) {
                societeMAJ.auteur = oldSociete.auteur;
              }
              // on conserve la date de creation la plus ancienne
              if (societeMAJ.dateCreation == null) {
                societeMAJ.dateCreation = oldSociete.dateCreation;
              }
              // on conserve l'enrgistrement le plus ancien
              if (societeMAJ.creation == null) {
                societeMAJ.creation = oldSociete.creation;
              }
              // on met à jour la date de modification
              societeMAJ.modification = new Date();
              // on sauvegarde temporairement toutes ces modifications
              this.societe = societeMAJ;
              this.oldSociete = oldSociete;
              // adapter l'affichage du commentaire si trop long
              if (this.societe.commentaires != null) {
                this.nbCaracteres = this.societe.commentaires.length;
                this.commentaires = this.societe.commentaires.substr(0, 150);
              }
              // adapter l'affichage du premier lien si trop long
              if (this.societe.coordonnees != null) {
                if (this.societe.coordonnees.premierURL != null) {
                  this.nbCaracteresPremURL = this.societe.coordonnees.premierURL.length;
                  let i = 0;
                  this.urlTempUn = '';
                  while (i < this.nbCaracteresPremURL) {
                    this.urlTempUn = this.urlTempUn + this.societe.coordonnees.premierURL.substr(i, 70) + ' ';
                    i = i + 70;
                  }
                }
              }
              // adapter l'affichage du deuxieme lien si trop long
              if (this.societe.coordonnees != null) {
                if (this.societe.coordonnees.deuxiemeURL != null) {
                  this.nbCaracteresDeuxURL = this.societe.coordonnees.deuxiemeURL.length;
                  let i = 0;
                  this.urlTempDeux = '';
                  while (i < this.nbCaracteresDeuxURL) {
                    this.urlTempDeux = this.urlTempDeux + this.societe.coordonnees.deuxiemeURL.substr(i, 70) + ' ';
                    i = i + 70;
                  }
                }
              }
            } else {
              this.dialogueShareService.addDialogue('merci de ne pas sélectionner de société cliente');
            }
          });
        } else {
          this.dialogueShareService.addDialogue('merci de ne pas sélectionner de société cliente');
        }
      });
    } else {
      this.dialogueShareService.addDialogue('merci de saisir deux sociétés différentes');
    }
  }

  majSociete() {
    // modifier le code ape à part à cause du promise
    this.organisationService.modifierCodeApeOrganisation(this.societe.id, this.societe.ape.id).then(retour => {
    });
    // modifier les informations de la société mise à jour
    let formeId = 0;
    if (this.societe.formeJuridique != null) {
      formeId = this.societe.formeJuridique.id;
    }
    let genreId = 0;
    if (this.societe.genre != null) {
      genreId = this.societe.genre.id;
    }
    this.organisationService.mettreAJourOrganisation(this.societe.id, this.societe, formeId, genreId).then(org => {
      const organisation = org as Organisation;
      // si société parente renseignée
      if (this.societe.appartenirA !== null) {
        // on l'assigne à la nouvelle organisation
        this.organisationService.addSocieteParent(this.societe.appartenirA.id, organisation.id).then(() => {
        });
      }
      // si dirigeant renseigné
      if (this.societe.dirigeant !== null) {
        // on l'assigne à la nouvelle organisation
        this.organisationService.addDirigeant(this.societe.dirigeant.id, organisation.id).then(() => {
        });
      }
      // si catégorie générale renseignée
      if (this.societe.categorieGenerale !== null) {
        // on l'assigne à la nouvelle organisation
        this.organisationService.addCategorie(this.societe.categorieGenerale.id, organisation.id).then(() => {
        });
      }
      // modifier les coordonnees de la société mise à jour si existantes
      if (this.societe.coordonnees !== null) {
        let paysId = 0;
        if (this.societe.coordonnees.pays != null) {
          paysId = this.societe.coordonnees.pays.id;
        }
        this.coordonneesService.modifierCoordonnees(this.societe.coordonnees.id, this.societe.coordonnees,
          this.societe.id, paysId).then((coord) => {
        });
      }
      // modifier les associations
      this.organisationService.getAllContactsOrganisation(this.oldSociete.id).then((contacts) => {
        const contactsRattaches = contacts['hydra:member'] as Array<ContactOrganisation>;
        contactsRattaches.forEach(contAsso => {
          // modifier association contact dans la base d'intranet
          this.organisationService.addContactIntranet(contAsso.contact.ancienId, this.societe.ancienId).then(() => {
          });
          // modifier association contact dans la base d'escalnet
          this.organisationService.modifierSocieteAssoContact(contAsso.id, this.societe.id).then((retour) => {
          });
        });
      });
      this.organisationService.getAllSocietesDetenuesOrganisation(this.oldSociete.id).then((societes) => {
        const societesDetenues = societes['hydra:member'] as Array<Organisation>;
        societesDetenues.forEach(socDet => {
          // modifier association societe dans la base d'intranet
          this.organisationService.addSocieteParentIntranet(socDet.ancienId, this.societe.ancienId).then(() => {
          });
          // modifier association societe dans la base d'escalnet
          this.organisationService.modifierSocieteAppartenirA(socDet.id, this.societe.id).then((retour) => {
          });
        });
      });
      this.organisationService.getAllSupportsDetenusOrganisation(this.oldSociete.id).then((supports) => {
        const supportsDetenus = supports['hydra:member'] as Array<Support>;
        supportsDetenus.forEach(supDet => {
          // modifier association support dans la base d'intranet
          this.organisationService.addSupportIntranet(supDet.ancienId, this.societe.ancienId).then(() => {
          });
          // modifier association support dans la base d'escalnet
          this.organisationService.modifierSupportDetenu(supDet.id, this.societe.id).then((retour) => {
          });
        });
      });
      this.organisationService.getAllEmissionsDetenusOrganisation(this.oldSociete.id).then((emissions) => {
        const emissionsDetenus = emissions['hydra:member'] as Array<Emission>;
        emissionsDetenus.forEach(emDet => {
          // modifier association emission dans la base d'intranet
          this.organisationService.addEmissionIntranet(emDet.ancienId, this.societe.ancienId).then(() => {
          });
          // modifier association emission dans la base d'escalnet
          this.organisationService.modifierEmissionDetenue(emDet.id, this.societe.id).then((retour) => {
          });
        });
      });
      // mettre à jour societe dans la base d'intranet
      this.organisationService.importerOrganisationIntranet(organisation.id, this.oldSociete.id).then((retour) => {
        // supprimer societe dans la base d'intranet
        this.organisationService.supprimerImportSocieteIntranet(this.oldSociete.ancienId)
          .then(() => {
            // supprimer société dans la base d'escalnet
            this.organisationService.supprimerOrganisation(this.oldSociete.id).then((oldOrganisation) => {
              this.visualier = 'fin';
            });
          });
      });
    });
  }

  changerAffichage() {
    if (this.afficher === 'plus') {
      this.commentaires = this.societe.commentaires.substr(0, 150);
      this.afficher = 'moins';
    } else {
      this.commentaires = this.societe.commentaires;
      this.afficher = 'plus';
    }
  }

  retour() {
    this.visualier = 'non';
    this.societe = null;
  }

  choixFait(nom) {
    this.suggSocChoix = [];
    this.choix = 'oui';
    this.organisationService.getOrganisationsByNom(nom).then((retour) => {
      const arrayTemp = retour['hydra:member'] as Array<Organisation>;
      arrayTemp.forEach(soc => {
        // si c'est le nom exact, pour matcher avec le compteur nombre de doublons
        if (soc.nom === nom) {
          this.suggSocChoix.push(soc);
        }
      });
    });
  }

  retourChoix() {
    this.choix = 'non';
    this.suggSocChoix = [];
  }
}
