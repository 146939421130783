import {Component, Input, OnInit} from '@angular/core';
import {AuthUser} from '../../../../../model/user/authUser';

@Component({
  selector: 'app-pagination-content-user',
  templateUrl: './pagination-content-user.component.html',
  styleUrls: ['./pagination-content-user.component.scss']
})
export class PaginationContentUserComponent implements OnInit {

  @Input()
  authUsers: Array<AuthUser>;

  constructor() { }

  ngOnInit() {
  }

}
