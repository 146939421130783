import {Client} from './Client';
import {AuthUser} from '../user/authUser';


export class RoleUserClient {
  id: string;
  adresseCoupure: boolean;
  adresseGenerique: boolean;
  client: Client;
  role: string;
  authUser: AuthUser;
  color: string;


  constructor(id: string, adresseCoupure: boolean, adresseGenerique: boolean, client: Client, role: string, authUser: AuthUser,
              color: string) {
    this.id = id;
    this.adresseCoupure = adresseCoupure;
    this.adresseGenerique = adresseGenerique;
    this.client = client;
    this.role = role;
    this.authUser = authUser;
    this.color = color;
  }
}
