import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact} from '../../../../../model/contact/Contact';
import {Suivi} from '../../../../../model/suivi/Suivi';
import {ContactService} from '../../../../../service/interne/contact/ContactService';
import {ActivatedRoute} from '@angular/router';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-escal-contact-fiche-informations',
  templateUrl: './escal-contact-fiche-informations.component.html',
  styleUrls: ['./escal-contact-fiche-informations.component.scss'],
  providers: [ContactService]
})
export class EscalContactFicheInformationsComponent implements OnInit {

  @Input()
  contact: Contact;
  @Output()
  refresh = new EventEmitter();
  suiviAssocies: Array<Suivi> = [];
  private sub: any;
  ouvert = 0;
  constructor(private route: ActivatedRoute, private contactService: ContactService,
              public formatIntranetShareService: FormatIntranetShareService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.sub = this.route.params.subscribe(params => {
      this.contactService.getLastSuivisByContactID(params.id).then(retour => {
        this.suiviAssocies = retour['hydra:member'] as Array<Suivi>;
        this.spinner.hide();
    });
  });
  }

  elemOuvert(id) {
    if (this.ouvert === id) {
      this.ouvert = 0;
    } else {
      this.ouvert = id;
    }
  }

  elementIsOuvert(id) {
    if (this.ouvert === id) {
      return true;
    }
  }
}
