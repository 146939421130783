import {Component, OnInit, ViewChild} from '@angular/core';

import {AuthShareService} from '../../../../../service/partage/AuthShareService';
import {MenuShareService} from '../../../../../service/partage/utilitaire/MenuShareService';

@Component({
  selector: 'app-structure-menu-gauche-info',
  templateUrl: './structure-menu-gauche-info.component.html',
  styleUrls: ['./structure-menu-gauche-info.component.scss']
})
export class StructureMenuGaucheInfoComponent implements OnInit {

  profilMenuOpen: boolean = false;
  @ViewChild('menuProfil') menuProfil;
  justopen: boolean = false;

  constructor(public authShareService: AuthShareService, public menuShareService: MenuShareService) {
    const that = this;
    window.addEventListener('click', event => {
      if (!that.menuProfil.nativeElement.contains(event.target)) {
        that.closeMenu();
      }});
  }

  ngOnInit() {
    setTimeout((retour) => {
      this.menuProfil.nativeElement.addEventListener('click', () => this.closeMenu());
    }, 1);
  }

  openMenu() {
    this.profilMenuOpen = !this.profilMenuOpen;
    this.justopen = true;
  }

  closeMenu() {
    if(!this.justopen){
      if(this.profilMenuOpen) {
        this.openMenu();
      }
    }
    this.justopen = false;
  }

}
