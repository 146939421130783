import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';

@Injectable()
export class DemandeCongeService {
  private jourDeCongeUrl = Environement.BASE_API_URL + '/api/demande_de_conges';

  constructor(private http: HttpClient) {
  }

  addDemande(idAuthUser, dateDebut, dateFin, congePaye, congeSansSolde, rattrapage, cbMatinBegin, cbApresMidiBegin,
             cbApresMidiFin, motif, autre, personneAEnvoyer): Promise<any> {
    const headers = this.initOptionHeader();
    let dateDebutOk = dateDebut;
    let dateFinOk = dateFin;
    let dateDebutFormat = '';
    let dateFinFormat = '';
    if (dateDebut) {
      dateDebutOk = new Date(dateDebut.getTime() + (2 * 1000 * 60 * 60));
      if (dateFin) {
        dateFinOk = new Date(dateFin.getTime() + (2 * 1000 * 60 * 60));
      }
      if (typeof dateDebutOk !== 'undefined') {
        if (typeof dateFinOk === 'undefined') {
          dateFinOk = dateDebutOk;
          if (cbMatinBegin === true) {
            dateDebutFormat = dateDebutOk.getFullYear() + '-' + (dateDebutOk.getMonth() + 1) + '-' +
              dateDebutOk.getDate() + 'T09:00:00';
          } else {
            dateDebutFormat = dateDebutOk.getFullYear() + '-' + (dateDebutOk.getMonth() + 1) + '-' +
              dateDebutOk.getDate() + 'T14:00:00';
          }
          if (cbApresMidiBegin === true) {
            dateFinFormat = dateFinOk.getFullYear() + '-' + (dateFinOk.getMonth() + 1) + '-' + dateFinOk.getDate() + 'T18:00:00';
          } else {
            dateFinFormat = dateFinOk.getFullYear() + '-' + (dateFinOk.getMonth() + 1) + '-' + dateFinOk.getDate() + 'T13:00:00';
          }
        } else {

          if (cbMatinBegin === true) {
            dateDebutFormat = dateDebutOk.getFullYear() + '-' + (dateDebutOk.getMonth() + 1) + '-' +
              dateDebutOk.getDate() + 'T09:00:00';
          } else {
            dateDebutFormat = dateDebutOk.getFullYear() + '-' + (dateDebutOk.getMonth() + 1) + '-' +
              dateDebutOk.getDate() + 'T14:00:00';
          }
          if (cbApresMidiFin) {
            dateFinFormat = dateFinOk.getFullYear() + '-' + (dateFinOk.getMonth() + 1) + '-' + dateFinOk.getDate() + 'T18:00:00';
          } else {
            dateFinFormat = dateFinOk.getFullYear() + '-' + (dateFinOk.getMonth() + 1) + '-' + dateFinOk.getDate() + 'T13:00:00';
          }
        }
      } else {
        return;
      }
    }
    return this.http.post(this.jourDeCongeUrl, {'authUser': '/api/auth_users/' + idAuthUser, 'debutConge': dateDebutFormat,
        'finConge': dateFinFormat, 'congePaye': congePaye, 'congeSansSolde': congeSansSolde, 'rattrapage': rattrapage, 'nbJourAutre': autre,
        'motif': motif, 'personneAEnvoyer': personneAEnvoyer},
        {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any):

  Promise<any> {
    return Promise.reject(err.message || err);
  }

  private initOptionHeader() {
  const token: any = localStorage.getItem('auth-tokens');
  let headers = new HttpHeaders().set('Content-Type', 'application/json');
  headers = headers.set('X-Auth-Token', token);
  return headers;
}
}
