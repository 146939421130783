import { Component, OnInit } from '@angular/core';

import {AuthShareService} from '../../../../../service/partage/AuthShareService';
import {UserGroupeService} from '../../../../../service/interne/user/UserGroupeService';

@Component({
  selector: 'app-escal-utilisateur-groupe',
  templateUrl: './escal-utilisateur-groupe.component.html',
  styleUrls: ['./escal-utilisateur-groupe.component.scss'],
  providers: [ UserGroupeService ]
})
export class EscalUtilisateurGroupeComponent implements OnInit {

  alias: string;
  nom: string;
  ajoutGroupOuvert: boolean = false;
  groups: Array<any> = [];

  constructor(private authShareService: AuthShareService, private userGroupeService: UserGroupeService) { }

  ngOnInit() {
    const that = this;
    this.userGroupeService.getUserGroupe()
      .then((retour) => {
        this.groups = retour['hydra:member'] as Array<UserGroupeService>;
      });
  }
  ajouterGroupe(){
    const that = this;
    this.userGroupeService.addUserGroupe(this.nom, this.alias)
      .then((retour) => {
        this.ajoutGroupOuvert = false;
        that.ngOnInit();
      });
  }
}
