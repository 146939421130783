import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../../environement/environement';
import {Client} from '../../../../model/client/Client';
import {ClientService} from '../ClientService';




@Injectable()
export class DocumentsService {
  private documentClientURL = Environement.BASE_API_URL + '/api/documents';
  public client: Client;
  public idClient: string;
  clientService: ClientService;
  constructor(private http: HttpClient) { }

  ajouterDocument(idClient, documentInfo) {
    const headers = this.initOptionHeader();
    const now = new Date();
    const dateHeure: string = now.getFullYear().toString() + '-' + (now.getMonth() + 1).toString() + '-' + now.getDate().toString()
      + now.getHours().toString() + ':' + now.getMinutes().toString();
    return this.http.post(this.documentClientURL,
      {nom: documentInfo.nom, type: documentInfo.type, format: documentInfo.format, creation: dateHeure, url: documentInfo.url,
        value: documentInfo.value, client: 'api/clients/' + idClient},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  supprimerDocument(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.delete(this.documentClientURL + '/' + id , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
