import { Component, OnInit } from '@angular/core';
import {ArticleSite} from '../../../../model/site/ArticleSite';
import {ArticleSiteService} from '../../../../service/interne/Site/ArticleSiteService';
import {Router} from '@angular/router';
import {MotCleSite} from '../../../../model/site/MotCleSite';
import {MotCleSiteService} from '../../../../service/interne/Site/MotCleSiteService';

@Component({
  selector: 'app-site-escal-mot-cle-add',
  templateUrl: './site-escal-mot-cle-add.component.html',
  styleUrls: ['./site-escal-mot-cle-add.component.scss'],
  providers: [ MotCleSiteService ]
})
export class SiteEscalMotCleAddComponent implements OnInit {

  motCle: MotCleSite = new MotCleSite(0, '' ,[]);
  constructor( private motCleSiteService: MotCleSiteService, private _router: Router) { }

  ngOnInit() {

  }

  saveArticle(){
    const that = this;
    this.motCleSiteService.addMotCle(this.motCle)
      .then( (retour) => {
        this._router.navigate(['/site/motCle/liste']);
      });
  }

}
