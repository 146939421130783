import {Injectable} from '@angular/core';

import {Pressroom} from '../../model/client/pressroom/Pressroom';
import {Client} from '../../model/client/Client';
import {PressroomAccueil} from '../../model/client/pressroom/PressroomAccueil';
import {ClientService} from '../interne/client/ClientService';
import {PressroomService} from '../interne/client/pressroom/PressroomService';
import {DialogueShareService} from './utilitaire/DialogueShareService';
import {ImagePressroomService} from '../interne/client/pressroom/ImagePressroomService';
import {CommuniqueService} from '../interne/client/pressroom/CommuniqueService';
import {ArticleService} from '../interne/client/pressroom/ArticleService';
import {PressroomAccueilService} from '../interne/client/pressroom/PressroomAccueilService';
import {EvenementService} from '../interne/client/pressroom/EvenementService';




@Injectable()
export class PressroomShareService {

  public pressroom: Pressroom;
  public client: Client;
  idClient: string;


  clientService: ClientService;
  pressroomService: PressroomService;
  dialogueShareService: DialogueShareService;
  imagePressroomService: ImagePressroomService;
  communiqueService: CommuniqueService;
  articleService: ArticleService;
  pressroomAccueilService: PressroomAccueilService;
  evenementService: EvenementService;


  constructor() {
  }

  public initPressroom() {
    const that = this;
    that.pressroom = null;
    this.clientService.getClientDetail(that.idClient)
      .then((retourClient) => {
        that.client = retourClient as Client;
        this.pressroomService.getPressroomByClient(that.idClient)
          .then((retourPressrooms) => {
            if (retourPressrooms['hydra:totalItems'] === 0) {
              that.createEmptyPressroom(that.client);
              that.initPressroomAccueil();
            } else if (retourPressrooms['hydra:totalItems'] === 1) {
              that.pressroom = retourPressrooms['hydra:member'][0];
              that.initPressroomAccueil();

            } else {
              that.dialogueShareService.addDialogue('Le client semble avoir plusieurs pressroom merci de contacter l\'administrateur');
            }
          });
      });
  }

  public createEmptyPressroom(client: Client){
    this.pressroom = new Pressroom(0, '' , client, [], new PressroomAccueil(0, '', '', []),
      [], [], [], null, null, null, null, []);
  }
  public initPressroomAccueil() {
    if (this.pressroom.pressroomAccueil === null) {
      this.pressroom.pressroomAccueil = new PressroomAccueil(0, 'Titre non définie', '#000000', []);
    }
  }

  public orderCommunique() {
    this.pressroom.pressroomCommunique = this.pressroom.pressroomCommunique.reverse();
  }

  public loadLogo() {
    this.imagePressroomService.getAllLogoPressroom(this.pressroom.id)
      .then((retour) =>{
        this.pressroom.logos = retour;
      });
  }
  public loadPhototech() {
    const that = this;
    this.imagePressroomService.getAllPhototechPressroom(this.pressroom.id)
      .then((retour) =>{
        that.pressroom.phototech = retour;
      });
  }
  public loadAcceuil() {
    const that = this;
    this.loadPhototech();
    this.pressroomAccueilService.getAccueilPressroom(this.pressroom.pressroomAccueil.id)
      .then((retour) =>{
        that.pressroom.pressroomAccueil = retour;
      });
  }
  public loadCommuniques() {
    const that = this;
    this.communiqueService.getAllCommuniquePressroom(this.pressroom.id)
      .then((retour) =>{
        that.pressroom.pressroomCommunique = retour;
        that.orderCommunique();
      });
  }
  public loadArticle(){
    const that = this;
    this.articleService.getAllArticlePressroom(this.pressroom.id)
      .then((retour) =>{
        that.pressroom.pressroomArticle = retour.reverse();
      });
  }

  public loadEvenements() {
    const that = this;
    this.evenementService.getAllEvenementsPressroom(this.pressroom.id)
      .then((retour) =>{
        that.pressroom.pressroomEvenement = retour;
        that.orderEvenement();
      });
  }

  public orderEvenement() {
    this.pressroom.pressroomEvenement = this.pressroom.pressroomEvenement.reverse();
  }
}
