import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-escal-utilisateur-profil-administratif',
  templateUrl: './escal-utilisateur-profil-administratif.component.html',
  styleUrls: ['./escal-utilisateur-profil-administratif.component.scss']
})
export class EscalUtilisateurProfilAdministratifComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
