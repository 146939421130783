import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Organisation} from '../../../../../model/organisation/Organisation';
import {OrganisationService} from '../../../../../service/interne/organisation/OrganisationService';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';

@Component({
  selector: 'app-escal-organisation-fiche-societes',
  templateUrl: './escal-organisation-fiche-societes.component.html',
  styleUrls: ['./escal-organisation-fiche-societes.component.scss']
})
export class EscalOrganisationFicheSocietesComponent implements OnInit {

  societesDetenues: Array<Organisation>;
  affiche: boolean;
  afficheSoc: boolean;
  modifier: boolean;
  societeId: number;
  societesRecherche: Array<any> = [];
  champRechercheSociete: string;
  rechercheEncoursSociete = false;
  @Input()
  organisation: Organisation;
  @Output()
  refresh = new EventEmitter();
  constructor(private organisationService: OrganisationService, public formatIntranetShareService: FormatIntranetShareService,
              private dialogueShareService: DialogueShareService) { }

  ngOnInit() {
    this.affiche = false;
    this.afficheSoc = false;
    this.modifier = false;
    this.societeId = 0;
    this.societesRecherche = [];
    this.champRechercheSociete = '';
    this.organisationService.getAllSocietesDetenuesOrganisation(this.organisation.id).then((societes) => {
      this.societesDetenues = societes['hydra:member'] as Array<Organisation>;
    });
  }

  afficheAjout() {
    if (this.affiche === true) {
      this.affiche = false;
      this.modifier = false;
    } else {
      this.affiche = true;
      this.modifier = true;
    }
  }

  rechercheSociete() {
    this.afficheSoc = true;
    if (this.champRechercheSociete.length > 2 && !this.rechercheEncoursSociete) {
      this.rechercheEncoursSociete = true;
      this.champRechercheSociete = this.champRechercheSociete.replace(' ', '.');
      this.organisationService.getSocieteRecherche(this.champRechercheSociete).then((retour) => {
        this.societesRecherche = retour;
        this.societesRecherche.forEach(societe => {
          if (societe.nom != '' && societe.nom != null) {
            societe.nom = societe.nom.replace('&amp;', '&');
            societe.nom = societe.nom.replace('&#039;', '’');
            societe.nom = societe.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheSociete = this.champRechercheSociete.replace('.', ' ');
      });
      this.rechercheEncoursSociete = false;
    }
  }

  ajouterSociete() {
    // si societe renseignée
    if (this.societeId != 0) {
      // on importe la societe
      this.organisationService.importerOrganisation(this.societeId).then(() => {
        // on récupère la societe renseignée
        this.organisationService.getDoublonEscalnetSiExiste(this.societeId).then((societe) => {
          const socId = societe['hydra:member'][0].id;
          // on l'associe
          this.organisationService.addSocieteParent(this.organisation.id, socId).then(() => {
            // on modifie aussi la base intranet avec les anciens id
            this.organisationService.addSocieteParentIntranet(this.societeId, this.organisation.ancienId).then(() => {
              this.ngOnInit();
              this.dialogueShareService.addDialogue('La société a bien été associée');
            });
          });
        });
      });
    }
  }

  supprimerParent(socId, socAncienId) {
    // on supp l'asso
    this.organisationService.suppSocieteParent(socId).then(() => {
      // on modifie aussi la base intranet avec les anciens id
      this.organisationService.suppSocieteParentIntranet(socAncienId, this.organisation.ancienId).then(() => {
        this.ngOnInit();
        this.dialogueShareService.addDialogue('La société a bien été désassociée');
      });
    });
  }
}
