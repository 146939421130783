import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../../environement/environement';

@Injectable({ providedIn: 'root' })
export class MotsClesService {
  private MotCleUrl = Environement.BASE_API_URL + '/api/mots_cles';
  constructor(private http: HttpClient) { }
  getMotCleByID(idMotCle): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.MotCleUrl + '/' + idMotCle, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
