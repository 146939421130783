import {CategorieSuggestion} from './CategorieSuggestion';
import {Statut} from './Statut';
import {AuthUser} from '../../user/authUser';


export class Suggestion {

  id: number;
  categorieSuggestion: CategorieSuggestion;
  cible: string;
  description: string;
  statut: Statut;
  priorite: string;
  date: Date;
  auteur: AuthUser;

  constructor(id: number, cible: string, description: string, priorite: string, statut: Statut, categorieSuggestion: CategorieSuggestion,
              date: Date, auteur: AuthUser) {
    this.id = id;
    this.priorite = priorite;
    this.cible = cible;
    this.description = description;
    this.statut = statut;
    this.categorieSuggestion = categorieSuggestion;
    this.date = date;
    this.auteur = auteur;
  }
}
