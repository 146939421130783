import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';


@Injectable()
export class ReseauxSociauxService {
  private reseauxSociauxUrl = Environement.BASE_API_URL + '/api/reseaux_sociauxes';
  constructor(private http: HttpClient) { }

  getReseauxSociauxDetail(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.reseauxSociauxUrl + '/' + id , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addResauxSociaux(linkedinURL, twitterURL, facebookURL) {
    const headers = this.initOptionHeader();
    return this.http.post(this.reseauxSociauxUrl, {'linkedinURL': linkedinURL, 'twitterURL': twitterURL,
        'facebookURL': facebookURL},
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  modifierResauxSociaux(ID ,linkedinURL, twitterURL, facebookURL) {
    const headers = this.initOptionHeader();
    return this.http.put(this.reseauxSociauxUrl + '/' + ID, {'linkedinURL': linkedinURL, 'twitterURL': twitterURL,
        'facebookURL': facebookURL},
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
