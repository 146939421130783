import { Component, OnInit } from '@angular/core';
import {Organisation} from '../../../../../model/organisation/Organisation';
import {OrganisationService} from '../../../../../service/interne/organisation/OrganisationService';
import {ActivatedRoute, Router} from '@angular/router';
import {OpenWithPostShareService} from '../../../../../service/partage/utilitaire/OpenWithPostShareService';
import {Environement} from '../../../../../environement/environement';

@Component({
  selector: 'app-escal-organisation-fiche-export',
  templateUrl: './escal-organisation-fiche-export.component.html',
  styleUrls: ['./escal-organisation-fiche-export.component.scss'],
  providers: [ OrganisationService ]
})
export class EscalOrganisationFicheExportComponent implements OnInit {

  private sub: any;
  organisation: Organisation;
  toggleActive = false;
  id: boolean;
  nom: boolean;
  dirigeant: boolean;
  date: boolean;
  code: boolean;
  siren: boolean;
  forme: boolean;
  capital: boolean;
  effectif: boolean;
  implantation: boolean;
  genre: boolean;
  categ: boolean;
  commentaire: boolean;
  premierTel: boolean;
  deuxiemeTel: boolean;
  email: boolean;
  rue: boolean;
  complement: boolean;
  bpostal: boolean;
  cpostal: boolean;
  ville: boolean;
  pays: boolean;
  premierURL: boolean;
  deuxiemeURL: boolean;
  constructor(private organisationService: OrganisationService, private route: ActivatedRoute, private openWithPostService: OpenWithPostShareService) { }

  ngOnInit() {
    this.id = true;
    this.nom = true;
    this.dirigeant = true;
    this.date = false;
    this.code = false;
    this.siren = false;
    this.forme = false;
    this.capital = false;
    this.effectif = false;
    this.implantation = false;
    this.genre = false;
    this.categ = false;
    this.commentaire = false;
    this.premierTel = true;
    this.deuxiemeTel = false;
    this.email = true;
    this.rue = false;
    this.complement = false;
    this.bpostal = false;
    this.cpostal = false;
    this.ville = true;
    this.pays = false;
    this.premierURL = false;
    this.deuxiemeURL = false;
    this.sub = this.route.params.subscribe(params => {
      this.organisationService.getOrganisationDetails(params.id)
        .then((organisation) => {
          this.organisation = organisation as Organisation;
        });
    });
  }

  exporterOrganisation() {
    let url: string;
    this.organisationService.exporterOrganisation(this.organisation.id, this.id, this.nom, this.dirigeant, this.date, this.code,
      this.siren, this.forme, this.capital, this.effectif, this.implantation, this.genre, this.categ, this.commentaire,
      this.premierTel, this.deuxiemeTel, this.email, this.rue, this.complement, this.bpostal, this.cpostal, this.ville,
      this.pays, this.premierURL, this.deuxiemeURL).then(() => {
        url = Environement.BASE_API_URL + '/excelStat/' + this.organisation.id + 'export.xlsx';
        this.openWithPostService.openWindows(url);
    });
  }

  modifierActive() {
    if (this.toggleActive === false) {
      this.id = false;
      this.nom = false;
      this.dirigeant = false;
      this.date = false;
      this.code = false;
      this.siren = false;
      this.forme = false;
      this.capital = false;
      this.effectif = false;
      this.implantation = false;
      this.genre = false;
      this.categ = false;
      this.commentaire = false;
      this.premierTel = false;
      this.deuxiemeTel = false;
      this.email = false;
      this.rue = false;
      this.complement = false;
      this.bpostal = false;
      this.cpostal = false;
      this.ville = false;
      this.pays = false;
      this.premierURL = false;
      this.deuxiemeURL = false;
    } else {
      this.id = true;
      this.nom = true;
      this.dirigeant = true;
      this.date = true;
      this.code = true;
      this.siren = true;
      this.forme = true;
      this.capital = true;
      this.effectif = true;
      this.implantation = true;
      this.genre = true;
      this.categ = true;
      this.commentaire = true;
      this.premierTel = true;
      this.deuxiemeTel = true;
      this.email = true;
      this.rue = true;
      this.complement = true;
      this.bpostal = true;
      this.cpostal = true;
      this.ville = true;
      this.pays = true;
      this.premierURL = true;
      this.deuxiemeURL = true;
    }
  }

}
