import {Component, OnInit, ViewChild} from '@angular/core';
import {ContactService} from '../../../../service/interne/contact/ContactService';
import {Contact} from '../../../../model/contact/Contact';
import {Organisation} from '../../../../model/organisation/Organisation';
import {
  PaginationRechercheComponent
} from '../../../utilitaire/pagination/pagination-recherche/pagination-recherche.component';

@Component({
  selector: 'app-escal-escal-contact-list',
  templateUrl: './escal-contact-list.component.html',
  styleUrls: ['./escal-contact-list.component.scss'],
  providers: [ContactService]
})
export class EscalContactListComponent implements OnInit {

  typeRecherche: Array<any> = [];
  headerContent: Array<any> = [];
  recherche: Array<any> = [];
  contacts: Array<Contact>;
  curentPage = 1;
  maxPage: number;
  total: number;
  @ViewChild(PaginationRechercheComponent) contactsIntranet: PaginationRechercheComponent;
  constructor(private contactService: ContactService) { }

  ngOnInit() {
    this.initContent();
    this.headerContent.push('isClient');
    this.headerContent.push('Nom');
    this.headerContent.push('Genre');
    this.headerContent.push('Fonction');
    this.headerContent.push('Supports');

    this.typeRecherche.push('Nom');
    this.typeRecherche.push('Tous');
    this.typeRecherche.push('Genre');
    this.typeRecherche.push('Fonction');
    this.typeRecherche.push('Intranet');
  }

  initContent() {
    const that = this;
    // affichage par defaut
    if ( this.recherche.length === 0) {
      this.contactService.getContactsParDefaut(this.curentPage)
        .then((data) => {
          that.contacts = data['hydra:member'] as Array<Contact>;
          that.total = data['hydra:totalItems'];
          that.getMaxPage(data['hydra:totalItems']);
        });
    } else if (this.recherche[0].length >= 3 ) {
      // si contenu recherche
      this.contactService.getContactsRecherche(this.recherche, this.curentPage)
        .then((data) => {
          that.contacts = data['hydra:member'] as Array<Contact>;
          that.total = data['hydra:totalItems'];
          that.getMaxPage(data['hydra:totalItems']);
        });
    }
  }

  updateRecherche(recherche: Array<any>) {
    this.curentPage = 1;
    if (recherche[0] === '') {
      this.recherche = [];
    } else {
      this.recherche = recherche;
    }
    this.initContent();
  }

  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }

  changePage(page) {
    this.curentPage = page;
    this.initContent();
  }

}
