import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';

@Injectable()
export class LogoService{
  private logoUrl = Environement.BASE_API_URL + '/api/logos';
  constructor(private http: HttpClient) { }

  getLogo(): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.logoUrl,
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  deleteLogo(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.delete(this.logoUrl + '/' + id , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addLogo(idClient, logoUpload): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post(this.logoUrl , {'idClient': idClient, 'url' : logoUpload['filename'], 'value': logoUpload['value']},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
