import { Component, OnInit } from '@angular/core';

import {AuthUserService} from '../../../../../service/interne/user/AuthUserService';
import {AuthShareService} from '../../../../../service/partage/AuthShareService';
import {UserService} from '../../../../../service/interne/user/UserService';



@Component({
  selector: 'app-escal-utilisateur-profil-administratif-information',
  templateUrl: './escal-utilisateur-profil-administratif-information.component.html',
  styleUrls: ['./escal-utilisateur-profil-administratif-information.component.scss'],
  providers: [ AuthUserService, UserService ]
})
export class EscalUtilisateurProfilAdministratifInformationComponent implements OnInit {

  justChange;
  modif;
  constructor(public authShareService: AuthShareService, private authUserService: AuthUserService, private userService: UserService) {
    document.addEventListener('click', () => this.onClick());
  }

  ngOnInit() {
  }
  updateTelInterne() {
    const that = this;
    this.authUserService.updateTelInterne(this.authShareService.authUser.id, this.authShareService.authUser.telInterne)
      .then((retour) => {
        that.authShareService.getUserByAuthToken();
      });
  }
  updateTelExterne() {
    const that = this;
    this.userService.updateTelExterne(this.authShareService.authUser.user.id, this.authShareService.authUser.user.tel)
      .then((retour) => {
        that.authShareService.getUserByAuthToken();
      });
  }
  onClick(type: string = '') {
    if (type === '' && this.justChange === 1) {
      this.justChange = 0;
    } else {
      if (type !== '') {
        this.justChange = 1;
      }
      this.modif = type;
    }
  }
}
