import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';

@Injectable()
export class ThemeDescriptifService {
  private themeDescriptifUrl = Environement.BASE_API_URL + '/api/theme_descriptifs';
  constructor(private http: HttpClient) {
  }
  getThemeDescriptifs() {
    const headers = this.initOptionHeader();
    return this.http.get(this.themeDescriptifUrl, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getThemeDescriptifById(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.themeDescriptifUrl + '/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  addThemeDescriptif(theme, actuId) {
    const headers = this.initOptionHeader();
    return this.http.post(this.themeDescriptifUrl, {'libelle': theme, 'actualite': Environement.BASE_API_URL + '/api/actualites/' + actuId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerThemeDescriptif(themeId) {
    const headers = this.initOptionHeader();
    return this.http.delete(this.themeDescriptifUrl + '/' + themeId,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
