import { Component, OnInit } from '@angular/core';

import {MaillingService} from '../../../../service/interne/mailling/maillingService';
import {ClientService} from '../../../../service/interne/client/ClientService';

import {Mailling} from '../../../../model/mailling/Mailling';
import {Client} from '../../../../model/client/Client';

@Component({
  selector: 'app-escal-mailling-list',
  templateUrl: './escal-mailling-list.component.html',
  styleUrls: ['./escal-mailling-list.component.scss'],
  providers: [ MaillingService, ClientService ]
})
export class EscalMaillingListComponent implements OnInit {


  CPcheck: boolean = true;
  COcheck: boolean = true;
  EnCourCheck: boolean = false;
  maillings: Array<Mailling>;
  clients: Array<Client>;
  selectClientIdVal: number;
  titrePage : string;

  constructor(private maillingSerice: MaillingService, private clientService: ClientService) { }

  ngOnInit() {
    const that = this;
    that.clients = [];
    that.selectClientIdVal = -1;
    var EnChourCheckVal = 1;
    if(this.EnCourCheck === false){
      var EnChourCheckVal = 0;
      this.titrePage = 'Liste des mailings à envoyer';
    } else {
      var EnChourCheckVal = 1;
      this.titrePage = 'Liste des mailings en cours';
    }
    this.maillingSerice.getMaillingList(0, EnChourCheckVal)
      .then((retour) => {
        that.maillings = retour['hydra:member'] as Array<Mailling>;
        this.clientService.getClientList(1)
          .then((clientsRetour) => {
            let clientId = [];
            clientsRetour['hydra:member'].forEach((clientRetour) => {
              that.maillings.forEach((mailling) => {
                if (Number(mailling.client.id) === Number(clientRetour.id)) {
                  if (clientId.indexOf(clientRetour.id) === -1) {
                    clientId.push(clientRetour.id);
                    that.clients.push(clientRetour);
                  }
                }
              });
            });
          });
      });
  }

  enCourChange() {
    this.ngOnInit();
  }
  afficheMailling(mailling: Mailling){
    if (this.selectClientIdVal === -1){
      if (mailling.typeMail === 'CP') {
        return this.CPcheck;
      }
      return this.COcheck;
    } else {
      if ( Number(mailling.client.id) !== Number(this.selectClientIdVal)) {
        return false;
      }
      if(mailling.typeMail === 'CP') {
        return this.CPcheck;
      }
      return this.COcheck;
    }
  }

  checkDelete() {
    const that = this;
    this.maillingSerice.checkDeleteMailling()
      .then((retour) => {
        that.ngOnInit();
      });
  }

}
