import {Injectable} from '@angular/core';
import {EvenementSalleService} from '../interne/calendrier/EvenementSalleService';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EvenementService} from '../interne/calendrier/EvenementService';


@Injectable()
export class ModalCalendrierShareService {


  public open = false;

  public eventType = '';

  public dateDebut;

  public dateFin;

  public salles;

  public salleId;

  public message;

  public nom;

  public evenementSalleService: EvenementSalleService;

  public typeEvent;

  public event;

  public clientId;

  public eventExterieurNom;

  cbSalle = true;

  cbClient = false;

  cbEvent = false;

  fullDay = false;

  constructor() {
  }

  verifDispo(fuseau = false) {
    const that = this;
    setTimeout(() => {
      let dateDebutReel = new Date();
      let dateFinReel = new Date();
        // le datetimepicker affiche les dates en ajoutant le fuseau horaire mais l'api elle prend des dates déja
        // formatter au fuseau horaire
      dateDebutReel = new Date(that.dateDebut.getTime() + -60 * (that.dateDebut.getTimezoneOffset()) * 1000);
      dateFinReel = new Date(that.dateFin.getTime() + -60 * (that.dateFin.getTimezoneOffset()) * 1000);
      that.evenementSalleService.checkDispoSalle(that.salleId, dateDebutReel, dateFinReel)
        .then((retour) => {
          if (Array.isArray(retour)) {
            if (retour.length > 0) {
              if ( retour.length === 1){
                retour.forEach((event) => {
                  if (that.event === undefined) {
                    that.message = 'Impossible de réserver la salle, il y a déjà une autre réservation à cette horaire';
                  } else {
                    if (event.id === that.event.meta.id) {
                      that.message = '';
                    } else {
                      that.message = 'Impossible de réserver la salle, il y a déjà une autre réservation à cette horaire';
                    }
                  }
                });
              } else {
                that.message = 'Impossible de réserver la salle, il y a déja une autre réservation à cette horaire';
              }
            } else {
              that.message = '';
            }
          } else {
            that.message = '';
          }
        });
    }, 5);
  }

  setEvent(event) {

    this.dateFin = event.end;
    this.dateDebut = event.start;
    if (event.meta.salle != null){
      this.salleId = event.meta.salle.id;
      this.cbSalle = true;
    } else {
      this.cbSalle = false;
    }
    this.event = event;
    this.nom = event.meta.nom;
    this.fullDay = event.meta.fullDay;
    this.eventExterieurNom = event.meta.eventNom;
    if (this.eventExterieurNom.length > 1 ) {
      this.cbEvent = true;
    }
  }

  verifDispoModif(event) {
    return new Promise((resolve, reject) => {
      const that = this;
      if (event.meta.salle) {
        that.evenementSalleService.checkDispoSalle(event.meta.salle.id, event.start,
          event.end, true)
          .then((retourEventDispo) => {
            if (Array.isArray(retourEventDispo)) {
              if (retourEventDispo.length > 1) {
                reject(false);
              } else if (retourEventDispo.length === 1) {
                if (retourEventDispo[0].id === event.meta.id) {
                  resolve(true);
                } else {
                  reject(false);
                }
              } else {
                resolve(true);
              }
            } else {
              reject(false);
            }
          });
      }
    });
  }


}
