import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';
import {Contact} from '../../../model/contact/Contact';
import {removeAccents} from '../../partage/prototype/sansAccents';
import {isObject} from "util";


@Injectable()
export class ContactService {
  private contactUrl = Environement.BASE_API_URL + '/api/contacts';
  private suiviIntranetUrl = Environement.BASE_API_URL + '/suivi/intranet/';
  private suiviURL = Environement.BASE_API_URL + '/api/suivis';
  private parutionUrl =  Environement.BASE_API_URL + '/api/parutions';
  contact: Contact;
  constructor(private http: HttpClient) { }

  getContactDetail(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '/' + id , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
getContacts() {
  const headers = this.initOptionHeader();
  return this.http.get(this.contactUrl, {headers: headers}).toPromise()
    .then( response => response)
    .catch(this.errorHandler);
}

  getContactsParDefaut(page) {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '?_page=' + page + '&order[nom]=asc', {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getContactsRecherche(recherche: Array<any>, page) {
    const content = recherche[0];
    let type = recherche[1];
    const headers = this.initOptionHeader();
    type = removeAccents(type.toLowerCase());
    if (type === 'tous') {
      return this.http.get(this.contactUrl + '?several_all=' + content + '&_page=' + page + '&order[nom]=asc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'nom') {
      return this.http.get(this.contactUrl + '?cont_nom=' + content + '&_page=' + page + '&order[nom]=asc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'genre') {
      return this.http.get(this.contactUrl + '?cont_genre=' + content + '&_page=' + page + '&order[nom]=asc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'fonction') {
      return this.http.get(this.contactUrl + '?cont_fonction=' + content + '&_page=' + page + '&order[nom]=asc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }

  addContact(contact, civiliteId) {
    const headers = this.initOptionHeader();
    const now = new Date();
    const dateCon = contact.dateConclusion;
    let civiliteContact;
    let dateConclu: string = null;
    if (contact.fonction != null) {
      contact.fonction =  'api/fonctions/' + contact.fonction.id;
    } else {
      contact.fonction = null;
    }
    if (civiliteId === 0) {
      civiliteContact = null;
    } else {
      civiliteContact = 'api/civilites/' + civiliteId;
    }
    if (contact.reseauxSociaux != null) {
      contact.reseauxSociaux = 'api/reseaux_sociauxes/' + contact.reseauxSociaux.id;
    } else {
      contact.reseauxSociaux = null;
    }
    if (contact.coordonnees != null) {
      contact.coordonnees = 'api/coordonnees/' + contact.coordonnees.id;
    }
    const dateCreation = contact.creation.getFullYear() + '-' + (contact.creation.getMonth() + 1).toString() + '-' + contact.creation.getDate() + ' ' + contact.creation.getHours() + ':' + contact.creation.getMinutes() + ':00' ;
    if (dateCon != null) {
      dateConclu = dateCon.getFullYear().toString() + '-' + (dateCon.getMonth() + 1).toString() + '-' + dateCon.getDate().toString()
        + dateCon.getHours().toString() + ':' + dateCon.getMinutes().toString();
    }
    return this.http.post(this.contactUrl, {
          civilite: civiliteContact,
          prenom: contact.prenom,
          nom: contact.nom,
          fonction: contact.fonction,
          coordonnees: contact.coordonnees,
          reseauxSociaux:  contact.reseauxSociaux,
          objet: contact.objet,
          creation: dateCreation,
          dateConclusion: dateConclu,
          genre: 'api/genres/' + contact.genre,
          contactPerso: contact.contactPerso,
          piecesJointes: contact.piecesJointes,
          modeEnvoiPref: contact.modeEnvoiPref,
          commentaires: contact.commentaires,
          auteur: 'api/auth_users/' + contact.auteur.id
        },
        {headers: headers}).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
  }
  addContactOrganisation(contactID, organisationID, telPro1, telPro2, emailPro) {
    const headers = this.initOptionHeader();
    return this.http.post(Environement.BASE_API_URL + '/api/contact_organisations',
      { contact: 'api/contacts/' + contactID,
        organisation: 'api/organisations/' + organisationID,
        premierTel:  telPro1, deuxiemeTel:  telPro2, email: emailPro},
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  addContactLangues(contactID, langueID) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/contact/langues/add/ ' + contactID + '/' + langueID,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  addContactPays(contactID, PaysID) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/contact/pays/add/ ' + contactID + '/' + PaysID,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  addContactCategGen(contactID, categGenAncienID) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/contact/categgen/add/ ' + contactID + '/' + categGenAncienID,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  addContactSousCategGen(contactID, sousCategGenAncienID) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/contact/souscateggen/add/ ' + contactID + '/' + sousCategGenAncienID,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getDoublonsSuggestion(nomContact) {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '/doublons/intranet/suggestion/' + nomContact, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getDoublonEscalnetSiExiste(doublonId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '?ancienId=' + doublonId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  importerContact(contactID) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suiviIntranetUrl + 'importation/tout/' + contactID + '/' + 0 + '/' + 0 + '/,',
    {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getAllInformationsContact(contactID) {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '/doublons/intranet/informations/' + contactID, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  modifierContact(idContactMAJ, contact, civiliteId, dedoublonnage = null) {
    const headers = this.initOptionHeader();
    let civ = null;
    let genre;
    let dateCon = null;
    let dateConclu = null;
    let idCoord = null;
    let idFonction = null;
    if (contact.coordonnees != null) {
      if (contact.coordonnees.id > 0) {
        idCoord = '/api/coordonnees/' + contact.coordonnees.id;
      }
      }
    if (contact.fonction != null) {
      if (contact.fonction.id > 0) {
        idFonction = '/api/fonctions/' + contact.fonction.id;
      }
    }
    if (civiliteId > 0)  {
      civ = 'api/civilites/' + civiliteId;
    } else if (contact.civilite != null && contact.civilite.id != '') {
      civ = 'api/civilites/' + contact.civilite;
    }
    if (isObject(contact.genre)) {
      genre = contact.genre.id;
    } else {
      genre = contact.genre;
    }
    if (contact.dateConclusion != null) {
      dateCon = new Date(contact.dateConclusion);
    }
    if (dateCon != null) {
      dateConclu = dateCon.getFullYear().toString() + '-' + (dateCon.getMonth() + 1).toString() + '-' + dateCon.getDate().toString()
        + dateCon.getHours().toString() + ':' + dateCon.getMinutes().toString();
    }
    contact.modification = new Date();
    const DateModifFormat = contact.modification.getFullYear() + '-' + (contact.modification.getMonth() + 1).toString()
      + '-' + contact.modification.getDate() + ' ' + contact.modification.getHours() + ':'
      + contact.modification.getMinutes() + ':00' ;
    if (dedoublonnage === null) {
      if ( contact.reseauxSociaux != null) {
        if (civ == null) {
          return this.http.put(this.contactUrl + '/' + idContactMAJ,
            {
              nom: contact.nom,
              prenom: contact.prenom,
              commentaires: contact.commentaires,
              modeEnvoiPref: contact.modeEnvoiPref,
              piecesJointes: contact.piecesJointes,
              objet: contact.objet,
              dateConclusion: dateConclu,
              fonction: idFonction,
              genre: 'api/genres/' + genre,
              reseauxSociaux: 'api/reseaux_sociauxes/' + contact.reseauxSociaux.id,
              coordonnees: idCoord,
              modification: DateModifFormat
            },
            {headers}).toPromise()
            .then(response => response)
            .catch(this.errorHandler);
        } else {
          return this.http.put(this.contactUrl + '/' + idContactMAJ,
            {
              civilite: civ,
              nom: contact.nom,
              prenom: contact.prenom,
              commentaires: contact.commentaires,
              modeEnvoiPref: contact.modeEnvoiPref,
              piecesJointes: contact.piecesJointes,
              objet: contact.objet,
              dateConclusion: dateConclu,
              fonction: idFonction,
              genre: 'api/genres/' + genre,
              reseauxSociaux: 'api/reseaux_sociauxes/' + contact.reseauxSociaux.id,
              coordonnees: idCoord,
              modification: DateModifFormat
            },
            {headers}).toPromise()
            .then(response => response)
            .catch(this.errorHandler);
        }
      } else if (contact.reseauxSociaux == null) {
        if (civ == null) {
          return this.http.put(this.contactUrl + '/' + idContactMAJ,
            {
              nom: contact.nom,
              prenom: contact.prenom,
              commentaires: contact.commentaires,
              modeEnvoiPref: contact.modeEnvoiPref,
              piecesJointes: contact.piecesJointes,
              objet: contact.objet,
              dateConclusion: dateConclu,
              fonction: idFonction,
              genre: 'api/genres/' + genre,
              coordonnees: idCoord,
              modification: DateModifFormat
            },
            {headers}).toPromise()
            .then(response => response)
            .catch(this.errorHandler);
        } else {
          return this.http.put(this.contactUrl + '/' + idContactMAJ,
            {
              civilite: civ,
              nom: contact.nom,
              prenom: contact.prenom,
              commentaires: contact.commentaires,
              modeEnvoiPref: contact.modeEnvoiPref,
              piecesJointes: contact.piecesJointes,
              objet: contact.objet,
              dateConclusion: dateConclu,
              fonction: idFonction,
              genre: 'api/genres/' + genre,
              coordonnees: idCoord,
              modification: DateModifFormat
            },
            {headers}).toPromise()
            .then(response => response)
            .catch(this.errorHandler);
        }
      }
    } else {
      if ( contact.reseauxSociaux != null) {
        if (civ == null) {
          return this.http.put(this.contactUrl + '/' + idContactMAJ,
            {
              nom: contact.nom,
              prenom: contact.prenom,
              commentaires: contact.commentaires,
              modeEnvoiPref: contact.modeEnvoiPref,
              piecesJointes: contact.piecesJointes,
              objet: contact.objet,
              dateConclusion: dateConclu,
              fonction: 'api/fonctions/' + contact.fonction.id,
              genre: 'api/genres/' + genre,
              reseauxSociaux: 'api/reseaux_sociauxes/' + contact.reseauxSociaux.id,
              modification: DateModifFormat
            },
            {headers}).toPromise()
            .then(response => response)
            .catch(this.errorHandler);
        } else {
          return this.http.put(this.contactUrl + '/' + idContactMAJ,
            {
              civilite: civ,
              nom: contact.nom,
              prenom: contact.prenom,
              commentaires: contact.commentaires,
              modeEnvoiPref: contact.modeEnvoiPref,
              piecesJointes: contact.piecesJointes,
              objet: contact.objet,
              dateConclusion: dateConclu,
              fonction: 'api/fonctions/' + contact.fonction.id,
              genre: 'api/genres/' + genre,
              reseauxSociaux: 'api/reseaux_sociauxes/' + contact.reseauxSociaux.id,
              modification: DateModifFormat
            },
            {headers}).toPromise()
            .then(response => response)
            .catch(this.errorHandler);
        }
      } else if (contact.reseauxSociaux == null) {
        if (civ == null) {
          return this.http.put(this.contactUrl + '/' + idContactMAJ,
            {
              nom: contact.nom,
              prenom: contact.prenom,
              commentaires: contact.commentaires,
              modeEnvoiPref: contact.modeEnvoiPref,
              piecesJointes: contact.piecesJointes,
              objet: contact.objet,
              dateConclusion: dateConclu,
              fonction: 'api/fonctions/' + contact.fonction.id,
              genre: 'api/genres/' + genre,
              modification: DateModifFormat
            },
            {headers}).toPromise()
            .then(response => response)
            .catch(this.errorHandler);
        } else {
          return this.http.put(this.contactUrl + '/' + idContactMAJ,
            {
              civilite: civ,
              nom: contact.nom,
              prenom: contact.prenom,
              commentaires: contact.commentaires,
              modeEnvoiPref: contact.modeEnvoiPref,
              piecesJointes: contact.piecesJointes,
              objet: contact.objet,
              dateConclusion: dateConclu,
              fonction: 'api/fonctions/' + contact.fonction.id,
              genre: 'api/genres/' + genre,
              modification: DateModifFormat
            },
            {headers}).toPromise()
            .then(response => response)
            .catch(this.errorHandler);
        }
      }
    }
  }
  deleteContactPays(idContact) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/contact/pays/delete/' + idContact,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  deleteContactLangues(idContact) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/contact/langues/delete/' + idContact,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  deleteContactCategGen(idContact) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/contact/categgen/delete/' + idContact,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  deleteContactSousCategGen(idContact) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/contact/souscateggen/delete/' + idContact,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getContactOrganisationBy(idContact) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/contact_organisations?contact=' + idContact,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  deleteContactOrganisation(id) {
    const headers = this.initOptionHeader();
    return this.http.delete(Environement.BASE_API_URL + '/api/contact_organisations/' + id,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getReseauxSociauxBy(contactAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/contact/intranet/reseauxsociaux/' + contactAncienId,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getContactSupportBycontactID(idContact) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/contact_supports?contact=' + idContact,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getContactListeContactByContactID(idContact) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/liste_contact_contacts?contact=' + idContact + '&pagination=false',
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  addContactSupport(contactID, supportID, tel1, tel2, email, fonctionID, participe, dateDepart, commentaire, dernierEmailVerif) {
    const headers = this.initOptionHeader();
    let participeBool = false;
    if (participe == 'N') {
      participeBool = true;
    }
    // si date de création renseignée
    if (dateDepart != null && dateDepart != '0000-00-00') {
      dateDepart = dateDepart + ' 00:00:00' ;
    } else  {
      dateDepart = null;
    }
    if (fonctionID > 0) {
      fonctionID = 'api/fonctions/' + fonctionID;
    } else {
      fonctionID = null;
    }
    return this.http.post(Environement.BASE_API_URL + '/api/contact_supports',
      {
        'contact': 'api/contacts/' + contactID,
        'support': 'api/supports/' + supportID,
        'fonction': fonctionID,
        'participe': participeBool,
        'premierTel':  tel1,
        'deuxiemeTel':  tel2,
        'email': email,
        'dateDepart': dateDepart,
        'commentaires': commentaire,
        'dernierEmailVerif': dernierEmailVerif
      },
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getFonctionIntranetByAncienID(fonctionAncienID) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/contact/intranet/fonction/' + fonctionAncienID,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  addListeContactContact(listeContactID, contactID, supportIDPref) {
    const headers = this.initOptionHeader();
    if (supportIDPref === 0) {
      supportIDPref = null;
    } else if (supportIDPref != null) {
      supportIDPref = 'api/supports/' + supportIDPref;
    }
    return this.http.post(Environement.BASE_API_URL + '/api/liste_contact_contacts',
      {
        'contact': 'api/contacts/' + contactID,
        'listeContact': 'api/liste_contacts/' + listeContactID,
        'supportPrefere': supportIDPref
      },
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  importerContactIntranet(contactID, contactAncienID = null) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/importer/contact/intranet/' + contactID + '/' + contactAncienID,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getLastSuivisByContactID(contactID) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suiviURL + '?contact.id=' + contactID + '&order[date]=desc',
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getAncienIdAuteur(contactID): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '/auteur/intranet/' + contactID , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateContactOrganisation(asso, telPro1, telPro2, emailPro) {
    const headers = this.initOptionHeader();
    return this.http.put(Environement.BASE_API_URL + '/api/contact_organisations/' + asso.id,
      {
        premierTel:  telPro1,
        deuxiemeTel:  telPro2,
        email: emailPro},
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  addContactAssoIntranet(typeAsso, contactID, typeAssoID, supportPrefID = null) {
    const headers = this.initOptionHeader();
    if (typeAsso == 'liste') {
      if (supportPrefID == null) {
        supportPrefID = 0;
      }
      return this.http.get(this.contactUrl + '/' + typeAsso + '/add/asso/intranet/' + contactID + '/' + typeAssoID
        + '/' + supportPrefID
        , {headers: headers}).toPromise()
        .then( response => response)
        .catch(this.errorHandler);
    } else {
      return this.http.get(this.contactUrl + '/' + typeAsso + '/add/asso/intranet/' + contactID + '/' + typeAssoID
        , {headers: headers}).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }
  updateContactAssoIntranet(typeAsso, typeAssoID) {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '/' + typeAsso + '/update/asso/intranet/' + typeAssoID,
          {headers: headers}).toPromise()
          .then( response => response)
          .catch(this.errorHandler);
  }
  deleteContactAssoIntranet(typeAsso, typeAssoID) {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '/' + typeAsso + '/delete/asso/intranet/' + typeAssoID,
          {headers} ).toPromise()
          .then(response => response)
          .catch(this.errorHandler);
  }
  updateContactSupport(asso, ligneDirect, autreLigne, email, participe, fonctionID) {
    const headers = this.initOptionHeader();
    if (fonctionID > 0) {
      fonctionID = 'api/fonctions/' + fonctionID;
    } else {
      fonctionID = null;
    }
    return this.http.put(Environement.BASE_API_URL + '/api/contact_supports/' + asso.id,
      {
        premierTel:  ligneDirect,
        deuxiemeTel:  autreLigne,
        'email': email,
        'participe': participe,
        fonction: fonctionID},
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  deleteContactSupport(id) {
    const headers = this.initOptionHeader();
    return this.http.delete(Environement.BASE_API_URL + '/api/contact_supports/' + id,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  importerListeContact($ancienID) {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '/liste/intranet/importer/' + $ancienID,
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  deleteListeContactContact(id) {
    const headers = this.initOptionHeader();
    return this.http.delete(Environement.BASE_API_URL + '/api/liste_contact_contacts/' + id,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  creerVcard(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '/creer/vcard/' + id,
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  getAllSuivisContact(contactID, page) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suiviURL + '?contact.id=' + contactID + '&_page=' + page + '&order[date]=desc',
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getContactsRechercheAvancee(nom, prenom, civiliteId, genreId, fonctionId, suiviPar, modeEnvoiPref, premierTel,
                              deuxiemeTel, email, rue, complements, codePostal, boitePostale,
                              paysId, creeChoisi, jours, page) {
    const headers = this.initOptionHeader();
    let url = '';
    // customiser url de recherche en fonction des champs renseignés
    if (nom != '') {
      url = url + '&nom=' + nom ;
    }
    if (prenom != '') {
      url = url + '&prenom=' + prenom ;
    }
    if (civiliteId != 0) {
      url = url + '&civilite.id=' + civiliteId ;
    }
    if (genreId != 0) {
      url = url + '&genre.id=' + genreId ;
    }
    if (fonctionId != 0) {
      url = url + '&fonction.ancienId=' + fonctionId ;
    }
    if (suiviPar != 0) {
      url = url + '&auteur.id=' + suiviPar ;
    }
    if (modeEnvoiPref != '') {
      url = url + '&modeEnvoiPref=' + modeEnvoiPref ;
    }
    if (premierTel != '') {
      url = url + '&coordonnees.premierTel=' + premierTel ;
    }
    if (deuxiemeTel != '') {
      url = url + '&coordonnees.deuxiemeTel=' + deuxiemeTel ;
    }
    if (email != '') {
      url = url + '&coordonnees.email=' + email ;
    }
    if (rue != '') {
      url = url + '&coordonnees.rue=' + rue ;
    }
    if (complements != '') {
      url = url + '&coordonnees.complements=' + complements ;
    }
    if (codePostal != '') {
      url = url + '&coordonnees.codePostal=' + codePostal ;
    }
    if (boitePostale != '') {
      url = url + '&coordonnees.boitePostale=' + boitePostale ;
    }
    if (paysId != 0) {
      url = url + '&coordonnees.pays.id=' + paysId ;
    }
    if (creeChoisi != '' && jours != 0) {
      const dateActuelle = new Date();
      dateActuelle.setDate(dateActuelle.getDate() - jours );
      const dateActu = dateActuelle.getFullYear() + '-' + (dateActuelle.getMonth() + 1) + '-' + dateActuelle.getDate() + ' '
        + dateActuelle.getHours() + ':' + dateActuelle.getMinutes() + ':00' ;
      if (creeChoisi == 'plus') {
        // il y a plus de X jours
        url = url + '&creation[before]=' + dateActu ;
      }
      if (creeChoisi == 'moins') {
        // il y a moins de X jours
        url = url + '&creation[after]=' + dateActu ;
      }
    }
    // supprimer le premier &
    url = url.substr(1);
    return this.http.get(this.contactUrl + '?' + url + '&_page=' + page + '&order[nom]=asc', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getContactOrgRechercheAvancee(orgID, page) {
    const headers = this.initOptionHeader();
    let url = '';
    if (orgID != 0) {
      url = url + '&organisation.ancienId=' + orgID ;
    }
    url = url.substr(1);
    return this.http.get(Environement.BASE_API_URL + '/api/contact_organisations?' + url
      + '&_page=' + page + '&order[nom]=asc', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getContactSupportRechercheAvancee(supportID, page) {
    const headers = this.initOptionHeader();
    let url = '';
    if (supportID != 0) {
      url = url + '&support.ancienId=' + supportID ;
    }
    url = url.substr(1);
    return this.http.get(Environement.BASE_API_URL + '/api/contact_supports?' + url
      + '&_page=' + page + '&order[nom]=asc', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getlisteContactContactRechercheAvancee(listeID, page) {
    const headers = this.initOptionHeader();
    let url = '';
    if (listeID != 0) {
      url = url + '&listeContact.ancienId=' + listeID ;
    }
    url = url.substr(1);
    return this.http.get(Environement.BASE_API_URL + '/api/liste_contact_contacts?' + url
      + '&_page=' + page + '&order[nom]=asc', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  exporterContactExcel(contactID, id, nom, prenom, civilite,  fonction,  contenu,  premierTel,  deuxiemeTel,  email,
                       rue,  complement,  bpostal,  cpostal,  ville,  pays,  premierURL,  deuxiemeURL,  societe,
                       telPro1,  telPro2,  emailPro,  support,  fonctionSupport,  telSupport,  emailSupport,
                       categorie,  objet,  dateConclu,  modeEnvoi,  piecesJointes ) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/contacts/exportation/excel/' + contactID + '/' +
      id + '/' + nom + '/' + prenom + '/' + civilite + '/' + fonction + '/' + contenu + '/' +
      premierTel + '/' + deuxiemeTel + '/' + email + '/' + rue + '/' + complement + '/' + bpostal + '/' +
      cpostal + '/' + ville + '/' + pays + '/' + premierURL + '/' + deuxiemeURL + '/' + societe + '/' + telPro1 + '/' +
      telPro2 + '/' + emailPro + '/' + support + '/' + fonctionSupport + '/' + telSupport + '/' + emailSupport + '/' +
      categorie + '/' + objet + '/'  + dateConclu + '/' + modeEnvoi + '/' + piecesJointes,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getContactRechercheByNom(champRecherche) {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '?nom=' + champRecherche + '&order[nom]=asc', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getSuggestionDedoublonnage() {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '?pagination=false&order[nom]=asc&exists[client]=false', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  deleteContact(id) {
    const headers = this.initOptionHeader();
    return this.http.delete(this.contactUrl + '/' +  id,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  deleteContactCopieIntranet(ancienID) {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '/delete/copie/' + ancienID ,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  updateContactSuvi(contact, suiviID) {
    const headers = this.initOptionHeader();
    return this.http.put(this.suiviURL + '/' + suiviID,
      {
        'contact': '/api/contacts/' + contact.id,
      },
      {headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateDirigeantSociete(contact, socID) {
    const headers = this.initOptionHeader();
    return this.http.put(Environement.BASE_API_URL + '/api/organisations/' + socID,
      {
        'dirigeant': '/api/contacts/' + contact.id,
      },
      {headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateContactSuviIntranet(contactID, suiviID) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/update/contact/suivi/intranet/' + contactID
      + '/' + suiviID, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateDirigeantSocieteIntranet(contactID, socID) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/update/societe/dirigeant/intranet/' + contactID
      + '/' + socID, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateListeContactContact(asso, support) {
    const headers = this.initOptionHeader();
    return this.http.put(Environement.BASE_API_URL + '/api/liste_contact_contacts/' + asso.id,
      {
        'supportPrefere': support,
},
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getContactByAncienId(ancienID) {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '?ancienId=' + ancienID, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getContactRechercheIntranet(recherche, type) {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '/recherche/intranet/' + recherche + '/' + type , {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  importerSuiviIntranet(suiviAncienID) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/suivi/intranet/importer/tout/' + suiviAncienID, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  demandeSupprimerContact(contactID, nomDemandeur) {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '/mail/supprimer/' + contactID + '/' + nomDemandeur,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getAllParutionsContact(contactID, page = null) {
    const headers = this.initOptionHeader();
    if (page === null) {
      return this.http.get(this.parutionUrl + '?parutionContacts.contact.ancienId=' + contactID +
        '&order[date]=desc',
        {headers: headers}).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    } else {
      return this.http.get(this.parutionUrl + '?parutionContacts.contact.ancienId=' + contactID + '&_page=' + page +
        '&order[date]=desc',
        {headers: headers}).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }
  importerSupport(supportId, contactID) {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '/support/intranet/importer/' + supportId + '/' + contactID,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getContactParutionByContactID(idContact) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/parution_contacts?contact.id=' + idContact,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getParutionContacts( idParution, idContact) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/parution_contacts?contact.id=' + idContact + '&parution.id=' + idParution,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  importerContactsSuivis() {
    const headers = this.initOptionHeader();
    return this.http.get(this.contactUrl + '/recuperer/contacts/importer/suivis',
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
