import {Injectable} from '@angular/core';
import {ImportListeContactHaService} from '../../interne/import/ImportListeContactHaService';



@Injectable()
export class ImportListeContactHaShareService {

  importListeContactHaService: ImportListeContactHaService;
  associationContacts: Array<any>;

  listeImportHaColone = [];

  listeTypeColone = [
    {
      content: 'Nom',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Prénom',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Civilité',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Fonction',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Domaine',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Sous Domaines',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Média',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Emission',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Production',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Adresse 1',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Adresse 2',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Code Postal',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Ville',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Pays',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'En tête',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Tel',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Tel direct',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Fax',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Fax direct',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Email',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Type Média',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Domaine Média',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Périodicité',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Couverture Média',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Preférentiel',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Parution',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Bouclage',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Audience',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
    {
      content: 'Audience Source',
      effectAllowed: 'move',
      disable: false,
      handle: false,
    },
  ];

  constructor() {
    this.associationContacts = [];
  }

  public updateContact(file) {
    const that = this;
    this.importListeContactHaService.getTabFromCsv(file)
      .then((retour) => {
        console.log(retour);
        that.associationContacts = retour;
      });
  }

  public initTabTitreColonne() {
    const that = this;
    that.listeImportHaColone = [];
    this.associationContacts.forEach( (element) => {
      const contact = {
        content: element,
        effectAllowed: 'move',
        disable: false,
        handle: false,
      };
      that.listeImportHaColone.push(contact);
    });
    that.orderListeImportHaColone();
  }
  public orderListeImportHaColone() {
    const contactColoneOrder = [];
    const contactColoneOrderNonTrouve = [];
    const trouver = [];
    const that = this;
    let find = 0;
    this.listeTypeColone.forEach( (element) => {
      find = 0;
      that.listeImportHaColone.forEach((element1) => {
        if (that.removeAccents(that.retraitCaractere(element1[`content`].toLowerCase())) ===
          that.removeAccents(that.retraitCaractere(element[`content`].toLowerCase()))) {
          trouver.push(element1[`content`]);
          find = element1;
        }
      });
      if (find === 0) {
        that.listeImportHaColone.forEach((element1) => {
          if (that.retraitMedia(that.removeAccents(element1[`content`].toLowerCase())) ===
            that.retraitMedia(that.removeAccents(element[`content`].toLowerCase()))) {
            find = element1;
            trouver.push(element1[`content`]);
          }
        });
      }
      if (find !== 0) {
        contactColoneOrder.push(find);
      }
    });

    that.listeImportHaColone.forEach((element1) => {
      find = 0;
      trouver.forEach((element) => {
        if (element === element1[`content`]) {
          find = 1;
        }
      });
      if (find === 0) {
        contactColoneOrderNonTrouve.push(element1);
      }
    });
    that.listeImportHaColone = [];
    contactColoneOrder.forEach((element) => {
      that.listeImportHaColone.push(element);
    });
    contactColoneOrderNonTrouve.forEach((element) => {
      that.listeImportHaColone.push(element);
    });
  }
  public removeAccents(str) {
    const accents    = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
    str = str.split('');
    const strLen = str.length;
    let i, x;
    for (i = 0; i < strLen; i++) {
      if ((x = accents.indexOf(str[i])) != -1) {
        str[i] = accentsOut[x];
      }
    }
    return str.join('');
  }
  public retraitCaractere(chaine) {
    chaine = chaine.replace(' ', '');
    chaine = chaine.replace('-', '');
    chaine = chaine.replace('_', '');
    return chaine;
  }
  public retraitMedia(chaine) {
    chaine = chaine.replace(' ', '');
    chaine = chaine.replace('-', '');
    chaine = chaine.replace('_', '');
    chaine = chaine.replace('media', '');
    return chaine;
  }

  public valideColonne() {
    this.importListeContactHaService.valideColonne(this.listeTypeColone, this.listeImportHaColone).then((retour) => {
      console.log(retour);
    });

  }
}
