import {Component, Input, OnInit} from '@angular/core';
import {Suivi} from '../../../../../model/suivi/Suivi';

@Component({
  selector: 'app-pagination-content-suivi',
  templateUrl: './pagination-content-suivi.component.html',
  styleUrls: ['./pagination-content-suivi.component.scss']
})
export class PaginationContentSuiviComponent implements OnInit {

  @Input()
  suivis: Array<Suivi>;

  constructor() { }

  ngOnInit() {
  }

}
