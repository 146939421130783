import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ImagePressroom} from '../../../../model/client/pressroom/ImagePressroom';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {Environement} from '../../../../environement/environement';
import {ImageArticleSite} from '../../../../model/site/ImageArticleSite';
import {ImageArticleSiteService} from '../../../../service/interne/Site/ImageArticleSiteService';
import {ArticleSite} from '../../../../model/site/ArticleSite';
import {ArticleSiteService} from '../../../../service/interne/Site/ArticleSiteService';

@Component({
  selector: 'app-site-escal-article-photo',
  templateUrl: './site-escal-article-photo.component.html',
  styleUrls: ['./site-escal-article-photo.component.scss'],
  providers: [ ImageArticleSiteService, ArticleSiteService]
})
export class SiteEscalArticlePhotoComponent implements OnInit {

  id: any;
  sub: any;
  imageArticle: ImageArticleSite;
  imageUpload: Array<any> = [];
  addOpen: boolean;
  article: ArticleSite;

  @ViewChild('inputFile')
  inputFile: ElementRef;

  constructor(private route: ActivatedRoute, private dialogueShareService: DialogueShareService,
              private imageArticleSiteService: ImageArticleSiteService, private articleSiteService: ArticleSiteService) { }

  ngOnInit() {
    const that = this;
    this.imageArticle = new ImageArticleSite(0, '', 'phototech');
    //this.pressroomShareService.loadPhototech();
    this.closeAddOpen();
    this.sub = this.route.params.subscribe(params => {
      that.id = params['id'];
      that.articleSiteService.getArticle(that.id)
        .then((retour) =>{
          that.article = retour as ArticleSite;
        });

    });
  }


  savePhoto() {
    const that = this;
    if (this.imageArticle.nom.length < 3){
      that.dialogueShareService.addDialogue('Le nom doit faire au moins 3 caractères');
    } else if (typeof (this.imageUpload['filename']) === undefined) {
      that.dialogueShareService.addDialogue('Choisissez d\'abord un fichier');
    } else {
      this.imageArticleSiteService.addImageArticle(this.imageArticle, this.imageUpload, this.id)
        .then(() => {
          that.ngOnInit();
          this.closeAddOpen();
        });
    }
  }

  closeAddOpen() {
    this.addOpen = false;
    this.imageUpload = [];
    this.imageArticle = new ImageArticleSite(0, '', 'phototech');
  }

  deletePhoto(photo: ImageArticleSite) {
    const variable = {
      imageArticleSiteService: this.imageArticleSiteService, photo: photo, that: this
    };
    this.dialogueShareService.addDialogue('êtes vous sûre de vouloir supprmier ce logo', () => {
      variable.imageArticleSiteService.deleteteImageArticle(variable.photo)
        .then((retour) => {
          variable.that.ngOnInit();
        });
    });
  }

  changeImage(event) {
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        const extension = file.name.split('.')[1];
        if (extension.toUpperCase() === 'JPEG' || extension.toUpperCase() === 'PNG' || extension.toUpperCase() === 'JPG'){

          that.imageUpload['filename'] = file.name;
          that.imageUpload['filetype'] = file.type;
          // @ts-ignore
          that.imageUpload['value'] = reader.result.split(',')[1];
          that.imageUpload['extension'] = file.name.split('.')[1];
        } else {
          that.dialogueShareService.addDialogue('Seul les format jpg, png, jpeg sont accepté');
          that.inputFile.nativeElement.value = '';
        }
      };
    }
  }

  getImageSource(logo: ImagePressroom){
    return Environement.BASE_API_URL + '/image/pressroom/content/' + logo.id;
  }


}
