import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';
import {removeAccents} from '../../partage/prototype/sansAccents';

@Injectable()
export class ActualiteService {
  private actualiteUrl = Environement.BASE_API_URL + '/api/actualites';
  constructor(private http: HttpClient) {
  }
  getActualites() {
    const headers = this.initOptionHeader();
    return this.http.get(this.actualiteUrl, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getActualiteDetails(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.actualiteUrl + '/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getActualitesParDefaut(page) {
    const headers = this.initOptionHeader();
    return this.http.get(this.actualiteUrl + '?_page=' + page + '&order[id]=desc', {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getActualitesRecherche(recherche: Array<any>, page) {
    const content = recherche[0];
    let type = recherche[1];
    const headers = this.initOptionHeader();
    type = removeAccents(type.toLowerCase());
    if (type === 'tous') {
      return this.http.get(this.actualiteUrl + '?several_all=' + content + '&_page=' + page + '&order[id]=desc',
        {headers}).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'nom') {
      return this.http.get(this.actualiteUrl + '?actu_nom=' + content + '&_page=' + page + '&order[id]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'client') {
      return this.http.get(this.actualiteUrl + '?actu_client=' + content + '&_page=' + page + '&order[id]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'pitch') {
      return this.http.get(this.actualiteUrl + '?actu_pitch=' + content + '&_page=' + page + '&order[id]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'theme descriptif') {
      return this.http.get(this.actualiteUrl + '?actu_theme=' + content + '&_page=' + page + '&order[id]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'perspective') {
      return this.http.get(this.actualiteUrl + '?actu_perspective=' + content + '&_page=' + page + '&order[id]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'innovation') {
      return this.http.get(this.actualiteUrl + '?actu_innovation=' + content + '&_page=' + page + '&order[id]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'chiffres cles') {
      return this.http.get(this.actualiteUrl + '?actu_chiffre=' + content + '&_page=' + page + '&order[id]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }

  addActualite(actualite, clientId) {
    const DateCre = actualite.creation.getFullYear() + '-' + (actualite.creation.getMonth() + 1).toString() + '-' + actualite.creation.getDate() + ' ' + actualite.creation.getHours() + ':' + actualite.creation.getMinutes() + ':00' ;
    const headers = this.initOptionHeader();
    return this.http.post(this.actualiteUrl, {'nom': actualite.nom, 'client': Environement.BASE_API_URL + '/api/clients/' + clientId,
        'pitch': actualite.pitch, 'mission': actualite.mission, 'lien': actualite.lien, 'creation' : DateCre,
        'auteur': Environement.BASE_API_URL + '/api/auth_users/' + actualite.auteur.id},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  importerActualiteIntranet(actualiteId, ancienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.actualiteUrl + '/importation/copie/actualite/' + actualiteId + '/' + ancienId,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  addAncienIdActualite(ancienIdActualite, actuId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.actualiteUrl + '/' +  actuId, {'ancienId': ancienIdActualite},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerActualite(id) {
    const headers = this.initOptionHeader();
    return this.http.delete(this.actualiteUrl + '/' +  id,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerImportActualiteIntranet(ancienIdActualite) {
    const headers = this.initOptionHeader();
    return this.http.get(this.actualiteUrl + '/importation/supprimer/copie/actualite/' + ancienIdActualite ,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  modifierActualite(actualite, clientId) {
    actualite.modification = new Date();
    const DateModif = actualite.modification.getFullYear() + '-' + (actualite.modification.getMonth() + 1).toString() + '-' + actualite.modification.getDate() + ' ' + actualite.modification.getHours() + ':' + actualite.modification.getMinutes() + ':00' ;
    const headers = this.initOptionHeader();
    return this.http.put(this.actualiteUrl + '/' +  actualite.id, {'nom': actualite.nom,
        'client': Environement.BASE_API_URL + '/api/clients/' + clientId,
        'pitch': actualite.pitch, 'mission': actualite.mission, 'lien': actualite.lien, 'modification' : DateModif},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
