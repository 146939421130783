import {Injectable} from '@angular/core';


import {DialogueShareService} from './utilitaire/DialogueShareService';
import {Suivi} from '../../model/suivi/Suivi';
import {AuthUser} from '../../model/user/authUser';
import {Contact} from '../../model/contact/Contact';
import {TypeRelationSuivi} from '../../model/suivi/TypeRelationSuivi';
import {VecteurSuivi} from '../../model/suivi/VecteurSuivi';
import {Statut} from '../../model/suivi/Statut';
import {Support} from '../../model/support/Support';
import {SuiviNature} from '../../model/suivi/SuiviNature';
import {Environement} from '../../environement/environement';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {removeAccents} from './prototype/sansAccents';





@Injectable()
export class CategGenShareService {
  private categGenURL = Environement.BASE_API_URL + '/api/categories_generales';
  private categGenIntranetURL = Environement.BASE_API_URL + '/api/organisations/recherche/intranet/categorie/';
  constructor(private http: HttpClient) {
  }
  getCategoriesGenerales() {
    const headers = this.initOptionHeader();
    return this.http.get(this.categGenURL, {headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getCategGenIntranet(recherche) {
    const headers = this.initOptionHeader();
    return this.http.get(this.categGenIntranetURL + recherche, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getCategGenByID(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.categGenURL + '/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getCategGensbyAncienID(ancienID) {
    const headers = this.initOptionHeader();
    return this.http.get(this.categGenURL + '?several_all=' + ancienID, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addCategGen(categGen) {
    const headers = this.initOptionHeader();
    let ancienId = categGen.id;
    if (categGen.ancienId) {
      ancienId = categGen.ancienId;
    }
    return this.http.post(this.categGenURL,
      {libelle: categGen.nom, ancienId: parseInt(ancienId, 10)}, {headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  /*
  getFonctionDetail(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.fonctionURL + '/' + id , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addCategoriesGenerales(fonction) {
    const headers = this.initOptionHeader();
    return this.http.post(this.fonctionURL,
      {libelle: fonction.libelle, ancienId: parseInt(fonction.ancien_id, 10)}, {headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  } */

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
