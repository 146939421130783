import { Injectable } from '@angular/core';
import {TitreMenu} from '../../../model/utilitaire/menu/titreMenu';
import {ContentTitre} from '../../../model/utilitaire/menu/contentTitre';
import {SousMenu} from '../../../model/utilitaire/menu/Sous-menu';
import {Menu} from '../../../model/utilitaire/menu/Menu';
import {AuthShareService} from '../../../service/partage/AuthShareService';




@Injectable()
export class MenuService {
  constructor(public authShareService: AuthShareService) { }

  getMenuUserFake() {
    const escal: Array<TitreMenu> = Array();

    const utilisateurEscal: Array<ContentTitre> = Array();
    const utilisateurActuel: ContentTitre = new ContentTitre('1', 'Actuels', '/escal/user/actuel');
    const utilisateurAncien: ContentTitre = new ContentTitre('1', 'Anciens', '/escal/user/ancien');
    const utilisateurGroup: ContentTitre = new ContentTitre('1', 'Groupes', '/escal/user/groupe');
    utilisateurEscal.push(utilisateurActuel);
    utilisateurEscal.push(utilisateurAncien);
    utilisateurEscal.push(utilisateurGroup);
    const clientEscal: Array<ContentTitre> = Array();
    const clientActuel: ContentTitre = new ContentTitre('1', 'Clientes', '/escal/client/actuel');
    const clientAncien: ContentTitre = new ContentTitre('1', 'Autres', '/escal/societes');
    const clientadd: ContentTitre = new ContentTitre('1', 'Nouvelle', '/escal/societe/ajout');
    clientEscal.push(clientActuel);
    clientEscal.push(clientAncien);
    clientEscal.push(clientadd);
    const maillingEscal: Array<ContentTitre> = Array();
    const maillingEnCour: ContentTitre = new ContentTitre('1', 'À envoyer', '/escal/mailling');
    const maillingEnVoyer: ContentTitre = new ContentTitre('1', 'Envoyés', '/escal/mailling/envoyer');
    maillingEscal.push(maillingEnCour);
    maillingEscal.push(maillingEnVoyer);

    const veilleEscal: Array<ContentTitre> = Array();
    const veilleCreation: ContentTitre = new ContentTitre('1', 'Création', '/escal/veille/creation');
    const veilleJour: ContentTitre = new ContentTitre('1', 'Jours', '/escal/veille/jour');
    const veillethematique: ContentTitre = new ContentTitre('1', 'Thématiques', '/escal/veille/thematique');
    veilleEscal.push(veilleCreation);
    veilleEscal.push(veilleJour);
    veilleEscal.push(veillethematique);

    const suiviEscal: Array<ContentTitre> = Array();
    const suivis: ContentTitre = new ContentTitre('1', 'Tous', '/escal/suivis');
    const nouveauSuivi: ContentTitre = new ContentTitre('1', 'Nouveau', '/escal/suivi/ajout');
    suiviEscal.push(suivis);
    suiviEscal.push(nouveauSuivi);

    const utilisateur: TitreMenu = new TitreMenu('1', 'fa-user', 'Utilisateurs', utilisateurEscal, 'User');
    const client: TitreMenu = new TitreMenu('1', 'fa-solid fa-building', 'Sociétés', clientEscal, 'Client');
    const mailling: TitreMenu = new TitreMenu('1', 'fa-envelope', 'Mailings', maillingEscal, 'Mailling');
    const veille: TitreMenu = new TitreMenu('1', 'fa-sharp fa-light fa-newspaper', 'Veille', veilleEscal, 'Veille');
    const suivi: TitreMenu = new TitreMenu('1', 'fa-regular fa-paper-plane', 'Suivis', suiviEscal, 'Suivi');
    escal.push(utilisateur);
    escal.push(client);
    escal.push(mailling);
    escal.push(veille);
    escal.push(suivi);

    const admin: Array<TitreMenu> = Array();
    const adminGestion: Array<ContentTitre> = Array();
    const adminGestionSalle: ContentTitre = new ContentTitre('13', 'Salle', '/admin/salle');
    const adminUpdateFerie: ContentTitre = new ContentTitre('13', 'Nouveaux jours fériés', '/admin/update/jourFerie');
    adminGestion.push(adminGestionSalle);
    adminGestion.push(adminUpdateFerie);
    if (this.authShareService.isRoleStat('ROLE_ADMIN') || this.authShareService.isRoleStat('ROLE_SUPER_ADMIN')) {
      const titre4: TitreMenu = new TitreMenu('1', 'fa-wrench', 'Gestion', adminGestion, 'admin');
      admin.push(titre4);
    }

    const statisticsGestion: Array<ContentTitre> = Array();
    const statistics: ContentTitre = new ContentTitre('17', 'Intranet', '/admin/statistic/intranet');
    const mutualisations: ContentTitre = new ContentTitre('17', 'Mutualisation', '/admin/statistic/mutualisation');
    statisticsGestion.push(statistics);
    statisticsGestion.push(mutualisations);
    const titre8: TitreMenu = new TitreMenu('18', 'fa-users', 'Statistique', statisticsGestion, 'admin/statistic');
    admin.push(titre8);


    const verification: Array<TitreMenu> = Array();
    const verificationThematiqueCoupure: Array<ContentTitre> = Array();
    const thematiqueVerif: ContentTitre = new ContentTitre('1', 'Verif', '/verif/thematique/verif');
    verificationThematiqueCoupure.push(thematiqueVerif);
    const titre5: TitreMenu = new TitreMenu('1', 'fa-user', 'Thématique Coupure', verificationThematiqueCoupure, 'Verification');
    verification.push(titre5);

    const utilitaire: Array<TitreMenu> = Array();
    const pdf: Array<ContentTitre> = Array();
    const mergePdf: ContentTitre = new ContentTitre('1', 'Merge', '/utilitaire/pdf/mergepdf');
    pdf.push(mergePdf);
    const titre6: TitreMenu = new TitreMenu('1', 'fa-file-pdf', 'pdf', pdf, 'pdf');
    utilitaire.push(titre6);
    const siteEscal: Array<ContentTitre> = Array();
    const listeArticle: ContentTitre = new ContentTitre('1', 'Liste Articles', 'site/article/liste');
    siteEscal.push(listeArticle);
    const listeMotCle: ContentTitre = new ContentTitre('2', 'Liste Mots Clés', 'site/motCle/liste');
    siteEscal.push(listeMotCle);
    // const titre21: TitreMenu = new TitreMenu('1', 'fa-file-pdf', 'site Escal', siteEscal, '/site');
    // utilitaire.push(titre21);


    const retouremail: Array<ContentTitre> = Array();
    const addretouremail: ContentTitre = new ContentTitre('1', 'Ajout', 'utilitaire/retour/email');
    retouremail.push(addretouremail);
    const titre9: TitreMenu = new TitreMenu('1', 'fa-envelope-open', 'Retour Mailings ', retouremail, 'utilitaire');
    utilitaire.push(titre9);


    const bdd: Array<ContentTitre> = Array();
    const bddRequest: ContentTitre = new ContentTitre('1', 'Données', 'utilitaire/bdd/base');
    bdd.push(bddRequest);
    const titre7: TitreMenu = new TitreMenu('1', 'fa-book-reader', 'Exportation', bdd, 'bdd');
    utilitaire.push(titre7);

    const dedoublonner: Array<ContentTitre> = Array();
    const dedoublonnerSoc: ContentTitre = new ContentTitre('1', 'Sociétés', 'utilitaire/dedoublonner/societes');
    dedoublonner.push(dedoublonnerSoc);
    const titre10: TitreMenu = new TitreMenu('1', 'fa-copy', 'Dédoublonnage', dedoublonner, 'dedoublonner');
    utilitaire.push(titre10);

    const importer: Array<ContentTitre> = Array();
    const importerSoc: ContentTitre = new ContentTitre('1', 'Sociétés', 'utilitaire/importer/societes');
    importer.push(importerSoc);
    const titre11: TitreMenu = new TitreMenu('1', 'fa-file-import', 'Importation', importer, 'importer');
    utilitaire.push(titre11);

    const escalMenu: SousMenu = new SousMenu('1', 'fa-info-circle', escal, 'menu de navigation', 'escal');
    const adminMenu: SousMenu = new SousMenu('1', 'fas fa-pencil-alt', admin, 'administration', 'admin', 'ROLE_ADMIN');
    const verifMenu: SousMenu = new SousMenu('1', 'far fa-edit', verification, 'verification données', 'admin', 'ROLE_R_BO');
    const utilitaireMenu: SousMenu = new SousMenu('1', 'far fa-edit', utilitaire, 'utilitaire', 'utilitaire');




    const SousMenuArray: Array<SousMenu> = Array();
    SousMenuArray.push(escalMenu);
    SousMenuArray.push(adminMenu);
    if (this.authShareService.isRoleStat('ROLE_STAT')) {
      const statMenu: SousMenu = new SousMenu('1', 'fas fa-pencil-alt', admin, 'exportation', 'admin', 'ROLE_STAT');
      SousMenuArray.push(statMenu);
    }
    //SousMenuArray.push(verifMenu);
    SousMenuArray.push(utilitaireMenu);
    const menu: Menu = new Menu('1', SousMenuArray);
    return menu;
  }
}

