import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {ElementVeilleService} from '../../../../service/interne/veille/ElementVeilleService';
import {VeilleThematiqueService} from '../../../../service/interne/veille/VeilleThematiqueService';

import {VeilleThematique} from '../../../../model/veille/VeilleThematique';

@Component({
  selector: 'app-escal-veille-thematique-affichage',
  templateUrl: './escal-veille-thematique-affichage.component.html',
  styleUrls: ['./escal-veille-thematique-affichage.component.scss'],
  providers: [ ElementVeilleService, VeilleThematiqueService ]
})
export class EscalVeilleThematiqueAffichageComponent implements OnInit {


  @Input()
  veilleThematiques: Array<VeilleThematique>;
  @Input()
  veilleValider: string;
  @Output()
  actualiser = new EventEmitter<any>();
  @Input()
  modification;

  active: number = 0;

  constructor(private dialogueShareService: DialogueShareService, private elementVeilleService: ElementVeilleService,
              private veilleThematiqueService: VeilleThematiqueService) { }

  ngOnInit() {
  }

  isActif(id) {
    if (id === this.active) {
      return 'active';
    }
  }

  deleteElementVeille(idElmentVeille, idVeilleThematique) {
    var that = this;
    var variable = {'that': that , 'idElmentVeille': idElmentVeille, 'idVeilleThematique': idVeilleThematique,
      'dialogueShareService': this.dialogueShareService, 'elementVeilleService': this.elementVeilleService,
      'veilleThematiqueService': this.veilleThematiqueService };
    this.dialogueShareService.addDialogue('supprimer l\'element veille', () => {
      variable['elementVeilleService'].removeElementVeille(variable['idElmentVeille'])
        .then((retour) => {
          variable['veilleThematiqueService'].getVeilleThematiqueid(variable['idVeilleThematique'])
            .then( (retour) => {
              if(retour.elementsVeille.length > 0){
                variable['that'].actualiser.emit();
                variable['dialogueShareService'].addDialogue('Element veille supprimer');
              } else{
                variable['veilleThematiqueService'].removeVeilleThematique(variable['idVeilleThematique'])
                  .then( () => {
                    variable['that'].actualiser.emit();
                    variable['dialogueShareService'].addDialogue('Element veille supprimer');
                  });
              }
            });
        })
        .catch((retour) => {
          variable['dialogueShareService'].addDialogue('erreur lors de la supression');
        });
    });
  }
}
