import { Component, OnInit } from '@angular/core';
import {Client} from '../../../../model/client/Client';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {ActivatedRoute} from '@angular/router';
import {ClientService} from '../../../../service/interne/client/ClientService';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import {Environement} from '../../../../environement/environement';
import {Color} from 'ng2-charts';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-escal-client-analyses',
  templateUrl: './escal-client-analyses.component.html',
  styleUrls: ['./escal-client-analyses.component.scss'],
  providers: [ ClientService ]
})
export class EscalClientAnalysesComponent implements OnInit {

  private sub: any;
  client: Client;
  datesChoisies: boolean;
  startMoment: Date;
  dateDebut: Date;
  dateFin: Date;
  totalRetombees: number;
  totalCommuniques: number;
  totalInterviews: number;
  totalJournalistesRP: number;
  totalJournalistesRPu: number;
  totalEquivalents: number;
  nbItwParMois: Array<any> = [];
  nbRetombeesParMois: Array<any> = [];
  repartitionTypePresse: Array<any> = [];
  repartitionTypeMedia: Array<any> = [];
  repartitionPeriodicite: Array<any> = [];
  repartitionZone: Array<any> = [];
  repartitionOJD: Array<any> = [];
  repartitionSuiteA: Array<any> = [];
  CPsEnvoyes: Array<any> = [];
  mailingsOutils: Array<any> = [];
  mailingsNiveaux: Array<any> = [];
  mailingsSujets: Array<any> = [];
  mailingsSujetsUn: Array<any> = [];
  mailingsSujetsDeux: Array<any> = [];
  mailingsSujetsTrois: Array<any> = [];
  retombeesThemes: Array<any> = [];
  retombeesType: Array<any> = [];
  retombeesAnnonces: Array<any> = [];
  nbItwParMoisFakeArray: Array<any>;
  nbRetombeesParMoisFakeArray: Array<any>;
  repartitionTypePresseFakeArray: Array<any>;
  repartitionTypeMediaFakeArray: Array<any>;
  repartitionPeriodiciteFakeArray: Array<any>;
  repartitionZoneFakeArray: Array<any>;
  repartitionOJDFakeArray: Array<any>;
  repartitionSuiteAFakeArray: Array<any>;
  CPsEnvoyesFakeArray: Array<any>;
  mailingsOutilsFakeArray: Array<any>;
  mailingsNiveauxFakeArray: Array<any>;
  mailingsSujetsUnFakeArray: Array<any>;
  mailingsSujetsDeuxFakeArray: Array<any>;
  mailingsSujetsTroisFakeArray: Array<any>;
  retombeesThemesFakeArray: Array<any>;
  retombeesTypeFakeArray: Array<any>;
  retombeesAnnoncesFakeArray: Array<any>;

  public lineChartOptions:any = {
    legend: {
      display: true,
      labels: {
        fontColor: '#33363b',
        boxWidth: 20,
        fontSize: 18,
      }},
    tooltips: {
      enabled	: true,
      xPadding: 40,
      yxPadding: 40,
      bodyFontSize: 20,
    },
    datasets: [{
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)'
      ],
      borderColor: [
        'rgb(255, 99, 132)'
      ],
    }],
    responsive: true
  };

  public barLineChartOptions:any = {
    legend: {
      display: false,
      labels: {
        fontColor: '#33363b',
        boxWidth: 20,
        fontSize: 30,
      }},
    tooltips: {
      enabled	: true,
      xPadding: 40,
      yxPadding: 40,
      bodyFontSize: 30,
    },
    datasets: [{
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)'
      ],
      borderColor: [
        'rgb(255, 99, 132)'
      ],
    }],
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    },
    responsive: true
  };


  public doughnutChartType: string = 'doughnut';
  public doughnutChartColors: Color[] = [{
    backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgba(255, 205, 86, 0.2)', 'rgba(75, 192, 192, 0.2)',
      'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(201, 203, 207, 0.2)', 'rgb(255, 218, 185, 0.2)',
      'rgb(255, 255, 77, 0.2)', 'rgb(119, 221, 119, 0.2)', 'rgb(255, 133, 51, 0.2)', 'rgb(173, 216, 230, 0.2)',
    'rgb(255, 153, 204, 0.2)', 'rgb(177, 156, 217, 0.2)', 'rgb(77, 121, 255, 0.2)', 'rgb(255, 51, 51, 0.2)',
    'rgb(102, 255, 51, 0.2)', 'rgb(255, 102, 179, 0.2)']
  }];

  public barChartType: string = 'bar';
  public barChartColors: Color[] = [{
    backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgba(255, 205, 86, 0.2)', 'rgba(75, 192, 192, 0.2)',
      'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(201, 203, 207, 0.2)', 'rgba(255, 99, 132, 0.2)',
      'rgba(255, 159, 64, 0.2)', 'rgba(255, 205, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)',
      'rgba(153, 102, 255, 0.2)', 'rgba(201, 203, 207, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)',
      'rgba(255, 205, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)',
      'rgba(201, 203, 207, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgba(255, 205, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(201, 203, 207, 0.2)',
      'rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgba(255, 205, 86, 0.2)', 'rgba(75, 192, 192, 0.2)',
      'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(201, 203, 207, 0.2)'],
    borderColor: ['rgb(255, 99, 132)', 'rgb(255, 159, 64)', 'rgb(255, 205, 86)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)',
      'rgb(153, 102, 255)', 'rgb(201, 203, 207)', 'rgb(255, 99, 132)', 'rgb(255, 159, 64)', 'rgb(255, 205, 86)',
      'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)', 'rgb(201, 203, 207)', 'rgb(255, 99, 132)',
      'rgb(255, 159, 64)', 'rgb(255, 205, 86)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)', 'rgb(201, 203, 207)',
      'rgb(255, 99, 132)', 'rgb(255, 159, 64)', 'rgb(255, 205, 86)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)',
      'rgb(153, 102, 255)', 'rgb(201, 203, 207)', 'rgb(255, 99, 132)', 'rgb(255, 159, 64)', 'rgb(255, 205, 86)',
      'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)', 'rgb(201, 203, 207)'],
    borderWidth: 1
  }];

  public barChartDataRetombeesMois: number[] = [];
  public barChartLabelsRetombeesMois: string[] = [];
  public barChartDataInterviewsMois: number[] = [];
  public barChartLabelsInterviewsMois: string[] = [];
  public doughnutChartDataTypePresse: number[] = [];
  public doughnutChartLabelsTypePresse: string[] = [];
  public doughnutChartDataTypeMedia: number[] = [];
  public doughnutChartLabelsTypeMedia: string[] = [];
  public doughnutChartDataPeriodicite: number[] = [];
  public doughnutChartLabelsPeriodicite: string[] = [];
  public doughnutChartDataZone: number[] = [];
  public doughnutChartLabelsZone: string[] = [];
  public doughnutChartDataOutils: number[] = [];
  public doughnutChartLabelsOutils: string[] = [];
  public doughnutChartDataNiveaux: number[] = [];
  public doughnutChartLabelsNiveaux: string[] = [];
  public doughnutChartDataSujetsUn: number[] = [];
  public doughnutChartLabelsSujetsUn: string[] = [];
  public doughnutChartDataSujetsDeux: number[] = [];
  public doughnutChartLabelsSujetsDeux: string[] = [];
  public doughnutChartDataSujetsTrois: number[] = [];
  public doughnutChartLabelsSujetsTrois: string[] = [];
  public doughnutChartDataThemes: number[] = [];
  public doughnutChartLabelsThemes: string[] = [];
  public doughnutChartDataType: number[] = [];
  public doughnutChartLabelsType: string[] = [];
  public doughnutChartDataAnnonces: number[] = [];
  public doughnutChartLabelsAnnonces: string[] = [];

  constructor(private dialogueShareService: DialogueShareService, private route: ActivatedRoute, private clientService: ClientService,
              private spinner: NgxSpinnerService) { }

  ngOnInit() {
    const that = this;
    this.datesChoisies = false;
    this.sub = this.route.params.subscribe(params => {
      this.clientService.getClientDetail(params.id)
        .then((retour) => {
          that.client = retour as Client;
        });
    });
  }

  getAnalysesClient() {
    if (this.dateDebut != undefined && this.dateFin != undefined) {
      const dateDebut = this.dateDebut.getFullYear() + '-' + (this.dateDebut.getMonth() + 1).toString() + '-' + this.dateDebut.getDate();
      const dateFin = this.dateFin.getFullYear() + '-' + (this.dateFin.getMonth() + 1).toString() + '-' + this.dateFin.getDate();
      this.spinner.show();
      this.clientService.getClientAnalyses(this.client.ancienId, dateDebut, dateFin)
        .then((datas) => {
          this.totalRetombees = datas.totalRetombees;
          this.totalCommuniques = datas.totalCommuniques;
          this.totalInterviews = datas.totalInterviews;
          this.totalJournalistesRP = datas.totalJournalistesRP;
          this.totalJournalistesRPu = datas.totalJournalistesRPu;
          this.totalEquivalents = datas.totalEquivalents;
          this.nbItwParMois = datas.nbItwParMois;
          this.nbRetombeesParMois = datas.nbRetombeesParMois;
          this.repartitionTypePresse = datas.repartitionTypePresse;
          this.repartitionTypeMedia = datas.repartitionTypeMedia;
          this.repartitionPeriodicite = datas.repartitionPeriodicite;
          this.repartitionZone = datas.repartitionZone;
          this.repartitionOJD = datas.repartitionOJD;
          this.repartitionSuiteA = datas.repartitionSuiteA;
          this.CPsEnvoyes = datas.CPsEnvoyes;
          this.mailingsOutils = datas.mailingsOutils;
          this.mailingsNiveaux = datas.mailingsNiveaux;
          this.mailingsSujets = datas.mailingsSujets;
          if (this.mailingsSujets != undefined) {
            if (this.mailingsSujets[0] != undefined) {
              this.mailingsSujetsUn = datas.mailingsSujets[0];
            }
            if (this.mailingsSujets[1] != undefined) {
              this.mailingsSujetsDeux = datas.mailingsSujets[1];
            }
            if (this.mailingsSujets[2] != undefined) {
              this.mailingsSujetsTrois = datas.mailingsSujets[2];
            }
          }
          this.retombeesThemes = datas.retombeesThemes;
          this.retombeesType = datas.retombeesType;
          this.retombeesAnnonces = datas.retombeesAnnonces;
          // on retire le champs Moyenne de la taille du tableau
          this.nbItwParMoisFakeArray = new Array(Object.keys(this.nbItwParMois).length - 1);
          this.nbRetombeesParMoisFakeArray = new Array(Object.keys(this.nbRetombeesParMois).length - 1);
          this.repartitionTypePresseFakeArray = new Array(Object.keys(this.repartitionTypePresse).length);
          this.repartitionTypeMediaFakeArray = new Array(Object.keys(this.repartitionTypeMedia).length);
          this.repartitionPeriodiciteFakeArray = new Array(Object.keys(this.repartitionPeriodicite).length);
          this.repartitionZoneFakeArray = new Array(Object.keys(this.repartitionZone).length);
          this.repartitionOJDFakeArray = new Array(Object.keys(this.repartitionOJD).length);
          this.repartitionSuiteAFakeArray = new Array(Object.keys(this.repartitionSuiteA).length);
          this.CPsEnvoyesFakeArray = new Array(Object.keys(this.CPsEnvoyes).length);
          this.mailingsOutilsFakeArray = new Array(Object.keys(this.mailingsOutils).length);
          this.mailingsNiveauxFakeArray = new Array(Object.keys(this.mailingsNiveaux).length);
          if (this.mailingsSujets != undefined) {
            if (this.mailingsSujets[0] != undefined) {
              this.mailingsSujetsUnFakeArray = new Array(Object.keys(this.mailingsSujetsUn).length - 1);
            }
            if (this.mailingsSujets[1] != undefined) {
              this.mailingsSujetsDeuxFakeArray = new Array(Object.keys(this.mailingsSujetsDeux).length - 1);
            }
            if (this.mailingsSujets[2] != undefined) {
              this.mailingsSujetsTroisFakeArray = new Array(Object.keys(this.mailingsSujetsTrois).length - 1);
            }
          }
          this.retombeesThemesFakeArray = new Array(Object.keys(this.retombeesThemes).length);
          this.retombeesTypeFakeArray = new Array(Object.keys(this.retombeesType).length);
          this.retombeesAnnoncesFakeArray = new Array(Object.keys(this.retombeesAnnonces).length);
          // configurer les données et les labels dynamiquement
          for (let i = 0; i < Object.keys(this.nbRetombeesParMois).length - 1; i++) {
            this.barChartDataRetombeesMois.push(this.nbRetombeesParMois[i].nbRetombees);
            this.barChartLabelsRetombeesMois.push(this.nbRetombeesParMois[i].mois);
          }
          for (let i = 0; i < Object.keys(this.nbItwParMois).length - 1; i++) {
            this.barChartDataInterviewsMois.push(this.nbItwParMois[i].nbItw);
            this.barChartLabelsInterviewsMois.push(this.nbItwParMois[i].mois);
          }
          for (let i = 0; i < Object.keys(this.repartitionTypePresse).length; i++) {
            this.doughnutChartDataTypePresse.push(this.repartitionTypePresse[i].totalType);
            this.doughnutChartLabelsTypePresse.push(this.repartitionTypePresse[i].type);
          }
          for (let i = 0; i < Object.keys(this.repartitionTypeMedia).length; i++) {
            this.doughnutChartDataTypeMedia.push(this.repartitionTypeMedia[i].totalGenre);
            this.doughnutChartLabelsTypeMedia.push(this.repartitionTypeMedia[i].genre);
          }
          for (let i = 0; i < Object.keys(this.repartitionPeriodicite).length; i++) {
            this.doughnutChartDataPeriodicite.push(this.repartitionPeriodicite[i].totalType);
            this.doughnutChartLabelsPeriodicite.push(this.repartitionPeriodicite[i].type);
          }
          for (let i = 0; i < Object.keys(this.repartitionZone).length; i++) {
            this.doughnutChartDataZone.push(this.repartitionZone[i].totalZone);
            this.doughnutChartLabelsZone.push(this.repartitionZone[i].zone);
          }
          for (let i = 0; i < Object.keys(this.mailingsOutils).length; i++) {
            this.doughnutChartDataOutils.push(this.mailingsOutils[i].totalOutils);
            this.doughnutChartLabelsOutils.push(this.mailingsOutils[i].outil);
          }
          for (let i = 0; i < Object.keys(this.mailingsNiveaux).length; i++) {
            this.doughnutChartDataNiveaux.push(this.mailingsNiveaux[i].totalNiveaux);
            this.doughnutChartLabelsNiveaux.push(this.mailingsNiveaux[i].niveau);
          }
          if (this.mailingsSujets != undefined) {
            if (this.mailingsSujets[0] != undefined) {
              for (let i = 0; i < Object.keys(this.mailingsSujetsUn).length - 1; i++) {
                this.doughnutChartDataSujetsUn.push(this.mailingsSujetsUn[i].total);
                this.doughnutChartLabelsSujetsUn.push(this.mailingsSujetsUn[i].nom);
              }
            }
            if (this.mailingsSujets[1] != undefined) {
              for (let i = 0; i < Object.keys(this.mailingsSujetsDeux).length - 1; i++) {
                this.doughnutChartDataSujetsDeux.push(this.mailingsSujetsDeux[i].total);
                this.doughnutChartLabelsSujetsDeux.push(this.mailingsSujetsDeux[i].nom);
              }
            }
            if (this.mailingsSujets[2] != undefined) {
              for (let i = 0; i < Object.keys(this.mailingsSujetsTrois).length - 1; i++) {
                this.doughnutChartDataSujetsTrois.push(this.mailingsSujetsTrois[i].total);
                this.doughnutChartLabelsSujetsTrois.push(this.mailingsSujetsTrois[i].nom);
              }
            }
          }
          for (let i = 0; i < Object.keys(this.retombeesThemes).length; i++) {
            this.doughnutChartDataThemes.push(this.retombeesThemes[i].totalThemes);
            this.doughnutChartLabelsThemes.push(this.retombeesThemes[i].theme);
          }
          for (let i = 0; i < Object.keys(this.retombeesType).length; i++) {
            this.doughnutChartDataType.push(this.retombeesType[i].totalTypes);
            this.doughnutChartLabelsType.push(this.retombeesType[i].type);
          }
          for (let i = 0; i < Object.keys(this.retombeesAnnonces).length; i++) {
            this.doughnutChartDataAnnonces.push(this.retombeesAnnonces[i].totalAnnonces);
            this.doughnutChartLabelsAnnonces.push(this.retombeesAnnonces[i].annonce);
          }
          this.datesChoisies = true;
          this.spinner.hide();
        });
    } else {
      this.dialogueShareService.addDialogue('Les dates sont obligatoires');
    }
  }

}
