import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Client} from '../../../../../model/client/Client';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';

@Component({
  selector: 'app-escal-client-fiche-calendriers-redactionnels',
  templateUrl: './escal-client-fiche-calendriers-redactionnels.component.html',
  styleUrls: ['./escal-client-fiche-calendriers-redactionnels.component.scss']
})
export class EscalClientFicheCalendriersRedactionnelsComponent implements OnInit {

  @Input()
  client: Client;
  @Output()
  refresh = new EventEmitter();
  constructor(public formatIntranetShareService: FormatIntranetShareService) { }

  ngOnInit() {
  }

}
