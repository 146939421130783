import { Component, OnInit } from '@angular/core';
import {AuthShareService} from '../../../../../service/partage/AuthShareService';
import {AdresseService} from '../../../../../service/interne/user/AdresseService';

@Component({
  selector: 'app-escal-utilisateur-profil-administratif-adresse',
  templateUrl: './escal-utilisateur-profil-administratif-adresse.component.html',
  styleUrls: ['./escal-utilisateur-profil-administratif-adresse.component.scss'],
  providers: [ AdresseService ],
})

export class EscalUtilisateurProfilAdministratifAdresseComponent implements OnInit {

  modif: string;
  justChange: number;

  constructor(public authShareService: AuthShareService, private adresseService: AdresseService) {
    document.addEventListener('click', () => this.onClick());
  }

  ngOnInit() {
  }

  onClick(type: string = '') {
    if (type === '' && this.justChange === 1) {
      this.justChange = 0;
    } else {
      if (type !== '') {
        this.justChange = 1;
      }
      this.modif = type;
    }
  }
  updateAdresse(){
    const that = this;
    this.adresseService.updateAdresse(this.authShareService.authUser.user.adresse.id, this.authShareService.authUser.user.adresse.adresse,
      this.authShareService.authUser.user.adresse.codePostal, this.authShareService.authUser.user.adresse.complement,
      this.authShareService.authUser.user.adresse.pays, this.authShareService.authUser.user.adresse.ville)
      .then((retour) => {
        that.authShareService.getUserByAuthToken();
      });
  }
}
