import {ArticleMotCleSite} from './ArticleMotCleSite';

export class MotCleSite {
  id: number;
  motCle: string;
  display: string;
  articlesMotCle: Array<ArticleMotCleSite>;


  constructor(id: number, motCle: string, articlesMotCle: Array<ArticleMotCleSite>) {
    this.id = id;
    this.motCle = motCle;
    this.articlesMotCle = articlesMotCle;
    this.display = motCle;
  }
}
