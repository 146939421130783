import { Component, OnInit } from '@angular/core';
import {CalendarEvent} from 'angular-calendar';
import {SalleService} from '../../../service/interne/lieux/SalleService';
import {Salle} from '../../../model/lieux/Salle';
import {EvenementModelService} from '../../../model/evenement/EvenementModelService';
import {ModalCalendrierShareService} from '../../../service/partage/ModalCalendrierShareService';
import {EvenementService} from '../../../service/interne/calendrier/EvenementService';
import {AuthShareService} from '../../../service/partage/AuthShareService';

@Component({
  selector: 'app-affiche-calendrier-mes-clients',
  templateUrl: './affiche-calendrier-mes-clients.component.html',
  styleUrls: ['./affiche-calendrier-mes-clients.component.scss'],
  providers: [ SalleService, EvenementModelService, EvenementService]
})
export class AfficheCalendrierMesClientsComponent implements OnInit {


  beginView = 'Week';

  beginHourTime = 8;

  endHourTime = 20;

  weekend = false;

  salles: Array<Salle> = [];

  events: Array<CalendarEvent>;


  constructor(private salleService: SalleService, private evenementModelService: EvenementModelService,
              private modalCalendriershareService: ModalCalendrierShareService, private evenementService: EvenementService,
              public modalCalendrierShareService: ModalCalendrierShareService, private authShareService: AuthShareService) {
  }

  ngOnInit() {
    const that = this;
    this.evenementModelService.authShareService = this.authShareService;
    this.salleService.getSalle()
      .then((retour) => {
        that.salles = retour['hydra:member'] as Array<Salle>;
        that.modalCalendriershareService.salles = this.salles;
      });
    this.evenementService.getEventEvent(0, 1, 0)
      .then((event) =>{
        this.events = this.evenementModelService.addEvent(event, this.events, 'client');
      });
    this.evenementService.getEventFerie()
      .then((jourFerier) =>{
        this.events = this.evenementModelService.getEventFerier(jourFerier['hydra:member'], this.events);
      });
  }

  refresh(){
    this.events = [];
    this.modalCalendrierShareService.open = false;
    this.ngOnInit();
  }

}
