import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Contact} from '../../../../../model/contact/Contact';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {Parution} from '../../../../../model/parution/Parution';
import {
  PaginationPaginateurComponent
} from '../../../../utilitaire/pagination/pagination-paginateur/pagination-paginateur.component';
import {ContactService} from "../../../../../service/interne/contact/ContactService";

@Component({
  selector: 'app-escal-contact-fiche-parutions',
  templateUrl: './escal-contact-fiche-parutions.component.html',
  styleUrls: ['./escal-contact-fiche-parutions.component.scss']
})
export class EscalContactFicheParutionsComponent implements OnInit {
  @Input()
  contact: Contact;
  @Output()
  refresh = new EventEmitter();
  @ViewChild(PaginationPaginateurComponent) paginationComponent: PaginationPaginateurComponent;
  constructor(public formatIntranetShareService: FormatIntranetShareService, private contactService: ContactService) { }
  parutionsAssocies: Array<Parution>;
  curentPage = 1;
  maxPage: number;
  total: number;
  fakeArray: Array<any>;
  page: number;
  ngOnInit() {
    this.page = 1;
    this.contactService.getAllParutionsContact(this.contact.ancienId, this.curentPage).then((parutions) => {
      this.parutionsAssocies = parutions['hydra:member'] as Array<Parution>;
      this.total = parutions['hydra:totalItems'];
      this.getMaxPage(parutions['hydra:totalItems']);
      this.fakeArray = new Array(this.maxPage);
    });
  }
  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }

  changePage(page) {
    this.curentPage = page;
    this.ngOnInit();
  }

  accesPage() {
    this.curentPage = this.page;
    this.paginationComponent.page = this.page;
    this.ngOnInit();
    this.paginationComponent.initPage();
  }
}
