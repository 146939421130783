import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SuivitIntranetService} from '../../../../../service/interne/suivit/SuivitIntranetService';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';

@Component({
  selector: 'app-pagination-content-suivi-intranet',
  templateUrl: './pagination-content-suivi-intranet.component.html',
  styleUrls: ['./pagination-content-suivi-intranet.component.scss'],
  providers: [ SuivitIntranetService ]
})
export class PaginationContentSuiviIntranetComponent implements OnInit {

  @Input()
  suivis: Array<any>;
  constructor(public formatIntranetShareService: FormatIntranetShareService) { }

  ngOnInit() {
    this.suivis.forEach( suivi => {
      suivi.dateSuivi = new Date(suivi.dateSuivi);
    });
  }

}
