import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';



@Injectable()
export class EvenementService {
    private evenementUrl = Environement.BASE_API_URL + '/api/evenements';
    constructor(private http: HttpClient) { }

  addParticipant(idEvent, idParticipant){
    const headers = this.initOptionHeader();

    return this.http.get(Environement.BASE_API_URL + '/evenement/participant/add/' + idEvent + '/' + idParticipant, {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  deleteParticipant(idEvent, idParticipant){
    const headers = this.initOptionHeader();

    return this.http.get(Environement.BASE_API_URL + '/evenement/participant/delete/' + idEvent + '/' + idParticipant, {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addEvenement(nom, dateDebut, dateFin, idAuteur, type, idSalle, eventNom, fullDay, idClient): Promise<any>{
    // le datetimepicker affiche les dates en ajoutant le fuseau horaire mais l'api elle prend des dates déja formatter au fuseau horaire
    const DateDebut = new Date(dateDebut.getTime() + - 60 * ( dateDebut.getTimezoneOffset()) * 1000);
    const DateFin = new Date(dateFin.getTime() + - 60 * ( dateFin.getTimezoneOffset()) * 1000);
    const headers = this.initOptionHeader();
    if(idSalle != null) {
      idSalle = '/api/salles/' + idSalle;
    }
    if (idClient != null) {
      idClient = '/api/clients/' + idClient;
    }
    return this.http.post(this.evenementUrl, {'nom': nom, 'dateDebut': DateDebut, 'dateFin': DateFin,
      'auteur': 'api/auth_users/' + idAuteur, 'type': type , 'salle': idSalle, 'eventNom': eventNom , 'fullDay': fullDay, 'client': idClient},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  deleteEvent(id): Promise<any>{
    const headers = this.initOptionHeader();
    return this.http.delete(this.evenementUrl + '/' + id,
      { headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateEventModif(id, nom, dateDebut, dateFin, idAuteur, type, idSalle, eventNom, fullDay, idClient) {
    // le datetimepicker affiche les dates en ajoutant le fuseau horaire mais l'api elle prend des dates déja formatter au fuseau horaire
    const DateDebut = new Date(dateDebut.getTime() + - 60 * ( dateDebut.getTimezoneOffset()) * 1000);
    const DateFin = new Date(dateFin.getTime() + - 60 * ( dateFin.getTimezoneOffset()) * 1000);
    const headers = this.initOptionHeader();
    console.log(idClient);
    if (idSalle != null) {
      idSalle = '/api/salles/' + idSalle;
    }
    if (idClient != null) {
      idClient = '/api/clients/' + idClient;
    }
    return this.http.put(this.evenementUrl + '/' + id,
      {'nom': nom, 'dateDebut': DateDebut, 'dateFin':DateFin,
        'auteur': 'api/auth_users/' + idAuteur, 'type': type , 'salle': idSalle, 'eventNom': eventNom , 'fullDay': fullDay , 'client': idClient},
      { headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  updateEvent(event, dragdrop = false ) {
    let dateDebutplus = new Date();
    let dateFinplus = new Date();
    if(dragdrop === true){
      dateDebutplus = new Date(event.start.getTime() + - 60 * ( event.start.getTimezoneOffset()) * 1000);
      dateFinplus = new Date(event.end.getTime() + - 60 * ( event.end.getTimezoneOffset()) * 1000);
    } else {
      dateDebutplus = event.start;
      dateFinplus = event.end;
    }
      const headers = this.initOptionHeader();
      return this.http.put(this.evenementUrl + '/' + event.meta.id,
        {'dateDebut': dateDebutplus, 'dateFin': dateFinplus}, { headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getEventFerie(): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.evenementUrl + '?type=jour_ferie', {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getEventId(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.evenementUrl + '/' + id , {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getEventConge(): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.evenementUrl + '?type=Conge', {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getEventEvent(salle, client, evenement, idClient = 0): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/evenement/bytype/' + salle + '/' + client + '/' + evenement  + '/' + idClient,
      {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getEventUser(id){
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/evenement/user/' + id , {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

    private errorHandler(err: any): Promise<any> {
        return Promise.reject(err.message || err );
    }

    private initOptionHeader() {
        const token: any = localStorage.getItem('auth-tokens');
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.set('X-Auth-Token', token);
        return headers;
    }
}
