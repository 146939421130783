import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';
@Injectable()
export class FonctionService {
  private fonctionURL = Environement.BASE_API_URL + '/api/fonctions';
  private fonctionIntranetURL = Environement.BASE_API_URL + '/contact/intranet/';
  constructor(private http: HttpClient) {
  }
  getFonctions() {
    const headers = this.initOptionHeader();
    return this.http.get(this.fonctionURL, {headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getFonctionDetail(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.fonctionURL + '/' + id , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getFonctionsEscalnetbyAncienID(ancienID) {
    const headers = this.initOptionHeader();
    return this.http.get(this.fonctionURL + '?several_all=' + ancienID, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getFonctionsIntranet(recherche) {
    const headers = this.initOptionHeader();
    return this.http.get(this.fonctionIntranetURL + 'fonction/' + recherche, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getFonctionIntranetbyID(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.fonctionIntranetURL + 'fonction/' + id, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addFonction(fonction, maj = null) {
    const headers = this.initOptionHeader();
    if (maj === null) {
      return this.http.post(this.fonctionURL,
        {libelle: fonction.libelle, ancienId: parseInt(fonction.ancienId, 10)}, {headers}).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    } else {
      return this.http.post(this.fonctionURL,
        {libelle: fonction.libelle, ancienId: parseInt(fonction.ancienId, 10)}, {headers}).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
