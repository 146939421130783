import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';

@Injectable()
export class ElementVeilleService{
  private elementVeilleUrl = Environement.BASE_API_URL + '/api/element_veilles';
  constructor(private http: HttpClient) { }

  addElementVeille(idVeilleThematique, titre, liens): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post(this.elementVeilleUrl , { 'veilleThematique': '/api/veille_thematiques/' + idVeilleThematique ,
      'liens': liens, 'titre': titre },{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  removeElementVeille(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.delete(this.elementVeilleUrl + '/' + id,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  };

  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
