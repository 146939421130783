import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';



@Injectable()
export class SalleService{
    private salleUrl = Environement.BASE_API_URL + '/api/salles';
    constructor(private http: HttpClient) { }

  getSalle() {
    const headers = this.initOptionHeader();
        return this.http.get(this.salleUrl , {headers: headers} ).toPromise()
            .then(response => response)
            .catch(this.errorHandler);
    }
  addSalle(nom, couleurFond, couleurBord){
    const headers = this.initOptionHeader();
    return this.http.post(this.salleUrl , {'nom': nom , 'couleurCalendrierFond': couleurFond, 'couleurCalendrierBord': couleurBord},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

    private errorHandler(err: any): Promise<any> {
        return Promise.reject(err.message || err );
    }
    private initOptionHeader(){
        const token: any = localStorage.getItem('auth-tokens');
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.set('X-Auth-Token', token);
        return headers;
    }
}
