import { Component, OnInit } from '@angular/core';


import {DialogueShareService} from '../../../service/partage/utilitaire/DialogueShareService';
import {SuggestionService} from '../../../service/interne/utilitaire/suggestion/SuggestionService';
import {Suggestion} from '../../../model/utilitaire/suggestion/Suggestion';
import {CategorieSuggestion} from '../../../model/utilitaire/suggestion/CategorieSuggestion';
import {AuthShareService} from '../../../service/partage/AuthShareService';
import {Statut} from '../../../model/utilitaire/suggestion/Statut';
import {CategoriesSuggestionService} from '../../../service/interne/utilitaire/suggestion/CategoriesSuggestionService';

@Component({
  selector: 'app-bugs',
  templateUrl: './bugs.component.html',
  styleUrls: ['./bugs.component.scss'],
  providers: [ SuggestionService, CategoriesSuggestionService ]
})
export class BugsComponent implements OnInit {

  suggestion: Suggestion;
  categoriesSuggestion: Array<CategorieSuggestion>;
  statuts: Array<Statut>;
  categId: number;
  suggestionId: number;
  envoyer: boolean;

  constructor(private suggestionService: SuggestionService, public dialogueShareService: DialogueShareService,
              private authShareService: AuthShareService, private categoriesSuggestionService: CategoriesSuggestionService) { }

  ngOnInit() {
    this.categId = 0;
    this.suggestionId = 0;
    this.envoyer = false;
    this.suggestion = new Suggestion(0, '', '', '', null, null, new Date(), this.authShareService.authUser);
    this.categoriesSuggestionService.getCategoriesSuggestion()
      .then((categs) => {
        this.categoriesSuggestion = categs['hydra:member'] as Array<CategorieSuggestion>;
      });
  }

  envoyerSuggestion() {
    // si informations obligatoires non renseignées
    if (this.suggestion.cible != '') {
      if (this.categId != 0) {
        if (this.suggestion.description != '') {
          // priorité définit selon la catégorie
          if (this.categId == 1) {
            this.suggestion.priorite = '3';
          } else if (this.categId == 2) {
            this.suggestion.priorite = '1';
          } else if (this.categId == 3) {
            this.suggestion.priorite = '2';
          } else if (this.categId == 4) {
            this.suggestion.priorite = '4';
          } else {
            this.suggestion.priorite = '2';
          }
          // enregistrer la suggestion
          this.suggestionService.addSuggestion(this.suggestion, this.categId)
            .then(suggestion => {
              this.suggestionId = suggestion.id;
              this.suggestionService.demandeEnvoyeeMail(this.suggestionId).then(() => {
                this.dialogueShareService.addDialogue('La suggestion a bien été envoyée');
                this.envoyer = true;
              });
            });
        } else {
          this.dialogueShareService.addDialogue('La description est obligatoire');
        }
      } else {
        this.dialogueShareService.addDialogue('La catégorie est obligatoire');
      }
    } else {
      this.dialogueShareService.addDialogue('L\'objet est obligatoire');
    }
  }

  redirect() {
    this.ngOnInit();
  }

  /*
  submitAnomalie() {
    const that = this;
    if(this.anomalie.cible !== '' && this.anomalie.description !== ''){
      this.anomalieService.postAnomalie(that.anomalie)
        .then((retour) => {
          that.dialogueShareService.addDialogue('le bug a bien été remonté');
          that.ngOnInit();
        });
    } else {
      that.dialogueShareService.addDialogue('la description et la cible doivent être remplis');
    }

  }
  categorieChange(){
    const that = this;
    this.categoriesAnomalie.forEach((categorie) => {
      if (categorie.id === that.selectedCatVal) {
        that.anomalie.categorie = categorie;
      }
    });
  }*/
}
