import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-escal-suivi-gestion-media',
  templateUrl: './escal-suivi-gestion-media.component.html',
  styleUrls: ['./escal-suivi-gestion-media.component.scss']
})
export class EscalSuiviGestionMediaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
