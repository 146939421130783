import {Logo} from '../utilitaire/Logo';
import {RoleUserClient} from './RoleUserClient';
import {ClientPersonne} from './ClientPersonne';
import {Documents} from './Documents';
import {MotsCles} from './motsCles/MotsCles';
import {Etat} from '../information/Etat';

export class Client {
    id: number;
    nom: string;
    actuel: boolean;
    dirigeant: string;
    logo: Logo;
    rolesUserClient: Array<RoleUserClient>;
    adresseGenerique: string;
    adresseCoupure: string;
    ancienId: number;
    personnes: Array<ClientPersonne>;
    documents: Array<Documents>;
    motsCles: Array<MotsCles>;
    dateEntree: Date;
    dateSortie: Date;
    duree: number;
    score: string;
    etat: Etat;


    constructor(id: number, nom: string, actuel: boolean, dirigeant: string, logo: Logo, rolesUserClient: Array<RoleUserClient>,
                adresseGenerique: string, adresseCoupure: string, ancienId: number,
                personnes: Array<ClientPersonne>, motsCles: Array<MotsCles>, dateEntree: Date,
                dateSortie: Date, duree: number, score: string, documents: Array<Documents>, etat: Etat) {
        this.id = id;
        this.nom = nom;
        this.actuel = actuel;
        this.dirigeant = dirigeant;
        this.logo = logo;
        this.rolesUserClient = rolesUserClient;
        this.adresseGenerique = adresseGenerique;
        this.adresseCoupure = adresseCoupure;
        this.ancienId = ancienId;
        this.personnes = personnes;
        this.documents = documents;
        this.motsCles = motsCles;
        this.dateEntree = dateEntree;
        this.dateSortie = dateSortie;
        this.duree = duree;
        this.score = score;
        this.etat = etat;
    }
}
