import { Component, OnInit } from '@angular/core';
import {StatistiqueIntranet} from '../../../../service/interne/utilitaire/statistique/statistiqueIntranet';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-statistique-mutualisation',
  templateUrl: './statistique-mutualisation.component.html',
  styleUrls: ['./statistique-mutualisation.component.scss'],
  providers: [ StatistiqueIntranet ]
})
export class StatistiqueMutualisationComponent implements OnInit {

  startMoment: Date;
  dateDebut: Date;
  dateFin: Date;
  constructor(private statistiqueIntranet: StatistiqueIntranet, private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  getStat() {
    const monthDeb = this.dateDebut.getMonth() + 1;
    const monthFin = this.dateFin.getMonth() + 1;
    this.spinner.show();
    this.statistiqueIntranet.getStatistiqueMutualisations(this.dateDebut.getFullYear() + '-' + monthDeb + '-' + this.dateDebut.getDate() ,
      this.dateFin.getFullYear() + '-' + monthFin + '-' + this.dateFin.getDate())
      .then( (retour) => {
        this.spinner.hide();
      });
  }
}
