import {Contact} from '../contact/Contact';
import {Support} from '../support/Support';
import {Coordonnees} from './Coordonnees';

export class Pays {
  id: number;
  libelleFR: string;
  libelleEN: string;
  iso: string;
  contacts: Array<Contact>;
  supports: Array<Support>;
  coordonnees: Array<Coordonnees>;

  constructor(id: number, libelleFR: string, libelleEN: string, iso: string, contacts: Array<Contact>, supports: Array<Support>, coordonnees: Array<Coordonnees>) {
    this.id = id;
    this.libelleFR = libelleFR;
    this.libelleEN = libelleEN;
    this.iso = iso;
    this.contacts = contacts;
    this.supports = supports;
    this.coordonnees = coordonnees;
  }
}
