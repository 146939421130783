import {AuthUser} from '../user/authUser';
import {TypeListe} from './TypeListe';
import {ListeContactContact} from './ListeContactContact';


export class ListeContact {
  id: number;
  nom: string;
  commentaires: string;
  creation: Date;
  modification: Date;
  auteur: AuthUser;
  typeListe: TypeListe;
  listeContactContact: Array<ListeContactContact>;
  ancienId: number;

  constructor(id: number, nom: string, commentaires: string, creation: Date, modification: Date, auteur: AuthUser, typeListe: TypeListe,
              listeContactContact: Array<ListeContactContact>, ancienId: number) {
    this.id = id;
    this.nom = nom;
    this.commentaires = commentaires;
    this.creation = creation;
    this.modification = modification;
    this.auteur = auteur;
    this.typeListe = typeListe;
    this.listeContactContact = listeContactContact;
    this.ancienId = ancienId;
  }
}
