import {Component, Input, OnInit} from '@angular/core';

import {Client} from '../../../../../model/client/Client';

@Component({
  selector: 'app-pagination-content-client',
  templateUrl: './pagination-content-client.component.html',
  styleUrls: ['./pagination-content-client.component.scss']
})
export class PaginationContentClientComponent implements OnInit {

  @Input()
  clients: Array<Client>;
  constructor() { }

  ngOnInit() {
  }

}
