import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact} from '../../../../../model/contact/Contact';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';

@Component({
  selector: 'app-escal-contact-fiche-evenements',
  templateUrl: './escal-contact-fiche-evenements.component.html',
  styleUrls: ['./escal-contact-fiche-evenements.component.scss']
})
export class EscalContactFicheEvenementsComponent implements OnInit {

  @Input()
  contact: Contact;
  @Output()
  refresh = new EventEmitter();
  constructor(public formatIntranetShareService: FormatIntranetShareService) { }

  ngOnInit() {
  }

}
