export class ImagePressroom {
    id: number;
    nom: string;
    type: string;



    constructor(id: number, nom: string, type: string) {
        this.id = id;
        this.nom = nom;
        this.type = type;
    }
}
