export class PartieCp {
  id: number;
  partie: string;
  contenu: string;



  constructor( id: number, partie: string, contenu: string) {
    this.id = id;
    this.partie = partie;
    this.contenu = contenu;
  }
}
