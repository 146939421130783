import {Suivi} from './Suivi';
import {NatureSuivi} from './NatureSuivi';
import {Client} from '../client/Client';

export class SuiviNature {
  id: number;
  suivi: Suivi;
  nature: NatureSuivi;
  axe: string;
  interesse: string;
  sujet: string;
  opportunitesClient: Client;
  themeItw: string;
  interlocuteur: string;
  raison: string;
  commentaire: string;
  dateItw: Date;

  constructor(id: number, suivi: Suivi, nature: NatureSuivi, axe: string, interesse: string, sujet: string, opportunitesClient: Client, themeItw: string,
              interlocuteur: string, raison: string, commentaire: string, dateItw: Date) {
    this.id = id;
    this.suivi = suivi;
    this.nature = nature;
    this.axe = axe;
    this.interesse = interesse;
    this.sujet = sujet;
    this.opportunitesClient = opportunitesClient;
    this.themeItw = themeItw;
    this.interlocuteur = interlocuteur;
    this.raison = raison;
    this.commentaire = commentaire;
    this.dateItw = dateItw;
  }
}
