import {Component, OnInit, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';

import {MenuShareService} from '../../../../service/partage/utilitaire/MenuShareService';
import {MenuService} from '../../../../service/interne/utilitaire/Menu.service';
import {AuthShareService} from '../../../../service/partage/AuthShareService';

@Component({
  selector: 'app-structure-menu-gauche',
  templateUrl: './structure-menu-gauche.component.html',
  styleUrls: ['./structure-menu-gauche.component.scss'],
  providers: [ MenuService ]
})

export class StructureMenuGaucheComponent implements OnInit {

  constructor( private menuShareService: MenuShareService, private menuService: MenuService, private router: Router,
               public authShareService: AuthShareService) {

  }

  ngOnChanges(changes: SimpleChanges) {
    const curentValueSplit = changes.urlActive.currentValue.split('/');
    this.menuShareService.switchActive = curentValueSplit[1];
    this.menuShareService.sousMenuActive = curentValueSplit[2];
    this.menuShareService.linkActive = curentValueSplit[3];
  }
  ngOnInit() {
    const curentRouteSplit = this.router.url.split('/');
    this.menuShareService.switchActive = curentRouteSplit[1];
    this.menuShareService.authShareService = this.authShareService;
    this.menuShareService.sousMenuActifActuel = curentRouteSplit[2];
    this.menuShareService.linkActive = curentRouteSplit[3];
    this.menuShareService.initMenu(this.menuService);
  }

}
