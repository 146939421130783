import {TitreMenu} from './titreMenu';

export class SousMenu {
  id: string;
  icone: string;
  TitreMenu: Array<TitreMenu>;
  nom: string;
  path: string;
  role:string;

  constructor(id: string, icone: string, TitreMenu: Array<TitreMenu>, nom: string, path: string,role:string = 'all') {
    this.id = id;
    this.icone = icone;
    this.TitreMenu = TitreMenu;
    this.nom = nom;
    this.path = path;
    this.role = role;
  }
}
