import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Organisation} from '../../../../../model/organisation/Organisation';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';

@Component({
  selector: 'app-escal-organisation-fiche-salles',
  templateUrl: './escal-organisation-fiche-salles.component.html',
  styleUrls: ['./escal-organisation-fiche-salles.component.scss']
})
export class EscalOrganisationFicheSallesComponent implements OnInit {

  @Input()
  organisation: Organisation;
  @Output()
  refresh = new EventEmitter();
  constructor(public formatIntranetShareService: FormatIntranetShareService) { }

  ngOnInit() {
  }

}
