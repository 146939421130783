import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Client} from '../../../../../model/client/Client';
import {CoupureService} from '../../../../../service/interne/coupure/CoupureService';
import {ClientPersonne} from '../../../../../model/client/ClientPersonne';
@Component({
  selector: 'app-escal-client-fiche-contacts',
  templateUrl: './escal-client-fiche-contacts.component.html',
  styleUrls: ['./escal-client-fiche-contacts.component.scss'],
  providers: [CoupureService]
})
export class EscalClientFicheContactsComponent implements OnInit {
  @Input()
  client: Client;
  contactsClient: Array<ClientPersonne>;
  contact: ClientPersonne;
  contacts: Array<any> = [];
  @Output()
  refresh = new EventEmitter();
  constructor(private coupureService: CoupureService) { }

  ngOnInit() {
    let trouve = false;
    this.contactsClient = [];
    this.coupureService.getContactsClient(this.client.ancienId)
      .then((allcontacts) => {
        this.contacts = allcontacts ;
        this.coupureService.getContactsClientCoupures(this.client.ancienId)
          .then((contactsenvoicoupures) => {
            for (let i = 0; i < this.contacts.length; i++) {
              trouve = false;
              contactsenvoicoupures.forEach(element => {
                if (this.contacts[i]['nom'] === element.nom) {
                  trouve = true ;
                  this.contact = new ClientPersonne(0, element.nom, element.prenom, element.ancienId, element.email);
                }
              });
              if (trouve === false){
                this.contact = new ClientPersonne(0, this.contacts[i]['nom'], this.contacts[i]['prenom'], this.contacts[i]['ID'], '');
              }
              this.contactsClient.push(this.contact);
            }
          });
      });
  }
  refreshAction() {
    this.refresh.emit();
  }
}
