import {AuthUser} from '../user/authUser';
import {Client} from '../client/Client';
import {FormeJuridique} from './FormeJuridique';
import {CategoriesGenerales} from '../information/CategoriesGenerales';
import {Coordonnees} from '../information/Coordonnees';
import {Support} from '../support/Support';
import {Emission} from '../support/Emission';
import {Contact} from '../contact/Contact';
import {CodeApe} from './CodeApe';
import {Genre} from '../contact/Genre';
import {ContactOrganisation} from '../contact/ContactOrganisation';

export class Organisation {
  id: number;
  ancienId: number;
  nom: string;
  dateCreation: Date;
  ape: CodeApe;
  siren: string;
  capital: string;
  effectif: string;
  implantation: string;
  commentaires: string;
  auteur: AuthUser;
  creation: Date;
  modification: Date;
  appartenirA: Organisation;
  client: Client;
  formeJuridique: FormeJuridique;
  genre: Genre;
  categorieGenerale: CategoriesGenerales;
  coordonnees: Coordonnees;
  supports: Array<Support>;
  emissions: Array<Emission>;
  dirigeant: Contact;
  contactsOrganisation: Array<ContactOrganisation>;

  constructor(id: number, ancienId: number, nom: string, dateCreation: Date, codeApe: CodeApe, siren: string, capital: string, effectif: string, implantation: string,
              commentaires: string, auteur: AuthUser, creation: Date, modification: Date, appartenirA: Organisation, client: Client,
              formeJuridique: FormeJuridique, genre: Genre, categorieGenerale: CategoriesGenerales, coordonnees: Coordonnees,
              supports: Array<Support>, emissions: Array<Emission>, dirigeant: Contact, contactsOrganisation: Array<ContactOrganisation>) {
    this.id = id;
    this.ancienId = ancienId;
    this.nom = nom;
    this.dateCreation = dateCreation;
    this.ape = codeApe;
    this.siren = siren;
    this.capital = capital;
    this.effectif = effectif;
    this.implantation = implantation;
    this.auteur = auteur;
    this.creation = creation;
    this.modification = modification;
    this.appartenirA = appartenirA;
    this.client = client;
    this.formeJuridique = formeJuridique;
    this.genre = genre;
    this.categorieGenerale = categorieGenerale;
    this.coordonnees = coordonnees;
    this.supports = supports;
    this.emissions = emissions;
    this.dirigeant = dirigeant;
    this.contactsOrganisation = contactsOrganisation;
    this.commentaires = commentaires;
  }
}
