import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../../environement/environement';


@Injectable()
export class ArticleService{
    constructor(private http: HttpClient) { }

  getAllArticlePressroom(idPressroom){
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/communique/pressroom/articles/' + idPressroom,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  loadArticlePressroom(idPressroom){
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/load/article/pressroom/' + idPressroom,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  };
  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
