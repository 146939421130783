import { Injectable } from '@angular/core';



import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';


@Injectable()
export class UserService{
  private userUrl = Environement.BASE_API_URL + '/api/users';
  constructor(private http: HttpClient) { }

  getUserOldLogin(): Promise<any>{
    let headers = this.initOptionHeader();
    return this.http.get(this.userUrl, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateCivilite(idUser, idCivilite): Promise<any>{
    let headers = this.initOptionHeader();
    return this.http.put(this.userUrl + '/' + idUser, {'civilite': '/api/civilites/' + idCivilite },{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateInfo(idUser, nom, prenom): Promise<any>{
    let headers = this.initOptionHeader();
    return this.http.put(this.userUrl + '/' + idUser, {'nom': nom, 'prenom': prenom },{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateTelExterne(idAuthUser, tel): Promise<any>{
    let headers = this.initOptionHeader();
    return this.http.put(this.userUrl + '/' + idAuthUser, {'tel': tel}, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addUser(nom, prenom, email, idCivilite, telExterne): Promise<any>{
    let headers = this.initOptionHeader();
    return this.http.post(this.userUrl, {'nom': nom, 'prenom': prenom, 'email': email, 'civilite': 'api/civilites/' + idCivilite,
      'tel': telExterne, 'respConge': false }, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  GetUsersRespConge() {
    const headers = this.initOptionHeader();
    return this.http.get(this.userUrl + '?respConge=1', {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
