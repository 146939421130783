import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';
import {Contact} from '../../../model/contact/Contact';

@Injectable()
export class PaysService {
  contact: Contact;
  private paysURL = Environement.BASE_API_URL + '/api/pays';
  constructor(private http: HttpClient) {
  }
  getPays() {
    const headers = this.initOptionHeader();
    return this.http.get(this.paysURL, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getPaysDetail(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.paysURL + '/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }

  getAllPays() {
    const headers = this.initOptionHeader();
    return this.http.get(this.paysURL + '?order[libelleFR]=asc', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
}