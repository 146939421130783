import { Component, OnInit } from '@angular/core';
import {JourFerieService} from '../../../service/interne/calendrier/JourFerieService';
import {DialogueShareService} from '../../../service/partage/utilitaire/DialogueShareService';

@Component({
  selector: 'app-update-jour-ferie',
  templateUrl: './update-jour-ferie.component.html',
  styleUrls: ['./update-jour-ferie.component.scss'],
  providers: [ JourFerieService ]
})
export class UpdateJourFerieComponent implements OnInit {

  annee: any;

  constructor(private jourFerieService: JourFerieService, private dialogueShareService: DialogueShareService) { }

  ngOnInit() {
  }

  updateJourFerierAnnee() {
    if ( !isNaN(this.annee)) {
      if ( this.annee >= 2017) {
        this.jourFerieService.updateJourFerierApi(this.annee)
          .then((retour) => {
            this.dialogueShareService.addDialogue('Les jours fériés de ' + this.annee + ' ont été mis à jour');
          });
      } else {
        this.dialogueShareService.addDialogue('Merci de rentrer une année à partir de 2017');
      }
    } else {
      this.dialogueShareService.addDialogue('Merci de rentrer une année. Exemple : 2019');
    }
  }
}
