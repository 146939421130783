import {Component, OnInit} from '@angular/core';
import {PressroomService} from '../../../../../service/interne/client/pressroom/PressroomService';
import {NgxSpinnerService} from 'ngx-spinner';
import {PressroomShareService} from '../../../../../service/partage/PressroomShareService';

@Component({
  selector: 'app-pressroom-client-create',
  templateUrl: './pressroom-client-create.component.html',
  styleUrls: ['./pressroom-client-create.component.scss'],
  providers: [ PressroomService ]
})
export class PressroomClientCreateComponent implements OnInit {


  constructor(public pressroomShareService: PressroomShareService, private pressroomService: PressroomService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  savePressroom(){
    const that = this;
    this.spinner.show();
    this.pressroomService.addPressroom(this.pressroomShareService.pressroom)
      .then( (retour) => {
        this.spinner.hide();
        that.pressroomShareService.initPressroom();
      });
  }

}
