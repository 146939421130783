import { SousMenu } from './Sous-menu';

export class Menu {
  id: string;
  SousMenus: Array<SousMenu>;


  constructor(id: string, SousMenus: Array<SousMenu>) {
    this.id = id;
    this.SousMenus = SousMenus;
  }
}
