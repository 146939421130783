import {Component, Input, OnInit} from '@angular/core';
import {Environement} from '../../../../../environement/environement';
import {PieceJointe} from '../../../../../model/mailling/PieceJointe';

@Component({
  selector: 'app-escal-mailling-view-piece-jointes',
  templateUrl: './escal-mailling-view-piece-jointes.component.html',
  styleUrls: ['./escal-mailling-view-piece-jointes.component.scss']
})
export class EscalMaillingViewPieceJointesComponent implements OnInit {

  @Input()
  pieceJointe: PieceJointe;
  @Input()
  id: string;

  hrefValue: string;

  constructor() { }

  ngOnInit() {
    return Environement.BASE_API_URL + '/logo/coupurepieceJointe/' + this.pieceJointe.url
  }

}
