import { Component, OnInit } from '@angular/core';
import {ModalCalendrierShareService} from '../../../service/partage/ModalCalendrierShareService';
import {AuthUser} from '../../../model/user/authUser';
import {AuthUserService} from '../../../service/interne/user/AuthUserService';
import {AuthUserGroupeUser} from '../../../model/user/AuthUserGroupeUser';
import {EvenementService} from '../../../service/interne/calendrier/EvenementService';

@Component({
  selector: 'app-modal-calendrier-participant',
  templateUrl: './modal-calendrier-participant.component.html',
  styleUrls: ['./modal-calendrier-participant.component.scss'],
  providers: [ AuthUserService, EvenementService ]
})
export class ModalCalendrierParticipantComponent implements OnInit {

  ouvrirAddVal: boolean;
  userSelectId;
  users: Array<AuthUser>;
  usersSelect: Array<AuthUser>;

  constructor(public modalCalendrierShareService: ModalCalendrierShareService, private authUserService: AuthUserService,
              private evenementService: EvenementService) { }

  ngOnInit() {
    const that = this;
    that.authUserService.getUsersList(1)
      .then((retour1) => {
        that.users = retour1['hydra:member'] as Array<AuthUser>;
        that.initClientSelect();
      });
  }

  initClientSelect() {
    this.usersSelect = [];
    this.users.forEach((authUserActuel: AuthUser) => {
      let present = 0;
      this.modalCalendrierShareService.event.meta.participants.forEach((participant: AuthUser) => {
        if (participant.id === authUserActuel.id) {
          present = 1;
        }
      });
      if (present === 0) {
        this.usersSelect.push(authUserActuel);
      }
    });
  }

  checkLastElement(index) {
    const nbELement = this.modalCalendrierShareService.event.meta.participants.length;
    if (nbELement % 2 === 1) {
      if (index === nbELement - 1) {
        return 'lastLiElement lastLiElementLine';
      }
    } else {
      if (index === nbELement - 2 || index === nbELement - 1) {
        return 'lastLiElement';
      }
    }
  }


  deleteParticipant(id){
    const that = this;
    console.log(this.userSelectId);
    that.evenementService.deleteParticipant(this.modalCalendrierShareService.event.meta.id, id).then((retour) => {
      that.evenementService.getEventId(this.modalCalendrierShareService.event.meta.id)
        .then((retour) => {
          this.modalCalendrierShareService.event.meta = retour;
          that.ngOnInit();
        });

    });
  }
  ajouterParticipant(){
    const that = this;
    console.log(this.userSelectId);
    that.evenementService.addParticipant(this.modalCalendrierShareService.event.meta.id, this.userSelectId).then((retour) => {
      that.evenementService.getEventId(this.modalCalendrierShareService.event.meta.id)
        .then((retour) => {
          this.modalCalendrierShareService.event.meta = retour;
          that.ngOnInit();
        });

    });
  }
}
