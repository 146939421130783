import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact} from '../../../../../model/contact/Contact';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';

@Component({
  selector: 'app-escal-contact-fiche-rappels',
  templateUrl: './escal-contact-fiche-rappels.component.html',
  styleUrls: ['./escal-contact-fiche-rappels.component.scss']
})
export class EscalContactFicheRappelsComponent implements OnInit {
  @Input()
  contact: Contact;
  @Output()
  refresh = new EventEmitter();
  constructor(public formatIntranetShareService: FormatIntranetShareService) { }

  ngOnInit() {
  }

}
