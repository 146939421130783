import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';
import {PartieCp} from '../../../../../model/mailling/PartieCp';
import {PartieCpService} from '../../../../../service/interne/mailling/partieCpService';

@Component({
  selector: 'app-escal-mailling-view-partie',
  templateUrl: './escal-mailling-view-partie.component.html',
  styleUrls: ['./escal-mailling-view-partie.component.scss'],
  providers: [PartieCpService]
})
export class EscalMaillingViewPartieComponent implements OnInit {

  @Input()
  partieCp: PartieCp;
  @Input()
  idMailling: number;
  @Output() deletePartie = new EventEmitter<Array<any>>();
  wait: boolean = false;

  constructor(private partieCpService: PartieCpService, private dialogueShareService: DialogueShareService) { }

  ngOnInit() {
  }

  updatePartieCp(){
    const that = this;
    if (this.partieCp.partie.length > 3) {
      if (this.partieCp.id === 0) {
        if (this.wait === false){
          this.wait = true;
          this.partieCpService.postPartieCp(this.idMailling, this.partieCp)
            .then((retour) => {
              const partieCpRetour = retour as PartieCp;
              that.partieCp.id = partieCpRetour.id;
              that.wait = false;
              console.log(this.partieCp);
            });
        }
      } else{
        this.partieCpService.putPartieCp(this.partieCp)
          .then((retour) => {
          });
      }
    }
  }
  removeELem(){
    if(this.partieCp.id !== 0){
      const that = this;
      const variable = {
        'partieCpService': this.partieCpService, 'dialogueShareService': this.dialogueShareService, 'id': this.partieCp.id,
        'deletePartie': this.deletePartie
      };
      this.dialogueShareService.addDialogue('êtes vous sûre de vouloir delete la partie?', function () {
        variable['partieCpService'].deletePartieCp(variable['id'])
          .then((retour) => {
            variable['deletePartie'].emit();
          });
      });
    } else {
      this.deletePartie.emit();
    }
  }
}
