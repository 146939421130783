import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';


@Injectable()
export class AuthTokenService{
  private authTokenUrl = Environement.BASE_API_URL + '/auth-tokens';
  constructor(private http: HttpClient) { }

  getAuthToken(login, password): Promise<any>{
    return this.http.post(this.authTokenUrl , {login, password}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getUserByToken(): Promise<any>{
    const headers = this.initOptionHeader();
    return this.http.get(this.authTokenUrl, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader(): HttpHeaders {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
