import {Suivi} from '../suivi/Suivi';

export class EvenementMetier {
  id: number;
  nom: string;
  ancienId: number;
  suivis: Array<Suivi>;

  constructor(id: number, nom: string, ancienId: number, suivis: Array<Suivi>) {
    this.id = id;
    this.nom = nom;
    this.ancienId = ancienId;
    this.suivis = suivis;
  }
}
