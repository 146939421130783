import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';
import {Contact} from '../../../model/contact/Contact';


@Injectable()
export class OrganisationService {
  private organisationUrl = Environement.BASE_API_URL + '/api/organisations';
  constructor(private http: HttpClient) { }
getOrganisation() {
  const headers = this.initOptionHeader();
  return this.http.get(this.organisationUrl, {headers: headers}).toPromise()
    .then( response => response)
    .catch(this.errorHandler);
}

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
