import {Injectable} from '@angular/core';


import {DialogueShareService} from './utilitaire/DialogueShareService';
import {AuthTokenService} from '../interne/user/AuthTokenService';
import {AuthUser} from '../../model/user/authUser';
import {forEach} from '@angular/router/src/utils/collection';




@Injectable()
export class AuthShareService {

  authUser: AuthUser;
  public authTokenService: AuthTokenService;
  public dialogueShareService: DialogueShareService;


  constructor() {
  }

  public getColorClient(client) {
    let colorAffiche: any = false;
    this.authUser.rolesUserClient.forEach( roleUserClient => {
      if ( roleUserClient.client.id === client.id) {
        colorAffiche  = {
          primary: roleUserClient.color,
          secondary: roleUserClient.color,
        };
      }
    });
    if (colorAffiche === false) {
      colorAffiche  = {
        primary: '#0203844',
        secondary: '#0203844',
      };
    }
    return colorAffiche;
  }

  public isGrantedEvenementCalendrier(evenement) {
    let allow = false;
    this.authUser.roles.forEach(element => {
      if (element === 'ROLE_SUPER_ADMIN') {
        allow = true;
      }
      if (element === 'ROLE_ADMIN') {
        allow = true;
      }
    });
    // @ts-ignore
    if (allow === true) {
      return true;
    } else {
      Array.from(evenement.participants).forEach( participant => {
        // @ts-ignore
        if (participant.id === this.authUser.id ) {
          allow = true;
        }
      });
    }
    // @ts-ignore
    if (allow === true) {
      return true;
    }
    return false;
  }

  public isGranted(role) {
    const that = this;
    let grantedVal = false;
    this.authUser.roles.forEach(element => {
      if (role === element) {
        grantedVal = true;
      }
      if (that.granted(role, element)) {
        grantedVal =  true;
      }
      if (role === 'all') {
        grantedVal = true;
      }
    });
    return grantedVal;
  }

  public isRoleStat(role) {
    const that = this;
    let grantedVal = false;
    this.authUser.roles.forEach(element => {
      if (role === element) {
        grantedVal = true;
      }
    });
    return grantedVal;
  }


  private granted(role, element) {
    if (element === 'ROLE_SUPER_ADMIN') {
      return true;
    }
    if (element === 'ROLE_ADMIN' && role !== 'ROLE_SUPER_ADMIN') {
      return true;
    }
    if (element === 'ROLE_COMMUNICATION' && role !== 'ROLE_SUPER_ADMIN' && role !== 'ROLE_ADMIN') {
      return true;
    }
    return false;
  }

  public identification(login, password) {
    const that = this;
    this.authTokenService.getAuthToken(login, password)
      .then((retour) => {
        that.authUser = retour.authUser as AuthUser;
        localStorage.setItem('auth-tokens', retour.value);
      })
      .catch((retour) => {
        this.dialogueShareService.addDialogue('erreur d\'identification');
      });
  }
  getUserByAuthToken() {
    const that = this;
    this.authTokenService.getUserByToken()
      .then((retour) => {
        that.authUser = retour.authUser as AuthUser;
        that.authUser.rolesUserClient.sort((a, b) => {
          if (a.client.nom < b.client.nom) {
            return 0;
          } else {
            return 1;
          }
        });
      })
      .catch((retour) => {
      });
  }



}
