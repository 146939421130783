import { Component, OnInit } from '@angular/core';
import {Civilite} from '../../../../model/user/Civilite';
import {CiviliteService} from '../../../../service/interne/user/CiviliteService';
import {OrganisationService} from '../../../../service/interne/organisation/OrganisationService';
import {FonctionService} from '../../../../service/interne/contact/FonctionService';
import {Genre} from '../../../../model/contact/Genre';
import {GenreService} from '../../../../service/interne/genre/GenreService';
import {CategoriesGenerales} from '../../../../model/information/CategoriesGenerales';
import {Organisation} from '../../../../model/organisation/Organisation';
import {Support} from '../../../../model/support/Support';
import {Pays} from '../../../../model/information/Pays';
import {PaysService} from '../../../../service/interne/contact/PaysService';
import {CategGenShareService} from '../../../../service/partage/CategGenShareService';
import {SousCategoriesGenerales} from '../../../../model/information/SousCategoriesGenerales';
import {SousCategGenShareService} from '../../../../service/partage/SousCategGenShareService';
import {ListeContact} from '../../../../model/liste/ListeContact';
import {ListeContactService} from '../../../../service/interne/contact/ListeContactService';
import {AuthUser} from '../../../../model/user/authUser';
import {AuthUserService} from '../../../../service/interne/user/AuthUserService';
import {ContactService} from '../../../../service/interne/contact/ContactService';
import {Contact} from '../../../../model/contact/Contact';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {OpenWithPostShareService} from '../../../../service/partage/utilitaire/OpenWithPostShareService';

@Component({
  selector: 'app-escal-contact-recherche',
  templateUrl: './escal-contact-recherche.component.html',
  styleUrls: ['./escal-contact-recherche.component.scss'],
  providers: [CiviliteService, OrganisationService, GenreService, CategGenShareService, SousCategGenShareService,
    ListeContactService, AuthUserService, OpenWithPostShareService]
})
export class EscalContactRechercheComponent implements OnInit {
  rechercheEnCours: boolean;
  nom: string;
  prenom: string;
  civilites: Array<Civilite> = [];
  genres: Array<Genre> = [];
  civiliteId: number;
  genreId: number;
  champRechercheFonction = '';
  fonctionsIntranet: Array<any> = [];
  rechercheFonctionEncours = false;
  afficheFonction: boolean;
  ancienIDFoncSelec = 0;
  champRechercheSociete = '';
  afficheSociete: boolean;
  rechercheSocieteEncours = false;
  societeRechercheIntranet: Array<any> = [];
  listeSocieteChoix: Array<any> = [];
  listeSocieteASauvegarder: Array<Organisation> = [];
  listeSupportChoix: Array<any> = [];
  listeSupportASauvegarder: Array<Support> = [];
  societeSelectionner: Organisation;
  idSocietesSelectionnees: Array<any>;
  idSocietesASauvegarder: Array<any>;
  idSupportsSelectionnees: Array<any>;
  idSupportsASauvegarder: Array<any>;
  champRechercheSupport = '';
  rechercheEncoursSupport: boolean;
  afficheSupp: boolean;
  supportRechercheIntranet: Array<any> = [];
  supportSelectionner: Support;
  modeEnvoiPref = '';
  listePaysBaseCoordPerso: Array<Pays> = [];
  idCoordPersoPays: number;
  rueVoie: string;
  ville: string;
  complements: string;
  codePostal: string;
  boitePostale: string;
  premierTelPerso: string;
  deuxiemeTelPerso: string;
  emailPerso: string;
  champRechercheCategGen = '';
  champRechercheSousCategGen = '';
  categGenIntranet: Array<any> = [];
  listeCategGenChoix: Array<any> = [];
  rechercheCategGenEncours = false;
  idCategGenSelectionnees: Array<any>;
  idCategGenASauvegarder: Array<any>;
  categGenSelectionner: CategoriesGenerales;
  listeCategGenASauvegarder: Array<CategoriesGenerales> = [];
  sousCategGenIntranet: Array<any> = [];
  listeSousCategGenChoix: Array<any> = [];
  rechercheSousCategGenEncours = false;
  idSousCategGenSelectionnees: Array<any>;
  idSousCategGenASauvegarder: Array<any>;
  sousCategGenSelectionner: SousCategoriesGenerales;
  listeSousCategGenASauvegarder: Array<SousCategoriesGenerales> = [];
  champRechercheListeContact = '';
  idListeContactSelectionnees: Array<any>;
  idListeContactASauvegarder: Array<any>;
  listeListeContactASauvegarder: Array<ListeContact> = [];
  listeListeContactChoix: Array<any> = [];
  listeListeContactIntranet: Array<any> = [];
  listeContactSelectionner: ListeContact;
  rechercheListeContactEncours = false;
  jours: number;
  suiviPar: number;
  creeChoisi: string;
  curentPage = 1;
  users: Array<AuthUser> = [];
  listeContactTrouvees: Array<Contact> = [];
  total: number;
  maxPage: number;
  headerContent: Array<any> = [];
  constructor(private civiliteService: CiviliteService, private organisationService: OrganisationService,
              private fonctionService: FonctionService, private genreService: GenreService, private paysService: PaysService,
              private categGenShareService: CategGenShareService, private sousCategGenShareService: SousCategGenShareService,
              private listeContactService: ListeContactService, private authUserService: AuthUserService,
              private contactService: ContactService, private dialogueShareService: DialogueShareService,
              private openWithPostShareService: OpenWithPostShareService) {
  }

  ngOnInit() {
    const that = this;
    this.headerContent.push('isClient');
    this.headerContent.push('Nom');
    this.headerContent.push('Genre');
    this.headerContent.push('Fonction');
    this.headerContent.push('Supports');
    this.nom = '';
    this.prenom = '';
    this.rechercheEnCours = false;
    this.civiliteId = 0;
    this.genreId = 0;
    this.afficheFonction = false;
    this.afficheSupp = false;
    this.idCoordPersoPays = 0;
    this.rueVoie = '';
    this.ville = '';
    this.complements = '';
    this.codePostal = '';
    this.boitePostale = '';
    this.premierTelPerso = '';
    this.deuxiemeTelPerso = '';
    this.emailPerso = '';
    this.creeChoisi = '';
    this.jours = 0;
    this.suiviPar = 0;
    this.total = 0;
    this.civiliteService.getCivilites()
      .then((retour) => {
        that.civilites = retour['hydra:member'];
      });
    this.genreService.getGenres().then(retour => {
      this.genres = retour['hydra:member'] as Array<Genre>;
    });
    this.paysService.getPays().then(retour => {
      this.listePaysBaseCoordPerso = retour['hydra:member'] as Array<Pays>;
    });
    this.authUserService.getUsersList(1).then((usersRetour) => {
      this.users = usersRetour['hydra:member'] as Array<AuthUser>;
    });
  }

  extraitNombre(str) {
    return Number(str.replace(/\D/g, ''));
  }

  setSelected(selectElement, action, nature) {
    const groupe: Array<any> = [];
    let optionSelected;
    for (let i = 0; i < selectElement.options.length; i++) {
      if (selectElement.options[i].selected) {
        optionSelected = selectElement.options[i].value;
        // Le format de value est de [0: '1']
        if (optionSelected.indexOf('\'') !== -1) {
          // extraire le vrai id sauvegarder
          optionSelected = optionSelected.indexOf('\'');
          optionSelected = selectElement.options[i].value.slice(optionSelected);
          optionSelected = this.extraitNombre(optionSelected);
          optionSelected = optionSelected.toString();
        }
        groupe.push(optionSelected);
      }
    }
    if (action === 'ajout') {
      switch (nature) {
        case 'societe':
          this.idSocietesSelectionnees = groupe;
          break;
        case 'support':
          this.idSupportsSelectionnees = groupe;
          break;
        case 'categgen':
          this.idCategGenSelectionnees = groupe;
          break;
        case 'souscateggen':
          this.idSousCategGenSelectionnees = groupe;
          break;
        case 'liste':
          this.idListeContactSelectionnees = groupe;
          break;
      }
    } else if (action === 'sup') {
      switch (nature) {
        case 'societe':
          this.idSocietesASauvegarder = groupe;
          break;
        case 'support':
          this.idSupportsASauvegarder = groupe;
          break;
        case 'categgen':
          this.idCategGenASauvegarder = groupe;
          break;
        case 'souscateggen':
          this.idSousCategGenASauvegarder = groupe;
          break;
        case 'liste':
          this.idListeContactASauvegarder = groupe;
          break;
      }
    }
  }
  rechercheSociete() {
    this.afficheSociete = true;
    if (this.champRechercheSociete.length > 2 && !this.rechercheSocieteEncours) {
      this.rechercheSocieteEncours = true;
      this.champRechercheSociete = this.champRechercheSociete.replace(' ', '.');
      this.organisationService.getSocieteRecherche(this.champRechercheSociete).then((retour) => {
        this.societeRechercheIntranet = retour;
        this.listeSocieteChoix = retour;
        // éviter les doublons dans la liste de selection en cas d'une nouvelle recherche
        if (this.listeSocieteASauvegarder.length > 0) {
          this.listeSocieteASauvegarder.forEach(societeASave => {
            this.listeSocieteChoix =
              this.listeSocieteChoix.filter(societeChoix => societeChoix.id !== societeASave.id);
          });
        }
        this.champRechercheSociete = this.champRechercheSociete.replace('.', ' ');
      });
      this.rechercheSocieteEncours = false;
    }
  }
  rechercheFonction() {
    this.afficheFonction = true;
    if (this.champRechercheFonction.length > 2 && !this.rechercheFonctionEncours) {
      this.rechercheFonctionEncours = true;
      this.champRechercheFonction = this.champRechercheFonction.replace(' ', '.');
      this.fonctionService.getFonctionsIntranet(this.champRechercheFonction).then((retour) => {
        this.fonctionsIntranet = retour;
        this.fonctionsIntranet.forEach(fonctionTrouve => {
          if (fonctionTrouve.libelle != '' && fonctionTrouve.libelle != null) {
            fonctionTrouve.libelle = fonctionTrouve.libelle.replace('&amp;', '&');
            fonctionTrouve.libelle = fonctionTrouve.libelle.replace('&#039;', '’');
            fonctionTrouve.libelle = fonctionTrouve.libelle.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheFonction = this.champRechercheFonction.replace('.', ' ');
      });
    }
    this.rechercheFonctionEncours = false;
  }
  rechercheSupport() {
    this.afficheSupp = true;
    if (this.champRechercheSupport.length > 2 && !this.rechercheEncoursSupport) {
      this.rechercheEncoursSupport = true;
      this.champRechercheSupport = this.champRechercheSupport.replace(' ', '.');
      this.organisationService.getSupportRecherche(this.champRechercheSupport).then((retour) => {
        retour.forEach(support => {
          if (support.nom != '' && support.nom != null) {
            support.nom = support.nom.replace('&amp;', '&');
            support.nom = support.nom.replace('&#039;', '’');
            support.nom = support.nom.replace('L&#039;', 'L’');
          }
        });
        this.supportRechercheIntranet = retour;
        this.listeSupportChoix = retour;
        // éviter les doublons dans la liste de selection en cas d'une nouvelle recherche
        if (this.listeSupportASauvegarder.length > 0) {
          this.listeSupportASauvegarder.forEach(supportASave => {
            this.listeSupportChoix =
              this.listeSupportChoix.filter(supportChoix => supportChoix.id !== supportASave.id);
          });
        }
        this.champRechercheSupport = this.champRechercheSupport.replace('.', ' ');
      });
      this.rechercheEncoursSupport = false;
    }
  }
  rechercheCategorieGenerale() {
    if (this.champRechercheCategGen.length > 2 && !this.rechercheCategGenEncours) {
      this.rechercheCategGenEncours = true;
      this.categGenShareService.getCategGenIntranet(this.champRechercheCategGen).then((retour) => {
        this.categGenIntranet = retour;
        this.listeCategGenChoix = retour;
        // éviter les doublons dans la liste de selection en cas d'une nouvelle recherche
        if (this.listeCategGenASauvegarder.length > 0) {
          this.listeCategGenASauvegarder.forEach(categGenASave => {
            this.listeCategGenChoix =
              this.listeCategGenChoix.filter(categGenChoix => categGenChoix.id !== categGenASave.id);
          });
        }
      });
    }
    this.rechercheCategGenEncours = false;
  }
  rechercheSousCategorieGenerale() {
    if (this.champRechercheSousCategGen.length > 2 && !this.rechercheSousCategGenEncours) {
      this.rechercheSousCategGenEncours = true;
      this.sousCategGenShareService.getSousCategGenIntranet(this.champRechercheSousCategGen).then((retour) => {
        this.sousCategGenIntranet = retour;
        this.listeSousCategGenChoix = retour;
        // éviter les doublons dans la liste de selection en cas d'une nouvelle recherche
        if (this.listeSousCategGenASauvegarder.length > 0) {
          this.listeSousCategGenASauvegarder.forEach(sousCategGenASave => {
            this.listeSousCategGenChoix =
              this.listeSousCategGenChoix.filter(sousCategGenChoix => sousCategGenChoix.id !== sousCategGenASave.id);
          });
        }
      });
    }
    this.rechercheSousCategGenEncours = false;
  }
  rechercheListeContact() {
    if (this.champRechercheListeContact.length > 2 && !this.rechercheListeContactEncours) {
      this.rechercheListeContactEncours = true;
      this.listeContactService.getListeContactIntranet(this.champRechercheListeContact).then((retour) => {
        this.listeListeContactIntranet = retour;
        this.listeListeContactChoix = retour;
        // éviter les doublons dans la liste de selection en cas d'une nouvelle recherche
        if (this.listeListeContactASauvegarder.length > 0) {
          this.listeListeContactASauvegarder.forEach(listeContactASave => {
            this.listeListeContactChoix =
              this.listeListeContactChoix.filter(listeContactChoix => listeContactChoix.id !== listeContactASave.id);
          });
        }
      });
    }
    this.rechercheListeContactEncours = false;
  }
  changeSociete(action) {
    let listeSociete;
    let societePresent;
    let societeDansSave;
    if (action === 'add') {
      listeSociete = this.idSocietesSelectionnees;
    } else {
      listeSociete = this.idSocietesASauvegarder;
    }
    // Gestion de la liste à sauvegarder et la liste de selection
    listeSociete.forEach((idSocietesSelectionnees) => {
      this.societeRechercheIntranet.forEach(societe => {
        if (idSocietesSelectionnees == societe.id) {
          this.societeSelectionner = societe as Organisation;
          this.listeSocieteChoix =
            this.listeSocieteChoix.filter(societeSave => societeSave.id !== idSocietesSelectionnees);
        } else {
          // retirer la(es) société(s) sélectionnée(s) dans la liste de société(s) à sauvegarder
          this.listeSocieteASauvegarder =
            this.listeSocieteASauvegarder.filter(societeSave => societeSave.id !== idSocietesSelectionnees);
        }
      });
      if (action === 'add') {
        // retirer la(es) société(s) sélectionnée(s) dans la liste de société(s) de base
        // évite les doublons
        societePresent = this.listeSocieteASauvegarder.find((categGen) => categGen.id === this.societeSelectionner.id);
        if (societePresent === undefined) {
          this.listeSocieteASauvegarder.push(this.societeSelectionner);
        }
      } else if (action === 'delete') {
        // évite les doublons
        societePresent = this.listeSocieteChoix.find((soc) => soc.id == this.societeSelectionner.id);
        societeDansSave = this.listeSocieteASauvegarder.find(soc => soc.id == this.societeSelectionner.id);
        if (societePresent === undefined && societeDansSave === undefined) {
          this.listeSocieteChoix.push(this.societeSelectionner);
        }
      }
    });
  }
  changeSupport(action) {
    let listeSupport;
    let supportPresent;
    let supportDansSave;
    if (action === 'add') {
      listeSupport = this.idSupportsSelectionnees;
    } else {
      listeSupport = this.idSupportsASauvegarder;
    }
    // Gestion de la liste à sauvegarder et la liste de selection
    listeSupport.forEach((idSupportSelectionnees) => {
      this.supportRechercheIntranet.forEach(support => {
        if (idSupportSelectionnees == support.id) {
          this.supportSelectionner = support as Support;
          this.listeSupportChoix =
            this.listeSupportChoix.filter(supportSave => supportSave.id !== idSupportSelectionnees);
        } else {
          // retirer la(es) support(s) sélectionnée(s) dans la liste de support(s) à sauvegarder
          this.listeSupportASauvegarder =
            this.listeSupportASauvegarder.filter(supportSave => supportSave.id !== idSupportSelectionnees);
        }
      });
      if (action === 'add') {
        // retirer la(es) support(s) sélectionnée(s) dans la liste de support(s) de base
        // évite les doublons
        supportPresent = this.listeSupportASauvegarder.find((support) => support.id === this.supportSelectionner.id);
        if (supportPresent === undefined) {
          this.listeSupportASauvegarder.push(this.supportSelectionner);
        }
      } else if (action === 'delete') {
        // évite les doublons
        supportPresent = this.listeSupportChoix.find((support) => support.id == this.supportSelectionner.id);
        supportDansSave = this.listeSupportASauvegarder.find(support => support.id == this.supportSelectionner.id);
        if (supportPresent === undefined && supportDansSave === undefined) {
          this.listeSupportChoix.push(this.supportSelectionner);
        }
      }
    });
  }
  changeCategGen(action) {
    let listeCategGen;
    let categGenPresent;
    let categGenDansSave;
    if (action === 'add') {
      listeCategGen = this.idCategGenSelectionnees;
    } else {
      listeCategGen = this.idCategGenASauvegarder;
    }
    // Gestion de la liste à sauvegarder et la liste de selection
    listeCategGen.forEach((idCategGenSelectionnees) => {
      this.categGenIntranet.forEach(categGen => {
        if (idCategGenSelectionnees == categGen.id) {
          this.categGenSelectionner = categGen as CategoriesGenerales;
          this.listeCategGenChoix =
            this.listeCategGenChoix.filter(categGenSave => categGenSave.id !== idCategGenSelectionnees);
        } else {
          // retirer la(es) categGen sélectionnée(s) dans la liste de categGen à sauvegarder
          this.listeCategGenASauvegarder =
            this.listeCategGenASauvegarder.filter(categGenSave => categGenSave.id !== idCategGenSelectionnees);
        }
      });
      if (action === 'add') {
        // retirer la(es) categGen sélectionnée(s) dans la liste de categGen de base
        // évite les doublons
        categGenPresent = this.listeCategGenASauvegarder.find((categGen) => categGen.id === this.categGenSelectionner.id);
        if (categGenPresent === undefined) {
          this.listeCategGenASauvegarder.push(this.categGenSelectionner);
        }
      } else if (action === 'delete') {
        // évite les doublons
        categGenPresent = this.listeCategGenChoix.find((categGen) => categGen.id == this.categGenSelectionner.id);
        categGenDansSave = this.listeCategGenASauvegarder.find(cateGen => cateGen.id == this.categGenSelectionner.id);
        if (categGenPresent === undefined && categGenDansSave === undefined) {
          this.listeCategGenChoix.push(this.categGenSelectionner);
        }
      }
    });
  }
  changeSousCategGen(action) {
    let listeSousCategGen;
    let sousCategGenPresent;
    let sousCategGenDansSave;
    if (action === 'add') {
      listeSousCategGen = this.idSousCategGenSelectionnees;
    } else {
      listeSousCategGen = this.idSousCategGenASauvegarder;
    }
    // Gestion de la liste à sauvegarder et la liste de selection
    listeSousCategGen.forEach((idSousCategGenSelectionnees) => {
      this.sousCategGenIntranet.forEach(sousCategGen => {
        if (idSousCategGenSelectionnees == sousCategGen.id) {
          this.sousCategGenSelectionner = sousCategGen as SousCategoriesGenerales;
          this.listeSousCategGenChoix =
            this.listeSousCategGenChoix.filter(sousCategGenSave => sousCategGenSave.id !== idSousCategGenSelectionnees);
        } else {
          // retirer la(es) categGen sélectionnée(s) dans la liste de categGen à sauvegarder
          this.listeSousCategGenASauvegarder =
            this.listeSousCategGenASauvegarder.filter(sousCategGenSave => sousCategGenSave.id !== idSousCategGenSelectionnees);
        }
      });
      if (action === 'add') {
        // retirer la(es) categGen sélectionnée(s) dans la liste de categGen de base
        // évite les doublons
        sousCategGenPresent = this.listeSousCategGenASauvegarder.find((sousCategGen) =>
          sousCategGen.id === this.sousCategGenSelectionner.id);
        if (sousCategGenPresent === undefined) {
          this.listeSousCategGenASauvegarder.push(this.sousCategGenSelectionner);
        }
      } else if (action === 'delete') {
        // retirer la(es) categGen sélectionnée(s) dans la liste de categGen à sauvegarder
        // évite les doublons
        sousCategGenPresent = this.listeSousCategGenChoix.find((sousCategGen) => sousCategGen.id == this.sousCategGenSelectionner.id);
        sousCategGenDansSave = this.listeSousCategGenASauvegarder.find(sousCategGen => sousCategGen.id == this.sousCategGenSelectionner.id);
        if (sousCategGenPresent === undefined && sousCategGenDansSave === undefined) {
          this.listeSousCategGenChoix.push(this.sousCategGenSelectionner);
        }
      }
    });
  }
  changeListeContact(action) {
    let listeListeContact;
    let listeContactPresent;
    let listeContactDansSave;
    if (action === 'add') {
      listeListeContact = this.idListeContactSelectionnees;
    } else {
      listeListeContact = this.idListeContactASauvegarder;
    }
    // Gestion de la liste à sauvegarder et la liste de selection
    listeListeContact.forEach((idListeContactSelectione) => {
      this.listeListeContactIntranet.forEach(listeCont => {
        if (idListeContactSelectione == listeCont.id) {
          this.listeContactSelectionner = listeCont as ListeContact;
          this.listeListeContactChoix =
            this.listeListeContactChoix.filter(listeContactSave => listeContactSave.id !== idListeContactSelectione);
        } else {
          // retirer la(es) liste contact sélectionnée(s) dans la liste de categGen à sauvegarder
          this.listeListeContactASauvegarder =
            this.listeListeContactASauvegarder.filter(listeContactSave => listeContactSave.id !== idListeContactSelectione);
        }
      });
      if (action === 'add') {
        // retirer la(es) liste contact sélectionnée(s) dans la liste de liste contact de base
        // évite les doublons
        listeContactPresent = this.listeListeContactASauvegarder.find((listeCont) => listeCont.id === this.listeContactSelectionner.id);
        if (listeContactPresent === undefined) {
          this.listeListeContactASauvegarder.push(this.listeContactSelectionner);
        }
      } else if (action === 'delete') {
        // évite les doublons
        listeContactPresent = this.listeListeContactChoix.find((liste) => liste.id == this.listeContactSelectionner.id);
        listeContactDansSave = this.listeListeContactASauvegarder.find(liste => liste.id == this.listeContactSelectionner.id);
        if (listeContactPresent === undefined && listeContactDansSave === undefined) {
          this.listeListeContactChoix.push(this.listeContactSelectionner);
        }
      }
    });
  }
  rechercherContact() {
    if (this.nom == '' && this.prenom == '' && this.civiliteId == 0 && this.genreId == 0
      && this.ancienIDFoncSelec == 0 && this.suiviPar == 0 && this.modeEnvoiPref == '' && this.premierTelPerso == ''
      &&  this.deuxiemeTelPerso == '' && this.emailPerso == '' && this.rueVoie == '' && this.complements == ''
      && this.codePostal == '' && this.boitePostale == '' && this.idCoordPersoPays == 0 && this.creeChoisi == ''
      && this.jours == 0 && this.listeListeContactASauvegarder.length == 0 && this.listeSupportASauvegarder.length == 0
      && this.listeSocieteASauvegarder.length == 0 && this.listeCategGenASauvegarder.length == 0
      && this.listeSousCategGenASauvegarder.length == 0) {
      this.dialogueShareService.addDialogue('Veuillez remplir au moins un champ');
    } else {
    this.contactService.getContactsRechercheAvancee(this.nom, this.prenom, this.civiliteId, this.genreId,
      this.ancienIDFoncSelec, this.suiviPar, this.modeEnvoiPref, this.premierTelPerso, this.deuxiemeTelPerso,
      this.emailPerso, this.rueVoie, this.complements,
      this.codePostal, this.boitePostale, this.idCoordPersoPays, this.creeChoisi, this.jours, this.curentPage)
      .then(retour => {
        let contactPresent;
        let nbElement = 0;
        if (this.nom != '' || this.prenom != '' || this.civiliteId != 0 || this.genreId != 0
          || this.ancienIDFoncSelec != 0 || this.suiviPar != 0 || this.modeEnvoiPref != '' || this.premierTelPerso != ''
          ||  this.deuxiemeTelPerso != '' || this.emailPerso != '' || this.rueVoie != '' || this.complements != ''
          || this.codePostal != '' || this.boitePostale != '' || this.idCoordPersoPays != 0 || this.creeChoisi != ''
          || this.jours != 0) {
          this.listeContactTrouvees = retour['hydra:member'] as Array<Contact>;
          this.total = retour['hydra:totalItems'];
          nbElement += retour['hydra:totalItems'];
          this.getMaxPage(nbElement);
        }
        this.rechercheEnCours = true;
        // filtre avec société
        if (this.listeSocieteASauvegarder.length > 0) {
          let contOrga;
          this.listeSocieteASauvegarder.forEach(organisation => {
            this.contactService.getContactOrgRechercheAvancee(organisation.id, this.curentPage).then(resultat => {
              contOrga = resultat['hydra:member'];
              contOrga.forEach(contactOrg => {
                contactPresent = this.listeContactTrouvees.find((cont) => cont.id == contactOrg.contact.id);
                // évite doublons
                if (contactPresent === undefined) {
                  this.listeContactTrouvees.push(contactOrg.contact);
                  this.total += resultat['hydra:totalItems'];
                  nbElement += resultat['hydra:totalItems'];
                  this.getMaxPage(nbElement);
                }
              });
            });
          });
        }
        // filtre avec supports media
        if (this.listeSupportASauvegarder.length > 0) {
          let contSupport;
          this.listeSupportASauvegarder.forEach(support => {
            this.contactService.getContactSupportRechercheAvancee(support.id, this.curentPage).then(resultat => {
              contSupport = resultat['hydra:member'];
              contSupport.forEach(contactOrg => {
                contactPresent = this.listeContactTrouvees.find((cont) => cont.id == contactOrg.contact.id);
                if (contactPresent === undefined) {
                  this.listeContactTrouvees.push(contactOrg.contact);
                  this.total += resultat['hydra:totalItems'];
                  nbElement += resultat['hydra:totalItems'];
                  this.getMaxPage(nbElement);
                }
              });
            });
          });
        }
        // filtre avec liste contact
        if (this.listeListeContactASauvegarder.length > 0) {
          let listeContContact;
          this.listeListeContactASauvegarder.forEach(listeContact => {
            this.contactService.getlisteContactContactRechercheAvancee(listeContact.id, this.curentPage).then(resultat => {
              listeContContact = resultat['hydra:member'];
              listeContContact.forEach(contactOrg => {
                contactPresent = this.listeContactTrouvees.find((cont) => cont.id == contactOrg.contact.id);
                if (contactPresent === undefined) {
                  this.listeContactTrouvees.push(contactOrg.contact);
                  this.total += resultat['hydra:totalItems'];
                  nbElement += resultat['hydra:totalItems'];
                  this.getMaxPage(nbElement);
                }
              });
            });
          });
        }
        // filtre avec catégorie
        if (this.listeCategGenASauvegarder.length > 0) {
          let listeCategGen;
          this.listeCategGenASauvegarder.forEach(categgen => {
            this.categGenShareService.getCategGensbyAncienID(categgen.id).then(resultat => {
              let contactLies;
              listeCategGen = resultat['hydra:member'][0];
              // Les contacts liés à la catégorie
              contactLies = listeCategGen.contacts;
              if (contactLies.length > 0) {
                contactLies.forEach(contact => {
                  contactPresent = this.listeContactTrouvees.find((cont) => cont.id == contact.id);
                  if (contactPresent === undefined) {
                    this.listeContactTrouvees.push(contact);
                    this.total += resultat['hydra:totalItems'];
                    nbElement += resultat['hydra:totalItems'];
                    this.getMaxPage(nbElement);
                  }
                });
              }
            });
          });
        }
        // filtre avec sous catégorie
        if (this.listeSousCategGenASauvegarder.length > 0) {
          let listeSousCategGen;
          this.listeSousCategGenASauvegarder.forEach(souscateggen => {
            this.sousCategGenShareService.getSousCategGensbyAncienID(souscateggen.id).then(resultat => {
              let contactLies;
              listeSousCategGen = resultat['hydra:member'][0];
              // Les contacts liés à la sous catégorie
              contactLies = listeSousCategGen.contacts;
              if (contactLies.length > 0) {
                contactLies.forEach(contact => {
                  contactPresent = this.listeContactTrouvees.find((cont) => cont.id == contact.id);
                  if (contactPresent === undefined) {
                    this.listeContactTrouvees.push(contact);
                    this.total += resultat['hydra:totalItems'];
                    nbElement += resultat['hydra:totalItems'];
                    this.getMaxPage(nbElement);
                  }
                });
              }
            });
          });
        }
      });
    }
  }

  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }

  changePage(page) {
    this.curentPage = page;
    this.rechercherContact();
  }

  reprendreRecherche() {
    this.rechercheEnCours = false;
    this.curentPage = 1;
  }
  CreerListeContact() {
    const listeAncienIdContact = [];
    this.listeContactTrouvees.forEach(contact => {
      listeAncienIdContact.push(contact.ancienId);
    });
    // Envoi les ancienID des contacts trouvés via la recherche avancée vers intranet afin de créer la liste
    this.openWithPostShareService.
    openWindowWithPost('http://intranet.escalconsulting.com/listecontact_cms.php?action=nouveau&origine=contact', {listeAncienIdContact});
  }
}
