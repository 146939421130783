import {Component, Input, OnInit} from '@angular/core';
import {OrganisationService} from '../../../../service/interne/organisation/OrganisationService';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {ActivatedRoute} from '@angular/router';
import {Organisation} from '../../../../model/organisation/Organisation';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';

@Component({
  selector: 'app-escal-organisation-fiche-intranet',
  templateUrl: './escal-organisation-fiche-intranet.component.html',
  styleUrls: ['./escal-organisation-fiche-intranet.component.scss'],
  providers: [ OrganisationService ]
})
export class EscalOrganisationFicheIntranetComponent implements OnInit {

  @Input()
  private sub: any;
  societe: any;
  commentaires: string;
  afficher: string;
  dejaImporte: string;
  nbCaracteres: number;
  socId: number;
  constructor(public formatIntranetShareService: FormatIntranetShareService, private organisationService: OrganisationService,
              private route: ActivatedRoute, public dialogueShareService: DialogueShareService) { }

  ngOnInit() {
    this.nbCaracteres = 0;
    this.afficher = 'moins';
    this.dejaImporte = '';
    this.sub = this.route.params.subscribe(params => {
      this.socId = params['id'];
      // vérifier si société pas déjà importée
      this.organisationService.getOrganisationByAncienId(params['id']).then((soc) => {
        const checkSoc = soc['hydra:member'] as Array<Organisation>;
        if (checkSoc.length > 0) {
          this.dejaImporte = 'oui';
        } else {
          this.dejaImporte = 'non';
        }
      });
      // récupérer toutes les informations de cette société
      this.organisationService.getSocieteIntranet(params['id'])
        .then((societe) => {
          this.societe = societe;
          if (this.societe != undefined) {
            this.societe.nom = this.societe.nom.replace('&amp;', '&');
            this.societe.nom = this.societe.nom.replace('&#039;', '’');
            this.societe.nom = this.societe.nom.replace('L&#039;', 'L’');
            if (this.societe.datecreation_soc != null && this.societe.datecreation_soc != '0000-00-00' && this.societe.datecreation_soc != '') {
              this.societe.datecreation_soc = new Date(this.societe.datecreation_soc);
            }
            if (this.societe.created != null && this.societe.created != '0000-00-00' && this.societe.created != '') {
              this.societe.created = new Date(this.societe.created);
            }
            if (this.societe.updated != null && this.societe.updated != '0000-00-00' && this.societe.updated != '') {
              this.societe.updated = new Date(this.societe.updated);
            }
            // gérer affichage commentaire
            this.nbCaracteres = this.societe.commentaire_soc.length;
            this.commentaires = this.societe.commentaire_soc.substr(0, 150);
          }
        });
    });
  }

  changerAffichage() {
    if (this.afficher === 'plus') {
      this.commentaires = this.societe.commentaire_soc.substr(0, 150);
      this.afficher = 'moins';
    } else {
      this.commentaires = this.societe.commentaire_soc;
      this.afficher = 'plus';
    }
  }

  importerSociete() {
    // importer la societe
    this.organisationService.importerOrganisation(this.socId).then(() => {
      this.ngOnInit();
      this.dialogueShareService.addDialogue('La société a bien été importée');
    });
  }

}
