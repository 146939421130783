import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../../environement/environement';
import {ImagePressroom} from '../../../../model/client/pressroom/ImagePressroom';


@Injectable()
export class ImagePressroomService{
    private imagePressroomUrl = Environement.BASE_API_URL + '/api/image_pressrooms';
    constructor(private http: HttpClient) { }

  addImagePressroom(imagePressRoom: ImagePressroom, logoUpload, idPressroom) {
    const headers = this.initOptionHeader();
    return this.http.post(this.imagePressroomUrl , {'nom': imagePressRoom.nom, 'idPressroom' : idPressroom ,
      'value': logoUpload['value'], 'filetype': logoUpload['extension'], 'type': imagePressRoom.type}, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  deleteteImage(imagePressRoom: ImagePressroom){
    const headers = this.initOptionHeader();
    return this.http.delete( this.imagePressroomUrl + '/'  + imagePressRoom.id ,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getAllLogoPressroom(idPressroom){
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/image/pressroom/logos/' + idPressroom,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getAllPhototechPressroom(idPressroom){
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/image/pressroom/phototech/' + idPressroom,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
        return Promise.reject(err.message || err );
    };

    private initOptionHeader(){
        const token: any = localStorage.getItem('auth-tokens');
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.set('X-Auth-Token', token);
        return headers;
    }
}
