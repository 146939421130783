import { Component, OnInit } from '@angular/core';
import {ModalCalendrierShareService} from '../../../../service/partage/ModalCalendrierShareService';

@Component({
  selector: 'app-modal-calendrier-salle',
  templateUrl: './modal-calendrier-salle.component.html',
  styleUrls: ['./modal-calendrier-salle.component.scss']
})
export class ModalCalendrierSalleComponent implements OnInit {

  salleId;

  constructor(public modalCalendrierShareService: ModalCalendrierShareService) { }

  ngOnInit() {
    if (this.modalCalendrierShareService.salleId === undefined) {
      this.modalCalendrierShareService.salleId = this.modalCalendrierShareService.salles[0].id;
    }
  }

  checkSalleLibre(){
    this.modalCalendrierShareService.verifDispo();
  }
}
