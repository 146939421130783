
export class ReseauxSociaux {
  id: number;
  linkedinURL: string;
  twitterURL: string;
  facebookURL: string;

  constructor(id: number, linkedinURL: string, twitterURL: string, facebookURL: string) {
    this.id = id;
    this.linkedinURL = linkedinURL;
    this.twitterURL = twitterURL;
    this.facebookURL = facebookURL;
  }
}
