import {ContentTitre} from './contentTitre';

export class TitreMenu {
  id: string;
  nom: string;
  icone: string;
  contentTitre: Array<ContentTitre>;
  path: string;


  constructor(id: string, icone: string,  nom: string , contentTitre: Array<ContentTitre>, path: string) {
    this.id = id;
    this.nom = nom;
    this.icone = icone;
    this.contentTitre = contentTitre
    this.path = path;
  }
}
