import {Component, OnInit, ViewChild} from '@angular/core';
import {Suivi} from '../../../../model/suivi/Suivi';
import {OrganisationService} from '../../../../service/interne/organisation/OrganisationService';
import {Organisation} from '../../../../model/organisation/Organisation';
import {PaginationRechercheComponent} from '../../../utilitaire/pagination/pagination-recherche/pagination-recherche.component';

@Component({
  selector: 'app-escal-organisation-list',
  templateUrl: './escal-organisation-list.component.html',
  styleUrls: ['./escal-organisation-list.component.scss'],
  providers: [OrganisationService]
})
export class EscalOrganisationListComponent implements OnInit {

  typeRecherche: Array<any> = [];
  headerContent: Array<any> = [];
  recherche: Array<any> = [];
  societes: Array<Organisation>;
  curentPage = 1;
  maxPage: number;
  total: number;

  @ViewChild(PaginationRechercheComponent) societesIntranet: PaginationRechercheComponent;
  constructor(private organisationService: OrganisationService) { }

  ngOnInit() {
    this.initContent();
    this.headerContent.push('isOrganisation');
    this.headerContent.push('Nom');
    this.headerContent.push('Genre');
    this.headerContent.push('Forme');
    this.headerContent.push('Catégorie');

    this.typeRecherche.push('Tous');
    this.typeRecherche.push('Nom');
    this.typeRecherche.push('Dirigeant');
    this.typeRecherche.push('Genre');
    this.typeRecherche.push('Forme Juridique');
    this.typeRecherche.push('Catégorie');
    this.typeRecherche.push('Intranet');
  }

  initContent() {
    const that = this;
    // affichage par defaut
    if ( this.recherche.length === 0) {
      this.organisationService.getOrganisationsParDefaut(this.curentPage)
        .then((data) => {
          that.societes = data['hydra:member'] as Array<Organisation>;
          that.total = data['hydra:totalItems'];
          that.getMaxPage(data['hydra:totalItems']);
        });
    } else if (this.recherche[0].length >= 3 ) {
      // si contenu recherche
      this.organisationService.getOrganisationsRecherche(this.recherche, this.curentPage)
        .then((data) => {
          that.societes = data['hydra:member'] as Array<Organisation>;
          that.total = data['hydra:totalItems'];
          that.getMaxPage(data['hydra:totalItems']);
        });
    }
  }

  updateRecherche(recherche: Array<any>) {
    this.curentPage = 1;
    if (recherche[0] === '') {
      this.recherche = [];
    } else {
      this.recherche = recherche;
    }
    this.initContent();
  }

  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }

  changePage(page) {
    this.curentPage = page;
    this.initContent();
  }

}
