import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';


@Injectable()
export class CodeApeService {
  private codeApeUrl = Environement.BASE_API_URL + '/api/code_apes';
  constructor(private http: HttpClient) { }

  getCodesApeRecherche(recherche) {
    const headers = this.initOptionHeader();
    return this.http.get(this.codeApeUrl + '?libelle=' + recherche + '&order[libelle]=asc', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getCodeApeByAncienId(apeSoc): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.codeApeUrl + '?ancienId=' + apeSoc, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getCodes() {
    const headers = this.initOptionHeader();
    return this.http.get(this.codeApeUrl, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }


  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
