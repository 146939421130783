import { Component, OnInit } from '@angular/core';
import {AuthShareService} from '../../../../service/partage/AuthShareService';

@Component({
  selector: 'app-gestion-mes-clients',
  templateUrl: './gestion-mes-clients.component.html',
  styleUrls: ['./gestion-mes-clients.component.scss']
})
export class GestionMesClientsComponent implements OnInit {

  ouvert = 0;
  constructor(private authShareService: AuthShareService) { }

  ngOnInit() {
  }
  elemOuvert(id) {
    this.ouvert = id;
  }
  elementIsOuvert(id){
    if (this.ouvert === id) {
      return true;
    }
  }

}
