import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MaillingService} from '../../../../../service/interne/mailling/maillingService';
import {Mailling} from '../../../../../model/mailling/Mailling';

@Component({
  selector: 'app-escal-mailling-view-en-cour',
  templateUrl: './escal-mailling-view-en-cour.component.html',
  styleUrls: ['./escal-mailling-view-en-cour.component.scss'],
  providers: [ MaillingService ],
})
export class EscalMaillingViewEnCourComponent implements OnInit {

  id: number;
  private sub: any;
  private mailling: Mailling;
  private maillingEnCourInfo: Array<any>;
  message_erreur: string;
  nbMailAttente: number = 0;
  nbMailToSend: number = 0;
  nbMailSend: number = 0;

  public lineChartOptions:any = {
    legend: {
      display: true,
      labels: {
        fontColor: '#33363b',
        boxWidth: 100,
        fontSize: 20,
      }},
    tooltips: {
      enabled	: true,
      xPadding: 40,
      yxPadding: 40,
      bodyFontSize: 20,
    },
    datasets: [{
      data: [2, 5, 1],
      backgroundColor: [
        'rgb(51, 204, 102)',
        'rgb(127, 209, 233)'
      ]
    }],
    responsive: true
  };


  public doughnutChartLabels: string[] = ['déjà envoyé', 'en attente d\'envoi'];
  public doughnutChartData: number[] = [0, 0];
  public doughnutChartType: string = 'doughnut';


  constructor(private route: ActivatedRoute, private maillingService: MaillingService) { }

  ngOnInit() {
    const that = this;
    this.sub = this.route.params.subscribe(params => {
      that.id = +params['id'];
      that.maillingService.getMaillingItem(that.id)
        .then((retour) => {
          that.mailling = retour as Mailling;
        });
      that.maillingService.getMaillingEnCourInfo(that.id)
        .then((EnCourInfo) => {
          that.maillingEnCourInfo = EnCourInfo;
          if (EnCourInfo['message_erreur'] === undefined) {
            that.nbMailAttente = Number(EnCourInfo['nbMaillAttente']);
            that.nbMailSend = Number(EnCourInfo['nbMailSend']);
            that.nbMailToSend = Number(EnCourInfo['nbMailToSend']);
            const nbMailRestant = that.nbMailSend - that.nbMailToSend;
            that.doughnutChartData = [ that.nbMailSend , nbMailRestant];
          } else {
            that.message_erreur = EnCourInfo['message_erreur'];
          }
        });
    });
  }
  tempsRestant(nbMail){
    return Math.trunc((nbMail / 100)) + 1;
  }
}
