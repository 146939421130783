import {Component, Input, OnInit} from '@angular/core';
import {Organisation} from '../../../../../model/organisation/Organisation';

@Component({
  selector: 'app-pagination-content-organisation',
  templateUrl: './pagination-content-organisation.component.html',
  styleUrls: ['./pagination-content-organisation.component.scss']
})
export class PaginationContentOrganisationComponent implements OnInit {

  @Input()
  societes: Array<Organisation>;

  constructor() { }

  ngOnInit() {
  }

}
