import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {ActivatedRoute} from '@angular/router';
import {ArticleSiteService} from '../../../../service/interne/Site/ArticleSiteService';
import {ArticleSite} from '../../../../model/site/ArticleSite';
import {ImagePressroom} from '../../../../model/client/pressroom/ImagePressroom';
import {ArticleSiteSection} from '../../../../model/site/ArticleSiteSection';
import {ImageArticleSite} from '../../../../model/site/ImageArticleSite';
import {Environement} from '../../../../environement/environement';
import {ArticleSiteSectionService} from '../../../../service/interne/Site/ArticleSiteSectionService';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {MotCleSiteService} from '../../../../service/interne/Site/MotCleSiteService';
import {TagModel} from 'ngx-chips/core/accessor';
import {Observable} from 'rxjs';
import {ArticleSiteMotCleService} from '../../../../service/interne/Site/ArticleSiteMotCleService';

@Component({
  selector: 'app-site-escal-article-configuration',
  templateUrl: './site-escal-article-configuration.component.html',
  styleUrls: ['./site-escal-article-configuration.component.scss'],
  providers: [ ArticleSiteService, ArticleSiteSectionService, MotCleSiteService, ArticleSiteMotCleService ]
})
export class SiteEscalArticleConfigurationComponent implements OnInit {
  modification: string;
  id: any;
  sub: any;
  article: ArticleSite;
  sectionEnModification: ArticleSiteSection;
  image: ImageArticleSite;
  imageFond: ImageArticleSite;
  public Editor = ClassicEditor;
  autocompleteItemsAsObjects = [

  ];
  public autocompleteResult = [];

  constructor(private route: ActivatedRoute, private articleSiteService: ArticleSiteService,
              private articleSiteSectionService: ArticleSiteSectionService, private dialogueShareService: DialogueShareService,
              private motCleSiteService: MotCleSiteService, private articleSiteMotCleService: ArticleSiteMotCleService) { }

  ngOnInit() {
  this.modification = 'none';
  this.autocompleteResult = [];
  const that = this;
  this.sub = this.route.params.subscribe(params => {
      that.id = params['id'];
      that.articleSiteService.getArticle(that.id)
        .then((retour) =>{
          that.article = retour as ArticleSite;
          that.imageFond = that.article.imageFond;
          this.motCleSiteService.getAllMotCles().then((retour1) =>{
            that.autocompleteItemsAsObjects = retour1['hydra:member'];
            that.article.articlesMotCle.forEach( (articleMotcle) => {
              const pos = that.autocompleteItemsAsObjects.map(e => e.id).indexOf(articleMotcle.motCle.id);
              if (pos !== -1) {
                that.autocompleteItemsAsObjects.splice(pos, 1);
                articleMotcle.motCle.display = articleMotcle.motCle.motCle;
                that.autocompleteResult.push(articleMotcle.motCle);
              }
            });
        });
    });
  });
  }
  public addMotCle(event) {
    const that = this;
    this.articleSiteMotCleService.addArticleSiteMotCle(this.article, event). then((retour) => {
      that.article.articlesMotCle.push(event);
      that.ngOnInit();
    });
  }
  public removeMotCle(event){
    const that = this;
    that.article.articlesMotCle.forEach( (articleMotcle) => {
      const pos = that.autocompleteResult.map(e => e.id).indexOf(articleMotcle.motCle.id);
      if (pos !== -1 && articleMotcle.motCle.id === event.id) {
        this.articleSiteMotCleService.deleteArticleSiteMotCle(articleMotcle.id).then( (retour) =>{
          const pos = that.article.articlesMotCle.map(e => e.id).indexOf(event.id);
          if (pos !== -1) {
            that.article.articlesMotCle.splice(pos, 1);
          }
        });
      }
    });

  }

  styleTitle() {
    const retour = {
      color: undefined
    };
    retour.color = this.article.couleurTitle;
    return retour;
  }

  modificationSection(section) {
    if ( section === null ) {
      this.sectionEnModification = new ArticleSiteSection(0, '', '#000000', '',
        '', 'g', 'g', new ImageArticleSite(0, '', 'imageSection'));
      this.image = null;
    } else {
      if (section.image === null || typeof section.image === 'undefined') {
        section.image = new ImageArticleSite(0, '', 'imageSection');
        this.image = null;
      } else {
        this.image = section.image;
      }
      this.sectionEnModification = section;
    }
    this.modification = 'section';
  }

  saveArticle(){
      const that = this;
      this.articleSiteService.updateTitle(this.article)
          .then( () => {
            that.ngOnInit();
            that.modification = 'none';
          });
    }

  positionContentChange(value) {
    if ( value !== this.sectionEnModification.contentDisposition) {
      this.sectionEnModification.contentDisposition = value;
    }
  }
  positionImageChange(value) {
    if (value !== this.sectionEnModification.imagePosition) {
      this.sectionEnModification.imagePosition = value;
    }
  }

  getImageSource(image: ImageArticleSite){
    return Environement.BASE_API_URL + '/image/site/content/' + image.id;
  }
  getImageZoomUrl() {
    if (this.image === null) {
      return 'assets/application/images/noImage.png';
    } else {
      return this.getImageSource(this.image);
    }
  }
  getImageFondZoomUrl() {
    if (this.imageFond === null) {
      return 'assets/application/images/noImage.png';
    } else {
      return this.getImageSource(this.imageFond);
    }
  }
  isImageSelected(image: ImageArticleSite) {
    if(image === null || this.image === null) {
      return false;
    }
    return image.id === this.image.id;
  }
  isImageFondSelected(image: ImageArticleSite) {
    if(this.imageFond === null || this.imageFond === null) {
      return false;
    }
    return this.imageFond.id === this.imageFond.id;
  }
  saveSection() {
    const that = this;
    if (this.sectionEnModification.id === 0) {
      this.articleSiteSectionService.addArticleSiteSection(this.sectionEnModification,
        this.article,
        this.image)
        .then( () => {
          that.ngOnInit();
          that.modification = 'none';
        });
    } else {
      this.articleSiteSectionService.updateArticleSiteSection(this.sectionEnModification, this.image)
        .then( () => {
          that.ngOnInit();
          that.modification = 'none';
        });
    }
  }
  saveImageFond(){
    const that = this;
    this.articleSiteService.ChangeImageFond(that.imageFond, that.article).then((retour) => {
      that.ngOnInit();
    });
  }
  deleteSection(articleSection: ArticleSiteSection){
    const that = this;
    const variable = {
      'articleSiteSectionService': this.articleSiteSectionService, 'dialogueShareService': this.dialogueShareService, 'id': articleSection.id
    };
    this.dialogueShareService.addDialogue('êtes vous sûre de vouloir supprimer la sectionn', function () {
      variable['articleSiteSectionService'].deleteSection(variable['id'])
        .then((retour) => {
          variable['dialogueShareService'].addDialogue( 'Sectuib supprimé avec succés');
          that.ngOnInit();
        })
        .catch((retour) => {
          variable['dialogueShareService'].addDialogue('erreur lors de la supression');
        });
    });
  }



}
