import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';

@Injectable()
export class AdresseService {
  private adressUrl = Environement.BASE_API_URL + '/api/adresses';
  constructor(private http: HttpClient) { }

  updateAdresse(idAdresse, adresse, codePostal, complement, pays, ville): Promise<any>{
    const headers = this.initOptionHeader();
    if (adresse === null) {
      adresse = '';
    }
    if (ville === null) {
      ville = '';
    }
    if (codePostal === null) {
      codePostal = '';
    }
    if (pays === null) {
      pays = '';
    }
    if (complement === null) {
      complement = '';
    }
    return this.http.put(this.adressUrl + '/' + idAdresse, {'adresse': adresse, 'ville': ville, 'codePostal': codePostal,
      'pays': pays, 'complement': complement}, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
