import {Injectable} from '@angular/core';


import {DialogueShareService} from './utilitaire/DialogueShareService';
import {Suivi} from '../../model/suivi/Suivi';
import {AuthUser} from '../../model/user/authUser';
import {Contact} from '../../model/contact/Contact';
import {TypeRelationSuivi} from '../../model/suivi/TypeRelationSuivi';
import {VecteurSuivi} from '../../model/suivi/VecteurSuivi';
import {Statut} from '../../model/suivi/Statut';
import {Support} from '../../model/support/Support';
import {SuiviNature} from '../../model/suivi/SuiviNature';
import {Environement} from '../../environement/environement';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {removeAccents} from './prototype/sansAccents';





@Injectable()
export class SousCategGenShareService {
  private sousCategGenURL = Environement.BASE_API_URL + '/api/sous_categories_generales';
  private sousCategGenIntranetURL = Environement.BASE_API_URL + '/api/contact/recherche/intranet/souscategorie/';
  constructor(private http: HttpClient) {
  }
  getSousCategoriesGenerales() {
    const headers = this.initOptionHeader();
    return this.http.get(this.sousCategGenURL, {headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getSousCategGenIntranet(recherche) {
    const headers = this.initOptionHeader();
    return this.http.get(this.sousCategGenIntranetURL + recherche, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getSousCategGenByID(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.sousCategGenURL + '/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getSousCategGensbyAncienID(ancienID) {
    const headers = this.initOptionHeader();
    return this.http.get(this.sousCategGenURL + '?several_all=' + ancienID, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addSousCategGen(sousCategGen) {
    const headers = this.initOptionHeader();
    return this.http.post(this.sousCategGenURL,
      {libelle: sousCategGen.nom, ancienId: parseInt(sousCategGen.id, 10)}, {headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
