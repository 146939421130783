import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';


@Injectable()
export class LogoMaillingService {
  private logoMaillingUrl = Environement.BASE_API_URL + '/api/logo_maillings';
  constructor(private http: HttpClient) { };

  addLogo(idMailling, logoUpload): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post(this.logoMaillingUrl , {'idMailling': idMailling, 'url' : idMailling.toString() + '.' + logoUpload['extension'],
        'value': logoUpload['value'], 'filetype': logoUpload['extension']},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  deleteLogo(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.delete(this.logoMaillingUrl + '/' + id , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
