import {ImagePressroom} from './ImagePressroom';

export class PressroomAccueilSection {
    id: number;
    title: string;
    couleurTitle: string;
    nom: string;
    content: string;
    contentDisposition: string;
    imagePosition: string;
    image: ImagePressroom;




    constructor(id: number, title: string, couleurTitle: string, nom: string, content: string, contentDisposition: string,
                imagePosition: string, image: ImagePressroom) {
        this.id = id;
        this.title = title;
        this.couleurTitle = couleurTitle;
        this.nom = nom;
        this.content = content;
        this.contentDisposition = contentDisposition;
        this.imagePosition = imagePosition;
        this.image = image;
    }
}
