import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';

import {ArticleSite} from '../../../model/site/ArticleSite';
import {Environement} from '../../../environement/environement';
import {removeAccents} from '../../partage/prototype/sansAccents';
import {ImageArticleSite} from '../../../model/site/ImageArticleSite';


@Injectable()
export class ArticleSiteService{
    private articleUrl = Environement.BASE_API_URL + '/api/article_sites';
    constructor(private http: HttpClient) { }

  addArticle(article: ArticleSite) {
    const headers = this.initOptionHeader();
    return this.http.post(this.articleUrl , {'nom': article.nom}, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  ChangeImageFond(imageArticleSite: ImageArticleSite, article: ArticleSite){
    let headers = this.initOptionHeader();
    if(imageArticleSite){
      return this.http.put(this.articleUrl + '/' + article.id, {'imageFond': '/api/image_article_sites/' + imageArticleSite.id },{headers: headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    } else {
      return this.http.put(this.articleUrl + '/' + article.id, {'imageFond': null },{headers: headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }

  }
  getArticles(page): Promise<any>{
    const headers = this.initOptionHeader();
    return this.http.get(this.articleUrl + '?_page=' + page , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getArticlesRecherche(recherche: Array<any>, page){
    const content = recherche[0];
    let type = recherche[1];
    const headers = this.initOptionHeader();
    type = removeAccents(type.toLowerCase());
    return this.http.get(this.articleUrl + '?' + type + '=' + content + '&_page=' + page,
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateActif(article: ArticleSite): Promise<any>{
      let headers = this.initOptionHeader();
      return this.http.put(this.articleUrl + '/' + article.id, {'actif': article.actif },{headers: headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
  }


  deleteArticle(id){
    const headers = this.initOptionHeader();
    return this.http.delete(this.articleUrl + '/' + id ,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getArticle(id): Promise<any>{
    const headers = this.initOptionHeader();
    return this.http.get(this.articleUrl + '/' + id , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  updateTitle(article: ArticleSite) {
    const headers = this.initOptionHeader();
    return this.http.put(this.articleUrl + '/' + article.id , {'title': article.title,
      'couleurTitle': article.couleurTitle}, {headers} ).toPromise()
      .then(response => response)

      .catch(this.errorHandler);
  }
    private errorHandler(err: any): Promise<any> {
        return Promise.reject(err.message || err );
    };
    private initOptionHeader(){
        const token: any = localStorage.getItem('auth-tokens');
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.set('X-Auth-Token', token);
        return headers;
    }
}
