import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Client} from '../../../../../model/client/Client';
import {ArticleSite} from '../../../../../model/site/ArticleSite';

@Component({
  selector: 'app-pagination-content-article-site',
  templateUrl: './pagination-content-article-site.component.html',
  styleUrls: ['./pagination-content-article-site.component.scss']
})
export class PaginationContentArticleSiteComponent implements OnInit {
  @Input()
  articles: Array<ArticleSite>;
  @Output() deleteArticle = new EventEmitter<Array<any>>();
  @Output() updateArticle = new EventEmitter<ArticleSite>();
  constructor() { }

  ngOnInit() {
  }

  deleteArticlefonction(id){
    this.deleteArticle.emit(id);
  }
  articleActifChange(event, article: ArticleSite){
    console.log(article);
    this.updateArticle.emit(article);
  }
  activation_possible(article: ArticleSite){
    if (article.articlesMotCle.length < 1){
      return true;
    }
    if (article.imageFond === null) {
      return true;
    }
    if (article.nom.length < 3) {
      return true;
    }
    if (article.sections.length < 1) {
      return true;
    }
    if (article.title.length < 3 ) {
      return true;
    }
    return false;
  }
}
