import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-utilitaire-importer-societes',
  templateUrl: './utilitaire-importer-societes.component.html',
  styleUrls: ['./utilitaire-importer-societes.component.scss']
})
export class UtilitaireImporterSocietesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
