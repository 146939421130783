import {Coupure} from '../coupure/Coupure';


export class PieceJointe {
  id: number;
  url: string;
  coupure: Coupure;


  constructor( id: number, url: string, coupure: Coupure) {
    this.id = id;
    this.url = url;
    this.coupure = coupure;
  }
}
