import {Component, Input, OnInit} from '@angular/core';
import {Mailling} from '../../../../../model/mailling/Mailling';

@Component({
  selector: 'app-pagination-content-mail-send',
  templateUrl: './pagination-content-mail-send.component.html',
  styleUrls: ['./pagination-content-mail-send.component.scss']
})
export class PaginationContentMailSendComponent implements OnInit {

  @Input()
  maillings: Array<Mailling>;
  constructor() { }

  ngOnInit() {}

}
