import {Injectable} from '@angular/core';



@Injectable()
export class OpenWithPostShareService {



  constructor() {
  }

  openWindowWithPost(url, data) {

    const form = document.createElement('form');
    form.target = '_blank';
    form.method = 'POST';
    form.action = url;
    form.style.display = 'none';

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
      }
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }

  openWindows(url) {

    const form = document.createElement('form');
    form.target = '_blank';
    form.method = 'GET';
    form.action = url;
    form.style.display = 'none';
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }

}
