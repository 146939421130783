import { Component, OnInit } from '@angular/core';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {AuthShareService} from '../../../../service/partage/AuthShareService';
import {AuthUserService} from '../../../../service/interne/user/AuthUserService';

@Component({
  selector: 'app-escal-utilisateur-change-mdp',
  templateUrl: './escal-utilisateur-change-mdp.component.html',
  styleUrls: ['./escal-utilisateur-change-mdp.component.scss'],
  providers: [ AuthUserService ],
})
export class EscalUtilisateurChangeMdpComponent implements OnInit {

  passwordIntra = '';
  passwordIntraVerif = '';

  nouveauPasswordIntraActive = '';
  nouveauPasswordVerifIntraActive = '';

  constructor(private dialogueShareService: DialogueShareService, private authUserService: AuthUserService,
              private authShareService: AuthShareService) {
    document.addEventListener('click', (event) => this.verifInput(event));
  }

  ngOnInit() {
    const that = this;
    setTimeout(() => {
      that.passwordIntra = '';
    }, 100);
  }


  verifInput(event) {

    const elem = event['target'];

    if (elem.classList.contains('passwordIntraActive')) {
      this.nouveauPasswordIntraActive = 'active';
    } else {
      if (this.passwordIntra === '') {
        this.nouveauPasswordIntraActive = '';
      } else {
        this.nouveauPasswordIntraActive = 'active';
      }
    }
    if (elem.classList.contains('passwordIntraVerifActive')) {
      this.nouveauPasswordVerifIntraActive = 'active';
    } else {
      if (this.passwordIntraVerif === '') {
        this.nouveauPasswordVerifIntraActive = '';
      } else {
        this.nouveauPasswordVerifIntraActive = 'active';
      }
    }
  }
  validerIntraChange(){
    if (this.passwordIntra !== this.passwordIntraVerif) {
      this.dialogueShareService.addDialogue('les 2 mots de passes ne sont pas identiques');
    } else if (this.passwordIntra.length < 6) {
      this.dialogueShareService.addDialogue('le mot de passe doit faire au moins 6 caractères');
    } else {
      const variable = {
        'authUserService': this.authUserService, 'passwordIntra': this.passwordIntra
        , 'dialogueShareService': this.dialogueShareService, 'id': this.authShareService.authUser.id
      };
      this.dialogueShareService.addDialogue('êtes vous sûre de vouloir changer votre password', () => {
        variable['authUserService'].changePassword(variable['id'], variable['passwordIntra'])
          .then((retour) => {
            variable['dialogueShareService'].addDialogue('mot de passe modifié');
          })
          .catch((retour) => {
            variable['dialogueShareService'].addDialogue('erreur de modification');
          });
      });
    }
  }

}
