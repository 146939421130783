
export class ClientPersonne {
    id: number;
    nom: string;
    prenom: string;
    ancienId: number;
    email: string;


    constructor(id: number, nom: string, prenom: string, ancienId: number, email: string) {
        this.id = id;
        this.nom = nom;
        this.prenom = prenom;
        this.ancienId = ancienId;
        this.email = email;
    }
}
