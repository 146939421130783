import {Contact} from './Contact';
import {Organisation} from '../organisation/Organisation';

export class Genre {
  id: number;
  libelle: string;
  contacts: Array<Contact>;
  organisations: Array<Organisation>;

  constructor(id: number, libelle: string, contacts: Array<Contact>, organisations: Array<Organisation>) {
    this.id = id;
    this.libelle = libelle;
    this.contacts = contacts;
    this.organisations = organisations;
  }
}
