import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact} from '../../../../../model/contact/Contact';
import {ContactService} from '../../../../../service/interne/contact/ContactService';
import {ActivatedRoute} from '@angular/router';
import {ContactOrganisation} from '../../../../../model/contact/ContactOrganisation';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {OrganisationService} from '../../../../../service/interne/organisation/OrganisationService';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';


@Component({
  selector: 'app-escal-contact-fiche-societes',
  templateUrl: './escal-contact-fiche-societes.component.html',
  styleUrls: ['./escal-contact-fiche-societes.component.scss'],
  providers: [OrganisationService]
})
export class EscalContactFicheSocietesComponent implements OnInit {
  @Input()
  contact: Contact;
  @Output()
  refresh = new EventEmitter();
  private sub: any;
  curentPage = 1;
  maxPage: number;
  contactOrganisations: Array<ContactOrganisation> = [];
  addOpen: boolean;
  addOpenMAJ: boolean;
  telPro1: string;
  telPro2: string;
  emailPro: string;
  idOrganisationSelected: number;
  champRechercheSociete = '';
  afficheSociete: boolean;
  rechercheSocieteEncours = false;
  societeRechercheIntranet: Array<any> = [];
  contactOrganisationModifier: ContactOrganisation;

  constructor(public formatIntranetShareService: FormatIntranetShareService, private route: ActivatedRoute,
              private contactService: ContactService, private organisationService: OrganisationService,
              private dialogueShareService: DialogueShareService) {
  }

  ngOnInit() {
    this.telPro1 = '';
    this.telPro2 = '';
    this.emailPro = '';
    this.addOpen = false;
    this.addOpenMAJ = false;
    this.afficheSociete = false;
    this.idOrganisationSelected = 0;
    this.sub = this.route.params.subscribe(async params => {
      await this.contactService.getContactOrganisationBy(params.id).then(retour => {
        this.contactOrganisations = retour['hydra:member'];
        this.getMaxPage(retour['hydra:totalItems']);
      });
    });
  }

  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }

  changePage(page) {
    this.curentPage = page;
    this.ngOnInit();
  }

  rechercheSociete() {
    this.afficheSociete = true;
    if (this.champRechercheSociete.length > 2 && !this.rechercheSocieteEncours) {
      this.rechercheSocieteEncours = true;
      this.champRechercheSociete = this.champRechercheSociete.replace(' ', '.');
      this.organisationService.getSocieteRecherche(this.champRechercheSociete).then((retour) => {
        this.societeRechercheIntranet = retour;
        this.societeRechercheIntranet.forEach(societeTrouve => {
          if (societeTrouve.nom != '' && societeTrouve.nom != null) {
            societeTrouve.nom = societeTrouve.nom.replace('&amp;', '&');
            societeTrouve.nom = societeTrouve.nom.replace('&#039;', '’');
            societeTrouve.nom = societeTrouve.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheSociete = this.champRechercheSociete.replace('.', ' ');
      });
      this.rechercheSocieteEncours = false;
    }
  }

  addSocieteAsso() {
    const societeIdAssocies = [];
    if (this.idOrganisationSelected > 0) {
      // Vérifier si la société existe déjà sur escalnet, sinon on l'importe
      this.organisationService.importerOrganisation(this.idOrganisationSelected).then(() => {
        this.organisationService.getDoublonEscalnetSiExiste(this.idOrganisationSelected).then(societe => {
          const socId = societe['hydra:member'][0].id;
          // récupérer les id des sociétés associées pour ne pas avoir de doublons
          if (this.contactOrganisations.length > 0) {
            this.contactOrganisations.forEach(assoExistant => {
              societeIdAssocies.push(assoExistant.organisation.id);
            });
          }
          if (this.contactOrganisations.length == 0 || societeIdAssocies.includes(socId) == false) {
            // on crée l'asso avec les coordonnées sur l'escalnet
            this.contactService.addContactOrganisation(this.contact.id, socId, this.telPro1,
              this.telPro2, this.emailPro).then(() => {
              // on crée l'asso avec les coordonnées sur l'intranet
              this.contactService.addContactAssoIntranet('societe', this.contact.id, socId).then(() => {
                this.dialogueShareService.addDialogue('La société est bien associée');
                this.ngOnInit();
              });
            });
          } else if (societeIdAssocies.includes(socId) == true) {
            this.dialogueShareService.addDialogue('La société est déjà associée');
          }
        });
      });
    } else {
      this.dialogueShareService.addDialogue('La société est obligatoire');
    }
  }

  updateDonneesSocieteAsso() {
    // Eviter de perdre les données déjà dans la base
    if (this.telPro1 == '' || this.telPro1 == null) {
      this.telPro1 = this.contactOrganisationModifier.premierTel;
    }
    if (this.telPro2 == '' || this.telPro2 == null) {
      this.telPro2 = this.contactOrganisationModifier.deuxiemeTel;
    }
    if (this.emailPro == '' || this.emailPro == null) {
      this.emailPro = this.contactOrganisationModifier.email;
    }
    // update sur escalnet
    this.contactService.updateContactOrganisation(this.contactOrganisationModifier, this.telPro1,
      this.telPro2, this.emailPro).then(() => {
      // update sur intranet
      this.contactService.updateContactAssoIntranet('societe', this.contactOrganisationModifier.id).then(() => {
        this.dialogueShareService.addDialogue('Mise à jour effectué');
        this.ngOnInit();
      });
    });
  }

  deleteAsso(asso) {
    const that = this;
    this.dialogueShareService.addDialogue('êtes-vous sûr de vouloir supprimer l\'association',
      function () {
        that.contactService.deleteContactAssoIntranet('societe', asso.id).then(() => {
          that.contactService.deleteContactOrganisation(asso.id).then(() => {
            that.dialogueShareService.addDialogue('l\'association est bien supprimé');
            that.ngOnInit();
          });
        })
          .catch(() => {
            that.dialogueShareService.addDialogue('Erreur de suppression');
          });
      });

    this.ngOnInit();
  }

  chargerChamps(asso) {
    this.addOpen = true;
    this.addOpenMAJ = true;
    this.contactOrganisationModifier = asso;
    if (this.contactOrganisations.length > 0) {
      this.contactOrganisations.forEach(contactOrganisation => {
        if (contactOrganisation.id == asso.id) {
          this.telPro1 = contactOrganisation.premierTel;
          this.telPro2 = contactOrganisation.deuxiemeTel;
          this.emailPro = contactOrganisation.email;
        }
      });
    }
  }

  viderChamps() {
    this.addOpen = true;
    this.addOpenMAJ = false;
    this.telPro1 = '';
    this.telPro2 = '';
    this.emailPro = '';
    this.idOrganisationSelected = 0;

  }
}
