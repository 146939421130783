import { Component, OnInit } from '@angular/core';


import {Anomalie} from '../../../model/utilitaire/bugs/Anomalie';
import {CategorieAnomalie} from '../../../model/utilitaire/bugs/CategorieAnomalie';
import {CategorieAnomalieService} from '../../../service/interne/utilitaire/Anomalie/CategorieAnomalieService';
import {AnomalieService} from '../../../service/interne/utilitaire/Anomalie/AnomalieService';
import {DialogueShareService} from '../../../service/partage/utilitaire/DialogueShareService';

@Component({
  selector: 'app-bugs',
  templateUrl: './bugs.component.html',
  styleUrls: ['./bugs.component.scss'],
  providers: [ CategorieAnomalieService, AnomalieService ]
})
export class BugsComponent implements OnInit {

  public anomalie: Anomalie;
  public categoriesAnomalie: Array<CategorieAnomalie>;
  public selectedCatVal: number;

  constructor(private categorieAnomalieService: CategorieAnomalieService, private anomalieService: AnomalieService,
              public dialogueShareService: DialogueShareService) { }

  ngOnInit() {
    const that = this;
    this.categorieAnomalieService.getCategorieAnomalieUser()
      .then((retour) => {
        that.categoriesAnomalie = retour['hydra:member'] as Array<CategorieAnomalie>;
        that.selectedCatVal = that.categoriesAnomalie[0].id;
        this.anomalie = new Anomalie(0, 'Utilisateur', '1', '', '', 'new', that.categoriesAnomalie[0], new Date());
      });
  }
  submitAnomalie() {
    const that = this;
    if(this.anomalie.cible !== '' && this.anomalie.description !== ''){
      this.anomalieService.postAnomalie(that.anomalie)
        .then((retour) => {
          that.dialogueShareService.addDialogue('le bug a bien été remonté');
          that.ngOnInit();
        });
    } else {
      that.dialogueShareService.addDialogue('la description et la cible doivent être remplis');
    }

  }
  categorieChange(){
    const that = this;
    this.categoriesAnomalie.forEach((categorie) => {
      if (categorie.id === that.selectedCatVal) {
        that.anomalie.categorie = categorie;
      }
    });
  }
}
