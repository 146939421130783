import {Component, Input, OnInit} from '@angular/core';
import {Contact} from '../../../../model/contact/Contact';
import {ActivatedRoute, Router} from '@angular/router';
import {ContactService} from '../../../../service/interne/contact/ContactService';
import {Langues} from '../../../../model/information/Langues';
import {Pays} from '../../../../model/information/Pays';
import {Fonction} from '../../../../model/contact/Fonction';
import {Genre} from '../../../../model/contact/Genre';
import {CategoriesGenerales} from '../../../../model/information/CategoriesGenerales';
import {LangueService} from '../../../../service/interne/contact/LangueService';
import {FonctionService} from '../../../../service/interne/contact/FonctionService';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {CiviliteService} from '../../../../service/interne/user/CiviliteService';
import {PaysService} from '../../../../service/interne/contact/PaysService';
import {Civilite} from '../../../../model/user/Civilite';
import {GenreService} from '../../../../service/interne/genre/GenreService';
import {CategGenShareService} from '../../../../service/partage/CategGenShareService';
import {SousCategoriesGenerales} from '../../../../model/information/SousCategoriesGenerales';
import {SousCategGenShareService} from '../../../../service/partage/SousCategGenShareService';
import {Coordonnees} from '../../../../model/information/Coordonnees';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {ReseauxSociauxService} from '../../../../service/interne/contact/ReseauxSociauxService';
import {ReseauxSociaux} from '../../../../model/contact/ReseauxSociaux';
import {CoordonneesService} from '../../../../service/interne/contact/CoordonneesService';
import {AuthShareService} from '../../../../service/partage/AuthShareService';

@Component({
  selector: 'app-escal-contact-modifier',
  templateUrl: './escal-contact-modifier.component.html',
  styleUrls: ['./escal-contact-modifier.component.scss'],
  providers: [ClientService, CiviliteService, GenreService, CategGenShareService, SousCategGenShareService, ReseauxSociauxService]
})
export class EscalContactModifierComponent implements OnInit {
  contact: Contact;
  private sub: any;
  fonctions: Array<Fonction> = [];
  genres: Array<Genre> = [];
  civilites: Array<Civilite>;
  listeLanguesBase: Array<Langues>;
  listePaysBase: Array<Pays>;
  champRechercheFonction = '';
  fonctionsIntranet: Array<any> = [];
  rechercheFonctionEncours = false;
  afficheFonction: boolean;
  ancienIDFoncSelec = 0;
  listeLanguesASauvegarder: Array<Langues> = [];
  idLanguesSelectionnees: Array<any>;
  idLanguesASauvegarder: Array<any>;
  langueSelectionner: Langues;
  idPaysSelectionnees: Array<any>;
  idPaysASauvegarder: Array<any>;
  paysSelectionner: Pays;
  listePaysASauvegarder: Array<Pays> = [];
  listeCategGenASauvegarder: Array<CategoriesGenerales> = [];
  listeCategGenChoix: Array<any> = [];
  champRechercheCategGen = '';
  rechercheCategGenEncours = false;
  categGenIntranet: Array<any> = [];
  idCategGenSelectionnees: Array<any>;
  idCategGenASauvegarder: Array<any>;
  categGenSelectionner: CategoriesGenerales;
  listeSousCategGenASauvegarder: Array<SousCategoriesGenerales> = [];
  listeSousCategGenChoix: Array<any> = [];
  champRechercheSousCategGen = '';
  rechercheSousCategGenEncours = false;
  sousCategGenIntranet: Array<any> = [];
  idSousCategGenSelectionnees: Array<any>;
  idSousCategGenASauvegarder: Array<any>;
  sousCategGenSelectionner: SousCategoriesGenerales;
  listePaysBaseCoordPerso: Array<Pays>;
  active = 'administratif';
  coordonneesPerso: Coordonnees;
  paysId: number;
  reseauxSociaux: ReseauxSociaux;
  creer: boolean;
  civ = 0;
  gen = 0;

  constructor(private route: ActivatedRoute, private contactService: ContactService,
              private langueService: LangueService, private fonctionService: FonctionService,
              private clientService: ClientService, private router: Router, private civiliteService: CiviliteService,
              private paysService: PaysService, private genreService: GenreService, private categGenShareService: CategGenShareService,
              private sousCategGenShareService: SousCategGenShareService, private dialogueShareService: DialogueShareService,
              private reseauxSociauxService: ReseauxSociauxService, private coordonneesService: CoordonneesService,
              private authShareService: AuthShareService) { }

  ngOnInit() {
    const that = this;
    this.afficheFonction = false;
    this.paysId = 0;
    this.creer = false;
    this.reseauxSociaux = new ReseauxSociaux(0, '', '', '');
    this.coordonneesPerso = new Coordonnees(0, '', '', '', '', '', '', '', '',
      new Pays(0, '', '',  '', null,  null, null), '', ''
      , this.authShareService.authUser, null, null, 0, null, null, null);
    this.contact = new Contact(0, null, '', '', '', false, false
      , '', '', null, null, null, null,
      new Civilite('', '', ''), new Fonction(0,  '', [], 0),
      this.coordonneesPerso
      , new Genre(0, '', [], []), [], [], [], [], [], [],
      [], [], [], new ReseauxSociaux(0, '', '' , ''),  []);
    this.langueService.getLangues().then(retour => {
      this.listeLanguesBase = retour['hydra:member'] as Array<Langues>;
    });
    this.paysService.getPays().then(retour => {
      this.listePaysBase = retour['hydra:member'] as Array<Pays>;
      this.listePaysBaseCoordPerso = retour['hydra:member'] as Array<Pays>;
    });
    this.civiliteService.getCivilites().then((retour) => {
      that.civilites = retour['hydra:member'];
    });
    this.fonctionService.getFonctions().then(retour => {
      this.fonctions = retour['hydra:member'] as Array<Fonction>;
    });
    this.genreService.getGenres().then(retour => {
      this.genres = retour['hydra:member'] as Array<Genre>;
    });
    this.sub = this.route.params.subscribe( params => {
      if (params.id !== 0) {
        this.contactService.getContactDetail(params.id).then(retour => {
        this.contact = retour as Contact;
        if (this.contact.coordonnees != null) {
          this.coordonneesService.getCoordonneesDetail(this.contact.coordonnees.id).then((coordonnes) => {
            this.coordonneesPerso = coordonnes as Coordonnees;
            if (this.coordonneesPerso != null) {
              if (this.coordonneesPerso.pays != null) {
                this.paysId = this.contact.coordonnees.pays.id;
              }
            }
          });
        }
        if (this.contact.reseauxSociaux != null) {
          this.reseauxSociauxService.getReseauxSociauxDetail(this.contact.reseauxSociaux.id).then(RS => {
            this.reseauxSociaux = RS as ReseauxSociaux;
          });
        }
        if (this.contact.fonction != null) {
          this.fonctionsIntranet.push(this.contact.fonction);
        }
        if (this.contact.langues != null && this.contact.langues.length > 0) {
          let nouvelleListeLangue;
          if (this.contact.langues.length > 0) {
            this.contact.langues.forEach(langue => {
              this.listeLanguesASauvegarder.push(langue);
            });
            this.listeLanguesASauvegarder.forEach(langueASauv => {
              nouvelleListeLangue = this.listeLanguesBase.filter((langue) => langue.id !== langueASauv.id);
              this.listeLanguesBase = nouvelleListeLangue;
            });
          }
        }
        if (this.contact.pays != null && this.contact.pays.length > 0) {
            let nouvelleListePays;
            if (this.contact.pays.length > 0) {
              this.contact.pays.forEach(pays => {
                this.listePaysASauvegarder.push(pays);
              });
              this.listePaysASauvegarder.forEach(paysASauv => {
                nouvelleListePays = this.listePaysBase.filter((pays) => pays.id !== paysASauv.id);
                this.listePaysBase = nouvelleListePays;
              });
            }
          }
        if (this.contact.categoriesGenerales != null) {
          this.contact.categoriesGenerales.forEach(categgen => {
            this.listeCategGenASauvegarder.push(categgen);
          });
        }
        if (this.contact.sousCategoriesGenerales != null) {
            this.contact.sousCategoriesGenerales.forEach(souscateggen => {
              this.listeSousCategGenASauvegarder.push(souscateggen);
            });
          }
        });
      }
    });
  }
  actived(type) {
    if (type === this.active) {
      return 'active';
    }
  }
  async ajoutFonction(idFonction) {
    let fonctSelec;
    let fonctionTrouve;
    // récupérer la fonction seléctionnée
    this.fonctionsIntranet.forEach(fonctionIntranet => {
      if (fonctionIntranet.ancienId == idFonction) {
        fonctSelec = fonctionIntranet as Fonction;
        return;
      }
    });
    // On vérifie si la fonction existe déjà sur la base escalnet
    await this.fonctionService.getFonctionsEscalnetbyAncienID(fonctSelec.ancienId).then(async retour => {
      fonctionTrouve = retour['hydra:member'];
      // si la fonction n'existe pas, alors on crée sur la base escalnet
      if (fonctionTrouve.length == 0) {
        await this.fonctionService.addFonction(fonctSelec).then();
      } else {
        this.contact.fonction = fonctionTrouve[0];
      }
    });
  }
  rechercheFonction() {
    this.afficheFonction = true;
    if (this.champRechercheFonction.length > 2 && !this.rechercheFonctionEncours) {
      this.rechercheFonctionEncours = true;
      this.champRechercheFonction = this.champRechercheFonction.replace(' ', '.');
      this.fonctionService.getFonctionsIntranet(this.champRechercheFonction).then((retour) => {
        this.fonctionsIntranet = retour;
        this.fonctionsIntranet.forEach(fonctionTrouve => {
          if (fonctionTrouve.libelle != '' && fonctionTrouve.libelle != null) {
            fonctionTrouve.libelle = fonctionTrouve.libelle.replace('&amp;', '&');
            fonctionTrouve.libelle = fonctionTrouve.libelle.replace('&#039;', '’');
            fonctionTrouve.libelle = fonctionTrouve.libelle.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheFonction = this.champRechercheFonction.replace('.', ' ');
      });
    }
    this.rechercheFonctionEncours = false;
  }
  setSelected(selectElement, action, nature) {
    const groupe: Array<any> = [];
    let optionSelected;
    for (let i = 0; i < selectElement.options.length; i++) {
      if (selectElement.options[i].selected) {
        optionSelected = selectElement.options[i].value;
        // Le format de value est de [0: '1']
        if (optionSelected.indexOf('\'') !== -1) {
          // extraire le vrai id sauvegarder
          optionSelected = optionSelected.indexOf('\'');
          optionSelected = selectElement.options[i].value.slice(optionSelected);
          optionSelected = this.extraitNombre(optionSelected);
          optionSelected = optionSelected.toString();
        }
        groupe.push(optionSelected);
      }
    }
    if (action === 'ajout') {
      switch (nature) {
        case 'langue':
          this.idLanguesSelectionnees = groupe;
          break;
        case 'pays':
           this.idPaysSelectionnees = groupe;
           break;
        case 'categgen':
           this.idCategGenSelectionnees = groupe;
           break;
        case 'souscateggen':
          this.idSousCategGenSelectionnees = groupe;
          break;
      }
    } else if (action === 'sup') {
      switch (nature) {
        case 'langue':
          this.idLanguesASauvegarder = groupe;
          break;
        case 'pays':
          this.idPaysASauvegarder = groupe;
          break;
        case 'categgen':
          this.idCategGenASauvegarder = groupe;
          break;
        case 'souscateggen':
          this.idSousCategGenASauvegarder = groupe;
          break;
      }
    }
  }
  extraitNombre(str) {
    return Number(str.replace(/\D/g, ''));
  }
  changeLangue(action) {
    const that = this;
    let nouvelleListeLangue;
    let listeLangue;
    let languePresent;
    if (action === 'add') {
      listeLangue = this.idLanguesSelectionnees;
    } else {
      listeLangue = this.idLanguesASauvegarder;
    }
    listeLangue.forEach((idLangueSelectionnees) => {
      that.langueService.getLangueByID(idLangueSelectionnees).then(retour => {
        that.langueSelectionner = retour as Langues;
        if (action === 'add') {
          // retirer la(es) langues sélectionnée(s) dans la liste de langue de base
          nouvelleListeLangue = this.listeLanguesBase.filter((langue) => langue.id !== that.langueSelectionner.id);
          this.listeLanguesBase = nouvelleListeLangue;
          // évite les doublons
          languePresent = this.listeLanguesASauvegarder.find((langue) => langue.id === that.langueSelectionner.id);
          if (languePresent === undefined) {
            this.listeLanguesASauvegarder.push(that.langueSelectionner);
          }
        } else if (action === 'delete') {
          // retirer la(es) langues sélectionnée(s) dans la liste de langue à sauvegarder
          nouvelleListeLangue = this.listeLanguesASauvegarder.filter((langue) => langue.id !== that.langueSelectionner.id);
          this.listeLanguesASauvegarder = nouvelleListeLangue;
          // évite les doublons
          languePresent = this.listeLanguesBase.find((langue) => langue.id === that.langueSelectionner.id);
          if (languePresent === undefined) {
            this.listeLanguesBase.push(that.langueSelectionner);
          }
        }
      });
    });
  }
  changePays(action) {
    const that = this;
    let nouvelleListePays;
    let listePays;
    let paysPresent;
    if (action === 'add') {
      listePays = this.idPaysSelectionnees;
    } else {
      listePays = this.idPaysASauvegarder;
    }
    listePays.forEach((idPaysSelectionnees) => {
      that.paysService.getPaysDetail(idPaysSelectionnees).then(retour => {
        that.paysSelectionner = retour as Pays;
        if (action === 'add') {
          // retirer la(es) pays sélectionnée(s) dans la liste de langue de base
          nouvelleListePays = this.listePaysBase.filter((pays) => pays.id !== that.paysSelectionner.id);
          this.listePaysBase = nouvelleListePays;
          // évite les doublons
          paysPresent = this.listePaysASauvegarder.find((pays) => pays.id === that.paysSelectionner.id);
          if (paysPresent === undefined) {
            this.listePaysASauvegarder.push(that.paysSelectionner);
          }
        } else if (action === 'delete') {
          // retirer la(es) pays sélectionnée(s) dans la liste de langue à sauvegarder
          nouvelleListePays = this.listePaysASauvegarder.filter((pays) => pays.id !== that.paysSelectionner.id);
          this.listePaysASauvegarder = nouvelleListePays;
          // évite les doublons
          paysPresent = this.listePaysBase.find((pays) => pays.id === that.paysSelectionner.id);
          if (paysPresent === undefined) {
            this.listePaysBase.push(that.paysSelectionner);
          }
        }
      });
    });
  }
  changeCategGen(action) {
    let listeCategGen;
    let categGenPresent;
    if (action === 'add') {
      listeCategGen = this.idCategGenSelectionnees;
    } else {
      listeCategGen = this.idCategGenASauvegarder;
    }
    // Gestion de la liste à sauvegarder et la liste de selection
    listeCategGen.forEach((idCategGenSelectionnees) => {
      this.categGenIntranet.forEach(categGen => {
        if (idCategGenSelectionnees == categGen.id) {
          this.categGenSelectionner = categGen as CategoriesGenerales;
          this.listeCategGenChoix =
            this.listeCategGenChoix.filter(categGenSave => categGenSave.id !== idCategGenSelectionnees);
        } else {
          // retirer la(es) categGen sélectionnée(s) dans la liste de categGen à sauvegarder
          this.listeCategGenASauvegarder =
            this.listeCategGenASauvegarder.filter(categGenSave => categGenSave.id !== idCategGenSelectionnees);
        }
      });
      if (action === 'add') {
        // retirer la(es) categGen sélectionnée(s) dans la liste de categGen de base
        // évite les doublons
        categGenPresent = this.listeCategGenASauvegarder.find((categGen) => categGen.id === this.categGenSelectionner.id);
        if (categGenPresent === undefined) {
          this.listeCategGenASauvegarder.push(this.categGenSelectionner);
        }
      } else if (action === 'delete') {
        // retirer la(es) liste contact sélectionnée(s) dans la liste de liste contact à sauvegarder
        this.listeCategGenASauvegarder =
          this.listeCategGenASauvegarder.filter(categGenSave => categGenSave.ancienId != idCategGenSelectionnees);
        }
    });
  }
  rechercheCategorieGenerale() {
    if (this.champRechercheCategGen.length > 2 && !this.rechercheCategGenEncours) {
      this.rechercheCategGenEncours = true;
      this.categGenShareService.getCategGenIntranet(this.champRechercheCategGen).then((retour) => {
        this.categGenIntranet = retour;
        this.listeCategGenChoix = retour;
        // éviter les doublons dans la liste de selection en cas d'une nouvelle recherche
        if (this.listeCategGenASauvegarder.length > 0) {
          this.listeCategGenASauvegarder.forEach(categGenASave => {
            // Il faut comparer avec l'ancien id
            if (categGenASave.ancienId) {
              this.listeCategGenChoix =
                this.listeCategGenChoix.filter(categGenChoix => categGenChoix.id != categGenASave.ancienId);
            } else {
              this.listeCategGenChoix =
                this.listeCategGenChoix.filter(categGenChoix => categGenChoix.id != categGenASave.id);
            }
          });
        }
      });
    }
    this.rechercheCategGenEncours = false;
  }
  changeSousCategGen(action) {
    let listeSousCategGen;
    let sousCategGenPresent;
    if (action === 'add') {
      listeSousCategGen = this.idSousCategGenSelectionnees;
    } else {
      listeSousCategGen = this.idSousCategGenASauvegarder;
    }
    // Gestion de la liste à sauvegarder et la liste de selection
    listeSousCategGen.forEach((idSousCategGenSelectionnees) => {
      this.sousCategGenIntranet.forEach(sousCategGen => {
        if (idSousCategGenSelectionnees == sousCategGen.id) {
          this.sousCategGenSelectionner = sousCategGen as SousCategoriesGenerales;
          this.listeSousCategGenChoix =
            this.listeSousCategGenChoix.filter(sousCategGenSave => sousCategGenSave.id !== idSousCategGenSelectionnees);
        } else {
          // retirer la(es) categGen sélectionnée(s) dans la liste de categGen à sauvegarder
          this.listeSousCategGenASauvegarder =
            this.listeSousCategGenASauvegarder.filter(sousCategGenSave => sousCategGenSave.id !== idSousCategGenSelectionnees);
        }
      });
      if (action === 'add') {
        // retirer la(es) categGen sélectionnée(s) dans la liste de categGen de base
        // évite les doublons
        sousCategGenPresent = this.listeSousCategGenASauvegarder.find((sousCategGen) =>
          sousCategGen.id === this.sousCategGenSelectionner.id);
        if (sousCategGenPresent === undefined) {
          this.listeSousCategGenASauvegarder.push(this.sousCategGenSelectionner);
        }
      } else if (action === 'delete') {
        // retirer la(es) categGen sélectionnée(s) dans la liste de categGen à sauvegarder
        this.listeSousCategGenASauvegarder =
          this.listeSousCategGenASauvegarder.filter(sousCategGenSave => sousCategGenSave.ancienId != idSousCategGenSelectionnees);
      }
    });
  }
  rechercheSousCategorieGenerale() {
    if (this.champRechercheSousCategGen.length > 2 && !this.rechercheSousCategGenEncours) {
      this.rechercheSousCategGenEncours = true;
      this.sousCategGenShareService.getSousCategGenIntranet(this.champRechercheSousCategGen).then((retour) => {
        this.sousCategGenIntranet = retour;
        this.listeSousCategGenChoix = retour;
        // éviter les doublons dans la liste de selection en cas d'une nouvelle recherche
        if (this.listeSousCategGenASauvegarder.length > 0) {
          this.listeSousCategGenASauvegarder.forEach(sousCategGenASave => {
            this.listeSousCategGenChoix =
              this.listeSousCategGenChoix.filter(sousCategGenChoix => sousCategGenChoix.id !== sousCategGenASave.id);
          });
        }
      });
    }
    this.rechercheSousCategGenEncours = false;
  }
  cancel() {
    this.router.navigate(['/escal/contact/' + this.contact.id]);
  }
  async ajoutCategGen(CategGen, contactID = null) {
    const that = this;
    let categGenTrouve;
    let catgeAncienId;
    // On vérifie si la catégorie générale existe déjà sur la base escalnet
    if (CategGen.ancienId != null) {
      console.log('ancien');
      catgeAncienId = CategGen.ancienId;
    } else {
      console.log('id');
      catgeAncienId = CategGen.id;
    }
    await this.categGenShareService.getCategGensbyAncienID(catgeAncienId).then(async retour => {
      categGenTrouve = retour['hydra:member'];
      // si la catégorie générale n'existe pas, alors on crée sur la base escalnet
      if (categGenTrouve.length == 0) {
        await that.categGenShareService.addCategGen(CategGen).then(async categGenCree => {
            await this.contactService.addContactCategGen(contactID, categGenCree.ancienId);
        });
      }
      this.contactService.addContactCategGen(contactID, catgeAncienId).then();
    });
  }

  async ajoutSousCategGen(sousCategGen, contactID = null) {
    const that = this;
    let sousCategGenTrouve;
    let sousCatgeAncienId;
    // On vérifie si la sous catégorie générale existe déjà sur la base escalnet
    if (sousCategGen.ancienId != null) {
      sousCatgeAncienId = sousCategGen.ancienId;
    } else {
      sousCatgeAncienId = sousCategGen.id;
    }
    await this.sousCategGenShareService.getSousCategGensbyAncienID(sousCatgeAncienId).then(async retour => {
      sousCategGenTrouve = retour['hydra:member'];
      // si la sous catégorie générale n'existe pas, alors on crée sur la base escalnet
      if (sousCategGenTrouve.length == 0) {
        await that.sousCategGenShareService.addSousCategGen(sousCategGen).then(async sousCategGenCree => {
          // Seulement dans le cas d'une mise à jour de contact
            await this.contactService.addContactSousCategGen(contactID, sousCategGenCree.ancienId);
        });
      }
      this.contactService.addContactSousCategGen(contactID, sousCatgeAncienId).then();
    });
  }
  async modifierContact() {
    let civ;
    if (this.contact.civilite != null) {
      civ = this.contact.civilite.id;
    } else {
      civ = 0;
    }
    // coordonnees Perso
    // si au moins un champ de coordonnées renseigné
    if (this.coordonneesPerso.premierTel != '' || this.coordonneesPerso.deuxiemeTel != ''
      || this.coordonneesPerso.email != '' || this.coordonneesPerso.rue != '' || this.coordonneesPerso.complements != ''
      || this.coordonneesPerso.boitePostale != '' || this.coordonneesPerso.codePostal != ''
      || this.coordonneesPerso.ville != '' || this.paysId != 0 || this.coordonneesPerso.premierURL != ''
      || this.coordonneesPerso.deuxiemeURL != '') {
      // si une coordonnee deja existante
      if (this.coordonneesPerso.id != 0) {
        await this.coordonneesService.modifierCoordonnees(this.coordonneesPerso.id, this.coordonneesPerso,
          this.contact.id, this.paysId).then();
      } else {
        // si pas encore de coordonnee
        await this.coordonneesService.addCoordonnees(this.coordonneesPerso).then(coord => {
            this.contact.coordonnees = coord;
        });
      }
    // Réseaux sociaux
      if (this.reseauxSociaux.facebookURL != '' || this.reseauxSociaux.twitterURL != ''
        || this.reseauxSociaux.linkedinURL) {
        if (this.reseauxSociaux.id != 0) {
          if (this.reseauxSociaux.facebookURL != '') {
            this.contact.reseauxSociaux.facebookURL = this.reseauxSociaux.facebookURL;
        }
          if (this.reseauxSociaux.twitterURL != '') {
            this.contact.reseauxSociaux.twitterURL = this.reseauxSociaux.twitterURL;
          }
          if (this.reseauxSociaux.linkedinURL != '') {
            this.contact.reseauxSociaux.linkedinURL = this.reseauxSociaux.linkedinURL;
          }
          await this.reseauxSociauxService.modifierResauxSociaux(this.contact.reseauxSociaux.id,
            this.contact.reseauxSociaux.linkedinURL, this.contact.reseauxSociaux.twitterURL,
            this.contact.reseauxSociaux.facebookURL)
            .then(reseauxSociaux => {
              this.contact.reseauxSociaux = reseauxSociaux;
            });
        } else {
          await this.reseauxSociauxService.addResauxSociaux(this.reseauxSociaux.linkedinURL,
            this.reseauxSociaux.twitterURL, this.reseauxSociaux.facebookURL)
            .then(reseauxSociaux => {
              this.contact.reseauxSociaux = reseauxSociaux;
            });
        }
      }
    }
    // Seulement si on sélectionne une fonction
    if (this.ancienIDFoncSelec > 0) {
      // Ajout d'une nouvelle fonction si jamais elle n'existe pas dans la base escalnet
      await this.ajoutFonction(this.ancienIDFoncSelec).then(async () => {
          await this.fonctionService.getFonctionsEscalnetbyAncienID(this.ancienIDFoncSelec).then(fonctionSelec => {
            this.contact.fonction = fonctionSelec['hydra:member'][0];
          });
      });

    }
    await this.contactService.modifierContact(this.contact.id, this.contact, civ).then(cont => {
      const contactModifier = cont as Contact;
      // Contact Pays Asso
      if (this.listePaysASauvegarder.length > 0) {
        this.contactService.deleteContactPays(contactModifier.id).then(() => {
          this.listePaysASauvegarder.forEach(idPays => {
            this.contactService.addContactPays(contactModifier.id, idPays.id).then();
          });
        });
      } else {
        this.contactService.deleteContactPays(contactModifier.id);
      }
      // Contact Langue Asso
      if (this.listeLanguesASauvegarder.length > 0) {
        this.contactService.deleteContactLangues(contactModifier.id).then(() => {
          this.listeLanguesASauvegarder.forEach(idLangue => {
            this.contactService.addContactLangues(contactModifier.id, idLangue.id).then();
          });
        });
      } else {
        this.contactService.deleteContactLangues(contactModifier.id);
      }
      // Contact Categorie asso
      if (this.listeCategGenASauvegarder.length > 0) {
        this.contactService.deleteContactCategGen(contactModifier.id).then(() => {
          this.listeCategGenASauvegarder.forEach(async categgen => {
             await this.ajoutCategGen(categgen, contactModifier.id).then();
          });
        });
      } else {
        this.contactService.deleteContactCategGen(contactModifier.id);
      }
      // Contact sous Categorie asso
      if (this.listeSousCategGenASauvegarder.length > 0) {
        // Sinon mettre à jour les assos via la selection dans le formulaire escalnet
        this.contactService.deleteContactSousCategGen(contactModifier.id).then(() => {
          this.listeSousCategGenASauvegarder.forEach(sousCateggen => {
            this.ajoutSousCategGen(sousCateggen, contactModifier.id).then();
          });
        });
      } else {
        this.contactService.deleteContactSousCategGen(contactModifier.id);
      }
      this.contactService.importerContactIntranet(this.contact.id, this.contact.ancienId).then(() => {
        // redirection la fiche du contact
      });
    });
    await this.contactService.importerContactIntranet(this.contact.id, this.contact.ancienId).then(() => {
      this.dialogueShareService.addDialogue('Le contact a bien été modifié');
      this.creer = true;
    });
  }
}
