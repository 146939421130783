import {Injectable} from '@angular/core';
import {AuthShareService} from '../AuthShareService';


import {Menu} from '../../../model/utilitaire/menu/Menu';
import {MenuService} from '../../interne/utilitaire/Menu.service';




@Injectable()
export class MenuShareService {

  menu: Menu;
  menuService: MenuService;
  indexMenuSelected: number;
  switchActive: string;
  sousMenuActive: string;
  linkActive: string;
  sousMenuActifActuel: string;
  authShareService: AuthShareService;
  menuOpen: boolean;

  constructor() {
  }

  public initMenu(menuService: MenuService){
    var that = this;
    this.menuService = menuService;
    this.menu = this.menuService.getMenuUserFake();
    if(!that.switchActive){
      that.indexMenuSelected = 0;
    } else{
      this.menu.SousMenus.forEach((sousMenu , index) => {
        if(sousMenu.path === that.switchActive) {
          that.indexMenuSelected = index;
        }})
    }
    if(!this.indexMenuSelected){
      this.indexMenuSelected = 0;
    }
  }

  getLenghtSousMenu(present){
    if(!present){
      return '0%';
    }
    var sousMenuLength: number = 0;
    var that = this;
    this.menu.SousMenus.forEach((sousmenu) => {
      if(that.authShareService.isGranted(sousmenu.role)){
        sousMenuLength ++;
      }
    });
    return 100 / sousMenuLength + '%' ;
  }
  updateIndexMenuSelected(index) {
    this.indexMenuSelected = index;
  }

  leftPosition(id) {
    var possition = this.indexMenuSelected + 250 * id - this.indexMenuSelected * 250;
    if (this.indexMenuSelected === undefined || id === this.indexMenuSelected) {
      return {'left': possition + 'px'};
    }
    return {'left': possition + 'px', 'display': 'none'};
  }
  checkPathActive(path) {
    return path === this.sousMenuActifActuel;
  }
}

