import { Component, OnInit } from '@angular/core';
import {StatistiqueIntranet} from '../../../../service/interne/utilitaire/statistique/statistiqueIntranet';
import {NgxSpinnerService} from 'ngx-spinner';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';

@Component({
  selector: 'app-statistique-intranet',
  templateUrl: './statistique-intranet.component.html',
  styleUrls: ['./statistique-intranet.component.scss'],
  providers: [ StatistiqueIntranet ]
})
export class StatistiqueIntranetComponent implements OnInit {

  startMoment: Date;
  dateDebut: Date;
  dateFin: Date;
  constructor(private statistiqueIntranet: StatistiqueIntranet, private spinner: NgxSpinnerService) { }

  ngOnInit() {

  }

  getStat(){
    let monthDeb = this.dateDebut.getMonth() + 1;
    let monthFin = this.dateFin.getMonth() + 1;
    this.spinner.show();
    this.statistiqueIntranet.getStatistiqueIntranet(this.dateDebut.getFullYear() + '-' + monthDeb + '-' + this.dateDebut.getDate() ,
      this.dateFin.getFullYear() + '-' + monthFin + '-' + this.dateFin.getDate())
      .then( (retour) => {
        this.spinner.hide();
      });
  }
}
