import {Contact} from './Contact';

export class Fonction {
  id: number;
  libelle: string;
  contacts: Array<Contact>;
  ancienId: number;

  constructor(id: number, libelle: string, contacts: Array<Contact>, ancienId: number) {
    this.id = id;
    this.libelle = libelle;
    this.contacts = contacts;
    this.ancienId = ancienId;
  }
}
