import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';


@Injectable()
export class SuivitIntranetService {
  private suivitIntranetUrl = Environement.BASE_API_URL + '/suivit/intranet/';
  private suiviIntranetUrl = Environement.BASE_API_URL + '/suivi/intranet/';
    constructor(private http: HttpClient) { }

  getSuivit() {
    const headers = this.initOptionHeader();
    return this.http.get(this.suivitIntranetUrl + 'type', {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getStatus() {
    const headers = this.initOptionHeader();
    return this.http.get(this.suivitIntranetUrl + 'status', {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getContactRecherche(recherche) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suivitIntranetUrl + 'contact/' + recherche, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getRecupererNom(contactId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suivitIntranetUrl + 'contact/recuperer/nom/' + contactId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getSuivisRecherche(recherche, datedeb, datefin, type, nature) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suivitIntranetUrl + 'suiviIntranet/' + recherche + '/' + datedeb + '/' + datefin + '/' + type + '/' + nature , {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getSuiviIntranet(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suivitIntranetUrl + 'suiviIntranet/fiche/' + id , {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getCalRedRecherche(champRechercheCalRed, idSociete, contactIDSelect) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suiviIntranetUrl + 'calRedsRecherche/' + champRechercheCalRed + '/' + idSociete + '/' + contactIDSelect,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
}

  getContactMedia(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suivitIntranetUrl + 'contact/media/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getEmailContact(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suivitIntranetUrl + 'contact/email/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getEmailContactMedia(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suivitIntranetUrl + 'contact/email/media/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getNumeroContact(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suivitIntranetUrl + 'contact/numero/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getNumeroContactMedia(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suivitIntranetUrl + 'contact/numero/media/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getCalendrierClient(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suivitIntranetUrl + 'calendrier/client/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getDefaultTypeDeRelation($arg) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suivitIntranetUrl + 'default/typederelation', {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getContactAssoPMO(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suivitIntranetUrl + 'contact/assopmo/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getContactCoordonnees(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suiviIntranetUrl + 'contact/informations/coordonnees/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getContactSupports(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suiviIntranetUrl + 'contact/informations/supports/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getContactSocietes(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suiviIntranetUrl + 'contact/informations/societes/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
      return Promise.reject(err.message || err );
    }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
