import {Component, Input, OnInit} from '@angular/core';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {ContactService} from '../../../../service/interne/contact/ContactService';
import {Contact} from '../../../../model/contact/Contact';
import {LangueService} from '../../../../service/interne/contact/LangueService';
import {Langues} from '../../../../model/information/Langues';
import {Pays} from '../../../../model/information/Pays';
import {PaysService} from '../../../../service/interne/contact/PaysService';
import {ListeContactService} from '../../../../service/interne/contact/ListeContactService';
import {ListeContact} from '../../../../model/liste/ListeContact';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-escal-contact-fiche-intranet',
  templateUrl: './escal-contact-fiche-intranet.component.html',
  styleUrls: ['./escal-contact-fiche-intranet.component.scss'],
  providers: [LangueService, PaysService, ListeContactService]
})
export class EscalContactFicheIntranetComponent implements OnInit {

  @Input()
  private sub: any;
  contact: any;
  contactID: number;
  afficher: string;
  dejaImporte: boolean;
  nbCaracteres: number;
  commentaires: string;
  langues: Array<Langues> = [];
  listePays: Array<Pays> = [];
  urlTempUn: string;
  urlTempDeux: string;
  nbCaracteresURL: number;
  reseauxSociaux: any;
  urlLinkedin: string;
  urlFacebook: string;
  urlTwitter: string;
  paysCoordonnees: Pays;
  suivisIDs: any;
  listesContactsAsso: any;
  supportsAsso: any;

  constructor(public formatIntranetShareService: FormatIntranetShareService, private contactService: ContactService,
              private route: ActivatedRoute, public dialogueShareService: DialogueShareService,
              private langueService: LangueService, private paysService: PaysService,
              private listeContactService: ListeContactService, private spinner: NgxSpinnerService, private router: Router) {
  }

  ngOnInit() {
    this.spinner.show();
    this.afficher = 'moins';
    this.nbCaracteres = 0;
    this.suivisIDs = [];
    this.listesContactsAsso = [];
    this.supportsAsso = [];
    this.sub = this.route.params.subscribe(params => {
      this.contactID = params['id'];
      this.contactService.getContactByAncienId(this.contactID).then(cont => {
        const checkCont = cont['hydra:member'] as Array<Contact>;
        if (checkCont.length > 0) {
          this.dejaImporte = true;
        } else {
          this.dejaImporte = false;
        }
      });
    });
    this.contactService.getAllInformationsContact(this.contactID).then(async contact => {
      this.contact = contact;
      if (this.contact != undefined) {
        // récupérer les id des suivis
        this.suivisIDs = this.contact.suivis;
        this.listesContactsAsso = this.contact.contact_liste_contact_asso;
        this.supportsAsso = this.contact.cont_supp_asso;
        // Langue
        if (this.contact.contact_langues_asso.length > 0) {
          for (const idLangue of this.contact.contact_langues_asso) {
            await this.langueService.getLangueByID(idLangue).then(retour => {
              const langue = retour as Langues;
              this.langues.push(langue);
            });
          }
        }
        // Pays
        if (this.contact.contact_pays_asso.length > 0) {
          for (const idPays of this.contact.contact_pays_asso) {
            await this.paysService.getPaysDetail(idPays).then(retour => {
              const pays = retour as Pays;
              this.listePays.push(pays);
            });
          }
        }
        // Pays coordonnées
        if (this.contact.ID_pays != null && this.contact.ID_pays != 0) {
          await this.paysService.getPaysDetail(this.contact.ID_pays).then(retour => {
            this.paysCoordonnees = retour as Pays;
          });
        }
        // Réseaux sociaux
        await this.contactService.getReseauxSociauxBy(this.contact.id).then(retour => {
          this.reseauxSociaux = retour;
          // adapter l'affichage de l'url linkedin si trop long
          if (this.reseauxSociaux.Linkedin != null) {
            this.nbCaracteresURL = this.reseauxSociaux.Linkedin.length;
            let i = 0;
            this.urlLinkedin = '';
            while (i < this.nbCaracteresURL) {
              this.urlLinkedin = this.urlLinkedin + this.reseauxSociaux.Linkedin.substr(i, 70) + ' ';
              i = i + 70;
            }
          }
          // adapter l'affichage de l'url twitter si trop long
          if (this.reseauxSociaux.Twitter != null) {
            this.nbCaracteresURL = this.reseauxSociaux.Twitter.length;
            let i = 0;
            this.urlTwitter = '';
            while (i < this.nbCaracteresURL) {
              this.urlTwitter = this.urlTwitter + this.reseauxSociaux.Twitter.substr(i, 70) + ' ';
              i = i + 70;
            }
          }
          // adapter l'affichage de l'url facebook si trop long
          if (this.reseauxSociaux.Facebook != null) {
            this.nbCaracteresURL = this.reseauxSociaux.Facebook.length;
            let i = 0;
            this.urlFacebook = '';
            while (i < this.nbCaracteresURL) {
              this.urlFacebook = this.urlFacebook + this.reseauxSociaux.Facebook.substr(i, 70) + ' ';
              i = i + 70;
            }
          }
        });
        this.contact.nom = this.contact.nom.replace('&amp;', '&');
        this.contact.nom = this.contact.nom.replace('&#039;', '’');
        this.contact.nom = this.contact.nom.replace('L&#039;', 'L’');
        this.contact.prenom = this.contact.prenom.replace('&amp;', '&');
        this.contact.prenom = this.contact.prenom.replace('&#039;', '’');
        this.contact.prenom = this.contact.prenom.replace('L&#039;', 'L’');
        if (this.contact.created != null && this.contact.created != '0000-00-00' && this.contact.created != '') {
          this.contact.created = new Date(this.contact.created);
        }
        if (this.contact.updated != null && this.contact.updated != '0000-00-00' && this.contact.updated != '') {
          this.contact.updated = new Date(this.contact.updated);
        }
        // gérer affichage commentaire
        this.nbCaracteres = this.contact.comments.length;
        this.commentaires = this.contact.comments.substr(0, 150);
        if (this.contact.ID_coord > 0) {
          // adapter l'affichage du premier lien si trop long
          if (this.contact.web1 != null) {
            this.nbCaracteresURL = this.contact.web1.length;
            let i = 0;
            this.urlTempUn = '';
            while (i < this.nbCaracteresURL) {
              this.urlTempUn = this.urlTempUn + this.contact.web1.substr(i, 70) + ' ';
              i = i + 70;
            }
          }
          // adapter l'affichage du deuxieme lien si trop long
          if (this.contact.web2 != null) {
            this.nbCaracteresURL = this.contact.web2.length;
            let i = 0;
            this.urlTempDeux = '';
            while (i < this.nbCaracteresURL) {
              this.urlTempDeux = this.urlTempDeux + this.contact.web2.substr(i, 70) + ' ';
              i = i + 70;
            }
          }
        }
      }
      this.spinner.hide();
    });
  }

  changerAffichage() {
    if (this.afficher === 'plus') {
      this.commentaires = this.contact.comments.substr(0, 150);
      this.afficher = 'moins';
    } else {
      this.commentaires = this.contact.comments;
      this.afficher = 'plus';
    }
  }

  importerContact() {
    this.spinner.show();
    // importer le contact
    this.contactService.importerContact(this.contactID).then(() => {
      // Importer dans la base escalnet les suivis associés existants sur intranet
     if (this.suivisIDs.length > 0) {
       for (const suiviId of this.suivisIDs) {
         this.contactService.importerSuiviIntranet(suiviId).then();
       }
     }
      // Importer dans la base escalnet les listes associées existantes sur intranet
     if (this.listesContactsAsso.length > 0) {
        for (const liste of this.listesContactsAsso) {
          this.contactService.importerListeContact(liste.ID_listecontact).then(() => {
            this.listeContactService.getListeContactEscalnetByAncienID(liste.ID_listecontact).then(retour => {
              const listeContactEscal = retour['hydra:member'][0] as ListeContact;
              this.contactService.getContactByAncienId(this.contactID).then(reponse => {
                const contact = reponse['hydra:member'][0] as Contact;
                this.contactService.addListeContactContact(listeContactEscal.id, contact.id, liste.ID_support_prefere).then();
              });
            });
          });
        }
      }
     // Importer dans la base escalnet les supports associés existants sur intranet
     if (this.supportsAsso.length > 0) {
        for (const support of this.supportsAsso) {
          this.contactService.importerSupport(support.id, this.contactID).then();
        }
      }
      // redirection
     this.spinner.hide();
     this.ngOnInit();
     this.dialogueShareService.addDialogue('Le contact a bien été importée');
    });
  }

  redirectionContact() {
    this.contactService.getContactByAncienId(this.contact.id).then(contact => {
      if (contact['hydra:member'][0] != null && contact['hydra:member'][0] != undefined) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree([`/escal/contact/${contact['hydra:member'][0].id}`])
        );
        window.open(url, '_blank');
      } else {
        const url = this.router.serializeUrl(
          this.router.createUrlTree([`/escal/contact/intranet/${this.contact.id}`])
        );
        window.open(url, '_blank');
      }
    });
  }
}
