import { Component, OnInit } from '@angular/core';
import {ArticleSite} from '../../../../model/site/ArticleSite';
import {ArticleSiteService} from '../../../../service/interne/Site/ArticleSiteService';
import {Router} from '@angular/router';

@Component({
  selector: 'app-site-escal-article-add',
  templateUrl: './site-escal-article-add.component.html',
  styleUrls: ['./site-escal-article-add.component.scss'],
  providers: [ ArticleSiteService ]
})
export class SiteEscalArticleAddComponent implements OnInit {

  article: ArticleSite = new ArticleSite(0, '' ,[], false, '', '#000000', [], [], false, null);
  constructor( private articleSiteService: ArticleSiteService, private _router: Router) { }

  ngOnInit() {

  }

  saveArticle(){
    const that = this;
    this.articleSiteService.addArticle(this.article)
      .then( (retour) => {
        this._router.navigate(['/site/article/liste']);
      });
  }

}
