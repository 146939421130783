import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';
@Injectable()
export class ListeContactService {
  private listeContactURL = Environement.BASE_API_URL + '/api/liste_contacts';
  private listeContactIntranetURL = Environement.BASE_API_URL + '/api/contacts/recherche/intranet/liste/';
  constructor(private http: HttpClient) {
  }
  getListeContactEscalnetByAncienID(listeContactAncienID) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/liste_contacts?ancienId=' + listeContactAncienID,
      {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addListeContact(listeContact) {
    const headers = this.initOptionHeader();
    return this.http.post(this.listeContactURL,
        {
          nom: listeContact.nom,
          commentaires: listeContact.commentaires,
          creation: listeContact.creation,
          modification: listeContact.modification,
          // auteur: listeContact.auteur,
          type: 'api/type_listes/' + parseInt(listeContact.typeListe, 10),
          ancienId: listeContact.ancienId,
        },
      {headers}).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
  }
  getListeContactIntranet(recherche) {
    const headers = this.initOptionHeader();
    return this.http.get(this.listeContactIntranetURL + recherche, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
