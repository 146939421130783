import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SuiviActionShareService} from '../../../../service/partage/SuiviActionShareService';
import {Suivi} from '../../../../model/suivi/Suivi';
import {SuiviNature} from '../../../../model/suivi/SuiviNature';
import {ClientMailingCalendrierRedactionnel} from '../../../../model/client/ClientMailingCalendrierRedactionnel';
import {SuivitIntranetService} from '../../../../service/interne/suivit/SuivitIntranetService';
import {Support} from '../../../../model/support/Support';
import {ContactSupport} from '../../../../model/contact/ContactSupport';
import {Client} from '../../../../model/client/Client';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {Mailling} from '../../../../model/mailling/Mailling';
import {MaillingService} from '../../../../service/interne/mailling/maillingService';
import {CalendrierRedactionnel} from '../../../../model/client/CalendrierRedactionnel';
import {TypeRelationSuivi} from '../../../../model/suivi/TypeRelationSuivi';
import {TypeDeRelationService} from '../../../../service/interne/SuiviAction/typeDeRelationService';
import {NatureTypeRelationSuivi} from '../../../../model/suivi/NatureTypeRelationSuivi';
import {NatureVecteur} from '../../../../model/suivi/NatureVecteur';
import {NatureSuivi} from '../../../../model/suivi/NatureSuivi';
import {VecteurSuivi} from '../../../../model/suivi/VecteurSuivi';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {ContactOrganisation} from '../../../../model/contact/ContactOrganisation';
import {Contact} from '../../../../model/contact/Contact';
import {AuthUser} from '../../../../model/user/authUser';
import {AuthUserService} from '../../../../service/interne/user/AuthUserService';
import {Statut} from '../../../../model/suivi/Statut';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {AuthShareService} from '../../../../service/partage/AuthShareService';
import {SupportService} from '../../../../service/interne/support/SupportService';
import {ContactService} from '../../../../service/interne/contact/ContactService';
import {EvenementMetier} from '../../../../model/evenementMetier/EvenementMetier';
import {EvenementMetierService} from '../../../../service/interne/evenementMetier/EvenementMetierService';

@Component({
  selector: 'app-escal-suivi-modifier',
  templateUrl: './escal-suivi-modifier.component.html',
  styleUrls: ['./escal-suivi-modifier.component.scss'],
  providers: [SuivitIntranetService, ClientService, MaillingService, TypeDeRelationService, AuthUserService, SupportService,
    ContactService, EvenementMetierService],
})
export class EscalSuiviModifierComponent implements OnInit {

  id: number;
  private sub: any;
  contactIDSelect: number;
  suivi: Suivi;
  suiviNature: SuiviNature;
  premiereAsso: ClientMailingCalendrierRedactionnel;
  deuxiemeAsso: ClientMailingCalendrierRedactionnel;
  contactNom: string;
  userSuiviPar: string;
  contact: Contact;
  supports: Array<Support>;
  contactSupports: Array<ContactSupport>;
  mediaSuiviIds: Array<any>;
  aParaitre: boolean;
  premiereSocieteSuivie: number;
  deuxiemeSocieteSuivie: number;
  clients: Array<Client>;
  actif = '';
  calRedUn: number;
  calRedDeux: number;
  afficheCalUn: boolean;
  afficheCalDeux: boolean;
  calRed: CalendrierRedactionnel;
  calRedBis: CalendrierRedactionnel;
  calRedsRechercheUn: Array<any> = [];
  calRedsRechercheDeux: Array<any> = [];
  champRechercheCalRedUn: string;
  champRechercheCalRedDeux: string;
  articleUn: string;
  articleDeux: string;
  communiquesUn: Array<Mailling>;
  communiquesDeux: Array<Mailling>;
  communiqueUn: number;
  communiqueDeux: number;
  rechercheCommEncours = false;
  rechercheEncoursCalRed = false;
  client: Client;
  calRedsRecherche: Array<any> = [];
  typeRelationIDSelect: number;
  natureActionSuiviIDSelect: number;
  vecteurIDSelect: number;
  niveauIDSelect: number;
  niveauMutu: string;
  typeDeRelations: Array<TypeRelationSuivi> = [];
  natureTypeRelations: Array<NatureTypeRelationSuivi>;
  natureVecteur: Array<NatureVecteur>;
  naturesSelect: Array<NatureSuivi> = [];
  vecteursSelect: Array<VecteurSuivi> = [];
  nature: NatureSuivi;
  niveauxDesSuivis: Array<any> = [];
  sujet = '';
  axe = '';
  interesse = '';
  theme = '';
  interlocuteur = '';
  commentaireVeille = '';
  note = '';
  raison = '';
  opportunite: number;
  suiviPar: number;
  emailSelect: string;
  telSelect: string;
  numerosSelect: Array<any> = [];
  emailsSelect: Array<any> = [];
  contactOrganisations: Array<ContactOrganisation>;
  users: Array<AuthUser> = [];
  statutId: number;
  statuts: Array<Statut>;
  mutuPar: number;
  mutualisation: boolean;
  dateitw: Date;
  verifCoord: boolean;
  verifComplements: boolean;
  verifMutu: boolean;
  tempDeuxiemeSoc: number;
  evenements: Array<any> = [];
  evenementId: number;
  evenementNom: string;
  evenement: EvenementMetier;
  constructor(private router: Router, private route: ActivatedRoute, public  suiviActionShareService: SuiviActionShareService,
              private suivitIntranetService: SuivitIntranetService, private clientService: ClientService,
              private maillingService: MaillingService, private typeDeRelationService: TypeDeRelationService,
              public formatIntranetShareService: FormatIntranetShareService, private authUserService: AuthUserService,
              private dialogueShareService: DialogueShareService, private authShareService: AuthShareService,
              private supportService: SupportService, private contactService: ContactService,
              private evenementMetierService: EvenementMetierService) { }

  ngOnInit() {
    const that = this;
    this.evenementId = 0;
    this.evenement = null;
    this.evenementNom = '';
    this.actif = 'premiere';
    this.afficheCalUn = false;
    this.afficheCalDeux = false;
    that.calRedUn = 0;
    that.calRedDeux = 0;
    that.communiqueDeux = 0;
    that.communiqueUn = 0;
    that.typeRelationIDSelect = 0;
    that.supports = [];
    that.articleUn = '';
    that.articleDeux = '';
    that.niveauMutu = '1';
    this.champRechercheCalRedUn = '';
    this.champRechercheCalRedDeux = '';
    this.note = '';
    this.emailSelect = '';
    this.telSelect = '';
    that.mutuPar = 0;
    that.mutualisation = false;
    this.verifCoord = false;
    this.verifComplements = false;
    this.verifMutu = false;
    this.sub = this.route.params.subscribe(params => {
      that.id = +params['id'];
      if (that.id !== 0) {
        this.suiviActionShareService.getSuiviDetails(that.id).then(suivi => {
          that.suivi = suivi as Suivi;
          that.aParaitre = that.suivi.aParaitre;
          that.note = that.suivi.commentaires;
          that.niveauIDSelect = that.suivi.niveau;
          that.typeRelationIDSelect = that.suivi.typeRelation.id;
          that.emailSelect = that.suivi.email;
          that.telSelect = that.suivi.telephone;
          that.suiviPar = that.suivi.suiviPar.id;
          that.statutId = that.suivi.statut.id;
          if (that.suivi.mutualisation) {
            that.mutuPar = that.suivi.mutualisation.id;
            that.mutualisation = true;
            that.niveauMutu = that.suivi.niveauMutualisation;
          }
          if (that.suivi.evenement) {
            that.evenementId = that.suivi.evenement.ancienId;
          }
          that.suivi.suiviNatures.forEach(suiviNature => {
            that.suiviNature = suiviNature;
          });
          that.axe = that.suiviNature.axe;
          that.interesse = that.suiviNature.interesse;
          that.sujet = that.suiviNature.sujet;
          if (that.suiviNature.opportunitesClient) {
            that.opportunite = that.suiviNature.opportunitesClient.id;
          } else {
            that.opportunite = 0;
          }
          that.raison = that.suiviNature.raison;
          that.theme = that.suiviNature.themeItw;
          that.interlocuteur = that.suiviNature.interlocuteur;
          that.dateitw = that.suiviNature.dateItw;
          that.commentaireVeille = that.suiviNature.commentaire;
          that.suivi.clientMailingCalendrierRedactionnels.reverse();
          that.premiereAsso = that.suivi.clientMailingCalendrierRedactionnels[0];
          that.premiereSocieteSuivie = that.premiereAsso.client.id;
          if (that.premiereAsso.calendrierRedactionnel) {
            that.calRedUn = that.premiereAsso.calendrierRedactionnel.id;
            this.suiviActionShareService.getCalRedDetails(that.calRedUn).then((calRed) => {
              this.calRed = calRed as CalendrierRedactionnel;
              this.calRedsRechercheUn.push(this.calRed);
              this.calRedsRechercheUn.forEach(cal => {
                cal.dossier = cal.dossier.replace('&#039;', '’');
              });
            });
          }
          if (that.premiereAsso.mailing) {
            that.communiqueUn = that.premiereAsso.mailing.id;
          }
          that.articleUn = that.premiereAsso.article;
          // deuxieme asso pour deuxieme societe
          that.deuxiemeAsso = that.suivi.clientMailingCalendrierRedactionnels[1];
          if (this.deuxiemeAsso) {
            that.deuxiemeSocieteSuivie = that.deuxiemeAsso.client.id;
            that.tempDeuxiemeSoc = that.deuxiemeAsso.client.id;
          } else {
            that.deuxiemeSocieteSuivie = 0;
            that.tempDeuxiemeSoc = 0;
          }
          if (that.deuxiemeAsso) {
            if (that.deuxiemeAsso.mailing) {
              that.communiqueDeux = that.deuxiemeAsso.mailing.id;
            }
          }
          if (that.deuxiemeAsso) {
            that.articleDeux = that.deuxiemeAsso.article;
          }
          if (that.deuxiemeAsso) {
            if (that.deuxiemeAsso.calendrierRedactionnel) {
              that.calRedDeux = that.deuxiemeAsso.calendrierRedactionnel.id;
              this.suiviActionShareService.getCalRedDetails(that.calRedDeux).then((calRedDeux) => {
                this.calRedBis = calRedDeux as CalendrierRedactionnel;
                this.calRedsRechercheDeux.push(this.calRedBis);
                this.calRedsRechercheDeux.forEach(calbis => {
                  calbis.dossier = calbis.dossier.replace('&#039;', '’');
                });
              });
            }
          }
          that.contactNom = that.suivi.contact.prenom + ' ' + that.suivi.contact.nom;
          that.userSuiviPar = that.suivi.suiviPar.user.prenom + ' ' + that.suivi.suiviPar.user.nom;
          that.contactIDSelect = that.suivi.contact.ancienId;
          this.suivitIntranetService.getContactCoordonnees(this.contactIDSelect).then((contCoor) => {
            this.contact = contCoor;
            // recupérer coordonnees perso du contact
            if (this.contact.coordonnees.premierTel !== '' && this.contact.coordonnees.premierTel !== null) {
              this.ajouterNumero(this.contact.coordonnees.premierTel);
            }
            if (this.contact.coordonnees.deuxiemeTel !== '' && this.contact.coordonnees.deuxiemeTel !== null) {
              this.ajouterNumero(this.contact.coordonnees.deuxiemeTel);
            }
            if (this.contact.coordonnees.email !== '' && this.contact.coordonnees.email !== null) {
              this.ajouterEmail(this.contact.coordonnees.email);
            }
            // recupérer coordonnees supports du contact
            this.suivitIntranetService.getContactSupports(this.contactIDSelect).then((contSupp) => {
              this.contactSupports = contSupp;
              this.contactSupports.forEach(contactSupport => {
                // récupérer nom des supports
                if (contactSupport.support.nom !== '' && contactSupport.support.nom !== null) {
                  contactSupport.support.nom = contactSupport.support.nom.replace('&amp;', '&');
                  contactSupport.support.nom = contactSupport.support.nom.replace('&#039;', '’');
                  contactSupport.support.nom = contactSupport.support.nom.replace('L&#039;', 'L’');
                  this.supports.push(contactSupport.support);
                }
                if (contactSupport.premierTel !== '' && contactSupport.premierTel !== null) {
                  this.ajouterNumero(contactSupport.premierTel);
                }
                if (contactSupport.deuxiemeTel !== '' && contactSupport.deuxiemeTel !== null) {
                  this.ajouterNumero(contactSupport.deuxiemeTel);
                }
                if (contactSupport.email !== '' && contactSupport.email !== null) {
                  this.ajouterEmail(contactSupport.email);
                }
                if (contactSupport.support.coordonnees.premierTel !== '' && contactSupport.support.coordonnees.premierTel !== null) {
                  this.ajouterNumero(contactSupport.support.coordonnees.premierTel);
                }
                if (contactSupport.support.coordonnees.email !== '' && contactSupport.support.coordonnees.email !== null) {
                  this.ajouterEmail(contactSupport.support.coordonnees.email);
                }
                if (contactSupport.support.telStandard !== '' && contactSupport.support.telStandard !== null) {
                  this.ajouterNumero(contactSupport.support.telStandard);
                }
                if (contactSupport.support.telPublicite !== '' && contactSupport.support.telPublicite !== null) {
                  this.ajouterNumero(contactSupport.support.telPublicite);
                }
              });
              // recupérer coordonnees pro du contact
              this.suivitIntranetService.getContactSocietes(this.contactIDSelect).then((contSoc) => {
                this.contactOrganisations = contSoc;
                this.contactOrganisations.forEach(contactOrganisation => {
                  if (contactOrganisation.premierTel !== '' && contactOrganisation.premierTel !== null) {
                    this.ajouterNumero(contactOrganisation.premierTel);
                  }
                  if (contactOrganisation.deuxiemeTel !== '' && contactOrganisation.deuxiemeTel !== null) {
                    this.ajouterNumero(contactOrganisation.deuxiemeTel);
                  }
                  if (contactOrganisation.email !== '' && contactOrganisation.email !== null) {
                    this.ajouterEmail(contactOrganisation.email);
                  }
                });
              });
            });
          });
          // recupérer coordonnees pro du contact
          this.suivitIntranetService.getContactSocietes(that.suivi.contact.ancienId).then((contSoc) => {
            this.contactOrganisations = contSoc;
            this.contactOrganisations.forEach(contactOrganisation => {
              if (contactOrganisation.premierTel !== '' && contactOrganisation.premierTel !== null) {
                this.ajouterNumero(contactOrganisation.premierTel);
              }
              if (contactOrganisation.deuxiemeTel !== '' && contactOrganisation.deuxiemeTel !== null) {
                this.ajouterNumero(contactOrganisation.deuxiemeTel);
              }
              if (contactOrganisation.email !== '' && contactOrganisation.email !== null) {
                this.ajouterEmail(contactOrganisation.email);
              }
            });
          });
          this.maillingService.getMaillingListClient(this.premiereSocieteSuivie).then((communiques) => {
            this.communiquesUn = communiques['hydra:member'] as Array<Mailling>;
            this.communiquesUn.forEach(comm => {
              // récupérer nom des supports
              if (comm.nom !== '' && comm.nom !== null) {
                comm.nom = comm.nom.replace('&#039;', '’');
              }
            });
          });
          if (that.deuxiemeAsso) {
            this.maillingService.getMaillingListClient(this.deuxiemeSocieteSuivie).then((communiquesDeux) => {
              this.communiquesDeux = communiquesDeux['hydra:member'] as Array<Mailling>;
              this.communiquesDeux.forEach(commDeux => {
                // récupérer nom des supports
                if (commDeux.nom !== '' && commDeux.nom !== null) {
                  commDeux.nom = commDeux.nom.replace('&#039;', '’');
                }
              });
            });
          }
          // valeurs par defaut type relaiton
          that.typeRelationIDSelect = this.suivi.typeRelation.id;
          // valeurs par defaut nature
          that.natureActionSuiviIDSelect = this.suiviNature.nature.id;
          // valeurs par defaut vecteur
          that.vecteurIDSelect = this.suivi.vecteur.id;
          // recuperer autres type relations
          this.typeDeRelationService.getTypeRelation().then((retour) => {
            that.typeDeRelations = retour['hydra:member'] as Array<TypeRelationSuivi>;
            this.typeDeRelations.forEach((typeDeRelation) => {
              // Relation presse par défaut
              if (typeDeRelation.id === that.typeRelationIDSelect) {
                // recuperer autres natures
                that.natureTypeRelations = typeDeRelation.natureTypeRelationSuivi;
                this.natureTypeRelations.forEach((naturesType) => {
                  if (naturesType.natureSuivi.id === that.natureActionSuiviIDSelect) {
                    that.nature = naturesType.natureSuivi;
                    that.natureVecteur = this.nature.natureVecteurSuivi;
                    // recuperer autres vecteurs
                    that.natureVecteur.forEach((naturesVecteur) => {
                      that.vecteursSelect.push(naturesVecteur.vecteurSuivi);
                    });
                  }
                  that.naturesSelect.push(naturesType.natureSuivi);
                });
              }
            });
          });
          that.mediaSuiviIds = [];
          // récupérer supports du suivi
          that.suivi.supports.forEach(support => {
            that.mediaSuiviIds.push(support.ancienId.toString());
          });
          let idSociete = this.premiereSocieteSuivie;
          this.clientService.getClientDetail(idSociete).then((client) => {
            this.client = client as Client;
            idSociete = this.client.ancienId;
            this.suivitIntranetService.getEvenements(idSociete).then((retour) => {
              this.evenements = retour;
              this.evenements.forEach(event => {
                if (event.nom != '' && event.nom != null) {
                  // remplacer caractères reçus de l'api
                  event.nom = event.nom.replace('&amp;', '&');
                  event.nom = event.nom.replace('&#039;', '’');
                  event.nom = event.nom.replace('L&#039;', 'L’');
                }
              });
            });
          });
        });
      }
      // récupérer liste des clients actuels
      this.clientService.getClientList(1)
        .then((clients) => {
          that.clients = clients['hydra:member'] as Array<Client>;
        });
      // récupérer liste des utilisateurs actuels
      this.authUserService.getUsersList(1).then((usersRetour) => {
        that.users = usersRetour['hydra:member'] as Array<AuthUser>;
      });
      // récupérer liste des statuts du suivi
      this.suiviActionShareService.getStatuts().then((statuts) => {
        that.statuts = statuts['hydra:member'] as Array<Statut>;
      });
      // liste des niveaux d'un suivi
      this.niveauxDesSuivis.push(1, 2, 3, 4, 5, 6, 7);
    });
  }

  active(actif) {
    if (this.actif !== actif) {
      this.actif = actif;
    }
  }

  changePremiereSociete() {
    this.calRedsRechercheUn = [];
    this.champRechercheCalRedUn = '';
    this.afficheCalUn = false;
    this.calRedUn = 0;
    this.communiqueUn = 0;
    this.articleUn = '';
    this.maillingService.getMaillingListClient(this.premiereSocieteSuivie).then((communiques) => {
      this.communiquesUn = communiques['hydra:member'] as Array<Mailling>;
      this.communiquesUn.forEach(comm => {
        // récupérer nom des com
        if (comm.nom !== '' && comm.nom !== null) {
          comm.nom = comm.nom.replace('&#039;', '’');
        }
      });
    });
    let idSociete = this.premiereSocieteSuivie;
    this.clientService.getClientDetail(idSociete).then((client) => {
      this.client = client as Client;
      idSociete = this.client.ancienId;
      this.suivitIntranetService.getEvenements(idSociete).then((retour) => {
        this.evenements = retour;
        this.evenements.forEach(event => {
          if (event.nom != '' && event.nom != null) {
            // remplacer caractères reçus de l'api
            event.nom = event.nom.replace('&amp;', '&');
            event.nom = event.nom.replace('&#039;', '’');
            event.nom = event.nom.replace('L&#039;', 'L’');
          }
        });
      });
    });
  }
  changeDeuxiemeSociete() {
    this.calRedsRechercheDeux = [];
    this.champRechercheCalRedDeux = '';
    this.afficheCalDeux = false;
    this.calRedDeux = 0;
    this.articleDeux = '';
    this.communiqueDeux = 0;
    this.maillingService.getMaillingListClient(this.deuxiemeSocieteSuivie).then((communiquesDeux) => {
      this.communiquesDeux = communiquesDeux['hydra:member'] as Array<Mailling>;
      this.communiquesDeux.forEach(comm => {
        // récupérer nom des com
        if (comm.nom !== '' && comm.nom !== null) {
          comm.nom = comm.nom.replace('&#039;', '’');
        }
      });
    });
  }


  changeEvenement() {
    const evenementChoisi = this.evenements.find((event) => event.id === this.evenementId);
    if (evenementChoisi != undefined) {
      this.evenementNom = evenementChoisi.nom;
      this.evenementNom = this.evenementNom.replace('&amp;', '&');
      this.evenementNom = this.evenementNom.replace('&#039;', '’');
      this.evenementNom = this.evenementNom.replace('L&#039;', 'L’');
      this.evenement = new EvenementMetier(null, this.evenementNom, Number(this.evenementId), []);
      // si pas déjà dans la base
      this.evenementMetierService.getEvenementMetierByAncienId(this.evenementId).then(eventExiste => {
        if (eventExiste['hydra:member'][0] === null || eventExiste['hydra:member'][0] === undefined) {
          this.evenementMetierService.addEvenementMetier(this.evenement).then(event => {
            this.evenement = event as EvenementMetier;
          });
        } else {
          this.evenement = eventExiste['hydra:member'][0];
        }
      });
    }
  }

  rechercheCalRed(soc) {
    let idSociete = 0;
    let recherche = '';
    if (soc === 'premiere') {
      idSociete = this.premiereSocieteSuivie;
      recherche = this.champRechercheCalRedUn;
      this.afficheCalUn = true;
    } else {
      idSociete = this.deuxiemeSocieteSuivie;
      recherche = this.champRechercheCalRedDeux;
      this.afficheCalDeux = true;
    }
    if (recherche.length > 2 && !this.rechercheEncoursCalRed) {
      this.rechercheEncoursCalRed = true;
      this.clientService.getClientDetail(idSociete).then((client) => {
        this.client = client as Client;
        idSociete = this.client.ancienId;
        recherche = recherche.replace(' ', '.');
        this.suivitIntranetService.getCalRedRecherche(recherche, idSociete, this.contactIDSelect).then((calReds) => {
          this.calRedsRecherche = calReds as Array<CalendrierRedactionnel>;
          this.calRedsRecherche.forEach(cal => {
            cal.dossier = cal.dossier.replace('&#039;', '’');
          });
          if (soc === 'premiere') {
            this.calRedsRechercheUn = this.calRedsRecherche;
          } else {
            this.calRedsRechercheDeux = this.calRedsRecherche;
          }
        });
      });
      this.rechercheEncoursCalRed = false;
    }
  }


  resetVecteur() {
    // on reset toutes les informations d action suite à
    this.axe = '';
    this.interesse = '';
    this.sujet = '';
    this.theme = '';
    this.interlocuteur = '';
    this.raison = '';
    this.commentaireVeille = '';
    this.opportunite = 0;
    // changer vecteur associes
    const that = this;
    this.naturesSelect.forEach((natures) => {
      if (natures.id == this.natureActionSuiviIDSelect) {
        that.vecteursSelect = [];
        that.natureVecteur = natures.natureVecteurSuivi;
        that.natureVecteur.forEach((naturesVecteur) => {
          if (that.vecteursSelect.length < 1) {
            that.vecteurIDSelect = naturesVecteur.vecteurSuivi.id;
          }
          that.vecteursSelect.push(naturesVecteur.vecteurSuivi);
        });
      }
    });
  }


  resetNatureSuiviAction() {
    const that = this;
    this.typeDeRelations.forEach((typeDeRelation) => {
      // Relation presse par défaut
      if (typeDeRelation.id == this.typeRelationIDSelect) {
        that.naturesSelect = [];
        that.suivi.typeRelation = typeDeRelation;
        that.typeRelationIDSelect = typeDeRelation.id;
        that.natureTypeRelations = typeDeRelation.natureTypeRelationSuivi;
        this.natureTypeRelations.forEach((naturesType) => {
          that.naturesSelect.push(naturesType.natureSuivi);
          // nature par defaut
          if (that.naturesSelect.length <= 1) {
            that.natureActionSuiviIDSelect = naturesType.natureSuivi.id;
            // vecteur associes
            this.naturesSelect.forEach((natures) => {
              if (natures.id == this.natureActionSuiviIDSelect) {
                that.vecteursSelect = [];
                that.natureVecteur = natures.natureVecteurSuivi;
                that.natureVecteur.forEach((naturesVecteur) => {
                  if (that.vecteursSelect.length < 1) {
                    that.vecteurIDSelect = naturesVecteur.vecteurSuivi.id;
                  }
                  that.vecteursSelect.push(naturesVecteur.vecteurSuivi);
                });
              }
            });
          }
        });
      }
    });
  }

  ajouterNumero(num) {
    // dédoublonner les numéros de téléphone
    let trouve = false;
    this.numerosSelect.forEach(numero => {
      if (numero === num) {
        trouve = true;
      }
    });
    if (!trouve) {
      // si pas déjà présent, l'ajouter à la liste des numéros
      this.numerosSelect.push(num);
    }
  }

  ajouterEmail(email) {
    // dédoublonner les numéros de téléphone
    let trouve = false;
    this.emailsSelect.forEach(adresse => {
      if (adresse === email) {
        trouve = true;
      }
    });
    if (!trouve) {
      // si pas déjà présent, l'ajouter à la liste des numéros
      this.emailsSelect.push(email);
    }
  }



  modifierSuivi() {
    const that = this;
    // vérifier les données obligatoires saisies
    if (this.suivi.titre !== '' && this.suivi.titre !== null) {
      if (this.contactIDSelect !== 0) {
          if (this.premiereSocieteSuivie !== 0) {
            // verifier si email et numero de tel saisis en fonction du choix du vecteur
            this.verifCoordVecteur();
            if (this.verifCoord === true) {
              // verifier si compléments bien saisis en fonction de la nature du suivi
              this.verifComplementsNature();
              if (this.verifComplements === true) {
                if (this.suiviPar !== 0) {
                  // verifier si mutualisation bien saisie
                  this.verifMutualisation();
                  if (this.verifMutu === true) {
                    let mediaIdsSelect = '';
                    // envoie les id des médias à l'api sous forme de chaine de caractère
                    this.mediaSuiviIds.forEach( mediaId => {
                      mediaIdsSelect = mediaIdsSelect + mediaId + ',';
                    });
                    // récupérer l'utilisateur qui suit le suivi
                    this.authUserService.getUser(this.suiviPar)
                      .then((userSuiviPar) => {
                        this.suivi.suiviPar = userSuiviPar as AuthUser;
                        // récupérer le statut du suivi
                        this.suiviActionShareService.getStatut(this.statutId)
                          .then((statut) => {
                            this.suivi.statut = statut as Statut;
                            // récupérer le vecteur du suivi
                            this.suiviActionShareService.getVecteur(this.vecteurIDSelect)
                              .then((vecteur) => {
                                this.suivi.vecteur = vecteur as VecteurSuivi;
                                // supprimer la nature actuelle du suivi pour la remplacer
                                this.suivi.suiviNatures = [];
                                // récupérer la nature du suivi
                                this.suiviActionShareService.getNature(this.natureActionSuiviIDSelect)
                                  .then((nature) => {
                                    this.suiviNature.nature = nature as NatureSuivi;
                                    // ajouter l'asso au suivi
                                    this.suivi.suiviNatures.push(this.suiviNature);
                                    // compléter infos du suivi
                                    this.suivi.commentaires = this.note;
                                    this.suivi.niveau = Number(this.niveauIDSelect);
                                    this.suivi.aParaitre = this.aParaitre;
                                    this.suivi.email = this.emailSelect;
                                    this.suivi.telephone = this.telSelect;
                                    this.suivi.auteur = this.authShareService.authUser;
                                    // gérer le cas sans supports
                                    if (mediaIdsSelect == '') {
                                      mediaIdsSelect = ',';
                                    }
                                    // on importe de intranet vers escalnet : contact, organisation, support, calred, etc...
                                    this.suiviActionShareService.importerTout(this.contactIDSelect, this.calRedUn, this.calRedDeux,
                                      mediaIdsSelect).then((retour) => {
                                      const $tab = retour;
                                      // on récupère le contact du suivi
                                      this.contactService.getContactDetail($tab.contact).then((contact) => {
                                        this.suivi.contact = contact as Contact;
                                        let eventId = 0;
                                        if (this.evenement != null) {
                                          eventId = this.evenement.id;
                                        }
                                        this.suiviActionShareService.modifierSuivi(that.suivi, this.mutuPar, this.niveauMutu, eventId)
                                          .then((suivi) => {
                                            const suiviAdd = suivi as Suivi;
                                            // si il y a des supports
                                            if (that.suivi.supports.length != 0) {
                                              // on supprime les assos supports du suivi, avant de les recréer
                                              that.suivi.supports.forEach(supportSuiviAncien => {
                                                this.suiviActionShareService.supprimerSupportSuivi(supportSuiviAncien.id, suiviAdd.id)
                                                  .then();
                                              });
                                            }
                                            that.suivi.supports = [];
                                            // enregistrer asso suivi nature
                                            this.suiviActionShareService.modifierSuiviNature(this.suiviNature.id, suiviAdd.id,
                                              this.natureActionSuiviIDSelect, this.axe, this.interesse, this.sujet, this.theme,
                                              this.interlocuteur, this.raison, this.commentaireVeille, this.opportunite,
                                              this.dateitw).then(suiNat => {
                                              // enregistrer asso client mailing calred premiere societe
                                              this.suiviActionShareService.modifierClientMailingCalRed(this.premiereAsso.id,
                                                this.premiereSocieteSuivie, this.communiqueUn,  $tab.calRed1, this.articleUn,
                                                suiviAdd.id).then(calredun => {
                                                // si il y a au moins un support sélectionné
                                                if ($tab.supports) {
                                                  // on récupère les supports du suivi
                                                  const tabSupports = $tab.supports.split(',');
                                                  tabSupports.forEach(supp => {
                                                    if (supp != '') {
                                                      // on recup le support
                                                      this.supportService.getSupportDetails(supp).then((support) => {
                                                        const $supportAjout = support as Support;
                                                        // on l'ajoute à la collection de supports du suivi
                                                        this.suivi.supports.push($supportAjout);
                                                        let compteur = 0;
                                                        that.suivi.supports.forEach(suppSuivi => {
                                                          this.suiviActionShareService.addSupportSuivi(suppSuivi.id, suiviAdd.id)
                                                            .then(suppSuiviadd => {
                                                              compteur = compteur + 1;
                                                              // si c'est le dernier passage dans le forEach
                                                              if (compteur == this.mediaSuiviIds.length) {
                                                                // si deuxieme soc renseignée
                                                                if (this.deuxiemeSocieteSuivie != 0) {
                                                                  // ajouter deuxieme asso si non existante
                                                                  if (!this.deuxiemeAsso) {
                                                                    this.suiviActionShareService.addClientMailingCalRed
                                                                    (this.deuxiemeSocieteSuivie, this.communiqueDeux, $tab.calRed2,
                                                                      this.articleDeux, suiviAdd.id).then(assoDeuxnew => {
                                                                      this.router.navigate(['/escal/suivi/' + suivi.id]);
                                                                      this.dialogueShareService.addDialogue('Le suivi a bien été modifié');
                                                                      // supprimer suivi dans la base intranet
                                                                      this.suiviActionShareService.supprimerImportSuiviIntranet(suiviAdd.id)
                                                                        .then(() => {
                                                                          // enregistrer suivi dans la base d'intranet
                                                                          this.suiviActionShareService.importerSuiviIntranet(suiviAdd.id, mediaIdsSelect)
                                                                            .then();
                                                                        });
                                                                    });
                                                                  } else {
                                                                    // modif asso si deja une deuxieme asso
                                                                    this.suiviActionShareService.modifierClientMailingCalRed
                                                                    (this.deuxiemeAsso.id, this.deuxiemeSocieteSuivie, this.communiqueDeux,
                                                                      $tab.calRed2, this.articleDeux,
                                                                      suiviAdd.id).then(assoDeuxModif => {
                                                                      this.router.navigate(['/escal/suivi/' + suivi.id]);
                                                                      this.dialogueShareService.addDialogue('Le suivi a bien été modifié');
                                                                      // supprimer suivi dans la base intranet
                                                                      this.suiviActionShareService.supprimerImportSuiviIntranet(suiviAdd.id)
                                                                        .then(() => {
                                                                          // enregistrer suivi dans la base d'intranet
                                                                          this.suiviActionShareService.importerSuiviIntranet(suiviAdd.id, mediaIdsSelect)
                                                                            .then();
                                                                        });
                                                                    });
                                                                  }
                                                                } else { // si deuxieme soc non renseignée
                                                                  if (this.tempDeuxiemeSoc == 0) {
                                                                    // si pas de changements sur deuxieme societe non existantes
                                                                    this.router.navigate(['/escal/suivi/' + suivi.id]);
                                                                    this.dialogueShareService.addDialogue('Le suivi a bien été modifié');
                                                                    // supprimer suivi dans la base intranet
                                                                    this.suiviActionShareService.supprimerImportSuiviIntranet(suiviAdd.id)
                                                                      .then(() => {
                                                                        // enregistrer suivi dans la base d'intranet
                                                                        this.suiviActionShareService.importerSuiviIntranet(suiviAdd.id, mediaIdsSelect)
                                                                          .then();
                                                                      });
                                                                  } else {
                                                                    // si pas de deuxieme societe mais changements (asso supprimée)
                                                                    this.suiviActionShareService.supprimerClientMailingCalRed
                                                                    (this.deuxiemeAsso.id).then(suppression => {
                                                                      this.router.navigate(['/escal/suivi/' + suivi.id]);
                                                                      this.dialogueShareService.addDialogue('Le suivi a bien été modifié');
                                                                      // supprimer suivi dans la base intranet
                                                                      this.suiviActionShareService.supprimerImportSuiviIntranet(suiviAdd.id)
                                                                        .then(() => {
                                                                          // enregistrer suivi dans la base d'intranet
                                                                          this.suiviActionShareService.importerSuiviIntranet(suiviAdd.id, mediaIdsSelect)
                                                                            .then();
                                                                        });
                                                                    });
                                                                  }
                                                                }
                                                              }
                                                            });
                                                        });
                                                      });
                                                    }
                                                  });
                                                } else { // si il n'y a aucun support média
                                                  // si deuxieme soc renseignée
                                                  if (this.deuxiemeSocieteSuivie != 0) {
                                                    // ajouter deuxieme asso si non existante
                                                    if (!this.deuxiemeAsso) {
                                                      this.suiviActionShareService.addClientMailingCalRed
                                                      (this.deuxiemeSocieteSuivie, this.communiqueDeux, $tab.calRed2,
                                                        this.articleDeux, suiviAdd.id).then(assoDeuxnew => {
                                                        this.router.navigate(['/escal/suivi/' + suivi.id]);
                                                        this.dialogueShareService.addDialogue('Le suivi a bien été modifié');
                                                        // supprimer suivi dans la base intranet
                                                        this.suiviActionShareService.supprimerImportSuiviIntranet(suiviAdd.id)
                                                          .then(() => {
                                                            // enregistrer suivi dans la base d'intranet
                                                            this.suiviActionShareService.importerSuiviIntranet(suiviAdd.id, mediaIdsSelect)
                                                              .then();
                                                          });
                                                      });
                                                    } else {
                                                      // modif asso si deja une deuxieme asso
                                                      this.suiviActionShareService.modifierClientMailingCalRed
                                                      (this.deuxiemeAsso.id, this.deuxiemeSocieteSuivie, this.communiqueDeux,
                                                        $tab.calRed2, this.articleDeux,
                                                        suiviAdd.id).then(assoDeuxModif => {
                                                        this.router.navigate(['/escal/suivi/' + suivi.id]);
                                                        this.dialogueShareService.addDialogue('Le suivi a bien été modifié');
                                                        // supprimer suivi dans la base intranet
                                                        this.suiviActionShareService.supprimerImportSuiviIntranet(suiviAdd.id)
                                                          .then(() => {
                                                            // enregistrer suivi dans la base d'intranet
                                                            this.suiviActionShareService.importerSuiviIntranet(suiviAdd.id, mediaIdsSelect)
                                                              .then();
                                                          });
                                                      });
                                                    }
                                                  } else { // si deuxieme soc non renseignée
                                                    if (this.tempDeuxiemeSoc == 0) {
                                                      // si pas de changements sur deuxieme societe non existantes
                                                      this.router.navigate(['/escal/suivi/' + suivi.id]);
                                                      this.dialogueShareService.addDialogue('Le suivi a bien été modifié');
                                                      // supprimer suivi dans la base intranet
                                                      this.suiviActionShareService.supprimerImportSuiviIntranet(suiviAdd.id)
                                                        .then(() => {
                                                          // enregistrer suivi dans la base d'intranet
                                                          this.suiviActionShareService.importerSuiviIntranet(suiviAdd.id, mediaIdsSelect)
                                                            .then();
                                                        });
                                                    } else {
                                                      // si pas de deuxieme societe mais changements (asso supprimée)
                                                      this.suiviActionShareService.supprimerClientMailingCalRed
                                                      (this.deuxiemeAsso.id).then(suppression => {
                                                        this.router.navigate(['/escal/suivi/' + suivi.id]);
                                                        this.dialogueShareService.addDialogue('Le suivi a bien été modifié');
                                                        // supprimer suivi dans la base intranet
                                                        this.suiviActionShareService.supprimerImportSuiviIntranet(suiviAdd.id)
                                                          .then(() => {
                                                            // enregistrer suivi dans la base d'intranet
                                                            this.suiviActionShareService.importerSuiviIntranet(suiviAdd.id, mediaIdsSelect)
                                                              .then();
                                                          });
                                                      });
                                                    }
                                                  }
                                                }
                                              });
                                            });
                                          });
                                      });
                                    });
                                  });
                              });
                          });
                      });
                  } else {
                    this.dialogueShareService.addDialogue('Veuillez choisir de qui provient la mutualisation');
                  }
                  this.verifMutu = false;
                } else {
                  this.dialogueShareService.addDialogue('Le collaborateur en charge du suivi doit être renseigné');
                }
              } else {
                this.dialogueShareService.addDialogue('Les compléments sont obligatoires');
              }
              this.verifComplements = false;
            } else {
              this.dialogueShareService.addDialogue('La coordonnée utilisée est obligatoire');
            }
            this.verifCoord = false;
          } else {
            this.dialogueShareService.addDialogue('Veuillez choisir une première société');
          }
      } else {
        this.dialogueShareService.addDialogue('Veuillez choisir un contact');
      }
    } else {
      this.dialogueShareService.addDialogue('Le titre du suivi est obligatoire');
    }
  }

  verifCoordVecteur() {
    if (this.vecteurIDSelect == 2 || this.vecteurIDSelect == 4 || this.vecteurIDSelect == 6) {
      if (this.vecteurIDSelect == 2 && this.emailSelect != '') {
        this.verifCoord = true;
      }
      if ((this.vecteurIDSelect == 4 || this.vecteurIDSelect == 6) && this.telSelect != '') {
        this.verifCoord = true;
      }
    } else {
      this.verifCoord = true;
    }
  }

  verifComplementsNature() {
    if (this.natureActionSuiviIDSelect == 1 || this.natureActionSuiviIDSelect == 9 || this.natureActionSuiviIDSelect == 15) {
      if (this.axe != '' && this.interesse != '' && this.sujet != '') {
        this.verifComplements = true;
      }
    } else if (this.natureActionSuiviIDSelect == 8 || this.natureActionSuiviIDSelect == 12 || this.natureActionSuiviIDSelect == 13 || this.natureActionSuiviIDSelect == 17) {
      if ((this.natureActionSuiviIDSelect ==  12 || this.natureActionSuiviIDSelect == 17) && this.raison != '' && this.theme != '' && this.interlocuteur != '') {
        this.verifComplements = true;
      }
      if ((this.natureActionSuiviIDSelect ==  8 || this.natureActionSuiviIDSelect == 13) && this.theme != '' && this.interlocuteur != '') {
        this.verifComplements = true;
      }
    } else if (this.natureActionSuiviIDSelect == 19) {
      if (this.evenement != null) {
        this.verifComplements = true;
      }
    }  else {
      this.verifComplements = true;
    }
  }

  verifMutualisation() {
    if (this.mutualisation == true) {
      if (this.mutuPar != 0) {
        this.verifMutu = true;
      }
    } else {
      this.verifMutu = true;
    }
  }

}
