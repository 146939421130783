import {Injectable} from '@angular/core';
import {Dialogue} from '../../../model/utilitaire/Dialogue';



@Injectable()
export class DialogueShareService {

  dialogue: Array<Dialogue>;
  nbDialogue: number = 0;

  constructor() {
    this.dialogue = [];
  }

  public addDialogue(content: string, fonctionCallBack = null, tempsVie = 5000){

    if (fonctionCallBack == null) {
      const nouveauDialogue = new Dialogue(this.nbDialogue, content, 'alert', fonctionCallBack);
      this.dialogue.push(nouveauDialogue);
      this.deleteDialogue(nouveauDialogue, tempsVie);
    } else {
      const nouveauDialogue = new Dialogue(this.nbDialogue, content, 'message', fonctionCallBack);
      this.dialogue.push(nouveauDialogue);
    }
    this.nbDialogue++;
  }
  deleteDialogue(dialogue: Dialogue, tempsVie) {
    let that = this;
    setTimeout(() => {
      that.removeDialogue(dialogue);
    }, tempsVie);
  }
  removeDialogue(dialogue: Dialogue){
    let i = 0;
    let elemSelected = 0;
    this.dialogue.forEach((element: Dialogue) => {
      if (element.id === dialogue.id) {
        elemSelected = i;
      }
      i++;
    });
    this.dialogue.splice(elemSelected, 1);

  }
}
