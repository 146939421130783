import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {OrganisationService} from '../../../../service/interne/organisation/OrganisationService';
import {Organisation} from '../../../../model/organisation/Organisation';
import {FormeJuridique} from '../../../../model/organisation/FormeJuridique';
import {Genre} from '../../../../model/contact/Genre';
import {Pays} from '../../../../model/information/Pays';
import {CodeApe} from '../../../../model/organisation/CodeApe';
import {FormeJuridiqueService} from '../../../../service/interne/organisation/FormeJuridiqueService';
import {GenreService} from '../../../../service/interne/genre/GenreService';
import {PaysService} from '../../../../service/interne/contact/PaysService';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {Coordonnees} from '../../../../model/information/Coordonnees';
import {CoordonneesService} from '../../../../service/interne/coordonnees/CoordonneesService';
import {CodeApeService} from '../../../../service/interne/organisation/CodeApeService';
import {AuthShareService} from '../../../../service/partage/AuthShareService';

@Component({
  selector: 'app-escal-organisation-modifier',
  templateUrl: './escal-organisation-modifier.component.html',
  styleUrls: ['./escal-organisation-modifier.component.scss'],
  providers: [OrganisationService, FormeJuridiqueService, GenreService, PaysService, CoordonneesService, CodeApeService]
})
export class EscalOrganisationModifierComponent implements OnInit {

  id: number;
  private sub: any;
  organisation: Organisation;
  coordonnee: Coordonnees;
  dirigeantId: number;
  societeId: number;
  codeId: number;
  categId: number;
  formeId: number;
  genreId: number;
  paysId: number;
  dirigeantsRecherche: Array<any> = [];
  societesRecherche: Array<any> = [];
  categoriesRecherche: Array<any> = [];
  formes: Array<FormeJuridique>;
  genres: Array<Genre>;
  pays: Array<Pays>;
  codes: Array<CodeApe>;
  champRechercheDirigeant: string;
  champRechercheSoc: string;
  champRechercheCode: string;
  champRechercheCateg: string;
  affiche: boolean;
  afficheDirigeant: boolean;
  afficheCode: boolean;
  afficheCateg: boolean;
  rechercheEncours = false;
  rechercheEncoursDirigeant = false;
  rechercheEncoursCateg = false;
  constructor(private route: ActivatedRoute, private organisationService: OrganisationService, private dialogueShareService: DialogueShareService,
              private router: Router, private formeJuridiqueService: FormeJuridiqueService, private genreService: GenreService,
              private paysService: PaysService, public formatIntranetShareService: FormatIntranetShareService,
              private coordonneesService: CoordonneesService, private codeApeService: CodeApeService,
              private authShareService: AuthShareService) { }

  ngOnInit() {
    this.organisation = new Organisation(0, 0, '', null, null, '', '', '' , '', '',
      null, new Date(), null, null, null, null, null, null, null, [], [], null, []);
    this.coordonnee = new Coordonnees(0, '', '', '', '', '', '', '', '', null,
      '', '', this.authShareService.authUser, new Date(), null, 0, null, null, null);
    const that = this;
    this.dirigeantId = 0;
    this.societeId = 0;
    this.codeId = 0;
    this.categId = 0;
    this.genreId = 0;
    this.paysId = 0;
    this.formeId = 0;
    this.affiche = false;
    this.afficheDirigeant = false;
    this.afficheCode = false;
    this.afficheCateg = false;
    this.champRechercheDirigeant = '';
    this.champRechercheSoc = '';
    this.champRechercheCode = '';
    this.champRechercheCateg = '';
    this.dirigeantsRecherche[0] = [];
    this.societesRecherche[0] = [];
    this.categoriesRecherche[0] = [];
    this.codes = [];
    this.sub = this.route.params.subscribe(params => {
      that.id = +params['id'];
      if (that.id !== 0) {
        this.organisationService.getOrganisationDetails(that.id)
          .then((retour) => {
            this.organisation = retour as Organisation;
            if (this.organisation.formeJuridique != null) {
              this.formeId = this.organisation.formeJuridique.id;
            }
            if (this.organisation.dirigeant != null) {
              this.dirigeantId = this.organisation.dirigeant.ancienId;
              this.dirigeantsRecherche[0]['id'] = this.dirigeantId;
              this.dirigeantsRecherche[0]['nomPrenom'] = this.organisation.dirigeant.prenom + ' ' + this.organisation.dirigeant.nom;
            }
            if (this.organisation.appartenirA != null) {
              this.societeId = this.organisation.appartenirA.ancienId;
              this.societesRecherche[0]['id'] = this.societeId;
              this.societesRecherche[0]['nom'] = this.organisation.appartenirA.nom;
            }
            if (this.organisation.ape != null) {
              this.codeId = this.organisation.ape.id;
              this.codes.push(this.organisation.ape);
            }
            if (this.organisation.categorieGenerale != null) {
              this.categId = this.organisation.categorieGenerale.ancienId;
              this.categoriesRecherche[0]['id'] = this.categId;
              this.categoriesRecherche[0]['nom'] = this.organisation.categorieGenerale.libelle;
            }
            if (this.organisation.genre != null) {
              this.genreId = this.organisation.genre.id;
            }
            if (this.organisation.coordonnees != null) {
              this.coordonneesService.getCoordonneesDetails(this.organisation.coordonnees.id).then((coordonnes) => {
                this.coordonnee = coordonnes as Coordonnees;
                if (this.coordonnee != null) {
                  if (this.coordonnee.pays != null) {
                    this.paysId = this.organisation.coordonnees.pays.id;
                  }
                }
              });
            }
          });
      }
    });
    this.genreService.getGenres().then(genres => {
      this.genres = genres['hydra:member'] as Array<Genre>;
    });
    this.paysService.getAllPays().then(pays => {
      this.pays = pays['hydra:member'] as Array<Pays>;
    });
    this.formeJuridiqueService.getFormesJuridiques().then(formesJ => {
      this.formes = formesJ['hydra:member'] as Array<FormeJuridique>;
    });
  }


  rechercheCode() {
    this.codeApeService.getCodesApeRecherche(this.champRechercheCode).then(codes => {
      this.codes = codes['hydra:member'] as Array<CodeApe>;
      this.afficheCode = true;
    });
  }

  rechercheDirigeant() {
    this.afficheDirigeant = true;
    if (this.champRechercheDirigeant.length > 2 && !this.rechercheEncoursDirigeant) {
      this.rechercheEncoursDirigeant = true;
      this.champRechercheDirigeant = this.champRechercheDirigeant.replace(' ', '.');
      this.organisationService.getDirigeantRecherche(this.champRechercheDirigeant).then((retour) => {
        this.dirigeantsRecherche = retour;
        this.dirigeantsRecherche.forEach(dirigeant => {
          if (dirigeant.nomPrenom != '' && dirigeant.nomPrenom != null) {
            dirigeant.nomPrenom = dirigeant.nomPrenom.replace('&amp;', '&');
            dirigeant.nomPrenom = dirigeant.nomPrenom.replace('&#039;', '’');
            dirigeant.nomPrenom = dirigeant.nomPrenom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheDirigeant = this.champRechercheDirigeant.replace('.', ' ');
      });
      this.rechercheEncoursDirigeant = false;
    }
  }

  rechercheSociete() {
    this.affiche = true;
    if (this.champRechercheSoc.length > 2 && !this.rechercheEncours) {
      this.rechercheEncours = true;
      this.champRechercheSoc = this.champRechercheSoc.replace(' ', '.');
      this.organisationService.getSocieteRecherche(this.champRechercheSoc).then((retour) => {
        this.societesRecherche = retour;
        this.societesRecherche.forEach(societe => {
          if (societe.nom != '' && societe.nom != null) {
            societe.nom = societe.nom.replace('&amp;', '&');
            societe.nom = societe.nom.replace('&#039;', '’');
            societe.nom = societe.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheSoc = this.champRechercheSoc.replace('.', ' ');
      });
      this.rechercheEncours = false;
    }
  }

  rechercheCategories() {
    this.afficheCateg = true;
    if (this.champRechercheCateg.length > 2 && !this.rechercheEncoursCateg) {
      this.rechercheEncoursCateg = true;
      this.champRechercheCateg = this.champRechercheCateg.replace(' ', '.');
      this.organisationService.getCategoriesRecherche(this.champRechercheCateg).then((retour) => {
        this.categoriesRecherche = retour;
        this.categoriesRecherche.forEach(categorie => {
          if (categorie.nom != '' && categorie.nom != null) {
            categorie.nom = categorie.nom.replace('&amp;', '&');
            categorie.nom = categorie.nom.replace('&#039;', '’');
            categorie.nom = categorie.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheCateg = this.champRechercheCateg.replace('.', ' ');
      });
      this.rechercheEncoursCateg = false;
    }
  }


  modifierSociete() {
    // informations obligatoires : Nom et Genre de la société
    if (this.organisation.nom != '') {
      if (this.genreId != 0) {
        this.organisationService.modifOrganisation(this.organisation, this.formeId, this.codeId, this.genreId).then(org => {
          const organisation = org as Organisation;
          // si société parente renseignée
          if (this.societeId != 0) {
            // on importe la societe à laquelle la nouvelle organisation appartient
            this.organisationService.importerOrganisation(this.societeId).then(() => {
              // on récupère la société importée
              this.organisationService.getDoublonEscalnetSiExiste(this.societeId).then(societe => {
                const socId = societe['hydra:member'][0].id;
                // on l'assigne à la nouvelle organisation
                this.organisationService.addSocieteParent(socId, organisation.id).then(() => {
                });
              });
            });
          }
          // si dirigeant renseigné
          if (this.dirigeantId != 0) {
            // on importe le dirigeant
            this.organisationService.importerDirigeant(this.dirigeantId).then(() => {
              // on récupère le dirigeant renseigné
              this.organisationService.getDirigeantByAncienId(this.dirigeantId).then((dirigeant) => {
                const dirId = dirigeant['hydra:member'][0].id;
                // on l'assigne à la nouvelle organisation
                this.organisationService.addDirigeant(dirId, organisation.id).then(() => {
                });
              });
            });
          }
          // si catégorie générale renseignée
          if (this.categId != 0) {
            // on importe la catégorie
            this.organisationService.importerCategorie(this.categId).then(() => {
              // on récupère la catégorie renseignée
              this.organisationService.getCategorieByAncienId(this.categId).then((categorie) => {
                const catId = categorie['hydra:member'][0].id;
                // on l'assigne à la nouvelle organisation
                this.organisationService.addCategorie(catId, organisation.id).then(() => {
                });
              });
            });
          }
          // si au moins un champs de coordonnees renseigné
          if (this.coordonnee.premierTel != '' || this.coordonnee.deuxiemeTel != '' || this.coordonnee.email != '' || this.coordonnee.rue != '' || this.coordonnee.complements != '' || this.coordonnee.boitePostale != '' || this.coordonnee.codePostal != '' || this.coordonnee.ville != '' || this.paysId != 0 || this.coordonnee.premierURL != '' || this.coordonnee.deuxiemeURL != '') {
            // si une coordonnee deja existante
            if (this.coordonnee.id != 0) {
              this.coordonneesService.modifierCoordonnees(this.coordonnee.id, this.coordonnee, organisation.id, this.paysId).then(coord => {
              });
            } else {
              // si pas encore de coordonnee
              this.coordonneesService.addCoordonneeOrganisation(this.coordonnee, organisation.id, this.paysId).then(coord => {
              });
            }
          }
          // modifier la societe dans la base d'intranet (pas d'import, juste màj puisqu'on envoie un ancien id)
          this.organisationService.importerOrganisationIntranet(organisation.id, this.organisation.ancienId).then((ancienIdSoc) => {
            // insérer l'id d'intranet dans ancienId d'escalnet
            this.organisationService.addAncienIdOrganisation(this.organisation.ancienId, organisation.id).then((retour) => {
              // rappeler la fonction, cette fois pour s'assurer que les promises soient terminés
              this.organisationService.importerOrganisationIntranet(organisation.id, this.organisation.ancienId).then((fin) => {
                this.router.navigate(['/escal/societes']);
                this.dialogueShareService.addDialogue('La société a bien été modifiée');
              });
            });
          });
        });
      } else {
        this.dialogueShareService.addDialogue('Le genre est obligatoire');
      }
    } else {
      this.dialogueShareService.addDialogue('Le nom est obligatoire');
    }
  }

}
