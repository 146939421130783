import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';


@Injectable()
export class PaysService {
  private paysURL = Environement.BASE_API_URL + '/api/pays';
  constructor(private http: HttpClient) { }

  getAllPays() {
    const headers = this.initOptionHeader();
    return this.http.get(this.paysURL + '?order[libelleFR]=asc', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }


  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
