import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';

@Injectable()
export class VeilleThematiqueService{
  private veilleThematiqueUrl = Environement.BASE_API_URL + '/api/veille_thematiques';
  constructor(private http: HttpClient) { }


  getVeilleThematiqueid(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.veilleThematiqueUrl + '/' + id + '.json' , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getVeilleThematique(idVeille,idThematique): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.veilleThematiqueUrl + '.json?thematique=' + idThematique + '&veille=' + idVeille ,
{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  removeVeilleThematique(id) {
    const headers = this.initOptionHeader();
    return this.http.delete(this.veilleThematiqueUrl + '/' + id + '.json' , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addVeilleThematique(idVeille,idThematique): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post(this.veilleThematiqueUrl + '.json', { 'veille': '/api/veilles/' + idVeille ,
      'thematique': '/api/thematiques/' + idThematique }, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  };

  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
