import { Component, OnInit } from '@angular/core';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {ImportListeContactHaShareService} from '../../../../service/partage/import/ImportListeContactHaShareService';
import {ImportListeContactHaService} from '../../../../service/interne/import/ImportListeContactHaService';
import {DndDropEvent} from 'ngx-drag-drop';

@Component({
  selector: 'app-import-liste-contact-ha',
  templateUrl: './import-liste-contact-ha.component.html',
  styleUrls: ['./import-liste-contact-ha.component.scss'],
  providers: [ ImportListeContactHaService ]
})
export class ImportListeContactHaComponent implements OnInit {

  csvUpload: Array<any> = [];
  choixColone = 0;

  constructor(private dialogueShareService: DialogueShareService, private importListeContactHaService: ImportListeContactHaService,
              private importListeContactHaShareService: ImportListeContactHaShareService) {}

  ngOnInit() {
    this.importListeContactHaShareService.importListeContactHaService = this.importListeContactHaService;
  }

  changeCsv(event) {
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        that.csvUpload['filename'] = file.name;
        that.csvUpload['filetype'] = file.type;
        // @ts-ignore
        that.csvUpload['value'] = reader.result.split(',')[1];
        that.csvUpload['extension'] = file.name.split('.')[1];
      };
    }
  }

  afficher() {
    const that = this;
    this.choixColone = 0;
    if (this.csvUpload['filename'] !== undefined) {
      that.importListeContactHaShareService.updateContact(that.csvUpload);
    } else {
      that.dialogueShareService.addDialogue('Merci de selectionner un csv');
    }
  }


}
