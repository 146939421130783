import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthUser} from '../../../../model/user/authUser';
import {Client} from '../../../../model/client/Client';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {SuivitIntranetService} from '../../../../service/interne/suivit/SuivitIntranetService';
import {AuthUserService} from '../../../../service/interne/user/AuthUserService';
import {AuthShareService} from '../../../../service/partage/AuthShareService';
import {Mailling} from '../../../../model/mailling/Mailling';
import {MaillingService} from '../../../../service/interne/mailling/maillingService';
import {SuivitActionSujet} from '../../../../model/suivitAction/SuivitActionSujet';
import {SuiviAction} from '../../../../model/suivitAction/SuiviAction';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {NatureActionSuivi} from '../../../../model/suivitAction/NatureActionSuivi';
import {NatureActionSuiviService} from '../../../../service/interne/SuiviAction/natureActionSuiviService';
import {Vecteur} from '../../../../model/suivitAction/Vecteur';
import {TypeDeRelation} from '../../../../model/suivitAction/TypeDeRelation';
import {TypeDeRelationService} from '../../../../service/interne/SuiviAction/typeDeRelationService';
import {SuiviActionShareService} from '../../../../service/partage/SuiviActionShareService';

@Component({
  selector: 'app-escal-suivit-gestion',
  templateUrl: './escal-suivit-gestion.component.html',
  styleUrls: ['./escal-suivit-gestion.component.scss'],
  providers: [ ClientService, SuivitIntranetService, AuthUserService, MaillingService, NatureActionSuiviService, TypeDeRelationService ]
})
export class EscalSuivitGestionComponent implements OnInit {

  id: number;
  private sub: any;
  clients: Array<Client> = [];
  typesSuivit: Array<any> = [];
  natureActionSuiviSort: Array<any> = [];
  statusSuivit: Array<any> = [];
  users: Array<AuthUser> = [];
  champRechercheContact: string = '';
  contactsRecherche: Array<any> = [];
  rechercheEncours: boolean = false;
  medias: Array<any> = [];
  vecteurs: Vecteur;
  emailsPossible: Array<any> = [];
  numerosPossible: Array<any> = [];
  typeDeRelations: Array<TypeDeRelation> = [];

  communiquesClient1: Array<Mailling> = [];
  communiquesClient2: Array<Mailling> = [];
  calendriersClient1: Array<Mailling> = [];
  calendriersClient2: Array<Mailling> = [];
  suiviAction: SuiviAction;

  lib: string = '';
  natureActionSuivis: Array<NatureActionSuivi> = [];
  structureChoisi: string;
  structures: Array<string> = ['Personne', 'Media', 'Organisation'];

  constructor(private router: Router, private route: ActivatedRoute, private clientService: ClientService,
              private suivitIntranetService: SuivitIntranetService, private authUserService: AuthUserService,
              private authShareService: AuthShareService, private maillingService: MaillingService,
              private dialogueShareService: DialogueShareService, public formatIntranetShareService: FormatIntranetShareService,
              private natureActionSuiviService: NatureActionSuiviService, private typeDeRelationService: TypeDeRelationService,
              private suiviActionShareService: SuiviActionShareService) {
  }

  ngOnInit() {
    const that = this;
    this.sub = this.route.params.subscribe(params => {
      that.id = +params['id'];
      if (that.id === 0) {
        this.suivitIntranetService.getDefaultTypeDeRelation(retour => {
          const defaultTypeDeRelation = retour;
          this.suiviAction = new SuiviAction(that.id, '', new Date(), false, 0, [], null,
            null, null, null, null, null, defaultTypeDeRelation,
            7, null, null, null, '0', null, 0,
            0, 0 , '', null, 0, '', null);
        });
        }
        });
    this.typeDeRelationService.getTypeRelation().then((retour) => {
      that.typeDeRelations = retour['hydra:member'] as Array<TypeDeRelation>;
    });
    this.clientService.getClientList(1).then((clientsRetour) => {
     that.clients = clientsRetour['hydra:member'] as Array<Client>;
   });
    this.suivitIntranetService.getSuivit().then((retour) => {
      that.typesSuivit = retour;
      this.adapteNatureAction();
   });
    this.suiviActionShareService.getStatuts().then((retour) => {
      that.statusSuivit = retour;
    });
    this.authUserService.getUsersList(1).then((usersRetour) => {
      that.users = usersRetour['hydra:member'] as Array<AuthUser>;
    });
   /* this.suiviAction.suivitPar = this.authShareService.authUser.id;
    this.addSujet();
    this.resetVecteur(); */

  }
  addSujet() {
    const sujet = new SuivitActionSujet('', false);
    this.suiviAction.sujetEnCours.push(sujet);
  }
  resetVecteur() {

  }

  rechercheContact() {
    if (this.champRechercheContact.length > 2 && !this.rechercheEncours) {
      const that = this;
      this.suiviAction.contactSuiviId = 0;
      this.rechercheEncours = true;
      this.suivitIntranetService.getContactRecherche(this.champRechercheContact).then((retour) => {
       this.contactsRecherche = retour;
       console.log(that.champRechercheContact);
       that.rechercheEncours = false;
      });
    }
  }
  changeContact() {
    const that = this;
    if (this.suiviAction.contactSuiviId !== 0) {
      this.suivitIntranetService.getContactMedia(this.suiviAction.contactSuiviId).then((retour) => {
        this.medias = retour;
      });
      this.resetEmailSuggestion();
      this.resetNumeroSuggestion();
      this.suiviAction.mediaSuivitIds = [];
    }
  }
  resetEmailSuggestion() {
    this.emailsPossible = [];
    this.suiviAction.emailContactSuivi = '0';
    this.suivitIntranetService.getEmailContact(this.suiviAction.contactSuiviId).then((emailRetour) => {
      for (var i = 0, len = emailRetour.length; i < len; i++) {
        this.emailsPossible.push(emailRetour[i]);
      }
    });
    this.suivitIntranetService.getEmailContactMedia(this.suiviAction.contactSuiviId).then((emailRetour) => {
      for (var i = 0, len = emailRetour.length; i < len; i++) {
        this.emailsPossible.push(emailRetour[i]);
      }
    });
  }
  resetNumeroSuggestion() {
    this.numerosPossible = [];
    this.suiviAction.numeroContactSuivi = '0';
    this.suivitIntranetService.getNumeroContact(this.suiviAction.contactSuiviId).then((numeroRetour) => {
      for (var i = 0, len = numeroRetour.length; i < len; i++) {
        this.numerosPossible.push(numeroRetour[i]);
      }
    });
    this.suivitIntranetService.getNumeroContactMedia(this.suiviAction.contactSuiviId).then((numeroRetour) => {
      for (var i = 0, len = numeroRetour.length; i < len; i++) {
        this.numerosPossible.push(numeroRetour[i]);
      }
    });
  }

  isAfficheMail(mail) {
    if (typeof mail['ID_support'] !== 'undefined') {
      for (var i = 0, len = this.suiviAction.mediaSuivitIds.length; i < len; i++) {
        if (this.suiviAction.mediaSuivitIds[i] == mail['ID_support']) {
          return true;
        }
      }
      return false;
    }
    return true;
  }
  isAfficheNumero(numero) {
    if (typeof numero['ID_support'] !== 'undefined') {
      for (var i = 0, len = this.suiviAction.mediaSuivitIds.length; i < len; i++) {
        if (this.suiviAction.mediaSuivitIds[i] == numero['ID_support']) {
          return true;
        }
      }
      return false;
    }
    return true;
  }
  addClient(newId, societe) {
    const that = this;
    this.clients.forEach((client) => {
      if ( client.id == newId) {
        if (societe === 1) {
        that.suiviAction.societe1 = client;
        } else if (societe === 2) {
          that.suiviAction.societe2 = client;
        }
      }
    });
    this.changeClient1();
  }

  changeClient1() {
    this.resetCommunique1();
    this.resetCalendrier1();
  }
  resetCommunique1() {
    const that = this;
    this.suiviAction.communiqueSociete1 = null;
    this.communiquesClient1 = [];
    this.maillingService.getMaillingListClient(this.suiviAction.societe1.id).then((retour) => {
      that.communiquesClient1 = retour['hydra:member'] as Array<Mailling>;
    });
  }
  resetCalendrier1() {
    const that = this;
    this.suiviAction.calRedSociete1 = null;
    this.calendriersClient1 = [];
    this.suivitIntranetService.getCalendrierClient(this.suiviAction.societe1.id).then((retour) => {
      that.calendriersClient1 = retour;
    });
  }
  isafficheMailling(mailling: Mailling) {
    let dateEnvoie = new Date(mailling.dateEnvoi);
    let date1ans = new Date();
    date1ans.setFullYear(date1ans.getFullYear() - 1);
    if (dateEnvoie > date1ans){
      return true;
    }
    return false;
  }
  changeClient2() {
    this.resetCommunique2();
    this.resetCalendrier2();
  }
  resetCommunique2() {
    const that = this;
    this.suiviAction.communiqueSociete2 = null;
    this.communiquesClient2 = [];
    this.maillingService.getMaillingListClient(this.suiviAction.societe2).then((retour) => {
      that.communiquesClient2 = retour['hydra:member'] as Array<Mailling>;
    });
  }
  resetCalendrier2() {
    const that = this;
    this.suiviAction.calRedSociete2 = null;
    this.calendriersClient2 = [];
    this.suivitIntranetService.getCalendrierClient(this.suiviAction.societe2).then((retour) => {
      that.calendriersClient2 = retour;
    });
  }
  sujetEnCoursUpdate() {
    let rempli = true;
    this.suiviAction.sujetEnCours.forEach((sujet) => {
      if (sujet.sujet.length < 5) {
        rempli = false;
      }
    });
    if (rempli === true) {
      this.addSujet();
    }
  }
  isMutualisation() {
    let mutulisation = false;
    this.suiviAction.sujetEnCours.forEach((sujet) => {
      if (sujet.mutualise === true) {
        mutulisation = true;
      }
    });
    return mutulisation;
  }

  isBeforeSuvit(nb) {
    if ( nb  === 0) {
      if (this.suiviAction.clientOportunites1Id == 0) {
        if (this.suiviAction.clientOportunites2Id != 0) {
          this.suiviAction.clientOportunites1Id = this.suiviAction.clientOportunites2Id;
          this.suiviAction.clientOportunites2Id = 0;
        }
      }
    }
    if ( nb  === 1) {
      if (this.suiviAction.clientOportunites2Id == 0) {
        if (this.suiviAction.clientOportunites3Id != 0) {
          this.suiviAction.clientOportunites2Id = this.suiviAction.clientOportunites3Id;
          this.suiviAction.clientOportunites3Id = 0;
        }
      }
    }
  }
  adapteNatureAction() {
    this.natureActionSuiviSort = [];
    this.typesSuivit.forEach((suivit) =>{
      if(this.suiviAction.typeDeRelation.id == 0 || this.suiviAction.typeDeRelation.id == 3){
        this.natureActionSuiviSort.push(suivit);
      }
      if(this.suiviAction.typeDeRelation.id == 1){
        if (suivit.id != 9 && suivit.id != 11) {
          this.natureActionSuiviSort.push(suivit);
        }
      }
      if(this.suiviAction.typeDeRelation.id == 2){
        if (suivit.id != 9 && suivit.id != 11) {
          this.natureActionSuiviSort.push(suivit);
        }
      }
    });
  }
  isPresent() {
    let count = 0;
    switch (Number(this.suiviAction.vecteur)) {
      // numero
      case 4:
        this.numerosPossible.forEach((numero) => {
          if (this.isAfficheNumero(numero)) {
            count++;
          }
        });
        break;
      // mail
      case 2:
        this.emailsPossible.forEach((mail) => {
          if (this.isAfficheMail(mail)) {
            count++;
          }
        });
        break;
    }
    return count >= 1;
  }
  changeVecteurLib() {
    switch (Number(this.suiviAction.vecteur)) {
      // numero
      case 4:
        this.lib = 'numéro';
        break;
      // mail
      case 2:
        this.lib = 'mail';
        break;
    }
    return this.lib;
  }
  choixStructure() {
    let that = this;
    switch (this.structureChoisi) {
      case 'Personne':
        console.log(that.structureChoisi);
        break;
      case 'Media':
        console.log(that.structureChoisi);
        break;
      case 'Organisation':
        console.log(that.structureChoisi);
        break;

    }
  }
}
