import {ArticleMotCleSite} from './ArticleMotCleSite';
import {ArticleSiteSection} from './ArticleSiteSection';
import {ImageArticleSite} from './ImageArticleSite';

export class ArticleSite {
  id: number;
  nom: string;
  articlesMotCle: Array<ArticleMotCleSite>;
  online: boolean;
  title: string;
  couleurTitle: string;
  sections: Array<ArticleSiteSection>;
  phototech: Array<ImageArticleSite>;
  actif: boolean;
  imageFond: ImageArticleSite;


  constructor(id: number, nom: string, articlesMotCle: Array<ArticleMotCleSite>, online: boolean, title: string, couleurTitle: string,
              sections: Array<ArticleSiteSection>, phototech: Array<ImageArticleSite>, actif: boolean, imageFond: ImageArticleSite) {
    this.id = id;
    this.nom = nom;
    this.articlesMotCle = articlesMotCle;
    this.online = online;
    this.title = title;
    this.couleurTitle = couleurTitle;
    this.sections = sections;
    this.phototech = phototech;
    this.actif = actif;
    this.imageFond = imageFond;
  }
}
