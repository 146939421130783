import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Client} from '../../../../../model/client/Client';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';

@Component({
  selector: 'app-escal-client-fiche-parutions',
  templateUrl: './escal-client-fiche-parutions.component.html',
  styleUrls: ['./escal-client-fiche-parutions.component.scss']
})
export class EscalClientFicheParutionsComponent implements OnInit {

  @Input()
  client: Client;
  @Output()
  refresh = new EventEmitter();
  constructor(public formatIntranetShareService: FormatIntranetShareService) { }

  ngOnInit() {
  }

}
