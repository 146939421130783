import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Client} from '../../../../../model/client/Client';
import {Suivi} from '../../../../../model/suivi/Suivi';
import {PaginationPaginateurComponent} from '../../../../utilitaire/pagination/pagination-paginateur/pagination-paginateur.component';
import {SuiviActionShareService} from '../../../../../service/partage/SuiviActionShareService';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';

@Component({
  selector: 'app-escal-client-fiche-suivis',
  templateUrl: './escal-client-fiche-suivis.component.html',
  styleUrls: ['./escal-client-fiche-suivis.component.scss']
})
export class EscalClientFicheSuivisComponent implements OnInit {

  suivisAssocies: Array<Suivi>;
  curentPage = 1;
  maxPage: number;
  total: number;
  fakeArray: Array<any>;
  page: number;
  @Input()
  client: Client;
  @Output()
  refresh = new EventEmitter();
  @ViewChild(PaginationPaginateurComponent) paginationComponent: PaginationPaginateurComponent;
  constructor(private suiviService: SuiviActionShareService, public formatIntranetShareService: FormatIntranetShareService) { }

  ngOnInit() {
    this.page = 1;
    this.suiviService.getAllSuivisClient(this.client.id, this.curentPage).then((suivis) => {
      this.suivisAssocies = suivis['hydra:member'] as Array<Suivi>;
      this.total = suivis['hydra:totalItems'];
      this.getMaxPage(suivis['hydra:totalItems']);
      this.fakeArray = new Array(this.maxPage);
    });
  }
  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }

  changePage(page) {
    this.curentPage = page;
    this.ngOnInit();
  }

  accesPage() {
    this.curentPage = this.page;
    this.paginationComponent.page = this.page;
    this.ngOnInit();
    this.paginationComponent.initPage();
  }

}
