import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';
import {Mailling} from '../../../model/mailling/Mailling';


@Injectable()
export class MaillingService{
    private maillingUrl = Environement.BASE_API_URL + '/api/maillings';
    constructor(private http: HttpClient) { }

  getMaillingList(envoyer, enCour = 0){
    const headers = this.initOptionHeader();
    return this.http.get(this.maillingUrl + '?pagination=false&envoyer=' + envoyer + '&enCour=' + enCour, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getMaillingListClient(idClient){
    const headers = this.initOptionHeader();
    return this.http.get(this.maillingUrl + '?pagination=false&envoyer=1&typeMail=CP&client.id=' + idClient,
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getMaillingSend(page, coCheck, cpCheck, idClient = 0, rechercheContent = '', dateEnvoi = 1){

    const headers = this.initOptionHeader();
    let type = '';
    if (coCheck === true && cpCheck === false){
      type = '&typeMail=Coupure';
    }
    if (cpCheck === true && coCheck === false){
      type = '&typeMail=CP';
    }
    let url = this.maillingUrl + '?envoyer=1';
    if ( rechercheContent.length > 1 ) {
      url = url + '&objet=' + rechercheContent;
    }
    if (dateEnvoi !== 1) {
      url = url + '&dateEnvoi=' + dateEnvoi;
    }
    if (Number(idClient) > 1) {
      url = url + '&client.id=' + idClient;
    }
    url = url + '&_page=' + page + type;
    return this.http.get(url, {headers: headers} ).toPromise()
          .then(response => response)
          .catch(this.errorHandler);
  }
  getMaillingEnCourInfo(idMailling) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/mailling/enCour/' + idMailling, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getMaillingItem(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.maillingUrl + '/' + id , { headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getMaillingContent(id) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/maillings/content/' + id , { headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  sendMailTest(idMailling, idAuthUser) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/maillings/send/test/' + idMailling + '/' + idAuthUser ,
      { headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  sendMail(idMailling) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/maillings/send/' + idMailling , { headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  sendMailDecaler(idMailling, date) {
    const headers = this.initOptionHeader();
    const month = date.getMonth() + 1;
    const DateFormat = date.getFullYear() + '-' + month + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':00' ;
    return this.http.get(Environement.BASE_API_URL + '/maillings/send/date/' + idMailling + '?date=' + DateFormat,
{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  validMailling(idMailling) {
    const headers = this.initOptionHeader();
    return this.http.put(this.maillingUrl + '/' + idMailling , { 'validerEnvoi': true },{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  updateMailling(mailling: Mailling, sendVal, responseVal, resumer, maillingContent, signature) {
    const headers = this.initOptionHeader();
    return this.http.put(this.maillingUrl + '/' + mailling.id , { 'replyTo': '/api/auth_users/' + responseVal ,
      'envoyePar': '/api/auth_users/' + sendVal, 'resumer': resumer, 'objetCour': mailling.objetCour, 'resumerCour': mailling.resumerCour,
      'titre': mailling.titre, 'content': maillingContent, 'color': mailling.color, 'langue': mailling.langue, 'signature': signature, 'signatureContenu': mailling.signatureContenu}, { headers: headers} )
      .toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  updateMaillingCoupure(mailling: Mailling, sendVal, responseVal, resumer, debutMail, finMail, hyperlien){
    const headers = this.initOptionHeader();
    return this.http.put(this.maillingUrl + '/' + mailling.id , { 'replyTo': '/api/auth_users/' + responseVal ,
      'envoyePar': '/api/auth_users/' + sendVal, 'resumer': resumer, 'objetCour': mailling.objetCour, 'resumerCour': mailling.resumerCour,
      'titre': mailling.titre, 'debutMailCoupure': debutMail, 'finMailCoupure': finMail, 'color': mailling.color, 'langue': mailling.langue, 'hyperlien': hyperlien}
      , { headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  checkDeleteMailling(){
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/maillings/checkDelete' ,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getMaillingEnvoieErreur(id){
    const headers = this.initOptionHeader();
    return this.http.get(this.maillingUrl + '/maillings/getErreur/' + id ,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  deleteMailling(id){
    const headers = this.initOptionHeader();
    return this.http.delete(Environement.BASE_API_URL + '/maillings/deleteMailling/' + id ,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  crerMailCoupure(IdClient){
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/maillings/prepareCoupure/' + IdClient , { headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  refreshNbMail(IdMailling) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/maillings/refreshNbMail/' + IdMailling , { headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
    }

}
