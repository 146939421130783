import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';

@Injectable()
export class PdfService {
  private pdfUrl = Environement.BASE_API_URL + '/api/pdfs';
  constructor(private http: HttpClient) { }

  deletPdf(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.delete(this.pdfUrl + '/' + id , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addPdf(idMailling, pdfUpload): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post(this.pdfUrl , {'idMailling': idMailling, 'url' : idMailling.toString(), 'value': pdfUpload['value']},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  mergePdf(pdfUpload): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post( Environement.BASE_API_URL + '/pdf/utilitaire/merge' , {'value1': pdfUpload[1]['value'], 'value2': pdfUpload[2]['value']},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
