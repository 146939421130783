import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';


@Injectable()
export class ThematiqueService{
  private thematiqueUrl = Environement.BASE_API_URL + '/api/thematiques';
  constructor(private http: HttpClient) { }

  getThematiques(): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.thematiqueUrl + '.json?order[libelle]', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getThematiquesAffiche(): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.thematiqueUrl + '.json?order[libelle]&affiche=1', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addThematique(libelle): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post(this.thematiqueUrl, { 'libelle': libelle },{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  };

  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
