import {Component, Input, OnInit} from '@angular/core';
import {Mailling} from '../../../../../model/mailling/Mailling';

@Component({
  selector: 'app-escal-mailling-list-item',
  templateUrl: './escal-mailling-list-item.component.html',
  styleUrls: ['./escal-mailling-list-item.component.scss']
})
export class EscalMaillingListItemComponent implements OnInit {

  @Input()
  mailling: Mailling;
  constructor() { }

  ngOnInit() {
  }
}
