import { Component, OnInit } from '@angular/core';
import {AuthShareService} from '../../../service/partage/AuthShareService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  login: string;
  password: string;

  constructor(private authShareService: AuthShareService) { }

  ngOnInit() {
  }

  identification(){
    const that = this;
    this.authShareService.identification(that.login, that.password)
  }
  validerEntre(event){
    if (event['key'] === 'Enter') {
      this.identification();
    }
  }
}
