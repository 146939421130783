import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ArticleSite} from '../../../model/site/ArticleSite';
import {Environement} from '../../../environement/environement';
import {MotCleSite} from '../../../model/site/MotCleSite';
import {ArticleMotCleSite} from '../../../model/site/ArticleMotCleSite';




@Injectable()
export class ArticleSiteMotCleService {
    private articleSiteMotCleUrl = Environement.BASE_API_URL + '/api/article_mot_cle_sites';
    constructor(private http: HttpClient) { }

  addArticleSiteMotCle(article: ArticleSite, motCle: MotCleSite) {
    const headers = this.initOptionHeader();
      return this.http.post(this.articleSiteMotCleUrl , {'article': Environement.BASE_API_URL + '/api/article_sites/' + article.id,
        'motCle': Environement.BASE_API_URL + '/api/mot_cle_sites/' + motCle.id}, {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
  }

  deleteArticleSiteMotCle(id){
    const headers = this.initOptionHeader();
    return this.http.delete(this.articleSiteMotCleUrl  + '/' + id , {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);

  }

    private errorHandler(err: any): Promise<any> {
        return Promise.reject(err.message || err );
    };
    private initOptionHeader(){
        const token: any = localStorage.getItem('auth-tokens');
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.set('X-Auth-Token', token);
        return headers;
    }
}
