import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact} from '../../../../../model/contact/Contact';
import {ActivatedRoute, Router} from '@angular/router';
import {ContactService} from '../../../../../service/interne/contact/ContactService';
import {ListeContactContact} from '../../../../../model/liste/ListeContactContact';
import {ListeContact} from '../../../../../model/liste/ListeContact';
import {ListeContactService} from '../../../../../service/interne/contact/ListeContactService';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';
import {Support} from '../../../../../model/support/Support';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {OpenWithPostShareService} from '../../../../../service/partage/utilitaire/OpenWithPostShareService';
import {SupportService} from '../../../../../service/interne/support/SupportService';

@Component({
  selector: 'app-escal-contact-fiche-listes',
  templateUrl: './escal-contact-fiche-listes.component.html',
  styleUrls: ['./escal-contact-fiche-listes.component.scss'],
  providers: [ListeContactService, SupportService]
})
export class EscalContactFicheListesComponent implements OnInit {
  @Input()
  contact: Contact;
  @Output()
  refresh = new EventEmitter();
  private sub: any;
  listeContactContact: Array<ListeContactContact> = [];
  addOpen: boolean;
  addOpenMAJ: boolean;
  curentPage = 1;
  listeContactId: number;
  champRechercheListeContact = '';
  idListeContactSelectionnees: Array<any>;
  idListeContactASauvegarder: Array<any>;
  listeListeContactASauvegarder: Array<ListeContact> = [];
  listeContactDejaAssocies: Array<ListeContact> = [];
  listeListeContactChoix: Array<any> = [];
  listeListeContactIntranet: Array<any> = [];
  listeContactSelectionner: ListeContact;
  supportsAssocies: Array<Support> = [];
  rechercheListeContactEncours =  false;
  idSupportPrefere: number;
  idListeContactAsso = [];
  selectElement: Array<any> = [];
  constructor(private route: ActivatedRoute, private contactService: ContactService,
              private listeContactService: ListeContactService, private dialogueShareService: DialogueShareService,
              public formatIntranetShareService: FormatIntranetShareService,
              private openWithPostShareService: OpenWithPostShareService, private supportService: SupportService) { }

  ngOnInit() {
    this.listeListeContactASauvegarder = [];
    this.listeListeContactChoix = [];
    this.listeContactId = 0;
    this.idSupportPrefere = 0;
    this.addOpenMAJ = false;
    this.sub = this.route.params.subscribe( params => {
      this.contactService.getContactListeContactByContactID(params.id).then(retour => {
        this.listeContactContact = retour['hydra:member'];
        if (this.listeContactContact.length > 0) {
          this.listeContactContact.forEach(asso => {
            this.listeListeContactASauvegarder.push(asso.listeContact);
            this.listeContactDejaAssocies.push(asso.listeContact);
            this.idListeContactAsso.push(asso.listeContact.ancienId);
            if (asso.supportPrefere != null && asso.supportPrefere.id > 0) {
              this.idSupportPrefere = asso.supportPrefere.id;
            }
          });
        }
      });
      this.contactService.getContactSupportBycontactID(params.id).then(retour => {
        retour['hydra:member'].forEach(contactSupport => {
          this.supportsAssocies.push(contactSupport.support);
        });
      });
    });
  }
  cancel() {
    this.ngOnInit();
  }
  setSelected(selectElement, action) {
      const groupe: Array<any> = [];
      let optionSelected;
      for (let i = 0; i < selectElement.options.length; i++) {
        if (selectElement.options[i].selected) {
          optionSelected = selectElement.options[i].value;
          // Le format de value est de [0: '1']
          if (optionSelected.indexOf('\'') !== -1) {
            // extraire le vrai id sauvegarder
            optionSelected = optionSelected.indexOf('\'');
            optionSelected = selectElement.options[i].value.slice(optionSelected);
            optionSelected = optionSelected.toString();
          }
          groupe.push(optionSelected);
        }
      }
      this.selectElement = groupe;
      if (action === 'ajout') {
        this.idListeContactSelectionnees = groupe;
      } else if (action === 'sup') {
        this.idListeContactASauvegarder = groupe;
      }
  }
  detailListeContact(selectElement) {
    this.openWithPostShareService.
    openWindowWithPost('http://intranet.escalconsulting.com/listecontact_cms.php?action=vuedetail&ls_ID=' + selectElement[0], []);
  }

  rechercheListeContact() {
    if (this.champRechercheListeContact.length > 2 && !this.rechercheListeContactEncours) {
      this.rechercheListeContactEncours = true;
      this.listeContactService.getListeContactIntranet(this.champRechercheListeContact).then((retour) => {
        this.listeListeContactIntranet = retour;
        this.listeListeContactChoix = retour;
        // éviter les doublons dans la liste de selection en cas d'une nouvelle recherche
        if (this.listeListeContactASauvegarder.length > 0) {
          this.listeListeContactASauvegarder.forEach(listeContactASave => {
            this.listeListeContactChoix =
              this.listeListeContactChoix.filter(listeContactChoix => listeContactChoix.id !== listeContactASave.id);
          });
        }
      });
    }
    this.rechercheListeContactEncours = false;
  }
  changeListeContact(action) {
    let listeListeContact;
    let listeContactPresent;
    this.addOpenMAJ = true;
    if (action === 'add') {
      listeListeContact = this.idListeContactSelectionnees;
    } else {
      listeListeContact = this.idListeContactASauvegarder;
    }
    // Gestion de la liste à sauvegarder et la liste de selection
    listeListeContact.forEach((idListeContactSelectione) => {
      this.listeListeContactIntranet.forEach(listeCont => {
        if (idListeContactSelectione == listeCont.id) {
          this.listeContactSelectionner = listeCont as ListeContact;
          this.listeListeContactChoix =
            this.listeListeContactChoix.filter(listeContactSave => listeContactSave.id !== idListeContactSelectione);
        } else {
          // retirer la(es) liste contact sélectionnée(s) dans la liste de categGen à sauvegarder
          this.listeListeContactASauvegarder =
            this.listeListeContactASauvegarder.filter(listeContactSave => listeContactSave.id !== idListeContactSelectione);
        }
      });
      if (action === 'add') {
        // retirer la(es) liste contact sélectionnée(s) dans la liste de liste contact de base
        // évite les doublons
        listeContactPresent = this.listeListeContactASauvegarder.find((listeCont) => listeCont.id === this.listeContactSelectionner.id);
        if (listeContactPresent === undefined) {
          this.listeListeContactASauvegarder.push(this.listeContactSelectionner);
        }
      } else if (action === 'delete') {
        // retirer la(es) liste contact sélectionnée(s) dans la liste de liste contact à sauvegarder
        this.listeListeContactASauvegarder =
          this.listeListeContactASauvegarder.filter(listeContactSave => listeContactSave.ancienId != idListeContactSelectione);
      }
    });
  }
  MAJListeContactAssocies() {
    this.listeListeContactASauvegarder.forEach(async liste => {
      let ancienID;
      if (liste.ancienId == undefined) {
        ancienID = liste.id;
      } else {
        ancienID = liste.ancienId;
      }
      await this.contactService.importerListeContact(ancienID).then(() => {
        this.listeContactService.getListeContactEscalnetByAncienID(ancienID).then((listeContact) => {
          console.log(listeContact['hydra:member'][0]);
          const listeContactId = listeContact['hydra:member'][0].id;
          const listeContactaAncienId = listeContact['hydra:member'][0].ancienId;
          // Eviter de créer un asso en doublon
          if (this.idListeContactAsso.includes(listeContactaAncienId) == false) {
            // Pour enregistrer le support préférer sur intranet, on a besoin de son ancien id
            if (this.idSupportPrefere > 0) {
              this.supportService.getSupportDetails(this.idSupportPrefere).then(support => {
                const ancienIdSupportPrefere = support.ancienId;
                this.contactService.addListeContactContact(listeContactId, this.contact.id, this.idSupportPrefere).then(() => {
                  this.contactService.addContactAssoIntranet('liste', this.contact.id, ancienID, ancienIdSupportPrefere).then(() => {
                    this.dialogueShareService.addDialogue('La(es) liste(s) de contacts est (sont) bien associée(s)');
                  });
                });
              });
          } else {
              this.contactService.addListeContactContact(listeContactId, this.contact.id, this.idSupportPrefere).then(() => {
                this.contactService.addContactAssoIntranet('liste', this.contact.id, ancienID).then(() => {
                  this.dialogueShareService.addDialogue('La(es) liste(s) de contacts est (sont) bien associée(s)');
                });
              });
            }
          }
        });
        });
       });
    // Supprimer les anciens asso si jamais il y en a
    if (this.listeContactDejaAssocies.length > 0) {
      let idAsso;
      this.listeContactDejaAssocies.forEach(async liste => {
        if (this.listeListeContactASauvegarder.includes(liste) == false) {
          this.listeContactContact.forEach(asso => {
            if (asso.listeContact.id === liste.id) {
              idAsso = asso.id;
            }
          });
          await this.contactService.deleteContactAssoIntranet('liste', idAsso).then(() => {
            this.contactService.deleteListeContactContact(idAsso).then(() => {
              this.dialogueShareService.addDialogue('La(es) liste(s) de contacts associée(s) est(sont) à jour');
          });
          });
        }
      });
    }
  }
}
