import { Component, OnInit } from '@angular/core';
import {DialogueShareService} from '../../../service/partage/utilitaire/DialogueShareService';

@Component({
  selector: 'app-utilitaire-dialogue',
  templateUrl: './utilitaire-dialogue.component.html',
  styleUrls: ['./utilitaire-dialogue.component.scss']
})
export class UtilitaireDialogueComponent implements OnInit {

  constructor(public dialogueShareService: DialogueShareService) { }

  ngOnInit() {
  }

}
