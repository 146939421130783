import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';
import {removeAccents} from '../../partage/prototype/sansAccents';


@Injectable()
export class AuthUserService{
  private authUserUrl = Environement.BASE_API_URL + '/api/auth_users';
  constructor(private http: HttpClient) { };

  getUser(id): Promise<any>{
    const headers = this.initOptionHeader();
    return this.http.get(this.authUserUrl + '/' +  id, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getUsersList(actuel): Promise<any>{
    const headers = this.initOptionHeader();
    return this.http.get(this.authUserUrl + '?pagination=false&actuel=' + actuel + '&uSysteme=0&order[user.prenom]', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getUsers(page, actuel): Promise<any>{
    let headers = this.initOptionHeader();
    return this.http.get(this.authUserUrl + '?_page=' + page + '&actuel=' + actuel + '&uSysteme=0&order[user.prenom]', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getUsersRecherche(recherche: Array<any>, page, actuel){
    const content = recherche[0];
    let type = recherche[1];
    const headers = this.initOptionHeader();
    type = removeAccents(type.toLowerCase());
    if (type === 'tous'){
      return this.http.get(this.authUserUrl + '?several_all=' + content + '&_page=' + page + '&actuel=' + actuel + '&order[user.nom]',
        {headers: headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    return this.http.get(this.authUserUrl + '?user.' + type + '=' + content + '&_page=' + page + '&actuel=' + actuel + '&order[user.nom]',
                {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  changePassword(id, password): Promise<any>{
    const headers = this.initOptionHeader();
    return this.http.put(this.authUserUrl + '/' + id, {'password': password }, {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateTelInterne(idAuthUser, tel){
    let headers = this.initOptionHeader();
    return this.http.put(this.authUserUrl + '/' + idAuthUser, {'telInterne': tel}, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addAuthUser(username, plainPassword, passswordMail, userId, fonction, telInterne, role, oldLogin, oldPassword, uSysteme){
    const realRole = [role];
    const headers = this.initOptionHeader();
    return this.http.post(this.authUserUrl , {'username': username, 'plainPassword': plainPassword, 'passwordMail': passswordMail,
    'user': userId, 'actuel': true, 'fonction': fonction, 'telInterne': telInterne.toString(), 'roles': realRole, 'oldLogin': oldLogin,
      'oldPassword': oldPassword , 'uSysteme': uSysteme, 'respConge': false},
      {headers: headers} )
      .toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  desactiverUser(idAuthUser): Promise<any>{
    let headers = this.initOptionHeader();
    return this.http.put(this.authUserUrl + '/' + idAuthUser, {'actuel': false }, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  };
  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
