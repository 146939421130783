import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';

import {PartieCp} from '../../../model/mailling/PartieCp';



@Injectable()
export class PartieCpService {
    private partieCpUrl = Environement.BASE_API_URL + '/api/partie_cps';
    constructor(private http: HttpClient) { }

  postPartieCp(idMailling, partieCp: PartieCp) {
    const headers = this.initOptionHeader();
    return this.http.post(this.partieCpUrl, {'partie': partieCp.partie,'contenu': partieCp.contenu, 'mailling': '/api/maillings/' + idMailling},
      {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  putPartieCp(partieCp: PartieCp) {
    const headers = this.initOptionHeader();
    return this.http.put(this.partieCpUrl + '/' + partieCp.id, {'partie': partieCp.partie,'contenu': partieCp.contenu}, {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  deletePartieCp(id) {
    const headers = this.initOptionHeader();
    return this.http.delete(this.partieCpUrl + '/' + id, {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers; }
}
