import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';


@Injectable()
export class CoordonneesService {
  private coordonneesURL = Environement.BASE_API_URL + '/api/coordonnees';
  constructor(private http: HttpClient) { }

  addCoordonneeOrganisation(coordonnee, organisationId, paysId) {
    const headers = this.initOptionHeader();
    let pays = null;
    const DateCreFormat = coordonnee.creation.getFullYear() + '-' + (coordonnee.creation.getMonth() + 1).toString() + '-' + coordonnee.creation.getDate() + ' ' + coordonnee.creation.getHours() + ':' + coordonnee.creation.getMinutes() + ':00' ;
    // si pays renseigné
    if (paysId != 0) {
      pays = Environement.BASE_API_URL + '/api/pays/' + paysId;
    }
    return this.http.post(this.coordonneesURL, {'premierTel': coordonnee.premierTel, 'deuxiemeTel': coordonnee.deuxiemeTel, 'email': coordonnee.email,
        'rue': coordonnee.rue, 'complements': coordonnee.complements, 'boitePostale': coordonnee.boitePostale,
        'codePostal': coordonnee.codePostal, 'ville': coordonnee.ville,
        'pays': pays,
        'premierURL': coordonnee.premierURL, 'deuxiemeURL': coordonnee.deuxiemeURL,
        'organisation': Environement.BASE_API_URL + '/api/organisations/' + organisationId,
        'auteur': Environement.BASE_API_URL + '/api/auth_users/' + coordonnee.auteur.id,
        'creation': DateCreFormat},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
}

  modifierCoordonnees(idCoordSocMaj, coordonnee, organisationId, paysId) {
    const headers = this.initOptionHeader();
    let pays = null;
    let auteur = null;
    if (coordonnee.auteur != null) {
      auteur = Environement.BASE_API_URL + '/api/auth_users/' + coordonnee.auteur.id;
    }
    coordonnee.modification = new Date();
    const DateModifFormat = coordonnee.modification.getFullYear() + '-' + (coordonnee.modification.getMonth() + 1).toString() + '-' + coordonnee.modification.getDate() + ' ' + coordonnee.modification.getHours() + ':' + coordonnee.modification.getMinutes() + ':00' ;
    // si pays renseigné
    if (paysId != 0) {
      pays = Environement.BASE_API_URL + '/api/pays/' + paysId;
    }
    return this.http.put(this.coordonneesURL + '/' +  idCoordSocMaj, {'premierTel': coordonnee.premierTel, 'deuxiemeTel': coordonnee.deuxiemeTel, 'email': coordonnee.email,
        'rue': coordonnee.rue, 'complements': coordonnee.complements, 'boitePostale': coordonnee.boitePostale,
        'codePostal': coordonnee.codePostal, 'ville': coordonnee.ville,
        'pays': pays,
        'premierURL': coordonnee.premierURL, 'deuxiemeURL': coordonnee.deuxiemeURL,
        'organisation': Environement.BASE_API_URL + '/api/organisations/' + organisationId,
        'auteur': auteur,
        'modification': DateModifFormat},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getCoordonneesDetails(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.coordonneesURL + '/' + id , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }


  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
