import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {Environement} from '../../../../../environement/environement';

import {LogoService} from '../../../../../service/interne/utilitaire/LogoService';

import {Client} from '../../../../../model/client/Client';

@Component({
  selector: 'app-escal-client-fiche-logo',
  templateUrl: './escal-client-fiche-logo.component.html',
  styleUrls: ['./escal-client-fiche-logo.component.scss'],
  providers: [ LogoService ],
})
export class EscalClientFicheLogoComponent implements OnInit {

  @Input()
  client: Client;
  @Output()
  refresh = new EventEmitter();
  baseServeur =  Environement.BASE_API_URL;
  logoUpload: Array<any> = [];

  constructor( private logoService: LogoService) { }

  ngOnInit() {
  }
  deleteLogo(){
    const that = this;
    this.logoService.deleteLogo(this.client.logo.id)
      .then((retour) => {
        that.refresh.emit();
      });
  }
  saveLogo(){
    const that = this;
    this.logoService.addLogo(this.client.id, this.logoUpload)
      .then((retour) => {
        that.refresh.emit();
      });
  }

  changeLogo(event){
    const that = this;
    const reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        that.logoUpload['filename'] = file.name;
        that.logoUpload['filetype'] = file.type;
        // @ts-ignore
        that.logoUpload['value'] = reader.result.split(',')[1];
      };
    }
  }

}
