import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Contact} from '../../../../../model/contact/Contact';
import {Suivi} from '../../../../../model/suivi/Suivi';
import {
  PaginationPaginateurComponent
} from '../../../../utilitaire/pagination/pagination-paginateur/pagination-paginateur.component';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {ContactService} from '../../../../../service/interne/contact/ContactService';

@Component({
  selector: 'app-escal-contact-fiche-suivis',
  templateUrl: './escal-contact-fiche-suivis.component.html',
  styleUrls: ['./escal-contact-fiche-suivis.component.scss']
})
export class EscalContactFicheSuivisComponent implements OnInit {
  @Input()
  contact: Contact;
  @Output()
  refresh = new EventEmitter();
  constructor(private contactService: ContactService, public formatIntranetShareService: FormatIntranetShareService) { }
  suivisAssocies: Array<Suivi>;
  curentPage = 1;
  maxPage: number;
  total: number;
  fakeArray: Array<any>;
  page: number;
  @ViewChild(PaginationPaginateurComponent) paginationComponent: PaginationPaginateurComponent;
  ngOnInit() {
    this.page = 1;
    this.contactService.getAllSuivisContact(this.contact.id, this.curentPage).then((suivis) => {
      this.suivisAssocies = suivis['hydra:member'] as Array<Suivi>;
      this.total = suivis['hydra:totalItems'];
      this.getMaxPage(suivis['hydra:totalItems']);
      this.fakeArray = new Array(this.maxPage);
    });
  }
  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }

  changePage(page) {
    this.curentPage = page;
    this.ngOnInit();
  }

  accesPage() {
    this.curentPage = this.page;
    this.paginationComponent.page = this.page;
    this.ngOnInit();
    this.paginationComponent.initPage();
  }
}
