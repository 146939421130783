import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Client} from '../../../../../model/client/Client';
import {Environement} from '../../../../../environement/environement';
import {Router} from '@angular/router';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {ClientService} from '../../../../../service/interne/client/ClientService';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';
import {OpenWithPostShareService} from '../../../../../service/partage/utilitaire/OpenWithPostShareService';
import {OrganisationService} from '../../../../../service/interne/organisation/OrganisationService';

@Component({
  selector: 'app-escal-client-fiche-informations',
  templateUrl: './escal-client-fiche-informations.component.html',
  styleUrls: ['./escal-client-fiche-informations.component.scss'],
  providers: [ OrganisationService ]
})
export class EscalClientFicheInformationsComponent implements OnInit {

  @Input()
  client: Client;
  premierMoitieDirigeant: string;
  secondMoitieDirigeant: string;
  premierMoitieClient: string;
  secondMoitieClient: string;
  premierMoitieGene: string;
  secondMoitieGene: string;
  premierMoitieCoup: string;
  secondMoitieCoup: string;
  afficheRapport: boolean;
  afficheListe: boolean;
  afficheOptions: boolean;
  afficheThemes: boolean;
  rechercheEncoursListe: boolean;
  rechercheEncoursListeItw: boolean;
  rechercheEncoursTheme: boolean;
  champRechercheListe: string;
  champRechercheListeItw: string;
  champRechercheTheme: string;
  listesRecherche: Array<any> = [];
  listesItwRecherche: Array<any> = [];
  categoriesRecherche: Array<any> = [];
  listeId: number;
  listeItwId: number;
  themeId: number;
  startMoment: Date;
  dateDebut: Date;
  dateFin: Date;
  themesId: Array<any> = [];
  categories: Array<any> = [];
  @Output()
  refresh = new EventEmitter();
  @Output()
  baseServeur =  Environement.BASE_API_URL;
  constructor(private router: Router, public formatIntranetShareService: FormatIntranetShareService, private clientService: ClientService,
              private dialogueShareService: DialogueShareService, private openWithPostService: OpenWithPostShareService,
              private organisationService: OrganisationService) { }

  ngOnInit() {
    this.afficheRapport = false;
    this.afficheListe = false;
    this.afficheOptions = false;
    this.afficheThemes = false;
    this.rechercheEncoursListe = false;
    this.rechercheEncoursListeItw = false;
    this.champRechercheListe = '';
    this.champRechercheListeItw = '';
    this.listeId = 0;
    this.listeItwId = 0;
    this.themeId = 0;
    // si chaine de caractère trop longue faire un retour à la ligne
    if (this.client.dirigeant) {
      if (this.client.dirigeant.length > 25) {
        this.premierMoitieDirigeant = this.client.dirigeant.substring(0, 25);
        this.secondMoitieDirigeant = this.client.dirigeant.substring(25);
      }
    }
    if (this.client.nom.length > 25) {
      this.premierMoitieClient = this.client.nom.substring(0, 25);
      this.secondMoitieClient = this.client.nom.substring(25);
    }
    if (this.client.adresseGenerique.length > 45) {
      this.premierMoitieGene = this.client.adresseGenerique.substring(0, 45);
      this.secondMoitieGene = this.client.adresseGenerique.substring(45);
    }
    if (this.client.adresseCoupure.length > 45) {
      this.premierMoitieCoup = this.client.adresseCoupure.substring(0, 45);
      this.secondMoitieCoup = this.client.adresseCoupure.substring(45);
    }
    this.clientService.getListeContactRecherche('~').then((retour) => {
      this.listesRecherche = retour;
      this.listesRecherche.forEach(liste => {
        if (liste.nom != '' && liste.nom != null) {
          liste.nom = liste.nom.replace('&amp;', '&');
          liste.nom = liste.nom.replace('&#039;', '’');
          liste.nom = liste.nom.replace('L&#039;', 'L’');
        }
      });
    });
    this.clientService.getAssoClientListeContact(this.client.ancienId).then((asso) => {
      this.listeId = asso.id;
      if(this.listeId == undefined) {
        this.listeId = 0;
      }
    });
    this.clientService.getThemesClient(this.client.ancienId).then((retour) => {
      this.categories = retour;
      this.categories.forEach(categ => {
        if (categ.libelle != '' && categ.libelle != null) {
          categ.libelle = categ.libelle.replace('&amp;', '&');
          categ.libelle = categ.libelle.replace('&#039;', '’');
          categ.libelle = categ.libelle.replace('L&#039;', 'L’');
        }
      });
    });
  }

  afficherRapport() {
    if (this.afficheRapport === false) {
      this.afficheRapport = true;
      this.afficheListe = false;
      this.afficheOptions = false;
      this.afficheThemes = false;
    } else {
      this.afficheRapport = false;
    }
  }

  afficherListe() {
    if (this.afficheListe === false) {
      this.afficheListe = true;
      this.afficheRapport = false;
      this.afficheOptions = false;
      this.afficheThemes = false;
    } else {
      this.afficheListe = false;
    }
  }

  afficherOptions() {
    if (this.afficheOptions === false) {
      this.afficheOptions = true;
      this.afficheRapport = false;
      this.afficheListe = false;
      this.afficheThemes = false;
    } else {
      this.afficheOptions = false;
    }
  }

  afficherThemes() {
    if (this.afficheThemes === false) {
      this.afficheThemes = true;
      this.afficheRapport = false;
      this.afficheListe = false;
      this.afficheOptions = false;
    } else {
      this.afficheThemes = false;
    }
  }

  rechercheListe() {
    this.listesRecherche = [];
    this.listeId = 0;
    if (this.champRechercheListe.length > 2 && !this.rechercheEncoursListe) {
      this.rechercheEncoursListe = true;
      this.champRechercheListe = this.champRechercheListe.replace(' ', '.');
      this.clientService.getListeContactRecherche(this.champRechercheListe).then((retour) => {
        this.listesRecherche = retour;
        this.listeId = this.listesRecherche[0].id;
        this.listesRecherche.forEach(liste => {
          if (liste.nom != '' && liste.nom != null) {
            liste.nom = liste.nom.replace('&amp;', '&');
            liste.nom = liste.nom.replace('&#039;', '’');
            liste.nom = liste.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheListe = this.champRechercheListe.replace('.', ' ');
      });
      this.rechercheEncoursListe = false;
    } else {
      this.clientService.getListeContactRecherche('~').then((retour) => {
        this.listesRecherche = retour;
        this.listesRecherche.forEach(liste => {
          if (liste.nom != '' && liste.nom != null) {
            liste.nom = liste.nom.replace('&amp;', '&');
            liste.nom = liste.nom.replace('&#039;', '’');
            liste.nom = liste.nom.replace('L&#039;', 'L’');
          }
        });
      });
    }
  }

  associerListe() {
    if (this.listeId != 0) {
      this.clientService.associerListeContactClient(this.listeId, this.client.ancienId).then((retour) => {
        this.dialogueShareService.addDialogue('Liste envoi coupures associée');
      });
    }
  }

  rechercheListeItw() {
    this.listesItwRecherche = [];
    this.listeItwId = 0;
    if (this.champRechercheListeItw.length > 2 && !this.rechercheEncoursListeItw) {
      this.rechercheEncoursListeItw = true;
      this.champRechercheListeItw = this.champRechercheListeItw.replace(' ', '.');
      this.clientService.getListeItwArticleRecherche(this.champRechercheListeItw).then((retour) => {
        this.listesItwRecherche = retour;
        this.listeItwId = this.listesItwRecherche[0].id;
        this.listesItwRecherche.forEach(liste => {
          if (liste.nom != '' && liste.nom != null) {
            liste.nom = liste.nom.replace('&amp;', '&');
            liste.nom = liste.nom.replace('&#039;', '’');
            liste.nom = liste.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheListeItw = this.champRechercheListeItw.replace('.', ' ');
      });
      this.rechercheEncoursListeItw = false;
    }
  }

  majListeItw() {
    if (this.listeItwId != 0) {
      this.clientService.majListeItwArticle(this.listeItwId, this.client.ancienId).then((retour) => {
        this.dialogueShareService.addDialogue('Liste mise à jour');
      });
    }
  }

  getRapportParution() {
    if (this.dateDebut != undefined && this.dateFin != undefined) {
      const dateDeb = this.dateDebut.getFullYear() + '-' + (this.dateDebut.getMonth() + 1).toString() + '-' + this.dateDebut.getDate();
      const dateFin = this.dateFin.getFullYear() + '-' + (this.dateFin.getMonth() + 1).toString() + '-' + this.dateFin.getDate();
      let url: string;
      this.clientService.exporterRapportParutions(this.client.ancienId, dateDeb, dateFin).then(() => {
        url = Environement.BASE_API_URL + '/excelStat/' + this.client.ancienId + 'RapportParution.xlsx';
        this.openWithPostService.openWindows(url);
      });
    } else {
      this.dialogueShareService.addDialogue('Les dates sont obligatoires');
    }
  }

  rechercheThemes() {
    if (this.champRechercheTheme.length > 2 && !this.rechercheEncoursTheme) {
      this.rechercheEncoursTheme = true;
      this.champRechercheTheme = this.champRechercheTheme.replace(' ', '.');
      this.organisationService.getCategoriesRecherche(this.champRechercheTheme).then((retour) => {
        this.categoriesRecherche = retour;
        this.themeId = this.categoriesRecherche[0].id;
        this.categoriesRecherche.forEach(categorie => {
          if (categorie.nom != '' && categorie.nom != null) {
            categorie.nom = categorie.nom.replace('&amp;', '&');
            categorie.nom = categorie.nom.replace('&#039;', '’');
            categorie.nom = categorie.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheTheme = this.champRechercheTheme.replace('.', ' ');
      });
      this.rechercheEncoursTheme = false;
    }
  }

  associerTheme() {
    if (this.themeId != 0) {
      this.clientService.associerThemeClient(this.themeId, this.client.ancienId).then((retour) => {
        // recharger la liste des themes du client
        this.categories = [];
        this.clientService.getThemesClient(this.client.ancienId).then((retour) => {
          this.categories = retour;
          this.categories.forEach(categ => {
            if (categ.libelle != '' && categ.libelle != null) {
              categ.libelle = categ.libelle.replace('&amp;', '&');
              categ.libelle = categ.libelle.replace('&#039;', '’');
              categ.libelle = categ.libelle.replace('L&#039;', 'L’');
            }
          });
        });
        this.dialogueShareService.addDialogue('Thème ajouté');
      });
    }
  }

  desassocierTheme() {
    if (this.themesId.length > 0) {
      let themesIdsSelect = '';
      // envoi les id à l'api sous forme de chaine de caractère
      this.themesId.forEach( themeId => {
        themesIdsSelect = themesIdsSelect + themeId + ',';
      });
      this.clientService.desassocierThemesClient(themesIdsSelect, this.client.ancienId).then((retour) => {
        // recharger la liste des themes du client
        this.categories = [];
        this.clientService.getThemesClient(this.client.ancienId).then((retour) => {
          this.categories = retour;
          this.categories.forEach(categ => {
            if (categ.libelle != '' && categ.libelle != null) {
              categ.libelle = categ.libelle.replace('&amp;', '&');
              categ.libelle = categ.libelle.replace('&#039;', '’');
              categ.libelle = categ.libelle.replace('L&#039;', 'L’');
            }
          });
        });
        this.dialogueShareService.addDialogue('Thème(s) supprimé(s)');
      });
    }
  }
}
