import {Component, OnInit, Output, ViewChild} from '@angular/core';
import {Suivi} from '../../../../model/suivi/Suivi';
import {SuiviActionShareService} from '../../../../service/partage/SuiviActionShareService';
import {PaginationRechercheComponent} from '../../../utilitaire/pagination/pagination-recherche/pagination-recherche.component';

@Component({
  selector: 'app-escal-suivi-list',
  templateUrl: './escal-suivi-list.component.html',
  styleUrls: ['./escal-suivi-list.component.scss'],
  providers: [ SuiviActionShareService ],
})
export class EscalSuiviListComponent implements OnInit {

  curentPage = 1;
  suivis: Array<Suivi>;
  headerContent: Array<any> = [];
  total: number;
  maxPage: number;
  typeRecherche: Array<any> = [];
  recherche: Array<any> = [];
  actuel: number;

@ViewChild(PaginationRechercheComponent) suivisIntranet: PaginationRechercheComponent;
  constructor(private suiviActionShareService: SuiviActionShareService) { }

  ngOnInit() {
    this.initContent();
    this.headerContent.push('');
    this.headerContent.push('Titre');
    this.headerContent.push('Contact');
    this.headerContent.push('Suivi par');
    this.headerContent.push('Date');

    this.typeRecherche.push('Tous');
    this.typeRecherche.push('Titre');
    this.typeRecherche.push('Client');
    this.typeRecherche.push('Contact');
    this.typeRecherche.push('Collaborateur');
    this.typeRecherche.push('Support');
    this.typeRecherche.push('Intranet');
  }
  initContent() {
    const that = this;
    if ( this.recherche.length === 0) {
      if (!this.suivisIntranet.jours) {
        // affichage par defaut
        this.suiviActionShareService.getSuivisParDefaut(this.curentPage, 30)
          .then((data) => {
            that.suivis = data['hydra:member'] as Array<Suivi>;
            that.total = data['hydra:totalItems'];
            that.getMaxPage(data['hydra:totalItems']);
          });
      } else {
        this.suiviActionShareService.getSuivisParDefaut(this.curentPage, this.suivisIntranet.jours)
          .then((data) => {
            that.suivis = data['hydra:member'] as Array<Suivi>;
            that.total = data['hydra:totalItems'];
            that.getMaxPage(data['hydra:totalItems']);
          });
      }
    } else if (this.recherche[0].length >= 3 ) {
      if (this.recherche[0] == 'changeJours') {
        // si changement de jours sans contenu recherche
        this.suiviActionShareService.getSuivisParDefaut(this.curentPage, this.recherche[2])
          .then((data) => {
            that.suivis = data['hydra:member'] as Array<Suivi>;
            that.total = data['hydra:totalItems'];
            that.getMaxPage(data['hydra:totalItems']);
          });
      } else {
        // si contenu recherche
        this.suiviActionShareService.getSuivisRecherche(this.recherche, this.curentPage)
          .then((data) => {
            that.suivis = data['hydra:member'] as Array<Suivi>;
            that.total = data['hydra:totalItems'];
            that.getMaxPage(data['hydra:totalItems']);
          });
      }
    }
  }
  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }
  changePage(page) {
    this.curentPage = page;
    this.initContent();
  }

  updateRecherche(recherche: Array<any>) {
    this.curentPage = 1;
    if (recherche[0] === '') {
      this.recherche = [];
    } else {
      this.recherche = recherche;
    }
    this.initContent();
  }

}
