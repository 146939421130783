import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-pagination-content-contact-intranet',
  templateUrl: './pagination-content-contact-intranet.component.html',
  styleUrls: ['./pagination-content-contact-intranet.component.scss']
})
export class PaginationContentContactIntranetComponent implements OnInit {

  @Input()
  contacts: Array<any>;
  constructor() { }

  ngOnInit() {
    this.contacts.forEach(contact => {
      contact.nomPrenom = contact.nomPrenom.replace('&amp;', '&');
      contact.nomPrenom = contact.nomPrenom.replace('&#039;', '’');
      contact.nomPrenom = contact.nomPrenom.replace('L&#039;', 'L’');
    });
  }
}
