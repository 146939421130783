import {Component, OnInit, ViewChild} from '@angular/core';
import {MaillingService} from '../../../../service/interne/mailling/maillingService';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {Mailling} from '../../../../model/mailling/Mailling';
import {Client} from '../../../../model/client/Client';

@Component({
  selector: 'app-escal-mailling-list-send',
  templateUrl: './escal-mailling-list-send.component.html',
  styleUrls: ['./escal-mailling-list-send.component.scss'],
  providers: [ MaillingService, ClientService ],
})
export class EscalMaillingListSendComponent implements OnInit {


  @ViewChild('dt') datetimepicker: any;
  CPcheck: boolean = true;
  COcheck: boolean = true;
  curentPage: number = 1;
  maillings: Array<Mailling>;
  headerContent: Array<any> = [];
  maxPage: number;
  clients: Array<Client>;
  selectClientIdVal: number;
  rechercheContent = '';
  rechercheActive: string = '';

  constructor( private maillingService: MaillingService, public clientService: ClientService) {
    document.addEventListener('click', (event) => this.onClick(event));
  }

  ngOnInit() {
    this.initContent();
    this.headerContent.push('Nom');
    this.headerContent.push('objet');
    this.headerContent.push('client');
    this.headerContent.push('date envoi');
  }

  initContent() {
    const that = this;
    that.clients = [];
    if (that.selectClientIdVal === undefined) {
      that.selectClientIdVal = -1;
      this.maillingService.getMaillingSend(this.curentPage, this.COcheck, this.CPcheck)
        .then((data) => {
          that.maillings = data['hydra:member'] as Array<Mailling>;
          that.getMaxPage(data['hydra:totalItems']);
          this.clientService.getClientList(1)
            .then((clientsRetour) => {
              that.clients = clientsRetour['hydra:member'] as Array<Client>;
            });
        });
    } else {
      if (this.CPcheck === false && this.COcheck === false){
        that.maillings = [];
        that.getMaxPage(0);
      } else {
        this.maillingService.getMaillingSend(this.curentPage, this.COcheck, this.CPcheck, this.selectClientIdVal, this.rechercheContent)
          .then((data) => {
            console.log(data['hydra:member']);
            that.maillings = data['hydra:member'] as Array<Mailling>;
            that.getMaxPage(data['hydra:totalItems']);
          });
      }
    }

  }

  miseAjourRecherche() {
    const that = this;
    this.curentPage = 1;
    this.maillingService.getMaillingSend(this.curentPage, this.COcheck, this.CPcheck, this.selectClientIdVal, this.rechercheContent)
      .then((data) => {
        that.maillings = data['hydra:member'] as Array<Mailling>;
        that.getMaxPage(data['hydra:totalItems']);
      });
  }

  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }
  changePage(page) {
    this.curentPage = page;
    this.initContent();
  }

  updateRechercheContent(event) {
    this.miseAjourRecherche();
  }

  onClick(event) {
    const elem = event['target'];
    if ( elem.classList.contains('rechercheActive')) {
      this.rechercheActive = 'active';
    } else {
      if ( this.rechercheContent === '') {
        this.rechercheActive = '';
      } else {
        this.rechercheActive = 'active';
      }
    }
  }


}
