import {Component, OnInit} from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


import {PressroomAccueilService} from '../../../../../../service/interne/client/pressroom/PressroomAccueilService';
import {DialogueShareService} from '../../../../../../service/partage/utilitaire/DialogueShareService';
import {PressroomAccueilSection} from '../../../../../../model/client/pressroom/PressroomAccueilSection';
import {ImagePressroom} from '../../../../../../model/client/pressroom/ImagePressroom';
import {PressroomAccueilSectionService} from '../../../../../../service/interne/client/pressroom/PressroomAccueilSectionService';
import {Environement} from '../../../../../../environement/environement';
import {PressroomShareService} from '../../../../../../service/partage/PressroomShareService';

@Component({
  selector: 'app-pressroom-client-configuration-accueil',
  templateUrl: './pressroom-client-configuration-accueil.component.html',
  styleUrls: ['./pressroom-client-configuration-accueil.component.scss'],
  providers: [ PressroomAccueilService, PressroomAccueilSectionService]
})
export class PressroomClientConfigurationAccueilComponent implements OnInit {


  sectionEnModification: PressroomAccueilSection;

  public Editor = ClassicEditor;

  image: ImagePressroom;

  modification: string;

  constructor(public pressroomShareService: PressroomShareService,
              private pressroomAccueilService: PressroomAccueilService,
              private dialogueShareService: DialogueShareService,
              private pressroomAccueilSectionService: PressroomAccueilSectionService) { }

  ngOnInit() {
    this.modification = 'none';
    this.pressroomShareService.loadAcceuil();
    }


  savePageAccueil() {
    const that = this;
    if (this.pressroomShareService.pressroom.pressroomAccueil.id === 0) {
      this.pressroomAccueilService.addTitle(this.pressroomShareService.pressroom.pressroomAccueil, this.pressroomShareService.pressroom.id)
        .then(() => {
          that.pressroomShareService.initPressroom();
          that.modification = 'none';
      });
    } else {
      this.pressroomAccueilService.updateTitle(this.pressroomShareService.pressroom.pressroomAccueil)
        .then( () => {
          that.pressroomShareService.initPressroom();
          that.modification = 'none';
        });
    }
  }
  styleTitle() {
    const retour = {
      color: undefined
    };
    retour.color = this.pressroomShareService.pressroom.pressroomAccueil.couleurTitle;
    return retour;
  }

  modificationSection(section) {
    if ( section === null ) {
      this.sectionEnModification = new PressroomAccueilSection(0, '', '#000000', '',
        '', 'g', 'g', new ImagePressroom(0, '', 'imageSection'));
      this.image = null;
    } else {
      if (section.image === null || typeof section.image === 'undefined') {
        section.image = new ImagePressroom(0, '', 'imageSection');
        this.image = null;
      } else {
        this.image = section.image;
      }
      this.sectionEnModification = section;
    }
    this.modification = 'section';
  }


  positionContentChange(value) {
    if ( value !== this.sectionEnModification.contentDisposition) {
      this.sectionEnModification.contentDisposition = value;
    }
  }
  positionImageChange(value) {
    if (value !== this.sectionEnModification.imagePosition) {
      this.sectionEnModification.imagePosition = value;
    }
  }

  saveSection() {
    const that = this;
    if (this.sectionEnModification.id === 0) {
      this.pressroomAccueilSectionService.addPressroomAccueilSection(this.sectionEnModification,
        this.pressroomShareService.pressroom.pressroomAccueil.id,
        this.image)
        .then( () => {
          that.pressroomShareService.initPressroom();
          that.modification = 'none';
        });
    } else {
      this.pressroomAccueilSectionService.updatePressroomAccueilSection(this.sectionEnModification, this.image)
        .then( () => {
          that.pressroomShareService.initPressroom();
          that.modification = 'none';
        });
    }
  }

  getImageSource(image: ImagePressroom){
    return Environement.BASE_API_URL + '/image/pressroom/content/' + image.id;
  }
  getImageZoomUrl() {
    if (this.image === null) {
      return 'assets/application/images/noImage.png';
    } else {
      return this.getImageSource(this.image);
    }
  }

  isImageSelected(image: ImagePressroom) {
    if(image === null || this.image === null) {
      return false;
    }
    return image.id === this.image.id;
  }
}
