import { Component, OnInit } from '@angular/core';
import {AuthShareService} from '../../../service/partage/AuthShareService';
import {AuthUser} from '../../../model/user/authUser';
import {ArticleSiteService} from '../../../service/interne/Site/ArticleSiteService';
import {ArticleSite} from '../../../model/site/ArticleSite';
import {DialogueShareService} from '../../../service/partage/utilitaire/DialogueShareService';

@Component({
  selector: 'app-site-escal-article',
  templateUrl: './site-escal-article.component.html',
  styleUrls: ['./site-escal-article.component.scss'],
  providers: [ ArticleSiteService ]
})
export class SiteEscalArticleComponent implements OnInit {

  curentPage: number = 1;
  articles: Array<ArticleSite>;
  headerContent: Array<any> = [];
  maxPage: number;
  typeRecherche: Array<any> = [];
  recherche: Array<any> = [];

  constructor(public authShareService: AuthShareService, private articleSiteService: ArticleSiteService, private dialogueShareService: DialogueShareService) { }

  ngOnInit() {
    this.initContent();
    this.headerContent.push('Nom');
    this.headerContent.push('Mot Clé');
    this.headerContent.push('Action');

    this.typeRecherche.push('Nom');
  }

  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }
  changePage(page) {
    this.curentPage = page;
    this.initContent();
  }

  initContent() {
    const that = this;
    if( this.recherche.length === 0 ){
      this.articleSiteService.getArticles(this.curentPage)
        .then((data) => {
          that.articles = data['hydra:member'] as Array<ArticleSite>;
          that.getMaxPage(data['hydra:totalItems']);
        });
    } else {
      this.articleSiteService.getArticlesRecherche(this.recherche, this.curentPage)
        .then((data) => {
          that.articles = data['hydra:member'] as Array<ArticleSite>;
          that.getMaxPage(data['hydra:totalItems']);
        });
    }
  }

  updateRecherche(recherche: Array<any>) {
    this.curentPage = 1;
    if (recherche[0] === ''){
      this.recherche = [];
    } else {
      this.recherche = recherche;
    }
    this.initContent();
  }

  deleteArticle(event){
    const that = this;
    const variable = {
      'articleSiteService': this.articleSiteService, 'dialogueShareService': this.dialogueShareService, 'id': event
    };
    this.dialogueShareService.addDialogue('êtes vous sûre de vouloir supprimer l\'article', function () {
      variable['articleSiteService'].deleteArticle(variable['id'])
        .then((retour) => {
          variable['dialogueShareService'].addDialogue( 'atticle supprimé avec succés');
          that.initContent();
        })
        .catch((retour) => {
          variable['dialogueShareService'].addDialogue('erreur lors de la supression');
        });
    });
  }
  updateActifArticle(event: ArticleSite){
    const that = this;
    this.articleSiteService.updateActif(event).then((retour) => {
      that.initContent();
    })
  }
}
