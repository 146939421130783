import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ModalCalendrierShareService} from '../../../../service/partage/ModalCalendrierShareService';
import {EvenementService} from '../../../../service/interne/calendrier/EvenementService';
import {AuthShareService} from '../../../../service/partage/AuthShareService';
import {EvenementSalleService} from '../../../../service/interne/calendrier/EvenementSalleService';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-modal-calendrier-reservation',
  templateUrl: './modal-calendrier-reservation.component.html',
  styleUrls: ['./modal-calendrier-reservation.component.scss'],
  providers: [DatePipe]
})
export class ModalCalendrierReservationComponent implements OnInit {

  @Output() refreshCalendar = new EventEmitter<any>();

  startMoment: Date;
  dateRepeter: Date;
  dateSuivanteDebut: Date;
  dateSuivanteFin: Date;


  myFilter = (d: Date): boolean => {
    if (d.getFullYear().toString() + '-' + d.getMonth().toString()
      + '-' + d.getDate().toString() === this.modalCalendrierShareService.dateDebut.getFullYear().toString() +
      '-' + this.modalCalendrierShareService.dateDebut.getMonth().toString()
      + '-' + this.modalCalendrierShareService.dateDebut.getDate().toString()) {
      return true;
    }
    return (d >= this.modalCalendrierShareService.dateDebut);
  };

  constructor(public modalCalendrierShareService: ModalCalendrierShareService, private evenementService: EvenementService,
              private authShareService: AuthShareService, private evenementSalleService: EvenementSalleService,
              private dialogueShareService: DialogueShareService, private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.modalCalendrierShareService.evenementSalleService = this.evenementSalleService;
  }

  validationPossible() {
    if (this.modalCalendrierShareService.nom.length < 5) {
      return true;
    }
    if (this.modalCalendrierShareService.cbSalle === true) {
      if (this.modalCalendrierShareService.message !== '') {
        return true;
      }
      return false;
    }
    if(this.modalCalendrierShareService.cbClient === true){
      return false;
    }
    if(this.modalCalendrierShareService.cbEvent === true){
      return false;
    }
    return true;
  }

  validationEvent() {
    const that = this;
    let idSalle;
    let idClient;
    if (this.modalCalendrierShareService.cbSalle === true) {
      idSalle = that.modalCalendrierShareService.salleId;
    } else {
      idSalle = null;
    }
    if (this.modalCalendrierShareService.cbEvent === false) {
      this.modalCalendrierShareService.eventExterieurNom = '';
      this.modalCalendrierShareService.fullDay = false;
    }
    if (this.modalCalendrierShareService.cbClient === true) {
      idClient = that.modalCalendrierShareService.clientId;
    } else {
      idClient = null;
    }
    if (this.modalCalendrierShareService.typeEvent === 'modif') {
      const idEvent = this.modalCalendrierShareService.event.meta.id;
      this.evenementService.updateEventModif(idEvent, this.modalCalendrierShareService.nom, this.modalCalendrierShareService.dateDebut,
        this.modalCalendrierShareService.dateFin, this.authShareService.authUser.id, 'event', idSalle,
        this.modalCalendrierShareService.eventExterieurNom, this.modalCalendrierShareService.fullDay, idClient).then((retour) => {
        that.refreshCalendar.emit();
        that.modalCalendrierShareService.open = false;
      });
    } else {
      // si la réunion planifiée est répétée
      if (this.dateRepeter !== undefined) {
        // on créé le premier jour
        this.evenementService.addEvenement(this.modalCalendrierShareService.nom, this.modalCalendrierShareService.dateDebut,
          this.modalCalendrierShareService.dateFin, this.authShareService.authUser.id, 'event', idSalle,
          this.modalCalendrierShareService.eventExterieurNom, this.modalCalendrierShareService.fullDay, idClient).then((retour) => {
          that.evenementService.addParticipant(retour.id, this.authShareService.authUser.id).then((retour) => {
            that.refreshCalendar.emit();
            that.modalCalendrierShareService.open = false;
          });
        });
        // on crée ensuite tous les jours suivants
        this.dateSuivanteDebut = this.modalCalendrierShareService.dateDebut;
        this.dateSuivanteFin = this.modalCalendrierShareService.dateFin;
        this.dateSuivanteDebut.setDate( this.dateSuivanteDebut.getDate() + 7 );
        this.dateSuivanteFin.setDate( this.dateSuivanteFin.getDate() + 7 );
        // tant que on n'a pas atteint la date limite on continue
        while (this.dateSuivanteDebut < this.dateRepeter) {
          let dateDebutReel = new Date();
          let dateFinReel = new Date();
          // on commence par vérifier si la salle est dispo la date suivante
          dateDebutReel = new Date(this.dateSuivanteDebut.getTime() + -60 * (this.dateSuivanteDebut.getTimezoneOffset()) * 1000);
          dateFinReel = new Date(this.dateSuivanteFin.getTime() + -60 * (this.dateSuivanteFin.getTimezoneOffset()) * 1000);
          // gerer le decalage d'heure à l'ajout de l'evenement
          const dateDebReel = dateDebutReel;
          const dateFiReel = dateFinReel;
          dateDebReel.setHours(dateDebReel.getHours() - 1 );
          dateFiReel.setHours(dateFiReel.getHours() - 1 );
          that.evenementSalleService.checkDispoSalle(idSalle, this.dateSuivanteDebut, this.dateSuivanteFin)
            .then((retour) => {
              console.log(retour);
              if (retour.length > 0) {
                const dateErreur = this.datePipe.transform(dateDebutReel, 'dd MMMM');
                this.dialogueShareService.addDialogue('Impossible de réserver la salle le ' + dateErreur);
              } else {
                this.evenementService.addEvenement(this.modalCalendrierShareService.nom, dateDebReel,
                  dateFiReel, this.authShareService.authUser.id, 'event', idSalle,
                  this.modalCalendrierShareService.eventExterieurNom, this.modalCalendrierShareService.fullDay, idClient).then((retour) => {
                  that.evenementService.addParticipant(retour.id, this.authShareService.authUser.id).finally(() => {
                    that.refreshCalendar.emit();
                    that.modalCalendrierShareService.open = false;
                  });
                });
              }
            });
          this.dateSuivanteDebut.setDate( this.dateSuivanteDebut.getDate() + 7 );
          this.dateSuivanteFin.setDate( this.dateSuivanteFin.getDate() + 7 );
        }
      } else {
        // si la réunion n'est pas répétée
        this.evenementService.addEvenement(this.modalCalendrierShareService.nom, this.modalCalendrierShareService.dateDebut,
          this.modalCalendrierShareService.dateFin, this.authShareService.authUser.id, 'event', idSalle,
          this.modalCalendrierShareService.eventExterieurNom, this.modalCalendrierShareService.fullDay, idClient).then((retour) => {
          that.evenementService.addParticipant(retour.id, this.authShareService.authUser.id).then((retour) => {
            that.refreshCalendar.emit();
            that.modalCalendrierShareService.open = false;
          });
        });
      }
    }
  }

  verifDispoSalle() {
    this.modalCalendrierShareService.verifDispo();
  }

  getNomBouton() {
    if (this.modalCalendrierShareService.typeEvent === 'modif') {
      return 'Modifier l\'évenement';
    } else {
      return 'Ajouter l\'évenement';
    }

  }

  cbSalleChange() {
    if (this.modalCalendrierShareService.cbSalle === true) {
      this.modalCalendrierShareService.verifDispo();
    } else {
      this.modalCalendrierShareService.message = '';
    }
  }

}
