import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {AuthUserService} from '../../../../service/interne/user/AuthUserService';
import {AuthShareService} from '../../../../service/partage/AuthShareService';

import {AuthUser} from '../../../../model/user/authUser';

@Component({
  selector: 'app-escal-utilisateur-list',
  templateUrl: './escal-utilisateur-list.component.html',
  styleUrls: ['./escal-utilisateur-list.component.scss'],
  providers: [ AuthUserService ],
})
export class EscalUtilisateurListComponent implements OnInit {

  curentPage: number = 1;
  authUsers: Array<AuthUser>;
  headerContent: Array<any> = [];
  maxPage: number;
  typeRecherche: Array<any> = [];
  recherche: Array<any> = [];
  actuel: number;


  constructor(private authUserService: AuthUserService, private route: ActivatedRoute, private authShareService: AuthShareService) {
    if(this.route.url['value'][2].path === 'actuel') {
      this.actuel = 1;
    } else {
      this.actuel = 0;
    }
  }

  ngOnInit() {
    this.initContent();
    this.headerContent.push('Prénom');
    this.headerContent.push('Nom');
    this.headerContent.push('email');

    this.typeRecherche.push('Tous');
    this.typeRecherche.push('Prénom');
    this.typeRecherche.push('Nom');
    this.typeRecherche.push('Email');
  }

  initContent() {
    const that = this;
    if( this.recherche.length === 0 ){
      this.authUserService.getUsers(this.curentPage, this.actuel)
        .then((data) => {
          that.authUsers = data['hydra:member'] as Array<AuthUser>;
          that.getMaxPage(data['hydra:totalItems']);
        });
    } else if (this.recherche[0].length >= 3 ) {
      this.authUserService.getUsersRecherche(this.recherche, this.curentPage, this.actuel)
        .then((data) => {
          that.authUsers = data['hydra:member'] as Array<AuthUser>;
          that.getMaxPage(data['hydra:totalItems']);
        });
    }
  }
  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }
  changePage(page) {
    this.curentPage = page;
    this.initContent();
  }

  updateRecherche(recherche: Array<any>) {
    this.curentPage = 1;
    if (recherche[0] === ''){
      this.recherche = [];
    } else {
      this.recherche = recherche;
    }
    this.initContent();
  }
}
