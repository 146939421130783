import {Component, OnInit} from '@angular/core';
import {PressroomShareService} from '../../../../../../service/partage/PressroomShareService';


@Component({
  selector: 'app-pressroom-client-configuration-article',
  templateUrl: './pressroom-client-configuration-article.component.html',
  styleUrls: ['./pressroom-client-configuration-article.component.scss']
})
export class PressroomClientConfigurationArticleComponent implements OnInit {

  constructor(private pressroomShareService: PressroomShareService) { }

  ngOnInit() {
    this.pressroomShareService.loadArticle();
  }
  update(){
    this.pressroomShareService.articleService.loadArticlePressroom(this.pressroomShareService.pressroom.id)
      .then(() => {
        this.pressroomShareService.dialogueShareService.addDialogue(' Les articles ont été mis à jour')
        this.pressroomShareService.loadArticle();
      });
  }

}
