import { Component, OnInit } from '@angular/core';

import {MenuShareService} from '../../../../../service/partage/utilitaire/MenuShareService';
import {AuthShareService} from '../../../../../service/partage/AuthShareService';

@Component({
  selector: 'app-structure-menu-gauche-switch',
  templateUrl: './structure-menu-gauche-switch.component.html',
  styleUrls: ['./structure-menu-gauche-switch.component.scss']
})
export class StructureMenuGaucheSwitchComponent implements OnInit {

  constructor(public menuShareService: MenuShareService, public authShareService: AuthShareService) { }

  ngOnInit() {
  }

}
