import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ModalCalendrierShareService} from '../../../service/partage/ModalCalendrierShareService';
import {EvenementService} from '../../../service/interne/calendrier/EvenementService';
import {EvenementSalleService} from '../../../service/interne/calendrier/EvenementSalleService';

@Component({
  selector: 'app-modal-calendrier',
  templateUrl: './modal-calendrier.component.html',
  styleUrls: ['./modal-calendrier.component.scss'],
  providers: [EvenementService, EvenementSalleService]
})
export class ModalCalendrierComponent implements OnInit {

  @Output() refreshCalendar = new EventEmitter<any>();

  constructor(public modalCalendrierShareService: ModalCalendrierShareService, public evenementService: EvenementService) {
  }

  partieOuvert = 'information';

  refreshCalendarAction() {
    this.refreshCalendar.emit();
  }

  deleteEvent(){
    const idEvent = this.modalCalendrierShareService.event.meta.id;
    this.evenementService.deleteEvent(idEvent).then((retour) =>{
      this.refreshCalendarAction();
    });
    this.modalCalendrierShareService.open = false;
  }
  ngOnInit() {
  }


}
