import { Component, OnInit } from '@angular/core';
import {DialogueShareService} from '../../../service/partage/utilitaire/DialogueShareService';
import {EmailRetourService} from '../../../service/interne/utilitaire/EmailRetourService';
import {AuthShareService} from '../../../service/partage/AuthShareService';

@Component({
  selector: 'app-report-mail-retour',
  templateUrl: './report-mail-retour.component.html',
  styleUrls: ['./report-mail-retour.component.scss'],
  providers: [EmailRetourService]
})
export class ReportMailRetourComponent implements OnInit {

  email: String = '';
  raisons: Array<any> = [];
  raisonChoix: String = 'mail inexistant';
  date: Date = new Date();
  startMoment: Date = new Date();
  emailDejaVerifier = 0;

  constructor(private dialogueShareService: DialogueShareService, private emailRetourService: EmailRetourService, private authShareService: AuthShareService) { }

  ngOnInit() {
    this.raisons.push('mail inexistant');
    this.raisons.push('email bloquer');
    this.raisons.push('absence du journaliste');
    this.raisons.push('Hop count exceeded');
    this.raisons.push('autre');
  }

  sendMailErreur() {
    if (this.email.length < 5) {
      this.dialogueShareService.addDialogue('l\'email semble non valide');
    } else {
      this.emailRetourService.addEmailRetour(this.email, this.raisonChoix, this.authShareService.authUser.id, this.date).then((retour) => {
        this.dialogueShareService.addDialogue('email ajouté');
        this.email = '';
        this.raisonChoix = 'mail inexistant';
        this.date = new Date();
      });
    }
  }
  raisonChoixAbsent(){
    return this.raisonChoix == 'absence du journaliste';
  }

  myFilter = (d: Date): boolean => {
    if ( this.startMoment.getFullYear() === d.getFullYear()) {
      if ( this.startMoment.getMonth() === d.getMonth() ) {
        if ( this.startMoment.getDate() === d.getDate() ) {
          return true;
        }
      }
    }
    return (d >= this.startMoment);
  }

  verifEmailExistant() {
    const that = this;
    this.emailRetourService.verifEmailRetour(this.email).then((retour) => {
      if (retour["hydra:totalItems"] > 0) {
        that.emailRetourService.addEmailDejaExistant(that.authShareService.authUser.id).then(() => {
        });
        this.emailDejaVerifier = 1;
      } else {
        this.emailDejaVerifier = 0;
      }
    });
  }
}
