import { Component, OnInit } from '@angular/core';
import {Actualite} from '../../../../model/actualite/Actualite';
import {ActualiteService} from '../../../../service/interne/actualite/ActualiteService';

@Component({
  selector: 'app-escal-actualite-list',
  templateUrl: './escal-actualite-list.component.html',
  styleUrls: ['./escal-actualite-list.component.scss'],
  providers: [ ActualiteService ]
})
export class EscalActualiteListComponent implements OnInit {

  typeRecherche: Array<any> = [];
  headerContent: Array<any> = [];
  recherche: Array<any> = [];
  actualites: Array<Actualite>;
  curentPage = 1;
  maxPage: number;
  total: number;
  constructor(private actualiteService: ActualiteService) { }

  ngOnInit() {
    this.initContent();
    this.headerContent.push('isActualite');

    this.typeRecherche.push('Tous');
    this.typeRecherche.push('Nom');
    this.typeRecherche.push('Client');
    this.typeRecherche.push('Pitch');
    this.typeRecherche.push('Thème descriptif');
    this.typeRecherche.push('Perspective');
    this.typeRecherche.push('Innovation');
    this.typeRecherche.push('Chiffres clés');
  }

  initContent() {
    this.total = 0;
    const that = this;
    // affichage par defaut
    if ( this.recherche.length === 0) {
      this.actualiteService.getActualitesParDefaut(this.curentPage)
        .then((data) => {
          that.actualites = data['hydra:member'] as Array<Actualite>;
          that.total = data['hydra:totalItems'];
          that.getMaxPage(data['hydra:totalItems']);
        });
    } else if (this.recherche[0].length >= 3 ) {
      // si contenu recherche
      this.actualiteService.getActualitesRecherche(this.recherche, this.curentPage)
        .then((data) => {
          that.actualites = data['hydra:member'] as Array<Actualite>;
          that.total = data['hydra:totalItems'];
          that.getMaxPage(data['hydra:totalItems']);
        });
    }
  }

  updateRecherche(recherche: Array<any>) {
    this.curentPage = 1;
    if (recherche[0] === '') {
      this.recherche = [];
    } else {
      this.recherche = recherche;
    }
    this.initContent();
  }

  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }

  changePage(page) {
    this.curentPage = page;
    this.initContent();
  }

  majTotal(value) {
    this.total = value;
  }
}
