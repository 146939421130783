import { Component, OnInit } from '@angular/core';
import {DialogueShareService} from '../../../service/partage/utilitaire/DialogueShareService';
import {DemandeCongeService} from '../../../service/interne/utilitaire/DemandeCongeService';
import {AuthShareService} from '../../../service/partage/AuthShareService';
import {Router} from '@angular/router';
import {UserService} from '../../../service/interne/user/UserService';
import {User} from '../../../model/user/User';

@Component({
  selector: 'app-demande-conge',
  templateUrl: './demande-conge.component.html',
  styleUrls: ['./demande-conge.component.scss'],
  providers: [ DemandeCongeService, UserService ]

})
export class DemandeCongeComponent implements OnInit {

  startMoment: Date = new Date();

  dateDebut: Date = new Date();

  dateFin: Date;

  payer = '0';

  sansSolde = '0';

  rattrapage = '0';

  cbApresMidiBegin;

  cbMatinBegin;

  cbApresMidiFin;

  motif;

  autre = '0';

  messageDemandeEnCours = false;

  selectedEmailSend = 'louis@escalconsulting.com';

  users: Array<User>;

  filterDebut = (d: Date): boolean => {

    const d1 = new Date();
    if (d.getFullYear() > d1.getFullYear()) {
      return true;
    } else if (d.getFullYear() === d1.getFullYear()) {
      if (d.getMonth() > d1.getMonth()) {
        return true;
      } else if (d.getMonth() === d1.getMonth()){
        if (d.getDate() >= d1.getDate()) {
          return true;
        }
      }
    }
    return false;
  }

  filterFin = (d: Date): boolean => {
    if (d.getFullYear() > this.dateDebut.getFullYear()) {
      return true;
    } else if (d.getFullYear() === this.dateDebut.getFullYear()) {
      if (d.getMonth() > this.dateDebut.getMonth()) {
        return true;
      } else if (d.getMonth() === this.dateDebut.getMonth()){
        if (d.getDate() > this.dateDebut.getDate()) {
          return true;
        }
      }
    }
  }

  constructor(private dialogueShareService: DialogueShareService, private demandeCongeService: DemandeCongeService,
              private authShareService: AuthShareService, private router: Router, private userService: UserService) { }

  ngOnInit() {
    this.cbMatinBegin = true;
    this.cbApresMidiFin = true;
    this.cbApresMidiBegin = true;
    this.userService.GetUsersRespConge()
      .then((users) => {
        this.users = users['hydra:member'] as Array<User>;
        console.log(this.users);
      });
  }
  EnvoyerDemande(){
    const that = this;
    if (!isNaN(parseInt(this.payer, 10)) && !isNaN(parseInt(this.sansSolde, 10))
      && !isNaN(parseInt(this.rattrapage, 10))) {
      if ((Number(this.payer) + Number(this.sansSolde) + Number(this.rattrapage) + Number(this.autre)) > 0) {
        this.demandeCongeService.addDemande(this.authShareService.authUser.id, this.dateDebut, this.dateFin, this.payer,
          this.sansSolde, this.rattrapage, this.cbMatinBegin, this.cbApresMidiBegin, this.cbApresMidiFin,
          this.motif, this.autre, this.selectedEmailSend)
          .then((retour) => {
            this.messageDemandeEnCours = true;
            setTimeout( () => {
              that.router.navigate(['/']);
            }, 5000);

          });
      } else {
        this.dialogueShareService.addDialogue('le total de jour doit être supérieur a 0');
      }
    } else {
      this.dialogueShareService.addDialogue('le nombre de jour payé et sans solde doit être un nombre');
    }
  }
}
