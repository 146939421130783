import { Component, OnInit } from '@angular/core';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {SuivitIntranetService} from '../../../../service/interne/suivit/SuivitIntranetService';
import {AuthUserService} from '../../../../service/interne/user/AuthUserService';
import {MaillingService} from '../../../../service/interne/mailling/maillingService';
import {NatureActionSuiviService} from '../../../../service/interne/SuiviAction/natureActionSuiviService';
import {TypeDeRelationService} from '../../../../service/interne/SuiviAction/typeDeRelationService';
import {SuiviAction} from '../../../../model/suivitAction/SuiviAction';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthShareService} from '../../../../service/partage/AuthShareService';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {TypeDeRelation} from '../../../../model/suivitAction/TypeDeRelation';
import {AssoPersonneMediaOrganisation} from '../../../../model/Core/AssoPersonneMediaOrganisation';
import {SuiviActionShareService} from '../../../../service/partage/SuiviActionShareService';
import {TypeRelationSuivi} from '../../../../model/suivi/TypeRelationSuivi';
import {NatureTypeRelationSuivi} from '../../../../model/suivi/NatureTypeRelationSuivi';
import {NatureSuivi} from '../../../../model/suivi/NatureSuivi';

@Component({
  selector: 'app-escal-suivi-gestion',
  templateUrl: './escal-suivi-gestion.component.html',
  styleUrls: ['./escal-suivi-gestion.component.scss'],
  providers: [ ClientService, SuivitIntranetService, AuthUserService, MaillingService, NatureActionSuiviService, TypeDeRelationService ]
})
export class EscalSuiviGestionComponent implements OnInit {

  id: number;
  private sub: any;
  typeRelations: Array<TypeRelationSuivi>;
  natureTypeRelations: Array<NatureTypeRelationSuivi>;
  naturesSelect: Array<NatureSuivi>;
  structureChoisi: string;
  contactIDSelect: number;
  // structures: Array<string> = ['personne', 'media', 'organisation'];

  constructor(private router: Router, private route: ActivatedRoute, private clientService: ClientService,
              private suivitIntranetService: SuivitIntranetService, private authUserService: AuthUserService,
              private authShareService: AuthShareService, private maillingService: MaillingService,
              private dialogueShareService: DialogueShareService, public formatIntranetShareService: FormatIntranetShareService,
              private natureActionSuiviService: NatureActionSuiviService, private typeDeRelationService: TypeDeRelationService,
              public  suiviActionShareService: SuiviActionShareService) {
  }

  ngOnInit() {
      const that = this;
      this.sub = this.route.params.subscribe(params => {
        this.contactIDSelect = 0;
        that.id = 0;
        this.suiviActionShareService.initNouveauSuivi(that.id);
      });
    /*
    this.typeDeRelationService.getTypeRelation().then((types) => {
      that.typeRelations = types['hydra:member'] as Array<TypeRelationSuivi>;
      that.typeRelations.forEach((typeDeRelation) => {
        // relation presse par defaut
        if (typeDeRelation.id === 1) {
          that.suiviActionShareService.suivi.typeRelation = typeDeRelation;
          this.natureActionSuiviService.getNaturesTypeRelation().then((naturesType) => {
            that.natureTypeRelations = naturesType['hydra:member'] as Array<NatureTypeRelationSuivi>;
            that.natureTypeRelations.forEach((natureType) => {
              if (natureType.typeRelation.id === typeDeRelation.id) {
                // recuperer l'objet nature
                this.natureActionSuiviService.getNature(natureType.nature.id).then((nature) => {
                  // ajouter l'objet à la liste des natures correspondant au type de relation
                  that.naturesSelect.push(nature);
                  });
                }
              });
            });
          }
        });
        });*/
          /*typeDeRelation.naturesTypeRelation.forEach((natureTypeRelation) => {
            if (natureTypeRelation.choixParDefaut) {
              that.suiviActionShareService.suivi.suiviNatures = natureTypeRelation.natureActionSuivi;
              natureTypeRelation.natureActionSuivi.natureActionSuiviVecteur.forEach((natureActionSuiviVecteur) => {
                if (natureActionSuiviVecteur.principal) {
                  that.suiviActionShareService.suivi.vecteur = natureActionSuiviVecteur.vecteur;
                }
              });
            }
          });
        }
      });
    });*/
    // Suivi coché par défaut
    this.structureChoisi = 'personne';
  }
  test(event) {
    this.dialogueShareService.addDialogue(event);
  }
}
