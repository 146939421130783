import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MaillingService} from '../../../../service/interne/mailling/maillingService';
import {Mailling} from '../../../../model/mailling/Mailling';
import {SuivitOuverture} from '../../../../model/mailling/SuivitOuverture';
import {SuivitMaillingService} from '../../../../service/interne/mailling/suivitMaillingService';
import {EmailRetour} from '../../../../model/mailling/EmailRetour';

@Component({
  selector: 'app-escal-mailling-suivit',
  templateUrl: './escal-mailling-suivit.component.html',
  styleUrls: ['./escal-mailling-suivit.component.scss'],
  providers: [ MaillingService, SuivitMaillingService ]
})
export class EscalMaillingSuivitComponent implements OnInit {

  private sub: any;
  private idOuvert = 1;
  mailling: Mailling;
  activeSlide:string = 'suivit';
  private id: string;
  erreursMailling: Array<any>;
  TousCheck: boolean = true;
  VueCheck: boolean = false;
  OuvertCheck: boolean = false;
  partiescheck: Array<any> = [];
  rechercheJournaliste: string = '';
  rechercheErreur: string = '';
  public lineChartOptions:any = {
    legend: {
      display: true,
      labels: {
        fontColor: '#33363b',
        boxWidth: 100,
        fontSize: 20,
      }},
    tooltips: {
      enabled	: true,
      xPadding: 40,
      yxPadding: 40,
      bodyFontSize: 20,
    },
    responsive: true
  };
  constructor(private route: ActivatedRoute, private maillingService: MaillingService,
              private suivitMaillingService:SuivitMaillingService) {
  }

  public doughnutChartLabels: string[] = ['ouvert', 'non ouvert'];
  public doughnutChartData: number[] = [0, 0];
  public doughnutChartType: string = 'doughnut';
  public doughnutChartLabels2: string[] = ['envoyés', 'non envoyés'];
  public doughnutChartData2: number[] = [0, 0];
  public doughnutChartType2: string = 'doughnut';



  ngOnInit() {
    const that = this;
    this.sub = this.route.params.subscribe(params => {
      that.id = params['id'];
      that.maillingService.getMaillingItem(that.id)
        .then((retour) => {
          that.mailling = retour;
          that.mailling.partiesCp.forEach((partieCp => {
            that.partiescheck[partieCp.id] = false;
          }));
          that.partiescheck[1] = false;
          that.suivitMaillingService.getSuvitMailling(that.mailling.id)
            .then((retour1) => {
              that.mailling.suivitOuvertures = retour1['hydra:member']  as Array<SuivitOuverture>;
              that.doughnutChartData = [ that.mailling.suivitOuvertures.length ,
                that.mailling.nbMailEnvoye - that.mailling.suivitOuvertures.length];
            });
        });
      that.maillingService.getMaillingEnvoieErreur(that.id)
        .then( (retour) => {
          that.erreursMailling = retour as Array<EmailRetour>;
          that.doughnutChartData2 = [ that.mailling.nbMailEnvoye - that.erreursMailling.length,
            that.erreursMailling.length];
        });
    });
  }
  tabActive(tab) {
    if ( tab === this.activeSlide) {
      return 'tabActive';
    }
    return;
  }
  updateCheck(type) {
    const that = this;
    if (type === 'tous') {
      this.VueCheck = false;
      this.OuvertCheck = false;
      this.mailling.partiesCp.forEach((partieCp) => {
        that.partiescheck[partieCp.id] = false;
      });
      that.partiescheck[1] = false;
    }
    if (type === 'vue') {
      this.TousCheck = false;
      this.OuvertCheck = false;
      this.mailling.partiesCp.forEach((partieCp) => {
        that.partiescheck[partieCp.id] = false;
      });
      that.partiescheck[1] = false;
    }
    if (type === 'ouvert') {
      this.TousCheck = false;
      this.VueCheck = false;
      this.mailling.partiesCp.forEach((partieCp) => {
        that.partiescheck[partieCp.id] = true;
      });
      that.partiescheck[1] = true;
    }
    this.updateGraph();
  }
  updateGraph() {
    const that = this;
    let nbSuivitAffiche = 0;
    this.mailling.suivitOuvertures.forEach((suivitOuverture) => {
      if (that.suivitPresent(suivitOuverture) === true) {
        nbSuivitAffiche = nbSuivitAffiche + 1;
      }
    });
    that.doughnutChartData = [ nbSuivitAffiche ,
      that.mailling.nbMailEnvoye - nbSuivitAffiche ];
    console.log(that.doughnutChartData);
  }

  suivitPresent(suivitOuverture: SuivitOuverture) {
    if (suivitOuverture.nomContact.toUpperCase().indexOf(this.rechercheJournaliste.toUpperCase()) !== -1) {
      if (this.TousCheck !== true) {
        if (this.VueCheck !== true) {
          if ( suivitOuverture.parties.length > 0) {
            if (this.nbOpen(suivitOuverture) > 0) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
        return true;
      }
      return true;
    } else {
      return false;
    }
  }
  erreurPresent(erreurMailling: EmailRetour) {
    if (erreurMailling.nomContact.toUpperCase().indexOf(this.rechercheErreur.toUpperCase()) !== -1) {
      return true;
    } else {
      return false;
    }
  }
  nbOpen(suivitOuverture: SuivitOuverture) {
    const that = this;
    let nbopen = 0;
    suivitOuverture.parties.forEach((partie) => {
      if (that.partiescheck[partie.partie.id] === true) {
        nbopen = nbopen + 1;
      }
    });
    return nbopen;
  }
  nbOpenTot(suivitOuverture: SuivitOuverture) {
    let nbopen = 0;
    suivitOuverture.parties.forEach(() => {
      nbopen = nbopen + 1;
    });
    return nbopen;
  }

  nbOpenType(suivitOuverture: SuivitOuverture) {
    if (this.TousCheck === true) {
      return suivitOuverture.nbOpen;
    }
    if (this.VueCheck === true) {
      return (suivitOuverture.nbOpen - this.nbOpenTot(suivitOuverture));
    }
    return this.nbOpen(suivitOuverture);
  }

}
