import { Component, OnInit } from '@angular/core';
import {FormeJuridique} from '../../../../model/organisation/FormeJuridique';
import {FormeJuridiqueService} from '../../../../service/interne/organisation/FormeJuridiqueService';
import {Genre} from '../../../../model/contact/Genre';
import {GenreService} from '../../../../service/interne/genre/GenreService';
import {Pays} from '../../../../model/information/Pays';
import {PaysService} from '../../../../service/interne/contact/PaysService';
import {CodeApe} from '../../../../model/organisation/CodeApe';
import {CodeApeService} from '../../../../service/interne/organisation/CodeApeService';
import {OrganisationService} from '../../../../service/interne/organisation/OrganisationService';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {Organisation} from '../../../../model/organisation/Organisation';
import {AuthShareService} from '../../../../service/partage/AuthShareService';
import {Suivi} from '../../../../model/suivi/Suivi';
import {CoordonneesService} from '../../../../service/interne/coordonnees/CoordonneesService';
import {Coordonnees} from '../../../../model/information/Coordonnees';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {Router} from '@angular/router';

@Component({
  selector: 'app-escal-organisation-add',
  templateUrl: './escal-organisation-add.component.html',
  styleUrls: ['./escal-organisation-add.component.scss'],
  providers: [FormeJuridiqueService, GenreService, PaysService, CodeApeService, OrganisationService, CoordonneesService]
})
export class EscalOrganisationAddComponent implements OnInit {

  societe: Organisation;
  coordonnee: Coordonnees;
  structureChoisi: string;
  champRechercheDirigeant: string;
  champRechercheSoc: string;
  champRechercheCode: string;
  champRechercheCateg: string;
  rechercheEncours = false;
  rechercheEncoursDirigeant = false;
  rechercheEncoursCateg = false;
  affiche: boolean;
  afficheDirigeant: boolean;
  afficheCode: boolean;
  afficheCateg: boolean;
  dirigeantId: number;
  societeId: number;
  codeId: number;
  categId: number;
  formeId: number;
  genreId: number;
  paysId: number;
  dirigeantsRecherche: Array<any> = [];
  societesRecherche: Array<any> = [];
  categoriesRecherche: Array<any> = [];
  formes: Array<FormeJuridique>;
  genres: Array<Genre>;
  pays: Array<Pays>;
  codes: Array<CodeApe>;
  ouvert = 0;
  doublonsRecherche: Array<any> = [];
  constructor(private formeJuridiqueService: FormeJuridiqueService, private genreService: GenreService,
              private paysService: PaysService, private codeApeService: CodeApeService,
              private organisationService: OrganisationService, public formatIntranetShareService: FormatIntranetShareService,
              private authShareService: AuthShareService, private coordonneesService: CoordonneesService,
              private dialogueShareService: DialogueShareService, private router: Router) { }

  ngOnInit() {
    this.societe = new Organisation(0, 0, '', null, null, '', '', '' , '', '',
      this.authShareService.authUser, new Date(), null, null, null, null, null, null, null, [], [], null, []);
    this.coordonnee = new Coordonnees(0, '', '', '', '', '', '', '', '', null,
      '', '', this.authShareService.authUser, new Date(), null, 0, null, null, null);
    this.structureChoisi = 'cliente';
    this.champRechercheDirigeant = '';
    this.champRechercheSoc = '';
    this.champRechercheCode = '';
    this.champRechercheCateg = '';
    this.affiche = false;
    this.afficheDirigeant = false;
    this.afficheCode = false;
    this.afficheCateg = false;
    this.dirigeantId = 0;
    this.societeId = 0;
    this.codeId = 0;
    this.categId = 0;
    this.formeId = 0;
    this.genreId = 0;
    this.paysId = 0;
    this.formeJuridiqueService.getFormesJuridiques().then(formesJ => {
      this.formes = formesJ['hydra:member'] as Array<FormeJuridique>;
    });
    this.genreService.getGenres().then(genres => {
      this.genres = genres['hydra:member'] as Array<Genre>;
    });
    this.paysService.getAllPays().then(pays => {
      this.pays = pays['hydra:member'] as Array<Pays>;
    });
  }

  rechercheCode() {
    this.codeApeService.getCodesApeRecherche(this.champRechercheCode).then(codes => {
      this.codes = codes['hydra:member'] as Array<CodeApe>;
      this.afficheCode = true;
    });
  }

  rechercheDirigeant() {
    this.afficheDirigeant = true;
    if (this.champRechercheDirigeant.length > 2 && !this.rechercheEncoursDirigeant) {
      this.rechercheEncoursDirigeant = true;
      this.champRechercheDirigeant = this.champRechercheDirigeant.replace(' ', '.');
      this.organisationService.getDirigeantRecherche(this.champRechercheDirigeant).then((retour) => {
        this.dirigeantsRecherche = retour;
        this.dirigeantsRecherche.forEach(dirigeant => {
          if (dirigeant.nomPrenom != '' && dirigeant.nomPrenom != null) {
            dirigeant.nomPrenom = dirigeant.nomPrenom.replace('&amp;', '&');
            dirigeant.nomPrenom = dirigeant.nomPrenom.replace('&#039;', '’');
            dirigeant.nomPrenom = dirigeant.nomPrenom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheDirigeant = this.champRechercheDirigeant.replace('.', ' ');
      });
      this.rechercheEncoursDirigeant = false;
    }
  }

  rechercheSociete() {
    this.affiche = true;
    if (this.champRechercheSoc.length > 2 && !this.rechercheEncours) {
      this.rechercheEncours = true;
      this.champRechercheSoc = this.champRechercheSoc.replace(' ', '.');
      this.organisationService.getSocieteRecherche(this.champRechercheSoc).then((retour) => {
        this.societesRecherche = retour;
        this.societesRecherche.forEach(societe => {
          if (societe.nom != '' && societe.nom != null) {
            societe.nom = societe.nom.replace('&amp;', '&');
            societe.nom = societe.nom.replace('&#039;', '’');
            societe.nom = societe.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheSoc = this.champRechercheSoc.replace('.', ' ');
      });
      this.rechercheEncours = false;
    }
  }

  rechercheCategories() {
    this.afficheCateg = true;
    if (this.champRechercheCateg.length > 2 && !this.rechercheEncoursCateg) {
      this.rechercheEncoursCateg = true;
      this.champRechercheCateg = this.champRechercheCateg.replace(' ', '.');
      this.organisationService.getCategoriesRecherche(this.champRechercheCateg).then((retour) => {
        this.categoriesRecherche = retour;
        this.categoriesRecherche.forEach(categorie => {
          if (categorie.nom != '' && categorie.nom != null) {
            categorie.nom = categorie.nom.replace('&amp;', '&');
            categorie.nom = categorie.nom.replace('&#039;', '’');
            categorie.nom = categorie.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheCateg = this.champRechercheCateg.replace('.', ' ');
      });
      this.rechercheEncoursCateg = false;
    }
  }

  enregistrerSociete() {
    // informations obligatoires : Nom et Genre de la société
    if (this.societe.nom != '') {
      if (this.genreId != 0) {
        this.organisationService.addOrganisation(this.societe, this.formeId, this.codeId, this.genreId).then(org => {
          const organisation = org as Organisation;
          // si société parente renseignée
          if (this.societeId != 0) {
            // on importe la societe à laquelle la nouvelle organisation appartient
            this.organisationService.importerOrganisation(this.societeId).then(() => {
              // on récupère la société importée
              this.organisationService.getDoublonEscalnetSiExiste(this.societeId).then(societe => {
                const socId = societe['hydra:member'][0].id;
                // on l'assigne à la nouvelle organisation
                this.organisationService.addSocieteParent(socId, organisation.id).then(() => {
                });
              });
            });
          }
          // si dirigeant renseigné
          if (this.dirigeantId != 0) {
            // on importe le dirigeant
            this.organisationService.importerDirigeant(this.dirigeantId).then(() => {
              // on récupère le dirigeant renseigné
              this.organisationService.getDirigeantByAncienId(this.dirigeantId).then((dirigeant) => {
                const dirId = dirigeant['hydra:member'][0].id;
                // on l'assigne à la nouvelle organisation
                this.organisationService.addDirigeant(dirId, organisation.id).then(() => {
                });
              });
            });
          }
          // si catégorie générale renseignée
          if (this.categId != 0) {
            // on importe la catégorie
            this.organisationService.importerCategorie(this.categId).then(() => {
              // on récupère la catégorie renseignée
              this.organisationService.getCategorieByAncienId(this.categId).then((categorie) => {
                const catId = categorie['hydra:member'][0].id;
                // on l'assigne à la nouvelle organisation
                this.organisationService.addCategorie(catId, organisation.id).then(() => {
                });
              });
            });
          }
          // si au moins un champs de coordonnees renseigné
          if (this.coordonnee.premierTel != '' || this.coordonnee.deuxiemeTel != '' || this.coordonnee.email != '' || this.coordonnee.rue != '' || this.coordonnee.complements != '' || this.coordonnee.boitePostale != '' || this.coordonnee.codePostal != '' || this.coordonnee.ville != '' || this.paysId != 0 || this.coordonnee.premierURL != '' || this.coordonnee.deuxiemeURL != '') {
            this.coordonneesService.addCoordonneeOrganisation(this.coordonnee, organisation.id, this.paysId).then(coord => {
            });
          }
          // enregistrer societe dans la base d'intranet
          this.organisationService.importerOrganisationIntranet(organisation.id, 0).then((ancienIdSoc) => {
            // insérer l'id d'intranet dans ancienId d'escalnet
            this.organisationService.addAncienIdOrganisation(Number(ancienIdSoc), organisation.id).then((retour) => {
              // rappeler la fonction, cette fois pour s'assurer que les promises soient terminés
              this.organisationService.importerOrganisationIntranet(organisation.id, Number(ancienIdSoc)).then((fin) => {
                this.router.navigate(['/escal/societes']);
                this.dialogueShareService.addDialogue('La société a bien été créée');
              });
            });
          });
        });
      } else {
        this.dialogueShareService.addDialogue('Le genre est obligatoire');
      }
    } else {
      this.dialogueShareService.addDialogue('Le nom est obligatoire');
    }
  }

  verifierSocieteDoublon() {
    if (this.societe.nom != '') {
      if (this.genreId != 0) {
        // gérer cas caractère spéciaux envoie api : espace et slash
        let nomSoc = this.societe.nom;
        nomSoc = nomSoc.replace(' ', '~~');
        nomSoc = nomSoc.replace('/', '@@@');
        // chercher potentiels doublons dans la base intranet
        this.organisationService.getDoublonsSuggestion(nomSoc).then((retour) => {
          this.doublonsRecherche = retour;
          // si aucun doublon trouvé
          if (this.doublonsRecherche.length === 0) {
            this.enregistrerSociete();
          } else {
            // s'il y a au moins un doublon
            this.structureChoisi = 'doublon';
            this.doublonsRecherche.forEach(doublon => {
              // vérifier si doublon déjà dans la base escalnet ou non
              this.organisationService.getDoublonEscalnetSiExiste(doublon.id).then((doublonEscalnet) => {
                if (doublonEscalnet['hydra:member'].length >= 1) {
                  doublon.escalnet = 'existe';
                }
              });
              if (doublon.nom != '' && doublon.nom != null) {
                // remplacer caractères reçus de l'api
                doublon.nom = doublon.nom.replace('&amp;', '&');
                doublon.nom = doublon.nom.replace('&#039;', '’');
                doublon.nom = doublon.nom.replace('L&#039;', 'L’');
              }
            });
          }
        });
      } else {
        this.dialogueShareService.addDialogue('Le genre est obligatoire');
      }
    } else {
      this.dialogueShareService.addDialogue('Le nom est obligatoire');
    }
  }

  importerSociete(socId) {
    // importer la societe
    this.organisationService.importerOrganisation(socId).then(() => {
      // redirection
      this.verifierSocieteDoublon();
      this.dialogueShareService.addDialogue('La société a bien été importée');
    });
  }

  majSociete(socId) {
    // mettre à jour la societe
    // on récupère les informations du doublon intranet, les informations sont peut-etre + à jour que celui deja dans la base escalnet
    this.organisationService.getAllInformationsOrganisation(socId).then((societeDoublon) => {
      const oldSociete = societeDoublon;
      // on récupère la societe escalnet
      this.organisationService.getDoublonEscalnetSiExiste(socId).then((societe) => {
        const idSocMAJ = societe['hydra:member'][0].id;
        let idCoordSocMaj = 0;
        if (societe['hydra:member'][0].coordonnees != null) {
          idCoordSocMaj = societe['hydra:member'][0].coordonnees.id;
        }
        // on compare les informations avec celles de l'existant,
        // on met à jour les informations si champ rempli, si champs vide on garde les anciennes
        if (this.societe.capital == null || this.societe.capital === '' || this.societe.capital === '0') {
          this.societe.capital = oldSociete.capital_soc ;
        }
        if (this.societe.commentaires == null || this.societe.commentaires === '') {
          this.societe.commentaires = oldSociete.commentaire_soc ;
        }
        if (this.societe.effectif == null || this.societe.effectif === '') {
          this.societe.effectif = oldSociete.effectif_soc ;
        }
        if (this.societe.implantation == null || this.societe.implantation === '') {
          this.societe.implantation = oldSociete.implantation_soc ;
        }
        if (this.societe.siren == null || this.societe.siren === '') {
          this.societe.siren = oldSociete.siren_soc ;
        }
        if (this.formeId == null || this.formeId === 0) {
          this.formeId = oldSociete.ID_formjuri_soc ;
        }
        if (this.dirigeantId == null || this.dirigeantId === 0) {
          this.dirigeantId = oldSociete.dirigeant_soc ;
        }
        if (this.societeId == null || this.societeId === 0) {
          // champs : appartenir à
          this.societeId = oldSociete.ID_parent ;
        }
        if (this.categId == null || this.categId === 0) {
          this.categId = oldSociete.ID_categoriegen_soc ;
        }
        if (this.coordonnee.premierTel == null || this.coordonnee.premierTel === '') {
          this.coordonnee.premierTel = oldSociete.tel ;
        }
        if (this.coordonnee.deuxiemeTel == null || this.coordonnee.deuxiemeTel === '') {
          this.coordonnee.deuxiemeTel = oldSociete.tel_gsm ;
        }
        if (this.coordonnee.email == null || this.coordonnee.email === '') {
          this.coordonnee.email = oldSociete.email ;
        }
        if (this.coordonnee.rue == null || this.coordonnee.rue === '') {
          this.coordonnee.rue = oldSociete.adresse1 ;
        }
        if (this.coordonnee.complements == null || this.coordonnee.complements === '') {
          this.coordonnee.complements = oldSociete.adresse2 ;
        }
        if (this.coordonnee.premierURL == null || this.coordonnee.premierURL === '') {
          this.coordonnee.premierURL = oldSociete.web1 ;
        }
        if (this.coordonnee.deuxiemeURL == null || this.coordonnee.deuxiemeURL === '') {
          this.coordonnee.deuxiemeURL = oldSociete.web2 ;
        }
        if (this.coordonnee.boitePostale == null || this.coordonnee.boitePostale === '') {
          this.coordonnee.boitePostale = oldSociete.bpostal ;
        }
        if (this.coordonnee.codePostal == null || this.coordonnee.codePostal === '') {
          this.coordonnee.codePostal = oldSociete.cpostal ;
        }
        if (this.coordonnee.ville == null || this.coordonnee.ville === '') {
          this.coordonnee.ville = oldSociete.ville ;
        }
        if (this.paysId == null || this.paysId === 0) {
          this.paysId = oldSociete.ID_pays ;
        }
        if (this.codeId == null || this.codeId === 0) {
          // on récupère le code ape dont l'ancien id correspond à celui d'intranet
          this.codeApeService.getCodeApeByAncienId(oldSociete.ape_soc).then((ape) => {
            this.codeId = ape['hydra:member'][0].id;
            // modifier le code ape à part à cause du promise
            this.organisationService.modifierCodeApeOrganisation(idSocMAJ, this.codeId).then(retour => {
            });
          });
        } else {
          // si on garde le nouveau code ape
          this.organisationService.modifierCodeApeOrganisation(idSocMAJ, this.codeId).then(retour => {
          });
        }
        // on conserve la date de creation d'intranet
        this.societe.dateCreation = oldSociete.datecreation_soc;
        // modifier les informations de la société mise à jour
        this.organisationService.modifierOrganisation(idSocMAJ, this.societe, this.formeId, this.genreId).then(org => {
          const organisation = org as Organisation;
          // si société parente renseignée
          if (this.societeId != 0) {
            // on importe la societe à laquelle la nouvelle organisation appartient
            this.organisationService.importerOrganisation(this.societeId).then(() => {
              // on récupère la société importée
              this.organisationService.getDoublonEscalnetSiExiste(this.societeId).then(societeParent => {
                const socParentId = societeParent['hydra:member'][0].id;
                // on l'assigne à la nouvelle organisation
                this.organisationService.addSocieteParent(socParentId, organisation.id).then(() => {
                });
              });
            });
          }
          // si dirigeant renseigné
          if (this.dirigeantId != 0) {
            // on importe le dirigeant
            this.organisationService.importerDirigeant(this.dirigeantId).then(() => {
              // on récupère le dirigeant renseigné
              this.organisationService.getDirigeantByAncienId(this.dirigeantId).then((dirigeantSoc) => {
                const dirSocId = dirigeantSoc['hydra:member'][0].id;
                // on l'assigne à la nouvelle organisation
                this.organisationService.addDirigeant(dirSocId, organisation.id).then(() => {
                });
              });
            });
          }
          // si catégorie générale renseignée
          if (this.categId != 0) {
            // on importe la catégorie
            this.organisationService.importerCategorie(this.categId).then(() => {
              // on récupère la catégorie renseignée
              this.organisationService.getCategorieByAncienId(this.categId).then((categorieSoc) => {
                const catSocId = categorieSoc['hydra:member'][0].id;
                // on l'assigne à la nouvelle organisation
                this.organisationService.addCategorie(catSocId, organisation.id).then(() => {
                });
              });
            });
          }
          // modifier les coordonnees de la société mise à jour si existantes
          if (idCoordSocMaj != 0) {
            this.coordonneesService.modifierCoordonnees(idCoordSocMaj, this.coordonnee, idSocMAJ, this.paysId).then((coord) => {
              // redirection fiche soc
              //
              this.dialogueShareService.addDialogue('La société a bien été mise à jour');
            });
          } else {
            // s'il n'existe pas encore de coordonnées et qu'au moins un champs de coordonnée est saisi
            if (this.coordonnee.premierTel != '' || this.coordonnee.deuxiemeTel != '' || this.coordonnee.email != '' || this.coordonnee.rue != '' || this.coordonnee.complements != '' || this.coordonnee.boitePostale !== '' || this.coordonnee.codePostal != '' || this.coordonnee.ville != '' || this.paysId != 0 || this.coordonnee.premierURL != '' || this.coordonnee.deuxiemeURL != '') {
              this.coordonneesService.addCoordonneeOrganisation(this.coordonnee, idSocMAJ, this.paysId).then(coord => {
                // redirection
                //
                this.dialogueShareService.addDialogue('La société a bien été mise à jour');
              });
            }
          }
          // mettre à jour societe dans la base d'intranet
          this.organisationService.importerOrganisationIntranet(organisation.id, oldSociete.id).then((retour) => {
          });
        });
      });
    });
  }

  importerMajSociete(socId) {
    // importer la societe
    this.organisationService.importerOrganisation(socId).then(() => {
      // mettre à jour la societe
      this.majSociete(socId);
      this.verifierSocieteDoublon();
      this.dialogueShareService.addDialogue('La société a bien été importée');
    });
  }

  supprimerSociete(socId) {

  }

  retourCreerSociete() {
    this.structureChoisi = 'autre';
    this.doublonsRecherche = [];
  }

  elemOuvert(id) {
    if (this.ouvert === id) {
      this.ouvert = 0;
    } else {
      this.ouvert = id;
    }
  }

  elementIsOuvert(id) {
    if (this.ouvert === id) {
      return true;
    }
  }

}
