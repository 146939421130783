import {Injectable} from '@angular/core';


@Injectable()
export class FormatIntranetShareService {

  public urlFicherContact(id) {
    return 'http://intranet.escalconsulting.com/contacts_cms.php?action=vuedetail&pers_ID=' + id;
  }

  public urlFicheSociete(id) {
    return 'http://intranet.escalconsulting.com/societes_cms.php?action=vuedetail&societe_ID=' + id;
  }


  public urlSuiviRelance(contactId, socId1) {
    return 'http://intranet.escalconsulting.com/rappels_cms.php?action=nouveau&IDcontactpar=' + contactId + '&IDScieClient1=' + socId1 + '&IDScieClient2=0';
  }

  public urlSuiviRelanceBis(contactId, socId1, socId2) {
    return 'http://intranet.escalconsulting.com/rappels_cms.php?action=nouveau&IDcontactpar=' + contactId + '&IDScieClient1=' + socId1 + '&IDScieClient2=' + socId2;
  }

  public urlSuiviRelanceExiste(rappelId) {
    return 'http://intranet.escalconsulting.com/rappels_cms.php?action=vuedetail&rappel_ID=' + rappelId;
  }

  public urlSuiviSupport(supportId) {
    return 'http://intranet.escalconsulting.com/supports_cms.php?action=vuedetail&support_ID=' + supportId;
  }

  public urlAjoutSupport(contactId) {
    return 'http://intranet.escalconsulting.com/supports_asso.php?action=vuedetail&ID_contact=' + contactId;
  }

  public urlAjoutEvenement() {
    return 'http://intranet.escalconsulting.com/evenements_cms.php?action=creer';
  }

  public urlSuiviContact(contactId) {
    return 'http://intranet.escalconsulting.com/contacts_cms.php?action=vuedetail&pers_ID=' + contactId;
  }

  public urlSuiviCalRed(calRedId) {
    return 'http://intranet.escalconsulting.com/calendrier_cms.php?action=vuedetail&calendrier_ID=' + calRedId;
  }

  public urlSuivi(suiviId) {
    return 'http://intranet.escalconsulting.com/contacts_suivi_cms.php?action=vuedetail&ID_suivi=' + suiviId;
  }

  public urlSocieteSalles(socId) {
    return 'http://intranet.escalconsulting.com/societes_cms.php?action=liste&type=&societe_ID=' + socId + '&action=liste&type=suiviaction&ID_societe=' + socId + '&salle=Liste+des+salles' ;
  }

  public urlSocieteEvenements(socId) {
    return 'http://intranet.escalconsulting.com/societes_cms.php?action=liste&type=&societe_ID=' + socId + '&action=liste&type=suiviaction&ID_societe=' + socId + '&evenement=Liste+des+Evenements' ;
  }

  public urlAuteur(auteurId)  {
    return 'http://intranet.escalconsulting.com/membres_cms.php?action=vuedetail&pers_ID=' + auteurId ;
  }

  public urlClientEvenements(clientId) {
    return 'http://intranet.escalconsulting.com/clients_cms.php?action=liste&type=evenement&client_ID=' + clientId + '&submit=Liste+des+%E9v%E8nements' ;
  }

  public urlClientRappels(clientId) {
    return 'http://intranet.escalconsulting.com/clients_cms.php?action=liste&type=rappel&client_ID=' + clientId + '&submit=Liste+des+rappels' ;
  }

  public urlClientCalendriers(clientId) {
    return 'http://intranet.escalconsulting.com/clients_cms.php?action=liste&type=calredactionnel&client_ID=' + clientId + '&submit=Liste+des+calendriers+redactionnels' ;
  }

  public urlClientParutions(clientId) {
    return 'http://intranet.escalconsulting.com/clients_cms.php?action=liste&type=parution&client_ID=' + clientId + '&submit=Liste+des+parutions' ;
  }

  public urlFicheClient(id) {
    return 'http://intranet.escalconsulting.com/clients_cms.php?action=vuedetail&client_ID=' + id;
  }

  public urlParution(id) {
    return 'http://intranet.escalconsulting.com/parution_cms.php?action=vuedetail&parution_ID=' + id;
  }

  public urlMail(id) {
    return 'http://intranet.escalconsulting.com/mail_cms.php?action=vuedetail&ID_mail=' + id;
  }

  public urlCalendrier(id) {
    return 'http://intranet.escalconsulting.com/calendrier_cms.php?action=vuedetail&calendrier_ID=' + id;
  }

  public urlSupport() {
    return 'http://intranet.escalconsulting.com/supports_cms.php?action=nouveau';
  }

  public urlLienPdf(pdf) {
    return 'http://intranet.escalconsulting.com/parutions/' + pdf;
  }

  public urlLienMultimedia(multimedia) {
    return 'http://intranet.escalconsulting.com/multimedia/' + multimedia;
  }

  public urlEvenementFiche(eventId) {
    return 'http://intranet.escalconsulting.com/evenements_cms.php?action=vuedetail&ID_evenement=' + eventId;
  }

  public urlListeContact(id) {
    return 'http://intranet.escalconsulting.com/listecontact_cms.php?action=vuedetail&ls_ID=' + id;
  }
  public urlRelanceContact(id) {
    return 'http://intranet.escalconsulting.com/rappels_cms.php?action=nouveau&IDcontactpar=' + id;
  }
  public urlContactEvenements(contactId) {
    return 'http://intranet.escalconsulting.com/contacts_cms.php?action=liste&type=evenement&ID_contact=' + contactId;
  }
  public urlContactParutions(contactId) {
    return 'http://intranet.escalconsulting.com/contacts_cms.php?action=liste&type=parution&ID_contact=' + contactId;
  }
  public urlContactRappels(contactId) {
    return 'http://intranet.escalconsulting.com/contacts_cms.php?action=liste&type=rappel&ID_contact=' + contactId;
  }
  public urlContactCalendriers(contactId) {
    return 'http://intranet.escalconsulting.com/contacts_cms.php?action=liste&type=calredactionnel&ID_contact=' + contactId;
  }
}
