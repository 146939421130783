import { Component, OnInit } from '@angular/core';
import {MotCleSite} from '../../../../model/site/MotCleSite';
import {MotCleSiteService} from '../../../../service/interne/Site/MotCleSiteService';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DndDropEvent, DropEffect} from 'ngx-drag-drop';
import {ArticleSite} from '../../../../model/site/ArticleSite';

@Component({
  selector: 'app-site-escal-mot-cle-configuration',
  templateUrl: './site-escal-mot-cle-configuration.component.html',
  styleUrls: ['./site-escal-mot-cle-configuration.component.scss'],
  providers: [ MotCleSiteService ]
})
export class SiteEscalMotCleConfigurationComponent implements OnInit {

  motCle: MotCleSite;
  id: any;
  sub: any;
  layout: any;
  horizontalLayoutActive:boolean = false;
  private currentDraggableEvent: DragEvent;
  articlesOrder: Array<ArticleSite>;
  articlesNoOrder: Array<ArticleSite>;
  private readonly verticalLayout = {
    container: "row",
    list: "column",
    dndHorizontal: false
  };
  private readonly horizontalLayout = {
    container: "row",
    list: "row",
    dndHorizontal: true
  };

  constructor(private snackBarService: MatSnackBar, private route: ActivatedRoute, private motCleSiteService: MotCleSiteService) {
    this.setHorizontalLayout( this.horizontalLayoutActive );
  }

  ngOnInit() {
    const that = this;
    this.sub = this.route.params.subscribe(params => {
      that.id = params['id'];
      that.motCleSiteService.getMotCle(that.id).then((retour) => {
        that.motCle = retour;
        console.log(that.motCle);
      });
    });
  }
  setHorizontalLayout( horizontalLayoutActive:boolean ) {
    this.layout = (horizontalLayoutActive) ? this.horizontalLayout : this.verticalLayout;
  }
  onDrop( event: DndDropEvent, list?:any[] ) {
  }

  onDragStart( event:DragEvent ) {
  }
  onDragged( item:any, list:any[], effect:DropEffect ) {
  }
  onDragEnd( event:DragEvent ) {
  }
}
