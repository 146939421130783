import {PressroomAccueilSection} from './PressroomAccueilSection';

export class PressroomAccueil {
    id: number;
    title: string;
    couleurTitle: string;
    sections: Array<PressroomAccueilSection>;



    constructor(id: number, title: string, couleurTitle: string, sections: Array<PressroomAccueilSection>) {
        this.id = id;
        this.title = title;
        this.couleurTitle = couleurTitle;
        this.sections = sections;
    }
}
