import { Component, OnInit } from '@angular/core';
import {ArticleSite} from '../../../model/site/ArticleSite';
import {AuthShareService} from '../../../service/partage/AuthShareService';
import {DialogueShareService} from '../../../service/partage/utilitaire/DialogueShareService';
import {MotCleSite} from '../../../model/site/MotCleSite';
import {MotCleSiteService} from '../../../service/interne/Site/MotCleSiteService';

@Component({
  selector: 'app-site-escal-mot-cle',
  templateUrl: './site-escal-mot-cle.component.html',
  styleUrls: ['./site-escal-mot-cle.component.scss'],
  providers: [ MotCleSiteService ]
})
export class SiteEscalMotCleComponent implements OnInit {

  curentPage: number = 1;
  motCles: Array<MotCleSite>;
  headerContent: Array<any> = [];
  maxPage: number;
  typeRecherche: Array<any> = [];
  recherche: Array<any> = [];

  constructor(public authShareService: AuthShareService, private motCleSiteService: MotCleSiteService, private dialogueShareService: DialogueShareService) { }

  ngOnInit() {
    this.initContent();
    this.headerContent.push('Mot Clé');
    this.headerContent.push('Action');

    this.typeRecherche.push('Mot Clé');
  }

  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }
  changePage(page) {
    this.curentPage = page;
    this.initContent();
  }

  initContent() {
    const that = this;
    if( this.recherche.length === 0 ){
      this.motCleSiteService.getMotCles(this.curentPage)
        .then((data) => {
          that.motCles = data['hydra:member'] as Array<MotCleSite>;
          that.getMaxPage(data['hydra:totalItems']);
        });
    } else {
      this.motCleSiteService.getMotCleRecherche(this.recherche, this.curentPage)
        .then((data) => {
          that.motCles = data['hydra:member'] as Array<MotCleSite>;
          that.getMaxPage(data['hydra:totalItems']);
        });
    }
  }

  updateRecherche(recherche: Array<any>) {
    this.curentPage = 1;
    if (recherche[0] === ''){
      this.recherche = [];
    } else {
      this.recherche = recherche;
    }
    this.initContent();
  }

  deleteArticle(event){
    const that = this;
    const variable = {
      'motCleSiteService': this.motCleSiteService, 'dialogueShareService': this.dialogueShareService, 'id': event
    };
    this.dialogueShareService.addDialogue('êtes vous sûre de vouloir supprimer l\'article', function () {
      variable['motCleSiteService'].deleteMotCle(variable['id'])
        .then((retour) => {
          variable['dialogueShareService'].addDialogue( 'atticle supprimé avec succés');
          that.initContent();
        })
        .catch((retour) => {
          variable['dialogueShareService'].addDialogue('erreur lors de la supression');
        });
    });
  }

}
