export class Dialogue {

  id: number;
 content: string;
 type: string;
 fonctionCallBack;

  constructor(id: number, content: string, type: string, fonctionCallBack) {
    this.id = id;
    this.content = content;
    this.type = type;
    this.fonctionCallBack = fonctionCallBack;
  }
}
