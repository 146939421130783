import {Client} from '../Client';
import {ImagePressroom} from './ImagePressroom';
import {PressroomAccueil} from './PressroomAccueil';
import {PressroomCommunique} from './PressroomCommunique';
import {PressroomArticle} from './PressroomArticle';
import {AuthUser} from '../../user/authUser';
import {PressroomEvenement} from './PressroomEvenement';

export class Pressroom {
    id: number;
    nom: string;
    client: Client;
    logos: Array<ImagePressroom>;
    pressroomAccueil: PressroomAccueil;
    phototech: Array<ImagePressroom>;
    pressroomCommunique: Array<PressroomCommunique>;
    pressroomArticle: Array<PressroomArticle>;
    imageFond: ImagePressroom;
    logoAffiche: ImagePressroom;
    contact1: AuthUser;
    contact2: AuthUser;
    pressroomEvenement: Array<PressroomEvenement>;


    constructor(id: number, nom: string, client: Client, logos: Array<ImagePressroom>, pressroomAccueil: PressroomAccueil,
                phototech: Array<ImagePressroom>, pressroomCommunique: Array<PressroomCommunique>,
                pressroomArticle: Array<PressroomArticle>, imageFond: ImagePressroom, logoAffiche: ImagePressroom, contact1: AuthUser,
                contact2: AuthUser, pressroomEvenement: Array<PressroomEvenement>) {
        this.id = id;
        this.nom = nom;
        this.client = client;
        this.logos = logos;
        this.pressroomAccueil = pressroomAccueil;
        this.phototech = phototech;
        this.pressroomCommunique = pressroomCommunique;
        this.pressroomArticle = pressroomArticle;
        this.imageFond = imageFond;
        this.logoAffiche = logoAffiche;
        this.contact1 = contact1;
        this.contact2 = contact2;
      this.pressroomEvenement = pressroomEvenement;
    }
}
