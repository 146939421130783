import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {ClientRoleUserService} from '../../../../../../service/interne/client/ClientRoleUserService';
import {AuthShareService} from '../../../../../../service/partage/AuthShareService';

import {RoleUserClient} from '../../../../../../model/client/RoleUserClient';

@Component({
  selector: 'app-escal-client-fiche-presse-member',
  templateUrl: './escal-client-fiche-presse-member.component.html',
  styleUrls: ['./escal-client-fiche-presse-member.component.scss'],
  providers: [ ClientRoleUserService ],
})
export class EscalClientFichePresseMemberComponent implements OnInit {

  @Input()
  roleUserClient: RoleUserClient;
  @Output()
  refresh = new EventEmitter();
  constructor(private clientRoleUserService: ClientRoleUserService, public authShareService: AuthShareService) { }

  ngOnInit() {
  }
  deleteMember() {
    const that = this;
    this.clientRoleUserService.updateRoleUserClient(this.roleUserClient)
      .then((retour) => {
        that.refresh.emit();
      });
  }

}
