import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../../service/interne/user/UserService';
import {CiviliteService} from '../../../../service/interne/user/CiviliteService';
import {AuthUserService} from '../../../../service/interne/user/AuthUserService';
import {Civilite} from '../../../../model/user/Civilite';
import {Router} from '@angular/router';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';

@Component({
  selector: 'app-escal-utilisateur-add',
  templateUrl: './escal-utilisateur-add.component.html',
  styleUrls: ['./escal-utilisateur-add.component.scss'],
  providers: [ UserService, CiviliteService, AuthUserService ]
})
export class EscalUtilisateurAddComponent implements OnInit {

  nom = '';
  prenom = '';
  email = '';
  passwordMail = '';
  telExterne = '';
  telInterne = '';
  userName = '';
  password = '';
  role = 'ROLE_BO';
  fonction = 'Bo';
  civilite = 1;
  civilites: Array<Civilite>;
  loginOld = '';
  passwordOld = '';
  test1 = '';
  uSysteme = false;

  constructor(private dialogueShareService: DialogueShareService, private userService: UserService,
              private civiliteService: CiviliteService, private authUserService: AuthUserService, private router: Router) { }

  ngOnInit() {
    const that = this;
    this.civiliteService.getCivilites()
      .then((retour) => {
        that.civilites = retour['hydra:member'];
      });
    setTimeout(function (){
      that.email = '';
      that.passwordMail = '';
    }, 300);
  }

  saveAuthUser(){
    if(this.nom.length < 2) {
      this.dialogueShareService.addDialogue('le nom doit faire au moins 2 caractères');
    } else if (this.prenom.length < 2){
      this.dialogueShareService.addDialogue('le prénom doit faire au moins 2 caractères');
    } else if(!this.email.includes('@escalconsulting.com')){
      this.dialogueShareService.addDialogue('l\'adresse mail doit contenir @escalconsulting.com');
    } else if (this.passwordMail.length <= 5){
      this.dialogueShareService.addDialogue('le password email doit faire au moins 6 caractères');
    } else if (this.password.length <= 5){
      this.dialogueShareService.addDialogue('le password doit faire au moins 6 caractères');
    } else {
      const that = this;
      this.userService.addUser(this.nom, this.prenom, this.email, this.civilite, this.telExterne)
        .then((retour) => {
          const idUserCree = retour['@id'];
          that.authUserService.addAuthUser(this.userName, this.password, this.passwordMail, idUserCree, this.fonction, this.telInterne,
            this.role, this.loginOld, this.passwordOld,this.uSysteme)
            .then((retour1) => {
              that.router.navigate(['/escal/user/actuel']);
            });
        });
    }
  }
}
