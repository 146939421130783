import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';


@Injectable()
export class CoordonneesService {
  private coordonneesUrl = Environement.BASE_API_URL + '/api/coordonnees';
  private paysUrl = Environement.BASE_API_URL + '/api/pays';
  constructor(private http: HttpClient) { }

  getCoordonneesDetail(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.coordonneesUrl + '/' + id , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
getContacts() {
  const headers = this.initOptionHeader();
  return this.http.get(this.coordonneesUrl, {headers: headers}).toPromise()
    .then( response => response)
    .catch(this.errorHandler);
}
  addCoordonnees(coordonnees) {
    const headers = this.initOptionHeader();
    const now = new Date();
    if (coordonnees.pays !== null && coordonnees.pays.id > 0) {
      coordonnees.pays = this.paysUrl + '/' + coordonnees.pays.id;
    } else {
      coordonnees.pays = null;
    }
    const dateHeure: string = now.getFullYear().toString() + '-' + (now.getMonth() + 1).toString() + '-' + now.getDate().toString()
      + now.getHours().toString() + ':' + now.getMinutes().toString();
    return this.http.post(this.coordonneesUrl, {premierTel: coordonnees.premierTel, deuxiemeTel: coordonnees.deuxiemeTel,
        email: coordonnees.email, rue: coordonnees.rue, complements: coordonnees.complements,
        boitePostale: coordonnees.boitePostale, codePostal: coordonnees.codePostal, ville: coordonnees.ville,
        pays: coordonnees.pays, premierURL: coordonnees.premierURL,
        deuxiemeURL: coordonnees.deuxiemeURL, auteur: 'api/auth_users/' + coordonnees.auteur.id,
        creation: dateHeure, modification: coordonnees.modification, ancienId: coordonnees.ancienId},
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  modifierCoordonnees(idCoordContMaj, coordonnee, contactId, paysId) {
    const headers = this.initOptionHeader();
    let pays = null;
    let auteur = null;
    if (coordonnee.auteur != null) {
      auteur = Environement.BASE_API_URL + '/api/auth_users/' + coordonnee.auteur.id;
    }
    coordonnee.modification = new Date();
    const DateModifFormat = coordonnee.modification.getFullYear() + '-'
      + (coordonnee.modification.getMonth() + 1).toString() + '-' + coordonnee.modification.getDate()
      + ' ' + coordonnee.modification.getHours() + ':' + coordonnee.modification.getMinutes() + ':00' ;
    // si pays renseigné
    if (paysId != 0) {
      pays = Environement.BASE_API_URL + '/api/pays/' + paysId;
    }
    return this.http.put(this.coordonneesUrl + '/' +  idCoordContMaj,
      {'premierTel': coordonnee.premierTel, 'deuxiemeTel': coordonnee.deuxiemeTel, 'email': coordonnee.email,
        'rue': coordonnee.rue, 'complements': coordonnee.complements, 'boitePostale': coordonnee.boitePostale,
        'codePostal': coordonnee.codePostal, 'ville': coordonnee.ville,
        'pays': pays,
        'premierURL': coordonnee.premierURL, 'deuxiemeURL': coordonnee.deuxiemeURL,
        'contact': Environement.BASE_API_URL + '/api/contacts/' + contactId,
        'auteur': auteur,
        'modification': DateModifFormat},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  resetCoordonneesContact(ContactID) {
    const headers = this.initOptionHeader();
    return this.http.put( Environement.BASE_API_URL + '/api/contacts/' +  ContactID,
        {
          'coordonnees': null
        },
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
