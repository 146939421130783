import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {AuthUserService} from '../../../../../service/interne/user/AuthUserService';
import {ClientRoleUserService} from '../../../../../service/interne/client/ClientRoleUserService';

import {Client} from '../../../../../model/client/Client';
import {AuthUser} from '../../../../../model/user/authUser';
import {RoleUserClient} from '../../../../../model/client/RoleUserClient';

@Component({
  selector: 'app-escal-client-fiche-presse',
  templateUrl: './escal-client-fiche-presse.component.html',
  styleUrls: ['./escal-client-fiche-presse.component.scss'],
  providers: [ AuthUserService, ClientRoleUserService ],
})
export class EscalClientFichePresseComponent implements OnInit {


  @Input()
  client: Client;
  @Output()
  refresh = new EventEmitter();
  authUsers: Array<AuthUser>;

  constructor(private authUserService: AuthUserService, private clientRoleUserService: ClientRoleUserService) { }

  ngOnInit() {
    const that = this;
    this.authUserService.getUsersList(1)
      .then((retour) => {
        that.authUsers = retour['hydra:member'] as Array<AuthUser>;

      });
  }

  refreshAction() {
    this.refresh.emit();
  }

}
