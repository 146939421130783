import {Pays} from './Pays';
import {AuthUser} from '../user/authUser';
import {Organisation} from '../organisation/Organisation';
import {Contact} from '../contact/Contact';
import {Support} from '../support/Support';

export class Coordonnees {
  id: number;
  premierTel: string;
  deuxiemeTel: string;
  email: string;
  rue: string;
  complements: string;
  boitePostale: string;
  codePostal: string;
  ville: string;
  pays: Pays;
  premierURL: string;
  deuxiemeURL: string;
  auteur: AuthUser;
  creation: Date;
  modification: Date;
  ancienId: number;
  organisation: Organisation;
  support: Support;
  contact: Contact;

  constructor(id: number, premierTel: string, deuxiemeTel: string, email: string, rue: string, complements: string , boitePostale: string, codePostal: string,
              ville: string, pays: Pays, premierURL: string, deuxiemeURL: string, auteur: AuthUser, creation: Date,
              modification: Date, ancienId: number, organisation: Organisation, support: Support, contact: Contact) {
    this.id = id;
    this.premierTel = premierTel;
    this.deuxiemeTel = deuxiemeTel;
    this.email = email;
    this.rue = rue;
    this.complements = complements;
    this.boitePostale = boitePostale;
    this.codePostal = codePostal;
    this.ville = ville;
    this.pays = pays;
    this.premierURL = premierURL;
    this.deuxiemeURL = deuxiemeURL;
    this.auteur = auteur;
    this.creation = creation;
    this.modification = modification;
    this.ancienId = ancienId;
    this.organisation = organisation;
    this.support = support;
    this.contact = contact;
  }
}
