import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Client} from '../../../../../model/client/Client';
import {Organisation} from '../../../../../model/organisation/Organisation';
import {ContactOrganisation} from '../../../../../model/contact/ContactOrganisation';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {OrganisationService} from '../../../../../service/interne/organisation/OrganisationService';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';
import {Router} from '@angular/router';

@Component({
  selector: 'app-escal-client-fiche-administratif',
  templateUrl: './escal-client-fiche-administratif.component.html',
  styleUrls: ['./escal-client-fiche-administratif.component.scss'],
  providers: [ OrganisationService ]
})
export class EscalClientFicheAdministratifComponent implements OnInit {

  @Input()
  client: Client;
  organisation: Organisation;
  afficher: string;
  commentaires: string;
  nbCaracteres: number;
  nbCaracteresPremURL: number;
  nbCaracteresDeuxURL: number;
  auteurAncienId: number;
  urlTempUn: string;
  urlTempDeux: string;
  @Output()
  refresh = new EventEmitter();
  constructor(public formatIntranetShareService: FormatIntranetShareService, private organisationService: OrganisationService) { }

  ngOnInit() {
    this.auteurAncienId = 0;
    this.afficher = 'moins';
    this.nbCaracteres = 0;
    this.nbCaracteresPremURL = 0;
    this.nbCaracteresDeuxURL = 0;
    // récupérer organisation liée au client
    this.organisationService.getInfosClient(this.client.id).then((societe) => {
      const orgs = societe['hydra:member'] as Array<Organisation>;
      this.organisation = orgs[0];
      // adapter l'affichage du commentaire si trop long
      if (this.organisation.commentaires != null) {
        this.nbCaracteres = this.organisation.commentaires.length;
        this.commentaires = this.organisation.commentaires.substr(0, 150);
      }
      // récupérer l'ancien id de l'auteur pour le lien vers sa fiche intranet
      if (this.organisation.auteur != null) {
        this.organisationService.getAncienIdAuteur(this.organisation.id).then((retour) => {
          if (retour !== 0) {
            this.auteurAncienId = retour;
          }
        });
      }
      // adapter l'affichage du premier lien si trop long
      if (this.organisation.coordonnees != null) {
        if (this.organisation.coordonnees.premierURL != null) {
          this.nbCaracteresPremURL = this.organisation.coordonnees.premierURL.length;
          let i = 0;
          this.urlTempUn = '';
          while (i < this.nbCaracteresPremURL) {
            this.urlTempUn = this.urlTempUn + this.organisation.coordonnees.premierURL.substr(i, 70) + ' ';
            i = i + 70;
          }
        }
      }
      // adapter l'affichage du deuxieme lien si trop long
      if (this.organisation.coordonnees != null) {
        if (this.organisation.coordonnees.deuxiemeURL != null) {
          this.nbCaracteresDeuxURL = this.organisation.coordonnees.deuxiemeURL.length;
          let i = 0;
          this.urlTempDeux = '';
          while (i < this.nbCaracteresDeuxURL) {
            this.urlTempDeux = this.urlTempDeux + this.organisation.coordonnees.deuxiemeURL.substr(i, 70) + ' ';
            i = i + 70;
          }
        }
      }
    });
  }

  changerAffichage() {
    if (this.afficher === 'plus') {
      this.commentaires = this.organisation.commentaires.substr(0, 150);
      this.afficher = 'moins';
    } else {
      this.commentaires = this.organisation.commentaires;
      this.afficher = 'plus';
    }
  }
}
