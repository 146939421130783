import { Component, OnInit } from '@angular/core';
import {ModalCalendrierShareService} from '../../../../service/partage/ModalCalendrierShareService';
import {Client} from '../../../../model/client/Client';

@Component({
  selector: 'app-modal-calendrier-event',
  templateUrl: './modal-calendrier-event.component.html',
  styleUrls: ['./modal-calendrier-event.component.scss']
})
export class ModalCalendrierEventComponent implements OnInit {

  constructor(public modalCalendrierShareService: ModalCalendrierShareService) { }

  ngOnInit() {
  }

}
