import {Component, Input, OnInit} from '@angular/core';
import {UserGroupe} from '../../../../../model/user/UserGroupe';

@Component({
  selector: 'app-escal-utilisateur-groupe-item',
  templateUrl: './escal-utilisateur-groupe-item.component.html',
  styleUrls: ['./escal-utilisateur-groupe-item.component.scss']
})
export class EscalUtilisateurGroupeItemComponent implements OnInit {

  @Input()
  group: UserGroupe;

  constructor() { }

  ngOnInit() {
  }


}
