import { BrowserModule } from '@angular/platform-browser';
import { TagInputModule } from 'ngx-chips';
import { NgModule, LOCALE_ID } from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {ColorPickerModule} from 'ngx-color-picker';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import {NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FlatpickrModule} from 'angularx-flatpickr';
import {registerLocaleData} from '@angular/common';
import {ChartsModule} from 'ng2-charts';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
import {MatIconModule, MatListModule, MatSelectModule, MatSlideToggleModule, MatSnackBarModule} from '@angular/material';

// drageble
import { DndModule } from 'ngx-drag-drop';


// service
import {AuthShareService} from './service/partage/AuthShareService';
import {DialogueShareService} from './service/partage/utilitaire/DialogueShareService';
import {OpenWithPostShareService} from './service/partage/utilitaire/OpenWithPostShareService';
import {MenuShareService} from './service/partage/utilitaire/MenuShareService';
import {ModalCalendrierShareService} from './service/partage/ModalCalendrierShareService';

// component
import { AppComponent } from './app.component';
import { LoginComponent } from './component/utilitaire/login/login.component';
import { UtilitaireDialogueComponent } from './component/utilitaire/utilitaire-dialogue/utilitaire-dialogue.component';
import { UtilitaireDialogueAlertComponent } from './component/utilitaire/utilitaire-dialogue/utilitaire-dialogue-alert/utilitaire-dialogue-alert.component';
import { UtilitaireDialogueMessageComponent } from './component/utilitaire/utilitaire-dialogue/utilitaire-dialogue-message/utilitaire-dialogue-message.component';
import { StructureMenuGaucheComponent } from './component/utilitaire/structure/structure-menu-gauche/structure-menu-gauche.component';
import { StructureMenuGaucheInfoComponent } from './component/utilitaire/structure/structure-menu-gauche/structure-menu-gauche-info/structure-menu-gauche-info.component';
import { StructureMenuGaucheSwitchComponent } from './component/utilitaire/structure/structure-menu-gauche/structure-menu-gauche-switch/structure-menu-gauche-switch.component';
import { StructureMenuGaucheContentComponent } from './component/utilitaire/structure/structure-menu-gauche/structure-menu-gauche-content/structure-menu-gauche-content.component';
import { StructureMenuGaucheContentElementComponent } from './component/utilitaire/structure/structure-menu-gauche/structure-menu-gauche-content/structure-menu-gauche-content-element/structure-menu-gauche-content-element.component';
import { StructureMenuGaucheContentElementContentComponent } from './component/utilitaire/structure/structure-menu-gauche/structure-menu-gauche-content/structure-menu-gauche-content-element-content/structure-menu-gauche-content-element-content.component';
import { StructureMenuHeaderComponent } from './component/utilitaire/structure/structure-menu-header/structure-menu-header.component';
import { BugsComponent } from './component/utilitaire/bugs/bugs.component';
import { VerificationThematiquesComponent } from './component/utilitaire/verification-thematiques/verification-thematiques.component';
import { EscalUtilisateurProfilPriveComponent } from './component/escal/escal-utilisateur/escal-utilisateur-profil-prive/escal-utilisateur-profil-prive.component';
import { EscalUtilisateurChangeMdpComponent } from './component/escal/escal-utilisateur/escal-utilisateur-change-mdp/escal-utilisateur-change-mdp.component';
import { GestionMesClientsComponent } from './component/escal/gestion-client/gestion-mes-clients/gestion-mes-clients.component';
import { GestionMesClientDetailComponent } from './component/escal/gestion-client/gestion-mes-client-detail/gestion-mes-client-detail.component';
import { GestionMesClientDetailItemComponent } from './component/escal/gestion-client/gestion-mes-client-detail/gestion-mes-client-detail-item/gestion-mes-client-detail-item.component';
import { EscalUtilisateurProfilPublicComponent } from './component/escal/escal-utilisateur/escal-utilisateur-profil-public/escal-utilisateur-profil-public.component';
import { EscalUtilisateurProfilAdministratifComponent } from './component/escal/escal-utilisateur/escal-utilisateur-profil-administratif/escal-utilisateur-profil-administratif.component';
import { EscalUtilisateurProfilAdministratifAdresseComponent } from './component/escal/escal-utilisateur/escal-utilisateur-profil-administratif/escal-utilisateur-profil-administratif-adresse/escal-utilisateur-profil-administratif-adresse.component';
import { EscalUtilisateurProfilAdministratifInformationComponent } from './component/escal/escal-utilisateur/escal-utilisateur-profil-administratif/escal-utilisateur-profil-administratif-information/escal-utilisateur-profil-administratif-information.component';
import { EscalVeilleThematiqueComponent } from './component/escal/escal-veille/escal-veille-thematique/escal-veille-thematique.component';
import { EscalVeilleCreationComponent } from './component/escal/escal-veille/escal-veille-creation/escal-veille-creation.component';
import { EscalVeilleThematiqueAffichageComponent } from './component/escal/escal-veille/escal-veille-thematique-affichage/escal-veille-thematique-affichage.component';
import { EscalVeilleJourComponent } from './component/escal/escal-veille/escal-veille-jour/escal-veille-jour.component';
import { EscalUtilisateurListComponent } from './component/escal/escal-utilisateur/escal-utilisateur-list/escal-utilisateur-list.component';
import { PaginationRechercheComponent } from './component/utilitaire/pagination/pagination-recherche/pagination-recherche.component';
import { PaginationHeaderComponent } from './component/utilitaire/pagination/pagination-header/pagination-header.component';
import { PaginationContentUserComponent } from './component/utilitaire/pagination/pagination-content/pagination-content-user/pagination-content-user.component';
import { PaginationPaginateurComponent } from './component/utilitaire/pagination/pagination-paginateur/pagination-paginateur.component';
import { EscalUtilisateurAddComponent } from './component/escal/escal-utilisateur/escal-utilisateur-add/escal-utilisateur-add.component';
import { AdministrationClientRoleUserComponent } from './component/administration/administration-client-role-user/administration-client-role-user.component';
import { EscalUtilisateurGroupeComponent } from './component/escal/escal-utilisateur/groupe/escal-utilisateur-groupe/escal-utilisateur-groupe.component';
import { EscalUtilisateurGroupeItemComponent } from './component/escal/escal-utilisateur/groupe/escal-utilisateur-groupe-item/escal-utilisateur-groupe-item.component';
import { EscalUtilisateurGroupeDetailComponent } from './component/escal/escal-utilisateur/groupe/escal-utilisateur-groupe-detail/escal-utilisateur-groupe-detail.component';
import { EscalClientListComponent } from './component/escal/escal-client/escal-client-list/escal-client-list.component';
import { PaginationContentClientComponent } from './component/utilitaire/pagination/pagination-content/pagination-content-client/pagination-content-client.component';
import { EscalClientAddComponent } from './component/escal/escal-client/escal-client-add/escal-client-add.component';
import { EscalClientFicheComponent } from './component/escal/escal-client/escal-client-fiche/escal-client-fiche.component';
import { EscalClientFicheCoupureComponent } from './component/escal/escal-client/escal-client-fiche/escal-client-fiche-coupure/escal-client-fiche-coupure.component';
import { EscalClientFichePresseComponent } from './component/escal/escal-client/escal-client-fiche/escal-client-fiche-presse/escal-client-fiche-presse.component';
import { EscalClientFichePresseMemberComponent } from './component/escal/escal-client/escal-client-fiche/escal-client-fiche-presse/escal-client-fiche-presse-member/escal-client-fiche-presse-member.component';
import { EscalClientFicheLogoComponent } from './component/escal/escal-client/escal-client-fiche/escal-client-fiche-logo/escal-client-fiche-logo.component';
import { EscalMaillingListComponent } from './component/escal/escal-mailling/escal-mailling-list/escal-mailling-list.component';
import { EscalMaillingListItemComponent } from './component/escal/escal-mailling/escal-mailling-list/escal-mailling-list-item/escal-mailling-list-item.component';
import { EscalMaillingListSendComponent } from './component/escal/escal-mailling/escal-mailling-list-send/escal-mailling-list-send.component';
import { PaginationContentMailSendComponent } from './component/utilitaire/pagination/pagination-content/pagination-content-mail-send/pagination-content-mail-send.component';
import { EscalMaillingValiderComponent } from './component/escal/escal-mailling/escal-mailling-valider/escal-mailling-valider.component';
import { EscalMaillingViewComponent } from './component/escal/escal-mailling/escal-mailling-view/escal-mailling-view.component';
import { EscalMaillingViewPartieComponent } from './component/escal/escal-mailling/escal-mailling-view/escal-mailling-view-partie/escal-mailling-view-partie.component';
import { EscalMaillingViewPieceJointesComponent } from './component/escal/escal-mailling/escal-mailling-view/escal-mailling-view-piece-jointes/escal-mailling-view-piece-jointes.component';
import { EscalMaillingViewEnCourComponent } from './component/escal/escal-mailling/escal-mailling-view/escal-mailling-view-en-cour/escal-mailling-view-en-cour.component';
import { EscalMaillingSuivitComponent } from './component/escal/escal-mailling/escal-mailling-suivit/escal-mailling-suivit.component';
import { TestCalendrierComponent } from './component/calendrier/test-calendrier/test-calendrier.component';
import { AfficheCalendrierComponent } from './component/calendrier/affiche-calendrier/affiche-calendrier.component';
import { AdministrationSalleComponent } from './component/administration/administration-salle/administration-salle.component';
import { AdministrationSalleItemComponent } from './component/administration/administration-salle/administration-salle-item/administration-salle-item.component';
import { ModalCalendrierComponent } from './component/calendrier/modal-calendrier/modal-calendrier.component';
import { ModalCalendrierSalleComponent } from './component/calendrier/modal-calendrier/modal-calendrier-salle/modal-calendrier-salle.component';
import { DemandeCongeComponent } from './component/utilitaire/demande-conge/demande-conge.component';
import { UpdateJourFerieComponent } from './component/utilitaire/update-jour-ferie/update-jour-ferie.component';
import { ModalCalendrierReservationComponent } from './component/calendrier/modal-calendrier/modal-calendrier-reservation/modal-calendrier-reservation.component';
import { ModalCalendrierClientComponent } from './component/calendrier/modal-calendrier/modal-calendrier-client/modal-calendrier-client.component';
import { ModalCalendrierEventComponent } from './component/calendrier/modal-calendrier/modal-calendrier-event/modal-calendrier-event.component';
import { ModalCalendrierParticipantComponent } from './component/calendrier/modal-calendrier-participant/modal-calendrier-participant.component';
import { AfficheCalendrierMesClientsComponent } from './component/calendrier/affiche-calendrier-mes-clients/affiche-calendrier-mes-clients.component';
import { AfficheCalendrierClientComponent } from './component/calendrier/affiche-calendrier-client/affiche-calendrier-client.component';
import { AfficheCalendrierUserComponent } from './component/calendrier/affiche-calendrier-user/affiche-calendrier-user.component';
import { SommaireListeParutionComponent } from './component/escal/parution/sommaire-liste-parution/sommaire-liste-parution.component';
import { SommaireListeParutionTypeComponent } from './component/escal/parution/sommaire-liste-parution/sommaire-liste-parution-type/sommaire-liste-parution-type.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxSpinnerModule} from 'ngx-spinner';
import { MergepdfComponent } from './component/utilitaire/mergepdf/mergepdf.component';
import { PressroomClientComponent } from './component/escal/escal-client/pressroom-client/pressroom-client.component';
import { PressroomClientCreateComponent } from './component/escal/escal-client/pressroom-client/pressroom-client-create/pressroom-client-create.component';
import { PressroomClientConfigurationComponent } from './component/escal/escal-client/pressroom-client/pressroom-client-configuration/pressroom-client-configuration.component';
import { PressroomClientConfigurationLogoComponent } from './component/escal/escal-client/pressroom-client/pressroom-client-configuration/pressroom-client-configuration-logo/pressroom-client-configuration-logo.component';
import { PressroomClientConfigurationAccueilComponent } from './component/escal/escal-client/pressroom-client/pressroom-client-configuration/pressroom-client-configuration-accueil/pressroom-client-configuration-accueil.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import { PressroomClientConfigurationPhototechComponent } from './component/escal/escal-client/pressroom-client/pressroom-client-configuration/pressroom-client-configuration-phototech/pressroom-client-configuration-phototech.component';
import { PressroomClientConfigurationCommuniqueComponent } from './component/escal/escal-client/pressroom-client/pressroom-client-configuration/pressroom-client-configuration-communique/pressroom-client-configuration-communique.component';
import { PressroomClientConfigurationArticleComponent } from './component/escal/escal-client/pressroom-client/pressroom-client-configuration/pressroom-client-configuration-article/pressroom-client-configuration-article.component';
import {PressroomShareService} from './service/partage/PressroomShareService';
import { PressroomClientConfigurationTemplateComponent } from './component/escal/escal-client/pressroom-client/pressroom-client-configuration/pressroom-client-configuration-template/pressroom-client-configuration-template.component';
import { ImportListeContactHaComponent } from './component/utilitaire/import/import-liste-contact-ha/import-liste-contact-ha.component';
import {ImportListeContactHaShareService} from './service/partage/import/ImportListeContactHaShareService';
import { ImportListeContactHaChoixColoneComponent } from './component/utilitaire/import/import-liste-contact-ha/import-liste-contact-ha-choix-colone/import-liste-contact-ha-choix-colone.component';
import { StatistiqueIntranetComponent } from './component/utilitaire/statistique/statistique-intranet/statistique-intranet.component';
import { SiteEscalArticleComponent } from './component/site-escal/site-escal-article/site-escal-article.component';
import { SiteEscalArticleAddComponent } from './component/site-escal/site-escal-article/site-escal-article-add/site-escal-article-add.component';
import { SiteEscalArticleConfigurationComponent } from './component/site-escal/site-escal-article/site-escal-article-configuration/site-escal-article-configuration.component';
import { PaginationContentArticleSiteComponent } from './component/utilitaire/pagination/pagination-content/pagination-content-article-site/pagination-content-article-site.component';
import { SiteEscalArticlePhotoComponent } from './component/site-escal/site-escal-article/site-escal-article-photo/site-escal-article-photo.component';
import { SiteEscalMotCleComponent } from './component/site-escal/site-escal-mot-cle/site-escal-mot-cle.component';
import { PaginationContentMotCleSiteComponent } from './component/utilitaire/pagination/pagination-content/pagination-content-mot-cle-site/pagination-content-mot-cle-site.component';
import { SiteEscalMotCleAddComponent } from './component/site-escal/site-escal-mot-cle/site-escal-mot-cle-add/site-escal-mot-cle-add.component';
import { SiteEscalMotCleConfigurationComponent } from './component/site-escal/site-escal-mot-cle/site-escal-mot-cle-configuration/site-escal-mot-cle-configuration.component';
import { EscalSuivitGestionComponent } from './component/escal/suivit/escal-suivit-gestion/escal-suivit-gestion.component';
import { UtilitaireBddBaseComponent } from './component/utilitaire/utilitaire-bdd/utilitaire-bdd-base/utilitaire-bdd-base.component';
import {FormatIntranetShareService} from './service/partage/prototype/FormatIntranetShareService';
import { AdministrationValidationCongeComponent } from './component/administration/administration-validation-conge/administration-validation-conge.component';
import { ReportMailRetourComponent } from './component/utilitaire/report-mail-retour/report-mail-retour.component';
import {MatRadioModule} from '@angular/material/radio';
import { EscalSuiviGestionComponent } from './component/escal/suivit/escal-suivi-gestion/escal-suivi-gestion.component';
import {SuiviActionShareService} from './service/partage/SuiviActionShareService';
import { AdministrationFicheUtilisateurComponent } from './component/administration/administration-fiche-utilisateur/administration-fiche-utilisateur.component';
import { EscalClientDocumentComponent } from './component/escal/escal-client/escal-client-document/escal-client-document.component';
import { EscalClientFicheMotsClesComponent } from './component/escal/escal-client/escal-client-fiche/escal-client-fiche-mots-cles/escal-client-fiche-mots-cles.component';
import {  MatCheckboxModule } from '@angular/material/checkbox';
import { EscalClientFicheContactsComponent } from './component/escal/escal-client/escal-client-fiche/escal-client-fiche-contacts/escal-client-fiche-contacts.component';
import { PressroomClientConfigurationEvenementComponent } from './component/escal/escal-client/pressroom-client/pressroom-client-configuration-evenement/pressroom-client-configuration-evenement.component';
import { EscalClientFicheInformationsComponent } from './component/escal/escal-client/escal-client-fiche/escal-client-fiche-informations/escal-client-fiche-informations.component';
import { EscalClientModifierComponent } from './component/escal/escal-client/escal-client-modifier/escal-client-modifier.component';
import { EscalSuiviListComponent } from './component/escal/suivit/escal-suivi-list/escal-suivi-list.component';
import { PaginationContentSuiviComponent } from './component/utilitaire/pagination/pagination-content/pagination-content-suivi/pagination-content-suivi.component';
import { EscalSuiviFicheComponent } from './component/escal/suivit/escal-suivi-fiche/escal-suivi-fiche.component';
import {
  EscalSuiviGestionPersonneComponent
} from './component/escal/suivit/escal-suivi-gestion/escal-suivi-gestion-personne/escal-suivi-gestion-personne.component';
import {
  EscalSuiviGestionMediaComponent
} from './component/escal/suivit/escal-suivi-gestion/escal-suivi-gestion-media/escal-suivi-gestion-media.component';
import {
  EscalSuiviGestionOrganisationComponent
} from './component/escal/suivit/escal-suivi-gestion/escal-suivi-gestion-organisation/escal-suivi-gestion-organisation.component';
import { EscalSuiviModifierComponent } from './component/escal/suivit/escal-suivi-modifier/escal-suivi-modifier.component';
import { StatistiqueMutualisationComponent } from './component/utilitaire/statistique/statistique-mutualisation/statistique-mutualisation.component';
import { PaginationContentSuiviIntranetComponent } from './component/utilitaire/pagination/pagination-content/pagination-content-suivi-intranet/pagination-content-suivi-intranet.component';
import { EscalSuiviFicheIntranetComponent } from './component/escal/suivit/escal-suivi-fiche-intranet/escal-suivi-fiche-intranet.component';
import { EscalSuiviRechercheComponent } from './component/escal/suivit/escal-suivi-recherche/escal-suivi-recherche.component';
import { EscalOrganisationListComponent } from './component/escal/escal-organisation/escal-organisation-list/escal-organisation-list.component';
import { EscalOrganisationAddComponent } from './component/escal/escal-organisation/escal-organisation-add/escal-organisation-add.component';
import { PaginationContentOrganisationComponent } from './component/utilitaire/pagination/pagination-content/pagination-content-organisation/pagination-content-organisation.component';
import { EscalOrganisationFicheComponent } from './component/escal/escal-organisation/escal-organisation-fiche/escal-organisation-fiche.component';
import { EscalOrganisationFicheInformationsComponent } from './component/escal/escal-organisation/escal-organisation-fiche/escal-organisation-fiche-informations/escal-organisation-fiche-informations.component';
import { EscalOrganisationFicheSuivisComponent } from './component/escal/escal-organisation/escal-organisation-fiche/escal-organisation-fiche-suivis/escal-organisation-fiche-suivis.component';
import { EscalOrganisationFicheEvenementsComponent } from './component/escal/escal-organisation/escal-organisation-fiche/escal-organisation-fiche-evenements/escal-organisation-fiche-evenements.component';
import { EscalOrganisationFicheSallesComponent } from './component/escal/escal-organisation/escal-organisation-fiche/escal-organisation-fiche-salles/escal-organisation-fiche-salles.component';
import { EscalOrganisationFicheContactsComponent } from './component/escal/escal-organisation/escal-organisation-fiche/escal-organisation-fiche-contacts/escal-organisation-fiche-contacts.component';
import { EscalOrganisationFicheSocietesComponent } from './component/escal/escal-organisation/escal-organisation-fiche/escal-organisation-fiche-societes/escal-organisation-fiche-societes.component';
import { EscalOrganisationFicheSupportsComponent } from './component/escal/escal-organisation/escal-organisation-fiche/escal-organisation-fiche-supports/escal-organisation-fiche-supports.component';
import { EscalOrganisationModifierComponent } from './component/escal/escal-organisation/escal-organisation-modifier/escal-organisation-modifier.component';
import { EscalOrganisationRechercheComponent } from './component/escal/escal-organisation/escal-organisation-recherche/escal-organisation-recherche.component';
import { EscalOrganisationFicheExportComponent } from './component/escal/escal-organisation/escal-organisation-fiche/escal-organisation-fiche-export/escal-organisation-fiche-export.component';
import { EscalOrganisationDocumentComponent } from './component/escal/escal-organisation/escal-organisation-document/escal-organisation-document.component';
import { PaginationContentOrganisationIntranetComponent } from './component/utilitaire/pagination/pagination-content/pagination-content-organisation-intranet/pagination-content-organisation-intranet.component';
import { EscalOrganisationFicheIntranetComponent } from './component/escal/escal-organisation/escal-organisation-fiche-intranet/escal-organisation-fiche-intranet.component';
import { UtilitaireDedoublonnerSocietesComponent } from './component/utilitaire/utilitaire-dedoublonner/utilitaire-dedoublonner-societes/utilitaire-dedoublonner-societes.component';
import { UtilitaireImporterSocietesComponent } from './component/utilitaire/utilitaire-importer/utilitaire-importer-societes/utilitaire-importer-societes.component';
import { EscalClientFicheAdministratifComponent } from './component/escal/escal-client/escal-client-fiche/escal-client-fiche-administratif/escal-client-fiche-administratif.component';
import { EscalClientFicheSuivisComponent } from './component/escal/escal-client/escal-client-fiche/escal-client-fiche-suivis/escal-client-fiche-suivis.component';
import { EscalClientFicheParutionsComponent } from './component/escal/escal-client/escal-client-fiche/escal-client-fiche-parutions/escal-client-fiche-parutions.component';
import { EscalClientFicheCalendriersRedactionnelsComponent } from './component/escal/escal-client/escal-client-fiche/escal-client-fiche-calendriers-redactionnels/escal-client-fiche-calendriers-redactionnels.component';
import { EscalClientFicheRappelsComponent } from './component/escal/escal-client/escal-client-fiche/escal-client-fiche-rappels/escal-client-fiche-rappels.component';
import { EscalClientFicheEvenementsComponent } from './component/escal/escal-client/escal-client-fiche/escal-client-fiche-evenements/escal-client-fiche-evenements.component';
import { EscalClientAnalysesComponent } from './component/escal/escal-client/escal-client-analyses/escal-client-analyses.component';
import { EscalClientRapportActiviteComponent } from './component/escal/escal-client/escal-client-rapport-activite/escal-client-rapport-activite.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UtilitaireDialogueComponent,
    UtilitaireDialogueAlertComponent,
    UtilitaireDialogueMessageComponent,
    StructureMenuGaucheComponent,
    StructureMenuGaucheInfoComponent,
    StructureMenuGaucheSwitchComponent,
    StructureMenuGaucheContentComponent,
    StructureMenuGaucheContentElementComponent,
    StructureMenuGaucheContentElementContentComponent,
    StructureMenuHeaderComponent,
    BugsComponent,
    VerificationThematiquesComponent,
    EscalUtilisateurProfilPriveComponent,
    EscalUtilisateurChangeMdpComponent,
    GestionMesClientsComponent,
    GestionMesClientDetailComponent,
    GestionMesClientDetailItemComponent,
    EscalUtilisateurProfilPublicComponent,
    EscalUtilisateurProfilAdministratifComponent,
    EscalUtilisateurProfilAdministratifAdresseComponent,
    EscalUtilisateurProfilAdministratifInformationComponent,
    EscalVeilleThematiqueComponent,
    EscalVeilleCreationComponent,
    EscalVeilleThematiqueAffichageComponent,
    EscalVeilleJourComponent,
    EscalUtilisateurListComponent,
    PaginationRechercheComponent,
    PaginationHeaderComponent,
    PaginationContentUserComponent,
    PaginationPaginateurComponent,
    EscalUtilisateurAddComponent,
    AdministrationClientRoleUserComponent,
    EscalUtilisateurGroupeComponent,
    EscalUtilisateurGroupeItemComponent,
    EscalUtilisateurGroupeDetailComponent,
    EscalClientListComponent,
    PaginationContentClientComponent,
    EscalClientAddComponent,
    EscalClientFicheComponent,
    EscalClientFicheCoupureComponent,
    EscalClientFichePresseComponent,
    EscalClientFichePresseMemberComponent,
    EscalClientFicheLogoComponent,
    EscalMaillingListComponent,
    EscalMaillingListItemComponent,
    EscalMaillingListSendComponent,
    PaginationContentMailSendComponent,
    EscalMaillingValiderComponent,
    EscalMaillingViewComponent,
    EscalMaillingViewPartieComponent,
    EscalMaillingViewPieceJointesComponent,
    EscalMaillingViewEnCourComponent,
    EscalMaillingSuivitComponent,
    TestCalendrierComponent,
    AfficheCalendrierComponent,
    AdministrationSalleComponent,
    AdministrationSalleItemComponent,
    ModalCalendrierComponent,
    ModalCalendrierSalleComponent,
    DemandeCongeComponent,
    UpdateJourFerieComponent,
    ModalCalendrierReservationComponent,
    ModalCalendrierClientComponent,
    ModalCalendrierEventComponent,
    ModalCalendrierParticipantComponent,
    AfficheCalendrierMesClientsComponent,
    AfficheCalendrierClientComponent,
    AfficheCalendrierUserComponent,
    SommaireListeParutionComponent,
    SommaireListeParutionTypeComponent,
    MergepdfComponent,
    PressroomClientComponent,
    PressroomClientCreateComponent,
    PressroomClientConfigurationComponent,
    PressroomClientConfigurationLogoComponent,
    PressroomClientConfigurationAccueilComponent,
    PressroomClientConfigurationPhototechComponent,
    PressroomClientConfigurationCommuniqueComponent,
    PressroomClientConfigurationArticleComponent,
    PressroomClientConfigurationTemplateComponent,
    ImportListeContactHaComponent,
    ImportListeContactHaChoixColoneComponent,
    StatistiqueIntranetComponent,
    SiteEscalArticleComponent,
    SiteEscalArticleAddComponent,
    SiteEscalArticleConfigurationComponent,
    PaginationContentArticleSiteComponent,
    SiteEscalArticlePhotoComponent,
    SiteEscalMotCleComponent,
    PaginationContentMotCleSiteComponent,
    SiteEscalMotCleAddComponent,
    SiteEscalMotCleConfigurationComponent,
    EscalSuivitGestionComponent,
    AdministrationValidationCongeComponent,
    UtilitaireBddBaseComponent,
    ReportMailRetourComponent,
    EscalSuiviGestionComponent,
    EscalSuiviGestionPersonneComponent,
    EscalSuiviGestionMediaComponent,
    EscalSuiviGestionOrganisationComponent,
    AdministrationFicheUtilisateurComponent,
    EscalClientDocumentComponent,
    EscalClientFicheMotsClesComponent,
    EscalClientFicheContactsComponent,
    PressroomClientConfigurationEvenementComponent,
    EscalClientFicheInformationsComponent,
    EscalClientModifierComponent,
    EscalSuiviListComponent,
    PaginationContentSuiviComponent,
    EscalSuiviFicheComponent,
    EscalSuiviModifierComponent,
    StatistiqueMutualisationComponent,
    PaginationContentSuiviIntranetComponent,
    EscalSuiviFicheIntranetComponent,
    EscalSuiviRechercheComponent,
    EscalOrganisationListComponent,
    EscalOrganisationAddComponent,
    PaginationContentOrganisationComponent,
    EscalOrganisationFicheComponent,
    EscalOrganisationFicheInformationsComponent,
    EscalOrganisationFicheSuivisComponent,
    EscalOrganisationFicheEvenementsComponent,
    EscalOrganisationFicheSallesComponent,
    EscalOrganisationFicheContactsComponent,
    EscalOrganisationFicheSocietesComponent,
    EscalOrganisationFicheSupportsComponent,
    EscalOrganisationModifierComponent,
    EscalOrganisationRechercheComponent,
    EscalOrganisationFicheExportComponent,
    EscalOrganisationDocumentComponent,
    PaginationContentOrganisationIntranetComponent,
    EscalOrganisationFicheIntranetComponent,
    UtilitaireDedoublonnerSocietesComponent,
    UtilitaireImporterSocietesComponent,
    EscalClientFicheAdministratifComponent,
    EscalClientFicheSuivisComponent,
    EscalClientFicheParutionsComponent,
    EscalClientFicheCalendriersRedactionnelsComponent,
    EscalClientFicheRappelsComponent,
    EscalClientFicheEvenementsComponent,
    EscalClientAnalysesComponent,
    EscalClientRapportActiviteComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        ColorPickerModule,
        ChartsModule,
        TagInputModule,
        ReactiveFormsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        NgbModule,
        NgbModalModule,
        FlatpickrModule.forRoot(),
        DndModule,
        MatSnackBarModule,
        FlexLayoutModule,
        MatListModule,
        MatIconModule,
        MatSlideToggleModule,
        NgxSpinnerModule,
        CKEditorModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule
    ],
  providers: [
    AuthShareService,
    DialogueShareService,
    MenuShareService,
    OpenWithPostShareService,
    ModalCalendrierShareService,
    PressroomShareService,
    ImportListeContactHaShareService,
    FormatIntranetShareService,
    SuiviActionShareService,
    { provide: LOCALE_ID, useValue: 'fr-FR'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
