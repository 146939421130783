import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Environement} from '../../../../environement/environement';

import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {AuthShareService} from '../../../../service/partage/AuthShareService';
import {MaillingService} from '../../../../service/interne/mailling/maillingService';
import {AuthUserService} from '../../../../service/interne/user/AuthUserService';
import {PhotoMaillingService} from '../../../../service/interne/mailling/photoMaillingService';
import {PdfService} from '../../../../service/interne/utilitaire/PdfService';

import {AuthUser} from '../../../../model/user/authUser';
import {Mailling} from '../../../../model/mailling/Mailling';
import {PartieCp} from '../../../../model/mailling/PartieCp';
import {LogoMaillingService} from '../../../../service/interne/mailling/logoMaillingService';
import {CoupureService} from '../../../../service/interne/coupure/CoupureService';

@Component({
  selector: 'app-escal-mailling-view',
  templateUrl: './escal-mailling-view.component.html',
  styleUrls: ['./escal-mailling-view.component.scss'],
  providers: [ MaillingService, AuthUserService, PdfService, PhotoMaillingService, LogoMaillingService, CoupureService]
})
export class EscalMaillingViewComponent implements OnInit {

  @ViewChild('dt') datetimepicker: any;
  id: number;
  private sub: any;
  public mailling: Mailling;
  authUsers: Array<AuthUser>;
  authUsersSend: Array<AuthUser> = [];
  dateMaillingDecaler: Date;
  startMoment: Date = new Date();
  pdfUpload: Array<any> = [];
  photoUpload: Array<any> = [];
  logoUpload: Array<any> = [];
  noCacheVal: string;
  timing: Date;

  selectedTestVal: number;
  selectedReponseVal: number;
  selectedSendVal: number;
  resumer: string;
  hyperlien: boolean = true;
  baseServeur =  Environement.BASE_API_URL;
  personnes: Array<any> = [];
  signature: boolean = false;

  @ViewChild('dataContainer') dataContainer;
  @ViewChild('colorPicker') colorPicker;
  private dateEnable: any;

  constructor(private route: ActivatedRoute, private maillingService: MaillingService, private authUserService: AuthUserService,
              private authShareService: AuthShareService, private dialogueShareService: DialogueShareService, private router: Router,
              private pdfService: PdfService, private photoMaillingService: PhotoMaillingService,
              private logoMaillingService: LogoMaillingService, private coupureService: CoupureService) {

  }

  ngOnInit() {
    this.timing = new Date();
    this.noCacheVal = this.NoCache();
    const that = this;
    this.sub = this.route.params.subscribe(params => {
      that.id = +params['id'];
      that.maillingService.getMaillingItem(that.id)
        .then((retour) => {
          that.mailling = retour as Mailling;
          if (this.mailling.typeMail === 'CP') {
            that.mailling.content = that.mailling.content.replace(new RegExp('<br/>', 'g'), String.fromCharCode(10));
            that.resumer = that.mailling.resumer;
          } else {
            this.coupureService.getContactsClientCoupures(that.mailling.client.ancienId)
              .then((contactsenvoicoupures) => {
              that.personnes = contactsenvoicoupures;
              console.log(that.personnes);
                if (that.mailling.debutMailCoupure !== null) {
                  that.mailling.debutMailCoupure  = that.mailling.debutMailCoupure.replace(new RegExp('<br/>', 'g'),
                    String.fromCharCode(10));
                }
                if (that.mailling.finMailCoupure !== null) {
                  that.mailling.finMailCoupure = that.mailling.finMailCoupure.replace(new RegExp('<br/>', 'g'), String.fromCharCode(10));
                }
            });
          }
          that.selectedReponseVal = that.mailling.replyTo.id;
          that.selectedSendVal = that.mailling.envoyePar.id;
        });
    });
    this.authUserService.getUsersList(1)
      .then((retour) => {
        that.authUsers = retour['hydra:member'] as Array<AuthUser>;
        that.authUsers.forEach( (authUser) => {
          if(authUser.adresseEnvoi !== null){
            that.authUsersSend.push(authUser);
          }
        });
      });
    if (typeof (this.selectedTestVal) === 'undefined') {
      this.selectedTestVal = this.authShareService.authUser.id;
    }
  }

  getContent() {
    const that = this;
    if (this.mailling.typeMail !== 'CP') {
      this.maillingService.getMaillingContent(this.id)
        .then((data) => {
          that.dataContainer.nativeElement.innerHTML = data;
        });
    }
  }

  mailTest(){
    if (this.mailling.enCour === false) {
      this.maillingService.sendMailTest(this.mailling.id, this.selectedTestVal)
        .then((retour) => {
          this.dialogueShareService.addDialogue(retour);
        }).catch((retour) => {
        this.dialogueShareService.addDialogue('une erreur s\'est produite, merci de contacter l\'administrateur');
      });
    } else {
      this.dialogueShareService.addDialogue('un mail test ne peut être envoyé que si le mailling n\'est pas en cours');
    }
  }

  sendChangeToApi(){
    const that = this;
    const actualDate = new Date();
    const secondenow = actualDate.getSeconds() + actualDate.getMinutes() * 60 + actualDate.getHours() * 60 * 24;
    const secondeTiming = this.timing.getSeconds() + this.timing.getMinutes() * 60 + this.timing.getHours() * 60 * 24;
    if ((secondenow - secondeTiming) < 300) {

      if (this.mailling.typeMail === 'CP') {
        let maillingContent = '';
        for (let i = 0; i < this.mailling.content.length; i++) {
          if (this.mailling.content.charCodeAt(i) === 10) {
            maillingContent = maillingContent + '<br/>';
          } else {
            maillingContent = maillingContent + this.mailling.content.charAt(i);
          }
          that.signature = that.mailling.signature;
        }
        this.maillingService.updateMailling(this.mailling,
          this.selectedSendVal, this.selectedReponseVal, this.resumer, maillingContent, this.signature)
          .then((retour) => {
            that.ngOnInit();
          });
      } else {
        that.hyperlien = that.mailling.hyperlien;
        let debutMail = '';
        if ( this.mailling.debutMailCoupure !== null) {
          for (let i = 0; i < this.mailling.debutMailCoupure.length; i++) {
            if (this.mailling.debutMailCoupure.charCodeAt(i) === 10) {
              debutMail = debutMail + '<br/>';
            } else {
              debutMail = debutMail + this.mailling.debutMailCoupure.charAt(i);
            }
          }
        }
        let finMail = '';
        if ( this.mailling.finMailCoupure !== null) {
          for (let i = 0; i < this.mailling.finMailCoupure.length; i++) {
            if (this.mailling.finMailCoupure.charCodeAt(i) === 10) {
              finMail = finMail + '<br/>';
            } else {
              finMail = finMail + this.mailling.finMailCoupure.charAt(i);
            }
          }
        }
        this.maillingService.updateMaillingCoupure(this.mailling, this.selectedSendVal, this.selectedReponseVal,
          this.resumer, debutMail, finMail, this.hyperlien)
          .then((retour) => {
            that.ngOnInit();
          });
      }


      this.timing = new Date();
    } else {
      this.dialogueShareService.addDialogue('Modification non effectué </br> temps d\'inactivité trop long');
      that.ngOnInit();
    }
  }

  sendMailling(){
    const that = this;
    if (this.mailling.enCour === false){
      this.mailling.enCour = true;
      this.dialogueShareService.addDialogue('Une demande d\'envoi a été demandé');
      this.maillingService.sendMail(that.mailling.id)
        .then((retour) => {
          this.dialogueShareService.addDialogue(retour);
          that.ngOnInit();
        });
    } else {
      this.dialogueShareService.addDialogue('Le mailling est déjà en cours d\'envoi');
    }
  }

  sendMaillingDecaler(){
    if(this.dateMaillingDecaler === undefined){
      this.dialogueShareService.addDialogue('Pour envoyer en décalé, il faut sélectionner une date');
    } else if (this.dateMaillingDecaler < new Date()){
      this.dialogueShareService.addDialogue('Pour envoyer en décalé, il faut sélectionner une date dans le futur');
    } else{
      const that = this;
      this.maillingService.sendMailDecaler(that.mailling.id, that.dateMaillingDecaler)
        .then((retour) => {
          this.dialogueShareService.addDialogue(retour);
          that.ngOnInit();
        });
    }
  }
  myFilter = (d: Date): boolean => {
    if ( this.startMoment.getFullYear() === d.getFullYear()) {
      if ( this.startMoment.getMonth() === d.getMonth() ) {
        if ( this.startMoment.getDate() === d.getDate() ) {
          return true;
        }
      }
    }
    return (d >= this.startMoment);
  };

  deleteMailling(){
    const that = this;
    const variable = {
      'maillingService': this.maillingService, 'dialogueShareService': this.dialogueShareService, 'id': this.mailling.id,
      'router': this.router
    };
    this.dialogueShareService.addDialogue('êtes-vous sûr de vouloir supprimer le mailling', function () {
      variable['maillingService'].deleteMailling(variable['id'])
        .then((retour) => {
          variable['dialogueShareService'].addDialogue(retour['message']);
          variable['router'].navigate(['/escal/mailling']);
        })
        .catch((retour) => {
          variable['dialogueShareService'].addDialogue('erreur de supression');
        });
    });
  }
  ajouterPartieCp(){
    const partieCp = new PartieCp(0, '', '');
    this.mailling.partiesCp.push(partieCp);
  }

  isOkSend(){
    if (this.mailling.typeMail === 'CP') {
      if (this.mailling.titre === null || this.mailling.resumerCour === null ||
        this.mailling.content === null || this.mailling.objetCour === null ||
        this.mailling.pdf === null || this.mailling.client.logo === null) {
        return false;
      }
      if (this.mailling.titre.length > 5) {
        if (this.mailling.resumerCour.length > 10) {
          if (this.mailling.content.length > 10) {
            if (this.mailling.objetCour.length > 10) {
              return true;
            }
          }
        }
      }
      return false;
    }
    return true;
  }

  deletePdf(){
    const that = this;
    this.pdfService.deletPdf(this.mailling.pdf.id)
      .then((retour) => {
        that.ngOnInit();
      });
  }

  deleteLogo(){
    const that = this;
    this.logoMaillingService.deleteLogo(this.mailling.logo.id)
      .then((retour) => {
        that.ngOnInit();
      });
  }
  deletePhoto(){
    const that = this;
    this.photoMaillingService.deletPhoto(this.mailling.photo.id)
      .then((retour) => {
        that.ngOnInit();
      });
  }
  savePdf() {
    const that = this;
    this.pdfService.addPdf(this.mailling.id, this.pdfUpload)
      .then((retour) => {
        that.ngOnInit();
      });
  }

  changePdf(event) {
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if(file.type === 'application/pdf'){
          that.pdfUpload['filename'] = file.name;
          that.pdfUpload['filetype'] = file.type;
          // @ts-ignore
          that.pdfUpload['value'] = reader.result.split(',')[1];
        }
      };
    }
  }

  savePhoto() {
    const that = this;
    this.photoMaillingService.addPhoto(this.mailling.id, this.photoUpload)
      .then((retour) => {
        that.ngOnInit();
      });
  }

  changePhoto(event){
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        that.photoUpload['filename'] = file.name;
        that.photoUpload['filetype'] = file.type;
        // @ts-ignore
        that.photoUpload['value'] = reader.result.split(',')[1];
        that.photoUpload['extension'] = file.name.split('.')[1];
      };
    }
  }

  saveLogo() {
    const that = this;
    this.logoMaillingService.addLogo(this.mailling.id, this.logoUpload)
      .then((retour) => {
        that.ngOnInit();
      });
  }

  changeLogo(event) {
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        that.logoUpload['filename'] = file.name;
        that.logoUpload['filetype'] = file.type;
        // @ts-ignore
        that.logoUpload['value'] = reader.result.split(',')[1];
        that.logoUpload['extension'] = file.name.split('.')[1];
      };
    }
  }

  NoCache() {
    const a =  Math.floor(Math.random() * Math.floor(99999));
    const b = Math.floor(Math.random() * Math.floor(99999));
    return '&' + a + b;
  }

  logoClient() {
    return (this.mailling.client.logo === null);
  }

  changeColor(event){
    this.mailling.color = event;
  }

  refreshNbMail() {
    this.dialogueShareService.addDialogue('Actualisation du nombre de mails en cours');
    this.maillingService.refreshNbMail(this.mailling.id).then((retour) => {
      this.mailling.nbMail = retour;
      this.dialogueShareService.addDialogue('Actualisation du nombre de mails effectuée');
    });
  }
  getColorNbMail(){
    if (this.mailling.nbMail < 2500) {
      return 'yellow';
    }
    if (this.mailling.nbMail < 4500) {
      return 'green';
    }
    return 'red';
  }

}
