export class ContentTitre {
  id: string;
  nom: string;
  url: string;

  constructor(id: string, nom: string , url: string) {
    this.id = id;
    this.nom = nom;
    this.url = url;
  }
}
