import {Component, Input, OnInit} from '@angular/core';

import {ContentTitre} from '../../../../../../model/utilitaire/menu/contentTitre';

@Component({
  selector: 'app-structure-menu-gauche-content-element-content',
  templateUrl: './structure-menu-gauche-content-element-content.component.html',
  styleUrls: ['./structure-menu-gauche-content-element-content.component.scss']
})
export class StructureMenuGaucheContentElementContentComponent implements OnInit {

  @Input()
  contentTitre: ContentTitre;
  constructor() { }

  ngOnInit() {
  }

}
