import {Component, Input, OnInit} from '@angular/core';
import {Organisation} from '../../../../../model/organisation/Organisation';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {OrganisationService} from '../../../../../service/interne/organisation/OrganisationService';
import * as url from 'url';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';
import {Router} from '@angular/router';
import {Support} from '../../../../../model/support/Support';
import {ContactOrganisation} from '../../../../../model/contact/ContactOrganisation';
import {Emission} from '../../../../../model/support/Emission';
import {FichiersService} from '../../../../../service/interne/organisation/FichiersService';

@Component({
  selector: 'app-escal-organisation-fiche-informations',
  templateUrl: './escal-organisation-fiche-informations.component.html',
  styleUrls: ['./escal-organisation-fiche-informations.component.scss'],
  providers: [ OrganisationService, FichiersService ]
})
export class EscalOrganisationFicheInformationsComponent implements OnInit {

  @Input()
  organisation: Organisation;
  afficher: string;
  commentaires: string;
  nbCaracteres: number;
  nbCaracteresPremURL: number;
  nbCaracteresDeuxURL: number;
  auteurAncienId: number;
  urlTempUn: string;
  urlTempDeux: string;
  societesDetenues: Array<Organisation>;
  contactsRattaches: Array<ContactOrganisation>;
  fichiers: Array<any>;
  constructor(public formatIntranetShareService: FormatIntranetShareService, private organisationService: OrganisationService,
              private dialogueShareService: DialogueShareService, private router: Router, private fichiersService: FichiersService) { }

  ngOnInit() {
    this.auteurAncienId = 0;
    this.afficher = 'moins';
    this.nbCaracteres = 0;
    this.nbCaracteresPremURL = 0;
    this.nbCaracteresDeuxURL = 0;
    // adapter l'affichage du commentaire si trop long
    if (this.organisation.commentaires != null) {
      this.nbCaracteres = this.organisation.commentaires.length;
      this.commentaires = this.organisation.commentaires.substr(0, 150);
    }
    // récupérer l'ancien id de l'auteur pour le lien vers sa fiche intranet
    if (this.organisation.auteur != null) {
      this.organisationService.getAncienIdAuteur(this.organisation.id).then((retour) => {
        if (retour !== 0) {
          this.auteurAncienId = retour;
        }
      });
    }
    // adapter l'affichage du premier lien si trop long
    if (this.organisation.coordonnees != null) {
      if (this.organisation.coordonnees.premierURL != null) {
        this.nbCaracteresPremURL = this.organisation.coordonnees.premierURL.length;
        let i = 0;
        this.urlTempUn = '';
        while (i < this.nbCaracteresPremURL) {
          this.urlTempUn = this.urlTempUn + this.organisation.coordonnees.premierURL.substr(i, 70) + ' ';
          i = i + 70;
        }
      }
    }
    // adapter l'affichage du deuxieme lien si trop long
    if (this.organisation.coordonnees != null) {
      if (this.organisation.coordonnees.deuxiemeURL != null) {
        this.nbCaracteresDeuxURL = this.organisation.coordonnees.deuxiemeURL.length;
        let i = 0;
        this.urlTempDeux = '';
        while (i < this.nbCaracteresDeuxURL) {
          this.urlTempDeux = this.urlTempDeux + this.organisation.coordonnees.deuxiemeURL.substr(i, 70) + ' ';
          i = i + 70;
        }
      }
    }
    this.fichiers = [];
    // récupérer les potentiels fichiers attachés
    this.fichiersService.getFichiersOrganisation(this.organisation.id).then((documents) => {
      this.fichiers = documents['hydra:member'];
    });
  }

  changerAffichage() {
    if (this.afficher === 'plus') {
      this.commentaires = this.organisation.commentaires.substr(0, 150);
      this.afficher = 'moins';
    } else {
      this.commentaires = this.organisation.commentaires;
      this.afficher = 'plus';
    }
  }


  supprimerSociete() {
    this.dialogueShareService.addDialogue('Supprimer la société ?', () => {
      // supprimer les associations avant de supprimer la société
      // pour chaque support détenu on modifie l'id societe
      if (this.organisation.supports != null) {
        this.organisation.supports.forEach((support: Support) => {
          this.organisationService.supprimerAssoSupport(support.id).then((supp) => {
          });
        });
      }
      // pour chaque contact rattaché on supprime l'asso
      this.contactsRattaches = [];
      this.organisationService.getAllContactsOrganisation(this.organisation.id).then((conts) => {
        this.contactsRattaches = conts['hydra:member'] as Array<ContactOrganisation>;
        if (this.contactsRattaches != null) {
          this.contactsRattaches.forEach((contactAsso: ContactOrganisation) => {
            this.organisationService.supprimerAssoContactSoc(contactAsso.id).then((contAsso) => {
            });
          });
        }
      });
      // pour chaque émission rattachée on modifie l'id societe
      if (this.organisation.emissions != null) {
        this.organisation.emissions.forEach((emission: Emission) => {
          this.organisationService.supprimerAssoEmission(emission.id).then((assoEm) => {
          });
        });
      }
      // pour chaque société détenue on modifie l'id société
      this.societesDetenues = [];
      this.organisationService.getAllSocietesDetenuesOrganisation(this.organisation.id).then((societesDetenues) => {
        this.societesDetenues = societesDetenues['hydra:member'] as Array<Organisation>;
        if (this.societesDetenues != null) {
          this.societesDetenues.forEach((socDet) => {
            this.organisationService.supprimerAssoAppartenir(socDet.id).then((assoApp) => {
            });
          });
        }
      });
      // supprimer societe dans la base intranet
      this.organisationService.supprimerImportSocieteIntranet(this.organisation.ancienId)
        .then(() => {
          this.organisationService.supprimerOrganisation(this.organisation.id).then((organisation) => {
            this.router.navigate(['/escal/societes']);
            this.dialogueShareService.addDialogue('La société a bien été supprimé');
          });
        });
    });
  }

}
