import { Component, OnInit } from '@angular/core';
import {Contact} from '../../../../model/contact/Contact';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {ActivatedRoute} from '@angular/router';
import {ContactService} from '../../../../service/interne/contact/ContactService';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {SuiviActionShareService} from '../../../../service/partage/SuiviActionShareService';
import {AuthShareService} from '../../../../service/partage/AuthShareService';
import {Environement} from '../../../../environement/environement';
import {OpenWithPostShareService} from '../../../../service/partage/utilitaire/OpenWithPostShareService';

@Component({
  selector: 'app-escal-contact-fiche',
  templateUrl: './escal-contact-fiche.component.html',
  styleUrls: ['./escal-contact-fiche.component.scss']
})
export class EscalContactFicheComponent implements OnInit {

  private sub: any;
  contact: Contact;
  active = 'informations';
  verifRelance: boolean;
  rappelId: number;
  constructor(private dialogueShareService: DialogueShareService, private route: ActivatedRoute,
              private contactService: ContactService, public formatIntranetShareService: FormatIntranetShareService,
              private suiviActionShareService: SuiviActionShareService, private authShareService: AuthShareService,
              private openWithPostService: OpenWithPostShareService) { }

  ngOnInit() {
    const that = this;
    this.rappelId = 0;
    this.sub = this.route.params.subscribe(params => {
      this.contactService.getContactDetail(params.id)
        .then((retour) => {
          that.contact = retour as Contact;
          // création d'un rappel
          let user = this.authShareService.authUser.user.prenom + ' ' + this.authShareService.authUser.user.nom;
          user = user.replace(' ', '.');
          this.suiviActionShareService.verifierRelanceIntranet(user, that.contact.ancienId)
            .then(verif => {
              if (verif != 0) {
                this.verifRelance = true;
                this.rappelId = verif;
              } else {
                this.verifRelance = false;
              }
            });
        });
    });
  }

  refresh() {
    this.ngOnInit();
  }
  actived(type) {
    if (type === this.active) {
      return 'active';
    }
  }
  sansAccent(chaine) {
    var accent = [
      /[\300-\306]/g, /[\340-\346]/g, // A, a
      /[\310-\313]/g, /[\350-\353]/g, // E, e
      /[\314-\317]/g, /[\354-\357]/g, // I, i
      /[\322-\330]/g, /[\362-\370]/g, // O, o
      /[\331-\334]/g, /[\371-\374]/g, // U, u
      /[\321]/g, /[\361]/g, // N, n
      /[\307]/g, /[\347]/g, // C, c
    ];
    const noaccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c'];
    for (var i = 0; i < accent.length; i++) {
      chaine = chaine.replace(accent[i], noaccent[i]);
    }
    return chaine;

  }
  creerVcard() {
    let url: string;
    let nomComplet: string;
    this.contactService.creerVcard(this.contact.id).then(() => {
      nomComplet =  this.contact.prenom + '-' + this.contact.nom;
      nomComplet = this.sansAccent(nomComplet).replace(' ', '-');
      url = Environement.BASE_API_URL + '/vCard/' + nomComplet.toLowerCase() + '.vcf';
      this.openWithPostService.openWindows(url);
    });

  }
}
