import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView} from 'angular-calendar';
import {Subject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {addDays, addHours, endOfDay, endOfMonth, isSameDay, isSameMonth, startOfDay, subDays} from 'date-fns';
import {ModalCalendrierShareService} from '../../../service/partage/ModalCalendrierShareService';
import {EvenementService} from '../../../service/interne/calendrier/EvenementService';
import {DialogueShareService} from '../../../service/partage/utilitaire/DialogueShareService';
import {EvenementSalleService} from '../../../service/interne/calendrier/EvenementSalleService';
import {AuthShareService} from '../../../service/partage/AuthShareService';



@Component({
  selector: 'app-test-calendrier',
  templateUrl: './test-calendrier.component.html',
  styleUrls: ['./test-calendrier.component.scss'],
  providers: [EvenementService, EvenementSalleService ]
})
export class TestCalendrierComponent implements OnInit {

  @Input() beginView: string;

  @Input() weekend: boolean;

  @Input() beginHourTime: number;

  @Input() endHourTime: number;

  @Input() events: CalendarEvent[];

  @Input() eventType: string;

  @Output() refreshCalendar = new EventEmitter<any>();

  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  view: CalendarView;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };


  refresh: Subject<any> = new Subject();


  activeDayIsOpen = true;


  constructor(private modal: NgbModal, public modalCalendrierShareService: ModalCalendrierShareService,
              private evenementService: EvenementService, private dialogueShareService: DialogueShareService,
              private evenementSalleService: EvenementSalleService, private authShareService: AuthShareService) {
  }


  ngOnInit() {
    this.modalCalendrierShareService.evenementSalleService = this.evenementSalleService;
    this.modalCalendrierShareService.eventType = this.eventType;
    if (this.beginView === 'Month') {
      this.view = CalendarView.Month;
    } else if (this.beginView === 'Day') {
      this.view = CalendarView.Day;
    } else {
      this.view = CalendarView.Week;
    }
  }


  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  eventTimesChanged({
                      event,
                      newStart,
                      newEnd
                    }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();

  }

  handleEvent(action: string, event: CalendarEvent): void {
    const that = this;
    this.modalData = {event, action};
    if (action === 'Dropped or resized') {
      this.modalCalendrierShareService.evenementSalleService = this.evenementSalleService;
      this.modalCalendrierShareService.verifDispoModif(event)
        .then(() => {
          that.evenementService.updateEvent(event, true)
            .then(() => {
            });
        })
        .catch(() => {
          this.dialogueShareService.addDialogue('Désolé la salle de réunion n\'est pas disponible à ce moment');
          this.refreshCalendar.emit();
        });
    } else if (action === 'Clicked') {
      if (this.authShareService.isGrantedEvenementCalendrier(event.meta)) {
        this.modalCalendrierShareService.open = true;
        this.modalCalendrierShareService.typeEvent = 'modif';
        this.modalCalendrierShareService.setEvent(event);
        this.modalCalendrierShareService.message = '';
      }
    }
  }


  isWeekEnd() {
    if (this.weekend) {
      return [];
    } else {
      return [0, 6];
    }
  }

  hoursCliecked(event) {
    this.modalCalendrierShareService.dateDebut = event.date;
    this.modalCalendrierShareService.dateFin = new Date(this.modalCalendrierShareService.dateDebut.getTime());
    this.modalCalendrierShareService.dateFin.setHours(this.modalCalendrierShareService.dateFin.getHours() + 1);
    this.modalCalendrierShareService.open = true;
    this.modalCalendrierShareService.typeEvent = 'cree';
    this.modalCalendrierShareService.eventExterieurNom = '';
    this.modalCalendrierShareService.cbEvent = false;
    this.modalCalendrierShareService.cbClient = false;
    this.modalCalendrierShareService.cbSalle = true;
    this.modalCalendrierShareService.nom = '';
    this.modalCalendrierShareService.verifDispo(true);
    this.modalCalendrierShareService.fullDay = false;
  }

  refreshCalendarAction() {
    this.refreshCalendar.emit();
  }
}
