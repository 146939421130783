import { Component, OnInit } from '@angular/core';
import {EvenementService} from '../../../../../service/interne/client/pressroom/EvenementService';
import {PressroomShareService} from '../../../../../service/partage/PressroomShareService';
import {PressroomEvenement} from '../../../../../model/client/pressroom/PressroomEvenement';
import {Environement} from '../../../../../environement/environement';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';
@Component({
  selector: 'app-pressroom-client-configuration-evenement',
  templateUrl: './pressroom-client-configuration-evenement.component.html',
  styleUrls: ['./pressroom-client-configuration-evenement.component.scss'],
  providers: [EvenementService]
})
export class PressroomClientConfigurationEvenementComponent implements OnInit {
  editEvenement: PressroomEvenement;
  ajoutEvenement: PressroomEvenement;
  addOpen: boolean;
  photoUpload: Array<any> = [];
  pdfUpload: Array<any> = [];
  noCacheVal: any;
  nom: string;
  date: Date;
  photo: boolean;
  modif: boolean;
  constructor(private evenementService: EvenementService, public pressroomShareService: PressroomShareService, private dialogueShareService: DialogueShareService,) { }
  ngOnInit() {
    this.noCacheVal = this.NoCache();
    this.pressroomShareService.loadEvenements();
    this.editEvenement = null;
    this.ajoutEvenement = null;
    this.nom = '';
    this.closeAddOpen();
    this.photo = false;
    this.modif = false;
    this.pdfUpload = [];
    this.photoUpload = [];
  }
  closeAddOpen() {
    this.addOpen = false;
  }
  saveTempPhoto() {
    this.editEvenement.photo = 'test';
    this.photo = true;
    console.log(this.editEvenement.photo);
  }
  NoCache() {
    const a =  Math.floor(Math.random() * Math.floor(99999));
    const b = Math.floor(Math.random() * Math.floor(99999));
    return '?' + a + b;
  }
  saveTitreEvenement() {
    const that = this;
    this.evenementService.saveTitreEvenement(this.editEvenement)
      .then(() => {
        that.pressroomShareService.loadEvenements();
        that.editEvenement = null;
      });
  }
  changePhoto(event) {
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        that.photoUpload['filename'] = file.name;
        that.photoUpload['filetype'] = file.type;
        // @ts-ignore
        that.photoUpload['value'] = reader.result.split(',')[1];
        that.photoUpload['extension'] = file.type.split(',')[1];
      };
    }
  }
  savePhoto() {
    const that = this;
    if (this.editEvenement !== null) {
      this.evenementService.addPhotoToEvenement(this.pressroomShareService.pressroom.id, this.editEvenement.id, this.photoUpload)
        .then(() => {
          that.pressroomShareService.loadEvenements();
          that.editEvenement = null;
        });
    } else {
      this.evenementService.addPhotoToEvenement(this.pressroomShareService.pressroom.id, this.ajoutEvenement.id, this.photoUpload)
        .then(() => {
          that.pressroomShareService.loadEvenements();
          that.editEvenement = null;
        });
    }
  }
  deletePhoto() {
    const that = this;
    this.evenementService.delPhotoEvenement(this.pressroomShareService.pressroom.id , this.editEvenement.id)
      .then((retour) => {
        that.pressroomShareService.loadEvenements();
        that.editEvenement = null;
      });
  }
  changePdf(event) {
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        that.pdfUpload['filename'] = file.name;
        that.pdfUpload['filetype'] = file.type;
        // @ts-ignore
        that.pdfUpload['value'] = reader.result.split(',')[1];
      };
    }
  }
  savePdf() {
    const that = this;
    if (this.editEvenement !== null){
      this.evenementService.changePdf(this.pressroomShareService.pressroom.id, this.editEvenement.id, this.pdfUpload)
        .then((retour) => {
          that.pressroomShareService.loadEvenements();
          that.editEvenement = null;
        });
    } else {
      this.evenementService.changePdf(this.pressroomShareService.pressroom.id, this.ajoutEvenement.id, this.pdfUpload)
        .then((retour) => {
          that.pressroomShareService.loadEvenements();
          that.editEvenement = null;
        });
    }
  }
  saveEvenement() {
    const that = this;
    if (this.nom.length < 3){
      that.dialogueShareService.addDialogue('Le nom doit faire au moins 3 caractères');
    } else if (this.date === undefined) {
      that.dialogueShareService.addDialogue('Veuillez saisir la date de l\'évènement');
    } else if (!this.pdfUpload['filename']) {
      that.dialogueShareService.addDialogue('Veuillez ajouter un pdf');
    } else {
      this.evenementService.addEvenementPressroom(this.nom, this.date, this.photoUpload, this.pressroomShareService.pressroom.id)
        .then((retour) => {
          this.ajoutEvenement = retour as PressroomEvenement;
          that.savePdf();
          that.savePhoto();
          this.pdfUpload = [];
          this.photoUpload = [];
          delete this.date ;
          this.ngOnInit();
        });
    }
  }
  deleteEvenement(idEvenement) {
    const that = this;
    this.evenementService.delPhotoEvenement(this.pressroomShareService.pressroom.id , idEvenement)
      .then((retour) => {
      });
    this.evenementService.delPdfEvenement(this.pressroomShareService.pressroom.id , idEvenement)
      .then((retour) => {
      });
    this.evenementService.deleteEvenementPressroom(idEvenement)
      .then(() => {
        that.pressroomShareService.loadEvenements();
        that.editEvenement = null;
      });
  }
  getPhotoUrl(evenement: PressroomEvenement) {
    return Environement.BASE_API_URL + '/pressroomClient/' + this.pressroomShareService.pressroom.id + '/evenement/' +
      evenement.id  + '.' + evenement.photo + this.noCacheVal;
  }
  getPdfUrl(evenement: PressroomEvenement) {
    return Environement.BASE_API_URL + '/pressroomClient/' + this.pressroomShareService.pressroom.id + '/evenement/' +
      evenement.id  + '.pdf' + this.noCacheVal;
  }
}
