import {Component, Input, OnInit} from '@angular/core';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import {Dialogue} from '../../../../model/utilitaire/Dialogue';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';


@Component({
  selector: 'app-utilitaire-dialogue-message',
  templateUrl: './utilitaire-dialogue-message.component.html',
  styleUrls: ['./utilitaire-dialogue-message.component.scss'],
  animations: [
    trigger('ArriveAnimation',[
      state('debut', style({
        transform: 'translateX(500px)',
      })),
      state('fin', style({
        transform: 'translateX(0)',
      })),
      transition('debut => fin', animate('500ms ease-in',
        keyframes([
          style({transform: 'translateX(500px)',offset: 0}),
          style({transform: 'translateX(250px)',offset: 0.5}),
          style({transform: 'translateX(0)',offset: 1})
        ]))),
    ]),
  ]
})
export class UtilitaireDialogueMessageComponent implements OnInit {

  @Input()
  dialogue: Dialogue;
  state: string = 'debut';
  constructor(public dialogueShareService: DialogueShareService) { }

  ngOnInit() {
    var that = this;
    setTimeout(function () {
      that.fonctionAexectuter();
    }, 100);
  }

  fonctionAexectuter(){
    this.state = 'fin';
  }

  refuser(dialogue: Dialogue){
    this.dialogueShareService.removeDialogue(dialogue);
  }
  valider(dialogue: Dialogue){
    dialogue.fonctionCallBack();
    this.dialogueShareService.removeDialogue(dialogue);
  }

}
