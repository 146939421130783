import { Component, OnInit } from '@angular/core';
import {Actualite} from '../../../../model/actualite/Actualite';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {ActualiteService} from '../../../../service/interne/actualite/ActualiteService';
import {ChiffreCle} from '../../../../model/actualite/ChiffreCle';
import {ThemeDescriptif} from '../../../../model/actualite/ThemeDescriptif';
import {Innovation} from '../../../../model/actualite/Innovation';
import {Perspective} from '../../../../model/actualite/Perspective';
import {Client} from '../../../../model/client/Client';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {ChiffreCleService} from '../../../../service/interne/actualite/ChiffreCleService';
import {InnovationService} from '../../../../service/interne/actualite/InnovationService';
import {PerspectiveService} from '../../../../service/interne/actualite/PerspectiveService';
import {ThemeDescriptifService} from '../../../../service/interne/actualite/ThemeDescriptifService';

@Component({
  selector: 'app-escal-actualite-modifier',
  templateUrl: './escal-actualite-modifier.component.html',
  styleUrls: ['./escal-actualite-modifier.component.scss'],
  providers: [ ActualiteService, ClientService, ChiffreCleService, InnovationService, PerspectiveService, ThemeDescriptifService ]
})
export class EscalActualiteModifierComponent implements OnInit {

  id: number;
  private sub: any;
  actualite: Actualite;
  chiffres: Array<ChiffreCle>;
  themes: Array<ThemeDescriptif>;
  innovs: Array<Innovation>;
  persp: Array<Perspective>;
  clients: Array<Client>;
  clientId: number;
  chiffresCles: string;
  themeDescriptifs: string;
  innovations: string;
  perspectives: string;
  constructor(private route: ActivatedRoute, private router: Router, private dialogueShareService: DialogueShareService,
              private actualiteService: ActualiteService, private clientService: ClientService,
              private chiffreCleService: ChiffreCleService, private innovationService: InnovationService,
              private perspectiveService: PerspectiveService, private themeDescriptifService: ThemeDescriptifService) { }

  ngOnInit() {
    this.chiffresCles = '';
    this.themeDescriptifs = '';
    this.innovations = '';
    this.perspectives = '';
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
      if (this.id !== 0) {
        this.actualiteService.getActualiteDetails(this.id)
          .then((retour) => {
            this.actualite = retour as Actualite;
            this.clientId = this.actualite.client.id;
            this.chiffres = this.actualite.chiffreCles;
            this.themes = this.actualite.themeDescriptifs;
            this.innovs = this.actualite.innovations;
            this.persp = this.actualite.perspectives;
            let premierPassage = 'oui';
            this.chiffres.forEach(chiffre => {
              chiffre.libelle = chiffre.libelle.replace('\r', '');
              if (premierPassage == 'oui') {
                premierPassage = 'non';
                this.chiffresCles = this.chiffresCles + chiffre.libelle ;
              } else {
                this.chiffresCles = this.chiffresCles + "\r\n"  + chiffre.libelle;
              }
            });
            premierPassage = 'oui';
            this.themes.forEach(theme => {
              theme.libelle = theme.libelle.replace('\r', '');
              if (premierPassage == 'oui') {
                premierPassage = 'non';
                this.themeDescriptifs = this.themeDescriptifs + theme.libelle ;
              } else {
                this.themeDescriptifs = this.themeDescriptifs + "\r\n" + theme.libelle ;
              }
            });
            premierPassage = 'oui';
            this.innovs.forEach(innov => {
              innov.libelle = innov.libelle.replace('\r', '');
              if (premierPassage == 'oui') {
                premierPassage = 'non';
                this.innovations = this.innovations + innov.libelle ;
              } else {
                this.innovations = this.innovations + "\r\n" + innov.libelle ;
              }
            });
            premierPassage = 'oui';
            this.persp.forEach(persp => {
              persp.libelle = persp.libelle.replace('\r', '');
              if (premierPassage == 'oui') {
                premierPassage = 'non';
                this.perspectives = this.perspectives + persp.libelle ;
              } else {
                this.perspectives = this.perspectives + "\r\n" + persp.libelle ;
              }
            });
          });
        this.clientService.getClientList(1)
          .then((clients) => {
            this.clients = clients['hydra:member'] as Array<Client>;
          });
      }
    });
  }


  async modifierActualite() {
    // si informations obligatoires non renseignées
    if (this.actualite.nom != '') {
      if (this.actualite.pitch != '') {
        if (this.clientId != 0) {
          // pour chaque saut de ligne, séparer les chiffres clés en tableau
          let contentChiffres = '';
          for (let i = 0; i < this.chiffresCles.length; i++) {
            if (this.chiffresCles.charCodeAt(i) === 10) {
              contentChiffres = contentChiffres + '~#';
            } else {
              contentChiffres = contentChiffres + this.chiffresCles.charAt(i);
            }
          }
          const tabChiffres = contentChiffres.split('~#');
          // pour chaque saut de ligne, séparer les thèmes descriptifs en tableau
          let contentThemes = '';
          for (let i = 0; i < this.themeDescriptifs.length; i++) {
            if (this.themeDescriptifs.charCodeAt(i) === 10) {
              contentThemes = contentThemes + '~#';
            } else {
              contentThemes = contentThemes + this.themeDescriptifs.charAt(i);
            }
          }
          const tabThemes = contentThemes.split('~#');
          // pour chaque saut de ligne, séparer les innovations en tableau
          let contentInnovations = '';
          for (let i = 0; i < this.innovations.length; i++) {
            if (this.innovations.charCodeAt(i) === 10) {
              contentInnovations = contentInnovations + '~#';
            } else {
              contentInnovations = contentInnovations + this.innovations.charAt(i);
            }
          }
          const tabInnovations = contentInnovations.split('~#');
          // pour chaque saut de ligne, séparer les perspectives en tableau
          let contentPerspectives = '';
          for (let i = 0; i < this.perspectives.length; i++) {
            if (this.perspectives.charCodeAt(i) === 10) {
              contentPerspectives = contentPerspectives + '~#';
            } else {
              contentPerspectives = contentPerspectives + this.perspectives.charAt(i);
            }
          }
          const tabPerspectives = contentPerspectives.split('~#');
          // supprimer les associations
          await this.chiffres.forEach(chiffre => {
            this.chiffreCleService.supprimerChiffreCle(chiffre.id).then();
          });
          await this.themes.forEach(theme => {
            this.themeDescriptifService.supprimerThemeDescriptif(theme.id).then();
          });
          await this.innovs.forEach(innov => {
            this.innovationService.supprimerInnovation(innov.id).then();
          });
          await this.persp.forEach(persp => {
            this.perspectiveService.supprimerPerspective(persp.id).then();
          });
          // modifier actualité
          await this.actualiteService.modifierActualite(this.actualite, this.clientId)
            .then(async actualite => {
              // creer chiffres cles asso
              await tabChiffres.forEach(chiffre => {
                this.chiffreCleService.addChiffreCle(chiffre, actualite.id).then();
              });
              // creer themes descriptifs asso
              await tabThemes.forEach(theme => {
                this.themeDescriptifService.addThemeDescriptif(theme, actualite.id).then();
              });
              // creer innovations asso
              await tabInnovations.forEach(innovation => {
                this.innovationService.addInnovation(innovation, actualite.id).then();
              });
              // creer perspectives asso
              await tabPerspectives.forEach(perspective => {
                this.perspectiveService.addPerspective(perspective, actualite.id).then();
              });
              // enregistrer actualite dans la base d'intranet
              await this.actualiteService.importerActualiteIntranet(this.actualite.id, this.actualite.ancienId).then((ancienIdActu) => {
                // insérer l'id d'intranet dans ancienId d'escalnet
                this.actualiteService.addAncienIdActualite(this.actualite.ancienId, this.actualite.id).then((retour) => {
                  // rappeler la fonction, cette fois pour s'assurer que les promises soient terminés
                  this.actualiteService.importerActualiteIntranet(this.actualite.id, this.actualite.ancienId).then((fin) => {
                    this.router.navigate(['/escal/actualites']);
                    this.dialogueShareService.addDialogue('L\'actualité a bien été modifiée');
                  });
                });
              });
            });
        } else {
          this.dialogueShareService.addDialogue('Le client est obligatoire');
        }
      } else {
        this.dialogueShareService.addDialogue('Le pitch est obligatoire');
      }
    } else {
      this.dialogueShareService.addDialogue('Le nom est obligatoire');
    }
  }

}
