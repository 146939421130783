import {AuthUser} from '../user/authUser';
import {Civilite} from '../user/Civilite';
import {Fonction} from './Fonction';
import {Coordonnees} from '../information/Coordonnees';
import {Genre} from './Genre';
import {CategoriesGenerales} from '../information/CategoriesGenerales';
import {SousCategoriesGenerales} from '../information/SousCategoriesGenerales';
import {Langues} from '../information/Langues';
import {Pays} from '../information/Pays';
import {Organisation} from '../organisation/Organisation';
import {ContactOrganisation} from './ContactOrganisation';
import {CalendrierRedactionnel} from '../client/CalendrierRedactionnel';
import {Suivi} from '../suivi/Suivi';
import {ContactSupport} from './ContactSupport';
import {ReseauxSociaux} from './ReseauxSociaux';
import {ListeContactContact} from '../liste/ListeContactContact';

export class Contact {
  id: number;
  ancienId: number;
  nom: string;
  prenom: string;
  modeEnvoiPref: string;
  piecesJointes: boolean;
  contactPerso: boolean;
  commentaires: string;
  objet: string;
  dateConclusion: Date;
  auteur: AuthUser;
  creation: Date;
  modification: Date;
  civilite: Civilite;
  fonction: Fonction;
  coordonnees: Coordonnees;
  genre: Genre;
  contactSupports: Array<ContactSupport>;
  categoriesGenerales: Array<CategoriesGenerales>;
  sousCategoriesGenerales: Array<SousCategoriesGenerales>;
  langues: Array<Langues>;
  pays: Array<Pays>;
  dirigeants: Array<Organisation>;
  contactOrganisations: Array<ContactOrganisation>;
  calendrierRedactionnels: Array<CalendrierRedactionnel>;
  suivis: Array<Suivi>;
  reseauxSociaux: ReseauxSociaux;
  listeContactContacts: Array<ListeContactContact>;

  constructor(id: number, ancienId: number, nom: string, prenom: string, modeEnvoiPref: string, piecesJointes: boolean, contactPerso: boolean,
              commentaires: string, objet: string, dateConclusion: Date, auteur: AuthUser, creation: Date, modification: Date,
              civilite: Civilite, fonction: Fonction, coordonnees: Coordonnees, genre: Genre, contactSupports: Array<ContactSupport>,
              categoriesGenerales: Array<CategoriesGenerales>, sousCategoriesGenerales: Array<SousCategoriesGenerales>,
              langues: Array<Langues>, pays: Array<Pays>, dirigeants: Array<Organisation>,
              contactOrganisations: Array<ContactOrganisation>, calendrierRedactionnels: Array<CalendrierRedactionnel>,
              suivis: Array<Suivi>, reseauxSociaux: ReseauxSociaux, listeContactContacts: Array<ListeContactContact>) {
    this.id = id;
    this.ancienId = ancienId;
    this.nom = nom;
    this.prenom = prenom;
    this.modeEnvoiPref = modeEnvoiPref;
    this.piecesJointes = piecesJointes;
    this.contactPerso = contactPerso;
    this.commentaires = commentaires;
    this.objet = objet;
    this.dateConclusion = dateConclusion;
    this.auteur = auteur;
    this.creation = creation;
    this.modification = modification;
    this.civilite = civilite;
    this.fonction = fonction;
    this.coordonnees = coordonnees;
    this.genre = genre;
    this.contactSupports = contactSupports;
    this.categoriesGenerales = categoriesGenerales;
    this.sousCategoriesGenerales = sousCategoriesGenerales;
    this.langues = langues;
    this.pays = pays;
    this.dirigeants = dirigeants;
    this.contactOrganisations = contactOrganisations;
    this.calendrierRedactionnels = calendrierRedactionnels;
    this.suivis = suivis;
    this.reseauxSociaux = reseauxSociaux;
    this.listeContactContacts = listeContactContacts;
  }
}
