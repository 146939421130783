import { Component, OnInit } from '@angular/core';
import {ThematiqueService} from '../../../../service/interne/veille/ThematiqueService';
import {Veille} from '../../../../model/veille/Veille';
import {Thematiques} from '../../../../model/veille/Thematiques';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {VeilleService} from '../../../../service/interne/veille/VeilleService';
import {ElementVeilleService} from '../../../../service/interne/veille/ElementVeilleService';
import {VeilleThematiqueService} from '../../../../service/interne/veille/VeilleThematiqueService';
import {element} from "protractor";

@Component({
  selector: 'app-escal-veille-creation',
  templateUrl: './escal-veille-creation.component.html',
  styleUrls: ['./escal-veille-creation.component.scss'],
  providers: [ ThematiqueService, VeilleService, ElementVeilleService, VeilleThematiqueService ]
})

export class EscalVeilleCreationComponent implements OnInit {

  veille: Veille;
  thematiques: Array<Thematiques>;
  titre: string = '';
  liens: string = '';
  thematiqueId: string;

  constructor(private thematiqueService: ThematiqueService, private veilleService: VeilleService,
              private  elementVeilleService: ElementVeilleService, private dialogueShareService: DialogueShareService,
              private veilleThematiqueService: VeilleThematiqueService) { }

  ngOnInit() {
    this.initVeille();
    this.initThematique();
  }

  initVeille(){
    const that = this;
    this.liens = '';
    this.titre = '';
    this.veilleService.getVeillesJour()
      .then(( retour ) => {
        if(retour.length > 0 ){
          that.veille = retour[0] as Veille;
        } else {
          that.veilleService.creeVeilleJour()
            .then( ( retour1 ) => {
              that.veille = retour1 as Veille;
            });
        }
      });
  }

  initThematique(){
    const that = this;
    this.thematiqueService.getThematiquesAffiche()
      .then((retour) =>
      {
        that.thematiques = retour as Array<Thematiques>;
        // trier par ordre alphabétique avec ou sans accent
        that.thematiques.sort(function compare(a, b) {
          if (a.libelle.localeCompare(b.libelle) < b.libelle.localeCompare(b.libelle, 'en', { sensitivity: 'base' })) {
            return -1;
          }
          if (a.libelle.localeCompare(b.libelle) > b.libelle.localeCompare(b.libelle, 'en', { sensitivity: 'base' })) {
            return 1;
          }
          return 0;
        });
      });
  }

  addElementVeille(){
    const that = this;
    let verif = this.liens.substr(0, 4);
    if (verif !== 'http') {
      this.liens = 'https://' + this.liens;
    }
    if(this.titre.length < 3 || this.liens.length < 3 || !this.thematiqueId){
      this.dialogueShareService.addDialogue('Le titre et le lien doivent faire au moins 3 caractères et il faut une catégorie');
    } else {
      this.veilleThematiqueService.getVeilleThematique(this.veille.id, this.thematiqueId)
        .then( (retour) => {
          if(retour.length > 0 ) {
            this.elementVeilleService.addElementVeille(retour[0].id, that.titre, that.liens)
              .then( () =>{
                that.ngOnInit();
              });
          } else {
            that.veilleThematiqueService.addVeilleThematique(this.veille.id, this.thematiqueId)
              .then( (retour2) => {
                this.elementVeilleService.addElementVeille(retour2.id, that.titre, that.liens)
                  .then( () =>{
                    that.initVeille();
                  });
              });
          }
        });
    }
  }
  validerVeille() {
    const variable = {
      veilleService: this.veilleService, veille: this.veille, that: this
    };
    this.dialogueShareService.addDialogue('êtes vous sûre de vouloir valider la veille', () => {
      variable.veilleService.vaiderVeille(variable.veille.id)
        .then((retour) => {
          variable.that.initVeille();
        });
    });
  }

}
