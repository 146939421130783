import { Component, OnInit } from '@angular/core';
import {OrganisationService} from '../../../../service/interne/organisation/OrganisationService';
import {Organisation} from '../../../../model/organisation/Organisation';
import {ActivatedRoute} from '@angular/router';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';

@Component({
  selector: 'app-escal-organisation-fiche',
  templateUrl: './escal-organisation-fiche.component.html',
  styleUrls: ['./escal-organisation-fiche.component.scss'],
  providers: [ OrganisationService ]
})
export class EscalOrganisationFicheComponent implements OnInit {

  private sub: any;
  organisation: Organisation;
  active = 'informations';
  constructor(private organisationService: OrganisationService, private route: ActivatedRoute,
              public formatIntranetShareService: FormatIntranetShareService) { }

  ngOnInit() {
    const that = this;
    this.sub = this.route.params.subscribe(params => {
      this.organisationService.getOrganisationDetails(params.id)
        .then((organisation) => {
          that.organisation = organisation as Organisation;
        });
    });
  }

  refresh() {
    this.ngOnInit();
  }

  actived(type) {
    if (type === this.active) {
      return 'active';
    }
  }

}
