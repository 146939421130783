import {MotCleSite} from './MotCleSite';
import {ArticleSite} from './ArticleSite';
import {ImageArticleSite} from './ImageArticleSite';

export class ArticleSiteSection {
  id: number;
  title: string;
  couleurTitle: string;
  nom: string;
  content: string;
  contentDisposition: string;
  imagePosition: string;
  image: ImageArticleSite;


  constructor(id: number, title: string, couleurTitle: string, nom: string, content: string, contentDisposition: string,
              imagePosition: string, image: ImageArticleSite) {
    this.id = id;
    this.nom = nom;
    this.content = content;
    this.contentDisposition = contentDisposition;
    this.imagePosition = imagePosition;
    this.title = title;
    this.couleurTitle = couleurTitle;
    this.image = image;
  }
}
