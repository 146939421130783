import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';

@Injectable()
export class InnovationService {
  private innovationUrl = Environement.BASE_API_URL + '/api/innovations';
  constructor(private http: HttpClient) {
  }
  getInnovations() {
    const headers = this.initOptionHeader();
    return this.http.get(this.innovationUrl, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getInnovationById(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.innovationUrl + '/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  addInnovation(innovation, actuId) {
    const headers = this.initOptionHeader();
    return this.http.post(this.innovationUrl, {'libelle': innovation, 'actualite': Environement.BASE_API_URL + '/api/actualites/' + actuId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerInnovation(innovationId) {
    const headers = this.initOptionHeader();
    return this.http.delete(this.innovationUrl + '/' + innovationId,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
