import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../../environement/environement';


import {Anomalie} from '../../../../model/utilitaire/bugs/Anomalie';



@Injectable()
export class AnomalieService{
  private anomalieUrl = Environement.BASE_API_URL + '/api/anomalies';
  constructor(private http: HttpClient) { };


  postAnomalie(anomalie: Anomalie): Promise<any>{
    const headers = this.initOptionHeader();
    return this.http.post(this.anomalieUrl, {'type': anomalie.type, 'priorite': anomalie.priorite, 'cible': anomalie.cible,
      'description': anomalie.description, 'status': anomalie.status, 'categorie': '/api/categorie_anomalies/' + anomalie.categorie.id},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  };
  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
