import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact} from '../../../../../model/contact/Contact';
import {ContactSupport} from '../../../../../model/contact/ContactSupport';
import {ContactService} from '../../../../../service/interne/contact/ContactService';
import {ActivatedRoute} from '@angular/router';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {OrganisationService} from '../../../../../service/interne/organisation/OrganisationService';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';
import {FonctionService} from '../../../../../service/interne/contact/FonctionService';
import {Fonction} from '../../../../../model/contact/Fonction';
import {Support} from "../../../../../model/support/Support";

@Component({
  selector: 'app-escal-contact-fiche-supports',
  templateUrl: './escal-contact-fiche-supports.component.html',
  styleUrls: ['./escal-contact-fiche-supports.component.scss'],
  providers: [OrganisationService, FonctionService]
})
export class EscalContactFicheSupportsComponent implements OnInit {
  @Input()
  contact: Contact;
  @Output()
  refresh = new EventEmitter();
  addOpen: boolean;
  addOpenMAJ: boolean;
  maxPage: number;
  sub: any;
  ligneDirect: string;
  autreLigne: string;
  email: string;
  contactSupports: Array<ContactSupport> = [];
  contactSupportModifier: ContactSupport;
  curentPage = 1;
  idSupportSelected: number;
  champRechercheSupport: string;
  rechercheEncoursSupport: boolean;
  afficheSupp: boolean;
  supportRechercheIntranet: Array<any> = [];
  ancienIDFoncSelec: number;
  afficheFonction: boolean;
  champRechercheFonction = '';
  fonctionsIntranet: Array<any> = [];
  rechercheFonctionEncours: boolean;
  fonctionSupport: Fonction;
  fonctionSupportStocke: Fonction;
  participe: boolean;
  constructor(private contactService: ContactService, private route: ActivatedRoute,
              public formatIntranetShareService: FormatIntranetShareService,
              private organisationService: OrganisationService, private dialogueShareService: DialogueShareService,
              private fonctionService: FonctionService) { }

  ngOnInit() {
    this.addOpen = false;
    this.addOpenMAJ = false;
    this.ligneDirect = '';
    this.autreLigne = '';
    this.email = '';
    this.idSupportSelected = 0;
    this.afficheSupp = false;
    this.champRechercheSupport = '';
    this.ancienIDFoncSelec = 0;
    this.afficheFonction = false;
    this.rechercheFonctionEncours = false;
    this.sub = this.route.params.subscribe(async params => {
      await this.contactService.getContactSupportBycontactID(params.id).then(retour => {
        this.contactSupports = retour['hydra:member'];
        this.getMaxPage(retour['hydra:totalItems']);
      });
    });
  }

  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }
  changePage(page) {
    this.curentPage = page;
    this.ngOnInit();
  }
  chargerChamps(asso) {
    this.contactSupportModifier = asso;
    this.addOpen = true;
    this.addOpenMAJ = true;
    const that = this;
    if (this.contactSupports.length > 0) {
      this.contactSupports.forEach(contactSupport => {
        if (contactSupport.id == asso.id) {
          this.ligneDirect = contactSupport.premierTel;
          this.autreLigne = contactSupport.deuxiemeTel;
          this.email = contactSupport.email;
          this.participe = contactSupport.participe;
          if (contactSupport.fonction !== null) {
            this.fonctionsIntranet = [];
            this.fonctionService.getFonctionsEscalnetbyAncienID(contactSupport.fonction.ancienId).then(fonction => {
              this.fonctionSupportStocke = fonction['hydra:member'][0];
              that.ancienIDFoncSelec = this.fonctionSupportStocke.ancienId;
              that.fonctionsIntranet.push(this.fonctionSupportStocke);
            });
          }
        }
      });
    }
  }
  viderChamps() {
    this.addOpen = true;
    this.addOpenMAJ = false;
    this.ligneDirect = '';
    this.autreLigne = '';
    this.email = '';
    this.idSupportSelected = 0;
    this.participe = false;

  }
  rechercheSupport() {
    this.afficheSupp = true;
    if (this.champRechercheSupport.length > 2 && !this.rechercheEncoursSupport) {
      this.rechercheEncoursSupport = true;
      this.champRechercheSupport = this.champRechercheSupport.replace(' ', '.');
      this.organisationService.getSupportRecherche(this.champRechercheSupport).then((retour) => {
        this.supportRechercheIntranet = retour;
        this.supportRechercheIntranet.forEach(support => {
          if (support.nom != '' && support.nom != null) {
            support.nom = support.nom.replace('&amp;', '&');
            support.nom = support.nom.replace('&#039;', '’');
            support.nom = support.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheSupport = this.champRechercheSupport.replace('.', ' ');
      });
      this.rechercheEncoursSupport = false;
    }
  }
  async ajoutFonction(idFonction) {
    let fonctSelec;
    let fonctionTrouve;
    // récupérer la fonction seléctionée
    this.fonctionsIntranet.forEach(fonctionIntranet => {
      if (fonctionIntranet.ancienId == idFonction) {
        fonctSelec = fonctionIntranet as Fonction;
        return;
      }
    });
    // On vérifie si la fonction existe déjà sur la base escalnet
    await this.fonctionService.getFonctionsEscalnetbyAncienID(fonctSelec.ancienId).then(async retour => {
      fonctionTrouve = retour['hydra:member'];
      // si la fonction n'existe pas, alors on crée sur la base escalnet
      if (fonctionTrouve.length == 0) {
        await this.fonctionService.addFonction(fonctSelec).then();
      } else {
        this.fonctionSupport = fonctionTrouve[0];
      }
    });
    if (this.fonctionSupport == null) {
      await this.fonctionService.getFonctionsEscalnetbyAncienID(this.ancienIDFoncSelec).then(fonctionSelec => {
        this.fonctionSupport = fonctionSelec['hydra:member'][0];
      });
    }
  }
  rechercheFonction() {
    this.afficheFonction = true;
    if (this.champRechercheFonction.length > 2 && !this.rechercheFonctionEncours) {
      this.rechercheFonctionEncours = true;
      this.champRechercheFonction = this.champRechercheFonction.replace(' ', '.');
      this.fonctionService.getFonctionsIntranet(this.champRechercheFonction).then((retour) => {
        this.fonctionsIntranet = retour;
        this.fonctionsIntranet.forEach(fonctionTrouve => {
          if (fonctionTrouve.libelle != '' && fonctionTrouve.libelle != null) {
            fonctionTrouve.libelle = fonctionTrouve.libelle.replace('&amp;', '&');
            fonctionTrouve.libelle = fonctionTrouve.libelle.replace('&#039;', '’');
            fonctionTrouve.libelle = fonctionTrouve.libelle.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheFonction = this.champRechercheFonction.replace('.', ' ');
      });
    }
    this.rechercheFonctionEncours = false;
  }

  async addSupportAsso() {
    let participe;
    let fonctionSupportAenreg = 0;
    const supportIdAssocies = [];
    if (this.participe) {
      participe = 'N';
    } else {
      participe = 'O';
    }
    if (this.idSupportSelected > 0) {
      // l'ajout d'une fonction n'est pas obligatoire
      if (this.ancienIDFoncSelec > 0) {
        await this.ajoutFonction(this.ancienIDFoncSelec).then(() => {
          if (this.fonctionSupport != null || true) {
            fonctionSupportAenreg = this.fonctionSupport.id;
          }
        });
      }
      // Vérifier si le support existe déjà sur escalnet, sinon on l'importe
      await this.organisationService.importerSupport(this.idSupportSelected).then(() => {
        this.organisationService.getSupportByAncienId(this.idSupportSelected).then(support => {
          const suppId = support['hydra:member'][0].id;
          // on l'associe
          // récupérer les id des supports associés pour ne pas avoir de doublons
          if (this.contactSupports.length > 0) {
            this.contactSupports.forEach(assoExistant => {
              supportIdAssocies.push(assoExistant.support.id);
            });
          }
          if (this.contactSupports.length == 0 || supportIdAssocies.includes(suppId) == false) {
            this.contactService.addContactSupport(this.contact.id, suppId, this.ligneDirect, this.autreLigne,
              this.email, fonctionSupportAenreg, participe, null, null, null).then(() => {
              this.contactService.addContactAssoIntranet('support', this.contact.id, suppId).then(() => {
                this.dialogueShareService.addDialogue('Le support est bien associé');
                this.ngOnInit();
              });
            });
          } else if (supportIdAssocies.includes(suppId) == true) {
            this.dialogueShareService.addDialogue('Le support est déjà associé');
          }
        });
      });
    } else {
      this.dialogueShareService.addDialogue('Le support est obligatoire');
    }
  }

   updateDonneesSupportAsso() {
     const that = this;
     // Eviter de perdre les données déjà dans la base
     if (this.ligneDirect == '' || this.ligneDirect == null) {
       this.ligneDirect = this.contactSupportModifier.premierTel;
     }
     if (this.autreLigne == '' || this.autreLigne == null) {
       this.autreLigne = this.contactSupportModifier.deuxiemeTel;
     }
     if (this.email == '' || this.email == null) {
       this.email = this.contactSupportModifier.email;
     }
     if (this.fonctionSupport != null) {
       this.ajoutFonction(this.ancienIDFoncSelec).then(() => {
         // update sur escalnet
         that.contactService.updateContactSupport(this.contactSupportModifier, this.ligneDirect,
           this.autreLigne, this.email, this.participe, this.fonctionSupport.id).then(() => {
           // update sur intranet
           that.contactService.updateContactAssoIntranet('support', this.contactSupportModifier.id).then(() => {
             this.dialogueShareService.addDialogue('Mise à jour effectué');
             this.ngOnInit();
           });
         });
       });
     } else {
       // update sur escalnet
       that.contactService.updateContactSupport(this.contactSupportModifier, this.ligneDirect,
         this.autreLigne, this.email, this.participe, 0).then(() => {
         // update sur intranet
         that.contactService.updateContactAssoIntranet('support', this.contactSupportModifier.id).then(() => {
           this.dialogueShareService.addDialogue('Mise à jour effectué');
           this.ngOnInit();
         });
       });
     }
   }
  deleteAsso(asso) {
    const that = this;
    this.dialogueShareService.addDialogue('êtes-vous sûr de vouloir supprimer l\'association',
      function () {
        that.contactService.deleteContactAssoIntranet('support', asso.id).then(() => {
          that.contactService.deleteContactSupport(asso.id).then(() => {
            that.dialogueShareService.addDialogue('l\'association est bien supprimé');
            that.ngOnInit();
          });
        })
          .catch(() => {
            that.dialogueShareService.addDialogue('Erreur de suppression');
          });
      });

    this.ngOnInit();
  }
}
