import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';

@Injectable()
export class EmailRetourService {
  private emailRetourUrl = Environement.BASE_API_URL + '/api/email_retours';
  constructor(private http: HttpClient) { }


  addEmailRetour(email, raison, iduser, date: Date): Promise<any> {
    const headers = this.initOptionHeader();
    if (raison == 'absence du journaliste') {
      const month = date.getMonth() + 1;
      const DateFormat = date.getFullYear() + '-' + month + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':00' ;
      return this.http.post(this.emailRetourUrl , {'email': email, 'raison' : raison, 'author' : '/api/auth_users/' + iduser,
          'dateAbsence' : DateFormat},
        {headers: headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    } else {
      return this.http.post(this.emailRetourUrl , {'email': email, 'raison' : raison, 'author' : '/api/auth_users/' + iduser},
        {headers: headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }
  verifEmailRetour(email): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.emailRetourUrl + '?email=' + email, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addEmailDejaExistant(iduser): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/verification/emailRetour/' + iduser,
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  };
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
