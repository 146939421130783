import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';


@Injectable()
export class EvenementMetierService {
  private evenementMetierURL = Environement.BASE_API_URL + '/api/evenement_metiers';
  constructor(private http: HttpClient) { }

  getEvenementsMetier() {
    const headers = this.initOptionHeader();
    return this.http.get(this.evenementMetierURL, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addEvenementMetier(evenement): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post(this.evenementMetierURL, {
      'nom': evenement.nom, 'ancienId': evenement.ancienId
    }, {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getEvenementMetierByAncienId(ancienId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.evenementMetierURL + '?ancienId=' + ancienId , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }


  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
