import { Component, OnInit } from '@angular/core';
import {DialogueShareService} from '../../../service/partage/utilitaire/DialogueShareService';
import {PdfService} from '../../../service/interne/utilitaire/PdfService';
import {NgxSpinnerService} from 'ngx-spinner';
import {Environement} from '../../../environement/environement';

@Component({
  selector: 'app-mergepdf',
  templateUrl: './mergepdf.component.html',
  styleUrls: ['./mergepdf.component.scss'],
  providers: [ PdfService ]
})
export class MergepdfComponent implements OnInit {

  pdfUpload: Array<any> = [];
  showlink = false;
  rand: string;

  constructor(private dialogueShareService: DialogueShareService, private pdfService: PdfService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.pdfUpload[1] = [];
    this.pdfUpload[2] = [];
    const a =  Math.floor(Math.random() * Math.floor(99999));
    const b = Math.floor(Math.random() * Math.floor(99999));
    this.rand =  '?' + a + '=' + b;
  }

  changePdf(number, event) {
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if(file.type === 'application/pdf'){
          that.pdfUpload[number]['filename'] = file.name;
          that.pdfUpload[number]['filetype'] = file.type;
          that.pdfUpload[number]['value'] = (reader.result as string).split(',')[1];
        }
      };
    }
  }

  savePdf() {
    const that = this;
    if (typeof (this.pdfUpload[1]['filename']) !== 'undefined' &&  typeof (this.pdfUpload[2]['filename']) !== 'undefined'){
      this.spinner.show();
      this.pdfService.mergePdf(this.pdfUpload)
        .then((retour) => {
          this.spinner.hide();
          that.showlink = true;
        });
    } else {
      this.dialogueShareService.addDialogue('merci d\'ajouter 2 PDF');
    }

  }

  linkTofile(){
    return Environement.BASE_API_URL + '/tmp/3.pdf' + this.rand;
  }

}
