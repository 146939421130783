export class SuivitActionSujet {
  sujet: string;
  mutualise: boolean;


  constructor(sujet: string, mutualise: boolean) {
    this.sujet = sujet;
    this.mutualise = mutualise;
  }
}
