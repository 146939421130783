import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Actualite} from '../../../../../model/actualite/Actualite';
import {ActualiteService} from '../../../../../service/interne/actualite/ActualiteService';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';
import {ChiffreCle} from '../../../../../model/actualite/ChiffreCle';
import {ChiffreCleService} from '../../../../../service/interne/actualite/ChiffreCleService';
import {InnovationService} from '../../../../../service/interne/actualite/InnovationService';
import {PerspectiveService} from '../../../../../service/interne/actualite/PerspectiveService';
import {ThemeDescriptifService} from '../../../../../service/interne/actualite/ThemeDescriptifService';
import {ThemeDescriptif} from '../../../../../model/actualite/ThemeDescriptif';
import {Innovation} from '../../../../../model/actualite/Innovation';
import {Perspective} from '../../../../../model/actualite/Perspective';
import {Router} from '@angular/router';

@Component({
  selector: 'app-pagination-content-actualite',
  templateUrl: './pagination-content-actualite.component.html',
  styleUrls: ['./pagination-content-actualite.component.scss'],
  providers: [ ActualiteService, ChiffreCleService, InnovationService, PerspectiveService, ThemeDescriptifService ]
})
export class PaginationContentActualiteComponent implements OnInit {

  ouvert = 0;
  actualite: Actualite;
  chiffresCles: Array<ChiffreCle>;
  themesDescriptifs: Array<ThemeDescriptif>;
  innovations: Array<Innovation>;
  perspectives: Array<Perspective>;
  @Input()
  actualites: Array<Actualite>;
  @Output() newItemEvent = new EventEmitter<any>();

  constructor(private actualiteService: ActualiteService, private dialogueShareService: DialogueShareService,
              private chiffreCleService: ChiffreCleService, private innovationService: InnovationService,
              private perspectiveService: PerspectiveService, private themeDescriptifService: ThemeDescriptifService,
              private router: Router) { }

  ngOnInit() {
    this.actualite = null;
    this.chiffresCles = [];
    this.themesDescriptifs = [];
    this.innovations = [];
    this.perspectives = [];
  }

  supprimerActualite(actualiteId) {
    this.dialogueShareService.addDialogue('Supprimer l\'actualité ?', () => {
      // supprimer les associations avant de supprimer l'actualité
      this.chiffresCles = [];
      this.themesDescriptifs = [];
      this.innovations = [];
      this.perspectives = [];
      this.actualiteService.getActualiteDetails(actualiteId).then(async (actualite) => {
        this.actualite = actualite as Actualite;
        // pour chaque chiffre clé on supprime l'asso
        this.chiffresCles = this.actualite.chiffreCles;
        if (this.chiffresCles != null) {
          await this.chiffresCles.forEach((chiffreCle: ChiffreCle) => {
            this.chiffreCleService.supprimerChiffreCle(chiffreCle.id).then(() => {
            });
          });
        }
        // pour chaque theme on supprime l'asso
        this.themesDescriptifs = this.actualite.themeDescriptifs;
        if (this.themesDescriptifs != null) {
          await this.themesDescriptifs.forEach((themeDescriptif: ThemeDescriptif) => {
            this.themeDescriptifService.supprimerThemeDescriptif(themeDescriptif.id).then(() => {
            });
          });
        }
        // pour chaque innovation on supprime l'asso
        this.innovations = this.actualite.innovations;
        if (this.innovations != null) {
          await this.innovations.forEach((innovation: Innovation) => {
            this.innovationService.supprimerInnovation(innovation.id).then(() => {
            });
          });
        }
        // pour chaque perspective on supprime l'asso
        this.perspectives = this.actualite.perspectives;
        if (this.perspectives != null) {
          await this.perspectives.forEach((perspective: Perspective) => {
            this.perspectiveService.supprimerPerspective(perspective.id).then(() => {
            });
          });
        }
        // supprimer actualité dans la base intranet
        this.actualiteService.supprimerImportActualiteIntranet(this.actualite.ancienId)
          .then(() => {
            // supprimer actualité
            this.actualiteService.supprimerActualite(actualiteId).then((fin) => {
              this.actualiteService.getActualitesParDefaut(1)
                .then((data) => {
                  this.actualites = data['hydra:member'] as Array<Actualite>;
                  this.newItemEvent.emit(data['hydra:totalItems']);
                });
              this.dialogueShareService.addDialogue('L\'actualité a bien été supprimée');
            });
          });
      });
    });
  }


  elemOuvert(id) {
    if (this.ouvert === id) {
      this.ouvert = 0;
    } else {
      this.ouvert = id;
    }
  }

  elementIsOuvert(id) {
    if (this.ouvert === id) {
      return true;
    }
  }

}
