import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../../environement/environement';
import {PressroomEvenement} from '../../../../model/client/pressroom/PressroomEvenement';
import {ImagePressroom} from '../../../../model/client/pressroom/ImagePressroom';
import {getDate} from 'date-fns';
@Injectable()
export class EvenementService{
  private pressroomEvenement = Environement.BASE_API_URL + '/api/pressroom_evenements/';
  constructor(private http: HttpClient) { }
  getAllEvenementsPressroom(idPressroom){
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/evenement/pressroom/' + idPressroom,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  saveTitreEvenement(evenement: PressroomEvenement){
    const headers = this.initOptionHeader();
    return this.http.put( this.pressroomEvenement + evenement.id , { 'nom' : evenement.nom} , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addPhotoToEvenement(idPressroom: number, idEvenement: number, photo: Array<any>) {
    const headers = this.initOptionHeader();
    return this.http.post( Environement.BASE_API_URL + '/update/pressroom/evenement/photo/' + idPressroom + '/' + idEvenement ,
      { 'data': photo['value'], 'nom': photo['filename']}, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  delPhotoEvenement(idpressroom, idEvenement){
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/update/pressroom/evenement/photo/delete/' + idpressroom + '/' + idEvenement , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  delPdfEvenement(idpressroom, idEvenement){
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/update/pressroom/evenement/pdf/delete/' + idpressroom + '/' + idEvenement , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  changePdf(idPressroom: number, idEvenement: number, pdf: Array<any>) {
    const headers = this.initOptionHeader();
    return this.http.post( Environement.BASE_API_URL + '/update/pressroom/evenement/pdf/' + idPressroom + '/' + idEvenement ,
      { 'data': pdf['value'], 'nom': pdf['filename']}, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addEvenementPressroom(nom: string, date: Date, photoUpload, idPressroom) {
    const headers = this.initOptionHeader();
    const now = new Date();
    return this.http.post(Environement.BASE_API_URL + '/api/pressroom_evenements' , {'nom': nom,
      'date': date, 'photo': '', 'pdf': '', 'pressroom': 'api/pressrooms/' + idPressroom}, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  deleteEvenementPressroom(id) {
    const headers = this.initOptionHeader();
    return this.http.delete(this.pressroomEvenement + id,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  };
  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
