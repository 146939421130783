import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';

import {ArticleSite} from '../../../model/site/ArticleSite';
import {Environement} from '../../../environement/environement';
import {removeAccents} from '../../partage/prototype/sansAccents';
import {MotCleSite} from '../../../model/site/MotCleSite';


@Injectable()
export class MotCleSiteService{
    private motCleUrl = Environement.BASE_API_URL + '/api/mot_cle_sites';
    constructor(private http: HttpClient) { }

  addMotCle(motCle: MotCleSite) {
    const headers = this.initOptionHeader();
    return this.http.post(this.motCleUrl , {'motCle': motCle.motCle}, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getMotCles(page): Promise<any>{
    const headers = this.initOptionHeader();
    return this.http.get(this.motCleUrl + '?_page=' + page , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getAllMotCles(): Promise<any>{
    const headers = this.initOptionHeader();
    return this.http.get(this.motCleUrl , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getMotCleRecherche(recherche: Array<any>, page){
    const content = recherche[0];
    let type = recherche[1];
    const headers = this.initOptionHeader();
    type = removeAccents(type.toLowerCase());
    return this.http.get(this.motCleUrl + '?' + type + '=' + content + '&_page=' + page,
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  deleteMotCle(id){
    const headers = this.initOptionHeader();
    return this.http.delete(this.motCleUrl + '/' + id ,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getMotCle(id): Promise<any>{
    const headers = this.initOptionHeader();
    return this.http.get(this.motCleUrl + '/' + id , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
    private errorHandler(err: any): Promise<any> {
        return Promise.reject(err.message || err );
    };
    private initOptionHeader(){
        const token: any = localStorage.getItem('auth-tokens');
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.set('X-Auth-Token', token);
        return headers;
    }
}
