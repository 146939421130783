import {CategorieAnomalie} from './CategorieAnomalie';

export class Anomalie {

 id: number;
 type: string;
 priorite: string;
 cible: string;
 description: string;
 status: string;
 categorie: CategorieAnomalie;
 date: Date;

  constructor(id: number, type: string, priorite: string, cible: string, description: string, status: string,
              categorie: CategorieAnomalie, date: Date) {
    this.id = id;
    this.type = type;
    this.priorite = priorite;
    this.cible = cible;
    this.description = description;
    this.status = status;
    this.categorie = categorie;
    this.date = date;
  }
}
