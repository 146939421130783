import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact} from '../../../../../model/contact/Contact';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {ContactService} from '../../../../../service/interne/contact/ContactService';
import {Support} from "../../../../../model/support/Support";
import {ContactOrganisation} from "../../../../../model/contact/ContactOrganisation";
import {Emission} from "../../../../../model/support/Emission";
import {Organisation} from "../../../../../model/organisation/Organisation";
import {DialogueShareService} from "../../../../../service/partage/utilitaire/DialogueShareService";
import {Router} from "@angular/router";
import {ListeContactContact} from "../../../../../model/liste/ListeContactContact";
import {AuthShareService} from "../../../../../service/partage/AuthShareService";
import {AuthUser} from "../../../../../model/user/authUser";

@Component({
  selector: 'app-escal-contact-fiche-administratif',
  templateUrl: './escal-contact-fiche-administratif.component.html',
  styleUrls: ['./escal-contact-fiche-administratif.component.scss'],
  providers: [ContactService]
})
export class EscalContactFicheAdministratifComponent implements OnInit {

  @Input()
  contact: Contact;
  afficher: string;
  commentaires: string;
  nbCaracteres: number;
  nbCaracteresURL: number;
  auteurAncienId: number;
  urlTempUn: string;
  urlTempDeux: string;
  urlLinkedin: string;
  urlTwitter: string;
  urlFacebook: string;
  authUser: AuthUser;
  @Output()
  refresh = new EventEmitter();

  constructor(public formatIntranetShareService: FormatIntranetShareService, private contactService: ContactService,
              private dialogueShareService: DialogueShareService, public authShareService: AuthShareService) {
  }

  ngOnInit() {
    const that = this;
    this.auteurAncienId = 0;
    this.afficher = 'moins';
    this.nbCaracteres = 0;
    this.nbCaracteresURL = 0;
    this.authUser = this.authShareService.authUser;
    // adapter l'affichage du commentaire si trop long
    if (this.contact.commentaires != null) {
      this.nbCaracteres = this.contact.commentaires.length;
      this.commentaires = this.contact.commentaires.substr(0, 150);
    }
    // récupérer l'ancien id de l'auteur pour le lien vers sa fiche intranet
    if (this.contact.auteur != null) {
      this.contactService.getAncienIdAuteur(this.contact.id).then(retour => {
        if (retour !== 0) {
          that.auteurAncienId = retour;
        }
      });
    }
    if (this.contact.coordonnees != null) {
      // adapter l'affichage du premier lien si trop long
      if (this.contact.coordonnees.premierURL != null) {
        this.nbCaracteresURL = this.contact.coordonnees.premierURL.length;
        let i = 0;
        this.urlTempUn = '';
        while (i < this.nbCaracteresURL) {
          this.urlTempUn = this.urlTempUn + this.contact.coordonnees.premierURL.substr(i, 70) + ' ';
          i = i + 70;
        }
      }
      // adapter l'affichage du deuxieme lien si trop long
      if (this.contact.coordonnees.deuxiemeURL != null) {
        this.nbCaracteresURL = this.contact.coordonnees.deuxiemeURL.length;
        let i = 0;
        this.urlTempDeux = '';
        while (i < this.nbCaracteresURL) {
          this.urlTempDeux = this.urlTempDeux + this.contact.coordonnees.deuxiemeURL.substr(i, 70) + ' ';
          i = i + 70;
        }
      }
    }
    if (this.contact.reseauxSociaux != null) {
      // adapter l'affichage de l'url linkedin si trop long
      if (this.contact.reseauxSociaux.linkedinURL != null) {
        this.nbCaracteresURL = this.contact.reseauxSociaux.linkedinURL.length;
        let i = 0;
        this.urlLinkedin = '';
        while (i < this.nbCaracteresURL) {
          this.urlLinkedin = this.urlLinkedin + this.contact.reseauxSociaux.linkedinURL.substr(i, 70) + ' ';
          i = i + 70;
        }
      }
      // adapter l'affichage de l'url twitter si trop long
      if (this.contact.reseauxSociaux.twitterURL != null) {
        this.nbCaracteresURL = this.contact.reseauxSociaux.twitterURL.length;
        let i = 0;
        this.urlTwitter = '';
        while (i < this.nbCaracteresURL) {
          this.urlTwitter = this.urlTwitter + this.contact.reseauxSociaux.twitterURL.substr(i, 70) + ' ';
          i = i + 70;
        }
      }
      // adapter l'affichage de l'url facebook si trop long
      if (this.contact.reseauxSociaux.facebookURL != null) {
        this.nbCaracteresURL = this.contact.reseauxSociaux.facebookURL.length;
        let i = 0;
        this.urlFacebook = '';
        while (i < this.nbCaracteresURL) {
          this.urlFacebook = this.urlFacebook + this.contact.reseauxSociaux.facebookURL.substr(i, 70) + ' ';
          i = i + 70;
        }
      }
    }
  }

  changerAffichage() {
    if (this.afficher === 'plus') {
      this.commentaires = this.contact.commentaires.substr(0, 150);
      this.afficher = 'moins';
    } else {
      this.commentaires = this.contact.commentaires;
      this.afficher = 'plus';
    }
  }
  supprimerContact() {
    this.dialogueShareService.addDialogue('Envoyer une demande à l\'IT ?', () => {
      const nomDemandeur = this.authUser.user.prenom + ' ' + this.authUser.user.nom;
      this.contactService.demandeSupprimerContact(this.contact.id, nomDemandeur).then(() => {
        this.dialogueShareService.addDialogue('Demande envoyée');
      });
    });
  }
}
