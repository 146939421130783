import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Organisation} from '../../../../../model/organisation/Organisation';
import {Support} from '../../../../../model/support/Support';
import {OrganisationService} from '../../../../../service/interne/organisation/OrganisationService';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';

@Component({
  selector: 'app-escal-organisation-fiche-supports',
  templateUrl: './escal-organisation-fiche-supports.component.html',
  styleUrls: ['./escal-organisation-fiche-supports.component.scss']
})
export class EscalOrganisationFicheSupportsComponent implements OnInit {

  supportsDetenus: Array<Support>;
  affiche: boolean;
  afficheSupp: boolean;
  modifier: boolean;
  supportId: number;
  supportsRecherche: Array<any> = [];
  champRechercheSupport: string;
  rechercheEncoursSupport = false;
  @Input()
  organisation: Organisation;
  @Output()
  refresh = new EventEmitter();
  constructor(private organisationService: OrganisationService, public formatIntranetShareService: FormatIntranetShareService,
              private dialogueShareService: DialogueShareService) { }

  ngOnInit() {
    this.affiche = false;
    this.afficheSupp = false;
    this.modifier = false;
    this.supportId = 0;
    this.supportsRecherche = [];
    this.champRechercheSupport = '';
    this.organisationService.getAllSupportsDetenusOrganisation(this.organisation.id).then((supports) => {
      this.supportsDetenus = supports['hydra:member'] as Array<Support>;
    });
  }

  afficheAjout() {
    if (this.affiche === true) {
      this.affiche = false;
      this.modifier = false;
    } else {
      this.affiche = true;
      this.modifier = true;
    }
  }

  rechercheSupport() {
    this.afficheSupp = true;
    if (this.champRechercheSupport.length > 2 && !this.rechercheEncoursSupport) {
      this.rechercheEncoursSupport = true;
      this.champRechercheSupport = this.champRechercheSupport.replace(' ', '.');
      this.organisationService.getSupportRecherche(this.champRechercheSupport).then((retour) => {
        this.supportsRecherche = retour;
        this.supportsRecherche.forEach(support => {
          if (support.nom != '' && support.nom != null) {
            support.nom = support.nom.replace('&amp;', '&');
            support.nom = support.nom.replace('&#039;', '’');
            support.nom = support.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheSupport = this.champRechercheSupport.replace('.', ' ');
      });
      this.rechercheEncoursSupport = false;
    }
  }

  ajouterSupport() {
    // si support renseigné
    if (this.supportId != 0) {
      // on importe le support
      this.organisationService.importerSupport(this.supportId).then(() => {
        // on récupère le support renseigné
        this.organisationService.getSupportByAncienId(this.supportId).then((support) => {
          const suppId = support['hydra:member'][0].id;
          // on l'associe
          this.organisationService.addSupport(suppId, this.organisation.id).then(() => {
            // on modifie aussi la base intranet avec les anciens id
            this.organisationService.addSupportIntranet(this.supportId, this.organisation.ancienId).then(() => {
              this.ngOnInit();
              this.dialogueShareService.addDialogue('Le support a bien été associé');
            });
          });
        });
      });
    }
  }

  supprimerSupport(suppId, suppcAncienId) {
    // on supp l'asso
    this.organisationService.suppSupportAsso(suppId).then(() => {
      // on modifie aussi la base intranet avec les anciens id
      this.organisationService.suppSupportIntranet(suppcAncienId, this.organisation.ancienId).then(() => {
        this.ngOnInit();
        this.dialogueShareService.addDialogue('Le support a bien été désassociée');
      });
    });
  }

}
