import {CalendarEvent} from 'angular-calendar';

import {Salle} from '../lieux/Salle';
import {Evenement} from './Evenement';
import {AuthShareService} from '../../service/partage/AuthShareService';

export class EvenementModelService {

  authShareService: AuthShareService;

  constructor() {

  }

  addEvent(eventsFind: Array<Evenement>, events: Array<CalendarEvent> = [], type = 'salle') {

    let colorAffiche: any;
    let fullDay: boolean;
    let auteurReservation;
    eventsFind.forEach((eventFind) => {
      if ( eventFind.salle !== null && type === 'salle') {
        colorAffiche  = {
            primary: eventFind.salle.couleurCalendrierBord,
            secondary: eventFind.salle.couleurCalendrierFond,
        };
      } else if ( eventFind.client !== null && type === 'client') {
          colorAffiche = this.authShareService.getColorClient(eventFind.client);
      } else if (type === 'user') {
          colorAffiche  = {
            primary: '#E79B3C',
            secondary: '#E79B3C',
          };
        } else {
        colorAffiche = {
          primary: '#f6bf26',
          secondary: '#f6bf26',
        };
      }
      if (eventFind.fullDay ) {
        fullDay = true;
      } else {
        fullDay = false;
      }
      // récuperer le prenom de l'auteur de la réservation de salle
      if (eventFind.auteur  === undefined) {
        auteurReservation = '';
      } else if (eventFind.auteur.user  === null) {
        auteurReservation = '';
      } else {
        auteurReservation = eventFind.auteur.user.prenom + ' ' + eventFind.auteur.user.nom.substr(0, 1) + '.';
      }
      const event = {
        start: new Date(eventFind.dateDebut),
        end: new Date(eventFind.dateFin),
        title: eventFind.nom + ' (' + auteurReservation + ')',
        color: colorAffiche,
        resizable: {
          beforeStart: this.authShareService.isGrantedEvenementCalendrier(eventFind),
          afterEnd: this.authShareService.isGrantedEvenementCalendrier(eventFind)
        },
        draggable: this.authShareService.isGrantedEvenementCalendrier(eventFind),
        meta: eventFind,
        cssClass: 'bonjour',
        allDay: fullDay
      };
      events.push(event);
    });
    return events;
  }

  getEventFerier(joursFerier: Array<Evenement>, events: Array<CalendarEvent> = []) {
    joursFerier.forEach((jourFerier) => {
      const event = {
        start: new Date(jourFerier.dateDebut),
        end: new Date(jourFerier.dateFin),
        title: jourFerier.nom,
        color: {
          primary: '#616161',
          secondary: '#616161',
        },
        resizable: {
          beforeStart: false,
          afterEnd: false
        },
        draggable: false,
        meta: jourFerier,
        cssClass: 'bonjour',
        allDay: true
      };
      events.push(event);
    });
    return events;
  }
  getEventConge(conges: Array<Evenement>, events: Array<CalendarEvent> = []){
    conges.forEach((conge) => {
      const event = {
        start: new Date(conge.dateDebut),
        end: new Date(conge.dateFin),
        title: conge.nom,
        color: {
          primary: '#8e24aa',
          secondary: '#8e24aa',
        },
        resizable: {
          beforeStart: false,
          afterEnd: false
        },
        draggable: false,
        meta: conge,
        cssClass: 'bonjour',
        allDay: true
      };
      events.push(event);
    });
    return events;
  }
}
