import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {Environement} from '../../../../../environement/environement';

import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';
import {MaillingService} from '../../../../../service/interne/mailling/maillingService';
import {CoupureService} from '../../../../../service/interne/coupure/CoupureService';

import {Coupure} from '../../../../../model/coupure/Coupure';
import {Client} from '../../../../../model/client/Client';


@Component({
  selector: 'app-escal-client-fiche-coupure',
  templateUrl: './escal-client-fiche-coupure.component.html',
  styleUrls: ['./escal-client-fiche-coupure.component.scss'],
  providers: [ CoupureService, MaillingService ]
})
export class EscalClientFicheCoupureComponent implements OnInit {

  @Input()
  client: Client;
  coupures: Array<Coupure>;
  valide: boolean;
  constructor(private router: Router, private coupureService: CoupureService, private maillingService: MaillingService,
              private dialogueShareService: DialogueShareService) { }

  ngOnInit() {
    this.valide = true;
    this.coupureService.getCoupureNotSend(this.client.id)
      .then((retour) => {
        this.coupures = retour['hydra:member'] as Array<Coupure>;
        this.coupures.forEach((coupure) => {
          if (coupure.piecesJointe === null) {
            this.valide = false;
          }
        });
      });
  }

  loadCoupure() {
    const that = this;
    this.coupureService.loadCoupure(this.client.id).
    then((retour) => {
      that.dialogueShareService.addDialogue('récupération des coupures effectuée',null);
      that.ngOnInit();
    });
  }
  getBadge(typeRetombe) {
    if(typeRetombe === 'Web'){
      return 'web.png';
    } else if (typeRetombe === 'Agence'){
      return 'agence.png';
    } else if ( typeRetombe === 'AudioVisuel'){
      return 'audiovisuel.png';
    } else {
      return 'print.png';
    }
  }
  datePrecedent(index) {
    if (index > 0) {
      if (this.coupures[index].dateParution !== this.coupures[index - 1].dateParution){
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  isbestOf(coupure) {
    if (coupure.bestOf) {
      return 'bestOf';
    }
  }

  getUrlCoupure(url){
    return Environement.BASE_API_URL + '/logo/coupurepieceJointe/' + url ;
  }

  creeMailling() {
    if(this.conditionBtn()) {
      this.maillingService.crerMailCoupure(this.client.id)
        .then((retour) => {
          // redirige l'utilisateur sur la page du mailling
          this.router.navigate(['/escal/mailling/' + retour['idMailling']]);
        });
    } else {
      this.dialogueShareService.addDialogue('Une des coupures n\'as pas de pièces jointes ou alors il n\'y a pas de coupure');
    }

  }
  conditionBtn() {
    if (this.valide === false) {
      return false;
    }
    if ( this.coupures.length === 0)  {
      return false;
    }
    return true;
  }

}
