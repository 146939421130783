import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';
import {removeAccents} from '../../partage/prototype/sansAccents';


@Injectable()
export class OrganisationService {
  private organisationURL = Environement.BASE_API_URL + '/api/organisations';
  constructor(private http: HttpClient) { }

  getOrganisations() {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getOrganisationDetails(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/' + id , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getAncienIdAuteur(organisationId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/auteur/intranet/' + organisationId , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getSocieteRecherche(recherche) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/recherche/intranet/societe/' + recherche, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getCategoriesRecherche(recherche) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/recherche/intranet/categorie/' + recherche, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getDirigeantRecherche(recherche) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/recherche/intranet/dirigeant/' + recherche, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getDoublonsSuggestion(nomSoc) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/doublons/intranet/suggestion/' + nomSoc, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getDoublonEscalnetSiExiste(doublonId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '?ancienId=' + doublonId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  importerOrganisation(socId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/doublons/intranet/importer/' + socId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }


  getAllInformationsOrganisation(socId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/doublons/intranet/informations/' + socId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }


  addOrganisation(societe, formeId, codeId, genreId) {
    const headers = this.initOptionHeader();
    let DateOrgaCre = null ;
    let forme = null;
    let code = null;
    // si date de création renseignée
    if (societe.dateCreation != null) {
      DateOrgaCre = societe.dateCreation.getFullYear() + '-' + (societe.dateCreation.getMonth() + 1).toString() + '-' + societe.dateCreation.getDate() + ' 00:00:00' ;
    }
    const DateCreFormat = societe.creation.getFullYear() + '-' + (societe.creation.getMonth() + 1).toString() + '-' + societe.creation.getDate() + ' ' + societe.creation.getHours() + ':' + societe.creation.getMinutes() + ':00' ;
    // si forme juridique renseignée
    if (formeId != 0) {
      forme = Environement.BASE_API_URL + '/api/forme_juridiques/' + formeId;
    }
    // si code ape renseigné
    if (codeId != 0) {
      code = Environement.BASE_API_URL + '/api/code_apes/' + codeId;
    }
      return this.http.post(this.organisationURL, {'nom': societe.nom, 'dateCreation': DateOrgaCre, 'commentaires': societe.commentaires,
          'siren': societe.siren, 'capital': societe.capital, 'effectif': societe.effectif, 'implantation': societe.implantation,
          'formeJuridique': forme,
          'ape': code,
          'genre': Environement.BASE_API_URL + '/api/genres/' + genreId,
          'auteur': Environement.BASE_API_URL + '/api/auth_users/' + societe.auteur.id,
          'creation': DateCreFormat},
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
  }

  addSocieteParent(socId, organisationId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.organisationURL + '/' +  organisationId, {'appartenirA': Environement.BASE_API_URL + '/api/organisations/' + socId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addDirigeant(dirId, organisationId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.organisationURL + '/' +  organisationId, {'dirigeant': Environement.BASE_API_URL + '/api/contacts/' + dirId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getDirigeantByAncienId(dirigeantId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/contacts?ancienId=' + dirigeantId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  importerDirigeant(dirigeantId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/dirigeant/intranet/importer/' + dirigeantId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  addCategorie(catId, organisationId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.organisationURL + '/' +  organisationId, {'categorieGenerale': Environement.BASE_API_URL + '/api/categories_generales/' + catId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getCategorieByAncienId(categId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/categories_generales?ancienId=' + categId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  importerCategorie(categId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/categorie/intranet/importer/' + categId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }


  modifierOrganisation(idSocMAJ, societe, formeId, genreId) {
    const headers = this.initOptionHeader();
    let DateOrgaCre = null ;
    let forme = null;
    // si date de création renseignée
    if (societe.dateCreation != null) {
      DateOrgaCre = societe.dateCreation + ' 00:00:00' ;
    }
    societe.modification = new Date();
    const DateModifFormat = societe.modification.getFullYear() + '-' + (societe.modification.getMonth() + 1).toString() + '-' + societe.modification.getDate() + ' ' + societe.modification.getHours() + ':' + societe.modification.getMinutes() + ':00' ;
    // si forme juridique renseignée
    if (formeId != 0) {
      forme = Environement.BASE_API_URL + '/api/forme_juridiques/' + formeId;
    }
    return this.http.put(this.organisationURL + '/' +  idSocMAJ, {'nom': societe.nom, 'dateCreation': DateOrgaCre, 'commentaires': societe.commentaires,
        'siren': societe.siren, 'capital': societe.capital, 'effectif': societe.effectif, 'implantation': societe.implantation,
        'formeJuridique': forme,
        'genre': Environement.BASE_API_URL + '/api/genres/' + genreId,
        'auteur': Environement.BASE_API_URL + '/api/auth_users/' + societe.auteur.id,
        'modification': DateModifFormat},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  modifierCodeApeOrganisation(idSocMAJ, codeId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.organisationURL + '/' +  idSocMAJ, {'ape': Environement.BASE_API_URL + '/api/code_apes/' + codeId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addAncienIdOrganisation(ancienIdSoc, organisationId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.organisationURL + '/' +  organisationId, {'ancienId': ancienIdSoc},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  importerOrganisationIntranet(organisationId, ancienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/importation/copie/organisation/' + organisationId + '/' + ancienId,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getOrganisationsParDefaut(page) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '?_page=' + page + '&order[nom]=asc&exists[client]=false', {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getAllContactsOrganisation(organisationId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/contact_organisations?organisation=' + organisationId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getAllSocietesDetenuesOrganisation(organisationId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '?appartenirA=' + organisationId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getAllSupportsDetenusOrganisation(organisationId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/supports?organisation=' + organisationId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }


  supprimerAssoAppartenir(organisationId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.organisationURL + '/' + organisationId, {'appartenirA': null},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerAssoSupport(supportId) {
    const headers = this.initOptionHeader();
    return this.http.put(Environement.BASE_API_URL + '/api/supports/' + supportId, {'organisation': null},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }


  supprimerAssoEmission(emisionId) {
    const headers = this.initOptionHeader();
    return this.http.put(Environement.BASE_API_URL + '/api/emissions/' + emisionId, {'organisation': null},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerAssoContactSoc(contactAssoId) {
    const headers = this.initOptionHeader();
    return this.http.delete(Environement.BASE_API_URL + '/api/contact_organisations/' + contactAssoId,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerOrganisation(id) {
    const headers = this.initOptionHeader();
    return this.http.delete(this.organisationURL + '/' +  id,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerImportSocieteIntranet(ancienIdSoc) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/importation/supprimer/copie/societe/' + ancienIdSoc ,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getOrganisationsRecherche(recherche: Array<any>, page) {
    const content = recherche[0];
    let type = recherche[1];
    const headers = this.initOptionHeader();
    type = removeAccents(type.toLowerCase());
    if (type === 'tous') {
      return this.http.get(this.organisationURL + '?several_all=' + content + '&_page=' + page + '&order[nom]=asc&exists[client]=false',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'nom') {
      return this.http.get(this.organisationURL + '?soc_nom=' + content + '&_page=' + page + '&order[nom]=asc&exists[client]=false',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'dirigeant') {
      return this.http.get(this.organisationURL + '?soc_dirigeant=' + content + '&_page=' + page + '&order[nom]=asc&exists[client]=false',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'genre') {
      return this.http.get(this.organisationURL + '?soc_genre=' + content + '&_page=' + page + '&order[nom]=asc&exists[client]=false',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'forme juridique') {
      return this.http.get(this.organisationURL + '?soc_forme=' + content + '&_page=' + page + '&order[nom]=asc&exists[client]=false',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'categorie') {
      return this.http.get(this.organisationURL + '?soc_categorie=' + content + '&_page=' + page + '&order[nom]=asc&exists[client]=false',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }

  modifOrganisation(societe, formeId, codeId, genreId) {
    const headers = this.initOptionHeader();
    let DateOrgaCre = null ;
    let forme = null;
    let code = null;
    let auteur = null;
    if (societe.auteur != null) {
      auteur = Environement.BASE_API_URL + '/api/auth_users/' + societe.auteur.id;
    }
    // si date de création renseignée
    if (societe.dateCreation != null) {
      DateOrgaCre = societe.dateCreation ;
    }
    societe.modification = new Date();
    const DateModifFormat = societe.modification.getFullYear() + '-' + (societe.modification.getMonth() + 1).toString() + '-' + societe.modification.getDate() + ' ' + societe.modification.getHours() + ':' + societe.modification.getMinutes() + ':00' ;
    // si forme juridique renseignée
    if (formeId != 0) {
      forme = Environement.BASE_API_URL + '/api/forme_juridiques/' + formeId;
    }
    if (codeId != 0) {
      code = Environement.BASE_API_URL + '/api/code_apes/' + codeId;
    }
    return this.http.put(this.organisationURL + '/' +  societe.id, {'nom': societe.nom, 'dateCreation': DateOrgaCre, 'commentaires': societe.commentaires,
        'siren': societe.siren, 'capital': societe.capital, 'effectif': societe.effectif, 'implantation': societe.implantation,
        'formeJuridique': forme,
        'genre': Environement.BASE_API_URL + '/api/genres/' + genreId,
        'auteur': auteur,
        'modification': DateModifFormat, 'ape': code},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getSocietesRechercheAvancee(nom, siren, capital, effectif, implantation, formeId, genreId, suiviPar, codeId, dirigeantId, categId,
                              premierTel, deuxiemeTel, email, premierURL, deuxiemeURL, rue, complements, codePostal, boitePostale, paysId,
                              creeChoisi, jours, page) {
    let headers = this.initOptionHeader();
    let url = '';
    // customiser url de recherche en fonction des champs renseignés
    if (nom != '') {
      url = url + '&nom=' + nom ;
    }
    if (siren != '') {
      url = url + '&siren=' + siren ;
    }
    if (capital != '') {
      url = url + '&capital=' + capital ;
    }
    if (effectif != '') {
      url = url + '&effectif=' + effectif ;
    }
    if (implantation != '') {
      url = url + '&implantation=' + implantation ;
    }
    if (formeId != 0) {
      url = url + '&formeJuridique.id=' + formeId ;
    }
    if (genreId != 0) {
      url = url + '&genre.id=' + genreId ;
    }
    if (suiviPar != 0) {
      url = url + '&auteur.id=' + suiviPar ;
    }
    if (codeId != 0) {
      url = url + '&ape.id=' + codeId ;
    }
    if (dirigeantId != 0) {
      url = url + '&dirigeant.ancienId=' + dirigeantId ;
    }
    if (categId != 0) {
      url = url + '&categorieGenerale.ancienId=' + categId ;
    }
    if (premierTel != '') {
      url = url + '&coordonnees.premierTel=' + premierTel ;
    }
    if (deuxiemeTel != '') {
      url = url + '&coordonnees.deuxiemeTel=' + deuxiemeTel ;
    }
    if (email != '') {
      url = url + '&coordonnees.email=' + email ;
    }
    if (premierURL != '') {
      url = url + '&coordonnees.premierURL=' + premierURL ;
    }
    if (deuxiemeURL != '') {
      url = url + '&coordonnees.deuxiemeURL=' + deuxiemeURL ;
    }
    if (rue != '') {
      url = url + '&coordonnees.rue=' + rue ;
    }
    if (complements != '') {
      url = url + '&coordonnees.complements=' + complements ;
    }
    if (codePostal != '') {
      url = url + '&coordonnees.codePostal=' + codePostal ;
    }
    if (boitePostale != '') {
      url = url + '&coordonnees.boitePostale=' + boitePostale ;
    }
    if (paysId != 0) {
      url = url + '&coordonnees.pays.id=' + paysId ;
    }
    if (creeChoisi != '' && jours != 0) {
      const dateActuelle = new Date();
      dateActuelle.setDate(dateActuelle.getDate() - jours );
      const dateActu = dateActuelle.getFullYear() + '-' + (dateActuelle.getMonth() + 1) + '-' + dateActuelle.getDate() + ' '
        + dateActuelle.getHours() + ':' + dateActuelle.getMinutes() + ':00' ;
      if (creeChoisi == 'plus') {
        // il y a plus de X jours
        url = url + '&creation[before]=' + dateActu ;
      }
      if (creeChoisi == 'moins') {
        // il y a moins de X jours
        url = url + '&creation[after]=' + dateActu ;
      }
    }
    // supprimer le premier &
    url = url.substr(1);
    return this.http.get(this.organisationURL + '?' + url + '&_page=' + page + '&order[nom]=asc&exists[client]=false', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  exporterOrganisation(socId, id, nom, dirigeant, date, code, siren, forme, capital, effectif, implantation, genre, categ, commentaire,
                       premierTel, deuxiemeTel, email, rue, complement, bpostal, cpostal, ville, pays, premierURL, deuxiemeURL) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/statistique/exportation/organisation/' + socId + '/' + id + '/' + nom + '/' + dirigeant + '/' + date + '/' + code + '/' + siren + '/' + forme + '/' + capital + '/' + effectif + '/' + implantation + '/' + genre + '/' + categ + '/' + commentaire + '/' +
      premierTel + '/' + deuxiemeTel + '/' + email + '/' + rue + '/' + complement + '/' + bpostal + '/' + cpostal + '/' + ville + '/' + pays + '/' + premierURL + '/' + deuxiemeURL,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  addContact(contId, organisationId) {
    const headers = this.initOptionHeader();
    return this.http.post(Environement.BASE_API_URL + '/api/contact_organisations', {
        'contact': Environement.BASE_API_URL + '/api/contacts/' + contId,
        'organisation': Environement.BASE_API_URL + '/api/organisations/' + organisationId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  suppContact(contactId, organisationId) {

}

  getSupportRecherche(recherche) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/recherche/intranet/support/' + recherche, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  importerSupport(suppId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/support/intranet/importer/' + suppId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getSupportByAncienId(suppId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/supports?ancienId=' + suppId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  addSupport(suppId, organisationId) {
    const headers = this.initOptionHeader();
    return this.http.put(Environement.BASE_API_URL + '/api/supports/' +  suppId, {'organisation': Environement.BASE_API_URL + '/api/organisations/' + organisationId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addContactIntranet(contactAncienId, organisationAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/contact/intranet/associer/' + contactAncienId + '/' + organisationAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  addSocieteParentIntranet(societeAncienId, organisationAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/societe/intranet/associer/' + societeAncienId + '/' + organisationAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  addSupportIntranet(supportAncienId, organisationAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/support/intranet/associer/' + supportAncienId + '/' + organisationAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  suppAssoContactIntranet(contactAncienId, organisationAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/contact/intranet/desassocier/' + contactAncienId + '/' + organisationAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  suppSocieteParentIntranet(socId, organisationAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/societe/intranet/desassocier/' + socId + '/' + organisationAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  suppSocieteParent(organisationId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.organisationURL + '/' +  organisationId, {'appartenirA': null},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  suppSupportAsso(suppId) {
    const headers = this.initOptionHeader();
    return this.http.put(Environement.BASE_API_URL + '/api/supports/' +  suppId, {'organisation': null},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  suppSupportIntranet(suppId, organisationAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/support/intranet/desassocier/' + suppId + '/' + organisationAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getSocietesRechercheIntranet(recherche, type) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/recherche/intranet/' + recherche + '/' + type , {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getSocieteIntranet(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/recherche/fiche/intranet/' + id , {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getSocieteByNom(champRecherche) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '?nom=' + champRecherche + '&order[nom]=asc', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  mettreAJourOrganisation(idSocMAJ, societe, formeId, genreId) {
    const headers = this.initOptionHeader();
    let DateOrgaCre = null ;
    let DateCre = null ;
    let forme = null;
    let genre = null;
    // si date de création renseignée
    if (societe.dateCreation != null) {
      societe.dateCreation = new Date(societe.dateCreation);
      DateOrgaCre = societe.dateCreation.getFullYear() + '-' + (societe.dateCreation.getMonth() + 1).toString() + '-' + societe.dateCreation.getDate() + ' ' + societe.dateCreation.getHours() + ':' + societe.dateCreation.getMinutes() + ':00' ;
    }
    if (societe.creation != null) {
      societe.creation = new Date(societe.creation);
      DateCre = societe.creation.getFullYear() + '-' + (societe.creation.getMonth() + 1).toString() + '-' + societe.creation.getDate() + ' ' + societe.creation.getHours() + ':' + societe.creation.getMinutes() + ':00' ;
    }
    societe.modification = new Date();
    const DateModifFormat = societe.modification.getFullYear() + '-' + (societe.modification.getMonth() + 1).toString() + '-' + societe.modification.getDate() + ' ' + societe.modification.getHours() + ':' + societe.modification.getMinutes() + ':00' ;
    // si forme juridique renseignée
    if (formeId != 0) {
      forme = Environement.BASE_API_URL + '/api/forme_juridiques/' + formeId;
    }
    if (genreId != 0) {
      genre = Environement.BASE_API_URL + '/api/genres/' + genreId;
    }
    return this.http.put(this.organisationURL + '/' +  idSocMAJ, {'nom': societe.nom, 'dateCreation': DateOrgaCre, 'commentaires': societe.commentaires,
        'siren': societe.siren, 'capital': societe.capital, 'effectif': societe.effectif, 'implantation': societe.implantation,
        'formeJuridique': forme,
        'genre': genre,
        'auteur': Environement.BASE_API_URL + '/api/auth_users/' + societe.auteur.id,
        'creation': DateCre,
        'modification': DateModifFormat},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  modifierSocieteAssoContact(assoId, organisationId) {
    const headers = this.initOptionHeader();
    return this.http.put(Environement.BASE_API_URL + '/api/contact_organisations/' +  assoId, {
        'organisation': Environement.BASE_API_URL + '/api/organisations/' + organisationId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  modifierSocieteAppartenirA(socId, organisationId) {
    const headers = this.initOptionHeader();
    return this.http.put(Environement.BASE_API_URL + '/api/organisations/' +  socId, {
        'appartenirA': Environement.BASE_API_URL + '/api/organisations/' + organisationId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  modifierSupportDetenu(supId, organisationId) {
    const headers = this.initOptionHeader();
    return this.http.put(Environement.BASE_API_URL + '/api/supports/' +  supId, {
        'organisation': Environement.BASE_API_URL + '/api/organisations/' + organisationId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getAllEmissionsDetenusOrganisation(organisationId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/emissions?organisation=' + organisationId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  modifierEmissionDetenue(emId, organisationId) {
    const headers = this.initOptionHeader();
    return this.http.put(Environement.BASE_API_URL + '/api/emissions/' +  emId, {
        'organisation': Environement.BASE_API_URL + '/api/organisations/' + organisationId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addEmissionIntranet(emissionAncienId, organisationAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/emission/intranet/associer/' + emissionAncienId + '/' + organisationAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  suppEmissionIntranet(emId, organisationAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '/emission/intranet/desassocier/' + emId + '/' + organisationAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getSuggestionDedoublonnage() {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '?pagination=false&order[nom]=asc&exists[client]=false', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getOrganisationsByNom(nom) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '?nom=' + nom + '&pagination=false&order[nom]=asc&exists[client]=false', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getOrganisationByAncienId(oldId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '?ancienId=' + oldId, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getInfosClient(clientId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.organisationURL + '?client.id=' + clientId, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }


  addAncienIdClient(ancienId, clientId) {
    const headers = this.initOptionHeader();
    return this.http.put(Environement.BASE_API_URL + '/api/clients/' +  clientId, {'ancienId': ancienId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addClientOrganisation(clientId, socId) {
    let client = null;
    if (clientId !== 0) {
      client = Environement.BASE_API_URL + '/api/clients/' +  clientId ;
    }
    const headers = this.initOptionHeader();
    return this.http.put(this.organisationURL + '/' +  socId, {'client': client},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }


  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
