import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SuiviActionShareService} from '../../../../../service/partage/SuiviActionShareService';
import {SuivitIntranetService} from '../../../../../service/interne/suivit/SuivitIntranetService';
import {AssoPersonneMediaOrganisation} from '../../../../../model/Core/AssoPersonneMediaOrganisation';
import {TypeDeRelationService} from '../../../../../service/interne/SuiviAction/typeDeRelationService';
import {TypeDeRelation} from '../../../../../model/suivitAction/TypeDeRelation';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {NatureActionSuiviService} from '../../../../../service/interne/SuiviAction/natureActionSuiviService';
import {NatureActionSuiviTypeDeRelation} from '../../../../../model/suivitAction/NatureActionSuiviTypeDeRelation';
import {NatureActionSuiviVecteur} from '../../../../../model/suivitAction/NatureActionSuiviVecteur';
import {Client} from '../../../../../model/client/Client';
import {ClientService} from '../../../../../service/interne/client/ClientService';
import {AuthUser} from '../../../../../model/user/authUser';
import {AuthUserService} from '../../../../../service/interne/user/AuthUserService';
import {TypeRelationSuivi} from '../../../../../model/suivi/TypeRelationSuivi';
import {NatureTypeRelationSuivi} from '../../../../../model/suivi/NatureTypeRelationSuivi';
import {Statut} from '../../../../../model/suivi/Statut';
import {NatureSuivi} from '../../../../../model/suivi/NatureSuivi';
import {NatureVecteur} from '../../../../../model/suivi/NatureVecteur';
import {VecteurSuivi} from '../../../../../model/suivi/VecteurSuivi';
import {Contact} from '../../../../../model/contact/Contact';
import {ContactSupport} from '../../../../../model/contact/ContactSupport';
import {Support} from '../../../../../model/support/Support';
import {ContactOrganisation} from '../../../../../model/contact/ContactOrganisation';
import {Mailling} from '../../../../../model/mailling/Mailling';
import {MaillingService} from '../../../../../service/interne/mailling/maillingService';
import {CalendrierRedactionnel} from '../../../../../model/client/CalendrierRedactionnel';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';
import {ActivatedRoute, Router} from '@angular/router';
import {Suivi} from '../../../../../model/suivi/Suivi';
import {SuiviNature} from '../../../../../model/suivi/SuiviNature';
import {ClientMailingCalendrierRedactionnel} from '../../../../../model/client/ClientMailingCalendrierRedactionnel';
import {$t} from 'codelyzer/angular/styles/chars';
import {ContactService} from '../../../../../service/interne/contact/ContactService';
import {forEach} from '@angular/router/src/utils/collection';
import {SupportService} from '../../../../../service/interne/support/SupportService';
import {AuthShareService} from '../../../../../service/partage/AuthShareService';

@Component({
  selector: 'app-escal-suivi-gestion-personne',
  templateUrl: './escal-suivi-gestion-personne.component.html',
  styleUrls: ['./escal-suivi-gestion-personne.component.scss'],
  providers: [SuivitIntranetService, ContactService, SupportService],
})
export class EscalSuiviGestionPersonneComponent implements OnInit {
  @Input()
  vriable: string;
  @Output()
  parentEvent: EventEmitter<any> = new EventEmitter<any>();
  champRechercheContact = '';
  champRechercheCalRedUn = '';
  champRechercheCalRedDeux = '';
  contactsRecherche: Array<any> = [];
  calRedsRecherche: Array<any> = [];
  calRedsRechercheUn: Array<any> = [];
  calRedsRechercheDeux: Array<any> = [];
  rechercheEncours = false;
  rechercheEncoursCalRed = false;
  rechercheCommEncours = false;
  typeDeRelations: Array<TypeRelationSuivi> = [];
  natureTypeRelations: Array<NatureTypeRelationSuivi>;
  natureVecteur: Array<NatureVecteur>;
  naturesSelect: Array<NatureSuivi>;
  vecteursSelect: Array<VecteurSuivi>;
  contactIDSelect: number;
  emailSelect: string;
  telSelect: string;
  opportunite: number;
  typeRelationIDSelect: number;
  vecteurIDSelect: number;
  natureActionSuiviIDSelect: number;
  niveauIDSelect: number;
  numerosSelect: Array<any> = [];
  emailsSelect: Array<any> = [];
  niveauxDesSuivis: Array<any> = [];
  premiereSocieteSuivie: number;
  deuxiemeSocieteSuivie: number;
  aParaitre: boolean;
  sujet = '';
  axe = '';
  interesse = '';
  theme = '';
  interlocuteur = '';
  commentaireVeille = '';
  note = '';
  suiviPar: number;
  mutuPar: number;
  mutualisation: boolean;
  communiqueUn: number;
  communiqueDeux: number;
  calRedUn: number;
  calRedDeux: number;
  articleUn = '';
  articleDeux = '';
  dateitw = new Date();
  clients: Array<Client>;
  statuts: Array<Statut>;
  users: Array<AuthUser> = [];
  contact: Contact;
  supports: Array<Support>;
  contactSupports: Array<ContactSupport>;
  contactOrganisations: Array<ContactOrganisation>;
  actif = '';
  communiquesUn: Array<Mailling>;
  communiquesDeux: Array<Mailling>;
  client: Client;
  affiche: boolean;
  afficheCalUn: boolean;
  afficheCalDeux: boolean;
  mediaSuiviIds: Array<any> = [];
  verifCoord: boolean;
  verifComplements: boolean;
  verifMutu: boolean;
  raison: string;
  statutId: number;
  suiviNature: SuiviNature;
  sub: any;
 constructor(public suiviActionShareService: SuiviActionShareService, private suivitIntranetService: SuivitIntranetService,
             private typeDeRelationService: TypeDeRelationService, public formatIntranetShareService: FormatIntranetShareService,
             private natureSuiviActionService: NatureActionSuiviService, private clientService: ClientService,
             private authUserService: AuthUserService, private natureActionSuiviService: NatureActionSuiviService,
             private maillingService: MaillingService, private dialogueShareService: DialogueShareService, private router: Router,
             private contactService: ContactService, private supportService: SupportService, private authShareService: AuthShareService,
             private route: ActivatedRoute) { }

  ngOnInit() {
   const that = this;
   this.emailSelect = '';
   this.telSelect = '';
   this.aParaitre = false;
   this.premiereSocieteSuivie = 0;
   this.deuxiemeSocieteSuivie = 0;
   this.statutId = 1;
   this.contactIDSelect = 0;
   this.emailSelect = '';
   this.telSelect = '';
   this.suiviPar = 0;
   this.mutuPar = 0;
   this.opportunite = 0;
   this.calRedUn = 0;
   this.calRedDeux = 0;
   this.communiqueUn = 0;
   this.communiqueDeux = 0;
   this.typeRelationIDSelect = 1;
   this.niveauIDSelect = 7;
   this.naturesSelect = [];
   this.vecteursSelect = [];
   this.mutualisation = false;
   this.affiche = false;
   this.afficheCalUn = false;
   this.afficheCalDeux = false;
   this.verifCoord = false;
   this.verifComplements = false;
   this.verifMutu = false;
   this.actif = 'premiere';
   this.raison = '';
   // suiviPar par défaut utilisateur connecté
   if (this.authShareService.authUser.id != undefined) {
     this.suiviPar = this.authShareService.authUser.id;
   }
    // ajout des types de relations
   this.typeDeRelationService.getTypeRelation().then((retour) => {
      that.typeDeRelations = retour['hydra:member'] as Array<TypeRelationSuivi>;
      this.typeDeRelations.forEach((typeDeRelation) => {
       // Relation presse par défaut
       if (typeDeRelation.id === 1) {
         that.suiviActionShareService.suivi.typeRelation = typeDeRelation;
         that.typeRelationIDSelect = typeDeRelation.id;
         that.natureTypeRelations = typeDeRelation.natureTypeRelationSuivi;
         this.natureTypeRelations.forEach((naturesType) => {
           // nature par defaut
           if (that.naturesSelect.length < 1) {
             // that.natureActionSuiviIDSelect = naturesType.natureSuivi.id;
             that.natureActionSuiviIDSelect = 9;
             // vecteur par defaut
             that.natureVecteur = naturesType.natureSuivi.natureVecteurSuivi;
             that.natureVecteur.forEach((naturesVecteur) => {
              if (that.vecteursSelect.length < 1) {
                // that.vecteurIDSelect = naturesVecteur.vecteurSuivi.id;
                that.vecteurIDSelect = 4;
               }
              that.vecteursSelect.push(naturesVecteur.vecteurSuivi);
             });
           }
           that.naturesSelect.push(naturesType.natureSuivi);
         });
       }
     });
    });
   this.clientService.getClientList(1)
      .then((clients) => {
        that.clients = clients['hydra:member'] as Array<Client>;
      });
   this.suiviActionShareService.getStatuts().then((statuts) => {
      that.statuts = statuts['hydra:member'] as Array<Statut>;
    });
   this.authUserService.getUsersList(1).then((usersRetour) => {
      that.users = usersRetour['hydra:member'] as Array<AuthUser>;
    });
   this.niveauxDesSuivis.push(1, 2, 3, 4, 5, 6, 7);
   // nouveau suivi via intranet
   this.sub = this.route.params.subscribe(params => {
     if (params['id'] != undefined) {
       this.contactIDSelect = params['id'];
       this.changeContact();
       this.recupererNom();
     }
   });
  }

  recupererNom() {
    this.suivitIntranetService.getRecupererNom(this.contactIDSelect).then((retour) => {
      this.contactsRecherche = retour;
    });
  }

  envoieParent() {
    this.parentEvent.emit('Parent');
  }

  rechercheContact() {
    this.affiche = true;
    if (this.champRechercheContact.length > 2 && !this.rechercheEncours) {
      this.rechercheEncours = true;
      this.champRechercheContact = this.champRechercheContact.replace(' ', '.');
      this.suivitIntranetService.getContactRecherche(this.champRechercheContact).then((retour) => {
        this.contactsRecherche = retour;
        this.champRechercheContact = this.champRechercheContact.replace('.', ' ');
      });
      this.rechercheEncours = false;
    }
  }

  changeContact() {
    this.supports = [];
    this.numerosSelect = [];
    this.emailsSelect = [];
    this.telSelect = '';
    this.emailSelect = '';
    if (this.contactIDSelect !== 0) {
      this.suivitIntranetService.getContactCoordonnees(this.contactIDSelect).then((contCoor) => {
        this.contact = contCoor;
        // recupérer coordonnees perso du contact
        if (this.contact.coordonnees.premierTel !== '' && this.contact.coordonnees.premierTel !== null) {
          this.ajouterNumero(this.contact.coordonnees.premierTel);
        }
        if (this.contact.coordonnees.deuxiemeTel !== '' && this.contact.coordonnees.deuxiemeTel !== null) {
          this.ajouterNumero(this.contact.coordonnees.deuxiemeTel);
        }
        if (this.contact.coordonnees.email !== '' && this.contact.coordonnees.email !== null) {
          this.ajouterEmail(this.contact.coordonnees.email);
          }
        // recupérer coordonnees supports du contact
        this.suivitIntranetService.getContactSupports(this.contactIDSelect).then((contSupp) => {
          this.contactSupports = contSupp;
          this.contactSupports.forEach(contactSupport => {
            // récupérer nom des supports
            if (contactSupport.support.nom !== '' && contactSupport.support.nom !== null) {
              contactSupport.support.nom = contactSupport.support.nom.replace('&amp;', '&');
              contactSupport.support.nom = contactSupport.support.nom.replace('&#039;', '’');
              contactSupport.support.nom = contactSupport.support.nom.replace('L&#039;', 'L’');
              this.supports.push(contactSupport.support);
            }
            if (contactSupport.premierTel !== '' && contactSupport.premierTel !== null) {
              this.ajouterNumero(contactSupport.premierTel);
            }
            if (contactSupport.deuxiemeTel !== '' && contactSupport.deuxiemeTel !== null) {
              this.ajouterNumero(contactSupport.deuxiemeTel);
            }
            if (contactSupport.email !== '' && contactSupport.email !== null) {
              this.ajouterEmail(contactSupport.email);
            }
            if (contactSupport.support.coordonnees.premierTel !== '' && contactSupport.support.coordonnees.premierTel !== null) {
              this.ajouterNumero(contactSupport.support.coordonnees.premierTel);
            }
            if (contactSupport.support.coordonnees.email !== '' && contactSupport.support.coordonnees.email !== null) {
              this.ajouterEmail(contactSupport.support.coordonnees.email);
            }
            if (contactSupport.support.telStandard !== '' && contactSupport.support.telStandard !== null) {
              this.ajouterNumero(contactSupport.support.telStandard);
            }
            if (contactSupport.support.telPublicite !== '' && contactSupport.support.telPublicite !== null) {
              this.ajouterNumero(contactSupport.support.telPublicite);
            }
          });
          // recupérer coordonnees pro du contact
          this.suivitIntranetService.getContactSocietes(this.contactIDSelect).then((contSoc) => {
            this.contactOrganisations = contSoc;
            this.contactOrganisations.forEach(contactOrganisation => {
              if (contactOrganisation.premierTel !== '' && contactOrganisation.premierTel !== null) {
                this.ajouterNumero(contactOrganisation.premierTel);
              }
              if (contactOrganisation.deuxiemeTel !== '' && contactOrganisation.deuxiemeTel !== null) {
                this.ajouterNumero(contactOrganisation.deuxiemeTel);
              }
              if (contactOrganisation.email !== '' && contactOrganisation.email !== null) {
                this.ajouterEmail(contactOrganisation.email);
              }
            });
          });
        });
      });
    }
  }

  ajouterNumero(num) {
    // dédoublonner les numéros de téléphone
    let trouve = false;
    this.numerosSelect.forEach(numero => {
      if (numero === num) {
        trouve = true;
      }
    });
    if (!trouve) {
      // si pas déjà présent, l'ajouter à la liste des numéros
      this.numerosSelect.push(num);
    }
  }

  ajouterEmail(email) {
    // dédoublonner les numéros de téléphone
    let trouve = false;
    this.emailsSelect.forEach(adresse => {
      if (adresse === email) {
        trouve = true;
      }
    });
    if (!trouve) {
      // si pas déjà présent, l'ajouter à la liste des numéros
      this.emailsSelect.push(email);
    }
  }

  active(actif) {
    if (this.actif !== actif) {
      this.actif = actif;
    }
  }

  rechercheCalRed(soc) {
    let idSociete = 0;
    let recherche = '';
    if (soc === 'premiere') {
      idSociete = this.premiereSocieteSuivie;
      recherche = this.champRechercheCalRedUn;
      this.afficheCalUn = true;
    } else {
      idSociete = this.deuxiemeSocieteSuivie;
      recherche = this.champRechercheCalRedDeux;
      this.afficheCalDeux = true;
    }
    if (recherche.length > 2 && !this.rechercheEncoursCalRed) {
      this.rechercheEncoursCalRed = true;
      this.clientService.getClientDetail(idSociete).then((client) => {
        this.client = client as Client;
        idSociete = this.client.ancienId;
        recherche = recherche.replace(' ', '.');
        this.suivitIntranetService.getCalRedRecherche(recherche, idSociete, this.contactIDSelect).then((calReds) => {
          this.calRedsRecherche = calReds as Array<CalendrierRedactionnel>;
          this.calRedsRecherche.forEach(cal => {
            cal.dossier = cal.dossier.replace('&#039;', '’');
          });
          if (soc === 'premiere') {
            this.calRedsRechercheUn = this.calRedsRecherche;
          } else {
            this.calRedsRechercheDeux = this.calRedsRecherche;
          }
        });
      });
      this.rechercheEncoursCalRed = false;
    }
  }


  changePremiereSociete() {
    this.calRedsRechercheUn = [];
    this.champRechercheCalRedUn = '';
    this.afficheCalUn = false;
    this.calRedUn = 0;
    this.articleUn = '';
    this.communiqueUn = 0;
    this.maillingService.getMaillingListClient(this.premiereSocieteSuivie).then((communiques) => {
      this.communiquesUn = communiques['hydra:member'] as Array<Mailling>;
      this.communiquesUn.forEach(comm => {
        // récupérer nom des supports
        if (comm.nom !== '' && comm.nom !== null) {
          comm.nom = comm.nom.replace('&#039;', '’');
        }
      });
    });
  }

  changeDeuxiemeSociete() {
    this.calRedsRechercheDeux = [];
    this.champRechercheCalRedDeux = '';
    this.afficheCalDeux = false;
    this.calRedDeux = 0;
    this.articleDeux = '';
    this.communiqueDeux = 0;
    this.maillingService.getMaillingListClient(this.deuxiemeSocieteSuivie).then((communiquesDeux) => {
      this.communiquesDeux = communiquesDeux['hydra:member'] as Array<Mailling>;
    });
  }

  resetNatureSuiviAction() {
   const that = this;
   this.typeDeRelations.forEach((typeDeRelation) => {
      // Relation presse par défaut
     if (typeDeRelation.id == this.typeRelationIDSelect) {
        that.naturesSelect = [];
        that.suiviActionShareService.suivi.typeRelation = typeDeRelation;
        that.typeRelationIDSelect = typeDeRelation.id;
        that.natureTypeRelations = typeDeRelation.natureTypeRelationSuivi;
        this.natureTypeRelations.forEach((naturesType) => {
          that.naturesSelect.push(naturesType.natureSuivi);
          // nature par defaut
          if (that.naturesSelect.length <= 1) {
            that.natureActionSuiviIDSelect = naturesType.natureSuivi.id;
            // vecteur associes
            this.naturesSelect.forEach((natures) => {
              if (natures.id == this.natureActionSuiviIDSelect) {
                that.vecteursSelect = [];
                that.natureVecteur = natures.natureVecteurSuivi;
                that.natureVecteur.forEach((naturesVecteur) => {
                  if (that.vecteursSelect.length < 1) {
                    that.vecteurIDSelect = naturesVecteur.vecteurSuivi.id;
                  }
                  that.vecteursSelect.push(naturesVecteur.vecteurSuivi);
                });
              }
            });
          }
        });
      }
    });
  }

  resetVecteur() {
   // on reset toutes les informations d action suite à
    this.axe = '';
    this.interesse = '';
    this.sujet = '';
    this.theme = '';
    this.interlocuteur = '';
    this.raison = '';
    this.commentaireVeille = '';
    this.opportunite = 0;
    // changer vecteur associes
    const that = this;
    this.naturesSelect.forEach((natures) => {
      if (natures.id == this.natureActionSuiviIDSelect) {
        that.vecteursSelect = [];
        that.natureVecteur = natures.natureVecteurSuivi;
        that.natureVecteur.forEach((naturesVecteur) => {
          if (that.vecteursSelect.length < 1) {
            that.vecteurIDSelect = naturesVecteur.vecteurSuivi.id;
          }
          that.vecteursSelect.push(naturesVecteur.vecteurSuivi);
        });
      }
    });
  }

  enregistrerSuivi() {
   const that = this;
   // vérifier les données obligatoires saisies
   if (this.suiviActionShareService.suivi.titre !== '' && this.suiviActionShareService.suivi.titre !== null) {
      if (this.contactIDSelect !== 0) {
          if (this.premiereSocieteSuivie !== 0) {
            // verifier si email et numero de tel saisis en fonction du choix du vecteur
            this.verifCoordVecteur();
            if (this.verifCoord === true) {
              // verifier si compléments bien saisis en fonction de la nature du suivi
              this.verifComplementsNature();
              if (this.verifComplements === true) {
                if (this.suiviPar !== 0) {
                  // verifier si mutualisation bien saisie
                  this.verifMutualisation();
                  if (this.verifMutu === true) {
                    let mediaIdsSelect = '';
                    // envoie les id des médias à l'api sous forme de chaine de caractère
                    this.mediaSuiviIds.forEach( mediaId => {
                      mediaIdsSelect = mediaIdsSelect + mediaId + ',';
                    });
                    // créer objet asso Suivi Nature
                    this.suiviNature = new SuiviNature(0, null, null, this.axe, this.interesse, this.sujet, null,
                      this.theme, this.interlocuteur, this.raison, this.commentaireVeille, this.dateitw);
                    // récupérer l'utilisateur qui suit le suivi
                    this.authUserService.getUser(this.suiviPar)
                      .then((userSuiviPar) => {
                        this.suiviActionShareService.suivi.suiviPar = userSuiviPar as AuthUser;
                        // récupérer le statut du suivi
                        this.suiviActionShareService.getStatut(this.statutId)
                          .then((statut) => {
                            this.suiviActionShareService.suivi.statut = statut as Statut;
                            // récupérer le vecteur du suivi
                            this.suiviActionShareService.getVecteur(this.vecteurIDSelect)
                              .then((vecteur) => {
                                this.suiviActionShareService.suivi.vecteur = vecteur as VecteurSuivi;
                                // récupérer la nature du suivi
                                this.suiviActionShareService.getNature(this.natureActionSuiviIDSelect)
                                  .then((nature) => {
                                    this.suiviNature.nature = nature as NatureSuivi;
                                    // ajouter l'asso au suivi
                                    this.suiviActionShareService.suivi.suiviNatures.push(this.suiviNature);
                                    // compléter infos du suivi
                                    this.suiviActionShareService.suivi.commentaires = this.note;
                                    this.suiviActionShareService.suivi.niveau = Number(this.niveauIDSelect);
                                    this.suiviActionShareService.suivi.aParaitre = this.aParaitre;
                                    this.suiviActionShareService.suivi.email = this.emailSelect;
                                    this.suiviActionShareService.suivi.telephone = this.telSelect;
                                    this.suiviActionShareService.suivi.auteur = this.authShareService.authUser;
                                    // gérer le cas sans supports
                                    if (mediaIdsSelect == '') {
                                      mediaIdsSelect = ',';
                                    }
                                    // on importe de intranet vers escalnet : contact, organisation, support, calred, etc...
                                    this.suiviActionShareService.importerTout(this.contactIDSelect, this.calRedUn, this.calRedDeux,
                                      mediaIdsSelect).then((retour) => {
                                        const $tab = retour;
                                        // on récupère le contact du suivi
                                        this.contactService.getContactDetail($tab.contact).then((contact) => {
                                          this.suiviActionShareService.suivi.contact = contact as Contact;
                                          this.suiviActionShareService.addSuivi(that.suiviActionShareService.suivi, this.mutuPar)
                                            .then((suivi) => {
                                              const suiviAdd = suivi as Suivi;
                                              // si il y a au moins un support sélectionné
                                              if ($tab.supports) {
                                                // on récupère les supports du suivi
                                                const tabSupports = $tab.supports.split(',');
                                                tabSupports.forEach( supp => {
                                                  if (supp != '') {
                                                    // on recup le support
                                                    this.supportService.getSupportDetails(supp).then((support) => {
                                                      const $supportAjout = support as Support;
                                                      // on l'ajoute à la collection de supports du suivi
                                                      this.suiviActionShareService.suivi.supports.push($supportAjout);
                                                      that.suiviActionShareService.suivi.supports.forEach( suppSuivi => {
                                                        this.suiviActionShareService.addSupportSuivi(suppSuivi.id, suiviAdd.id).then();
                                                      });
                                                    });
                                                  }
                                                });
                                              }
                                              // enregistrer asso suivi nature
                                              this.suiviActionShareService.addSuiviNature(suiviAdd.id, this.natureActionSuiviIDSelect,
                                                this.axe, this.interesse, this.sujet, this.theme, this.interlocuteur, this.raison,
                                                this.commentaireVeille, this.opportunite, this.dateitw).then( nature => {
                                                // enregistrer asso client mailing calred premiere societe
                                                this.suiviActionShareService.addClientMailingCalRed(this.premiereSocieteSuivie,
                                                  this.communiqueUn,  $tab.calRed1, this.articleUn, suiviAdd.id).then( asso => {
                                                  // enregistrer suivi dans la base d'intranet
                                                  this.suiviActionShareService.importerSuiviIntranet(suiviAdd.id, mediaIdsSelect)
                                                    .then();
                                                });
                                              });
                                              if (this.deuxiemeSocieteSuivie != 0) {
                                                // enregistrer asso client mailing calred deuxieme societe
                                                this.suiviActionShareService.addClientMailingCalRed(this.deuxiemeSocieteSuivie,
                                                  this.communiqueDeux, $tab.calRed2, this.articleDeux, suiviAdd.id).then();
                                              }
                                              // enregistrement suivi escalnet terminé
                                              this.router.navigate(['/escal/suivis']);
                                              this.dialogueShareService.addDialogue('Le suivi a bien été créé');
                                            });
                                          });
                                        });
                                    });
                                  });
                              });
                          });
                  } else {
                    this.dialogueShareService.addDialogue('Veuillez choisir de qui provient la mutualisation');
                  }
                  this.verifMutu = false;
                } else {
                  this.dialogueShareService.addDialogue('Le collaborateur en charge du suivi doit être renseigné');
                }
              } else {
                this.dialogueShareService.addDialogue('Les compléments sont obligatoires');
              }
              this.verifComplements = false;
            } else {
              this.dialogueShareService.addDialogue('La coordonnée utilisée est obligatoire');
            }
            this.verifCoord = false;
          } else {
            this.dialogueShareService.addDialogue('Veuillez choisir une première société');
          }
      } else {
        this.dialogueShareService.addDialogue('Veuillez choisir un contact');
      }
    } else {
      this.dialogueShareService.addDialogue('Le titre du suivi est obligatoire');
    }
  }

  verifCoordVecteur() {
    if (this.vecteurIDSelect == 2 || this.vecteurIDSelect == 4 || this.vecteurIDSelect == 6) {
      if (this.vecteurIDSelect == 2 && this.emailSelect != '') {
        this.verifCoord = true;
      }
      if ((this.vecteurIDSelect == 4 || this.vecteurIDSelect == 6) && this.telSelect != '') {
        this.verifCoord = true;
      }
    } else {
      this.verifCoord = true;
    }
  }

  verifComplementsNature() {
    if (this.natureActionSuiviIDSelect == 1 || this.natureActionSuiviIDSelect == 9 || this.natureActionSuiviIDSelect == 15) {
      if (this.axe != '' && this.interesse != '' && this.sujet != '') {
        this.verifComplements = true;
      }
    } else if (this.natureActionSuiviIDSelect == 8 || this.natureActionSuiviIDSelect == 12 || this.natureActionSuiviIDSelect == 13 || this.natureActionSuiviIDSelect == 17) {
        if ((this.natureActionSuiviIDSelect ==  12 || this.natureActionSuiviIDSelect == 17) && this.raison != '' && this.theme != '' && this.interlocuteur != '') {
        this.verifComplements = true;
      }
        if ((this.natureActionSuiviIDSelect ==  8 || this.natureActionSuiviIDSelect == 13) && this.theme != '' && this.interlocuteur != '') {
        this.verifComplements = true;
      }
    } else {
        this.verifComplements = true;
    }
  }

  verifMutualisation() {
    if (this.mutualisation == true) {
      if (this.mutuPar != 0) {
        this.verifMutu = true;
      }
    } else {
      this.verifMutu = true;
    }
  }

  /*isAfficheMail(mail) {
    if (typeof mail.ID_support !== 'undefined') {

      for (let i = 0, len = this.suiviActionShareService.suivi.mediaSuivitIds.length; i < len; i++) {
        if (this.suiviActionShareService.suivi.mediaSuivitIds[i] == mail.ID_support) {
          return true;
        }
      }
      return false;
    }
    return true;
  }*/
  /*isAfficheNumero(numero) {
    if (typeof numero.ID_support !== 'undefined') {
      for (let i = 0, len = this.suiviActionShareService.suivi.mediaSuivitIds.length; i < len; i++) {
        if (this.suiviActionShareService.suivi.mediaSuivitIds[i] == numero.ID_support) {
          return true;
        }
      }
      return false;
    }
    return true;
  }*/

  /*isPresent() {
    let count = 0;
    switch (Number(this.suiviActionShareService.suivi.vecteur)) {
      // numero
      case 4:
        this.numerosPossibles.forEach((numero) => {
          if (this.isAfficheNumero(numero)) {
            count++;
          }
        });
        break;
      // mail
      case 2:
        this.emailsPossibles.forEach((mail) => {
          if (this.isAfficheMail(mail)) {
            count++;
          }
        });
        break;
    }
    return count >= 1;
  }*/

  /*initDefaultNatureSuiviAction(typeRelationIDSelect) {
  const that = this;
  this.natureActionSuiviService.getNaturesTypeRelation().then((naturesType) => {
    that.natureTypeRelations = naturesType['hydra:member'] as Array<NatureTypeRelationSuivi>;
    that.natureTypeRelations.forEach((natureType) => {
      if (natureType.typeRelationSuivi.id === typeRelationIDSelect) {
        // recuperer l'objet natur
        this.natureActionSuiviService.getNature(natureType.natureSuivi.id).then((nature) => {
          // ajouter l'objet à la liste des natures correspondant au type de relation
          that.naturesSelect.push(nature);
        });
      }
    });
  });
}*/

  /*initDefaultTypeDeRelation() {
    const that = this;
    this.typeDeRelations.forEach((typeDeRelation) => {
      // Relation presse par défaut
      if (typeDeRelation.id === 1) {
        that.suiviActionShareService.suivi.typeRelation = typeDeRelation;
        that.typeRelationIDSelect = typeDeRelation.id;
        // that.resetNatureSuiviAction();
        that.initDefaultNatureSuiviAction(that.typeRelationIDSelect);
      }
    });
  }*/

  /*initDefaultVecteur() {
   const that = this;
   this.natureActionSuiviVecteurs.forEach((natureActionSuiviVecteur) => {
     if (natureActionSuiviVecteur.principal === true) {
       // that.suiviActionShareService.suivi.vecteur = natureActionSuiviVecteur.vecteur;
       that.vecteurIDSelect = natureActionSuiviVecteur.vecteur.id;
     }
   });
  }
  resetEmailSuggestion() {
    this.emailsPossibles = [];
  }*/

  /*
    changeContactTest() {
      this.assoMedias = [];
      this.numerosSelect = [];
      this.emailsSelect = [];
      let trouveTel = false;
      let trouveCoor = false;
      let trouveEmail = false;
      const that = this;
      if (this.contactIDSelect !== 0) {
        this.suivitIntranetService.getContactAssoPMO(this.contactIDSelect).then((retour) => {
          that.assoPMOs = retour;
          that.assoPMOs.forEach(assoPMO => {
            if (assoPMO.media != null) {
              // ajout des medias
              that.assoMedias.push(assoPMO);
            }
            if (assoPMO.organisation != null) {
            }
            if (assoPMO.coordonnees != null) {
              if (assoPMO.coordonnees.telephone !== '' && assoPMO.coordonnees.telephone !== null) {
                // dédoublonner les numéros de téléphone à sélectionner
                trouveCoor = false;
                this.numerosSelect.forEach(numero => {
                  if (numero === assoPMO.coordonnees.telephone) {
                    trouveCoor = true;
                  }
                });
                if (!trouveCoor) {
                  this.numerosSelect.push(assoPMO.coordonnees.telephone);
                }
              }
              if (assoPMO.coordonnees.email !== '' && assoPMO.coordonnees.email !== null) {
                // dédoublonner les emails à sélectionner
                trouveEmail = false;
                this.emailsSelect.forEach(email => {
                  if (email === assoPMO.coordonnees.email) {
                    trouveEmail = true;
                  }
                });
                if (!trouveEmail) {
                  this.emailsSelect.push(assoPMO.coordonnees.email);
                }
              }
            }
            if (assoPMO.personne != null) {
              if (assoPMO.personne.coordonnees.telephone !== '' && assoPMO.personne.coordonnees.telephone !== null) {
                // dédoublonner les numéros de téléphone à sélectionner
                trouveTel = false;
                this.numerosSelect.forEach(numero => {
                  if (numero === assoPMO.personne.coordonnees.telephone) {
                    trouveTel = true;
                  }
                });
                if (!trouveTel) {
                  this.numerosSelect.push(assoPMO.personne.coordonnees.telephone);
                }
              }
              if (assoPMO.personne.coordonnees.email !== '' && assoPMO.personne.coordonnees.email !== null) {
                // dédoublonner les emails à sélectionner
                trouveEmail = false;
                this.emailsSelect.forEach(email => {
                  if (email === assoPMO.personne.coordonnees.email) {
                    trouveEmail = true;
                  }
                });
                if (!trouveEmail) {
                  this.emailsSelect.push(assoPMO.personne.coordonnees.email);
                }
              }
            }
          });
        });
      }
    }*/

  /*
  changeContact() {
   this.assoMedias = [];
   const that = this;
   if (this.suiviActionShareService.suiviAction.contactSuiviId !== 0) {
      this.suivitIntranetService.getContactAssoPMO(this.suiviActionShareService.suiviAction.contactSuiviId).then((retour) => {
        that.assoPMOs = retour;
        that.assoPMOs.forEach(assoPMO => {
          if (assoPMO.media != null) {
            // ajout des medias
            that.assoMedias.push(assoPMO);
          }
          if (assoPMO.organisation != null) {
          this.numerosPossibles.push(assoPMO.organisation.coordonnees.telephone + '(' + assoPMO.organisation.nom + ')');
          }
          this.numerosPossibles.push(assoPMO.personne.coordonnees.telephone + '(' + assoPMO.personne.nom + ')');
          this.numerosPossibles.push(assoPMO.coordonnees);
        });
      });
   }
 }*/

}
