import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../../environement/environement';
import {Pressroom} from '../../../../model/client/pressroom/Pressroom';
import {ImagePressroom} from '../../../../model/client/pressroom/ImagePressroom';


@Injectable()
export class PressroomService{
    private pressroomUrl = Environement.BASE_API_URL + '/api/pressrooms';
    constructor(private http: HttpClient) { }

  getPressroomByClient(idClient) {
    const headers = this.initOptionHeader();
    return this.http.get(this.pressroomUrl + '?client.id=' + idClient , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
    }
  addPressroom(pressroom: Pressroom) {
    const headers = this.initOptionHeader();
    return this.http.post(this.pressroomUrl , {'nom': pressroom.nom, 'client' : '/api/clients/' +
        pressroom.client.id}, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateConfiguration(pressroom: Pressroom, contact1, contact2, image: ImagePressroom, logo: ImagePressroom){
    const headers = this.initOptionHeader();
    let contact1Url;
    let contact2Url;
    let imageUrl;
    let logoUrl;
    if (contact1 === null || contact1 === 'null'){
      contact1Url = null;
    } else {
      contact1Url = Environement.BASE_API_URL + '/api/auth_users/' + contact1;
    }
    if (contact2 === null || contact2 === 'null'){
      contact2Url = null;
    } else {
      if (contact1 === null || contact1 === 'null'){
        contact1Url = Environement.BASE_API_URL + '/api/auth_users/' + contact2;
        contact2Url = null;
      } else {
        contact2Url = Environement.BASE_API_URL + '/api/auth_users/' + contact2;
      }
    }
    if(image === null){
      imageUrl = null;
    } else {
      imageUrl = Environement.BASE_API_URL + '/api/image_pressrooms/' + image.id;
    }
    if(logo === null){
      logoUrl = null;
    } else {
      logoUrl = Environement.BASE_API_URL + '/api/image_pressrooms/' + logo.id;
    }
    return this.http.put( this.pressroomUrl + '/' + pressroom.id , { 'contact1' : contact1Url, 'contact2': contact2Url,
    'imageFond': imageUrl, 'logoAffiche': logoUrl} , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  sendToTest(idPressroom) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/update/pressroom/test/' + idPressroom , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  sendToProd(idPressroom) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/update/pressroom/prod/' + idPressroom , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
    private errorHandler(err: any): Promise<any> {
        return Promise.reject(err.message || err );
    };
    private initOptionHeader(){
        const token: any = localStorage.getItem('auth-tokens');
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.set('X-Auth-Token', token);
        return headers;
    }
}
