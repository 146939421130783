import {Component, OnInit} from '@angular/core';
import {PressroomShareService} from '../../../../../service/partage/PressroomShareService';

@Component({
  selector: 'app-pressroom-client-configuration',
  templateUrl: './pressroom-client-configuration.component.html',
  styleUrls: ['./pressroom-client-configuration.component.scss'],
})
export class PressroomClientConfigurationComponent implements OnInit {


  private sectionActive: string;

  constructor(public pressroomShareService: PressroomShareService) { }

  ngOnInit() {}
  isActif(value) {
    if (value === this.sectionActive) {
      return 'active';
    }
  }

  refreshPressRoomAction(){
    this.pressroomShareService.initPressroom();
  }

  changeSectionActive(nom: string){
    if (this.sectionActive !== nom){
      this.sectionActive = nom;
    } else {
      this.sectionActive = null;
    }
  }

}
