import {Component, OnInit, Input, ViewChild, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SuivitIntranetService} from '../../../../service/interne/suivit/SuivitIntranetService';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';

@Component({
  selector: 'app-escal-suivi-fiche-intranet',
  templateUrl: './escal-suivi-fiche-intranet.component.html',
  styleUrls: ['./escal-suivi-fiche-intranet.component.scss'],
  providers: [ SuivitIntranetService ]
})
export class EscalSuiviFicheIntranetComponent implements OnInit {

  @Input()
  private sub: any;
  suivis: Array<any>;

  constructor(private route: ActivatedRoute, private suivitIntranetService: SuivitIntranetService,
              public formatIntranetShareService: FormatIntranetShareService) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.suivitIntranetService.getSuiviIntranet(params['id'])
        .then((suivi) => {
          this.suivis = suivi;
          this.suivis.forEach( lesuivi => {
            lesuivi.dateSuivi = new Date(lesuivi.dateSuivi);
            if (lesuivi.typeSuivi == 0) {
              lesuivi.typeSuivi = 'Relation presse';
            } else if (lesuivi.typeSuivi == 1) {
              lesuivi.typeSuivi = 'Relation publique';
            } else if (lesuivi.typeSuivi == 2) {
              lesuivi.typeSuivi = 'Suivi commercial';
            }
          });
      });
    });
  }

}
