import {Component, Input, OnInit} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';

@Component({
  selector: 'app-sommaire-liste-parution-type',
  templateUrl: './sommaire-liste-parution-type.component.html',
  styleUrls: ['./sommaire-liste-parution-type.component.scss']
})
export class SommaireListeParutionTypeComponent implements OnInit {


  @Input()
  listeTypeParution: any;

  layout: any;
  horizontalLayoutActive:boolean = false;
  private currentDraggableEvent:DragEvent;
  private currentDragEffectMsg:string;
  private readonly verticalLayout = {
    container: "row",
    list: "column",
    dndHorizontal: false
  };
  private readonly horizontalLayout = {
    container: "row",
    list: "row",
    dndHorizontal: true
  };

  constructor( ) {

    this.setHorizontalLayout( this.horizontalLayoutActive );
  }
  changeColor(color){

  }



  setHorizontalLayout( horizontalLayoutActive:boolean ) {

    this.layout = (horizontalLayoutActive) ? this.horizontalLayout : this.verticalLayout;
  }

  onDragStart( event:DragEvent ) {

    this.currentDragEffectMsg = '';
    this.currentDraggableEvent = event;

  }

  onDragged( item:any, list:any[], effect:DropEffect ) {

    this.currentDragEffectMsg = `Drag ended with effect "${effect}"!`;

    if( effect === "move" ) {

      const index = list.indexOf( item );
      list.splice( index, 1 );
    }
  }

  onDragEnd( event:DragEvent ) {

    this.currentDraggableEvent = event;
  }

  onDrop( event:DndDropEvent, list?:any[] ) {

    if( list
      && (event.dropEffect === "copy"
        || event.dropEffect === "move") ) {

      let index = event.index;

      if( typeof index === "undefined" ) {

        index = list.length;
      }

      list.splice( index, 0, event.data );
    }
  }
  ngOnInit() {
  }

}
