
import {Pressroom} from './Pressroom';
import {Client} from '../Client';
import {PressroomAccueil} from './PressroomAccueil';

export class PressroomModelService {



  constructor() {

  }

}
