import {Component, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {ClientService} from '../../../../service/interne/client/ClientService';
import { PaginationRechercheComponent} from '../../../utilitaire/pagination/pagination-recherche/pagination-recherche.component';

import {Client} from '../../../../model/client/Client';
import {CategorieDesMotsCles} from '../../../../model/client/motsCles/CategorieDesMotsCles';
import {CategoriesService} from '../../../../service/interne/client/motsCles/CategoriesService';
import {MotsCles} from '../../../../model/client/motsCles/MotsCles';

@Component({
  selector: 'app-escal-client-list',
  templateUrl: './escal-client-list.component.html',
  styleUrls: ['./escal-client-list.component.scss'],
  providers: [ ClientService ],
})

export class EscalClientListComponent implements OnInit {


  curentPage = 1;
  clients: Array<Client>;
  clientsTous: Array<Client>;
  IDClientsList: Array<Client>;
  client: Client;
  clientsPagination: Array<Client> = [];
  motsClesRecherche: Array<MotsCles>;
  headerContent: Array<any> = [];
  maxPage: number;
  typeRecherche: Array<any> = [];
  recherche: Array<any> = [];
  actuel: number;
@ViewChild(PaginationRechercheComponent) clientsFiltres: PaginationRechercheComponent;



  constructor( private clientService: ClientService, private route: ActivatedRoute) {
    if(this.route.url['value'][2].path === 'actuel') {
      this.actuel = 1;
    } else {
      this.actuel = 0;
    }
  }

  ngOnInit() {
    this.initContent();
    this.headerContent.push('Nom');
    this.headerContent.push('Dirigeant');
    this.headerContent.push('Référent');

    this.typeRecherche.push('Tous');
    this.typeRecherche.push('Nom');
    this.typeRecherche.push('Dirigeant');
    this.typeRecherche.push('Score');
    this.typeRecherche.push('Dates');
    this.typeRecherche.push('Mots clés');
  }

  initContent() {
    const that = this;
    that.clientsTous = [];
    let actuel = false;
    let clientPresent;
    let score;
    this.clientsPagination = [];
    if (this.recherche.length === 0) {
      this.clientService.getClient(this.curentPage, this.actuel)
        .then((data) => {
          that.clients = data['hydra:member'] as Array<Client>;
          that.getMaxPage(data['hydra:totalItems']);
        });
    } else if (this.recherche[0].length >= 3) { // minimum 3 caractères pour la recherche
      if (this.clientsFiltres.rechercheType === "Tous") {
        this.clientService.getClientsRecherche(this.recherche, this.curentPage, this.actuel)
          .then((data) => {
            that.clients = data['hydra:member'] as Array<Client>;
            that.getMaxPage(data['hydra:totalItems']);
          });
      } else if (this.clientsFiltres.rechercheType === "Nom" || this.clientsFiltres.rechercheType === "Dirigeant") {
        this.clientService.getClientsRecherche(this.recherche, this.curentPage, this.actuel)
          .then((data) => {
            // that.clientsTous collection temporaire
            that.clientsTous = data['hydra:member'] as Array<Client>;
            that.getMaxPage(data['hydra:totalItems']);
            that.clients = that.clientsTous;
          });
      } else if (this.clientsFiltres.rechercheType === "Mots clés") {
        this.clientService.getClientsRechercheMotsCles(this.recherche)
          .then((databis) => {
            that.clientsTous = [];
            // mots cles
            that.motsClesRecherche = databis['hydra:member'] as Array<MotsCles>;
            that.getMaxPage(databis['hydra:totalItems']);
            that.motsClesRecherche .forEach(motcle => {
              motcle.client.forEach(client => {
                if (this.actuel === 1) {
                  actuel = true;
                } else {
                  actuel = false;
                }
                if (client.actuel === actuel) {
                  // si pas deja dans la liste, on l'ajoute
                  clientPresent = that.clientsTous.find(element => element.id === client.id);
                  if (clientPresent === undefined) {
                    that.clientsTous.push(client);
                  }
                }
              });
            });
            that.clients = that.clientsTous;
          });
      }
    } else if (this.recherche[0].length === 1 && this.clientsFiltres.rechercheType === "Score") {
      score = this.recherche[0].toUpperCase();
      console.log(score);
      this.clientService.rechercheClientsScore(score, this.actuel)
        .then((retour) => {
          that.clientsTous = [];
          this.IDClientsList = retour.split(',');
          for (let i = 0; i < this.IDClientsList.length - 1; i++) {
            this.clientService.getClientDetail(this.IDClientsList[i])
              .then((retour2) => {
                this.client = retour2 as Client;
                // parcourir this.clients et ajouter client si il n'est pas dans this.clients
                clientPresent = this.clientsTous.find(element => element.id === this.client.id);
                if (clientPresent === undefined) {
                  this.clientsTous.push(this.client);
                }
                that.clients = that.clientsTous;
                this.getMaxPage(this.clientsTous.length);
                // on renvoie les 10 premiers à chaque nouvelle recherche
                this.curentPage = 1;
                this.getChangePage(this.curentPage);
              });
          }
        });
    }
  }
  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }
  getChangePage(page) {
    this.clientsPagination = [];
    this.curentPage = page;
    let position = this.curentPage * 10 ; // 3 pages donc 30, on va donc commencer à 20
    let nbElement = 0;
    this.clients.forEach(client => {
      if (nbElement === position - 10) {
        this.clientsPagination.push(client);
        if (position - 10 < this.curentPage * 10 - 1) {
          position +=  1;
        }
      }
      nbElement = nbElement + 1;
    });
  }



  changePage(page) {
    this.curentPage = page;
    this.initContent();
  }

  updateRecherche(recherche: Array<any>) {
    this.curentPage = 1;
    if (recherche[0] === '') {
      this.recherche = [];
    } else {
      this.recherche = recherche;
    }
    this.initContent();
  }
}
