import { Component, OnInit } from '@angular/core';

import {SalleService} from '../../../service/interne/lieux/SalleService';

import {Salle} from '../../../model/lieux/Salle';
import {AuthShareService} from '../../../service/partage/AuthShareService';

@Component({
  selector: 'app-administration-salle',
  templateUrl: './administration-salle.component.html',
  styleUrls: ['./administration-salle.component.scss'],
  providers: [ SalleService ]
})
export class AdministrationSalleComponent implements OnInit {

  nom: string;
  ajoutSalleOuvert: boolean = false;
  couleurFond;
  couleurBord;
  salles: Array<Salle> = [];

  constructor(private salleService: SalleService, public authShareService: AuthShareService) { }

  ngOnInit() {
    const that = this;
    this.salleService.getSalle()
      .then((retour) => {
        this.salles = retour['hydra:member'] as Array<Salle>;
      });
  }
  ajouterSalle() {
    const that = this;
    if(this.nom.length > 2){
      this.salleService.addSalle(this.nom, this.couleurFond, this.couleurBord)
        .then((retour) => {
          this.ajoutSalleOuvert = false;
          that.ngOnInit();
        });
    }
  }

}
