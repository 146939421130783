import {AuthUser} from '../user/authUser';
import {Client} from '../client/Client';
import {ChiffreCle} from './ChiffreCle';
import {ThemeDescriptif} from './ThemeDescriptif';
import {Perspective} from './Perspective';
import {Innovation} from './Innovation';


export class Actualite {
  id: number;
  nom: string;
  pitch: string;
  lien: string;
  mission: string;
  creation: Date;
  modification: Date;
  auteur: AuthUser;
  client: Client;
  chiffreCles: Array<ChiffreCle>;
  themeDescriptifs: Array<ThemeDescriptif>;
  perspectives: Array<Perspective>;
  innovations: Array<Innovation>;
  ancienId: number;

  constructor(id: number, nom: string, pitch: string, lien: string, mission: string, creation: Date, modification: Date,
              auteur: AuthUser, client: Client, chiffreCles: Array<ChiffreCle>, themeDescriptifs: Array<ThemeDescriptif>,
              perspectives: Array<Perspective>, innovations: Array<Innovation>, ancienId: number) {
    this.id = id;
    this.nom = nom;
    this.pitch = pitch;
    this.lien = lien;
    this.mission = mission;
    this.creation = creation;
    this.modification = modification;
    this.auteur = auteur;
    this.client = client;
    this.chiffreCles = chiffreCles;
    this.themeDescriptifs = themeDescriptifs;
    this.perspectives = perspectives;
    this.innovations = innovations;
    this.ancienId = ancienId;
  }
}
