import { Component, OnInit } from '@angular/core';
import {Contact} from '../../../../../model/contact/Contact';
import {ContactService} from '../../../../../service/interne/contact/ContactService';
import {ActivatedRoute} from '@angular/router';
import {Environement} from '../../../../../environement/environement';
import {OpenWithPostShareService} from "../../../../../service/partage/utilitaire/OpenWithPostShareService";

@Component({
  selector: 'app-escal-contact-fiche-export',
  templateUrl: './escal-contact-fiche-export.component.html',
  styleUrls: ['./escal-contact-fiche-export.component.scss']
})
export class EscalContactFicheExportComponent implements OnInit {

  private sub: any;
  contact: Contact;
  toggleActive = false;
  nom: boolean;
  prenom: boolean;
  ID: boolean;
  civilite: boolean;
  fonction: boolean;
  contenu: boolean;
  premierTel: boolean;
  deuxiemeTel: boolean;
  email: boolean;
  rue: boolean;
  complement: boolean;
  bpostal: boolean;
  cpostal: boolean;
  ville: boolean;
  pays: boolean;
  premierURL: boolean;
  deuxiemeURL: boolean;
  societe: boolean;
  telPro1: boolean;
  telPro2: boolean;
  emailPro: boolean;
  support: boolean;
  fonctionSupport: boolean;
  telSupport: boolean;
  emailSupport: boolean;
  categorie: boolean;
  objet: boolean;
  dateConclu: boolean;
  modeEnvoi: boolean;
  piecesJointes: boolean;
  constructor(private contactService: ContactService, private route: ActivatedRoute, private openWithPostShareService: OpenWithPostShareService) { }

  ngOnInit() {
    this.ID = true;
    this.nom = true;
    this.prenom = true;
    this.civilite = true;
    this.fonction = false;
    this.contenu = false;
    this.premierTel = false;
    this.deuxiemeTel = false;
    this.email = false;
    this.rue = false;
    this.complement = false;
    this.bpostal = false;
    this.cpostal = false;
    this.ville = false;
    this.pays = false;
    this.premierURL = false;
    this.deuxiemeURL = false;
    this.societe = false;
    this.telPro1 = false;
    this.telPro2 = false;
    this.emailPro = false;
    this.support = false;
    this.fonctionSupport = false;
    this.telSupport = false;
    this.emailSupport = false;
    this.categorie = false;
    this.objet = false;
    this.dateConclu = false;
    this.modeEnvoi = false;
    this.piecesJointes = false;
    this.sub = this.route.params.subscribe(params => {
      this.contactService.getContactDetail(params.id)
        .then((retour) => {
          this.contact = retour as Contact;
        });
    });
  }
  modifierActive() {
    if (this.toggleActive === false) {
      this.ID = false;
      this.nom = false;
      this.prenom = false;
      this.civilite = false;
      this.fonction = false;
      this.contenu = false;
      this.premierTel = false;
      this.deuxiemeTel = false;
      this.email = false;
      this.rue = false;
      this.complement = false;
      this.bpostal = false;
      this.cpostal = false;
      this.ville = false;
      this.pays = false;
      this.premierURL = false;
      this.deuxiemeURL = false;
      this.societe = false;
      this.telPro1 = false;
      this.telPro2 = false;
      this.emailPro = false;
      this.support = false;
      this.fonctionSupport = false;
      this.telSupport = false;
      this.emailSupport = false;
      this.categorie = false;
      this.objet = false;
      this.dateConclu = false;
      this.modeEnvoi = false;
      this.piecesJointes = false;
    } else {
      this.ID = true;
      this.nom = true;
      this.prenom = true;
      this.civilite = true;
      this.fonction = true;
      this.contenu = true;
      this.premierTel = true;
      this.deuxiemeTel = true;
      this.email = true;
      this.rue = true;
      this.complement = true;
      this.bpostal = true;
      this.cpostal = true;
      this.ville = true;
      this.pays = true;
      this.premierURL = true;
      this.deuxiemeURL = true;
      this.societe = true;
      this.telPro1 = true;
      this.telPro2 = true;
      this.emailPro = true;
      this.support = true;
      this.fonctionSupport = true;
      this.telSupport = true;
      this.emailSupport = true;
      this.categorie = true;
      this.objet = true;
      this.dateConclu = true;
      this.modeEnvoi = true;
      this.piecesJointes = true;
    }
  }
  exporterContact() {
    let url = '';
    this.contactService.exporterContactExcel(this.contact.id, this.ID, this.nom, this.prenom, this.civilite,
      this.fonction, this.contenu, this.premierTel, this.deuxiemeTel, this.email, this.rue, this.complement,
      this.bpostal, this.cpostal, this.ville, this.pays, this.premierURL, this.deuxiemeURL, this.societe, this.telPro1,
      this.telPro2, this.emailPro, this.support, this.fonctionSupport, this.telSupport, this.emailSupport,
      this.categorie, this.objet, this.dateConclu, this.modeEnvoi, this.piecesJointes ).then(() => {
      url = Environement.BASE_API_URL + '/excelStat/' + this.contact.id + 'export.xlsx';
      this.openWithPostShareService.openWindows(url);
    });
  }
}
