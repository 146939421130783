import { Component, OnInit } from '@angular/core';


import {Civilite} from '../../../../model/user/Civilite';
import {AuthShareService} from '../../../../service/partage/AuthShareService';
import {CiviliteService} from '../../../../service/interne/user/CiviliteService';
import {UserService} from '../../../../service/interne/user/UserService';

@Component({
  selector: 'app-escal-utilisateur-profil-prive',
  templateUrl: './escal-utilisateur-profil-prive.component.html',
  styleUrls: ['./escal-utilisateur-profil-prive.component.scss'],
  providers: [ CiviliteService, UserService ],
})
export class EscalUtilisateurProfilPriveComponent implements OnInit {

  modif: string;
  justChange: number = 0;
  civilites: Array<Civilite>;
  civiliteSelected: string;

  constructor(public authShareService: AuthShareService, private civiliteService: CiviliteService, private userService: UserService) {
    document.addEventListener('click', () => this.onClick());
  }

  ngOnInit() {
    const that = this;
    this.authShareService.getUserByAuthToken();
    this.civiliteSelected = this.authShareService.authUser.user.civilite.id;
    this.civiliteService.getCivilites()
      .then((retour) => {
        that.civilites = retour['hydra:member'] as Array<Civilite>;
      });
  }

  onClick(type: string = '') {
    if (type === '' && this.justChange === 1) {
      this.justChange = 0;
    } else {
      if (type !== '') {
        this.justChange = 1;
      }
      this.modif = type;
    }
  }
  updateCivilite(event) {
    const that = this;
    that.userService.updateCivilite(this.authShareService.authUser.user.id, this.civiliteSelected)
      .then((retour) => {
        that.ngOnInit();
      });
  }
  persoChange(){
    const that = this;
    that.userService.updateInfo(this.authShareService.authUser.user.id, this.authShareService.authUser.user.nom,
      this.authShareService.authUser.user.prenom)
      .then((retour) => {
        that.ngOnInit();
      });
  }

}
