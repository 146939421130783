import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';

@Injectable()
export class PerspectiveService {
  private perspectiveUrl = Environement.BASE_API_URL + '/api/perspectives';
  constructor(private http: HttpClient) {
  }
  getPerspectives() {
    const headers = this.initOptionHeader();
    return this.http.get(this.perspectiveUrl, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getPerspectiveById(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.perspectiveUrl + '/' + id, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  addPerspective(perspective, actuId) {
    const headers = this.initOptionHeader();
    return this.http.post(this.perspectiveUrl, {'libelle': perspective, 'actualite': Environement.BASE_API_URL + '/api/actualites/' + actuId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerPerspective(perspectiveId) {
    const headers = this.initOptionHeader();
    return this.http.delete(this.perspectiveUrl + '/' + perspectiveId,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
