import { Component, OnInit } from '@angular/core';
import {Client} from '../../../../model/client/Client';
import {AuthShareService} from '../../../../service/partage/AuthShareService';
import {RoleUserClient} from '../../../../model/client/RoleUserClient';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {ClientRoleUserService} from '../../../../service/interne/client/ClientRoleUserService';

@Component({
  selector: 'app-gestion-mes-client-detail',
  templateUrl: './gestion-mes-client-detail.component.html',
  styleUrls: ['./gestion-mes-client-detail.component.scss'],
  providers: [ ClientService, ClientRoleUserService ]
})
export class GestionMesClientDetailComponent implements OnInit {

  ouvert = false;
  clients: Array<Client>;
  clientSelect: Array<Client>;
  clientIdSelect;

  constructor(private authShareService: AuthShareService, private clientService: ClientService,
              private clientRoleUserService: ClientRoleUserService) {
    document.addEventListener('click', (event) => this.onClick(event));
  }

  ngOnInit() {
    const that = this;
    that.authShareService.getUserByAuthToken();
    this.clientService.getClientList(1)
      .then((retour) => {
        that.clients = retour['hydra:member'] as Array<Client>;
        that.initClientSelect();
      });
  }
  onClick(event){
    const elem = event['target'];
    if (elem.classList.contains('modal-overlay')) {
      this.ouvert = false;
    }
  }
  displayModale(){
    if (this.ouvert) {
      return 'inline-block';
    }
    return 'none';
  }

  initClientSelect() {
    const clientSelectVal: Array<Client> = [];
    this.clients.forEach((client: Client) => {
      let present = 0;
      this.authShareService.authUser.rolesUserClient.forEach((clientRole: RoleUserClient) => {
        if (clientRole.client.id === client.id){
          present = 1;
        }
      });
      if(present === 0){
        clientSelectVal.push(client);
      }
    });
    this.clientSelect = clientSelectVal;
    if(this.clientSelect.length === 0){
      this.clientIdSelect = '0';
    }else{
      this.clientIdSelect = this.clientSelect[0].id;
    }
  }
  ajouter(){
    const that = this;
    this.clientRoleUserService.addClientRoleUser(this.clientIdSelect, this.authShareService.authUser.id)
      .then( (retour ) => {
        that.ouvert = false;
        that.authShareService.getUserByAuthToken();
      });
  }
}
