import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {Client} from '../../../../model/client/Client';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';

@Component({
  selector: 'app-escal-client-fiche',
  templateUrl: './escal-client-fiche.component.html',
  styleUrls: ['./escal-client-fiche.component.scss'],
  providers: [ ClientService ]
})
export class EscalClientFicheComponent implements OnInit {

  private sub: any;
  client: Client;
  active = 'informations';
  constructor(private dialogueShareService: DialogueShareService, private route: ActivatedRoute, private clientService: ClientService) { }

  ngOnInit() {
    const that = this;
    this.sub = this.route.params.subscribe(params => {
      this.clientService.getClientDetail(params.id)
        .then((retour) => {
          that.client = retour as Client;
        });
    });
  }

  changerStatut() {
    if (this.client.actuel === false ) {
      this.client.actuel = true;
    } else {
      this.client.actuel = false;
    }
    this.clientService.changerStatut(this.client)
      .then((retour) => {
        this.dialogueShareService.addDialogue('Statut mis à jour');
        this.ngOnInit();
      });
  }

  refresh() {
    this.ngOnInit();
  }
  actived(type) {
    if (type === this.active) {
      return 'active';
    }
  }


}
