
export class ImageArticleSite {
  id: number;
  nom: string;



  constructor(id: number, nom: string, type: string) {
    this.id = id;
    this.nom = nom;
  }
}
