import {Component, Input, OnInit} from '@angular/core';
import {Contact} from '../../../../../model/contact/Contact';

@Component({
  selector: 'app-pagination-content-contact',
  templateUrl: './pagination-content-contact.component.html',
  styleUrls: ['./pagination-content-contact.component.scss']
})
export class PaginationContentContactComponent implements OnInit {

  @Input()
  contacts: Array<Contact>;
  constructor() { }

  ngOnInit() {
  }

}
